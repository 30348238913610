import { useOne } from '@pankod/refine-core';
import { IChannel } from 'interfaces';

const RESOURCE_NAME = 'channels';

const RESOURCE_FIELDS = [
  'id',
  'name',
  'approvedAt',
  'genres',
  {
    user: [
      'id',
      'companyName',
      'companyAddressLine1',
      'companyAddressLine2',
      'companyAddressPostcode',
      'companyAddressCity',
      'companyAddressState',
      'companyAddressCountry',
      'bankName',
      'bankSwiftCode',
      'bankAccountHolderName',
      'bankAccountNumber',
      'bankBranchCode',
      'contactPersonName',
      'contactPersonMobileNumber',
      'contactPersonEmail',
    ],
  },
] as const;

export const useGetChannel = (id: string) => {
  const result = useOne<IChannel>({
    id,
    resource: RESOURCE_NAME,
    metaData: { fields: [...RESOURCE_FIELDS] },
  });

  return result;
};
