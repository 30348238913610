import { Badge, Button } from '@pankod/refine-antd';
import { useWeb3Context } from '../context';
import { truncateEthAddress } from 'libs';

export const ConnectWalletButton = () => {
  const { web3Provider, connect, address } = useWeb3Context();

  return (
    <>
      {web3Provider ? (
        <Button danger>
          <Badge status="success" />
          {truncateEthAddress(address)}
        </Button>
      ) : (
        <Button onClick={connect} type="primary">
          Connect Wallet
        </Button>
      )}
    </>
  );
};
