import { useTable, useTableReturnType } from '@pankod/refine-antd';
import { CrudFilters, CrudSort, HttpError } from '@pankod/refine-core';
import { PAGINATION_FIELDS } from './constant';
import { IChannel, ITransaction, ITransactionFilterVariables, ResourceConfig } from 'interfaces';

const RESOURCE_NAME = 'transactions';

const RESOURCE_FIELDS = [
  'id',
  'amount',
  'creatorRevenue',
  'currencyCode',
  'description',
  'type',
  'createdAt',
  'status',
  {
    paymentTo: ['id', 'name'], // channel name
  },
  {
    paymentBy: ['id', 'fullName', 'mobileNumber', 'email'],
  },
] as const;

export const useListTransactions = (): useTableReturnType<ITransaction, ITransactionFilterVariables> & {
  resourceConfig: ResourceConfig;
} => {
  const initialSorter: CrudSort[] = [{ field: 'id', order: 'asc' }];
  const initialPageSize = 10;

  const result = useTable<ITransaction, HttpError, ITransactionFilterVariables>({
    resource: RESOURCE_NAME,
    metaData: {
      items: [...RESOURCE_FIELDS],
      metadata: [...PAGINATION_FIELDS],
    },
    initialSorter,
    initialPageSize,
    onSearch: (params) => {
      const filters: CrudFilters = [];

      const { channelId, transactionDates } = params;

      filters.push({
        field: 'channelId',
        operator: 'eq',
        value: channelId || null,
      });

      if (transactionDates) {
        filters.push({
          field: 'createdAt',
          operator: 'between',
          value:
            transactionDates?.length > 0
              ? [transactionDates[0].startOf('day').toDate(), transactionDates[1].endOf('day').toDate()]
              : [],
        });
      }

      return filters;
    },
  });

  return {
    ...result,
    resourceConfig: {
      name: RESOURCE_NAME,
      filters: result.filters,
      items: [...RESOURCE_FIELDS],
      metadata: [...PAGINATION_FIELDS],
    },
  };
};
