import React from 'react';
import { Breadcrumb, Button, Col, DateField, ExportButton, Icons, List, Row, Space, Table } from '@pankod/refine-antd';
import { IResourceComponentsProps, useExport, useTranslate } from '@pankod/refine-core';
import { ChannelDetailDialog, ChannelFilter } from 'components/channel';
import { PAGINATION_FIELDS } from 'hooks/constant';
import { useListChannels } from 'hooks';
import { IChannel } from 'interfaces';

export const ChannelManagementList: React.FC<IResourceComponentsProps> = () => {
  const t = useTranslate();

  const [viewRecordId, setViewRecordId] = React.useState<string | null>(null);
  const showRecordModal = (record: any) => setViewRecordId(record.id);
  const closeRecordModal = () => setViewRecordId(null);

  const { tableProps, searchFormProps, tableQueryResult, filters, resourceConfig } = useListChannels();

  const { triggerExport, isLoading: exportLoading } = useExport<IChannel>({
    pageSize: 1000,
    mapData: (item) => {
      let values = {
        fullName: item?.user?.fullName,
        username: item?.user?.username,
        email: item?.user?.email,
        role: item?.user?.role,
        status: item?.user?.status,
        mobileCountryCode: item?.user?.mobileCountryCode,
        mobileNumber: item?.user?.mobileNumber,
        joinedDate: item?.user?.createdAt,
        companyName: item?.user?.companyName,
        companyAddressLine1: item?.user?.companyAddressLine1,
        companyAddressLine2: item?.user?.companyAddressLine2,
        companyAddressPostcode: item?.user?.companyAddressPostcode,
        companyAddressCity: item?.user?.companyAddressCity,
        companyAddressState: item?.user?.companyAddressState,
        companyAddressCountry: item?.user?.companyAddressCountry,
        contactPersonName: item?.user?.contactPersonName,
        contactPersonMobileNumber: item?.user?.contactPersonMobileNumber,
        contactPersonEmail: item?.user?.contactPersonEmail,
        bankName: item?.user?.bankName,
        bankSwiftCode: item?.user?.bankSwiftCode,
        bankBranchCode: item?.user?.bankBranchCode,
        bankAccountHolderName: item?.user?.bankAccountHolderName,
        bankAccountNumber: item?.user?.bankAccountNumber,
        preferredLanguage: item?.user?.preferredLanguage,
        countryCode: item?.user?.countryCode,
        subscribeInfoAgreed: item?.user?.subscribeInfoAgreed,
        tosAgreed: item?.user?.tosAgreed,
        selfRegistered: item?.user?.selfRegistered,
        isEmailVerified: item?.user?.isEmailVerified,
        channelName: item?.name,
        isGiftEnabled: item?.isGiftEnabled,
        isSubscriptionEnabled: item?.isSubscriptionEnabled,
        currencyCode: item?.currencyCode,
        userId: item?.userId,
        totalReleasedVideoCount: item?.totalReleasedVideoCount,
        totalFollowerCount: item?.totalFollowerCount,
        genres: item?.genres,
        channelStatus: item?.status,
        setupCompletedAt: item?.setupCompletedAt,
        lastVideoUploadedAt: item?.lastVideoUploadedAt,
      };

      Object.keys(values).forEach((key) => {
        values[key] = values[key] || 'N/A';
      });

      return values;
    },
    onError: (error) => {
      console.error(error);
    },
    resourceName: resourceConfig.name,
    metaData: {
      items: resourceConfig.items,
      metadata: resourceConfig.metadata,
    },
    filters: resourceConfig.filters,
  });

  const Actions: React.FC = () => (
    <Space>
      <ExportButton
        loading={exportLoading}
        onClick={() => {
          triggerExport();
        }}
      />
    </Space>
  );

  return (
    <Row gutter={[16, 16]}>
      {viewRecordId && <ChannelDetailDialog channelId={viewRecordId} close={closeRecordModal} />}

      <Col xl={24} lg={24} xs={24}>
        <ChannelFilter formProps={searchFormProps} tableQueryResult={tableQueryResult} filters={filters || []} />
      </Col>

      <Col xl={24} xs={24}>
        <List
          pageHeaderProps={{
            title: 'Channel',
            breadcrumb: <Breadcrumb hideIcons />,
            extra: <Actions />,
          }}
        >
          <Table {...tableProps} rowKey="id">
            <Table.Column dataIndex="name" title={'Channel Name'} render={(value) => value || '-'} />
            <Table.Column dataIndex="totalReleasedVideoCount" title={'Total Released Videos'} />
            <Table.Column dataIndex="totalFollowerCount" title={'Total Followers'} />
            <Table.Column
              title="Setup Completed At"
              dataIndex="setupCompletedAt"
              key="setupCompletedAt"
              render={(value) => (value ? <DateField value={value} format="LLL" /> : 'N/A')}
            />
            <Table.Column
              title="Last Video Uploaded At"
              dataIndex="setupCompletedAt"
              key="setupCompletedAt"
              render={(value) => (value ? <DateField value={value} format="LLL" /> : 'N/A')}
            />
            <Table.Column<IChannel>
              width="5%"
              key="actions"
              title={t('table.actions')}
              dataIndex="actions"
              render={(_, record) => (
                <Space>
                  <Button size="small" icon={<Icons.EyeOutlined />} onClick={() => showRecordModal(record)} />
                </Space>
              )}
            />
          </Table>
        </List>
      </Col>
    </Row>
  );
};
