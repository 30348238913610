import { useState } from 'react';
import { Upload, UploadProps, message } from '@pankod/refine-antd';
import { InboxOutlined } from '@ant-design/icons';
import axios from 'axios';
import { getPresignUrl } from 'hooks';
import { OssResponse } from 'interfaces';

export const AliyunOSSUpload = ({
  purpose,
  accept = '*',
  description,
  onChange,
}: {
  purpose:
    | 'PersonalBrandedSite'
    | 'PlaylistThumbnail'
    | 'VideoSubtitle'
    | 'VideoThumbnail'
    | 'Banner'
    | 'CommunityLogo';
  accept?: string;
  description?: string;
  onChange: (OSSData: OssResponse) => void;
}) => {
  const [OSSData, setOSSData] = useState<OssResponse>();

  const handleRequest = async (options: any) => {
    const file: any = options.file;

    try {
      const response = await axios.put(options.action, file, {
        headers: {
          'Content-Type': file.type,
        },
      });
      options.onSuccess(response.data, options.file);
    } catch (error) {
      options.onError(error);
      console.log(error);
    }
  };

  const uploadProps: UploadProps = {
    accept,
    name: OSSData?.path.split('/').pop(),
    action: OSSData?.signedUrl,
    maxCount: 1,
  };

  return (
    <Upload.Dragger
      {...uploadProps}
      customRequest={handleRequest}
      onChange={({ file }) => {
        if (file.status === 'done') {
          message.success(`${file?.name} file uploaded successfully.`);
          onChange(OSSData as OssResponse);
        } else if (file.status === 'error') {
          message.error(`${file?.name} file upload failed.`);
        }
      }}
      beforeUpload={async (file: File) => {
        await getPresignUrl({
          params: {
            fileName: file.name,
            purpose,
          },
          onSuccess: (result: OssResponse) => {
            setOSSData(result);
          },
          onError: (error) => {
            message.error('Error: ' + error);
          },
        });

        return file;
      }}
    >
      <p className="ant-upload-drag-icon">
        <InboxOutlined />
      </p>
      <p className="ant-upload-text">Click or drag file to this area to upload</p>
      {description && <p className="ant-upload-hint" dangerouslySetInnerHTML={{ __html: description }}></p>}
    </Upload.Dragger>
  );
};
