import React from 'react';
import {
  Breadcrumb,
  Card,
  Col,
  DateField,
  ExportButton,
  List,
  Row,
  Space,
  Table,
  Typography,
} from '@pankod/refine-antd';
import { IResourceComponentsProps, LogicalFilter, useExport, useTranslate } from '@pankod/refine-core';
import { TransactionFilter } from 'components/report';
import { useGetTransactionSummary } from 'hooks/use-get-transaction-summary';
import { useListTransactions } from 'hooks';
import { ITransaction } from 'interfaces';
import { formatMoney } from 'libs/number';

const { Text, Title } = Typography;

const responsive = {
  xs: 24,
  sm: 24,
  md: 6,
  lg: 6,
  xl: 6,
};

export const TransactionList: React.FC<IResourceComponentsProps> = () => {
  const t = useTranslate();

  const { tableProps, searchFormProps, tableQueryResult, filters, resourceConfig } = useListTransactions();

  const summaryQueryResult = useGetTransactionSummary({
    channelId: (filters as LogicalFilter[]).find(({ field }) => field === 'channelId')?.value || null,
    transactionDates: (filters as LogicalFilter[]).find(({ field }) => field === 'createdAt')?.value || [],
  });

  const { triggerExport, isLoading: exportLoading } = useExport<ITransaction>({
    pageSize: 1000,
    mapData: (item) => ({
      id: item.id,
      amount: formatMoney((item.amount || 0) / 100, {
        currency: item.currencyCode,
      }),
      creatorRevenue: formatMoney((item.creatorRevenue || 0) / 100, {
        currency: item.currencyCode,
      }),
      currencyCode: item.currencyCode,
      paymentTo: item.paymentTo?.name,
      paymentBy: item.paymentBy?.fullName,
      description: item.description,
      type: item.type,
      createdAt: item.createdAt,
      status: item.status,
    }),
    onError: (error) => {
      console.error(error);
    },
    resourceName: resourceConfig.name,
    metaData: {
      items: resourceConfig.items,
      metadata: resourceConfig.metadata,
    },
    filters: resourceConfig.filters,
  });

  const Actions: React.FC = () => (
    <Space>
      <ExportButton
        loading={exportLoading}
        onClick={() => {
          triggerExport();
        }}
      />
    </Space>
  );

  return (
    <>
      <Row gutter={[16, 16]}>
        <Col {...responsive}>
          <Card>
            <Title>Total Transactions</Title>
            <Text>
              {formatMoney(summaryQueryResult?.amount / 100, {
                currency: summaryQueryResult?.currencyCode,
              })}
            </Text>
          </Card>
        </Col>
        <Col {...responsive}>
          <Card>
            <Title>Total Virtual Gifting</Title>
            <Text>
              {formatMoney(summaryQueryResult?.gift / 100, {
                currency: summaryQueryResult?.currencyCode,
              })}
            </Text>
          </Card>
        </Col>
        <Col {...responsive}>
          <Card>
            <Title>Total Subscription</Title>
            <Text>
              {formatMoney(summaryQueryResult?.subscription / 100, {
                currency: summaryQueryResult?.currencyCode,
              })}
            </Text>
          </Card>
        </Col>
        <Col {...responsive}>
          <Card>
            <Title>Total Pay-Per-View</Title>
            <Text>
              {formatMoney(summaryQueryResult?.payPerView / 100, {
                currency: summaryQueryResult?.currencyCode,
              })}
            </Text>
          </Card>
        </Col>
      </Row>

      <br />

      <Row gutter={[16, 16]}>
        <Col xl={24} lg={24} xs={24}>
          <TransactionFilter formProps={searchFormProps} tableQueryResult={tableQueryResult} filters={filters || []} />
        </Col>

        <Col xl={24} xs={24}>
          <List
            pageHeaderProps={{
              title: 'Transaction Report',
              breadcrumb: <Breadcrumb hideIcons />,
              extra: <Actions />,
            }}
          >
            <Table {...tableProps} scroll={{ x: 1800 }} rowKey="id">
              <Table.Column<ITransaction>
                dataIndex="createdAt"
                title={'Date'}
                render={(_, record) => <DateField value={record.createdAt} format="lll" />}
                fixed="left"
                width="2200"
              />
              <Table.Column dataIndex="id" title={'Transaction ID'} />
              <Table.Column<ITransaction>
                title={'Channel Name'}
                render={(_, record) => {
                  return <>{record?.paymentTo?.name}</>;
                }}
              />
              <Table.Column<ITransaction>
                title={'Description'}
                render={(_, record) => {
                  return <>{record?.description}</>;
                }}
              />
              <Table.Column<ITransaction>
                dataIndex="amount"
                title={'Total Transactions (MYR)'}
                render={(_, record) => formatMoney(record?.amount / 100 || 0)}
              />
              <Table.Column<ITransaction>
                dataIndex="creatorRevenue"
                title={'Total Creator Revenue (MYR)'}
                render={(_, record) => formatMoney(record?.creatorRevenue / 100 || 0)}
              />
              <Table.Column<ITransaction> title={'Audience ID'} render={(_, record) => record?.paymentBy?.id || '-'} />
              <Table.Column<ITransaction>
                title={'Audience Phone Number'}
                render={(_, record) => record?.paymentBy?.mobileNumber || '-'}
              />
              <Table.Column<ITransaction>
                title={'Audience Email'}
                render={(_, record) => record?.paymentBy?.email || '-'}
              />
            </Table>
          </List>
        </Col>
      </Row>
    </>
  );
};
