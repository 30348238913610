export enum TransactionReceiptStatus {
  SUCCESS = 1,
  FAIL = 0,
}

// ref: https://github.com/MetaMask/eth-rpc-errors/blob/main/src/error-constants.ts
export enum rpcErrorCodes {
  userRejectedRequest = 4001,
  unauthorized = 4100,
  unsupportedMethod = 4200,
  disconnected = 4900,
  chainDisconnected = 4901,
}

export enum ChainId {
  mumbai = 80001,
  polygon = 137,
  goerli = 5,
  mainnet = 1,
}
