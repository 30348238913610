import { useCustom } from '@pankod/refine-core';
import { ITransactionFilterVariables, ITransactionSummary } from 'interfaces';

export const useGetTransactionSummary = (values: ITransactionFilterVariables) => {
  const result = useCustom<ITransactionSummary>({
    url: '',
    method: 'get',
    metaData: {
      operation: 'getTransactionSummary',
      fields: ['currencyCode', 'amount', 'payPerView', 'gift', 'subscription', 'tips'],
      variables: {
        where: {
          value: {
            channelId: values?.channelId || null,
            dateFrom: values?.transactionDates[0] || null,
            dateTo: values?.transactionDates[1] || null,
          },
          type: 'TransactionSummaryInput',
          required: true,
        },
      },
    },
  });

  return result?.data?.data;
};
