import React from 'react';
import { useTable, useTableReturnType } from '@pankod/refine-antd';
import { CrudFilters, CrudSort, HttpError } from '@pankod/refine-core';
import { PAGINATION_FIELDS } from './constant';
import { IChannel, IChannelFilterVariables, ResourceConfig } from 'interfaces';

const RESOURCE_NAME = 'channels';

const RESOURCE_FIELDS = [
  'id',
  'name',
  'aboutMe',
  'aboutLinks',
  'appLogo',
  'rollLogo',
  'splashScreenLogo',
  'loginBanner',
  'promotionalBanners',
  'themeColor',
  'primaryHex',
  'secondaryHex',
  'isGiftEnabled',
  'isSubscriptionEnabled',
  'currencyCode',
  'userId',
  {
    user: [
      'id',
      'fullName',
      'username',
      'role',
      'mobileCountryCode',
      'mobileNumber',
      'email',
      'status',
      'companyName',
      'companyAddressLine1',
      'companyAddressLine2',
      'companyAddressPostcode',
      'companyAddressCity',
      'companyAddressState',
      'companyAddressCountry',
      'bankName',
      'bankSwiftCode',
      'bankBranchCode',
      'bankAccountHolderName',
      'bankAccountNumber',
      'contactPersonName',
      'contactPersonMobileNumber',
      'contactPersonEmail',
      'countryCode',
      'subscribeInfoAgreed',
      'tosAgreed',
      'selfRegistered',
      'isEmailVerified',
      'preferredLanguage',
      'currencyCode',
    ],
  },
  'totalReleasedVideoCount',
  'totalFollowerCount',
  'genres',
  'status',
  'lastVideoUploadedAt',
  'setupCompletedAt',
  'approvedAt',
  'createdAt',
  'updatedAt',
] as const;

export const useListChannels = (): useTableReturnType<IChannel, IChannelFilterVariables> & {
  resourceConfig: ResourceConfig;
} => {
  const initialSorter: CrudSort[] = [{ field: 'id', order: 'asc' }];
  const initialPageSize = 10;

  const result = useTable<IChannel, HttpError, IChannelFilterVariables>({
    resource: RESOURCE_NAME,
    metaData: {
      items: [...RESOURCE_FIELDS],
      metadata: [...PAGINATION_FIELDS],
    },
    initialSorter,
    initialPageSize,
    onSearch: (params: any) => {
      const filters: CrudFilters = [];
      const { name, joinedDates } = params;

      filters.push({
        field: 'name',
        operator: 'contains',
        value: name,
      });

      filters.push({
        field: 'approvedAt',
        operator: 'between',
        value:
          joinedDates?.length > 0 ? [joinedDates[0].startOf('day').toDate(), joinedDates[1].endOf('day').toDate()] : [],
      });

      return filters;
    },
  });

  return {
    ...result,
    resourceConfig: {
      name: RESOURCE_NAME,
      filters: result.filters,
      items: [...RESOURCE_FIELDS],
      metadata: [...PAGINATION_FIELDS],
    },
  };
};
