import { useEffect, useState } from 'react';
import {
  Breadcrumb,
  Col,
  Edit,
  Form,
  Icons,
  Input,
  Row,
  Select,
  Tooltip,
  Typography,
  message,
} from '@pankod/refine-antd';
import { IResourceComponentsProps, useCustom } from '@pankod/refine-core';
import VerifyEmailLabel from 'components/auth/VerifyEmailLabel';
import { dataProvider } from '../../App';
import { countries } from '../../data/countries.js';
import { mapValuesToSetter } from '../../libs';
import { RESOURCE_FIELDS } from './constant';
import { IUser } from 'interfaces';
import { simplifyErrorMessage } from 'libs';

const { Text } = Typography;

export const AccountSettingEditProfile: React.FC<IResourceComponentsProps> = () => {
  const [isEmailVerified, setIsEmailVerified] = useState(true);
  const onVerifySuccess = () => {
    setIsEmailVerified(true);
  };

  const { data, isFetching } = useCustom<IUser>({
    url: '',
    method: 'get',
    metaData: {
      operation: 'me',
      fields: [...RESOURCE_FIELDS],
    },
  });

  const [form] = Form.useForm();

  useEffect(() => {
    form.setFieldsValue({
      ...(data?.data as any),
    });

    setIsEmailVerified(!!data?.data.isEmailVerified);

    return () => {
      form.resetFields();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data, isFetching]);

  const handleSubmit = async (payload: any) => {
    try {
      const { data } = await dataProvider.custom!({
        url: '',
        method: 'put',
        metaData: {
          operation: 'updateMyAccount',
          variables: {
            data: {
              value: {
                ...payload,
              },
              type: 'UpdateMyAccountInput',
              required: true,
            },
          },
          fields: ['id'],
        },
      });
      message.success('Successfully edited profile');
    } catch (error) {
      const errorMessage = simplifyErrorMessage(error);
      message.error(errorMessage || 'Error while editing profile');
    }
  };

  const renderVerifyEmailLabel = () => {
    return !isEmailVerified && !isFetching ? <VerifyEmailLabel onSuccess={onVerifySuccess} /> : null;
  };

  return (
    <>
      {renderVerifyEmailLabel()}

      <Edit
        title="Account Setting - Profile"
        isLoading={isFetching}
        saveButtonProps={{
          disabled: isFetching,
          onClick: () => {
            form.submit();
          },
        }}
        pageHeaderProps={{
          breadcrumb: <Breadcrumb hideIcons />,
          extra: <></>,
        }}
      >
        {data && (
          <Form
            form={form}
            layout="vertical"
            onFinish={(values) => {
              const payload = mapValuesToSetter({
                ...values,
              });
              handleSubmit(payload);
            }}
          >
            <Row gutter={[64, 0]} wrap>
              <Col xs={24} lg={24}>
                <Row>
                  <Col xs={24} lg={12}>
                    <Text strong>Username</Text>
                  </Col>
                  <Col xs={24} lg={12}>
                    <Form.Item name="username" rules={[{ required: true }]}>
                      <Input disabled />
                    </Form.Item>
                  </Col>
                </Row>

                <Row>
                  <Col xs={24} lg={12}>
                    <Text strong>Login Email</Text>
                  </Col>
                  <Col xs={24} lg={12}>
                    <Form.Item name="email" rules={[{ required: true }]}>
                      <Input disabled />
                    </Form.Item>
                  </Col>
                </Row>

                <Row
                  align={'middle'}
                  style={{
                    marginTop: '40px',
                    marginBottom: '20px',
                  }}
                >
                  <Typography.Title
                    level={4}
                    underline
                    style={{
                      marginRight: '5px',
                    }}
                  >
                    Personal Information
                  </Typography.Title>
                  <Tooltip title="Personal information can be company's or creator's information.">
                    <Icons.InfoCircleOutlined />
                  </Tooltip>
                </Row>

                <Row>
                  <Col xs={24} lg={12}>
                    <Text strong>* Full Name (Legacy)</Text>
                  </Col>
                  <Col xs={24} lg={12}>
                    <Form.Item
                      name="fullName"
                      rules={[
                        {
                          required: true,
                          message: 'Full Name is a required field',
                        },
                      ]}
                    >
                      <Input />
                    </Form.Item>
                  </Col>
                </Row>

                <Row>
                  <Col xs={24} lg={12}>
                    <Text strong>* Phone Number</Text>
                  </Col>
                  <Col xs={24} lg={12}>
                    <Row gutter={[6, 0]}>
                      <Col xs={8} lg={7}>
                        <Form.Item
                          name="mobileCountryCode"
                          rules={[
                            {
                              required: true,
                              message: 'Phone Number is a required field',
                            },
                          ]}
                        >
                          <Select
                            showSearch
                            filterOption={(input, option) => {
                              return (option!.value as unknown as string).toLowerCase().includes(input.toLowerCase());
                            }}
                          >
                            {countries.map((country, index) => (
                              <Select.Option key={`country${index}`} label={country.name} value={country.dial_code}>
                                <Text>
                                  {country.flag} {country.dial_code}
                                </Text>
                              </Select.Option>
                            ))}
                          </Select>
                        </Form.Item>
                      </Col>
                      <Col xs={16} lg={17}>
                        <Form.Item name="mobileNumber" rules={[{ required: true }]}>
                          <Input />
                        </Form.Item>
                      </Col>
                    </Row>
                  </Col>
                </Row>

                <Row>
                  <Col xs={24} lg={12}>
                    <Text strong>* Address Line 1</Text>
                  </Col>
                  <Col xs={24} lg={12}>
                    <Form.Item
                      name="companyAddressLine1"
                      rules={[
                        {
                          required: true,
                          message: 'Address Line 1 is a required field',
                        },
                      ]}
                    >
                      <Input />
                    </Form.Item>
                  </Col>
                </Row>

                <Row>
                  <Col xs={24} lg={12}>
                    <Text strong>Address Line 2</Text>
                  </Col>
                  <Col xs={24} lg={12}>
                    <Form.Item name="companyAddressLine2" rules={[{ required: false }]}>
                      <Input />
                    </Form.Item>
                  </Col>
                </Row>

                <Row>
                  <Col xs={24} lg={12}>
                    <Text strong>* State</Text>
                  </Col>
                  <Col xs={24} lg={12}>
                    <Form.Item
                      name="companyAddressState"
                      rules={[{ required: true, message: 'State is a required field' }]}
                    >
                      <Input />
                    </Form.Item>
                  </Col>
                </Row>

                <Row>
                  <Col xs={24} lg={12}>
                    <Text strong>* Postcode</Text>
                  </Col>
                  <Col xs={24} lg={12}>
                    <Form.Item
                      name="companyAddressPostcode"
                      rules={[
                        {
                          required: true,
                          message: 'Postcode is a required field',
                        },
                      ]}
                    >
                      <Input />
                    </Form.Item>
                  </Col>
                </Row>

                <Row>
                  <Col xs={24} lg={12}>
                    <Text strong>* Country</Text>
                  </Col>
                  <Col xs={24} lg={12}>
                    <Form.Item
                      name="companyAddressCountry"
                      rules={[
                        {
                          required: true,
                          message: 'Country is a required field',
                        },
                      ]}
                    >
                      <Select
                        showSearch
                        filterOption={(input, option) => {
                          return (option!.label as unknown as string).toLowerCase().includes(input.toLowerCase());
                        }}
                      >
                        {countries.map((country, index) => (
                          <Select.Option key={`country${index}`} label={country.name} value={country.name}>
                            <Text>{country.name}</Text>
                          </Select.Option>
                        ))}
                      </Select>
                    </Form.Item>
                  </Col>
                </Row>

                <Row
                  align={'middle'}
                  style={{
                    marginTop: '40px',
                    marginBottom: '20px',
                  }}
                >
                  <Typography.Title
                    level={4}
                    underline
                    style={{
                      marginRight: '5px',
                    }}
                  >
                    Agent / PIC Information
                  </Typography.Title>
                  <Tooltip title="Fill the particulars below if you have a manager/agent for us to contact.">
                    <Icons.InfoCircleOutlined />
                  </Tooltip>
                </Row>

                <Row>
                  <Col xs={24} lg={12}>
                    <Text strong> Nickname</Text>
                  </Col>
                  <Col xs={24} lg={12}>
                    <Form.Item name="contactPersonName" rules={[{ required: false }]}>
                      <Input />
                    </Form.Item>
                  </Col>
                </Row>

                <Row>
                  <Col xs={24} lg={12}>
                    <Text strong> Phone Number</Text>
                  </Col>
                  <Col xs={24} lg={12}>
                    <Form.Item name="contactPersonMobileNumber" rules={[{ required: false }]}>
                      <Input />
                    </Form.Item>
                  </Col>
                </Row>

                <Row>
                  <Col xs={24} lg={12}>
                    <Text strong> Email</Text>
                  </Col>
                  <Col xs={24} lg={12}>
                    <Form.Item name="contactPersonEmail" rules={[{ required: false }]}>
                      <Input />
                    </Form.Item>
                  </Col>
                </Row>
              </Col>
              <Col xs={24} lg={8}></Col>
            </Row>
          </Form>
        )}
      </Edit>
    </>
  );
};
