export const RESOURCE_NAME = 'users';
export const RESOURCE_FIELDS = [
  'username',
  'id',
  'fullName',
  'mobileCountryCode',
  'mobileNumber',
  'email',
  'preferredLanguage',
  'currencyCode',
  'companyName',
  'companyAddressLine1',
  'companyAddressLine2',
  'companyAddressPostcode',
  'companyAddressCity',
  'companyAddressState',
  'companyAddressCountry',
  'bankName',
  'bankSwiftCode',
  'bankBranchCode',
  'bankAccountHolderName',
  'bankAccountNumber',
  'contactPersonName',
  'contactPersonMobileNumber',
  'contactPersonEmail',
  'isEmailVerified',
] as const;
export const PAGINATION_FIELDS = ['currentPage', 'perPage', 'totalCount', 'totalPages'] as const;
