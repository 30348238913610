import { RESOURCE_FIELDS as videoResourceFields } from "../../videos/constant";

export const RESOURCE_NAME = "playlistVideos";
export const RESOURCE_FIELDS = [
  "videoId",
  "playlistId",
  {
    video: videoResourceFields,
  },
] as const;
export const PAGINATION_FIELDS = [
  "currentPage",
  "perPage",
  "totalCount",
  "totalPages",
] as const;
