import { LogicalFilter, useList } from '@pankod/refine-core';
import { PAGINATION_FIELDS } from './constant';
import { IComment } from 'interfaces';

export const useListComments = ({
  filters,
  pagination,
}: {
  filters?: LogicalFilter[];
  pagination?: {
    current: number;
    pageSize: number;
  };
}) => {
  return useList<IComment>({
    resource: 'comments',
    metaData: {
      items: [
        'id',
        'userId',
        {
          user: ['username'],
        },
        'videoId',
        'content',
        'archivedAt',
        'createdAt',
        'updatedAt',
      ],
      metadata: [...PAGINATION_FIELDS],
    },
    config: {
      filters: [...filters],
      sort: [
        {
          field: 'createdAt',
          order: 'desc',
        },
      ],
      pagination: {
        current: pagination.current,
        pageSize: pagination.pageSize,
      },
    },
  });
};
