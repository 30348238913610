import { Form, FormProps, Input, Modal, ModalProps } from '@pankod/refine-antd';
import { AliyunOSSUpload } from 'components/upload';
import { mapValuesToSetter } from 'libs';

type EditPlaylistProps = {
  modalProps: ModalProps;
  formProps: FormProps;
  hideCompanySelect?: boolean;
};

export const EditDialog: React.FC<EditPlaylistProps> = ({ modalProps, formProps }) => {
  return (
    <Modal {...modalProps} title="Edit Playlist" width={500}>
      <Form
        {...formProps}
        onFinish={(values) => {
          formProps.onFinish(mapValuesToSetter(values));
        }}
        layout="vertical"
      >
        <Form.Item label="Name" name="name" rules={[{ required: true }]}>
          <Input />
        </Form.Item>
        <Form.Item label="Description" name="description" rules={[{ required: false }]}>
          <Input.TextArea />
        </Form.Item>
        <Form.Item label={'Thumbnail'} name="thumbnailUrl" rules={[{ required: false }]}>
          <AliyunOSSUpload
            purpose="PlaylistThumbnail"
            accept="image/*"
            description="Extension Allowed: .jpg/.jpeg/.png<br>Recommended Dimension: 1280 x 720 pixels"
            onChange={(response) => {
              formProps.form.setFieldsValue({
                thumbnailUrl: response.url,
              });
            }}
          />
        </Form.Item>
      </Form>
    </Modal>
  );
};
