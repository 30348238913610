import { useEffect } from 'react';
import { Form, FormProps, Input, InputNumber, Modal, ModalProps, Select } from '@pankod/refine-antd';
import { BaseRecord, GetOneResponse, useList } from '@pankod/refine-core';
import iconList from '../../../data/virtual-gift-icon-list';
import { PAGINATION_FIELDS, RESOURCE_FIELDS, RESOURCE_NAME } from '../constant';
import '../style.less';
import { CurrencyCode } from 'api';
import { IVirtualGift } from 'interfaces';
import { mapValuesToSetter } from 'libs';

interface VirtualGiftEditFormProps {
  values?: GetOneResponse<BaseRecord>;
  modalProps: ModalProps;
  formProps: FormProps;
  currencyCode: CurrencyCode;
}

const { Option } = Select;

export const VirtualGiftEditForm = ({
  values,
  modalProps,
  formProps,
  currencyCode = CurrencyCode.Myr,
}: VirtualGiftEditFormProps) => {
  const { data: virtualGiftList } = useList<IVirtualGift>({
    resource: RESOURCE_NAME,
    metaData: {
      operation: 'listMyGifts',
      items: [...RESOURCE_FIELDS],
      metadata: [...PAGINATION_FIELDS],
    },
    config: {
      pagination: { current: 1, pageSize: 1000 },
    },
  });

  const filterIconURL = (iconsList: string[]) => {
    if (!virtualGiftList?.data) {
      return iconsList;
    }
    const addedGiftImg = virtualGiftList?.data
      .filter((gift) => gift.archivedAt === null)
      .map((gift) => gift.thumbnailUrl);

    const availableImages = iconList.filter(
      (icon) => !addedGiftImg.includes(icon) || icon === values?.data.thumbnailUrl,
    );

    return availableImages;
  };

  return (
    <Modal {...modalProps} title="Edit Virtual Gift" width={600}>
      <Form
        {...formProps}
        labelCol={{ span: 8 }}
        wrapperCol={{ span: 16 }}
        onFinish={(values) => {
          const payload = mapValuesToSetter({
            ...values,
            price: Math.round(values.price * 100),
          });
          formProps.onFinish({
            ...payload,
          });
        }}
      >
        <Form.Item label="Icon" className="gift-icon-selector" name="thumbnailUrl" rules={[{ required: true }]}>
          <Select>
            {filterIconURL(iconList).map((url) => {
              return (
                <Option key={url} value={url}>
                  <div
                    style={{
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'center',
                    }}
                  >
                    <img alt="Gift Icon" style={{ width: 'auto', height: 45 }} src={url} />
                  </div>
                </Option>
              );
            })}
          </Select>
        </Form.Item>

        <Form.Item label="Gift Name" name="name" rules={[{ required: true }]}>
          <Input />
        </Form.Item>

        <Form.Item
          label={`Gift Price (${currencyCode})`}
          name="price"
          rules={[
            { required: true },
            ({ getFieldValue }) => ({
              validator(_, value) {
                if (value > 0 || !value) {
                  return Promise.resolve();
                }
                return Promise.reject(new Error('Gift value must be larger than zero!'));
              },
            }),
          ]}
        >
          <InputNumber controls={false} style={{ width: '100%' }} addonAfter=".00" />
        </Form.Item>
      </Form>
    </Modal>
  );
};
