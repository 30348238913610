import { useTable, useTableReturnType } from '@pankod/refine-antd';
import { CrudFilters, CrudSort, HttpError } from '@pankod/refine-core';
import { PAGINATION_FIELDS } from './constant';
import { IWatchHistoryFilterVariables, IWatchHistorySummary, ResourceConfig } from 'interfaces';

const RESOURCE_NAME = 'watchHistorySummary';

const RESOURCE_FIELDS = ['frequency', 'totalViewCount', 'totalVideoCount', 'date'] as const;

export const useListWatchHistories = ({
  frequency,
}): useTableReturnType<IWatchHistorySummary, IWatchHistoryFilterVariables> & { resourceConfig: ResourceConfig } => {
  const initialFilter: CrudFilters = [
    {
      field: 'frequency',
      operator: 'null',
      value: frequency,
    },
  ];
  const initialSorter: CrudSort[] = [];
  const initialPageSize = 10;

  const result = useTable<IWatchHistorySummary, HttpError, IWatchHistoryFilterVariables>({
    resource: RESOURCE_NAME,
    metaData: {
      operation: 'getWatchHistorySummary',
      items: [...RESOURCE_FIELDS],
      metadata: [...PAGINATION_FIELDS],
    },
    initialSorter,
    initialPageSize,
    initialFilter,
    onSearch: (params) => {
      const filters: CrudFilters = [];

      const { channelId, searchDates } = params;

      filters.push({
        field: 'channelId',
        operator: 'null',
        value: channelId,
      });

      if (searchDates) {
        filters.push({
          field: 'dateFrom',
          operator: 'null',
          value: searchDates[0].startOf('day').toDate(),
        });

        filters.push({
          field: 'dateTo',
          operator: 'null',
          value: searchDates[1].endOf('day').toDate(),
        });
      }

      return filters;
    },
  });

  return {
    ...result,
    resourceConfig: {
      name: RESOURCE_NAME,
      filters: result.filters,
      items: [...RESOURCE_FIELDS],
      metadata: [...PAGINATION_FIELDS],
    },
  };
};
