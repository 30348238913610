import { useUpdate } from "@pankod/refine-core";
import React from "react";

export const useToggleChannelSubscription = ({
  channelId,
}: {
  channelId: string;
}) => {
  const { mutate, isSuccess } = useUpdate();

  const toggle = async (checked: boolean) => {
    if (channelId) {
      mutate({
        resource: "channel",
        id: channelId,
        values: {
          isSubscriptionEnabled: { set: checked },
        },
      });
    }
  };

  return { toggle, isSuccess };
};
