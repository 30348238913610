import { useEffect } from 'react';
import {
  Breadcrumb,
  Col,
  Edit,
  Form,
  Icons,
  Input,
  Row,
  Select,
  Tooltip,
  Typography,
  message,
} from '@pankod/refine-antd';
import { IResourceComponentsProps, useCustom } from '@pankod/refine-core';
import { useUpdateMyChannel } from 'pages/personal-brand/hooks/use-update-my-channel';
import { mapValuesToSetter } from '../../libs';
import { RESOURCE_FIELDS } from './constant';
import { dataProvider } from 'App';
import { CurrencyCode } from 'api';
import { IChannel, IUser } from 'interfaces';
import { simplifyErrorMessage } from 'libs';
import { get, omit } from 'lodash';

const { Text } = Typography;
const { Option } = Select;

export const AccountSettingEditPayout: React.FC<IResourceComponentsProps> = () => {
  const queryResult = useCustom<IUser & { channel: IChannel }>({
    url: '',
    method: 'get',
    metaData: {
      operation: 'me',
      fields: [...RESOURCE_FIELDS, { channel: ['id', 'currencyCode'] }],
    },
  });
  const { data, isFetching } = queryResult;
  const { updateSite } = useUpdateMyChannel();

  const [form] = Form.useForm();

  useEffect(() => {
    form.setFieldsValue({
      ...(data?.data as any),
    });
    return () => {
      form.resetFields();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data, isFetching]);

  const updateForm = async (payload: any) => {
    try {
      await dataProvider.custom!({
        url: '',
        method: 'put',
        metaData: {
          operation: 'updateMyAccount',
          variables: {
            data: {
              value: {
                ...mapValuesToSetter(omit(payload, 'channel')),
              },
              type: 'UpdateMyAccountInput',
              required: true,
            },
          },
          fields: ['id'],
        },
      });

      await updateSite({ ...mapValuesToSetter(get(payload, 'channel')) });
      message.success('Successfully edited profile');
    } catch (error) {
      const errorMessage = simplifyErrorMessage(error);
      message.error(errorMessage || 'Error while editing profile');
    }
  };

  return (
    <Edit
      title="Account Setting - Payout"
      isLoading={queryResult?.isFetching}
      saveButtonProps={{
        disabled: queryResult?.isFetching,
        onClick: () => {
          form.submit();
        },
      }}
      pageHeaderProps={{
        breadcrumb: <Breadcrumb hideIcons />,
        extra: <></>,
      }}
    >
      {data && (
        <Form
          form={form}
          layout="vertical"
          onFinish={(values) => {
            updateForm(values);
          }}
        >
          <Row gutter={[64, 0]} wrap>
            <Col xs={24} lg={24}>
              <div
                style={{
                  marginBottom: '20px',
                }}
              >
                <Typography.Title level={4} underline>
                  Payout Information
                </Typography.Title>
              </div>

              <Row>
                <Col xs={24} lg={12}>
                  <Row align={'middle'}>
                    <Text
                      strong
                      style={{
                        marginRight: '5px',
                      }}
                    >
                      * Bank Account Holder Name
                    </Text>
                    <Tooltip title="Make sure the bank account holder name is same as the legacy name in Profile - Personal Information.">
                      <Icons.InfoCircleOutlined />
                    </Tooltip>
                  </Row>
                </Col>
                <Col xs={24} lg={12}>
                  <Form.Item
                    name="bankAccountHolderName"
                    rules={[
                      {
                        required: true,
                        message: 'Bank Account Holder Name is a required field.',
                      },
                    ]}
                  >
                    <Input />
                  </Form.Item>
                </Col>
              </Row>

              <Row>
                <Col xs={24} lg={12}>
                  <Text strong>* Bank</Text>
                </Col>
                <Col xs={24} lg={12}>
                  <Form.Item name="bankName" rules={[{ required: true, message: 'Bank is a requiref field' }]}>
                    <Input />
                  </Form.Item>
                </Col>
              </Row>

              <Row>
                <Col xs={24} lg={12}>
                  <Text strong>* Bank Account Number</Text>
                </Col>
                <Col xs={24} lg={12}>
                  <Form.Item
                    name="bankAccountNumber"
                    rules={[
                      {
                        required: true,
                        message: 'Bank Account Number is a required field.',
                      },
                    ]}
                  >
                    <Input />
                  </Form.Item>
                </Col>
              </Row>

              <Row>
                <Col xs={24} lg={12}>
                  <Row align={'middle'}>
                    <Text
                      strong
                      style={{
                        marginRight: '5px',
                      }}
                    >
                      Swift Code
                    </Text>
                    <Text
                      style={{
                        fontSize: '12px',
                      }}
                    >
                      {'(Please provide if not a Malaysia bank account)'}
                    </Text>
                  </Row>
                </Col>
                <Col xs={24} lg={12}>
                  <Form.Item name="bankSwiftCode" rules={[{ required: false }]}>
                    <Input />
                  </Form.Item>
                </Col>
              </Row>

              <Row>
                <Col xs={24} lg={12}>
                  <Row align={'middle'}>
                    <Text
                      strong
                      style={{
                        marginRight: '5px',
                      }}
                    >
                      Branch Code
                    </Text>
                    <Text
                      style={{
                        fontSize: '12px',
                      }}
                    >
                      {'(Please provide if not a Malaysia bank account)'}
                    </Text>
                  </Row>
                </Col>
                <Col xs={24} lg={12}>
                  <Form.Item name="bankBranchCode" rules={[{ required: false }]}>
                    <Input />
                  </Form.Item>
                </Col>
              </Row>

              <Row>
                <Col xs={24} lg={12}>
                  <Row align={'middle'}>
                    <Text
                      strong
                      style={{
                        marginRight: '5px',
                      }}
                    >
                      * Currency To Received
                    </Text>
                    <Tooltip title="This is the base currency that PrestoColor will pay to you.">
                      <Icons.InfoCircleOutlined />
                    </Tooltip>
                  </Row>
                </Col>
                <Col xs={24} lg={12}>
                  <Form.Item
                    name="currencyCode"
                    rules={[
                      {
                        required: true,
                      },
                    ]}
                  >
                    <Select>
                      <Option key={'MYR'} value={'MYR'}>
                        <div>
                          <Text>MYR</Text>
                        </div>
                      </Option>
                      <Option key={'USD'} value={'USD'}>
                        <div>
                          <Text>USD</Text>
                        </div>
                      </Option>
                    </Select>
                  </Form.Item>
                </Col>
              </Row>

              {process.env.REACT_APP_ENABLE_MULTI_CURRENCY === 'true' && (
                <Row>
                  <Col xs={24} lg={12}>
                    <Row align={'middle'}>
                      <Text
                        strong
                        style={{
                          marginRight: '5px',
                        }}
                      >
                        * Display Currency
                      </Text>
                      <Tooltip title="This is the currency that display to your Fans, they will pay to you based on this currency.">
                        <Icons.InfoCircleOutlined />
                      </Tooltip>
                    </Row>
                  </Col>
                  <Col xs={24} lg={12}>
                    <Form.Item name={['channel', 'currencyCode']} rules={[{ required: true }]}>
                      <Select>
                        <Option key={'MYR'} value={CurrencyCode.Myr}>
                          <div>
                            <Text>MYR</Text>
                          </div>
                        </Option>
                        <Option key={'USD'} value={CurrencyCode.Usd}>
                          <div>
                            <Text>USD</Text>
                          </div>
                        </Option>
                      </Select>
                    </Form.Item>
                  </Col>
                </Row>
              )}
            </Col>
          </Row>
        </Form>
      )}
    </Edit>
  );
};
