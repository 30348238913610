import React from 'react';
import { Button, Col, Form, FormInstance, Icons, Input, Modal, Row, Select } from '@pankod/refine-antd';
import { useCreate, useList, useUpdate } from '@pankod/refine-core';
import { AliyunOSSUpload } from 'components/upload';
import { IPlaylist } from 'interfaces';

export const UpsertPlaylistDialog: React.FC<{
  videoIds: React.Key[];
  onSuccess: () => void;
}> = ({ videoIds, onSuccess }) => {
  const [pagination, setPagination] = React.useState({
    current: 1,
    pageSize: 20,
  });

  const queryResult = useList<IPlaylist>({
    resource: 'playlists',
    metaData: {
      operation: 'getMyPlaylists',
      items: ['id', 'name'],
      metadata: ['currentPage', 'perPage', 'totalCount', 'totalPages'],
    },
    config: {
      pagination: {
        current: pagination.current,
        pageSize: pagination.pageSize,
      },
    },
  });
  const playlistOptions = (queryResult?.data?.data || []).map(({ id, name }) => ({
    value: id,
    label: name,
  }));

  const [isCreate, setIsCreate] = React.useState(false);
  const toCreate = () => setIsCreate(true);

  const [isModalVisible, setIsModalVisible] = React.useState(false);
  const showModal = () => setIsModalVisible(true);
  const closeModal = () => {
    setIsModalVisible(false);
    setIsCreate(false);
  };

  const [form] = Form.useForm<any>();

  return (
    <>
      <Button type="primary" icon={<Icons.PlusSquareOutlined />} onClick={showModal} disabled={videoIds.length === 0}>
        Add to playlist
      </Button>

      <Modal
        title={'Add to playlist'}
        visible={isModalVisible}
        onCancel={closeModal}
        footer={[
          isCreate ? (
            <Button
              key="create"
              type="primary"
              onClick={() => {
                form.submit();
              }}
            >
              Create and Save into playlist
            </Button>
          ) : (
            <Button
              key="save"
              type="primary"
              onClick={() => {
                form.submit();
              }}
            >
              Save into playlist
            </Button>
          ),
          <Button
            key="back"
            onClick={() => {
              closeModal();
            }}
          >
            Close
          </Button>,
        ]}
      >
        <Row gutter={[10, 10]}>
          {isCreate ? (
            <Col span={24}>
              <NewPlaylistForm
                form={form}
                videoIds={videoIds}
                onSuccess={() => {
                  onSuccess();
                  closeModal();
                }}
              />
            </Col>
          ) : (
            <>
              <Col span={24}>
                <SaveIntoPlaylistForm
                  form={form}
                  videoIds={videoIds}
                  options={playlistOptions}
                  onSuccess={() => {
                    onSuccess();
                    closeModal();
                  }}
                />
              </Col>
              <Col span={24}>
                <Button onClick={toCreate}>Create New Playlist</Button>
              </Col>
            </>
          )}
        </Row>
      </Modal>
    </>
  );
};

const NewPlaylistForm: React.FC<{
  form: FormInstance;
  videoIds: React.Key[];
  onSuccess: () => void;
}> = ({ form, videoIds, onSuccess }) => {
  const { mutate, isSuccess } = useCreate();

  React.useEffect(() => {
    form.setFieldsValue({});
    return () => {
      form.resetFields();
    };
  }, [form]);

  React.useEffect(() => {
    if (isSuccess) {
      onSuccess();
    }
  }, [isSuccess, onSuccess]);

  return (
    <Form
      form={form}
      layout="vertical"
      onFinish={(values) => {
        mutate({
          resource: 'playlist',
          metaData: {
            operation: 'createMyPlaylist',
            operationType: 'MyPlaylistCreateInput',
          },
          values: {
            ...values,
            playlistVideos: {
              createMany: {
                data: videoIds.map((videoId) => ({
                  videoId,
                })),
              },
            },
          },
        });
      }}
    >
      <Form.Item label="Name" name="name" rules={[{ required: true }]}>
        <Input />
      </Form.Item>
      <Form.Item label="Description" name="description">
        <Input />
      </Form.Item>
      <Form.Item label={'Thumbnail'} name="thumbnailUrl" rules={[{ required: false }]}>
        <AliyunOSSUpload
          purpose="PlaylistThumbnail"
          accept="image/*"
          description="Extension Allowed: .jpg/.jpeg/.png<br>Recommended Dimension: 1280 x 720 pixels"
          onChange={(response) => {
            form.setFieldsValue({
              thumbnailUrl: response.url,
            });
          }}
        />
      </Form.Item>
    </Form>
  );
};

const SaveIntoPlaylistForm: React.FC<{
  form: FormInstance;
  videoIds: React.Key[];
  options: { label: string; value: string }[];
  onSuccess: () => void;
}> = ({ form, videoIds, options, onSuccess }) => {
  const { mutate, isSuccess } = useUpdate();

  React.useEffect(() => {
    form.setFieldsValue({});
    return () => {
      form.resetFields();
    };
  }, [form]);

  React.useEffect(() => {
    if (isSuccess) {
      onSuccess();
    }
  }, [isSuccess, onSuccess]);

  const [value, setValue] = React.useState('');
  const handleChange = (value: string) => setValue(value);

  return (
    <Form
      form={form}
      layout="vertical"
      onFinish={(value) => {
        mutate({
          id: value.playlistId,
          resource: 'playlists',
          values: {
            playlistVideos: {
              connectOrCreate: videoIds.map((videoId) => {
                return {
                  where: {
                    videoId_playlistId: {
                      videoId,
                      playlistId: value.playlistId,
                    },
                  },
                  create: {
                    video: {
                      connect: {
                        id: videoId,
                      },
                    },
                  },
                };
              }),
            },
          },
        });
      }}
    >
      <Form.Item label="Playlist" name="playlistId" rules={[{ required: true }]}>
        <Select defaultValue={value} style={{ width: '100%' }} onChange={handleChange}>
          {options.map((option, index) => (
            <Select.Option key={`options${index}`} value={option.value}>
              {option.label}
            </Select.Option>
          ))}
        </Select>
      </Form.Item>
    </Form>
  );
};
