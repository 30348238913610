import { IResourceComponentsProps } from "@pankod/refine-core";
import { Typography, Tooltip, Collapse, Icons } from "@pankod/refine-antd";
import { List } from "antd";
const { Panel } = Collapse;

export const HelpCenter: React.FC<IResourceComponentsProps> = () => {
  return (
    <>
      <h1>Help Center</h1>
      <Collapse className="help-center__panels" defaultActiveKey={["1"]}>
        <Panel header="Creator Guide Book" key="1">
          Please refer to the guidebook{" "}
          <Typography.Link
            href={"https://rb.gy/1vluis"}
            target="_blank"
            rel="noopener  noreferrer" // security measure
            style={{
              color: "#fa3532",
            }}
          >
            here
          </Typography.Link>
          .
        </Panel>
        <Panel header="Tutorial" key="2">
          Coming Soon
        </Panel>
        <Panel header="FAQ" key="3">
          Please refer to the FAQ{" "}
          <Typography.Link
            href={"https://rb.gy/owtjpe"}
            target="_blank"
            rel="noopener  noreferrer" // security measure
            style={{
              color: "#fa3532",
            }}
          >
            here
          </Typography.Link>
          .
        </Panel>
        <Panel header="Terms of Use" key="4">
          <ul style={{ listStyleType: "decimal" }}>
            <li style={{ marginBottom: "8px" }}>
              <Typography.Link
                href={`${
                  process.env.REACT_APP_H5_API_BASE_URL || ""
                }/terms-of-use/user-terms`}
                target="_blank"
                rel="noopener  noreferrer" // security measure
                style={{
                  color: "#fa3532",
                }}
              >
                Terms of Use for All Users
              </Typography.Link>
            </li>
            <li style={{ marginBottom: "8px" }}>
              <Typography.Link
                href={`${
                  process.env.REACT_APP_H5_API_BASE_URL || ""
                }/terms-of-use/creator-terms`}
                target="_blank"
                rel="noopener  noreferrer" // security measure
                style={{
                  color: "#fa3532",
                }}
              >
                Terms of Use for Creators
              </Typography.Link>
            </li>
            <li style={{ marginBottom: "8px" }}>
              <Typography.Link
                href={`${
                  process.env.REACT_APP_H5_API_BASE_URL || ""
                }/terms-of-use/acceptable-policy`}
                target="_blank"
                rel="noopener  noreferrer" // security measure
                style={{
                  color: "#fa3532",
                }}
              >
                Acceptable Use Policy
              </Typography.Link>
            </li>
            <li style={{ marginBottom: "8px" }}>
              <Typography.Link
                href={`${
                  process.env.REACT_APP_H5_API_BASE_URL || ""
                }/terms-of-use/standard-contract`}
                target="_blank"
                rel="noopener  noreferrer" // security measure
                style={{
                  color: "#fa3532",
                }}
              >
                Standard Contract Between Fan and Creator
              </Typography.Link>
            </li>
            <li style={{ marginBottom: "8px" }}>
              <Typography.Link
                href={`${
                  process.env.REACT_APP_H5_API_BASE_URL || ""
                }/privacy-policy`}
                target="_blank"
                rel="noopener  noreferrer" // security measure
                style={{
                  color: "#fa3532",
                }}
              >
                Privacy Policy
              </Typography.Link>
            </li>
          </ul>
        </Panel>
      </Collapse>
    </>
  );
};
