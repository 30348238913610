import { useEffect, useState } from 'react';
import { Button, Col, Form, Input, Modal, Row, message } from '@pankod/refine-antd';
import { useCustom, useTranslate } from '@pankod/refine-core';
import { dataProvider } from 'App';
import { VerificationPurpose } from 'api';
import { IUser } from 'interfaces';
import { simplifyErrorMessage } from 'libs';


export interface IVerifyEmailFormForm {
  otpToken: string;
}

export const VerifyEmailDialog: React.FC<{
  open: boolean;
  setOpen: (value: boolean) => void;
  onSuccess?: () => void;
}> = ({ open, setOpen, onSuccess }) => {
  const t = useTranslate();
  const [isResend, setIsResend] = useState(false);
  const [nextOTPRequestDate, setNextOTPRequestDate] = useState('');
  const [countdownSeconds, setCountdownSeconds] = useState(0);
  const [email, setEmail] = useState('');
  const [form] = Form.useForm<IVerifyEmailFormForm>();

  const queryResult = useCustom<IUser>({
    url: '',
    method: 'get',
    metaData: {
      operation: 'me',
      fields: ['id', 'email'],
    },
  });
  const { data } = queryResult;
  useEffect(() => {
    if (data?.data.email) {
      setEmail(data?.data.email);
    }
  }, [data, setEmail]);

  const onSubmit = async (values: IVerifyEmailFormForm) => {
    try {
      const { data } = await dataProvider.custom!({
        url: '',
        method: 'post',
        metaData: {
          operation: 'verifyOtp',
          variables: {
            data: {
              value: {
                email,
                otpCode: values.otpToken,
                purpose: VerificationPurpose.EmailVerification,
              },
              type: 'VerifyOtpInput',
              required: true,
            },
          },
          fields: ['success'],
        },
      });

      if (data.success) {
        message.success(t('pages.editProfile.verifyEmail.verifyEmailSuccess'));
        onSuccess();
      }
    } catch (error) {
      const errorMessage = simplifyErrorMessage(error);
      if (errorMessage === 'OTP_TOKEN_INVALID_ERROR') {
        message.error('OTP token is invalid');
      } else {
        message.error(errorMessage || t('pages.editProfile.verifyEmail.requestOtpFailed'));
      }

      form.resetFields();
    }
  };

  const onRequestOTP = async () => {
    try {
      const { data } = await dataProvider.custom!({
        url: '',
        method: 'post',
        metaData: {
          operation: 'requestOtp',
          variables: {
            data: {
              value: {
                email,
                purpose: VerificationPurpose.EmailVerification,
                isResend,
              },
              type: 'RequestOtpInput',
              required: true,
            },
          },
          fields: ['success', 'nextRequestOn'],
        },
      });

      if (data.success) {
        setIsResend(true);
        setNextOTPRequestDate(data.nextRequestOn);
        message.success(`Sent OTP to ${email}!`);
      }
    } catch (error) {
      const errorMessage = simplifyErrorMessage(error);
      message.error(errorMessage || 'Request OTP failed!');
      return;
    }
  };

  useEffect(() => {
    if (nextOTPRequestDate) {
      const updateTimer = setInterval(() => {
        const nowTs = new Date().getTime();
        const nextRequestTs = new Date(nextOTPRequestDate).getTime();
        const seconds = Math.floor((nextRequestTs - nowTs) / 1000);

        setCountdownSeconds(seconds);

        if (seconds <= 0) {
          setNextOTPRequestDate('');
        }
      }, 1000);

      return () => {
        clearInterval(updateTimer);
      };
    }
  }, [nextOTPRequestDate]);

  return (
    <Modal
      title={'Verify Email'}
      visible={open}
      onCancel={() => setOpen(false)}
      footer={[
        <Button
          key="submit"
          type="primary"
          onClick={() => {
            form.submit();
          }}
        >
          {t('buttons.submit')}
        </Button>,
        <Button
          key="back"
          onClick={() => {
            form.resetFields();
            setOpen(false);
          }}
        >
          {t('buttons.close')}
        </Button>,
      ]}
    >
      <Form<IVerifyEmailFormForm>
        form={form}
        layout="vertical"
        onFinish={(values) => {
          onSubmit({
            otpToken: values.otpToken,
          });
          setOpen(false);
        }}
        initialValues={{
          otpToken: '',
        }}
      >
        <Form.Item label={t('pages.forgotPassword.otp', 'OTP')} name="otpToken">
          <Row gutter={8}>
            <Col span={16}>
              <Form.Item
                noStyle
                rules={[
                  {
                    required: true,
                    message: t('pages.editProfile.verifyEmail.otpInputRequiredMessage'),
                  },
                ]}
                validateTrigger="onBlur"
              >
                <Input placeholder="OTP" size="large"/>{' '}
                <span className="text-gray-500 text-xs">{t('pages.editProfile.verifyEmail.otpInputDescription')}</span>
              </Form.Item>
            </Col>
            <Col span={8}>
              <Button
                className={`w-full text-white rounded-md ${
                  nextOTPRequestDate !== '' ? 'opacity-50 cursor-not-allowed p-0' : ''
                }`}
                size="large"
                type="primary"
                onClick={onRequestOTP}
                disabled={nextOTPRequestDate !== ''}
              >
                {t('buttons.request')}{countdownSeconds > 0 && `(${countdownSeconds}s)`}
              </Button>
            </Col>
          </Row>
        </Form.Item>
      </Form>
    </Modal>
  );
};