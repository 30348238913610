import React, { useEffect } from 'react';
import {
  Button,
  DateField,
  DeleteButton,
  Icons,
  ImageField,
  List,
  Space,
  Switch,
  Table,
  useModalForm,
  useTable,
} from '@pankod/refine-antd';
import { CrudSort, HttpError, IResourceComponentsProps, useCustom, useTranslate, useUpdate } from '@pankod/refine-core';
import { VirtualGiftCreateForm } from './components/create';
import { VirtualGiftEditForm } from './components/edit';
import { useToggleChannelGift } from './hooks/use-toggle-channel-gift';
import { PAGINATION_FIELDS, RESOURCE_FIELDS, RESOURCE_NAME } from './constant';
import { IUser, IVirtualGift } from 'interfaces';

export const VirtualGiftingList: React.FC<IResourceComponentsProps> = () => {
  const t = useTranslate();
  const initialSorter: CrudSort[] = [{ field: 'id', order: 'asc' }];
  const initialPageSize = 10;

  const {
    data: channelData,
    isLoading,
    isSuccess,
    refetch,
  } = useCustom<IUser>({
    url: '',
    method: 'get',
    metaData: {
      operation: 'me',
      fields: [{ channel: ['id', 'isGiftEnabled', 'currencyCode'] }],
    },
  });

  const isCompletedLoad = isSuccess && !isLoading;
  const canShowList = isCompletedLoad && channelData?.data?.channel?.isGiftEnabled;

  const { tableProps } = useTable<IVirtualGift, HttpError>({
    resource: RESOURCE_NAME,
    metaData: {
      operation: 'listMyGifts',
      items: [...RESOURCE_FIELDS],
      metadata: [...PAGINATION_FIELDS],
    },
    initialSorter,
    initialPageSize,
  });

  const { toggle, isSuccess: isUpdated } = useToggleChannelGift({
    channelId: channelData?.data.channel.id,
  });
  useEffect(() => {
    if (isUpdated) {
      refetch();
    }
  }, [refetch, isUpdated]);

  const { show: showCreate, ...createFromProps } = useModalForm({
    action: 'create',
    resource: RESOURCE_NAME,
    metaData: {
      operation: 'createMyGift',
      operationType: 'CreateMyGiftInput',
    },
    autoSubmitClose: true,
    redirect: false,
  });

  const { show: showEdit, ...editFromProps } = useModalForm({
    action: 'edit',
    resource: RESOURCE_NAME,
    metaData: {
      fields: [...RESOURCE_FIELDS],
    },
    autoSubmitClose: true,
    redirect: false,
  });

  // reset modal form on close
  useEffect(() => {
    if (createFromProps.modalProps.visible) {
      createFromProps.form.resetFields();
    }
  }, [createFromProps]);

  // format to cents
  useEffect(() => {
    if (editFromProps?.queryResult?.data?.data) {
      editFromProps.form.setFieldsValue({
        price: Math.round(editFromProps.queryResult?.data?.data?.price / 100),
      });
    }
  }, [editFromProps]);

  return (
    <>
      <h1>Virtual Gifting</h1>

      <VirtualGiftCreateForm
        currencyCode={channelData?.data?.channel?.currencyCode}
        modalProps={createFromProps.modalProps}
        formProps={createFromProps.formProps}
      />

      <VirtualGiftEditForm
        values={editFromProps.queryResult?.data}
        modalProps={editFromProps.modalProps}
        formProps={editFromProps.formProps}
        currencyCode={channelData?.data?.channel?.currencyCode}
      />

      <Space direction="horizontal" size="large">
        <p>Enable/Disable Virtual Gifting in Your Channel:</p>
        {isCompletedLoad && (
          <Switch
            defaultChecked={channelData?.data?.channel?.isGiftEnabled}
            onChange={toggle}
            style={{ marginBottom: '16px' }}
          />
        )}
      </Space>

      {canShowList && (
        <List
          title=""
          pageHeaderProps={{
            breadcrumb: <Space></Space>,
            // extra: <Space></Space>,
          }}
          createButtonProps={{
            onClick: () => showCreate(),
          }}
        >
          <Table {...tableProps} rowKey="id">
            <Table.Column<IVirtualGift>
              dataIndex="thumbnailUrl"
              title={'Icon'}
              render={(_, record) => (
                <ImageField
                  value={record.thumbnailUrl}
                  title={record.thumbnailUrl}
                  width={100}
                  height={100}
                  style={{ objectFit: 'contain' }}
                />
              )}
            />
            <Table.Column dataIndex="name" title={'Name'} />
            <Table.Column dataIndex="displayPrice" title={`Price (${channelData?.data?.channel?.currencyCode})`} />
            <Table.Column<IVirtualGift>
              dataIndex="updatedAt"
              title={'Updated On'}
              render={(record) => <DateField format="LLL" value={record} />}
            />
            <Table.Column<IVirtualGift>
              width="5%"
              key="actions"
              title={t('table.actions')}
              dataIndex="actions"
              render={(_, record) => (
                <Space>
                  <Button size="small" icon={<Icons.EditFilled />} onClick={() => showEdit(record.id)} />
                  <DeleteButton resourceName={RESOURCE_NAME} hideText size="small" recordItemId={record.id} />
                </Space>
              )}
            />
          </Table>
        </List>
      )}
    </>
  );
};
