export const RESOURCE_NAME = "subscription_plans";
export const RESOURCE_LIST_NAME = "monetization/subscription";
export const RESOURCE_FIELDS = [
  "id",
  "name",
  "description",
  "duration",
  "price",
  "archivedAt",
  "createdAt",
  "updatedAt",
] as const;
export const PAGINATION_FIELDS = [
  "currentPage",
  "perPage",
  "totalCount",
  "totalPages",
] as const;
