import { BiMoviePlay } from 'react-icons/bi';
import { FaCoins } from 'react-icons/fa';
import { IoSettingsSharp } from 'react-icons/io5';
import { MdOutlineGroup } from 'react-icons/md';
import { TbListCheck } from 'react-icons/tb';
import { Icons } from '@pankod/refine-antd';
import { AccountSettingEditPayout } from 'pages/account-setting/edit-payout';
import { AccountSettingEditProfile } from 'pages/account-setting/edit-profile';
import { HelpCenter } from 'pages/help-center/help-center';
import { NFTCommunityCreate } from 'pages/nft-community/create';
import { PersonalBrandPage } from 'pages/personal-brand';
import { Playlist } from 'pages/playlist';
import { PlaylistVideoList } from 'pages/playlist/video/list';
import { SubscriptionEdit, SubscriptionList } from 'pages/subscription';
import { VideoCreate, VideoEdit, VideoList } from 'pages/videos';
import { VirtualGiftingList } from 'pages/virtual-gifting';
import { withRouteGuard } from 'components/route-guard';

const role = 'user';

export const UserRoutes = [
  {
    key: 'my-channel',
    icon: <TbListCheck />,
    name: 'my-channel',
    options: {
      label: 'My Channel',
      role,
    },
    list: withRouteGuard(PersonalBrandPage, 'user', true),
  },
  {
    key: 'content',
    icon: <BiMoviePlay />,
    name: 'content',
    options: {
      label: 'Content Manager',
      role,
    },
  },
  {
    name: 'videos',
    parentName: 'content',
    options: { label: 'Content Library' },
    list: withRouteGuard(VideoList, 'user', true),
    create: withRouteGuard(VideoCreate, 'user', true),
    edit: withRouteGuard(VideoEdit, 'user', true),
  },
  {
    name: 'playlists',
    parentName: 'content',
    options: { label: 'Playlists' },
    list: withRouteGuard(Playlist, 'user', true),
    edit: withRouteGuard(PlaylistVideoList, 'user', true),
  },
  //
  {
    key: 'monetization',
    icon: <FaCoins />,
    name: 'monetization',
    options: {
      label: 'Monetization',
      role,
    },
  },
  {
    name: 'subscription',
    parentName: 'monetization',
    options: { label: 'Subscription Plans' },
    list: withRouteGuard(SubscriptionList, 'user', true),
    edit: withRouteGuard(SubscriptionEdit, 'user', true),
  },
  {
    name: 'virtual-gifting',
    parentName: 'monetization',
    options: { label: 'Virtual Gifting' },
    list: withRouteGuard(VirtualGiftingList, 'user', true),
  },
  {
    key: 'nft-community',
    icon: <MdOutlineGroup />,
    name: 'nft-community',
    options: {
      label: 'NFT Community',
      role,
    },
  },
  {
    name: 'create-community',
    parentName: 'nft-community',
    options: {
      label: 'Create Community',
    },
    list: NFTCommunityCreate,
  },
  // Help Center
  {
    key: 'help-center',
    name: 'help-center',
    options: {
      label: 'Help Center',
      role,
    },
    icon: <Icons.ToolOutlined />,
    list: withRouteGuard(HelpCenter, 'user'),
  },
  // Account Settings
  {
    key: 'account-settings',
    icon: <IoSettingsSharp />,
    name: 'account-settings',
    options: {
      label: 'Account Settings',
      role,
    },
  },
  {
    name: 'profile',
    parentName: 'account-settings',
    options: { label: 'Profile' },
    list: withRouteGuard(AccountSettingEditProfile, 'user'),
  },
  {
    name: 'payout',
    parentName: 'account-settings',
    options: { label: 'Payout' },
    list: withRouteGuard(AccountSettingEditPayout, 'user', true),
  },
];
