import React, { useState } from 'react';
import { DateField, Icons } from '@pankod/refine-antd';
import { Button, List, Popconfirm, Space, Typography } from 'antd';
import { useListComments, useSoftDeleteComment } from 'hooks';
import { IComment } from 'interfaces';

export const CommentList: React.FC<{ recordId: string }> = ({ recordId }) => {
  const [pagination, setPagination] = useState({
    current: 1,
    pageSize: 20,
  });

  const queryResult = useListComments({
    filters: [
      {
        field: 'videoId',
        operator: 'eq',
        value: recordId,
      },
      {
        field: 'archivedAt',
        operator: 'null',
        value: '',
      },
    ],
    pagination,
  });

  const { softDelete } = useSoftDeleteComment({
    onSuccess: () => {
      queryResult.refetch();
    },
  });

  const comments = queryResult?.data?.data || [];

  return (
    <List<IComment>
      itemLayout="vertical"
      size="large"
      pagination={{
        onChange: (current, pageSize) => {
          setPagination({
            ...pagination,
            current,
            pageSize,
          });
        },
        total: queryResult?.data?.total || 0,
        pageSize: pagination.pageSize,
      }}
      dataSource={comments}
      renderItem={(item) => (
        <List.Item
          key={item.id}
          actions={[
            <Space>
              <Typography.Text>{item?.user?.username}</Typography.Text>
              <DateField value={item?.createdAt} format="L" />
              <Popconfirm
                title="Delete this comment?"
                onConfirm={() => {
                  softDelete(item.id);
                }}
                onCancel={() => {}}
                okText="Yes"
                cancelText="No"
              >
                <Button danger={true} size="small" icon={<Icons.DeleteOutlined />} />
              </Popconfirm>
            </Space>,
          ]}
        >
          {item.content || '-'}
        </List.Item>
      )}
    />
  );
};
