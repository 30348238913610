import { HttpError, useNotification, useTranslate } from '@pankod/refine-core';
import { dataProvider } from 'App';
import React from 'react';
import { RESOURCE_FIELDS, RESOURCE_NAME } from '../constant';

export const useUpdateMyChannel = () => {
  const t = useTranslate();
  const { open } = useNotification();
  const [isUpdating, setIsUpdating] = React.useState(false);

  const updateSite = async (values) => {
    try {
      setIsUpdating(true);

      await dataProvider.custom!({
        url: '',
        method: 'post',
        metaData: {
          operation: 'updateMyChannel',
          variables: {
            data: {
              value: { ...values },
              type: 'ChannelUpdateInput',
              required: true,
            },
          },
          fields: [...RESOURCE_FIELDS],
        },
      });
      open({
        key: `success` + Date.now(),
        type: 'success',
        description: t('notifications.success'),
        message: t('notifications.editSuccess', {
          resource: RESOURCE_NAME,
        }),
      });
    } catch (error) {
      open({
        key: `error` + Date.now(),
        type: 'error',
        description: t('notifications.error', {
          // ts-ignore
          statusCode: (error as HttpError).statusCode || 'unknown',
        }),
        message: error as any,
      });
    } finally {
      setIsUpdating(false);
    }
  };

  return {
    isUpdating,
    updateSite,
  };
};
