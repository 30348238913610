import { Modal, Progress, Spin } from '@pankod/refine-antd';

export const useUploadVideoDialog = () => {
  let modal = null;

  const showUploading = () => {
    modal = Modal.info({
      icon: null,
      closable: false,
      keyboard: false,
      okButtonProps: {
        style: { display: 'none' },
      },
      title: 'Uploading your video...',
      content: (
        <div>
          <p>Please don't close this page until the video is uploaded.</p>
        </div>
      ),
    });
  };

  const uploadProgress = (percentage: number) => {
    modal.update({
      content: (
        <div>
          <p>Please don't close this page until the video is uploaded.</p>
          <Progress percent={percentage || 0} />
        </div>
      ),
    });
  };

  const uploadDismiss = () => {
    modal.destroy();
  };

  return {
    showUploading,
    uploadProgress,
    uploadDismiss,
  };
};
