import React from "react";
import { Button, Modal, Form, Input } from "antd";

interface MediaLink {
  key: string;
  title: string;
  url: string;
}

export const MediaForm: React.FC<{
  modalVisible: boolean;
  values?: MediaLink;
  onClose: () => void;
  onSubmit: (data: Omit<MediaLink, "key">) => void;
}> = ({ values, modalVisible, onClose, onSubmit }) => {
  const [key, setKey] = React.useState("");
  const [form] = Form.useForm<Omit<MediaLink, "key">>();
  const isEdit = !!values;

  // reset form on close
  React.useEffect(() => {
    if (modalVisible && isEdit) {
      form.setFieldsValue({ ...values });
      setKey(Date.now().toString());
    } else {
      form.resetFields();
    }
  }, [form, isEdit, modalVisible, values]);

  return (
    <Modal
      title={(isEdit ? "Edit" : "Add") + " Media Link"}
      visible={modalVisible}
      onCancel={onClose}
      footer={[
        <Button
          key="submit"
          type="primary"
          onClick={() => {
            form.submit();
          }}
        >
          Submit
        </Button>,
        <Button key="back" onClick={onClose}>
          Close
        </Button>,
      ]}
    >
      <Form
        form={form}
        layout="vertical"
        onFinish={(values) => {
          onSubmit({
            title: values.title,
            url: values.url,
          });
          onClose();
        }}
      >
        <Form.Item label={"Title"} name="title" rules={[{ required: true }]}>
          <Input />
        </Form.Item>

        <Form.Item
          label={"URL"}
          name="url"
          rules={[{ required: true }, { type: "url" }]}
        >
          <Input placeholder="https://example.com" />
        </Form.Item>
      </Form>
    </Modal>
  );
};
