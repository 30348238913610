import { ChannelManagementList } from 'pages/channel-management';
import { TransactionList } from 'pages/transaction';
import { VideoManagementList } from 'pages/video-management';
import { VideoEdit } from 'pages/videos';
import { WatchHistoryList } from 'pages/watch-history';
import { withRouteGuard } from 'components/route-guard';

const role = 'admin';

export const AdminRoutes = [
  {
    key: 'management',
    name: 'management',
    options: {
      label: 'Management',
      role,
    },
  },
  {
    name: 'videos-management',
    parentName: 'management',
    options: { label: 'Video' },
    list: withRouteGuard(VideoManagementList, 'admin'),
    edit: VideoEdit,
  },
  {
    name: 'channels-management',
    parentName: 'management',
    options: { label: 'Channel' },
    list: withRouteGuard(ChannelManagementList, 'admin'),
  },
  {
    key: 'report',
    name: 'report',
    options: {
      label: 'Report',
      role,
    },
  },
  {
    name: 'transactions',
    parentName: 'report',
    options: { label: 'Transaction' },
    list: withRouteGuard(TransactionList, 'admin'),
  },
  {
    name: 'view',
    parentName: 'report',
    options: { label: 'View' },
    list: withRouteGuard(WatchHistoryList, 'admin'),
  },
];
