import React from 'react';
import {
  Breadcrumb,
  Button,
  Divider,
  Icons,
  ImageField,
  List,
  Space,
  Table,
  useModalForm,
  useTable,
} from '@pankod/refine-antd';
import { CrudSort, HttpError, IResourceComponentsProps, useNavigation, useTranslate } from '@pankod/refine-core';
import { EditDialog } from './components/edit-dialog';
import { useDeletePlaylist } from './hooks/use-delete-playlist';
import { PAGINATION_FIELDS, RESOURCE_FIELDS, RESOURCE_NAME } from './constant';
import { IPlaylist } from 'interfaces';

export const Playlist: React.FC<IResourceComponentsProps> = () => {
  const t = useTranslate();
  const initialSorter: CrudSort[] = [{ field: 'createdAt', order: 'desc' }];
  const initialPageSize = 10;

  const { tableProps, tableQueryResult } = useTable<IPlaylist, HttpError>({
    resource: RESOURCE_NAME,
    metaData: {
      operation: 'getMyPlaylists',
      items: [...RESOURCE_FIELDS],
      metadata: [...PAGINATION_FIELDS],
    },
    initialSorter,
    initialPageSize,
  });

  const [selectedRowKeys, setSelectedRowKeys] = React.useState<React.Key[]>([]);

  const { formProps, modalProps, show } = useModalForm({
    resource: RESOURCE_NAME,
    metaData: {
      fields: ['name', 'description', 'thumbnailUrl'],
    },
    action: 'edit',
    redirect: false,
  });

  const { edit } = useNavigation();

  const { deleteMany } = useDeletePlaylist({
    onSuccess: () => {
      tableQueryResult.refetch();
    },
  });

  const Actions: React.FC = () => <Space></Space>;

  return (
    <List
      pageHeaderProps={{
        breadcrumb: <Breadcrumb hideIcons />,
        extra: <Actions />,
      }}
    >
      <span>Only released videos can be added into playlist</span>
      <EditDialog modalProps={modalProps} formProps={formProps} />

      <Button
        type="primary"
        className="mt-4"
        onClick={() => {
          deleteMany({
            playlistIds: selectedRowKeys.map((id) => id),
          });
        }}
        disabled={!(selectedRowKeys?.length > 0)}
      >
        Delete Playlist
      </Button>

      <Divider />

      <Table
        {...tableProps}
        rowSelection={{
          selectedRowKeys,
          onChange: (newSelectedRowKeys: React.Key[], record) => {
            setSelectedRowKeys(newSelectedRowKeys);
          },
        }}
        rowKey="id"
      >
        <Table.Column<IPlaylist>
          dataIndex="thumbnailUrl"
          title={'Thumbnail'}
          render={(_, record) => (
            <ImageField value={record?.thumbnailUrl || ''} title={record?.thumbnailUrl || ''} width={150} />
          )}
        />
        <Table.Column dataIndex="name" title={'Playlist Name'} />
        <Table.Column dataIndex="description" title={'Playlist Description'} />

        <Table.Column<IPlaylist>
          width="5%"
          key="actions"
          title={t('table.actions')}
          dataIndex="actions"
          render={(_, record) => (
            <Space>
              <Button size="small" icon={<Icons.EditFilled />} onClick={() => show(record.id)} />
              <Button
                size="small"
                icon={<Icons.EyeOutlined />}
                onClick={() => {
                  edit(RESOURCE_NAME, record.id);
                }}
              />
            </Space>
          )}
        />
      </Table>
    </List>
  );
};
