const icons = [
  // basic 2.0 (added 11/02/2022)
  "https://colorpixel.oss-ap-southeast-3.aliyuncs.com/PrestoColor%20Virtual%20Gifting%20Feb%2022-01.png",
  "https://colorpixel.oss-ap-southeast-3.aliyuncs.com/PrestoColor%20Virtual%20Gifting%20Feb%2022-02.png",
  "https://colorpixel.oss-ap-southeast-3.aliyuncs.com/PrestoColor%20Virtual%20Gifting%20Feb%2022-03.png",
  "https://colorpixel.oss-ap-southeast-3.aliyuncs.com/PrestoColor%20Virtual%20Gifting%20Feb%2022-04.png",
  "https://colorpixel.oss-ap-southeast-3.aliyuncs.com/PrestoColor%20Virtual%20Gifting%20Feb%2022-05.png",
  "https://colorpixel.oss-ap-southeast-3.aliyuncs.com/PrestoColor%20Virtual%20Gifting%20Feb%2022-06.png",
  "https://colorpixel.oss-ap-southeast-3.aliyuncs.com/PrestoColor%20Virtual%20Gifting%20Feb%2022-07.png",
  "https://colorpixel.oss-ap-southeast-3.aliyuncs.com/PrestoColor%20Virtual%20Gifting%20Feb%2022-08.png",
  "https://colorpixel.oss-ap-southeast-3.aliyuncs.com/PrestoColor%20Virtual%20Gifting%20Feb%2022-09.png",
  "https://colorpixel.oss-ap-southeast-3.aliyuncs.com/PrestoColor%20Virtual%20Gifting%20Feb%2022-10.png",
  "https://colorpixel.oss-ap-southeast-3.aliyuncs.com/PrestoColor%20Virtual%20Gifting%20Feb%2022-11.png",
  "https://colorpixel.oss-ap-southeast-3.aliyuncs.com/PrestoColor%20Virtual%20Gifting%20Feb%2022-12.png",
  "https://colorpixel.oss-ap-southeast-3.aliyuncs.com/PrestoColor%20Virtual%20Gifting%20Feb%2022-13.png",
  "https://colorpixel.oss-ap-southeast-3.aliyuncs.com/PrestoColor%20Virtual%20Gifting%20Feb%2022-14.png",
  "https://colorpixel.oss-ap-southeast-3.aliyuncs.com/PrestoColor%20Virtual%20Gifting%20Feb%2022-15.png",
  "https://colorpixel.oss-ap-southeast-3.aliyuncs.com/PrestoColor%20Virtual%20Gifting%20Feb%2022-16.png",
  "https://colorpixel.oss-ap-southeast-3.aliyuncs.com/PrestoColor%20Virtual%20Gifting%20Feb%2022-17.png",
  "https://colorpixel.oss-ap-southeast-3.aliyuncs.com/PrestoColor%20Virtual%20Gifting%20Feb%2022-18.png",
  "https://colorpixel.oss-ap-southeast-3.aliyuncs.com/PrestoColor%20Virtual%20Gifting%20Feb%2022-19.png",
  "https://colorpixel.oss-ap-southeast-3.aliyuncs.com/PrestoColor%20Virtual%20Gifting%20Feb%2022-20.png",

  // CNY 2022
  "https://colorpixel.oss-ap-southeast-3.aliyuncs.com/virtual%20gifting-01-4c5e.png",
  "https://colorpixel.oss-ap-southeast-3.aliyuncs.com/virtual%20gifting-02-14c7.png",
  "https://colorpixel.oss-ap-southeast-3.aliyuncs.com/virtual%20gifting-03-499d.png",
  "https://colorpixel.oss-ap-southeast-3.aliyuncs.com/virtual%20gifting-04-5f0e.png",
  "https://colorpixel.oss-ap-southeast-3.aliyuncs.com/virtual%20gifting-05-a73d.png",
  "https://colorpixel.oss-ap-southeast-3.aliyuncs.com/virtual%20gifting-06-5042.png",
  "https://colorpixel.oss-ap-southeast-3.aliyuncs.com/virtual%20gifting-07-06fa.png",
  "https://colorpixel.oss-ap-southeast-3.aliyuncs.com/virtual%20gifting-08-e6a2.png",
  "https://colorpixel.oss-ap-southeast-3.aliyuncs.com/virtual%20gifting-09-5133.png",
  "https://colorpixel.oss-ap-southeast-3.aliyuncs.com/virtual%20gifting-10-9548.png",
  "https://colorpixel.oss-ap-southeast-3.aliyuncs.com/virtual%20gifting-11-d13f.png",
  "https://colorpixel.oss-ap-southeast-3.aliyuncs.com/virtual%20gifting-12-c7a2.png",
  "https://colorpixel.oss-ap-southeast-3.aliyuncs.com/virtual%20gifting-14-a82b.png",
  "https://colorpixel.oss-ap-southeast-3.aliyuncs.com/virtual%20gifting-15-ae78.png",
  "https://colorpixel.oss-ap-southeast-3.aliyuncs.com/virtual%20gifting-16-e244.png",
  "https://colorpixel.oss-ap-southeast-3.aliyuncs.com/virtual%20gifting-17-d62f.png",
  "https://colorpixel.oss-ap-southeast-3.aliyuncs.com/virtual%20gifting-19-16d1.png",
  "https://colorpixel.oss-ap-southeast-3.aliyuncs.com/virtual%20gifting-20-1a85.png",
  "https://colorpixel.oss-ap-southeast-3.aliyuncs.com/virtual%20gifting-21-30bd.png",
  "https://colorpixel.oss-ap-southeast-3.aliyuncs.com/virtual%20gifting-22-994a.png",
  "https://colorpixel.oss-ap-southeast-3.aliyuncs.com/virtual%20gifting-23-43da.png",
  "https://colorpixel.oss-ap-southeast-3.aliyuncs.com/virtual%20gifting-24-9a74.png",
  "https://colorpixel.oss-ap-southeast-3.aliyuncs.com/virtual%20gifting-25-7883.png",
  "https://colorpixel.oss-ap-southeast-3.aliyuncs.com/virtual%20gifting-26-1020.png",
  "https://colorpixel.oss-ap-southeast-3.aliyuncs.com/virtual%20gifting-27-ff46.png",

  // basic 1.0
  // "https://colorpixel.oss-ap-southeast-3.aliyuncs.com/balloon-28a1.png",
  // "https://colorpixel.oss-ap-southeast-3.aliyuncs.com/birthdaycake-f93a.png",
  // "https://colorpixel.oss-ap-southeast-3.aliyuncs.com/car-2b67.png",
  // "https://colorpixel.oss-ap-southeast-3.aliyuncs.com/champaign-8cc3.png",
  // "https://colorpixel.oss-ap-southeast-3.aliyuncs.com/diamond-8cc3.png",
  // "https://colorpixel.oss-ap-southeast-3.aliyuncs.com/fingerheart-100f.png",
  // "https://colorpixel.oss-ap-southeast-3.aliyuncs.com/flower-275a.png",
  // "https://colorpixel.oss-ap-southeast-3.aliyuncs.com/present-463c.png",
  // "https://colorpixel.oss-ap-southeast-3.aliyuncs.com/teddybear-5c61.png",
];

export default icons;
