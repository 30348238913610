import { Button, Col, Modal, Row, Typography } from '@pankod/refine-antd';
import { VideoStatus } from 'api';
import { IVideo } from 'interfaces';
import { convertSecondsToDuration } from 'libs';
import React from 'react';

const { Text, Paragraph } = Typography;

export const VideoDetailDialog: React.FC<{
  values: IVideo | null;
  close: () => void;
}> = ({ values, close }) => {
  if (!values) {
    return <></>;
  }

  return (
    <Modal
      title="Video Details"
      width={800}
      visible={!!values}
      onCancel={close}
      footer={[
        <Button key="back" onClick={close}>
          OK
        </Button>,
      ]}
    >
      <Row gutter={[16, 0]}>
        <Col span={12}>
          <Paragraph>
            <Text strong>Origin of Video: </Text>
            <Text>{'N/A'}</Text>
          </Paragraph>
          <Paragraph>
            <Text strong>Duration: </Text>
            <Text>{convertSecondsToDuration(values.duration || 0)}</Text>
          </Paragraph>
          <Paragraph>
            <Text strong>Status: </Text>
            <Text>{values.status}</Text>
          </Paragraph>
          <Paragraph>
            <Text strong> VO Language: </Text>
            <Text>{values.language}</Text>
          </Paragraph>
          <Paragraph>
            <Text strong>Available In Other Channel: </Text>
            <Text>{'N/A'}</Text>
          </Paragraph>
        </Col>

        <Col span={12}>
          <Paragraph>
            <Text strong>Genre: </Text>
            <Text>{values.genre}</Text>
          </Paragraph>
          <Paragraph>
            <Text strong>Audience: </Text>
            <Text>{values.rating}</Text>
          </Paragraph>
          <Paragraph>
            <Text strong>Broadcast Country: </Text>
            <Text>{'N/A'}</Text>
          </Paragraph>
          <Paragraph>
            <Text strong>Subtitle Language: </Text>
            <Text>{values.subtitleLanguage}</Text>
          </Paragraph>
          <Paragraph>
            <Text strong>Available In My Channel: </Text>
            <Text>{values.status === VideoStatus.Released ? 'Yes' : 'No'}</Text>
          </Paragraph>
        </Col>

        <Col span={24}>
          <Paragraph>
            <Text strong>Video Type: </Text>
            <Text>
              {values.isVertical ? 'Vertical' : 'Horizontal'} /{' '}
              {values.isPayPerView ? 'Pay-Per-View Video' : 'Non Pay-Per-View Video'}
            </Text>
          </Paragraph>
        </Col>

        <Col span={12}>
          <Paragraph>
            <Text strong>Product Url:</Text>
            <Text>{values.productUrl || ' N/A'} </Text>
          </Paragraph>
          <Paragraph>
            <Text strong>Full Description: </Text>
            <Text>{values.description}</Text>
          </Paragraph>
        </Col>
      </Row>
    </Modal>
  );
};
