import { Breadcrumb, Col, Create, Form, Input, InputNumber, Row } from '@pankod/refine-antd';
import { useTransactionLoadingDialog } from '../../components/web3/components/TransactionDialog';
import { useCommunityFactory } from '../../components/web3/hooks/use-community-factory';
import { CreateCommunityInput, ProviderRpcError } from '../../components/web3/type';
import { AliyunOSSUploadPreview } from 'components/upload/aliyun-oss-upload-preview';
import { NFTPreviewCard } from 'components/web3/components/NFTPreviewCard';
import { useWeb3Context } from 'components/web3/context';
import { TransactionReceiptStatus, rpcErrorCodes } from 'components/web3/enum';
import MaticIcon from 'assets/icon/MaticIcon';
import { ContractReceipt } from 'ethers';

export const NFTCommunityCreate = () => {
  const [createCommunityForm] = Form.useForm<CreateCommunityInput>();
  const nameValue = Form.useWatch('name', createCommunityForm);
  const { showLoading, loadingDismiss, showSuccess, showError, showConnectWallet } = useTransactionLoadingDialog();
  const { createCommunity, getUserCommunities, isUserChainValid } = useCommunityFactory();
  const { web3Provider } = useWeb3Context();

  const formSubmitHandler = async (values: CreateCommunityInput) => {
    if (!web3Provider) {
      showConnectWallet();
      return;
    }

    if (!(await isUserChainValid())) {
      showError('Please connect to Mumbai network');
      return;
    }

    showLoading();

    try {
      const transaction = await createCommunity(values);
      const receipt: ContractReceipt = await transaction.wait();

      if (receipt.status === TransactionReceiptStatus.SUCCESS) {
        loadingDismiss();
        const userCommunities = await getUserCommunities();
        showSuccess(userCommunities[userCommunities.length - 1]);
        return;
      }
    } catch (error) {
      if ((error as ProviderRpcError)?.code === rpcErrorCodes.userRejectedRequest) {
        showError('Please confirm the transaction to proceed.');
        return;
      }

      showError();
    }
  };

  return (
    <Create
      pageHeaderProps={{
        title: 'Create NFT Community',
        breadcrumb: <Breadcrumb hideIcons />,
      }}
      saveButtonProps={{
        icon: null,
        children: 'Create Now',
        onClick: () => createCommunityForm.submit(),
      }}
    >
      <Form
        form={createCommunityForm}
        layout="vertical"
        onFinish={(values: CreateCommunityInput) => {
          formSubmitHandler(values);
        }}
      >
        <Row gutter={[32, 24]} justify="start" align="top">
          <Col span={12}>
            <p className="font-bold">Community Information</p>

            <Row gutter={[0, 24]} wrap={true}>
              <Col xs={24} xl={5}>
                <Form.Item className="nft-logo-upload" label={'Logo'} name="logoUrl" rules={[{ required: true }]}>
                  <AliyunOSSUploadPreview
                    purpose="CommunityLogo"
                    accept="image/*"
                    onChange={(response) => {
                      console.log('logo url on changes', response);
                      createCommunityForm.setFieldsValue({ logoUrl: response.url });
                    }}
                  />
                </Form.Item>
              </Col>
              <Col xs={24} xl={19}>
                <Form.Item
                  className="nft-logo-upload"
                  label={'Cover Image'}
                  name="bannerUrl"
                  rules={[{ required: true }]}
                >
                  <AliyunOSSUploadPreview
                    purpose="CommunityBanner"
                    accept="image/*"
                    onChange={(response) => {
                      console.log('logo url on changes', response);
                      createCommunityForm.setFieldsValue({ bannerUrl: response.url });
                    }}
                    description={`Drop files here to upload`}
                  />
                </Form.Item>
              </Col>
            </Row>

            <Form.Item label={'Community Name'} name="name" rules={[{ required: true }]}>
              <Input placeholder="Enter a Community Name" />
            </Form.Item>

            <Form.Item label={'Community Introduction'} name="description" rules={[{ required: true }]}>
              <Input.TextArea placeholder="Enter Community Introduction" showCount maxLength={200} />
            </Form.Item>
          </Col>

          <Col span={12}>
            <p className="font-bold">NFT Community Card</p>

            <Form.Item label={'NFT Community Card Name'} name="symbol" rules={[{ required: true }]}>
              <Input placeholder="Enter NFT Community Card Name" />
            </Form.Item>

            <Form.Item label={'NFT Image'} name="nftImageURL" rules={[{ required: true }]}>
              <NFTPreviewCard
                onChange={(response) => {
                  console.log('logo url on changes', response);
                  createCommunityForm.setFieldsValue({ nftImageURL: response.url });
                }}
                communityName={nameValue}
              />
            </Form.Item>

            <Form.Item label={'NFT Quantity'} name="nftSupply" rules={[{ required: true }]}>
              <InputNumber placeholder="Enter NFT quantity" style={{ width: '100%' }} min={1} />
            </Form.Item>

            <Form.Item label={'NFT Pricing'} name="mintPrice" rules={[{ required: true }]}>
              <InputNumber
                addonBefore={<MaticIcon />}
                placeholder="Enter NFT price"
                style={{ width: '100%' }}
                min={0}
              />
            </Form.Item>
          </Col>
        </Row>
      </Form>
    </Create>
  );
};
