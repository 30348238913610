import { useCustom } from '@pankod/refine-core';
import { PAGINATION_FIELDS } from './constant';
import { IWatchHistoryFilterVariables, IWatchHistorySummary } from 'interfaces';

export const useGetWatchHistorySummary = (values: IWatchHistoryFilterVariables) => {
  const response = useCustom<{ items: IWatchHistorySummary[] }>({
    url: '',
    method: 'get',
    metaData: {
      operation: 'getWatchHistorySummary',
      fields: [
        { items: ['frequency', 'totalViewCount', 'totalVideoCount', 'date'] },
        { metadata: [...PAGINATION_FIELDS] },
      ],
      variables: {
        where: {
          value: {
            frequency: values.frequency,
            channelId: values?.channelId || null,
            dateFrom: values?.searchDates[0] || null,
            dateTo: values?.searchDates[1] || null,
          },
          type: 'WatchHistorySummaryWhereInput',
          required: true,
        },
      },
    },
  });

  const items = response?.data?.data?.items || [{ totalViewCount: 0, totalVideoCount: 0 }];

  return {
    totalViewCount: items[0].totalViewCount,
    totalVideoCount: items[0].totalVideoCount,
  };
};
