import React, { useState } from 'react';
import { useCustom } from '@pankod/refine-core';
import { Select } from 'antd';
import { IChannel } from 'interfaces';
import { debounce } from 'lodash';

const { Option } = Select;

interface SearchInputProps {
  placeholder: string;
  value?: string;
  onChange?: (value: string) => void;
}

export const SearchInput: React.FC<SearchInputProps> = (props) => {
  const [value, setValue] = useState<string>();

  let queryResult = useCustom<IChannel[]>({
    url: '',
    method: 'get',
    metaData: {
      operation: 'channels',
      fields: ['id', 'name'],
      variables: {
        where: {
          value: {
            name: {
              contains: value,
              mode: 'insensitive',
            },
          },
          type: 'ChannelWhereInput',
          required: true,
        },
        take: 20,
      },
    },
    queryOptions: {
      enabled: false,
    },
  });

  const handleSearch = debounce((newValue: string) => {
    if (newValue) {
      setValue(newValue);
      queryResult.refetch();
    }
  }, 1000);

  const handleChange = (newValue: string) => {
    setValue(newValue);
    props.onChange(newValue);
  };

  const options = (queryResult?.data?.data || []).map((d) => <Option key={d.id}>{d.name}</Option>);

  return (
    <Select
      showSearch
      value={value}
      placeholder={props.placeholder}
      defaultActiveFirstOption={false}
      filterOption={false}
      onSearch={handleSearch}
      onChange={handleChange}
      loading={queryResult.isFetching || false}
    >
      {options}
    </Select>
  );
};
