import React from 'react';
import { Button, Col, Modal, Row, Typography } from '@pankod/refine-antd';
import { useGetChannel } from 'hooks/use-get-channel';

const { Text, Paragraph } = Typography;

export const ChannelDetailDialog: React.FC<{
  channelId: string;
  close: () => void;
}> = ({ channelId, close }) => {
  const queryResult = useGetChannel(channelId);

  const result = queryResult?.data?.data;

  return (
    <Modal
      title="Channel Details"
      width={800}
      visible={queryResult?.isSuccess}
      onCancel={close}
      footer={[
        <Button key="back" onClick={close}>
          OK
        </Button>,
      ]}
    >
      <Row gutter={[16, 16]}>
        <Col span={24}>
          <Paragraph>
            <Text strong>Address Line 1: </Text>
            <Text>{result?.user?.companyAddressLine1}</Text>
          </Paragraph>
          <Paragraph>
            <Text strong>Address Line 2: </Text>
            <Text>{result?.user?.companyAddressLine2}</Text>
          </Paragraph>
          <Paragraph>
            <Text strong>City: </Text>
            <Text>{result?.user?.companyAddressCity}</Text>
          </Paragraph>
          <Paragraph>
            <Text strong>State: </Text>
            <Text>{result?.user?.companyAddressState}</Text>
          </Paragraph>
          <Paragraph>
            <Text strong>Postcode: </Text>
            <Text>{result?.user?.companyAddressPostcode}</Text>
          </Paragraph>
          <Paragraph>
            <Text strong>Join Date (Approval Date): </Text>
            <Text>{result?.approvedAt || 'N/A'}</Text>
          </Paragraph>
          <Paragraph>
            <Text strong>Type: </Text>
            <Text>Channel</Text>
          </Paragraph>
        </Col>

        <Col span={24}>
          <Paragraph>
            <Text strong>Person In Charge Contact: </Text>
            <ul>
              <li>{result?.user?.contactPersonName}</li>
              <li>{result?.user?.contactPersonMobileNumber}</li>
              <li>{result?.user?.contactPersonEmail}</li>
            </ul>
          </Paragraph>
        </Col>

        <Col span={12}>
          <Paragraph>
            <Text strong>Genre: </Text>
            <ul>
              {result?.genres.map((genre, index) => (
                <li key={index}>{genre}</li>
              ))}
            </ul>
          </Paragraph>
        </Col>

        <Col span={12}>
          <Paragraph>
            <Text strong>Bank Account Details: </Text> <br />
            <Text>{result?.user?.bankName}</Text> <br />
            <Text>{result?.user?.bankAccountNumber}</Text> <br />
          </Paragraph>
        </Col>
      </Row>
    </Modal>
  );
};
