import { useState } from 'react';
import { BiImageAdd } from 'react-icons/bi';
import { RiImageAddLine } from 'react-icons/ri';
import { Upload, UploadProps, message } from '@pankod/refine-antd';
import axios from 'axios';
import classNames from 'classnames';
import { getPresignUrl } from 'hooks';
import { OssResponse } from 'interfaces';

export const AliyunOSSUploadPreview = ({
  purpose,
  accept = '*',
  description,
  onChange,
}: {
  purpose:
    | 'PersonalBrandedSite'
    | 'PlaylistThumbnail'
    | 'VideoSubtitle'
    | 'VideoThumbnail'
    | 'Banner'
    | 'CommunityLogo'
    | 'CommunityBanner';
  accept?: string;
  description?: string;
  onChange: (OSSData: OssResponse) => void;
}) => {
  const [OSSData, setOSSData] = useState<OssResponse>();
  const [previewImageUrl, setPreviewImageUrl] = useState('');

  const handleRequest = async (options: any) => {
    const file: any = options.file;

    try {
      const response = await axios.put(options.action, file, {
        headers: {
          'Content-Type': file.type,
        },
      });
      options.onSuccess(response.data, options.file);
    } catch (error) {
      options.onError(error);
      console.log(error);
    }
  };

  const uploadButton = (
    <div>
      <RiImageAddLine size={40} />
      {description && <div className="ant-upload-text">{description}</div>}
    </div>
  );

  const uploadProps: UploadProps = {
    accept,
    name: OSSData?.path.split('/').pop(),
    action: OSSData?.signedUrl,
    maxCount: 1,
    showUploadList: false,
  };

  return (
    <Upload
      {...uploadProps}
      className={classNames({
        'upload-button-community-banner': purpose === 'CommunityBanner',
      })}
      listType="picture-card"
      customRequest={handleRequest}
      onChange={({ file }) => {
        if (file.status === 'done') {
          message.success(`${file?.name} file uploaded successfully.`);
          onChange(OSSData as OssResponse);
          setPreviewImageUrl(OSSData?.url);
        } else if (file.status === 'error') {
          message.error(`${file?.name} file upload failed.`);
        }
      }}
      beforeUpload={async (file: File) => {
        await getPresignUrl({
          params: {
            fileName: file.name,
            purpose,
          },
          onSuccess: (result: OssResponse) => {
            setOSSData(result);
          },
          onError: (error) => {
            message.error('Error: ' + error);
          },
        });

        return file;
      }}
    >
      {previewImageUrl ? <img className="picture-card-image" src={previewImageUrl} alt="avatar" /> : uploadButton}
    </Upload>
  );
};
