import { Modal, Row, Col, Typography } from "@pankod/refine-antd";
import { useOne } from "@pankod/refine-core";
import { RESOURCE_FIELDS, RESOURCE_NAME } from "pages/videos/constant";

const { Text, Title, Paragraph } = Typography;

export const PlaylistVideoDetail = ({ id }) => {
  const queryResult = useOne({
    id,
    resource: RESOURCE_NAME,
    metaData: {
      fields: [...RESOURCE_FIELDS],
    },
  });

  const video = queryResult?.data?.data;

  return (
    <Row>
      {video && (
        <>
          <Col span={12}>
            <Paragraph>Title: {video.title}</Paragraph>
            <Paragraph>Description: {video.description}</Paragraph>
            <Paragraph>Playback URL: {video.playbackUrl}</Paragraph>
            <Paragraph>Duration: {video.duration}</Paragraph>
            <Paragraph>Language: {video.language}</Paragraph>
            <Paragraph>Genre: {video.genre}</Paragraph>
            <Paragraph>Rating: {video.rating}</Paragraph>
          </Col>
          <Col span={12}>
            <Paragraph>Thumbnail Url: {video.thumbnailUrl}</Paragraph>
            <Paragraph>Subtitle Url: {video.subtitleUrl}</Paragraph>
            <Paragraph>Status: {video.status}</Paragraph>
            <Paragraph>Schedule Start: {video.scheduleStart}</Paragraph>
            <Paragraph>Scheudle End: {video.scheduleEnd}</Paragraph>
            <Paragraph>
              is pay per view: {video.isPayPerView ? "yes" : "no"}
            </Paragraph>
          </Col>
        </>
      )}
    </Row>
  );
};
