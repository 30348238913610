import { dataProvider } from '../App';
import { OssResponse } from 'interfaces';

export const getPresignUrl = async ({
  params,
  onSuccess,
  onError,
}: {
  params: { fileName: string; purpose: string };
  onSuccess: (data: OssResponse) => void;
  onError: (error: unknown) => void;
}): Promise<OssResponse> => {
  try {
    const response = await dataProvider.custom!({
      url: '',
      method: 'post',
      metaData: {
        operation: 'generatePresignedFile',
        variables: {
          data: {
            value: {
              fileName: params.fileName,
              purpose: params.purpose,
            },
            type: 'GeneratePresignedFileInput',
            required: true,
          },
        },
        fields: ['name', 'path', 'url', 'signedUrl'],
      },
    });

    const result = response.data as OssResponse;

    onSuccess(result);

    return result;
  } catch (error) {
    onError(error);
  }
};
