import React, { useEffect } from 'react';
import Hls from 'hls.js';

type Props = {
  playbackUrl: string;
};

export const VideoPlayer = ({ playbackUrl }: Props) => {
  const videoPlayerRef = React.createRef<HTMLVideoElement>();

  useEffect(() => {
    // ref: https://github.com/video-dev/hls.js/#alternative-setup
    if (videoPlayerRef.current.canPlayType('application/vnd.apple.mpegurl')) {
      videoPlayerRef.current.src = playbackUrl;
    } else if (Hls.isSupported()) {
      var hls = new Hls();
      hls.loadSource(playbackUrl);
      hls.attachMedia(videoPlayerRef.current);
    }
  }, [playbackUrl, videoPlayerRef]);

  return (
    <>
      <video ref={videoPlayerRef} src={playbackUrl} controls height={250} />
    </>
  );
};