import { FormInstance } from '@pankod/refine-antd';
import { dataProvider } from 'App';
import { debounce } from 'lodash';
import React from 'react';

export const useSearchChannel = ({ form, exclude }: { form: FormInstance; exclude: string }) => {
  const [isSearching, setIsSearching] = React.useState(false);
  const [isValidName, setIsValidName] = React.useState<boolean | undefined>(true);

  const search = debounce(async (value) => {
    try {
      setIsSearching(true);

      await form.validateFields(['name']);

      const result = await dataProvider.custom!({
        url: '',
        method: 'get',
        metaData: {
          operation: 'getPublicChannelByName',
          fields: ['id', 'name'],
          variables: {
            name: {
              value,
              type: `String`,
              required: true,
            },
          },
        },
      });

      if (result?.data) {
        if (result?.data?.name === exclude) {
          return setIsValidName(true);
        }

        form.setFields([
          {
            name: 'name',
            errors: ['Channel name is already taken'],
          },
        ]);
        return setIsValidName(false);
      }

      return setIsValidName(true);
    } catch (error: unknown) {
      setIsValidName(false);
    } finally {
      setIsSearching(false);
    }
  }, 1000);

  return { search, isSearching, isValidName };
};
