import React from 'react';
import {
  Breadcrumb,
  Button,
  Col,
  DateField,
  EditButton,
  ExportButton,
  Icons,
  ImageField,
  List,
  Row,
  Space,
  Table,
  Tag,
} from '@pankod/refine-antd';
import { IResourceComponentsProps, LogicalFilter, useExport, useTranslate } from '@pankod/refine-core';
import { VideoDetailDialog } from 'components/video';
import { VideoFilter } from 'components/video/video-filter';
import { Popconfirm, Radio } from 'antd';
import { useListVideos, useSoftDeleteVideo } from 'hooks';
import { IVideo } from 'interfaces';

export const VideoManagementList: React.FC<IResourceComponentsProps> = () => {
  const t = useTranslate();

  const [viewRecord, setViewRecord] = React.useState<IVideo | null>(null);
  const showRecordModal = (record: IVideo) => setViewRecord(record);
  const closeRecordModal = () => setViewRecord(null);

  const { tableProps, searchFormProps, tableQueryResult, filters, setFilters, resourceConfig } = useListVideos({
    initialFilter: [{ field: 'archivedAt', operator: 'null', value: '' }],
  });
  const defaultIsArchivedAt = (filters as LogicalFilter[]).find((filter) => filter.field === 'archivedAt')?.operator;

  const { softDelete, restoreDelete } = useSoftDeleteVideo({
    onSuccess: () => {
      tableQueryResult.refetch();
    },
  });

  const { triggerExport, isLoading: exportLoading } = useExport<IVideo>({
    exportOptions: {},
    pageSize: 1000,
    mapData: (item) => ({
      id: item.id,
      title: item.title,
      description: item.description,
      language: item.language,
      genre: item.genre,
      rating: item.rating,
      status: item.status,
    }),
    onError: (error) => {
      console.error(error);
    },
    resourceName: resourceConfig.name,
    metaData: {
      items: resourceConfig.items,
      metadata: resourceConfig.metadata,
    },
    filters: resourceConfig.filters,
  });

  const Actions: React.FC = () => (
    <Space>
      <Radio.Group
        value={defaultIsArchivedAt}
        onChange={(e) => {
          setFilters(
            [
              {
                field: 'archivedAt',
                operator: e.target.value,
                value: '',
              },
            ],
            'replace',
          );
        }}
      >
        <Radio.Button value="null">Active</Radio.Button>
        <Radio.Button value="nnull">Archived</Radio.Button>
      </Radio.Group>
      <ExportButton
        loading={exportLoading}
        onClick={() => {
          triggerExport();
        }}
      />
    </Space>
  );

  return (
    <Row gutter={[16, 16]}>
      {viewRecord && <VideoDetailDialog values={viewRecord} close={closeRecordModal} />}

      <Col xl={24} lg={24} xs={24}>
        <VideoFilter formProps={searchFormProps} />
      </Col>

      <Col xl={24} xs={24}>
        <List
          pageHeaderProps={{
            title: 'Content Library',
            breadcrumb: <Breadcrumb hideIcons />,
            extra: <Actions />,
          }}
        >
          <Table {...tableProps} rowKey="id">
            <Table.Column<IVideo>
              dataIndex="thumbnailUrl"
              title={t('videos.fields.thumbnail')}
              render={(_, record) => (
                <ImageField
                  value={record?.thumbnailUrl ?? record?.coverThumbnailUrl}
                  title={record.thumbnailUrl ?? record?.coverThumbnailUrl}
                  width={120}
                  height={68}
                  style={{ objectFit: 'contain' }}
                />
              )}
            />
            <Table.Column dataIndex="title" title={t('videos.fields.title')} />
            <Table.Column dataIndex="description" title={t('videos.fields.description')} />
            <Table.Column<IVideo>
              dataIndex="status"
              title={t('videos.fields.status')}
              render={(_, record) => <Tag>{record.status}</Tag>}
            />
            <Table.Column<IVideo>
              title="Updated At"
              dataIndex="updatedAt"
              key="updatedAt"
              render={(_, record) => <DateField value={record.updatedAt} format="LLL" />}
            />
            <Table.Column<IVideo>
              width="5%"
              key="actions"
              title={t('table.actions')}
              dataIndex="actions"
              render={(_, record) => (
                <Space>
                  <EditButton hideText size="small" recordItemId={record.id} />
                  <Button
                    size="small"
                    icon={<Icons.EyeOutlined />}
                    onClick={() => {
                      showRecordModal(record);
                    }}
                  />
                  {defaultIsArchivedAt === 'null' && (
                    <Popconfirm
                      title="Archive video?"
                      onConfirm={() => {
                        softDelete(record.id);
                      }}
                      onCancel={() => {}}
                      okText="Yes"
                      cancelText="No"
                    >
                      <Button danger={true} size="small" icon={<Icons.DeleteOutlined />} />
                    </Popconfirm>
                  )}

                  {defaultIsArchivedAt === 'nnull' && (
                    <Popconfirm
                      title="Restore video?"
                      onConfirm={() => {
                        restoreDelete(record.id);
                      }}
                      onCancel={() => {}}
                      okText="Yes"
                      cancelText="No"
                    >
                      <Button danger={true} size="small" icon={<Icons.RollbackOutlined />} />
                    </Popconfirm>
                  )}
                </Space>
              )}
            />
          </Table>
        </List>
      </Col>
    </Row>
  );
};
