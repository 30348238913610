import { useState } from 'react';
import { Col, Row } from '@pankod/refine-antd';
import { AliyunOSSUploadPreview } from 'components/upload/aliyun-oss-upload-preview';
import { OssResponse } from 'interfaces';

type Props = {
  communityName?: string;
  onChange: (OSSData: OssResponse) => void;
};

export const NFTPreviewCard = ({ communityName = 'Community Name', onChange }: Props) => {
  const monthYearNow = new Date().toLocaleString('default', { month: 'long', year: 'numeric' });

  const [previewImageUrl, setPreviewImageUrl] = useState('');

  return (
    <div className="nft-preview-card">
      <AliyunOSSUploadPreview
        purpose="CommunityLogo"
        accept="image/*"
        onChange={(response) => {
          console.log('logo url on changes', response);
          setPreviewImageUrl(response.url);
          onChange(response);
        }}
      />
      <div className="w-52 box-border bg-gray-300 justify-center items-center border-2 border-[#e5e5e5] rounded-b-md py-3">
        <Row gutter={[12, 12]} align="middle" justify="center">
          <Col>
            <p className="mb-0">{communityName.length ? communityName : 'Community Name'}</p>
            <span className=" text-[#A5A7AA] text-xs">Created {monthYearNow}</span>
          </Col>
          <Col>
            {previewImageUrl ? (
              <img
                className="rounded-md w-10 h-10 border border-solid border-white object-cover"
                src={previewImageUrl}
                alt="logo"
              />
            ) : (
              <div className="box-border bg-red-300 rounded-md w-10 h-10 border border-solid border-white">&nbsp;</div>
            )}
          </Col>
        </Row>
      </div>
    </div>
  );
};
