import { Button, Col, Collapse, Form, FormProps, Row, Select } from '@pankod/refine-antd';
import { CrudFilters, useTranslate } from '@pankod/refine-core';
import { SearchInput } from 'components/search-input';
import { DatePicker } from 'antd';
import { IWatchHistoryFilterVariables } from 'interfaces';

export const WatchHistoryFilter: React.FC<{
  formProps?: FormProps<IWatchHistoryFilterVariables>;
  filters?: CrudFilters;
  tableQueryResult: any;
}> = (props) => {
  const t = useTranslate();

  return (
    <Collapse>
      <Collapse.Panel header="Filter" key="1">
        <Form layout="vertical" {...props.formProps}>
          <Row gutter={[10, 0]} align="bottom">
            <Col xs={24} sm={24} md={6} lg={6} xl={6}>
              <Form.Item label={'Channel'} name="channelId">
                <SearchInput placeholder="Search channel name" />
              </Form.Item>
            </Col>

            <Col xs={24} sm={24} md={6} lg={6} xl={6}>
              <Form.Item label={'Search Date'} name="searchDates">
                <DatePicker.RangePicker style={{ width: '100%' }} />
              </Form.Item>
            </Col>
          </Row>

          <Row gutter={[10, 0]} align="bottom">
            <Col xs={24} sm={24} md={4} lg={4} xl={4}>
              <Form.Item>
                <Button style={{ width: '100%' }} htmlType="submit" type="primary">
                  {t('videos.filter.submit')}
                </Button>
              </Form.Item>
            </Col>

            <Col xs={24} sm={24} md={4} lg={4} xl={4}>
              <Form.Item>
                <Button
                  style={{ width: '100%' }}
                  onClick={() => {
                    props.formProps.form.resetFields();
                    props.formProps.form.submit();
                  }}
                  type="primary"
                >
                  Reset
                </Button>
              </Form.Item>
            </Col>
          </Row>
        </Form>
      </Collapse.Panel>
    </Collapse>
  );
};
