export const RESOURCE_NAME = "gifts";
export const RESOURCE_LIST_NAME = "my-channel/monetization/virtual-gifting";
export const RESOURCE_FIELDS = [
  "id",
  "name",
  "thumbnailUrl",
  "price",
  "archivedAt",
  "createdAt",
  "updatedAt",
  "displayPrice",
] as const;
export const PAGINATION_FIELDS = [
  "currentPage",
  "perPage",
  "totalCount",
  "totalPages",
] as const;
