import { message } from '@pankod/refine-antd';
import { dataProvider } from 'App';
import { IVideo } from 'interfaces';
import { mapValuesToSetter, simplifyErrorMessage } from 'libs';

// useCustom, will trigger infinity loop when in Table component, so I directly use custom
export const useSoftDeleteVideo = ({ onSuccess }: { onSuccess: () => void }) => {
  const softDelete = async (id: string) => {
    try {
      const result = await dataProvider.custom<IVideo>({
        url: '',
        method: 'post',
        metaData: {
          operation: 'updateVideo',
          variables: {
            where: {
              value: { id },
              type: `VideoWhereUniqueInput`,
              required: true,
            },
            data: {
              value: mapValuesToSetter({
                archivedAt: new Date(),
              }),
              type: 'VideoUpdateInput',
              required: true,
            },
          },
          fields: ['id'],
        },
      });

      message.success('Successfully archive video');

      if (result.data.id) {
        onSuccess();
      }
    } catch (error) {
      const errorMessage = simplifyErrorMessage(error);
      message.error(errorMessage || 'Error while archive video');
    }
  };

  const restoreDelete = async (id: string) => {
    try {
      const result = await dataProvider.custom<IVideo>({
        url: '',
        method: 'post',
        metaData: {
          operation: 'updateVideo',
          variables: {
            where: {
              value: { id },
              type: `VideoWhereUniqueInput`,
              required: true,
            },
            data: {
              value: mapValuesToSetter({
                archivedAt: null,
              }),
              type: 'VideoUpdateInput',
              required: true,
            },
          },
          fields: ['id'],
        },
      });

      message.success('Successfully restore video');

      if (result.data.id) {
        onSuccess();
      }
    } catch (error) {
      const errorMessage = simplifyErrorMessage(error);
      message.error(errorMessage || 'Error while restore video');
    }
  };

  return {
    softDelete,
    restoreDelete,
  };
};
