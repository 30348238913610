import { CommunityFactoryABI as ABI } from 'components/web3/abi/communityFactory';
import { useWeb3Context } from 'components/web3/context';
import { ChainId } from '../enum';
import { CreateCommunityInput } from '../type';
import { ContractTransaction, ethers } from 'ethers';

export const useCommunityFactory = () => {
  const { web3Provider, address } = useWeb3Context();
  const addressContract = process.env.REACT_APP_COMMUNITY_FACTORY_CONTRACT_ADDRESS;

  const getContract = () => {
    const signer = web3Provider.getSigner();
    return new ethers.Contract(addressContract, ABI, signer);
  };

  const getImplementation = async (): Promise<string> => {
    const implementation = await getContract().getImplementation();
    return implementation;
  };

  const getBeacon = async (): Promise<string> => {
    const beacon = await getContract().getBeacon();
    return beacon;
  };

  const isUserChainValid = async (): Promise<boolean> => {
    const userNetwork = await web3Provider.getNetwork();
    return userNetwork.chainId === (parseInt(process.env.REACT_APP_VALID_CHAIN_ID) ?? ChainId.mumbai);
  };

  const createCommunity = async (values: CreateCommunityInput) => {
    const transaction: ContractTransaction = await getContract().createCommunity(
      values.name,
      values.symbol,
      values.logoUrl,
      values.bannerUrl,
      values.description,
      values.nftImageURL,
      values.nftSupply,
      ethers.utils.parseEther(values.mintPrice.toString()),
    );

    return transaction;
  };

  const getUserCommunities = async (): Promise<string[]> => {
    const userCommunities = await getContract().getOwnerCommunities(address);
    return userCommunities;
  };

  return {
    getImplementation,
    getBeacon,
    createCommunity,
    getUserCommunities,
    isUserChainValid,
  };
};
