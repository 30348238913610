import React from 'react';
import { useParams } from 'react-router-dom';
import { Col, Edit, Form, Input, InputNumber, Row } from '@pankod/refine-antd';
import {
  IResourceComponentsProps,
  useRedirectionAfterSubmission,
  useResourceWithRoute,
  useTranslate,
} from '@pankod/refine-core';
import { mapValuesToSetter } from '../../libs';
import { RESOURCE_FIELDS, RESOURCE_LIST_NAME, RESOURCE_NAME } from './constant';
import { CurrencyCode } from 'api';
import { useFormUpdate } from 'hooks';
import { IChannel, ISubscription } from 'interfaces';

export const SubscriptionEdit: React.FC<IResourceComponentsProps> = () => {
  const t = useTranslate();
  const { id } = useParams();


  const { form, formProps, queryResult, mutateResult } = useFormUpdate<ISubscription & { channel: IChannel }>({
    id: id && +id,
    resourceName: RESOURCE_NAME,
    fields: [...RESOURCE_FIELDS, { channel: ['id', 'currencyCode'] }],
    submitRedirect: false,
  });

  const resourceWithRoute = useResourceWithRoute();
  const resourceList = resourceWithRoute(RESOURCE_LIST_NAME);
  const handleSubmitWithRedirect = useRedirectionAfterSubmission();

  React.useEffect(() => {
    if (mutateResult.isSuccess) {
      handleSubmitWithRedirect({
        redirect: 'list',
        resource: resourceList,
      });
    }
  }, [handleSubmitWithRedirect, mutateResult, resourceList]);

  React.useEffect(() => {
    if (queryResult.data?.data) {
      form.setFieldsValue({
        ...queryResult.data.data,
        price: Math.round(queryResult.data?.data?.price / 100),
      });
    }
    return () => {
      form.resetFields();
    };
  }, [form, queryResult]);

  return (
    <Edit
      title="Edit Subscription Plan"
      isLoading={queryResult?.isFetching}
      saveButtonProps={{
        disabled: queryResult?.isFetching,
        onClick: () => {
          form.submit();
        },
      }}
      pageHeaderProps={{
        extra: <></>,
      }}
    >
      <Form
        form={form}
        layout="vertical"
        onFinish={(values) => {
          const payload = mapValuesToSetter({
            ...values,
            price: Math.round(Math.max(values.price * 100, 0)),
          });

          formProps?.onFinish?.(payload);
        }}
      >
        <Row gutter={[64, 0]} wrap>
          <Col xs={24} md={24} lg={24}>
            <Form.Item label={'Description'} name="description" rules={[{ required: true }]}>
              <Input.TextArea />
            </Form.Item>

            <Form.Item label={'Subscription Duration (Days)'} name="duration" rules={[{ required: true }]}>
              <InputNumber controls={false} style={{ width: '100%' }} />
            </Form.Item>

            <Form.Item
              label={`Subscription Price (${queryResult?.data?.data?.channel?.currencyCode})`}
              name="price"
              rules={[
                { required: true },
                ({ getFieldValue }) => ({
                  validator(_, value) {
                    if (value > 0 || !value) {
                      return Promise.resolve();
                    }
                    return Promise.reject(new Error('Subscription Price must be larger than zero!​'));
                  },
                }),
              ]}
            >
              <InputNumber controls={false} style={{ width: '100%' }} />
            </Form.Item>
          </Col>
        </Row>
      </Form>
    </Edit>
  );
};
