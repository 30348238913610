import React from 'react';
import { useParams } from 'react-router-dom';
import {
  Breadcrumb,
  Card,
  Col,
  Edit,
  Form,
  Input,
  ListButton,
  RefreshButton,
  Row,
  Select,
  Switch,
  Tag,
  Typography,
  useForm,
} from '@pankod/refine-antd';
import { HttpError, IResourceComponentsProps, useTranslate } from '@pankod/refine-core';
import { AliyunOSSUpload } from 'components/upload';
import { mapValuesToSetter } from '../../libs';
import {
  RESOURCE_FIELDS,
  RESOURCE_NAME,
  VideoStatus,
  genreOptions,
  ratingOptions,
  voLanguageOptions,
} from './constant';
import { VideoPlayer } from './video-player';
import { DatePicker, InputNumber } from 'antd';
import { IChannel, IVideoForm } from 'interfaces';
import moment from 'moment';

const { Link } = Typography;

const responsive = {
  xs: 24,
  sm: 24,
  md: 12,
  lg: 12,
  xl: 12,
};

const uploadItemResponsive = {
  xs: 24,
  sm: 24,
  md: 24,
  lg: 24,
  xl: 12,
};

export const VideoEdit: React.FC<IResourceComponentsProps> = () => {
  const t = useTranslate();
  const { id } = useParams();

  const { formProps, queryResult } = useForm<IVideoForm & { channel: IChannel }, HttpError, IVideoForm>({
    id,
    action: 'edit',
    resource: RESOURCE_NAME,
    metaData: {
      fields: [...RESOURCE_FIELDS, { channel: ['id', 'currencyCode'] }],
    },
  });
  const result = queryResult?.data?.data;
  const isRelease = (result?.status as unknown as VideoStatus) === VideoStatus.RELEASED;

  const [isPayPerView, setIsPayPerView] = React.useState(false);

  React.useEffect(() => {
    if (result) {
      formProps.form.setFieldsValue({
        ...result,
        scheduleStart: result.scheduleStart ? moment(result.scheduleStart) : undefined,
        payPerViewPrice: result.payPerViewPrice ? result.payPerViewPrice / 100 : 1, // divide by 100 to convert to cents
      });

      setIsPayPerView(!!result.isPayPerView);

      return () => {
        formProps.form.resetFields();
      };
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [result]);

  return (
    <Edit
      isLoading={queryResult?.isFetching}
      saveButtonProps={{
        disabled: queryResult?.isFetching,
        onClick: () => {
          formProps.form.submit();
        },
      }}
      pageHeaderProps={{
        breadcrumb: <Breadcrumb hideIcons />,
        extra: (
          <>
            <ListButton />
            <RefreshButton onClick={() => queryResult?.refetch({})} />
          </>
        ),
      }}
    >
      <Form
        {...formProps}
        layout="vertical"
        onFinish={(values) => {
          const payload = {
            ...mapValuesToSetter({
              ...values,
              // multiply by 100 to convert to cents
              payPerViewPrice: Math.round(Math.max(values.payPerViewPrice * 100, 0)),
              isPayPerView,
            }),
          };

          // @ts-ignore
          formProps?.onFinish?.(payload);
        }}
      >
        <Row gutter={[24, 24]} justify="start" align="top">
          <Col {...responsive}>
            <Card title="General Settings">
              <Form.Item label={t('videos.fields.title')} name="title" rules={[{ required: true }]}>
                <Input />
              </Form.Item>
              <Form.Item label={t('videos.fields.description')} name="description" rules={[{ required: false }]}>
                <Input.TextArea />
              </Form.Item>

              <Form.Item label={t('videos.fields.language')} name="language" rules={[{ required: true }]}>
                <Select options={voLanguageOptions} />
              </Form.Item>

              <Form.Item label={t('videos.fields.genre')} name="genre" rules={[{ required: true }]}>
                <Select options={genreOptions} />
              </Form.Item>

              <Form.Item label={t('videos.fields.rating')} name="rating" rules={[{ required: true }]}>
                <Select options={ratingOptions} />
              </Form.Item>

              <Form.Item
                label={'Publish Date'}
                name="scheduleStart"
                rules={[{ required: true }]}
                getValueProps={(value) => ({ value: moment(value) })}
              >
                <DatePicker
                  style={{ width: '100%' }}
                  allowClear={false}
                  disabledDate={(current) => {
                    return current && current < moment().subtract(1, 'day').endOf('day');
                  }}
                />
              </Form.Item>
            </Card>
          </Col>

          <Col {...responsive}>
            <Card title="Monetize Video">
              <Form.Item label={'Is this Pay-Per-View Content?'}>
                <Switch
                  checked={isPayPerView}
                  onChange={(value) => {
                    setIsPayPerView(value);
                  }}
                />
              </Form.Item>

              {isPayPerView && (
                <Form.Item
                  label={`Price Pay-Per-View (${queryResult?.data?.data.channel?.currencyCode})`}
                  name="payPerViewPrice"
                  rules={[{ required: true }]}
                >
                  <InputNumber style={{ width: '100%' }} min={1} />
                </Form.Item>
              )}
            </Card>
          </Col>

          <Col {...uploadItemResponsive}>
            <Card>
              <Form.Item
                label={
                  <>
                    Video Upload &nbsp; <Tag>{result?.status}</Tag>
                  </>
                }
              >
                {isRelease && <VideoPlayer playbackUrl={result?.playbackUrl} />}
              </Form.Item>
              <br />
              <Form.Item label={'Subtitles Upload'} name="subtitleUrl">
                <AliyunOSSUpload
                  purpose="VideoSubtitle"
                  accept=".vtt,.srt"
                  description="Extension Allowed: .srt/.vtt"
                  onChange={(response) => {
                    formProps.form?.setFieldsValue({
                      subtitleUrl: response.url,
                    });
                  }}
                />
              </Form.Item>
              URL:
              <Link
                onClick={() => {
                  window.open(result?.subtitleUrl);
                }}
              >
                {result?.subtitleUrl}
              </Link>
            </Card>
          </Col>

          <Col {...uploadItemResponsive}>
            <Card>
              <Form.Item label={'Thumbnail Upload'} name="thumbnailUrl" rules={[{ required: false }]}>
                <AliyunOSSUpload
                  purpose="VideoThumbnail"
                  accept="image/*"
                  description="Extension Allowed: .jpg/.jpeg/.png<br>Recommended Dimension: 1280 x 720 pixels"
                  onChange={(response) => {
                    formProps.form?.setFieldsValue({
                      thumbnailUrl: response.url,
                    });
                  }}
                />
              </Form.Item>
              URL:
              <Link
                onClick={() => {
                  window.open(result?.thumbnailUrl);
                }}
              >
                {result?.thumbnailUrl}
              </Link>
            </Card>
          </Col>
        </Row>
      </Form>
    </Edit>
  );
};
