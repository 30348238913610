import { useUpdate } from "@pankod/refine-core";
import React from "react";

export const useToggleChannelGift = ({ channelId }: { channelId: string }) => {
  const { mutate, isSuccess } = useUpdate();

  const toggle = async (checked: boolean) => {
    if (channelId) {
      mutate({
        resource: "channel",
        id: channelId,
        values: {
          isGiftEnabled: { set: checked },
        },
      });
    }
  };

  return { toggle, isSuccess };
};
