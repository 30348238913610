import { Button, Modal, Spin } from '@pankod/refine-antd';

export const useTransactionLoadingDialog = () => {
  let modal = null;

  const directToCommunity = (communityAddress: string) => {
    window.open(`https://mumbai.polygonscan.com/address/${communityAddress}`, '_blank');
  };

  const showLoading = () => {
    modal = Modal.info({
      icon: null,
      closable: false,
      keyboard: false,
      okButtonProps: {
        style: { display: 'none' },
      },
      title: 'Processing your transaction...',
      content: (
        <div>
          <p>Please don't close this page until the transaction is confirmed.</p>
          <div className="w-full flex flex-row items-center justify-center ">
            <Spin />
          </div>
        </div>
      ),
    });
  };

  const showSuccess = (communityAddress: string) => {
    if (modal) loadingDismiss();

    modal = Modal.success({
      content: (
        <div className=" justify-center flex flex-col items-center">
          <p>Congratulations! You’ve form a NFT community</p>
          <Button type="primary" onClick={() => directToCommunity(communityAddress)}>
            Visit NFT Community
          </Button>
        </div>
      ),
      okButtonProps: { style: { display: 'none' } },
      okText: '',
      icon: null,
      closable: true,
    });
  };

  const showError = (errorMessage?: string) => {
    if (modal) loadingDismiss();

    modal = Modal.error({
      content: (
        <div className=" justify-center flex flex-col items-center">
          <p>{errorMessage ? errorMessage : 'Something went wrong! Please try again later.'}</p>
        </div>
      ),
      okButtonProps: { style: { display: 'none' } },
      okText: '',
      icon: null,
      closable: true,
    });
  };

  const showConnectWallet = () => {
    modal = Modal.info({
      icon: null,
      closable: true,
      keyboard: true,
      okButtonProps: {
        style: { display: 'none' },
      },
      title: '',
      content: (
        <div className="justify-center flex flex-col items-center pt-4">
          <p>Please connect your wallet</p>
        </div>
      ),
    });
  };

  const loadingDismiss = () => {
    modal.destroy();
  };

  return {
    showLoading,
    showSuccess,
    showError,
    showConnectWallet,
    loadingDismiss,
  };
};
