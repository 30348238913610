import React from 'react';
import {
  Breadcrumb,
  Card,
  Col,
  DateField,
  ExportButton,
  List,
  Row,
  Space,
  Table,
  Typography,
} from '@pankod/refine-antd';
import { IResourceComponentsProps, LogicalFilter, useExport, useTranslate } from '@pankod/refine-core';
import { WatchHistoryFilter } from 'components/report';
import { Frequency } from 'api';
import { useGetWatchHistorySummary, useListWatchHistories } from 'hooks';
import { IWatchHistorySummary } from 'interfaces';

const { Text, Title } = Typography;

const responsive = {
  xs: 24,
  sm: 24,
  md: 12,
  lg: 12,
  xl: 12,
};

export const WatchHistoryList: React.FC<IResourceComponentsProps> = () => {
  const t = useTranslate();

  const { tableProps, searchFormProps, tableQueryResult, filters, resourceConfig } = useListWatchHistories({
    frequency: Frequency.Daily,
  });

  const { channelId, dateFrom, dateTo } = (filters as LogicalFilter[]).reduce<any>((acc, filter) => {
    acc[filter.field] = filter.value;
    return acc;
  }, {});

  const summaryQueryResult = useGetWatchHistorySummary({
    frequency: Frequency.AllTime,
    channelId: channelId || null,
    searchDates: [dateFrom, dateTo],
  });

  const { triggerExport, isLoading: exportLoading } = useExport<IWatchHistorySummary>({
    exportOptions: {},
    pageSize: 1000,
    mapData: (item) => ({
      date: item.date,
      totalViewCount: item.totalViewCount,
      totalVideoCount: item.totalVideoCount,
    }),
    onError: (error) => {
      console.error(error);
    },
    resourceName: resourceConfig.name,
    metaData: {
      items: resourceConfig.items,
      metadata: resourceConfig.metadata,
    },
    filters: resourceConfig.filters,
  });

  const Actions: React.FC = () => (
    <Space>
      <ExportButton
        loading={exportLoading}
        onClick={() => {
          triggerExport();
        }}
      />
    </Space>
  );

  return (
    <>
      <Row gutter={[16, 16]}>
        <Col {...responsive}>
          <Card>
            <Title>Total Views</Title>
            <Text>{summaryQueryResult?.totalViewCount}</Text>
          </Card>
        </Col>
        <Col {...responsive}>
          <Card>
            <Title>Total Views</Title>
            <Text>{summaryQueryResult?.totalVideoCount}</Text>
          </Card>
        </Col>
      </Row>

      <br />

      <Row gutter={[16, 16]}>
        <Col xl={24} lg={24} xs={24}>
          <WatchHistoryFilter formProps={searchFormProps} tableQueryResult={tableQueryResult} filters={filters || []} />
        </Col>

        <Col xl={24} xs={24}>
          <List
            pageHeaderProps={{
              title: 'View Report',
              breadcrumb: <Breadcrumb hideIcons />,
              extra: <Actions />,
            }}
          >
            <Table {...tableProps} rowKey="date">
              <Table.Column title="Date" dataIndex="date" render={(value) => <DateField value={value} format="LL" />} />
              <Table.Column dataIndex="totalViewCount" title={'Total View'} />
              <Table.Column dataIndex="totalVideoCount" title={'Total Count'} />
            </Table>
          </List>
        </Col>
      </Row>
    </>
  );
};
