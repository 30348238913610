import React from 'react';
import { useTable, useTableReturnType } from '@pankod/refine-antd';
import { CrudFilters, CrudSort, HttpError, MetaDataQuery } from '@pankod/refine-core';
import { PAGINATION_FIELDS } from './constant';
import { IVideo, IVideoFilterVariables, ResourceConfig } from 'interfaces';

const RESOURCE_NAME = 'videos';

const RESOURCE_FIELDS = [
  'id',
  'title',
  'description',
  'playbackUrl',
  'duration',
  'language',
  'genre',
  'rating',
  'subtitleLanguage',
  'thumbnailUrl',
  'subtitleUrl',
  'status',
  'scheduleStart',
  'scheduleEnd',
  'isPayPerView',
  'payPerViewPrice',
  'productUrl',
  'isVertical',
  'updatedAt',
  'archivedAt',
  'coverThumbnailUrl',
] as const;

export const useListVideos = ({
  metadata,
  initialFilter,
}: {
  metadata?: MetaDataQuery;
  initialFilter?: CrudFilters;
}): useTableReturnType<IVideo, IVideoFilterVariables> & { resourceConfig: ResourceConfig } => {
  const initialSorter: CrudSort[] = [{ field: 'updatedAt', order: 'desc' }];
  const initialPageSize = 10;

  const result = useTable<IVideo, HttpError, IVideoFilterVariables>({
    resource: RESOURCE_NAME,
    metaData: {
      ...metadata,
      items: [...RESOURCE_FIELDS],
      metadata: [...PAGINATION_FIELDS],
    },
    initialSorter,
    initialPageSize,
    initialFilter,
    onSearch: (params: any) => {
      const filters: CrudFilters = [];
      const { title, createdDates, genre, status } = params;

      filters.push({
        field: 'title',
        operator: 'contains',
        value: title,
      });

      filters.push({
        field: 'genre',
        operator: 'eq',
        value: genre || null,
      });

      filters.push({
        field: 'status',
        operator: 'eq',
        value: status || null,
      });

      filters.push({
        field: 'createdAt',
        operator: 'between',
        value:
          createdDates?.length > 0
            ? [createdDates[0].startOf('day').toDate(), createdDates[1].endOf('day').toDate()]
            : [],
      });

      return filters;
    },
  });

  return {
    ...result,
    resourceConfig: {
      name: RESOURCE_NAME,
      filters: result.filters,
      items: [...RESOURCE_FIELDS],
      metadata: [...PAGINATION_FIELDS],
    },
  };
};
