import { HttpError, useNotification, useTranslate } from '@pankod/refine-core';
import { dataProvider } from 'App';
import React from 'react';
import { RESOURCE_NAME } from '../constant';

export const useDeletePlaylist = ({ onSuccess }: { onSuccess: () => void }) => {
  const t = useTranslate();
  const { open } = useNotification();

  const [isFetching, setIsFetching] = React.useState(false);

  const deleteMany = async ({ playlistIds }: { playlistIds: React.Key[] }) => {
    try {
      setIsFetching(true);

      const result = await dataProvider.custom!({
        url: '',
        method: 'post',
        metaData: {
          operation: 'deleteMyPlaylists',
          fields: ['id'],
          variables: {
            where: {
              value: {
                id: {
                  in: playlistIds,
                },
              },
              type: `DeleteMyPlaylistInput`,
              required: true,
            },
          },
        },
      });

      if (result) {
        open({
          key: `success` + Date.now(),
          type: 'success',
          description: t('notifications.success'),
          message: t('notifications.deleteSuccess', {
            resource: RESOURCE_NAME,
          }),
        });

        onSuccess();

        return result;
      }
    } catch (error: unknown) {
      open({
        key: `error` + Date.now(),
        type: 'error',
        description: t('notifications.error', {
          // ts-ignore
          statusCode: (error as HttpError).statusCode || 'unknown',
        }),
        message: error as any,
      });
    } finally {
      setIsFetching(false);
    }
  };

  return {
    deleteMany,
    isFetching,
  };
};
