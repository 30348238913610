import { gql } from '@apollo/client';
export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
  DateTime: any;
  JSON: any;
};

export type AdminLoginInput = {
  password: Scalars['String'];
  username: Scalars['String'];
};

export type AdminPermission = {
  __typename?: 'AdminPermission';
  createdAt: Scalars['DateTime'];
  id: Scalars['Int'];
  name: Scalars['String'];
  role: AdminRole;
  roleId: Scalars['Int'];
  slug: Scalars['String'];
  updatedAt: Scalars['DateTime'];
};

export type AdminPermissionCreateManyRoleInput = {
  createdAt?: InputMaybe<Scalars['DateTime']>;
  id?: InputMaybe<Scalars['Int']>;
  name: Scalars['String'];
  slug: Scalars['String'];
  updatedAt?: InputMaybe<Scalars['DateTime']>;
};

export type AdminPermissionCreateManyRoleInputEnvelope = {
  data: Array<AdminPermissionCreateManyRoleInput>;
  skipDuplicates?: InputMaybe<Scalars['Boolean']>;
};

export type AdminPermissionCreateNestedManyWithoutRoleInput = {
  connect?: InputMaybe<Array<AdminPermissionWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<AdminPermissionCreateOrConnectWithoutRoleInput>>;
  create?: InputMaybe<Array<AdminPermissionCreateWithoutRoleInput>>;
  createMany?: InputMaybe<AdminPermissionCreateManyRoleInputEnvelope>;
};

export type AdminPermissionCreateOrConnectWithoutRoleInput = {
  create: AdminPermissionCreateWithoutRoleInput;
  where: AdminPermissionWhereUniqueInput;
};

export type AdminPermissionCreateWithoutRoleInput = {
  createdAt?: InputMaybe<Scalars['DateTime']>;
  name: Scalars['String'];
  slug: Scalars['String'];
  updatedAt?: InputMaybe<Scalars['DateTime']>;
};

export type AdminPermissionListRelationFilter = {
  every?: InputMaybe<AdminPermissionWhereInput>;
  none?: InputMaybe<AdminPermissionWhereInput>;
  some?: InputMaybe<AdminPermissionWhereInput>;
};

export type AdminPermissionOrderByRelationAggregateInput = {
  _count?: InputMaybe<SortOrder>;
};

export type AdminPermissionOrderByWithRelationInput = {
  createdAt?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  name?: InputMaybe<SortOrder>;
  role?: InputMaybe<AdminRoleOrderByWithRelationInput>;
  roleId?: InputMaybe<SortOrder>;
  slug?: InputMaybe<SortOrder>;
  updatedAt?: InputMaybe<SortOrder>;
};

export enum AdminPermissionScalarFieldEnum {
  CreatedAt = 'createdAt',
  Id = 'id',
  Name = 'name',
  RoleId = 'roleId',
  Slug = 'slug',
  UpdatedAt = 'updatedAt'
}

export type AdminPermissionScalarWhereInput = {
  AND?: InputMaybe<Array<AdminPermissionScalarWhereInput>>;
  NOT?: InputMaybe<Array<AdminPermissionScalarWhereInput>>;
  OR?: InputMaybe<Array<AdminPermissionScalarWhereInput>>;
  createdAt?: InputMaybe<DateTimeFilter>;
  id?: InputMaybe<IntFilter>;
  name?: InputMaybe<StringFilter>;
  roleId?: InputMaybe<IntFilter>;
  slug?: InputMaybe<StringFilter>;
  updatedAt?: InputMaybe<DateTimeFilter>;
};

export type AdminPermissionUpdateManyMutationInput = {
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  name?: InputMaybe<StringFieldUpdateOperationsInput>;
  slug?: InputMaybe<StringFieldUpdateOperationsInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
};

export type AdminPermissionUpdateManyWithWhereWithoutRoleInput = {
  data: AdminPermissionUpdateManyMutationInput;
  where: AdminPermissionScalarWhereInput;
};

export type AdminPermissionUpdateManyWithoutRoleInput = {
  connect?: InputMaybe<Array<AdminPermissionWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<AdminPermissionCreateOrConnectWithoutRoleInput>>;
  create?: InputMaybe<Array<AdminPermissionCreateWithoutRoleInput>>;
  createMany?: InputMaybe<AdminPermissionCreateManyRoleInputEnvelope>;
  delete?: InputMaybe<Array<AdminPermissionWhereUniqueInput>>;
  deleteMany?: InputMaybe<Array<AdminPermissionScalarWhereInput>>;
  disconnect?: InputMaybe<Array<AdminPermissionWhereUniqueInput>>;
  set?: InputMaybe<Array<AdminPermissionWhereUniqueInput>>;
  update?: InputMaybe<Array<AdminPermissionUpdateWithWhereUniqueWithoutRoleInput>>;
  updateMany?: InputMaybe<Array<AdminPermissionUpdateManyWithWhereWithoutRoleInput>>;
  upsert?: InputMaybe<Array<AdminPermissionUpsertWithWhereUniqueWithoutRoleInput>>;
};

export type AdminPermissionUpdateWithWhereUniqueWithoutRoleInput = {
  data: AdminPermissionUpdateWithoutRoleInput;
  where: AdminPermissionWhereUniqueInput;
};

export type AdminPermissionUpdateWithoutRoleInput = {
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  name?: InputMaybe<StringFieldUpdateOperationsInput>;
  slug?: InputMaybe<StringFieldUpdateOperationsInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
};

export type AdminPermissionUpsertWithWhereUniqueWithoutRoleInput = {
  create: AdminPermissionCreateWithoutRoleInput;
  update: AdminPermissionUpdateWithoutRoleInput;
  where: AdminPermissionWhereUniqueInput;
};

export type AdminPermissionWhereInput = {
  AND?: InputMaybe<Array<AdminPermissionWhereInput>>;
  NOT?: InputMaybe<Array<AdminPermissionWhereInput>>;
  OR?: InputMaybe<Array<AdminPermissionWhereInput>>;
  createdAt?: InputMaybe<DateTimeFilter>;
  id?: InputMaybe<IntFilter>;
  name?: InputMaybe<StringFilter>;
  role?: InputMaybe<AdminRoleRelationFilter>;
  roleId?: InputMaybe<IntFilter>;
  slug?: InputMaybe<StringFilter>;
  updatedAt?: InputMaybe<DateTimeFilter>;
};

export type AdminPermissionWhereUniqueInput = {
  id?: InputMaybe<Scalars['Int']>;
  name?: InputMaybe<Scalars['String']>;
};

export type AdminRole = {
  __typename?: 'AdminRole';
  _count?: Maybe<AdminRoleCount>;
  admins: Array<AdminUser>;
  createdAt: Scalars['DateTime'];
  description?: Maybe<Scalars['String']>;
  id: Scalars['Int'];
  name: Scalars['String'];
  permissions: Array<AdminPermission>;
  slug: Scalars['String'];
  updatedAt: Scalars['DateTime'];
};


export type AdminRoleAdminsArgs = {
  cursor?: InputMaybe<AdminUserWhereUniqueInput>;
  distinct?: InputMaybe<Array<AdminUserScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<AdminUserOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<AdminUserWhereInput>;
};


export type AdminRolePermissionsArgs = {
  cursor?: InputMaybe<AdminPermissionWhereUniqueInput>;
  distinct?: InputMaybe<Array<AdminPermissionScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<AdminPermissionOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<AdminPermissionWhereInput>;
};

export type AdminRoleCount = {
  __typename?: 'AdminRoleCount';
  admins: Scalars['Int'];
  permissions: Scalars['Int'];
};

export type AdminRoleCreateNestedOneWithoutAdminsInput = {
  connect?: InputMaybe<AdminRoleWhereUniqueInput>;
  connectOrCreate?: InputMaybe<AdminRoleCreateOrConnectWithoutAdminsInput>;
  create?: InputMaybe<AdminRoleCreateWithoutAdminsInput>;
};

export type AdminRoleCreateOrConnectWithoutAdminsInput = {
  create: AdminRoleCreateWithoutAdminsInput;
  where: AdminRoleWhereUniqueInput;
};

export type AdminRoleCreateWithoutAdminsInput = {
  createdAt?: InputMaybe<Scalars['DateTime']>;
  description?: InputMaybe<Scalars['String']>;
  name: Scalars['String'];
  permissions?: InputMaybe<AdminPermissionCreateNestedManyWithoutRoleInput>;
  slug: Scalars['String'];
  updatedAt?: InputMaybe<Scalars['DateTime']>;
};

export type AdminRoleOrderByWithRelationInput = {
  admins?: InputMaybe<AdminUserOrderByRelationAggregateInput>;
  createdAt?: InputMaybe<SortOrder>;
  description?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  name?: InputMaybe<SortOrder>;
  permissions?: InputMaybe<AdminPermissionOrderByRelationAggregateInput>;
  slug?: InputMaybe<SortOrder>;
  updatedAt?: InputMaybe<SortOrder>;
};

export type AdminRoleRelationFilter = {
  is?: InputMaybe<AdminRoleWhereInput>;
  isNot?: InputMaybe<AdminRoleWhereInput>;
};

export type AdminRoleUpdateOneWithoutAdminsInput = {
  connect?: InputMaybe<AdminRoleWhereUniqueInput>;
  connectOrCreate?: InputMaybe<AdminRoleCreateOrConnectWithoutAdminsInput>;
  create?: InputMaybe<AdminRoleCreateWithoutAdminsInput>;
  delete?: InputMaybe<Scalars['Boolean']>;
  disconnect?: InputMaybe<Scalars['Boolean']>;
  update?: InputMaybe<AdminRoleUpdateWithoutAdminsInput>;
  upsert?: InputMaybe<AdminRoleUpsertWithoutAdminsInput>;
};

export type AdminRoleUpdateWithoutAdminsInput = {
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  description?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  name?: InputMaybe<StringFieldUpdateOperationsInput>;
  permissions?: InputMaybe<AdminPermissionUpdateManyWithoutRoleInput>;
  slug?: InputMaybe<StringFieldUpdateOperationsInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
};

export type AdminRoleUpsertWithoutAdminsInput = {
  create: AdminRoleCreateWithoutAdminsInput;
  update: AdminRoleUpdateWithoutAdminsInput;
};

export type AdminRoleWhereInput = {
  AND?: InputMaybe<Array<AdminRoleWhereInput>>;
  NOT?: InputMaybe<Array<AdminRoleWhereInput>>;
  OR?: InputMaybe<Array<AdminRoleWhereInput>>;
  admins?: InputMaybe<AdminUserListRelationFilter>;
  createdAt?: InputMaybe<DateTimeFilter>;
  description?: InputMaybe<StringNullableFilter>;
  id?: InputMaybe<IntFilter>;
  name?: InputMaybe<StringFilter>;
  permissions?: InputMaybe<AdminPermissionListRelationFilter>;
  slug?: InputMaybe<StringFilter>;
  updatedAt?: InputMaybe<DateTimeFilter>;
};

export type AdminRoleWhereUniqueInput = {
  id?: InputMaybe<Scalars['Int']>;
  name?: InputMaybe<Scalars['String']>;
};

export type AdminUser = {
  __typename?: 'AdminUser';
  createdAt: Scalars['DateTime'];
  email: Scalars['String'];
  id: Scalars['String'];
  role?: Maybe<AdminRole>;
  roleId?: Maybe<Scalars['Int']>;
  status: Status;
  updatedAt: Scalars['DateTime'];
  username: Scalars['String'];
};

export type AdminUserAvgAggregate = {
  __typename?: 'AdminUserAvgAggregate';
  roleId?: Maybe<Scalars['Float']>;
};

export type AdminUserAvgOrderByAggregateInput = {
  roleId?: InputMaybe<SortOrder>;
};

export type AdminUserCountAggregate = {
  __typename?: 'AdminUserCountAggregate';
  _all: Scalars['Int'];
  createdAt: Scalars['Int'];
  email: Scalars['Int'];
  id: Scalars['Int'];
  password: Scalars['Int'];
  roleId: Scalars['Int'];
  status: Scalars['Int'];
  updatedAt: Scalars['Int'];
  username: Scalars['Int'];
};

export type AdminUserCountOrderByAggregateInput = {
  createdAt?: InputMaybe<SortOrder>;
  email?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  password?: InputMaybe<SortOrder>;
  roleId?: InputMaybe<SortOrder>;
  status?: InputMaybe<SortOrder>;
  updatedAt?: InputMaybe<SortOrder>;
  username?: InputMaybe<SortOrder>;
};

export type AdminUserCreateInput = {
  createdAt?: InputMaybe<Scalars['DateTime']>;
  email: Scalars['String'];
  id?: InputMaybe<Scalars['String']>;
  password?: InputMaybe<Scalars['String']>;
  role?: InputMaybe<AdminRoleCreateNestedOneWithoutAdminsInput>;
  status?: InputMaybe<Status>;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
  username: Scalars['String'];
};

export type AdminUserCreateManyInput = {
  createdAt?: InputMaybe<Scalars['DateTime']>;
  email: Scalars['String'];
  id?: InputMaybe<Scalars['String']>;
  password?: InputMaybe<Scalars['String']>;
  roleId?: InputMaybe<Scalars['Int']>;
  status?: InputMaybe<Status>;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
  username: Scalars['String'];
};

export type AdminUserGroupBy = {
  __typename?: 'AdminUserGroupBy';
  _avg?: Maybe<AdminUserAvgAggregate>;
  _count?: Maybe<AdminUserCountAggregate>;
  _max?: Maybe<AdminUserMaxAggregate>;
  _min?: Maybe<AdminUserMinAggregate>;
  _sum?: Maybe<AdminUserSumAggregate>;
  createdAt: Scalars['DateTime'];
  email: Scalars['String'];
  id: Scalars['String'];
  password?: Maybe<Scalars['String']>;
  roleId?: Maybe<Scalars['Int']>;
  status: Status;
  updatedAt: Scalars['DateTime'];
  username: Scalars['String'];
};

export type AdminUserListRelationFilter = {
  every?: InputMaybe<AdminUserWhereInput>;
  none?: InputMaybe<AdminUserWhereInput>;
  some?: InputMaybe<AdminUserWhereInput>;
};

export type AdminUserMaxAggregate = {
  __typename?: 'AdminUserMaxAggregate';
  createdAt?: Maybe<Scalars['DateTime']>;
  email?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  password?: Maybe<Scalars['String']>;
  roleId?: Maybe<Scalars['Int']>;
  status?: Maybe<Status>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  username?: Maybe<Scalars['String']>;
};

export type AdminUserMaxOrderByAggregateInput = {
  createdAt?: InputMaybe<SortOrder>;
  email?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  password?: InputMaybe<SortOrder>;
  roleId?: InputMaybe<SortOrder>;
  status?: InputMaybe<SortOrder>;
  updatedAt?: InputMaybe<SortOrder>;
  username?: InputMaybe<SortOrder>;
};

export type AdminUserMinAggregate = {
  __typename?: 'AdminUserMinAggregate';
  createdAt?: Maybe<Scalars['DateTime']>;
  email?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  password?: Maybe<Scalars['String']>;
  roleId?: Maybe<Scalars['Int']>;
  status?: Maybe<Status>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  username?: Maybe<Scalars['String']>;
};

export type AdminUserMinOrderByAggregateInput = {
  createdAt?: InputMaybe<SortOrder>;
  email?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  password?: InputMaybe<SortOrder>;
  roleId?: InputMaybe<SortOrder>;
  status?: InputMaybe<SortOrder>;
  updatedAt?: InputMaybe<SortOrder>;
  username?: InputMaybe<SortOrder>;
};

export type AdminUserOrderByRelationAggregateInput = {
  _count?: InputMaybe<SortOrder>;
};

export type AdminUserOrderByWithAggregationInput = {
  _avg?: InputMaybe<AdminUserAvgOrderByAggregateInput>;
  _count?: InputMaybe<AdminUserCountOrderByAggregateInput>;
  _max?: InputMaybe<AdminUserMaxOrderByAggregateInput>;
  _min?: InputMaybe<AdminUserMinOrderByAggregateInput>;
  _sum?: InputMaybe<AdminUserSumOrderByAggregateInput>;
  createdAt?: InputMaybe<SortOrder>;
  email?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  password?: InputMaybe<SortOrder>;
  roleId?: InputMaybe<SortOrder>;
  status?: InputMaybe<SortOrder>;
  updatedAt?: InputMaybe<SortOrder>;
  username?: InputMaybe<SortOrder>;
};

export type AdminUserOrderByWithRelationInput = {
  createdAt?: InputMaybe<SortOrder>;
  email?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  password?: InputMaybe<SortOrder>;
  role?: InputMaybe<AdminRoleOrderByWithRelationInput>;
  roleId?: InputMaybe<SortOrder>;
  status?: InputMaybe<SortOrder>;
  updatedAt?: InputMaybe<SortOrder>;
  username?: InputMaybe<SortOrder>;
};

export enum AdminUserScalarFieldEnum {
  CreatedAt = 'createdAt',
  Email = 'email',
  Id = 'id',
  Password = 'password',
  RoleId = 'roleId',
  Status = 'status',
  UpdatedAt = 'updatedAt',
  Username = 'username'
}

export type AdminUserScalarWhereWithAggregatesInput = {
  AND?: InputMaybe<Array<AdminUserScalarWhereWithAggregatesInput>>;
  NOT?: InputMaybe<Array<AdminUserScalarWhereWithAggregatesInput>>;
  OR?: InputMaybe<Array<AdminUserScalarWhereWithAggregatesInput>>;
  createdAt?: InputMaybe<DateTimeWithAggregatesFilter>;
  email?: InputMaybe<StringWithAggregatesFilter>;
  id?: InputMaybe<StringWithAggregatesFilter>;
  password?: InputMaybe<StringNullableWithAggregatesFilter>;
  roleId?: InputMaybe<IntNullableWithAggregatesFilter>;
  status?: InputMaybe<EnumStatusWithAggregatesFilter>;
  updatedAt?: InputMaybe<DateTimeWithAggregatesFilter>;
  username?: InputMaybe<StringWithAggregatesFilter>;
};

export type AdminUserSumAggregate = {
  __typename?: 'AdminUserSumAggregate';
  roleId?: Maybe<Scalars['Int']>;
};

export type AdminUserSumOrderByAggregateInput = {
  roleId?: InputMaybe<SortOrder>;
};

export type AdminUserUpdateInput = {
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  email?: InputMaybe<StringFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  password?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  role?: InputMaybe<AdminRoleUpdateOneWithoutAdminsInput>;
  status?: InputMaybe<EnumStatusFieldUpdateOperationsInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  username?: InputMaybe<StringFieldUpdateOperationsInput>;
};

export type AdminUserUpdateManyMutationInput = {
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  email?: InputMaybe<StringFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  password?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  status?: InputMaybe<EnumStatusFieldUpdateOperationsInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  username?: InputMaybe<StringFieldUpdateOperationsInput>;
};

export type AdminUserWhereInput = {
  AND?: InputMaybe<Array<AdminUserWhereInput>>;
  NOT?: InputMaybe<Array<AdminUserWhereInput>>;
  OR?: InputMaybe<Array<AdminUserWhereInput>>;
  createdAt?: InputMaybe<DateTimeFilter>;
  email?: InputMaybe<StringFilter>;
  id?: InputMaybe<StringFilter>;
  password?: InputMaybe<StringNullableFilter>;
  role?: InputMaybe<AdminRoleRelationFilter>;
  roleId?: InputMaybe<IntNullableFilter>;
  status?: InputMaybe<EnumStatusFilter>;
  updatedAt?: InputMaybe<DateTimeFilter>;
  username?: InputMaybe<StringFilter>;
};

export type AdminUserWhereUniqueInput = {
  email?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['String']>;
  username?: InputMaybe<Scalars['String']>;
};

export type AffectedRowsOutput = {
  __typename?: 'AffectedRowsOutput';
  count: Scalars['Int'];
};

export type AggregateAdminUser = {
  __typename?: 'AggregateAdminUser';
  _avg?: Maybe<AdminUserAvgAggregate>;
  _count?: Maybe<AdminUserCountAggregate>;
  _max?: Maybe<AdminUserMaxAggregate>;
  _min?: Maybe<AdminUserMinAggregate>;
  _sum?: Maybe<AdminUserSumAggregate>;
};

export type AggregateChannel = {
  __typename?: 'AggregateChannel';
  _avg?: Maybe<ChannelAvgAggregate>;
  _count?: Maybe<ChannelCountAggregate>;
  _max?: Maybe<ChannelMaxAggregate>;
  _min?: Maybe<ChannelMinAggregate>;
  _sum?: Maybe<ChannelSumAggregate>;
};

export type AggregateChannelFollower = {
  __typename?: 'AggregateChannelFollower';
  _count?: Maybe<ChannelFollowerCountAggregate>;
  _max?: Maybe<ChannelFollowerMaxAggregate>;
  _min?: Maybe<ChannelFollowerMinAggregate>;
};

export type AggregateComment = {
  __typename?: 'AggregateComment';
  _count?: Maybe<CommentCountAggregate>;
  _max?: Maybe<CommentMaxAggregate>;
  _min?: Maybe<CommentMinAggregate>;
};

export type AggregateGift = {
  __typename?: 'AggregateGift';
  _avg?: Maybe<GiftAvgAggregate>;
  _count?: Maybe<GiftCountAggregate>;
  _max?: Maybe<GiftMaxAggregate>;
  _min?: Maybe<GiftMinAggregate>;
  _sum?: Maybe<GiftSumAggregate>;
};

export type AggregateGiftDistribution = {
  __typename?: 'AggregateGiftDistribution';
  _count?: Maybe<GiftDistributionCountAggregate>;
  _max?: Maybe<GiftDistributionMaxAggregate>;
  _min?: Maybe<GiftDistributionMinAggregate>;
};

export type AggregateInquiry = {
  __typename?: 'AggregateInquiry';
  _count?: Maybe<InquiryCountAggregate>;
  _max?: Maybe<InquiryMaxAggregate>;
  _min?: Maybe<InquiryMinAggregate>;
};

export type AggregateLikedVideo = {
  __typename?: 'AggregateLikedVideo';
  _count?: Maybe<LikedVideoCountAggregate>;
  _max?: Maybe<LikedVideoMaxAggregate>;
  _min?: Maybe<LikedVideoMinAggregate>;
};

export type AggregateMintedNft = {
  __typename?: 'AggregateMintedNft';
  _avg?: Maybe<MintedNftAvgAggregate>;
  _count?: Maybe<MintedNftCountAggregate>;
  _max?: Maybe<MintedNftMaxAggregate>;
  _min?: Maybe<MintedNftMinAggregate>;
  _sum?: Maybe<MintedNftSumAggregate>;
};

export type AggregateNftCollection = {
  __typename?: 'AggregateNftCollection';
  _avg?: Maybe<NftCollectionAvgAggregate>;
  _count?: Maybe<NftCollectionCountAggregate>;
  _max?: Maybe<NftCollectionMaxAggregate>;
  _min?: Maybe<NftCollectionMinAggregate>;
  _sum?: Maybe<NftCollectionSumAggregate>;
};

export type AggregatePaymentWebhook = {
  __typename?: 'AggregatePaymentWebhook';
  _count?: Maybe<PaymentWebhookCountAggregate>;
  _max?: Maybe<PaymentWebhookMaxAggregate>;
  _min?: Maybe<PaymentWebhookMinAggregate>;
};

export type AggregatePlaylist = {
  __typename?: 'AggregatePlaylist';
  _count?: Maybe<PlaylistCountAggregate>;
  _max?: Maybe<PlaylistMaxAggregate>;
  _min?: Maybe<PlaylistMinAggregate>;
};

export type AggregatePlaylistVideo = {
  __typename?: 'AggregatePlaylistVideo';
  _count?: Maybe<PlaylistVideoCountAggregate>;
  _max?: Maybe<PlaylistVideoMaxAggregate>;
  _min?: Maybe<PlaylistVideoMinAggregate>;
};

export type AggregateSubscriptionPlan = {
  __typename?: 'AggregateSubscriptionPlan';
  _avg?: Maybe<SubscriptionPlanAvgAggregate>;
  _count?: Maybe<SubscriptionPlanCountAggregate>;
  _max?: Maybe<SubscriptionPlanMaxAggregate>;
  _min?: Maybe<SubscriptionPlanMinAggregate>;
  _sum?: Maybe<SubscriptionPlanSumAggregate>;
};

export type AggregateTips = {
  __typename?: 'AggregateTips';
  _count?: Maybe<TipsCountAggregate>;
  _max?: Maybe<TipsMaxAggregate>;
  _min?: Maybe<TipsMinAggregate>;
};

export type AggregateTransaction = {
  __typename?: 'AggregateTransaction';
  _avg?: Maybe<TransactionAvgAggregate>;
  _count?: Maybe<TransactionCountAggregate>;
  _max?: Maybe<TransactionMaxAggregate>;
  _min?: Maybe<TransactionMinAggregate>;
  _sum?: Maybe<TransactionSumAggregate>;
};

export type AggregateUser = {
  __typename?: 'AggregateUser';
  _count?: Maybe<UserCountAggregate>;
  _max?: Maybe<UserMaxAggregate>;
  _min?: Maybe<UserMinAggregate>;
};

export type AggregateUserPayPerView = {
  __typename?: 'AggregateUserPayPerView';
  _count?: Maybe<UserPayPerViewCountAggregate>;
  _max?: Maybe<UserPayPerViewMaxAggregate>;
  _min?: Maybe<UserPayPerViewMinAggregate>;
};

export type AggregateUserSubscription = {
  __typename?: 'AggregateUserSubscription';
  _count?: Maybe<UserSubscriptionCountAggregate>;
  _max?: Maybe<UserSubscriptionMaxAggregate>;
  _min?: Maybe<UserSubscriptionMinAggregate>;
};

export type AggregateVideo = {
  __typename?: 'AggregateVideo';
  _avg?: Maybe<VideoAvgAggregate>;
  _count?: Maybe<VideoCountAggregate>;
  _max?: Maybe<VideoMaxAggregate>;
  _min?: Maybe<VideoMinAggregate>;
  _sum?: Maybe<VideoSumAggregate>;
};

export type AggregateWatchHistory = {
  __typename?: 'AggregateWatchHistory';
  _count?: Maybe<WatchHistoryCountAggregate>;
  _max?: Maybe<WatchHistoryMaxAggregate>;
  _min?: Maybe<WatchHistoryMinAggregate>;
};

export type Auth = {
  __typename?: 'Auth';
  /** JWT access token */
  accessToken: Scalars['String'];
  expiresIn: Scalars['Float'];
  refreshExpiresIn: Scalars['Float'];
  /** JWT refresh token */
  refreshToken: Scalars['String'];
};

export type AuthPrestoUserProfile = Auth | PrestoUserResponse;

export type BoolFieldUpdateOperationsInput = {
  set?: InputMaybe<Scalars['Boolean']>;
};

export type BoolFilter = {
  equals?: InputMaybe<Scalars['Boolean']>;
  not?: InputMaybe<NestedBoolFilter>;
};

export type BoolWithAggregatesFilter = {
  _count?: InputMaybe<NestedIntFilter>;
  _max?: InputMaybe<NestedBoolFilter>;
  _min?: InputMaybe<NestedBoolFilter>;
  equals?: InputMaybe<Scalars['Boolean']>;
  not?: InputMaybe<NestedBoolWithAggregatesFilter>;
};

export type ChangeMyPasswordInput = {
  currentPassword: Scalars['String'];
  newPassword: Scalars['String'];
};

export type ChangeMyPasswordResponse = {
  __typename?: 'ChangeMyPasswordResponse';
  id: Scalars['String'];
};

export type Channel = {
  __typename?: 'Channel';
  _count?: Maybe<ChannelCount>;
  aboutLinks?: Maybe<Scalars['JSON']>;
  aboutMe?: Maybe<Scalars['String']>;
  activePlaylistCount: Scalars['Float'];
  appLogo?: Maybe<Scalars['String']>;
  approvedAt?: Maybe<Scalars['DateTime']>;
  createdAt: Scalars['DateTime'];
  currencyCode: CurrencyCode;
  followedUsers: Array<ChannelFollower>;
  genres?: Maybe<Scalars['JSON']>;
  gifts: Array<Gift>;
  giftsReceived: Array<GiftDistribution>;
  hasFollowedChannel: Scalars['Boolean'];
  hasSubscribedChannel: Scalars['Boolean'];
  id: Scalars['String'];
  isGiftEnabled: Scalars['Boolean'];
  isSubscriptionEnabled: Scalars['Boolean'];
  lastVideoUploadedAt?: Maybe<Scalars['DateTime']>;
  loginBanner?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  nftCollectionCount: Scalars['Float'];
  playlists: Array<Playlist>;
  primaryHex: Scalars['String'];
  promotionalBanners?: Maybe<Scalars['JSON']>;
  receivedTips: Array<Tips>;
  receivedTransactions: Array<Transaction>;
  rollLogo?: Maybe<Scalars['String']>;
  secondaryHex: Scalars['String'];
  setupCompletedAt?: Maybe<Scalars['DateTime']>;
  shortenedUrls: Array<ShortenedUrl>;
  splashScreenLogo?: Maybe<Scalars['String']>;
  status: Status;
  subscribedUsers: Array<UserSubscription>;
  subscriptionPlans: Array<SubscriptionPlan>;
  themeColor: ThemeColor;
  totalFollowerCount: Scalars['Int'];
  totalReleasedVideoCount: Scalars['Int'];
  updatedAt: Scalars['DateTime'];
  user: User;
  userId: Scalars['String'];
  videoStatistics: Array<VideoStatistic>;
  videos: Array<Video>;
};


export type ChannelFollowedUsersArgs = {
  cursor?: InputMaybe<ChannelFollowerWhereUniqueInput>;
  distinct?: InputMaybe<Array<ChannelFollowerScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<ChannelFollowerOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<ChannelFollowerWhereInput>;
};


export type ChannelGiftsArgs = {
  cursor?: InputMaybe<GiftWhereUniqueInput>;
  distinct?: InputMaybe<Array<GiftScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<GiftOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<GiftWhereInput>;
};


export type ChannelGiftsReceivedArgs = {
  cursor?: InputMaybe<GiftDistributionWhereUniqueInput>;
  distinct?: InputMaybe<Array<GiftDistributionScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<GiftDistributionOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<GiftDistributionWhereInput>;
};


export type ChannelPlaylistsArgs = {
  cursor?: InputMaybe<PlaylistWhereUniqueInput>;
  distinct?: InputMaybe<Array<PlaylistScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<PlaylistOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<PlaylistWhereInput>;
};


export type ChannelReceivedTipsArgs = {
  cursor?: InputMaybe<TipsWhereUniqueInput>;
  distinct?: InputMaybe<Array<TipsScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<TipsOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<TipsWhereInput>;
};


export type ChannelReceivedTransactionsArgs = {
  cursor?: InputMaybe<TransactionWhereUniqueInput>;
  distinct?: InputMaybe<Array<TransactionScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<TransactionOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<TransactionWhereInput>;
};


export type ChannelShortenedUrlsArgs = {
  cursor?: InputMaybe<ShortenedUrlWhereUniqueInput>;
  distinct?: InputMaybe<Array<ShortenedUrlScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<ShortenedUrlOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<ShortenedUrlWhereInput>;
};


export type ChannelSubscribedUsersArgs = {
  cursor?: InputMaybe<UserSubscriptionWhereUniqueInput>;
  distinct?: InputMaybe<Array<UserSubscriptionScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<UserSubscriptionOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<UserSubscriptionWhereInput>;
};


export type ChannelSubscriptionPlansArgs = {
  cursor?: InputMaybe<SubscriptionPlanWhereUniqueInput>;
  distinct?: InputMaybe<Array<SubscriptionPlanScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<SubscriptionPlanOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<SubscriptionPlanWhereInput>;
};


export type ChannelVideoStatisticsArgs = {
  cursor?: InputMaybe<VideoStatisticWhereUniqueInput>;
  distinct?: InputMaybe<Array<VideoStatisticScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<VideoStatisticOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<VideoStatisticWhereInput>;
};


export type ChannelVideosArgs = {
  cursor?: InputMaybe<VideoWhereUniqueInput>;
  distinct?: InputMaybe<Array<VideoScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<VideoOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<VideoWhereInput>;
};

export type ChannelAvgAggregate = {
  __typename?: 'ChannelAvgAggregate';
  totalFollowerCount?: Maybe<Scalars['Float']>;
  totalReleasedVideoCount?: Maybe<Scalars['Float']>;
};

export type ChannelAvgOrderByAggregateInput = {
  totalFollowerCount?: InputMaybe<SortOrder>;
  totalReleasedVideoCount?: InputMaybe<SortOrder>;
};

export type ChannelCount = {
  __typename?: 'ChannelCount';
  followedUsers: Scalars['Int'];
  gifts: Scalars['Int'];
  giftsReceived: Scalars['Int'];
  playlists: Scalars['Int'];
  receivedTips: Scalars['Int'];
  receivedTransactions: Scalars['Int'];
  shortenedUrls: Scalars['Int'];
  subscribedUsers: Scalars['Int'];
  subscriptionPlans: Scalars['Int'];
  videoStatistics: Scalars['Int'];
  videos: Scalars['Int'];
};

export type ChannelCountAggregate = {
  __typename?: 'ChannelCountAggregate';
  _all: Scalars['Int'];
  aboutLinks: Scalars['Int'];
  aboutMe: Scalars['Int'];
  appLogo: Scalars['Int'];
  approvedAt: Scalars['Int'];
  createdAt: Scalars['Int'];
  currencyCode: Scalars['Int'];
  genres: Scalars['Int'];
  id: Scalars['Int'];
  isGiftEnabled: Scalars['Int'];
  isSubscriptionEnabled: Scalars['Int'];
  lastVideoUploadedAt: Scalars['Int'];
  loginBanner: Scalars['Int'];
  name: Scalars['Int'];
  primaryHex: Scalars['Int'];
  promotionalBanners: Scalars['Int'];
  rollLogo: Scalars['Int'];
  secondaryHex: Scalars['Int'];
  setupCompletedAt: Scalars['Int'];
  splashScreenLogo: Scalars['Int'];
  status: Scalars['Int'];
  themeColor: Scalars['Int'];
  totalFollowerCount: Scalars['Int'];
  totalReleasedVideoCount: Scalars['Int'];
  updatedAt: Scalars['Int'];
  userId: Scalars['Int'];
};

export type ChannelCountOrderByAggregateInput = {
  aboutLinks?: InputMaybe<SortOrder>;
  aboutMe?: InputMaybe<SortOrder>;
  appLogo?: InputMaybe<SortOrder>;
  approvedAt?: InputMaybe<SortOrder>;
  createdAt?: InputMaybe<SortOrder>;
  currencyCode?: InputMaybe<SortOrder>;
  genres?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  isGiftEnabled?: InputMaybe<SortOrder>;
  isSubscriptionEnabled?: InputMaybe<SortOrder>;
  lastVideoUploadedAt?: InputMaybe<SortOrder>;
  loginBanner?: InputMaybe<SortOrder>;
  name?: InputMaybe<SortOrder>;
  primaryHex?: InputMaybe<SortOrder>;
  promotionalBanners?: InputMaybe<SortOrder>;
  rollLogo?: InputMaybe<SortOrder>;
  secondaryHex?: InputMaybe<SortOrder>;
  setupCompletedAt?: InputMaybe<SortOrder>;
  splashScreenLogo?: InputMaybe<SortOrder>;
  status?: InputMaybe<SortOrder>;
  themeColor?: InputMaybe<SortOrder>;
  totalFollowerCount?: InputMaybe<SortOrder>;
  totalReleasedVideoCount?: InputMaybe<SortOrder>;
  updatedAt?: InputMaybe<SortOrder>;
  userId?: InputMaybe<SortOrder>;
};

export type ChannelCreateInput = {
  aboutLinks?: InputMaybe<Scalars['JSON']>;
  aboutMe?: InputMaybe<Scalars['String']>;
  appLogo?: InputMaybe<Scalars['String']>;
  approvedAt?: InputMaybe<Scalars['DateTime']>;
  createdAt?: InputMaybe<Scalars['DateTime']>;
  currencyCode?: InputMaybe<CurrencyCode>;
  followedUsers?: InputMaybe<ChannelFollowerCreateNestedManyWithoutFolloweeInput>;
  genres?: InputMaybe<Scalars['JSON']>;
  gifts?: InputMaybe<GiftCreateNestedManyWithoutChannelInput>;
  giftsReceived?: InputMaybe<GiftDistributionCreateNestedManyWithoutReceiverInput>;
  id?: InputMaybe<Scalars['String']>;
  isGiftEnabled?: InputMaybe<Scalars['Boolean']>;
  isSubscriptionEnabled?: InputMaybe<Scalars['Boolean']>;
  lastVideoUploadedAt?: InputMaybe<Scalars['DateTime']>;
  loginBanner?: InputMaybe<Scalars['String']>;
  name?: InputMaybe<Scalars['String']>;
  playlists?: InputMaybe<PlaylistCreateNestedManyWithoutChannelInput>;
  primaryHex?: InputMaybe<Scalars['String']>;
  promotionalBanners?: InputMaybe<Scalars['JSON']>;
  receivedTips?: InputMaybe<TipsCreateNestedManyWithoutRecipientInput>;
  receivedTransactions?: InputMaybe<TransactionCreateNestedManyWithoutPaymentToInput>;
  rollLogo?: InputMaybe<Scalars['String']>;
  secondaryHex?: InputMaybe<Scalars['String']>;
  setupCompletedAt?: InputMaybe<Scalars['DateTime']>;
  shortenedUrls?: InputMaybe<ShortenedUrlCreateNestedManyWithoutChannelInput>;
  splashScreenLogo?: InputMaybe<Scalars['String']>;
  status?: InputMaybe<Status>;
  subscribedUsers?: InputMaybe<UserSubscriptionCreateNestedManyWithoutSubscribedChannelInput>;
  subscriptionPlans?: InputMaybe<SubscriptionPlanCreateNestedManyWithoutChannelInput>;
  themeColor?: InputMaybe<ThemeColor>;
  totalFollowerCount?: InputMaybe<Scalars['Int']>;
  totalReleasedVideoCount?: InputMaybe<Scalars['Int']>;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
  user: UserCreateNestedOneWithoutChannelInput;
  videoStatistics?: InputMaybe<VideoStatisticCreateNestedManyWithoutChannelInput>;
  videos?: InputMaybe<VideoCreateNestedManyWithoutChannelInput>;
};

export type ChannelCreateManyInput = {
  aboutLinks?: InputMaybe<Scalars['JSON']>;
  aboutMe?: InputMaybe<Scalars['String']>;
  appLogo?: InputMaybe<Scalars['String']>;
  approvedAt?: InputMaybe<Scalars['DateTime']>;
  createdAt?: InputMaybe<Scalars['DateTime']>;
  currencyCode?: InputMaybe<CurrencyCode>;
  genres?: InputMaybe<Scalars['JSON']>;
  id?: InputMaybe<Scalars['String']>;
  isGiftEnabled?: InputMaybe<Scalars['Boolean']>;
  isSubscriptionEnabled?: InputMaybe<Scalars['Boolean']>;
  lastVideoUploadedAt?: InputMaybe<Scalars['DateTime']>;
  loginBanner?: InputMaybe<Scalars['String']>;
  name?: InputMaybe<Scalars['String']>;
  primaryHex?: InputMaybe<Scalars['String']>;
  promotionalBanners?: InputMaybe<Scalars['JSON']>;
  rollLogo?: InputMaybe<Scalars['String']>;
  secondaryHex?: InputMaybe<Scalars['String']>;
  setupCompletedAt?: InputMaybe<Scalars['DateTime']>;
  splashScreenLogo?: InputMaybe<Scalars['String']>;
  status?: InputMaybe<Status>;
  themeColor?: InputMaybe<ThemeColor>;
  totalFollowerCount?: InputMaybe<Scalars['Int']>;
  totalReleasedVideoCount?: InputMaybe<Scalars['Int']>;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
  userId: Scalars['String'];
};

export type ChannelCreateNestedOneWithoutFollowedUsersInput = {
  connect?: InputMaybe<ChannelWhereUniqueInput>;
  connectOrCreate?: InputMaybe<ChannelCreateOrConnectWithoutFollowedUsersInput>;
  create?: InputMaybe<ChannelCreateWithoutFollowedUsersInput>;
};

export type ChannelCreateNestedOneWithoutGiftsInput = {
  connect?: InputMaybe<ChannelWhereUniqueInput>;
  connectOrCreate?: InputMaybe<ChannelCreateOrConnectWithoutGiftsInput>;
  create?: InputMaybe<ChannelCreateWithoutGiftsInput>;
};

export type ChannelCreateNestedOneWithoutGiftsReceivedInput = {
  connect?: InputMaybe<ChannelWhereUniqueInput>;
  connectOrCreate?: InputMaybe<ChannelCreateOrConnectWithoutGiftsReceivedInput>;
  create?: InputMaybe<ChannelCreateWithoutGiftsReceivedInput>;
};

export type ChannelCreateNestedOneWithoutPlaylistsInput = {
  connect?: InputMaybe<ChannelWhereUniqueInput>;
  connectOrCreate?: InputMaybe<ChannelCreateOrConnectWithoutPlaylistsInput>;
  create?: InputMaybe<ChannelCreateWithoutPlaylistsInput>;
};

export type ChannelCreateNestedOneWithoutReceivedTipsInput = {
  connect?: InputMaybe<ChannelWhereUniqueInput>;
  connectOrCreate?: InputMaybe<ChannelCreateOrConnectWithoutReceivedTipsInput>;
  create?: InputMaybe<ChannelCreateWithoutReceivedTipsInput>;
};

export type ChannelCreateNestedOneWithoutReceivedTransactionsInput = {
  connect?: InputMaybe<ChannelWhereUniqueInput>;
  connectOrCreate?: InputMaybe<ChannelCreateOrConnectWithoutReceivedTransactionsInput>;
  create?: InputMaybe<ChannelCreateWithoutReceivedTransactionsInput>;
};

export type ChannelCreateNestedOneWithoutSubscribedUsersInput = {
  connect?: InputMaybe<ChannelWhereUniqueInput>;
  connectOrCreate?: InputMaybe<ChannelCreateOrConnectWithoutSubscribedUsersInput>;
  create?: InputMaybe<ChannelCreateWithoutSubscribedUsersInput>;
};

export type ChannelCreateNestedOneWithoutSubscriptionPlansInput = {
  connect?: InputMaybe<ChannelWhereUniqueInput>;
  connectOrCreate?: InputMaybe<ChannelCreateOrConnectWithoutSubscriptionPlansInput>;
  create?: InputMaybe<ChannelCreateWithoutSubscriptionPlansInput>;
};

export type ChannelCreateNestedOneWithoutUserInput = {
  connect?: InputMaybe<ChannelWhereUniqueInput>;
  connectOrCreate?: InputMaybe<ChannelCreateOrConnectWithoutUserInput>;
  create?: InputMaybe<ChannelCreateWithoutUserInput>;
};

export type ChannelCreateNestedOneWithoutVideoStatisticsInput = {
  connect?: InputMaybe<ChannelWhereUniqueInput>;
  connectOrCreate?: InputMaybe<ChannelCreateOrConnectWithoutVideoStatisticsInput>;
  create?: InputMaybe<ChannelCreateWithoutVideoStatisticsInput>;
};

export type ChannelCreateNestedOneWithoutVideosInput = {
  connect?: InputMaybe<ChannelWhereUniqueInput>;
  connectOrCreate?: InputMaybe<ChannelCreateOrConnectWithoutVideosInput>;
  create?: InputMaybe<ChannelCreateWithoutVideosInput>;
};

export type ChannelCreateOrConnectWithoutFollowedUsersInput = {
  create: ChannelCreateWithoutFollowedUsersInput;
  where: ChannelWhereUniqueInput;
};

export type ChannelCreateOrConnectWithoutGiftsInput = {
  create: ChannelCreateWithoutGiftsInput;
  where: ChannelWhereUniqueInput;
};

export type ChannelCreateOrConnectWithoutGiftsReceivedInput = {
  create: ChannelCreateWithoutGiftsReceivedInput;
  where: ChannelWhereUniqueInput;
};

export type ChannelCreateOrConnectWithoutPlaylistsInput = {
  create: ChannelCreateWithoutPlaylistsInput;
  where: ChannelWhereUniqueInput;
};

export type ChannelCreateOrConnectWithoutReceivedTipsInput = {
  create: ChannelCreateWithoutReceivedTipsInput;
  where: ChannelWhereUniqueInput;
};

export type ChannelCreateOrConnectWithoutReceivedTransactionsInput = {
  create: ChannelCreateWithoutReceivedTransactionsInput;
  where: ChannelWhereUniqueInput;
};

export type ChannelCreateOrConnectWithoutSubscribedUsersInput = {
  create: ChannelCreateWithoutSubscribedUsersInput;
  where: ChannelWhereUniqueInput;
};

export type ChannelCreateOrConnectWithoutSubscriptionPlansInput = {
  create: ChannelCreateWithoutSubscriptionPlansInput;
  where: ChannelWhereUniqueInput;
};

export type ChannelCreateOrConnectWithoutUserInput = {
  create: ChannelCreateWithoutUserInput;
  where: ChannelWhereUniqueInput;
};

export type ChannelCreateOrConnectWithoutVideoStatisticsInput = {
  create: ChannelCreateWithoutVideoStatisticsInput;
  where: ChannelWhereUniqueInput;
};

export type ChannelCreateOrConnectWithoutVideosInput = {
  create: ChannelCreateWithoutVideosInput;
  where: ChannelWhereUniqueInput;
};

export type ChannelCreateWithoutFollowedUsersInput = {
  aboutLinks?: InputMaybe<Scalars['JSON']>;
  aboutMe?: InputMaybe<Scalars['String']>;
  appLogo?: InputMaybe<Scalars['String']>;
  approvedAt?: InputMaybe<Scalars['DateTime']>;
  createdAt?: InputMaybe<Scalars['DateTime']>;
  currencyCode?: InputMaybe<CurrencyCode>;
  genres?: InputMaybe<Scalars['JSON']>;
  gifts?: InputMaybe<GiftCreateNestedManyWithoutChannelInput>;
  giftsReceived?: InputMaybe<GiftDistributionCreateNestedManyWithoutReceiverInput>;
  id?: InputMaybe<Scalars['String']>;
  isGiftEnabled?: InputMaybe<Scalars['Boolean']>;
  isSubscriptionEnabled?: InputMaybe<Scalars['Boolean']>;
  lastVideoUploadedAt?: InputMaybe<Scalars['DateTime']>;
  loginBanner?: InputMaybe<Scalars['String']>;
  name?: InputMaybe<Scalars['String']>;
  playlists?: InputMaybe<PlaylistCreateNestedManyWithoutChannelInput>;
  primaryHex?: InputMaybe<Scalars['String']>;
  promotionalBanners?: InputMaybe<Scalars['JSON']>;
  receivedTips?: InputMaybe<TipsCreateNestedManyWithoutRecipientInput>;
  receivedTransactions?: InputMaybe<TransactionCreateNestedManyWithoutPaymentToInput>;
  rollLogo?: InputMaybe<Scalars['String']>;
  secondaryHex?: InputMaybe<Scalars['String']>;
  setupCompletedAt?: InputMaybe<Scalars['DateTime']>;
  shortenedUrls?: InputMaybe<ShortenedUrlCreateNestedManyWithoutChannelInput>;
  splashScreenLogo?: InputMaybe<Scalars['String']>;
  status?: InputMaybe<Status>;
  subscribedUsers?: InputMaybe<UserSubscriptionCreateNestedManyWithoutSubscribedChannelInput>;
  subscriptionPlans?: InputMaybe<SubscriptionPlanCreateNestedManyWithoutChannelInput>;
  themeColor?: InputMaybe<ThemeColor>;
  totalFollowerCount?: InputMaybe<Scalars['Int']>;
  totalReleasedVideoCount?: InputMaybe<Scalars['Int']>;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
  user: UserCreateNestedOneWithoutChannelInput;
  videoStatistics?: InputMaybe<VideoStatisticCreateNestedManyWithoutChannelInput>;
  videos?: InputMaybe<VideoCreateNestedManyWithoutChannelInput>;
};

export type ChannelCreateWithoutGiftsInput = {
  aboutLinks?: InputMaybe<Scalars['JSON']>;
  aboutMe?: InputMaybe<Scalars['String']>;
  appLogo?: InputMaybe<Scalars['String']>;
  approvedAt?: InputMaybe<Scalars['DateTime']>;
  createdAt?: InputMaybe<Scalars['DateTime']>;
  currencyCode?: InputMaybe<CurrencyCode>;
  followedUsers?: InputMaybe<ChannelFollowerCreateNestedManyWithoutFolloweeInput>;
  genres?: InputMaybe<Scalars['JSON']>;
  giftsReceived?: InputMaybe<GiftDistributionCreateNestedManyWithoutReceiverInput>;
  id?: InputMaybe<Scalars['String']>;
  isGiftEnabled?: InputMaybe<Scalars['Boolean']>;
  isSubscriptionEnabled?: InputMaybe<Scalars['Boolean']>;
  lastVideoUploadedAt?: InputMaybe<Scalars['DateTime']>;
  loginBanner?: InputMaybe<Scalars['String']>;
  name?: InputMaybe<Scalars['String']>;
  playlists?: InputMaybe<PlaylistCreateNestedManyWithoutChannelInput>;
  primaryHex?: InputMaybe<Scalars['String']>;
  promotionalBanners?: InputMaybe<Scalars['JSON']>;
  receivedTips?: InputMaybe<TipsCreateNestedManyWithoutRecipientInput>;
  receivedTransactions?: InputMaybe<TransactionCreateNestedManyWithoutPaymentToInput>;
  rollLogo?: InputMaybe<Scalars['String']>;
  secondaryHex?: InputMaybe<Scalars['String']>;
  setupCompletedAt?: InputMaybe<Scalars['DateTime']>;
  shortenedUrls?: InputMaybe<ShortenedUrlCreateNestedManyWithoutChannelInput>;
  splashScreenLogo?: InputMaybe<Scalars['String']>;
  status?: InputMaybe<Status>;
  subscribedUsers?: InputMaybe<UserSubscriptionCreateNestedManyWithoutSubscribedChannelInput>;
  subscriptionPlans?: InputMaybe<SubscriptionPlanCreateNestedManyWithoutChannelInput>;
  themeColor?: InputMaybe<ThemeColor>;
  totalFollowerCount?: InputMaybe<Scalars['Int']>;
  totalReleasedVideoCount?: InputMaybe<Scalars['Int']>;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
  user: UserCreateNestedOneWithoutChannelInput;
  videoStatistics?: InputMaybe<VideoStatisticCreateNestedManyWithoutChannelInput>;
  videos?: InputMaybe<VideoCreateNestedManyWithoutChannelInput>;
};

export type ChannelCreateWithoutGiftsReceivedInput = {
  aboutLinks?: InputMaybe<Scalars['JSON']>;
  aboutMe?: InputMaybe<Scalars['String']>;
  appLogo?: InputMaybe<Scalars['String']>;
  approvedAt?: InputMaybe<Scalars['DateTime']>;
  createdAt?: InputMaybe<Scalars['DateTime']>;
  currencyCode?: InputMaybe<CurrencyCode>;
  followedUsers?: InputMaybe<ChannelFollowerCreateNestedManyWithoutFolloweeInput>;
  genres?: InputMaybe<Scalars['JSON']>;
  gifts?: InputMaybe<GiftCreateNestedManyWithoutChannelInput>;
  id?: InputMaybe<Scalars['String']>;
  isGiftEnabled?: InputMaybe<Scalars['Boolean']>;
  isSubscriptionEnabled?: InputMaybe<Scalars['Boolean']>;
  lastVideoUploadedAt?: InputMaybe<Scalars['DateTime']>;
  loginBanner?: InputMaybe<Scalars['String']>;
  name?: InputMaybe<Scalars['String']>;
  playlists?: InputMaybe<PlaylistCreateNestedManyWithoutChannelInput>;
  primaryHex?: InputMaybe<Scalars['String']>;
  promotionalBanners?: InputMaybe<Scalars['JSON']>;
  receivedTips?: InputMaybe<TipsCreateNestedManyWithoutRecipientInput>;
  receivedTransactions?: InputMaybe<TransactionCreateNestedManyWithoutPaymentToInput>;
  rollLogo?: InputMaybe<Scalars['String']>;
  secondaryHex?: InputMaybe<Scalars['String']>;
  setupCompletedAt?: InputMaybe<Scalars['DateTime']>;
  shortenedUrls?: InputMaybe<ShortenedUrlCreateNestedManyWithoutChannelInput>;
  splashScreenLogo?: InputMaybe<Scalars['String']>;
  status?: InputMaybe<Status>;
  subscribedUsers?: InputMaybe<UserSubscriptionCreateNestedManyWithoutSubscribedChannelInput>;
  subscriptionPlans?: InputMaybe<SubscriptionPlanCreateNestedManyWithoutChannelInput>;
  themeColor?: InputMaybe<ThemeColor>;
  totalFollowerCount?: InputMaybe<Scalars['Int']>;
  totalReleasedVideoCount?: InputMaybe<Scalars['Int']>;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
  user: UserCreateNestedOneWithoutChannelInput;
  videoStatistics?: InputMaybe<VideoStatisticCreateNestedManyWithoutChannelInput>;
  videos?: InputMaybe<VideoCreateNestedManyWithoutChannelInput>;
};

export type ChannelCreateWithoutPlaylistsInput = {
  aboutLinks?: InputMaybe<Scalars['JSON']>;
  aboutMe?: InputMaybe<Scalars['String']>;
  appLogo?: InputMaybe<Scalars['String']>;
  approvedAt?: InputMaybe<Scalars['DateTime']>;
  createdAt?: InputMaybe<Scalars['DateTime']>;
  currencyCode?: InputMaybe<CurrencyCode>;
  followedUsers?: InputMaybe<ChannelFollowerCreateNestedManyWithoutFolloweeInput>;
  genres?: InputMaybe<Scalars['JSON']>;
  gifts?: InputMaybe<GiftCreateNestedManyWithoutChannelInput>;
  giftsReceived?: InputMaybe<GiftDistributionCreateNestedManyWithoutReceiverInput>;
  id?: InputMaybe<Scalars['String']>;
  isGiftEnabled?: InputMaybe<Scalars['Boolean']>;
  isSubscriptionEnabled?: InputMaybe<Scalars['Boolean']>;
  lastVideoUploadedAt?: InputMaybe<Scalars['DateTime']>;
  loginBanner?: InputMaybe<Scalars['String']>;
  name?: InputMaybe<Scalars['String']>;
  primaryHex?: InputMaybe<Scalars['String']>;
  promotionalBanners?: InputMaybe<Scalars['JSON']>;
  receivedTips?: InputMaybe<TipsCreateNestedManyWithoutRecipientInput>;
  receivedTransactions?: InputMaybe<TransactionCreateNestedManyWithoutPaymentToInput>;
  rollLogo?: InputMaybe<Scalars['String']>;
  secondaryHex?: InputMaybe<Scalars['String']>;
  setupCompletedAt?: InputMaybe<Scalars['DateTime']>;
  shortenedUrls?: InputMaybe<ShortenedUrlCreateNestedManyWithoutChannelInput>;
  splashScreenLogo?: InputMaybe<Scalars['String']>;
  status?: InputMaybe<Status>;
  subscribedUsers?: InputMaybe<UserSubscriptionCreateNestedManyWithoutSubscribedChannelInput>;
  subscriptionPlans?: InputMaybe<SubscriptionPlanCreateNestedManyWithoutChannelInput>;
  themeColor?: InputMaybe<ThemeColor>;
  totalFollowerCount?: InputMaybe<Scalars['Int']>;
  totalReleasedVideoCount?: InputMaybe<Scalars['Int']>;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
  user: UserCreateNestedOneWithoutChannelInput;
  videoStatistics?: InputMaybe<VideoStatisticCreateNestedManyWithoutChannelInput>;
  videos?: InputMaybe<VideoCreateNestedManyWithoutChannelInput>;
};

export type ChannelCreateWithoutReceivedTipsInput = {
  aboutLinks?: InputMaybe<Scalars['JSON']>;
  aboutMe?: InputMaybe<Scalars['String']>;
  appLogo?: InputMaybe<Scalars['String']>;
  approvedAt?: InputMaybe<Scalars['DateTime']>;
  createdAt?: InputMaybe<Scalars['DateTime']>;
  currencyCode?: InputMaybe<CurrencyCode>;
  followedUsers?: InputMaybe<ChannelFollowerCreateNestedManyWithoutFolloweeInput>;
  genres?: InputMaybe<Scalars['JSON']>;
  gifts?: InputMaybe<GiftCreateNestedManyWithoutChannelInput>;
  giftsReceived?: InputMaybe<GiftDistributionCreateNestedManyWithoutReceiverInput>;
  id?: InputMaybe<Scalars['String']>;
  isGiftEnabled?: InputMaybe<Scalars['Boolean']>;
  isSubscriptionEnabled?: InputMaybe<Scalars['Boolean']>;
  lastVideoUploadedAt?: InputMaybe<Scalars['DateTime']>;
  loginBanner?: InputMaybe<Scalars['String']>;
  name?: InputMaybe<Scalars['String']>;
  playlists?: InputMaybe<PlaylistCreateNestedManyWithoutChannelInput>;
  primaryHex?: InputMaybe<Scalars['String']>;
  promotionalBanners?: InputMaybe<Scalars['JSON']>;
  receivedTransactions?: InputMaybe<TransactionCreateNestedManyWithoutPaymentToInput>;
  rollLogo?: InputMaybe<Scalars['String']>;
  secondaryHex?: InputMaybe<Scalars['String']>;
  setupCompletedAt?: InputMaybe<Scalars['DateTime']>;
  shortenedUrls?: InputMaybe<ShortenedUrlCreateNestedManyWithoutChannelInput>;
  splashScreenLogo?: InputMaybe<Scalars['String']>;
  status?: InputMaybe<Status>;
  subscribedUsers?: InputMaybe<UserSubscriptionCreateNestedManyWithoutSubscribedChannelInput>;
  subscriptionPlans?: InputMaybe<SubscriptionPlanCreateNestedManyWithoutChannelInput>;
  themeColor?: InputMaybe<ThemeColor>;
  totalFollowerCount?: InputMaybe<Scalars['Int']>;
  totalReleasedVideoCount?: InputMaybe<Scalars['Int']>;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
  user: UserCreateNestedOneWithoutChannelInput;
  videoStatistics?: InputMaybe<VideoStatisticCreateNestedManyWithoutChannelInput>;
  videos?: InputMaybe<VideoCreateNestedManyWithoutChannelInput>;
};

export type ChannelCreateWithoutReceivedTransactionsInput = {
  aboutLinks?: InputMaybe<Scalars['JSON']>;
  aboutMe?: InputMaybe<Scalars['String']>;
  appLogo?: InputMaybe<Scalars['String']>;
  approvedAt?: InputMaybe<Scalars['DateTime']>;
  createdAt?: InputMaybe<Scalars['DateTime']>;
  currencyCode?: InputMaybe<CurrencyCode>;
  followedUsers?: InputMaybe<ChannelFollowerCreateNestedManyWithoutFolloweeInput>;
  genres?: InputMaybe<Scalars['JSON']>;
  gifts?: InputMaybe<GiftCreateNestedManyWithoutChannelInput>;
  giftsReceived?: InputMaybe<GiftDistributionCreateNestedManyWithoutReceiverInput>;
  id?: InputMaybe<Scalars['String']>;
  isGiftEnabled?: InputMaybe<Scalars['Boolean']>;
  isSubscriptionEnabled?: InputMaybe<Scalars['Boolean']>;
  lastVideoUploadedAt?: InputMaybe<Scalars['DateTime']>;
  loginBanner?: InputMaybe<Scalars['String']>;
  name?: InputMaybe<Scalars['String']>;
  playlists?: InputMaybe<PlaylistCreateNestedManyWithoutChannelInput>;
  primaryHex?: InputMaybe<Scalars['String']>;
  promotionalBanners?: InputMaybe<Scalars['JSON']>;
  receivedTips?: InputMaybe<TipsCreateNestedManyWithoutRecipientInput>;
  rollLogo?: InputMaybe<Scalars['String']>;
  secondaryHex?: InputMaybe<Scalars['String']>;
  setupCompletedAt?: InputMaybe<Scalars['DateTime']>;
  shortenedUrls?: InputMaybe<ShortenedUrlCreateNestedManyWithoutChannelInput>;
  splashScreenLogo?: InputMaybe<Scalars['String']>;
  status?: InputMaybe<Status>;
  subscribedUsers?: InputMaybe<UserSubscriptionCreateNestedManyWithoutSubscribedChannelInput>;
  subscriptionPlans?: InputMaybe<SubscriptionPlanCreateNestedManyWithoutChannelInput>;
  themeColor?: InputMaybe<ThemeColor>;
  totalFollowerCount?: InputMaybe<Scalars['Int']>;
  totalReleasedVideoCount?: InputMaybe<Scalars['Int']>;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
  user: UserCreateNestedOneWithoutChannelInput;
  videoStatistics?: InputMaybe<VideoStatisticCreateNestedManyWithoutChannelInput>;
  videos?: InputMaybe<VideoCreateNestedManyWithoutChannelInput>;
};

export type ChannelCreateWithoutSubscribedUsersInput = {
  aboutLinks?: InputMaybe<Scalars['JSON']>;
  aboutMe?: InputMaybe<Scalars['String']>;
  appLogo?: InputMaybe<Scalars['String']>;
  approvedAt?: InputMaybe<Scalars['DateTime']>;
  createdAt?: InputMaybe<Scalars['DateTime']>;
  currencyCode?: InputMaybe<CurrencyCode>;
  followedUsers?: InputMaybe<ChannelFollowerCreateNestedManyWithoutFolloweeInput>;
  genres?: InputMaybe<Scalars['JSON']>;
  gifts?: InputMaybe<GiftCreateNestedManyWithoutChannelInput>;
  giftsReceived?: InputMaybe<GiftDistributionCreateNestedManyWithoutReceiverInput>;
  id?: InputMaybe<Scalars['String']>;
  isGiftEnabled?: InputMaybe<Scalars['Boolean']>;
  isSubscriptionEnabled?: InputMaybe<Scalars['Boolean']>;
  lastVideoUploadedAt?: InputMaybe<Scalars['DateTime']>;
  loginBanner?: InputMaybe<Scalars['String']>;
  name?: InputMaybe<Scalars['String']>;
  playlists?: InputMaybe<PlaylistCreateNestedManyWithoutChannelInput>;
  primaryHex?: InputMaybe<Scalars['String']>;
  promotionalBanners?: InputMaybe<Scalars['JSON']>;
  receivedTips?: InputMaybe<TipsCreateNestedManyWithoutRecipientInput>;
  receivedTransactions?: InputMaybe<TransactionCreateNestedManyWithoutPaymentToInput>;
  rollLogo?: InputMaybe<Scalars['String']>;
  secondaryHex?: InputMaybe<Scalars['String']>;
  setupCompletedAt?: InputMaybe<Scalars['DateTime']>;
  shortenedUrls?: InputMaybe<ShortenedUrlCreateNestedManyWithoutChannelInput>;
  splashScreenLogo?: InputMaybe<Scalars['String']>;
  status?: InputMaybe<Status>;
  subscriptionPlans?: InputMaybe<SubscriptionPlanCreateNestedManyWithoutChannelInput>;
  themeColor?: InputMaybe<ThemeColor>;
  totalFollowerCount?: InputMaybe<Scalars['Int']>;
  totalReleasedVideoCount?: InputMaybe<Scalars['Int']>;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
  user: UserCreateNestedOneWithoutChannelInput;
  videoStatistics?: InputMaybe<VideoStatisticCreateNestedManyWithoutChannelInput>;
  videos?: InputMaybe<VideoCreateNestedManyWithoutChannelInput>;
};

export type ChannelCreateWithoutSubscriptionPlansInput = {
  aboutLinks?: InputMaybe<Scalars['JSON']>;
  aboutMe?: InputMaybe<Scalars['String']>;
  appLogo?: InputMaybe<Scalars['String']>;
  approvedAt?: InputMaybe<Scalars['DateTime']>;
  createdAt?: InputMaybe<Scalars['DateTime']>;
  currencyCode?: InputMaybe<CurrencyCode>;
  followedUsers?: InputMaybe<ChannelFollowerCreateNestedManyWithoutFolloweeInput>;
  genres?: InputMaybe<Scalars['JSON']>;
  gifts?: InputMaybe<GiftCreateNestedManyWithoutChannelInput>;
  giftsReceived?: InputMaybe<GiftDistributionCreateNestedManyWithoutReceiverInput>;
  id?: InputMaybe<Scalars['String']>;
  isGiftEnabled?: InputMaybe<Scalars['Boolean']>;
  isSubscriptionEnabled?: InputMaybe<Scalars['Boolean']>;
  lastVideoUploadedAt?: InputMaybe<Scalars['DateTime']>;
  loginBanner?: InputMaybe<Scalars['String']>;
  name?: InputMaybe<Scalars['String']>;
  playlists?: InputMaybe<PlaylistCreateNestedManyWithoutChannelInput>;
  primaryHex?: InputMaybe<Scalars['String']>;
  promotionalBanners?: InputMaybe<Scalars['JSON']>;
  receivedTips?: InputMaybe<TipsCreateNestedManyWithoutRecipientInput>;
  receivedTransactions?: InputMaybe<TransactionCreateNestedManyWithoutPaymentToInput>;
  rollLogo?: InputMaybe<Scalars['String']>;
  secondaryHex?: InputMaybe<Scalars['String']>;
  setupCompletedAt?: InputMaybe<Scalars['DateTime']>;
  shortenedUrls?: InputMaybe<ShortenedUrlCreateNestedManyWithoutChannelInput>;
  splashScreenLogo?: InputMaybe<Scalars['String']>;
  status?: InputMaybe<Status>;
  subscribedUsers?: InputMaybe<UserSubscriptionCreateNestedManyWithoutSubscribedChannelInput>;
  themeColor?: InputMaybe<ThemeColor>;
  totalFollowerCount?: InputMaybe<Scalars['Int']>;
  totalReleasedVideoCount?: InputMaybe<Scalars['Int']>;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
  user: UserCreateNestedOneWithoutChannelInput;
  videoStatistics?: InputMaybe<VideoStatisticCreateNestedManyWithoutChannelInput>;
  videos?: InputMaybe<VideoCreateNestedManyWithoutChannelInput>;
};

export type ChannelCreateWithoutUserInput = {
  aboutLinks?: InputMaybe<Scalars['JSON']>;
  aboutMe?: InputMaybe<Scalars['String']>;
  appLogo?: InputMaybe<Scalars['String']>;
  approvedAt?: InputMaybe<Scalars['DateTime']>;
  createdAt?: InputMaybe<Scalars['DateTime']>;
  currencyCode?: InputMaybe<CurrencyCode>;
  followedUsers?: InputMaybe<ChannelFollowerCreateNestedManyWithoutFolloweeInput>;
  genres?: InputMaybe<Scalars['JSON']>;
  gifts?: InputMaybe<GiftCreateNestedManyWithoutChannelInput>;
  giftsReceived?: InputMaybe<GiftDistributionCreateNestedManyWithoutReceiverInput>;
  id?: InputMaybe<Scalars['String']>;
  isGiftEnabled?: InputMaybe<Scalars['Boolean']>;
  isSubscriptionEnabled?: InputMaybe<Scalars['Boolean']>;
  lastVideoUploadedAt?: InputMaybe<Scalars['DateTime']>;
  loginBanner?: InputMaybe<Scalars['String']>;
  name?: InputMaybe<Scalars['String']>;
  playlists?: InputMaybe<PlaylistCreateNestedManyWithoutChannelInput>;
  primaryHex?: InputMaybe<Scalars['String']>;
  promotionalBanners?: InputMaybe<Scalars['JSON']>;
  receivedTips?: InputMaybe<TipsCreateNestedManyWithoutRecipientInput>;
  receivedTransactions?: InputMaybe<TransactionCreateNestedManyWithoutPaymentToInput>;
  rollLogo?: InputMaybe<Scalars['String']>;
  secondaryHex?: InputMaybe<Scalars['String']>;
  setupCompletedAt?: InputMaybe<Scalars['DateTime']>;
  shortenedUrls?: InputMaybe<ShortenedUrlCreateNestedManyWithoutChannelInput>;
  splashScreenLogo?: InputMaybe<Scalars['String']>;
  status?: InputMaybe<Status>;
  subscribedUsers?: InputMaybe<UserSubscriptionCreateNestedManyWithoutSubscribedChannelInput>;
  subscriptionPlans?: InputMaybe<SubscriptionPlanCreateNestedManyWithoutChannelInput>;
  themeColor?: InputMaybe<ThemeColor>;
  totalFollowerCount?: InputMaybe<Scalars['Int']>;
  totalReleasedVideoCount?: InputMaybe<Scalars['Int']>;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
  videoStatistics?: InputMaybe<VideoStatisticCreateNestedManyWithoutChannelInput>;
  videos?: InputMaybe<VideoCreateNestedManyWithoutChannelInput>;
};

export type ChannelCreateWithoutVideoStatisticsInput = {
  aboutLinks?: InputMaybe<Scalars['JSON']>;
  aboutMe?: InputMaybe<Scalars['String']>;
  appLogo?: InputMaybe<Scalars['String']>;
  approvedAt?: InputMaybe<Scalars['DateTime']>;
  createdAt?: InputMaybe<Scalars['DateTime']>;
  currencyCode?: InputMaybe<CurrencyCode>;
  followedUsers?: InputMaybe<ChannelFollowerCreateNestedManyWithoutFolloweeInput>;
  genres?: InputMaybe<Scalars['JSON']>;
  gifts?: InputMaybe<GiftCreateNestedManyWithoutChannelInput>;
  giftsReceived?: InputMaybe<GiftDistributionCreateNestedManyWithoutReceiverInput>;
  id?: InputMaybe<Scalars['String']>;
  isGiftEnabled?: InputMaybe<Scalars['Boolean']>;
  isSubscriptionEnabled?: InputMaybe<Scalars['Boolean']>;
  lastVideoUploadedAt?: InputMaybe<Scalars['DateTime']>;
  loginBanner?: InputMaybe<Scalars['String']>;
  name?: InputMaybe<Scalars['String']>;
  playlists?: InputMaybe<PlaylistCreateNestedManyWithoutChannelInput>;
  primaryHex?: InputMaybe<Scalars['String']>;
  promotionalBanners?: InputMaybe<Scalars['JSON']>;
  receivedTips?: InputMaybe<TipsCreateNestedManyWithoutRecipientInput>;
  receivedTransactions?: InputMaybe<TransactionCreateNestedManyWithoutPaymentToInput>;
  rollLogo?: InputMaybe<Scalars['String']>;
  secondaryHex?: InputMaybe<Scalars['String']>;
  setupCompletedAt?: InputMaybe<Scalars['DateTime']>;
  shortenedUrls?: InputMaybe<ShortenedUrlCreateNestedManyWithoutChannelInput>;
  splashScreenLogo?: InputMaybe<Scalars['String']>;
  status?: InputMaybe<Status>;
  subscribedUsers?: InputMaybe<UserSubscriptionCreateNestedManyWithoutSubscribedChannelInput>;
  subscriptionPlans?: InputMaybe<SubscriptionPlanCreateNestedManyWithoutChannelInput>;
  themeColor?: InputMaybe<ThemeColor>;
  totalFollowerCount?: InputMaybe<Scalars['Int']>;
  totalReleasedVideoCount?: InputMaybe<Scalars['Int']>;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
  user: UserCreateNestedOneWithoutChannelInput;
  videos?: InputMaybe<VideoCreateNestedManyWithoutChannelInput>;
};

export type ChannelCreateWithoutVideosInput = {
  aboutLinks?: InputMaybe<Scalars['JSON']>;
  aboutMe?: InputMaybe<Scalars['String']>;
  appLogo?: InputMaybe<Scalars['String']>;
  approvedAt?: InputMaybe<Scalars['DateTime']>;
  createdAt?: InputMaybe<Scalars['DateTime']>;
  currencyCode?: InputMaybe<CurrencyCode>;
  followedUsers?: InputMaybe<ChannelFollowerCreateNestedManyWithoutFolloweeInput>;
  genres?: InputMaybe<Scalars['JSON']>;
  gifts?: InputMaybe<GiftCreateNestedManyWithoutChannelInput>;
  giftsReceived?: InputMaybe<GiftDistributionCreateNestedManyWithoutReceiverInput>;
  id?: InputMaybe<Scalars['String']>;
  isGiftEnabled?: InputMaybe<Scalars['Boolean']>;
  isSubscriptionEnabled?: InputMaybe<Scalars['Boolean']>;
  lastVideoUploadedAt?: InputMaybe<Scalars['DateTime']>;
  loginBanner?: InputMaybe<Scalars['String']>;
  name?: InputMaybe<Scalars['String']>;
  playlists?: InputMaybe<PlaylistCreateNestedManyWithoutChannelInput>;
  primaryHex?: InputMaybe<Scalars['String']>;
  promotionalBanners?: InputMaybe<Scalars['JSON']>;
  receivedTips?: InputMaybe<TipsCreateNestedManyWithoutRecipientInput>;
  receivedTransactions?: InputMaybe<TransactionCreateNestedManyWithoutPaymentToInput>;
  rollLogo?: InputMaybe<Scalars['String']>;
  secondaryHex?: InputMaybe<Scalars['String']>;
  setupCompletedAt?: InputMaybe<Scalars['DateTime']>;
  shortenedUrls?: InputMaybe<ShortenedUrlCreateNestedManyWithoutChannelInput>;
  splashScreenLogo?: InputMaybe<Scalars['String']>;
  status?: InputMaybe<Status>;
  subscribedUsers?: InputMaybe<UserSubscriptionCreateNestedManyWithoutSubscribedChannelInput>;
  subscriptionPlans?: InputMaybe<SubscriptionPlanCreateNestedManyWithoutChannelInput>;
  themeColor?: InputMaybe<ThemeColor>;
  totalFollowerCount?: InputMaybe<Scalars['Int']>;
  totalReleasedVideoCount?: InputMaybe<Scalars['Int']>;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
  user: UserCreateNestedOneWithoutChannelInput;
  videoStatistics?: InputMaybe<VideoStatisticCreateNestedManyWithoutChannelInput>;
};

export type ChannelFollower = {
  __typename?: 'ChannelFollower';
  channelId: Scalars['String'];
  createdAt: Scalars['DateTime'];
  followee: Channel;
  followeeOwner: User;
  followeeOwnerId: Scalars['String'];
  follower: User;
  followerId: Scalars['String'];
  id: Scalars['String'];
  updatedAt: Scalars['DateTime'];
};

export type ChannelFollowerChannelIdFollowerIdCompoundUniqueInput = {
  channelId: Scalars['String'];
  followerId: Scalars['String'];
};

export type ChannelFollowerCountAggregate = {
  __typename?: 'ChannelFollowerCountAggregate';
  _all: Scalars['Int'];
  channelId: Scalars['Int'];
  createdAt: Scalars['Int'];
  followeeOwnerId: Scalars['Int'];
  followerId: Scalars['Int'];
  id: Scalars['Int'];
  updatedAt: Scalars['Int'];
};

export type ChannelFollowerCountOrderByAggregateInput = {
  channelId?: InputMaybe<SortOrder>;
  createdAt?: InputMaybe<SortOrder>;
  followeeOwnerId?: InputMaybe<SortOrder>;
  followerId?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  updatedAt?: InputMaybe<SortOrder>;
};

export type ChannelFollowerCreateInput = {
  createdAt?: InputMaybe<Scalars['DateTime']>;
  followee: ChannelCreateNestedOneWithoutFollowedUsersInput;
  followeeOwner: UserCreateNestedOneWithoutFollowedByInput;
  follower: UserCreateNestedOneWithoutFollowedChannelsInput;
  id?: InputMaybe<Scalars['String']>;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
};

export type ChannelFollowerCreateManyFolloweeInput = {
  createdAt?: InputMaybe<Scalars['DateTime']>;
  followeeOwnerId: Scalars['String'];
  followerId: Scalars['String'];
  id?: InputMaybe<Scalars['String']>;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
};

export type ChannelFollowerCreateManyFolloweeInputEnvelope = {
  data: Array<ChannelFollowerCreateManyFolloweeInput>;
  skipDuplicates?: InputMaybe<Scalars['Boolean']>;
};

export type ChannelFollowerCreateManyFolloweeOwnerInput = {
  channelId: Scalars['String'];
  createdAt?: InputMaybe<Scalars['DateTime']>;
  followerId: Scalars['String'];
  id?: InputMaybe<Scalars['String']>;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
};

export type ChannelFollowerCreateManyFolloweeOwnerInputEnvelope = {
  data: Array<ChannelFollowerCreateManyFolloweeOwnerInput>;
  skipDuplicates?: InputMaybe<Scalars['Boolean']>;
};

export type ChannelFollowerCreateManyFollowerInput = {
  channelId: Scalars['String'];
  createdAt?: InputMaybe<Scalars['DateTime']>;
  followeeOwnerId: Scalars['String'];
  id?: InputMaybe<Scalars['String']>;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
};

export type ChannelFollowerCreateManyFollowerInputEnvelope = {
  data: Array<ChannelFollowerCreateManyFollowerInput>;
  skipDuplicates?: InputMaybe<Scalars['Boolean']>;
};

export type ChannelFollowerCreateManyInput = {
  channelId: Scalars['String'];
  createdAt?: InputMaybe<Scalars['DateTime']>;
  followeeOwnerId: Scalars['String'];
  followerId: Scalars['String'];
  id?: InputMaybe<Scalars['String']>;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
};

export type ChannelFollowerCreateNestedManyWithoutFolloweeInput = {
  connect?: InputMaybe<Array<ChannelFollowerWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<ChannelFollowerCreateOrConnectWithoutFolloweeInput>>;
  create?: InputMaybe<Array<ChannelFollowerCreateWithoutFolloweeInput>>;
  createMany?: InputMaybe<ChannelFollowerCreateManyFolloweeInputEnvelope>;
};

export type ChannelFollowerCreateNestedManyWithoutFolloweeOwnerInput = {
  connect?: InputMaybe<Array<ChannelFollowerWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<ChannelFollowerCreateOrConnectWithoutFolloweeOwnerInput>>;
  create?: InputMaybe<Array<ChannelFollowerCreateWithoutFolloweeOwnerInput>>;
  createMany?: InputMaybe<ChannelFollowerCreateManyFolloweeOwnerInputEnvelope>;
};

export type ChannelFollowerCreateNestedManyWithoutFollowerInput = {
  connect?: InputMaybe<Array<ChannelFollowerWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<ChannelFollowerCreateOrConnectWithoutFollowerInput>>;
  create?: InputMaybe<Array<ChannelFollowerCreateWithoutFollowerInput>>;
  createMany?: InputMaybe<ChannelFollowerCreateManyFollowerInputEnvelope>;
};

export type ChannelFollowerCreateOrConnectWithoutFolloweeInput = {
  create: ChannelFollowerCreateWithoutFolloweeInput;
  where: ChannelFollowerWhereUniqueInput;
};

export type ChannelFollowerCreateOrConnectWithoutFolloweeOwnerInput = {
  create: ChannelFollowerCreateWithoutFolloweeOwnerInput;
  where: ChannelFollowerWhereUniqueInput;
};

export type ChannelFollowerCreateOrConnectWithoutFollowerInput = {
  create: ChannelFollowerCreateWithoutFollowerInput;
  where: ChannelFollowerWhereUniqueInput;
};

export type ChannelFollowerCreateWithoutFolloweeInput = {
  createdAt?: InputMaybe<Scalars['DateTime']>;
  followeeOwner: UserCreateNestedOneWithoutFollowedByInput;
  follower: UserCreateNestedOneWithoutFollowedChannelsInput;
  id?: InputMaybe<Scalars['String']>;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
};

export type ChannelFollowerCreateWithoutFolloweeOwnerInput = {
  createdAt?: InputMaybe<Scalars['DateTime']>;
  followee: ChannelCreateNestedOneWithoutFollowedUsersInput;
  follower: UserCreateNestedOneWithoutFollowedChannelsInput;
  id?: InputMaybe<Scalars['String']>;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
};

export type ChannelFollowerCreateWithoutFollowerInput = {
  createdAt?: InputMaybe<Scalars['DateTime']>;
  followee: ChannelCreateNestedOneWithoutFollowedUsersInput;
  followeeOwner: UserCreateNestedOneWithoutFollowedByInput;
  id?: InputMaybe<Scalars['String']>;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
};

export type ChannelFollowerGroupBy = {
  __typename?: 'ChannelFollowerGroupBy';
  _count?: Maybe<ChannelFollowerCountAggregate>;
  _max?: Maybe<ChannelFollowerMaxAggregate>;
  _min?: Maybe<ChannelFollowerMinAggregate>;
  channelId: Scalars['String'];
  createdAt: Scalars['DateTime'];
  followeeOwnerId: Scalars['String'];
  followerId: Scalars['String'];
  id: Scalars['String'];
  updatedAt: Scalars['DateTime'];
};

export type ChannelFollowerListRelationFilter = {
  every?: InputMaybe<ChannelFollowerWhereInput>;
  none?: InputMaybe<ChannelFollowerWhereInput>;
  some?: InputMaybe<ChannelFollowerWhereInput>;
};

export type ChannelFollowerMaxAggregate = {
  __typename?: 'ChannelFollowerMaxAggregate';
  channelId?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  followeeOwnerId?: Maybe<Scalars['String']>;
  followerId?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type ChannelFollowerMaxOrderByAggregateInput = {
  channelId?: InputMaybe<SortOrder>;
  createdAt?: InputMaybe<SortOrder>;
  followeeOwnerId?: InputMaybe<SortOrder>;
  followerId?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  updatedAt?: InputMaybe<SortOrder>;
};

export type ChannelFollowerMinAggregate = {
  __typename?: 'ChannelFollowerMinAggregate';
  channelId?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  followeeOwnerId?: Maybe<Scalars['String']>;
  followerId?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type ChannelFollowerMinOrderByAggregateInput = {
  channelId?: InputMaybe<SortOrder>;
  createdAt?: InputMaybe<SortOrder>;
  followeeOwnerId?: InputMaybe<SortOrder>;
  followerId?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  updatedAt?: InputMaybe<SortOrder>;
};

export type ChannelFollowerOrderByRelationAggregateInput = {
  _count?: InputMaybe<SortOrder>;
};

export type ChannelFollowerOrderByWithAggregationInput = {
  _count?: InputMaybe<ChannelFollowerCountOrderByAggregateInput>;
  _max?: InputMaybe<ChannelFollowerMaxOrderByAggregateInput>;
  _min?: InputMaybe<ChannelFollowerMinOrderByAggregateInput>;
  channelId?: InputMaybe<SortOrder>;
  createdAt?: InputMaybe<SortOrder>;
  followeeOwnerId?: InputMaybe<SortOrder>;
  followerId?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  updatedAt?: InputMaybe<SortOrder>;
};

export type ChannelFollowerOrderByWithRelationInput = {
  channelId?: InputMaybe<SortOrder>;
  createdAt?: InputMaybe<SortOrder>;
  followee?: InputMaybe<ChannelOrderByWithRelationInput>;
  followeeOwner?: InputMaybe<UserOrderByWithRelationInput>;
  followeeOwnerId?: InputMaybe<SortOrder>;
  follower?: InputMaybe<UserOrderByWithRelationInput>;
  followerId?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  updatedAt?: InputMaybe<SortOrder>;
};

export enum ChannelFollowerScalarFieldEnum {
  ChannelId = 'channelId',
  CreatedAt = 'createdAt',
  FolloweeOwnerId = 'followeeOwnerId',
  FollowerId = 'followerId',
  Id = 'id',
  UpdatedAt = 'updatedAt'
}

export type ChannelFollowerScalarWhereInput = {
  AND?: InputMaybe<Array<ChannelFollowerScalarWhereInput>>;
  NOT?: InputMaybe<Array<ChannelFollowerScalarWhereInput>>;
  OR?: InputMaybe<Array<ChannelFollowerScalarWhereInput>>;
  channelId?: InputMaybe<StringFilter>;
  createdAt?: InputMaybe<DateTimeFilter>;
  followeeOwnerId?: InputMaybe<StringFilter>;
  followerId?: InputMaybe<StringFilter>;
  id?: InputMaybe<StringFilter>;
  updatedAt?: InputMaybe<DateTimeFilter>;
};

export type ChannelFollowerScalarWhereWithAggregatesInput = {
  AND?: InputMaybe<Array<ChannelFollowerScalarWhereWithAggregatesInput>>;
  NOT?: InputMaybe<Array<ChannelFollowerScalarWhereWithAggregatesInput>>;
  OR?: InputMaybe<Array<ChannelFollowerScalarWhereWithAggregatesInput>>;
  channelId?: InputMaybe<StringWithAggregatesFilter>;
  createdAt?: InputMaybe<DateTimeWithAggregatesFilter>;
  followeeOwnerId?: InputMaybe<StringWithAggregatesFilter>;
  followerId?: InputMaybe<StringWithAggregatesFilter>;
  id?: InputMaybe<StringWithAggregatesFilter>;
  updatedAt?: InputMaybe<DateTimeWithAggregatesFilter>;
};

export type ChannelFollowerUpdateInput = {
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  followee?: InputMaybe<ChannelUpdateOneRequiredWithoutFollowedUsersInput>;
  followeeOwner?: InputMaybe<UserUpdateOneRequiredWithoutFollowedByInput>;
  follower?: InputMaybe<UserUpdateOneRequiredWithoutFollowedChannelsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
};

export type ChannelFollowerUpdateManyMutationInput = {
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
};

export type ChannelFollowerUpdateManyWithWhereWithoutFolloweeInput = {
  data: ChannelFollowerUpdateManyMutationInput;
  where: ChannelFollowerScalarWhereInput;
};

export type ChannelFollowerUpdateManyWithWhereWithoutFolloweeOwnerInput = {
  data: ChannelFollowerUpdateManyMutationInput;
  where: ChannelFollowerScalarWhereInput;
};

export type ChannelFollowerUpdateManyWithWhereWithoutFollowerInput = {
  data: ChannelFollowerUpdateManyMutationInput;
  where: ChannelFollowerScalarWhereInput;
};

export type ChannelFollowerUpdateManyWithoutFolloweeInput = {
  connect?: InputMaybe<Array<ChannelFollowerWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<ChannelFollowerCreateOrConnectWithoutFolloweeInput>>;
  create?: InputMaybe<Array<ChannelFollowerCreateWithoutFolloweeInput>>;
  createMany?: InputMaybe<ChannelFollowerCreateManyFolloweeInputEnvelope>;
  delete?: InputMaybe<Array<ChannelFollowerWhereUniqueInput>>;
  deleteMany?: InputMaybe<Array<ChannelFollowerScalarWhereInput>>;
  disconnect?: InputMaybe<Array<ChannelFollowerWhereUniqueInput>>;
  set?: InputMaybe<Array<ChannelFollowerWhereUniqueInput>>;
  update?: InputMaybe<Array<ChannelFollowerUpdateWithWhereUniqueWithoutFolloweeInput>>;
  updateMany?: InputMaybe<Array<ChannelFollowerUpdateManyWithWhereWithoutFolloweeInput>>;
  upsert?: InputMaybe<Array<ChannelFollowerUpsertWithWhereUniqueWithoutFolloweeInput>>;
};

export type ChannelFollowerUpdateManyWithoutFolloweeOwnerInput = {
  connect?: InputMaybe<Array<ChannelFollowerWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<ChannelFollowerCreateOrConnectWithoutFolloweeOwnerInput>>;
  create?: InputMaybe<Array<ChannelFollowerCreateWithoutFolloweeOwnerInput>>;
  createMany?: InputMaybe<ChannelFollowerCreateManyFolloweeOwnerInputEnvelope>;
  delete?: InputMaybe<Array<ChannelFollowerWhereUniqueInput>>;
  deleteMany?: InputMaybe<Array<ChannelFollowerScalarWhereInput>>;
  disconnect?: InputMaybe<Array<ChannelFollowerWhereUniqueInput>>;
  set?: InputMaybe<Array<ChannelFollowerWhereUniqueInput>>;
  update?: InputMaybe<Array<ChannelFollowerUpdateWithWhereUniqueWithoutFolloweeOwnerInput>>;
  updateMany?: InputMaybe<Array<ChannelFollowerUpdateManyWithWhereWithoutFolloweeOwnerInput>>;
  upsert?: InputMaybe<Array<ChannelFollowerUpsertWithWhereUniqueWithoutFolloweeOwnerInput>>;
};

export type ChannelFollowerUpdateManyWithoutFollowerInput = {
  connect?: InputMaybe<Array<ChannelFollowerWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<ChannelFollowerCreateOrConnectWithoutFollowerInput>>;
  create?: InputMaybe<Array<ChannelFollowerCreateWithoutFollowerInput>>;
  createMany?: InputMaybe<ChannelFollowerCreateManyFollowerInputEnvelope>;
  delete?: InputMaybe<Array<ChannelFollowerWhereUniqueInput>>;
  deleteMany?: InputMaybe<Array<ChannelFollowerScalarWhereInput>>;
  disconnect?: InputMaybe<Array<ChannelFollowerWhereUniqueInput>>;
  set?: InputMaybe<Array<ChannelFollowerWhereUniqueInput>>;
  update?: InputMaybe<Array<ChannelFollowerUpdateWithWhereUniqueWithoutFollowerInput>>;
  updateMany?: InputMaybe<Array<ChannelFollowerUpdateManyWithWhereWithoutFollowerInput>>;
  upsert?: InputMaybe<Array<ChannelFollowerUpsertWithWhereUniqueWithoutFollowerInput>>;
};

export type ChannelFollowerUpdateWithWhereUniqueWithoutFolloweeInput = {
  data: ChannelFollowerUpdateWithoutFolloweeInput;
  where: ChannelFollowerWhereUniqueInput;
};

export type ChannelFollowerUpdateWithWhereUniqueWithoutFolloweeOwnerInput = {
  data: ChannelFollowerUpdateWithoutFolloweeOwnerInput;
  where: ChannelFollowerWhereUniqueInput;
};

export type ChannelFollowerUpdateWithWhereUniqueWithoutFollowerInput = {
  data: ChannelFollowerUpdateWithoutFollowerInput;
  where: ChannelFollowerWhereUniqueInput;
};

export type ChannelFollowerUpdateWithoutFolloweeInput = {
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  followeeOwner?: InputMaybe<UserUpdateOneRequiredWithoutFollowedByInput>;
  follower?: InputMaybe<UserUpdateOneRequiredWithoutFollowedChannelsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
};

export type ChannelFollowerUpdateWithoutFolloweeOwnerInput = {
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  followee?: InputMaybe<ChannelUpdateOneRequiredWithoutFollowedUsersInput>;
  follower?: InputMaybe<UserUpdateOneRequiredWithoutFollowedChannelsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
};

export type ChannelFollowerUpdateWithoutFollowerInput = {
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  followee?: InputMaybe<ChannelUpdateOneRequiredWithoutFollowedUsersInput>;
  followeeOwner?: InputMaybe<UserUpdateOneRequiredWithoutFollowedByInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
};

export type ChannelFollowerUpsertWithWhereUniqueWithoutFolloweeInput = {
  create: ChannelFollowerCreateWithoutFolloweeInput;
  update: ChannelFollowerUpdateWithoutFolloweeInput;
  where: ChannelFollowerWhereUniqueInput;
};

export type ChannelFollowerUpsertWithWhereUniqueWithoutFolloweeOwnerInput = {
  create: ChannelFollowerCreateWithoutFolloweeOwnerInput;
  update: ChannelFollowerUpdateWithoutFolloweeOwnerInput;
  where: ChannelFollowerWhereUniqueInput;
};

export type ChannelFollowerUpsertWithWhereUniqueWithoutFollowerInput = {
  create: ChannelFollowerCreateWithoutFollowerInput;
  update: ChannelFollowerUpdateWithoutFollowerInput;
  where: ChannelFollowerWhereUniqueInput;
};

export type ChannelFollowerWhereInput = {
  AND?: InputMaybe<Array<ChannelFollowerWhereInput>>;
  NOT?: InputMaybe<Array<ChannelFollowerWhereInput>>;
  OR?: InputMaybe<Array<ChannelFollowerWhereInput>>;
  channelId?: InputMaybe<StringFilter>;
  createdAt?: InputMaybe<DateTimeFilter>;
  followee?: InputMaybe<ChannelRelationFilter>;
  followeeOwner?: InputMaybe<UserRelationFilter>;
  followeeOwnerId?: InputMaybe<StringFilter>;
  follower?: InputMaybe<UserRelationFilter>;
  followerId?: InputMaybe<StringFilter>;
  id?: InputMaybe<StringFilter>;
  updatedAt?: InputMaybe<DateTimeFilter>;
};

export type ChannelFollowerWhereUniqueInput = {
  channelId_followerId?: InputMaybe<ChannelFollowerChannelIdFollowerIdCompoundUniqueInput>;
  id?: InputMaybe<Scalars['String']>;
};

export type ChannelGroupBy = {
  __typename?: 'ChannelGroupBy';
  _avg?: Maybe<ChannelAvgAggregate>;
  _count?: Maybe<ChannelCountAggregate>;
  _max?: Maybe<ChannelMaxAggregate>;
  _min?: Maybe<ChannelMinAggregate>;
  _sum?: Maybe<ChannelSumAggregate>;
  aboutLinks?: Maybe<Scalars['JSON']>;
  aboutMe?: Maybe<Scalars['String']>;
  appLogo?: Maybe<Scalars['String']>;
  approvedAt?: Maybe<Scalars['DateTime']>;
  createdAt: Scalars['DateTime'];
  currencyCode: CurrencyCode;
  genres?: Maybe<Scalars['JSON']>;
  id: Scalars['String'];
  isGiftEnabled: Scalars['Boolean'];
  isSubscriptionEnabled: Scalars['Boolean'];
  lastVideoUploadedAt?: Maybe<Scalars['DateTime']>;
  loginBanner?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  primaryHex: Scalars['String'];
  promotionalBanners?: Maybe<Scalars['JSON']>;
  rollLogo?: Maybe<Scalars['String']>;
  secondaryHex: Scalars['String'];
  setupCompletedAt?: Maybe<Scalars['DateTime']>;
  splashScreenLogo?: Maybe<Scalars['String']>;
  status: Status;
  themeColor: ThemeColor;
  totalFollowerCount: Scalars['Int'];
  totalReleasedVideoCount: Scalars['Int'];
  updatedAt: Scalars['DateTime'];
  userId: Scalars['String'];
};

export type ChannelMaxAggregate = {
  __typename?: 'ChannelMaxAggregate';
  aboutMe?: Maybe<Scalars['String']>;
  appLogo?: Maybe<Scalars['String']>;
  approvedAt?: Maybe<Scalars['DateTime']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  currencyCode?: Maybe<CurrencyCode>;
  id?: Maybe<Scalars['String']>;
  isGiftEnabled?: Maybe<Scalars['Boolean']>;
  isSubscriptionEnabled?: Maybe<Scalars['Boolean']>;
  lastVideoUploadedAt?: Maybe<Scalars['DateTime']>;
  loginBanner?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  primaryHex?: Maybe<Scalars['String']>;
  rollLogo?: Maybe<Scalars['String']>;
  secondaryHex?: Maybe<Scalars['String']>;
  setupCompletedAt?: Maybe<Scalars['DateTime']>;
  splashScreenLogo?: Maybe<Scalars['String']>;
  status?: Maybe<Status>;
  themeColor?: Maybe<ThemeColor>;
  totalFollowerCount?: Maybe<Scalars['Int']>;
  totalReleasedVideoCount?: Maybe<Scalars['Int']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  userId?: Maybe<Scalars['String']>;
};

export type ChannelMaxOrderByAggregateInput = {
  aboutMe?: InputMaybe<SortOrder>;
  appLogo?: InputMaybe<SortOrder>;
  approvedAt?: InputMaybe<SortOrder>;
  createdAt?: InputMaybe<SortOrder>;
  currencyCode?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  isGiftEnabled?: InputMaybe<SortOrder>;
  isSubscriptionEnabled?: InputMaybe<SortOrder>;
  lastVideoUploadedAt?: InputMaybe<SortOrder>;
  loginBanner?: InputMaybe<SortOrder>;
  name?: InputMaybe<SortOrder>;
  primaryHex?: InputMaybe<SortOrder>;
  rollLogo?: InputMaybe<SortOrder>;
  secondaryHex?: InputMaybe<SortOrder>;
  setupCompletedAt?: InputMaybe<SortOrder>;
  splashScreenLogo?: InputMaybe<SortOrder>;
  status?: InputMaybe<SortOrder>;
  themeColor?: InputMaybe<SortOrder>;
  totalFollowerCount?: InputMaybe<SortOrder>;
  totalReleasedVideoCount?: InputMaybe<SortOrder>;
  updatedAt?: InputMaybe<SortOrder>;
  userId?: InputMaybe<SortOrder>;
};

export type ChannelMinAggregate = {
  __typename?: 'ChannelMinAggregate';
  aboutMe?: Maybe<Scalars['String']>;
  appLogo?: Maybe<Scalars['String']>;
  approvedAt?: Maybe<Scalars['DateTime']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  currencyCode?: Maybe<CurrencyCode>;
  id?: Maybe<Scalars['String']>;
  isGiftEnabled?: Maybe<Scalars['Boolean']>;
  isSubscriptionEnabled?: Maybe<Scalars['Boolean']>;
  lastVideoUploadedAt?: Maybe<Scalars['DateTime']>;
  loginBanner?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  primaryHex?: Maybe<Scalars['String']>;
  rollLogo?: Maybe<Scalars['String']>;
  secondaryHex?: Maybe<Scalars['String']>;
  setupCompletedAt?: Maybe<Scalars['DateTime']>;
  splashScreenLogo?: Maybe<Scalars['String']>;
  status?: Maybe<Status>;
  themeColor?: Maybe<ThemeColor>;
  totalFollowerCount?: Maybe<Scalars['Int']>;
  totalReleasedVideoCount?: Maybe<Scalars['Int']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  userId?: Maybe<Scalars['String']>;
};

export type ChannelMinOrderByAggregateInput = {
  aboutMe?: InputMaybe<SortOrder>;
  appLogo?: InputMaybe<SortOrder>;
  approvedAt?: InputMaybe<SortOrder>;
  createdAt?: InputMaybe<SortOrder>;
  currencyCode?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  isGiftEnabled?: InputMaybe<SortOrder>;
  isSubscriptionEnabled?: InputMaybe<SortOrder>;
  lastVideoUploadedAt?: InputMaybe<SortOrder>;
  loginBanner?: InputMaybe<SortOrder>;
  name?: InputMaybe<SortOrder>;
  primaryHex?: InputMaybe<SortOrder>;
  rollLogo?: InputMaybe<SortOrder>;
  secondaryHex?: InputMaybe<SortOrder>;
  setupCompletedAt?: InputMaybe<SortOrder>;
  splashScreenLogo?: InputMaybe<SortOrder>;
  status?: InputMaybe<SortOrder>;
  themeColor?: InputMaybe<SortOrder>;
  totalFollowerCount?: InputMaybe<SortOrder>;
  totalReleasedVideoCount?: InputMaybe<SortOrder>;
  updatedAt?: InputMaybe<SortOrder>;
  userId?: InputMaybe<SortOrder>;
};

export type ChannelOrderByWithAggregationInput = {
  _avg?: InputMaybe<ChannelAvgOrderByAggregateInput>;
  _count?: InputMaybe<ChannelCountOrderByAggregateInput>;
  _max?: InputMaybe<ChannelMaxOrderByAggregateInput>;
  _min?: InputMaybe<ChannelMinOrderByAggregateInput>;
  _sum?: InputMaybe<ChannelSumOrderByAggregateInput>;
  aboutLinks?: InputMaybe<SortOrder>;
  aboutMe?: InputMaybe<SortOrder>;
  appLogo?: InputMaybe<SortOrder>;
  approvedAt?: InputMaybe<SortOrder>;
  createdAt?: InputMaybe<SortOrder>;
  currencyCode?: InputMaybe<SortOrder>;
  genres?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  isGiftEnabled?: InputMaybe<SortOrder>;
  isSubscriptionEnabled?: InputMaybe<SortOrder>;
  lastVideoUploadedAt?: InputMaybe<SortOrder>;
  loginBanner?: InputMaybe<SortOrder>;
  name?: InputMaybe<SortOrder>;
  primaryHex?: InputMaybe<SortOrder>;
  promotionalBanners?: InputMaybe<SortOrder>;
  rollLogo?: InputMaybe<SortOrder>;
  secondaryHex?: InputMaybe<SortOrder>;
  setupCompletedAt?: InputMaybe<SortOrder>;
  splashScreenLogo?: InputMaybe<SortOrder>;
  status?: InputMaybe<SortOrder>;
  themeColor?: InputMaybe<SortOrder>;
  totalFollowerCount?: InputMaybe<SortOrder>;
  totalReleasedVideoCount?: InputMaybe<SortOrder>;
  updatedAt?: InputMaybe<SortOrder>;
  userId?: InputMaybe<SortOrder>;
};

export type ChannelOrderByWithRelationInput = {
  aboutLinks?: InputMaybe<SortOrder>;
  aboutMe?: InputMaybe<SortOrder>;
  appLogo?: InputMaybe<SortOrder>;
  approvedAt?: InputMaybe<SortOrder>;
  createdAt?: InputMaybe<SortOrder>;
  currencyCode?: InputMaybe<SortOrder>;
  followedUsers?: InputMaybe<ChannelFollowerOrderByRelationAggregateInput>;
  genres?: InputMaybe<SortOrder>;
  gifts?: InputMaybe<GiftOrderByRelationAggregateInput>;
  giftsReceived?: InputMaybe<GiftDistributionOrderByRelationAggregateInput>;
  id?: InputMaybe<SortOrder>;
  isGiftEnabled?: InputMaybe<SortOrder>;
  isSubscriptionEnabled?: InputMaybe<SortOrder>;
  lastVideoUploadedAt?: InputMaybe<SortOrder>;
  loginBanner?: InputMaybe<SortOrder>;
  name?: InputMaybe<SortOrder>;
  playlists?: InputMaybe<PlaylistOrderByRelationAggregateInput>;
  primaryHex?: InputMaybe<SortOrder>;
  promotionalBanners?: InputMaybe<SortOrder>;
  receivedTips?: InputMaybe<TipsOrderByRelationAggregateInput>;
  receivedTransactions?: InputMaybe<TransactionOrderByRelationAggregateInput>;
  rollLogo?: InputMaybe<SortOrder>;
  secondaryHex?: InputMaybe<SortOrder>;
  setupCompletedAt?: InputMaybe<SortOrder>;
  shortenedUrls?: InputMaybe<ShortenedUrlOrderByRelationAggregateInput>;
  splashScreenLogo?: InputMaybe<SortOrder>;
  status?: InputMaybe<SortOrder>;
  subscribedUsers?: InputMaybe<UserSubscriptionOrderByRelationAggregateInput>;
  subscriptionPlans?: InputMaybe<SubscriptionPlanOrderByRelationAggregateInput>;
  themeColor?: InputMaybe<SortOrder>;
  totalFollowerCount?: InputMaybe<SortOrder>;
  totalReleasedVideoCount?: InputMaybe<SortOrder>;
  updatedAt?: InputMaybe<SortOrder>;
  user?: InputMaybe<UserOrderByWithRelationInput>;
  userId?: InputMaybe<SortOrder>;
  videoStatistics?: InputMaybe<VideoStatisticOrderByRelationAggregateInput>;
  videos?: InputMaybe<VideoOrderByRelationAggregateInput>;
};

export type ChannelRelationFilter = {
  is?: InputMaybe<ChannelWhereInput>;
  isNot?: InputMaybe<ChannelWhereInput>;
};

export enum ChannelScalarFieldEnum {
  AboutLinks = 'aboutLinks',
  AboutMe = 'aboutMe',
  AppLogo = 'appLogo',
  ApprovedAt = 'approvedAt',
  CreatedAt = 'createdAt',
  CurrencyCode = 'currencyCode',
  Genres = 'genres',
  Id = 'id',
  IsGiftEnabled = 'isGiftEnabled',
  IsSubscriptionEnabled = 'isSubscriptionEnabled',
  LastVideoUploadedAt = 'lastVideoUploadedAt',
  LoginBanner = 'loginBanner',
  Name = 'name',
  PrimaryHex = 'primaryHex',
  PromotionalBanners = 'promotionalBanners',
  RollLogo = 'rollLogo',
  SecondaryHex = 'secondaryHex',
  SetupCompletedAt = 'setupCompletedAt',
  SplashScreenLogo = 'splashScreenLogo',
  Status = 'status',
  ThemeColor = 'themeColor',
  TotalFollowerCount = 'totalFollowerCount',
  TotalReleasedVideoCount = 'totalReleasedVideoCount',
  UpdatedAt = 'updatedAt',
  UserId = 'userId'
}

export type ChannelScalarWhereWithAggregatesInput = {
  AND?: InputMaybe<Array<ChannelScalarWhereWithAggregatesInput>>;
  NOT?: InputMaybe<Array<ChannelScalarWhereWithAggregatesInput>>;
  OR?: InputMaybe<Array<ChannelScalarWhereWithAggregatesInput>>;
  aboutLinks?: InputMaybe<JsonNullableWithAggregatesFilter>;
  aboutMe?: InputMaybe<StringNullableWithAggregatesFilter>;
  appLogo?: InputMaybe<StringNullableWithAggregatesFilter>;
  approvedAt?: InputMaybe<DateTimeNullableWithAggregatesFilter>;
  createdAt?: InputMaybe<DateTimeWithAggregatesFilter>;
  currencyCode?: InputMaybe<EnumCurrencyCodeWithAggregatesFilter>;
  genres?: InputMaybe<JsonNullableWithAggregatesFilter>;
  id?: InputMaybe<StringWithAggregatesFilter>;
  isGiftEnabled?: InputMaybe<BoolWithAggregatesFilter>;
  isSubscriptionEnabled?: InputMaybe<BoolWithAggregatesFilter>;
  lastVideoUploadedAt?: InputMaybe<DateTimeNullableWithAggregatesFilter>;
  loginBanner?: InputMaybe<StringNullableWithAggregatesFilter>;
  name?: InputMaybe<StringNullableWithAggregatesFilter>;
  primaryHex?: InputMaybe<StringWithAggregatesFilter>;
  promotionalBanners?: InputMaybe<JsonNullableWithAggregatesFilter>;
  rollLogo?: InputMaybe<StringNullableWithAggregatesFilter>;
  secondaryHex?: InputMaybe<StringWithAggregatesFilter>;
  setupCompletedAt?: InputMaybe<DateTimeNullableWithAggregatesFilter>;
  splashScreenLogo?: InputMaybe<StringNullableWithAggregatesFilter>;
  status?: InputMaybe<EnumStatusWithAggregatesFilter>;
  themeColor?: InputMaybe<EnumThemeColorWithAggregatesFilter>;
  totalFollowerCount?: InputMaybe<IntWithAggregatesFilter>;
  totalReleasedVideoCount?: InputMaybe<IntWithAggregatesFilter>;
  updatedAt?: InputMaybe<DateTimeWithAggregatesFilter>;
  userId?: InputMaybe<StringWithAggregatesFilter>;
};

export type ChannelSumAggregate = {
  __typename?: 'ChannelSumAggregate';
  totalFollowerCount?: Maybe<Scalars['Int']>;
  totalReleasedVideoCount?: Maybe<Scalars['Int']>;
};

export type ChannelSumOrderByAggregateInput = {
  totalFollowerCount?: InputMaybe<SortOrder>;
  totalReleasedVideoCount?: InputMaybe<SortOrder>;
};

export type ChannelUpdateInput = {
  aboutLinks?: InputMaybe<Scalars['JSON']>;
  aboutMe?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  appLogo?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  approvedAt?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  currencyCode?: InputMaybe<EnumCurrencyCodeFieldUpdateOperationsInput>;
  followedUsers?: InputMaybe<ChannelFollowerUpdateManyWithoutFolloweeInput>;
  genres?: InputMaybe<Scalars['JSON']>;
  gifts?: InputMaybe<GiftUpdateManyWithoutChannelInput>;
  giftsReceived?: InputMaybe<GiftDistributionUpdateManyWithoutReceiverInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  isGiftEnabled?: InputMaybe<BoolFieldUpdateOperationsInput>;
  isSubscriptionEnabled?: InputMaybe<BoolFieldUpdateOperationsInput>;
  lastVideoUploadedAt?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  loginBanner?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  name?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  playlists?: InputMaybe<PlaylistUpdateManyWithoutChannelInput>;
  primaryHex?: InputMaybe<StringFieldUpdateOperationsInput>;
  promotionalBanners?: InputMaybe<Scalars['JSON']>;
  receivedTips?: InputMaybe<TipsUpdateManyWithoutRecipientInput>;
  receivedTransactions?: InputMaybe<TransactionUpdateManyWithoutPaymentToInput>;
  rollLogo?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  secondaryHex?: InputMaybe<StringFieldUpdateOperationsInput>;
  setupCompletedAt?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  shortenedUrls?: InputMaybe<ShortenedUrlUpdateManyWithoutChannelInput>;
  splashScreenLogo?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  status?: InputMaybe<EnumStatusFieldUpdateOperationsInput>;
  subscribedUsers?: InputMaybe<UserSubscriptionUpdateManyWithoutSubscribedChannelInput>;
  subscriptionPlans?: InputMaybe<SubscriptionPlanUpdateManyWithoutChannelInput>;
  themeColor?: InputMaybe<EnumThemeColorFieldUpdateOperationsInput>;
  totalFollowerCount?: InputMaybe<IntFieldUpdateOperationsInput>;
  totalReleasedVideoCount?: InputMaybe<IntFieldUpdateOperationsInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  user?: InputMaybe<UserUpdateOneRequiredWithoutChannelInput>;
  videoStatistics?: InputMaybe<VideoStatisticUpdateManyWithoutChannelInput>;
  videos?: InputMaybe<VideoUpdateManyWithoutChannelInput>;
};

export type ChannelUpdateManyMutationInput = {
  aboutLinks?: InputMaybe<Scalars['JSON']>;
  aboutMe?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  appLogo?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  approvedAt?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  currencyCode?: InputMaybe<EnumCurrencyCodeFieldUpdateOperationsInput>;
  genres?: InputMaybe<Scalars['JSON']>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  isGiftEnabled?: InputMaybe<BoolFieldUpdateOperationsInput>;
  isSubscriptionEnabled?: InputMaybe<BoolFieldUpdateOperationsInput>;
  lastVideoUploadedAt?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  loginBanner?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  name?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  primaryHex?: InputMaybe<StringFieldUpdateOperationsInput>;
  promotionalBanners?: InputMaybe<Scalars['JSON']>;
  rollLogo?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  secondaryHex?: InputMaybe<StringFieldUpdateOperationsInput>;
  setupCompletedAt?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  splashScreenLogo?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  status?: InputMaybe<EnumStatusFieldUpdateOperationsInput>;
  themeColor?: InputMaybe<EnumThemeColorFieldUpdateOperationsInput>;
  totalFollowerCount?: InputMaybe<IntFieldUpdateOperationsInput>;
  totalReleasedVideoCount?: InputMaybe<IntFieldUpdateOperationsInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
};

export type ChannelUpdateOneRequiredWithoutFollowedUsersInput = {
  connect?: InputMaybe<ChannelWhereUniqueInput>;
  connectOrCreate?: InputMaybe<ChannelCreateOrConnectWithoutFollowedUsersInput>;
  create?: InputMaybe<ChannelCreateWithoutFollowedUsersInput>;
  update?: InputMaybe<ChannelUpdateWithoutFollowedUsersInput>;
  upsert?: InputMaybe<ChannelUpsertWithoutFollowedUsersInput>;
};

export type ChannelUpdateOneRequiredWithoutGiftsInput = {
  connect?: InputMaybe<ChannelWhereUniqueInput>;
  connectOrCreate?: InputMaybe<ChannelCreateOrConnectWithoutGiftsInput>;
  create?: InputMaybe<ChannelCreateWithoutGiftsInput>;
  update?: InputMaybe<ChannelUpdateWithoutGiftsInput>;
  upsert?: InputMaybe<ChannelUpsertWithoutGiftsInput>;
};

export type ChannelUpdateOneRequiredWithoutGiftsReceivedInput = {
  connect?: InputMaybe<ChannelWhereUniqueInput>;
  connectOrCreate?: InputMaybe<ChannelCreateOrConnectWithoutGiftsReceivedInput>;
  create?: InputMaybe<ChannelCreateWithoutGiftsReceivedInput>;
  update?: InputMaybe<ChannelUpdateWithoutGiftsReceivedInput>;
  upsert?: InputMaybe<ChannelUpsertWithoutGiftsReceivedInput>;
};

export type ChannelUpdateOneRequiredWithoutPlaylistsInput = {
  connect?: InputMaybe<ChannelWhereUniqueInput>;
  connectOrCreate?: InputMaybe<ChannelCreateOrConnectWithoutPlaylistsInput>;
  create?: InputMaybe<ChannelCreateWithoutPlaylistsInput>;
  update?: InputMaybe<ChannelUpdateWithoutPlaylistsInput>;
  upsert?: InputMaybe<ChannelUpsertWithoutPlaylistsInput>;
};

export type ChannelUpdateOneRequiredWithoutReceivedTipsInput = {
  connect?: InputMaybe<ChannelWhereUniqueInput>;
  connectOrCreate?: InputMaybe<ChannelCreateOrConnectWithoutReceivedTipsInput>;
  create?: InputMaybe<ChannelCreateWithoutReceivedTipsInput>;
  update?: InputMaybe<ChannelUpdateWithoutReceivedTipsInput>;
  upsert?: InputMaybe<ChannelUpsertWithoutReceivedTipsInput>;
};

export type ChannelUpdateOneRequiredWithoutReceivedTransactionsInput = {
  connect?: InputMaybe<ChannelWhereUniqueInput>;
  connectOrCreate?: InputMaybe<ChannelCreateOrConnectWithoutReceivedTransactionsInput>;
  create?: InputMaybe<ChannelCreateWithoutReceivedTransactionsInput>;
  update?: InputMaybe<ChannelUpdateWithoutReceivedTransactionsInput>;
  upsert?: InputMaybe<ChannelUpsertWithoutReceivedTransactionsInput>;
};

export type ChannelUpdateOneRequiredWithoutSubscribedUsersInput = {
  connect?: InputMaybe<ChannelWhereUniqueInput>;
  connectOrCreate?: InputMaybe<ChannelCreateOrConnectWithoutSubscribedUsersInput>;
  create?: InputMaybe<ChannelCreateWithoutSubscribedUsersInput>;
  update?: InputMaybe<ChannelUpdateWithoutSubscribedUsersInput>;
  upsert?: InputMaybe<ChannelUpsertWithoutSubscribedUsersInput>;
};

export type ChannelUpdateOneRequiredWithoutSubscriptionPlansInput = {
  connect?: InputMaybe<ChannelWhereUniqueInput>;
  connectOrCreate?: InputMaybe<ChannelCreateOrConnectWithoutSubscriptionPlansInput>;
  create?: InputMaybe<ChannelCreateWithoutSubscriptionPlansInput>;
  update?: InputMaybe<ChannelUpdateWithoutSubscriptionPlansInput>;
  upsert?: InputMaybe<ChannelUpsertWithoutSubscriptionPlansInput>;
};

export type ChannelUpdateOneRequiredWithoutVideoStatisticsInput = {
  connect?: InputMaybe<ChannelWhereUniqueInput>;
  connectOrCreate?: InputMaybe<ChannelCreateOrConnectWithoutVideoStatisticsInput>;
  create?: InputMaybe<ChannelCreateWithoutVideoStatisticsInput>;
  update?: InputMaybe<ChannelUpdateWithoutVideoStatisticsInput>;
  upsert?: InputMaybe<ChannelUpsertWithoutVideoStatisticsInput>;
};

export type ChannelUpdateOneRequiredWithoutVideosInput = {
  connect?: InputMaybe<ChannelWhereUniqueInput>;
  connectOrCreate?: InputMaybe<ChannelCreateOrConnectWithoutVideosInput>;
  create?: InputMaybe<ChannelCreateWithoutVideosInput>;
  update?: InputMaybe<ChannelUpdateWithoutVideosInput>;
  upsert?: InputMaybe<ChannelUpsertWithoutVideosInput>;
};

export type ChannelUpdateOneWithoutUserInput = {
  connect?: InputMaybe<ChannelWhereUniqueInput>;
  connectOrCreate?: InputMaybe<ChannelCreateOrConnectWithoutUserInput>;
  create?: InputMaybe<ChannelCreateWithoutUserInput>;
  delete?: InputMaybe<Scalars['Boolean']>;
  disconnect?: InputMaybe<Scalars['Boolean']>;
  update?: InputMaybe<ChannelUpdateWithoutUserInput>;
  upsert?: InputMaybe<ChannelUpsertWithoutUserInput>;
};

export type ChannelUpdateWithoutFollowedUsersInput = {
  aboutLinks?: InputMaybe<Scalars['JSON']>;
  aboutMe?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  appLogo?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  approvedAt?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  currencyCode?: InputMaybe<EnumCurrencyCodeFieldUpdateOperationsInput>;
  genres?: InputMaybe<Scalars['JSON']>;
  gifts?: InputMaybe<GiftUpdateManyWithoutChannelInput>;
  giftsReceived?: InputMaybe<GiftDistributionUpdateManyWithoutReceiverInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  isGiftEnabled?: InputMaybe<BoolFieldUpdateOperationsInput>;
  isSubscriptionEnabled?: InputMaybe<BoolFieldUpdateOperationsInput>;
  lastVideoUploadedAt?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  loginBanner?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  name?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  playlists?: InputMaybe<PlaylistUpdateManyWithoutChannelInput>;
  primaryHex?: InputMaybe<StringFieldUpdateOperationsInput>;
  promotionalBanners?: InputMaybe<Scalars['JSON']>;
  receivedTips?: InputMaybe<TipsUpdateManyWithoutRecipientInput>;
  receivedTransactions?: InputMaybe<TransactionUpdateManyWithoutPaymentToInput>;
  rollLogo?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  secondaryHex?: InputMaybe<StringFieldUpdateOperationsInput>;
  setupCompletedAt?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  shortenedUrls?: InputMaybe<ShortenedUrlUpdateManyWithoutChannelInput>;
  splashScreenLogo?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  status?: InputMaybe<EnumStatusFieldUpdateOperationsInput>;
  subscribedUsers?: InputMaybe<UserSubscriptionUpdateManyWithoutSubscribedChannelInput>;
  subscriptionPlans?: InputMaybe<SubscriptionPlanUpdateManyWithoutChannelInput>;
  themeColor?: InputMaybe<EnumThemeColorFieldUpdateOperationsInput>;
  totalFollowerCount?: InputMaybe<IntFieldUpdateOperationsInput>;
  totalReleasedVideoCount?: InputMaybe<IntFieldUpdateOperationsInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  user?: InputMaybe<UserUpdateOneRequiredWithoutChannelInput>;
  videoStatistics?: InputMaybe<VideoStatisticUpdateManyWithoutChannelInput>;
  videos?: InputMaybe<VideoUpdateManyWithoutChannelInput>;
};

export type ChannelUpdateWithoutGiftsInput = {
  aboutLinks?: InputMaybe<Scalars['JSON']>;
  aboutMe?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  appLogo?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  approvedAt?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  currencyCode?: InputMaybe<EnumCurrencyCodeFieldUpdateOperationsInput>;
  followedUsers?: InputMaybe<ChannelFollowerUpdateManyWithoutFolloweeInput>;
  genres?: InputMaybe<Scalars['JSON']>;
  giftsReceived?: InputMaybe<GiftDistributionUpdateManyWithoutReceiverInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  isGiftEnabled?: InputMaybe<BoolFieldUpdateOperationsInput>;
  isSubscriptionEnabled?: InputMaybe<BoolFieldUpdateOperationsInput>;
  lastVideoUploadedAt?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  loginBanner?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  name?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  playlists?: InputMaybe<PlaylistUpdateManyWithoutChannelInput>;
  primaryHex?: InputMaybe<StringFieldUpdateOperationsInput>;
  promotionalBanners?: InputMaybe<Scalars['JSON']>;
  receivedTips?: InputMaybe<TipsUpdateManyWithoutRecipientInput>;
  receivedTransactions?: InputMaybe<TransactionUpdateManyWithoutPaymentToInput>;
  rollLogo?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  secondaryHex?: InputMaybe<StringFieldUpdateOperationsInput>;
  setupCompletedAt?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  shortenedUrls?: InputMaybe<ShortenedUrlUpdateManyWithoutChannelInput>;
  splashScreenLogo?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  status?: InputMaybe<EnumStatusFieldUpdateOperationsInput>;
  subscribedUsers?: InputMaybe<UserSubscriptionUpdateManyWithoutSubscribedChannelInput>;
  subscriptionPlans?: InputMaybe<SubscriptionPlanUpdateManyWithoutChannelInput>;
  themeColor?: InputMaybe<EnumThemeColorFieldUpdateOperationsInput>;
  totalFollowerCount?: InputMaybe<IntFieldUpdateOperationsInput>;
  totalReleasedVideoCount?: InputMaybe<IntFieldUpdateOperationsInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  user?: InputMaybe<UserUpdateOneRequiredWithoutChannelInput>;
  videoStatistics?: InputMaybe<VideoStatisticUpdateManyWithoutChannelInput>;
  videos?: InputMaybe<VideoUpdateManyWithoutChannelInput>;
};

export type ChannelUpdateWithoutGiftsReceivedInput = {
  aboutLinks?: InputMaybe<Scalars['JSON']>;
  aboutMe?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  appLogo?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  approvedAt?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  currencyCode?: InputMaybe<EnumCurrencyCodeFieldUpdateOperationsInput>;
  followedUsers?: InputMaybe<ChannelFollowerUpdateManyWithoutFolloweeInput>;
  genres?: InputMaybe<Scalars['JSON']>;
  gifts?: InputMaybe<GiftUpdateManyWithoutChannelInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  isGiftEnabled?: InputMaybe<BoolFieldUpdateOperationsInput>;
  isSubscriptionEnabled?: InputMaybe<BoolFieldUpdateOperationsInput>;
  lastVideoUploadedAt?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  loginBanner?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  name?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  playlists?: InputMaybe<PlaylistUpdateManyWithoutChannelInput>;
  primaryHex?: InputMaybe<StringFieldUpdateOperationsInput>;
  promotionalBanners?: InputMaybe<Scalars['JSON']>;
  receivedTips?: InputMaybe<TipsUpdateManyWithoutRecipientInput>;
  receivedTransactions?: InputMaybe<TransactionUpdateManyWithoutPaymentToInput>;
  rollLogo?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  secondaryHex?: InputMaybe<StringFieldUpdateOperationsInput>;
  setupCompletedAt?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  shortenedUrls?: InputMaybe<ShortenedUrlUpdateManyWithoutChannelInput>;
  splashScreenLogo?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  status?: InputMaybe<EnumStatusFieldUpdateOperationsInput>;
  subscribedUsers?: InputMaybe<UserSubscriptionUpdateManyWithoutSubscribedChannelInput>;
  subscriptionPlans?: InputMaybe<SubscriptionPlanUpdateManyWithoutChannelInput>;
  themeColor?: InputMaybe<EnumThemeColorFieldUpdateOperationsInput>;
  totalFollowerCount?: InputMaybe<IntFieldUpdateOperationsInput>;
  totalReleasedVideoCount?: InputMaybe<IntFieldUpdateOperationsInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  user?: InputMaybe<UserUpdateOneRequiredWithoutChannelInput>;
  videoStatistics?: InputMaybe<VideoStatisticUpdateManyWithoutChannelInput>;
  videos?: InputMaybe<VideoUpdateManyWithoutChannelInput>;
};

export type ChannelUpdateWithoutPlaylistsInput = {
  aboutLinks?: InputMaybe<Scalars['JSON']>;
  aboutMe?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  appLogo?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  approvedAt?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  currencyCode?: InputMaybe<EnumCurrencyCodeFieldUpdateOperationsInput>;
  followedUsers?: InputMaybe<ChannelFollowerUpdateManyWithoutFolloweeInput>;
  genres?: InputMaybe<Scalars['JSON']>;
  gifts?: InputMaybe<GiftUpdateManyWithoutChannelInput>;
  giftsReceived?: InputMaybe<GiftDistributionUpdateManyWithoutReceiverInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  isGiftEnabled?: InputMaybe<BoolFieldUpdateOperationsInput>;
  isSubscriptionEnabled?: InputMaybe<BoolFieldUpdateOperationsInput>;
  lastVideoUploadedAt?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  loginBanner?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  name?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  primaryHex?: InputMaybe<StringFieldUpdateOperationsInput>;
  promotionalBanners?: InputMaybe<Scalars['JSON']>;
  receivedTips?: InputMaybe<TipsUpdateManyWithoutRecipientInput>;
  receivedTransactions?: InputMaybe<TransactionUpdateManyWithoutPaymentToInput>;
  rollLogo?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  secondaryHex?: InputMaybe<StringFieldUpdateOperationsInput>;
  setupCompletedAt?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  shortenedUrls?: InputMaybe<ShortenedUrlUpdateManyWithoutChannelInput>;
  splashScreenLogo?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  status?: InputMaybe<EnumStatusFieldUpdateOperationsInput>;
  subscribedUsers?: InputMaybe<UserSubscriptionUpdateManyWithoutSubscribedChannelInput>;
  subscriptionPlans?: InputMaybe<SubscriptionPlanUpdateManyWithoutChannelInput>;
  themeColor?: InputMaybe<EnumThemeColorFieldUpdateOperationsInput>;
  totalFollowerCount?: InputMaybe<IntFieldUpdateOperationsInput>;
  totalReleasedVideoCount?: InputMaybe<IntFieldUpdateOperationsInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  user?: InputMaybe<UserUpdateOneRequiredWithoutChannelInput>;
  videoStatistics?: InputMaybe<VideoStatisticUpdateManyWithoutChannelInput>;
  videos?: InputMaybe<VideoUpdateManyWithoutChannelInput>;
};

export type ChannelUpdateWithoutReceivedTipsInput = {
  aboutLinks?: InputMaybe<Scalars['JSON']>;
  aboutMe?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  appLogo?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  approvedAt?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  currencyCode?: InputMaybe<EnumCurrencyCodeFieldUpdateOperationsInput>;
  followedUsers?: InputMaybe<ChannelFollowerUpdateManyWithoutFolloweeInput>;
  genres?: InputMaybe<Scalars['JSON']>;
  gifts?: InputMaybe<GiftUpdateManyWithoutChannelInput>;
  giftsReceived?: InputMaybe<GiftDistributionUpdateManyWithoutReceiverInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  isGiftEnabled?: InputMaybe<BoolFieldUpdateOperationsInput>;
  isSubscriptionEnabled?: InputMaybe<BoolFieldUpdateOperationsInput>;
  lastVideoUploadedAt?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  loginBanner?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  name?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  playlists?: InputMaybe<PlaylistUpdateManyWithoutChannelInput>;
  primaryHex?: InputMaybe<StringFieldUpdateOperationsInput>;
  promotionalBanners?: InputMaybe<Scalars['JSON']>;
  receivedTransactions?: InputMaybe<TransactionUpdateManyWithoutPaymentToInput>;
  rollLogo?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  secondaryHex?: InputMaybe<StringFieldUpdateOperationsInput>;
  setupCompletedAt?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  shortenedUrls?: InputMaybe<ShortenedUrlUpdateManyWithoutChannelInput>;
  splashScreenLogo?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  status?: InputMaybe<EnumStatusFieldUpdateOperationsInput>;
  subscribedUsers?: InputMaybe<UserSubscriptionUpdateManyWithoutSubscribedChannelInput>;
  subscriptionPlans?: InputMaybe<SubscriptionPlanUpdateManyWithoutChannelInput>;
  themeColor?: InputMaybe<EnumThemeColorFieldUpdateOperationsInput>;
  totalFollowerCount?: InputMaybe<IntFieldUpdateOperationsInput>;
  totalReleasedVideoCount?: InputMaybe<IntFieldUpdateOperationsInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  user?: InputMaybe<UserUpdateOneRequiredWithoutChannelInput>;
  videoStatistics?: InputMaybe<VideoStatisticUpdateManyWithoutChannelInput>;
  videos?: InputMaybe<VideoUpdateManyWithoutChannelInput>;
};

export type ChannelUpdateWithoutReceivedTransactionsInput = {
  aboutLinks?: InputMaybe<Scalars['JSON']>;
  aboutMe?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  appLogo?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  approvedAt?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  currencyCode?: InputMaybe<EnumCurrencyCodeFieldUpdateOperationsInput>;
  followedUsers?: InputMaybe<ChannelFollowerUpdateManyWithoutFolloweeInput>;
  genres?: InputMaybe<Scalars['JSON']>;
  gifts?: InputMaybe<GiftUpdateManyWithoutChannelInput>;
  giftsReceived?: InputMaybe<GiftDistributionUpdateManyWithoutReceiverInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  isGiftEnabled?: InputMaybe<BoolFieldUpdateOperationsInput>;
  isSubscriptionEnabled?: InputMaybe<BoolFieldUpdateOperationsInput>;
  lastVideoUploadedAt?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  loginBanner?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  name?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  playlists?: InputMaybe<PlaylistUpdateManyWithoutChannelInput>;
  primaryHex?: InputMaybe<StringFieldUpdateOperationsInput>;
  promotionalBanners?: InputMaybe<Scalars['JSON']>;
  receivedTips?: InputMaybe<TipsUpdateManyWithoutRecipientInput>;
  rollLogo?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  secondaryHex?: InputMaybe<StringFieldUpdateOperationsInput>;
  setupCompletedAt?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  shortenedUrls?: InputMaybe<ShortenedUrlUpdateManyWithoutChannelInput>;
  splashScreenLogo?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  status?: InputMaybe<EnumStatusFieldUpdateOperationsInput>;
  subscribedUsers?: InputMaybe<UserSubscriptionUpdateManyWithoutSubscribedChannelInput>;
  subscriptionPlans?: InputMaybe<SubscriptionPlanUpdateManyWithoutChannelInput>;
  themeColor?: InputMaybe<EnumThemeColorFieldUpdateOperationsInput>;
  totalFollowerCount?: InputMaybe<IntFieldUpdateOperationsInput>;
  totalReleasedVideoCount?: InputMaybe<IntFieldUpdateOperationsInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  user?: InputMaybe<UserUpdateOneRequiredWithoutChannelInput>;
  videoStatistics?: InputMaybe<VideoStatisticUpdateManyWithoutChannelInput>;
  videos?: InputMaybe<VideoUpdateManyWithoutChannelInput>;
};

export type ChannelUpdateWithoutSubscribedUsersInput = {
  aboutLinks?: InputMaybe<Scalars['JSON']>;
  aboutMe?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  appLogo?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  approvedAt?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  currencyCode?: InputMaybe<EnumCurrencyCodeFieldUpdateOperationsInput>;
  followedUsers?: InputMaybe<ChannelFollowerUpdateManyWithoutFolloweeInput>;
  genres?: InputMaybe<Scalars['JSON']>;
  gifts?: InputMaybe<GiftUpdateManyWithoutChannelInput>;
  giftsReceived?: InputMaybe<GiftDistributionUpdateManyWithoutReceiverInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  isGiftEnabled?: InputMaybe<BoolFieldUpdateOperationsInput>;
  isSubscriptionEnabled?: InputMaybe<BoolFieldUpdateOperationsInput>;
  lastVideoUploadedAt?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  loginBanner?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  name?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  playlists?: InputMaybe<PlaylistUpdateManyWithoutChannelInput>;
  primaryHex?: InputMaybe<StringFieldUpdateOperationsInput>;
  promotionalBanners?: InputMaybe<Scalars['JSON']>;
  receivedTips?: InputMaybe<TipsUpdateManyWithoutRecipientInput>;
  receivedTransactions?: InputMaybe<TransactionUpdateManyWithoutPaymentToInput>;
  rollLogo?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  secondaryHex?: InputMaybe<StringFieldUpdateOperationsInput>;
  setupCompletedAt?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  shortenedUrls?: InputMaybe<ShortenedUrlUpdateManyWithoutChannelInput>;
  splashScreenLogo?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  status?: InputMaybe<EnumStatusFieldUpdateOperationsInput>;
  subscriptionPlans?: InputMaybe<SubscriptionPlanUpdateManyWithoutChannelInput>;
  themeColor?: InputMaybe<EnumThemeColorFieldUpdateOperationsInput>;
  totalFollowerCount?: InputMaybe<IntFieldUpdateOperationsInput>;
  totalReleasedVideoCount?: InputMaybe<IntFieldUpdateOperationsInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  user?: InputMaybe<UserUpdateOneRequiredWithoutChannelInput>;
  videoStatistics?: InputMaybe<VideoStatisticUpdateManyWithoutChannelInput>;
  videos?: InputMaybe<VideoUpdateManyWithoutChannelInput>;
};

export type ChannelUpdateWithoutSubscriptionPlansInput = {
  aboutLinks?: InputMaybe<Scalars['JSON']>;
  aboutMe?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  appLogo?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  approvedAt?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  currencyCode?: InputMaybe<EnumCurrencyCodeFieldUpdateOperationsInput>;
  followedUsers?: InputMaybe<ChannelFollowerUpdateManyWithoutFolloweeInput>;
  genres?: InputMaybe<Scalars['JSON']>;
  gifts?: InputMaybe<GiftUpdateManyWithoutChannelInput>;
  giftsReceived?: InputMaybe<GiftDistributionUpdateManyWithoutReceiverInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  isGiftEnabled?: InputMaybe<BoolFieldUpdateOperationsInput>;
  isSubscriptionEnabled?: InputMaybe<BoolFieldUpdateOperationsInput>;
  lastVideoUploadedAt?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  loginBanner?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  name?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  playlists?: InputMaybe<PlaylistUpdateManyWithoutChannelInput>;
  primaryHex?: InputMaybe<StringFieldUpdateOperationsInput>;
  promotionalBanners?: InputMaybe<Scalars['JSON']>;
  receivedTips?: InputMaybe<TipsUpdateManyWithoutRecipientInput>;
  receivedTransactions?: InputMaybe<TransactionUpdateManyWithoutPaymentToInput>;
  rollLogo?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  secondaryHex?: InputMaybe<StringFieldUpdateOperationsInput>;
  setupCompletedAt?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  shortenedUrls?: InputMaybe<ShortenedUrlUpdateManyWithoutChannelInput>;
  splashScreenLogo?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  status?: InputMaybe<EnumStatusFieldUpdateOperationsInput>;
  subscribedUsers?: InputMaybe<UserSubscriptionUpdateManyWithoutSubscribedChannelInput>;
  themeColor?: InputMaybe<EnumThemeColorFieldUpdateOperationsInput>;
  totalFollowerCount?: InputMaybe<IntFieldUpdateOperationsInput>;
  totalReleasedVideoCount?: InputMaybe<IntFieldUpdateOperationsInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  user?: InputMaybe<UserUpdateOneRequiredWithoutChannelInput>;
  videoStatistics?: InputMaybe<VideoStatisticUpdateManyWithoutChannelInput>;
  videos?: InputMaybe<VideoUpdateManyWithoutChannelInput>;
};

export type ChannelUpdateWithoutUserInput = {
  aboutLinks?: InputMaybe<Scalars['JSON']>;
  aboutMe?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  appLogo?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  approvedAt?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  currencyCode?: InputMaybe<EnumCurrencyCodeFieldUpdateOperationsInput>;
  followedUsers?: InputMaybe<ChannelFollowerUpdateManyWithoutFolloweeInput>;
  genres?: InputMaybe<Scalars['JSON']>;
  gifts?: InputMaybe<GiftUpdateManyWithoutChannelInput>;
  giftsReceived?: InputMaybe<GiftDistributionUpdateManyWithoutReceiverInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  isGiftEnabled?: InputMaybe<BoolFieldUpdateOperationsInput>;
  isSubscriptionEnabled?: InputMaybe<BoolFieldUpdateOperationsInput>;
  lastVideoUploadedAt?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  loginBanner?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  name?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  playlists?: InputMaybe<PlaylistUpdateManyWithoutChannelInput>;
  primaryHex?: InputMaybe<StringFieldUpdateOperationsInput>;
  promotionalBanners?: InputMaybe<Scalars['JSON']>;
  receivedTips?: InputMaybe<TipsUpdateManyWithoutRecipientInput>;
  receivedTransactions?: InputMaybe<TransactionUpdateManyWithoutPaymentToInput>;
  rollLogo?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  secondaryHex?: InputMaybe<StringFieldUpdateOperationsInput>;
  setupCompletedAt?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  shortenedUrls?: InputMaybe<ShortenedUrlUpdateManyWithoutChannelInput>;
  splashScreenLogo?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  status?: InputMaybe<EnumStatusFieldUpdateOperationsInput>;
  subscribedUsers?: InputMaybe<UserSubscriptionUpdateManyWithoutSubscribedChannelInput>;
  subscriptionPlans?: InputMaybe<SubscriptionPlanUpdateManyWithoutChannelInput>;
  themeColor?: InputMaybe<EnumThemeColorFieldUpdateOperationsInput>;
  totalFollowerCount?: InputMaybe<IntFieldUpdateOperationsInput>;
  totalReleasedVideoCount?: InputMaybe<IntFieldUpdateOperationsInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  videoStatistics?: InputMaybe<VideoStatisticUpdateManyWithoutChannelInput>;
  videos?: InputMaybe<VideoUpdateManyWithoutChannelInput>;
};

export type ChannelUpdateWithoutVideoStatisticsInput = {
  aboutLinks?: InputMaybe<Scalars['JSON']>;
  aboutMe?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  appLogo?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  approvedAt?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  currencyCode?: InputMaybe<EnumCurrencyCodeFieldUpdateOperationsInput>;
  followedUsers?: InputMaybe<ChannelFollowerUpdateManyWithoutFolloweeInput>;
  genres?: InputMaybe<Scalars['JSON']>;
  gifts?: InputMaybe<GiftUpdateManyWithoutChannelInput>;
  giftsReceived?: InputMaybe<GiftDistributionUpdateManyWithoutReceiverInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  isGiftEnabled?: InputMaybe<BoolFieldUpdateOperationsInput>;
  isSubscriptionEnabled?: InputMaybe<BoolFieldUpdateOperationsInput>;
  lastVideoUploadedAt?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  loginBanner?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  name?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  playlists?: InputMaybe<PlaylistUpdateManyWithoutChannelInput>;
  primaryHex?: InputMaybe<StringFieldUpdateOperationsInput>;
  promotionalBanners?: InputMaybe<Scalars['JSON']>;
  receivedTips?: InputMaybe<TipsUpdateManyWithoutRecipientInput>;
  receivedTransactions?: InputMaybe<TransactionUpdateManyWithoutPaymentToInput>;
  rollLogo?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  secondaryHex?: InputMaybe<StringFieldUpdateOperationsInput>;
  setupCompletedAt?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  shortenedUrls?: InputMaybe<ShortenedUrlUpdateManyWithoutChannelInput>;
  splashScreenLogo?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  status?: InputMaybe<EnumStatusFieldUpdateOperationsInput>;
  subscribedUsers?: InputMaybe<UserSubscriptionUpdateManyWithoutSubscribedChannelInput>;
  subscriptionPlans?: InputMaybe<SubscriptionPlanUpdateManyWithoutChannelInput>;
  themeColor?: InputMaybe<EnumThemeColorFieldUpdateOperationsInput>;
  totalFollowerCount?: InputMaybe<IntFieldUpdateOperationsInput>;
  totalReleasedVideoCount?: InputMaybe<IntFieldUpdateOperationsInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  user?: InputMaybe<UserUpdateOneRequiredWithoutChannelInput>;
  videos?: InputMaybe<VideoUpdateManyWithoutChannelInput>;
};

export type ChannelUpdateWithoutVideosInput = {
  aboutLinks?: InputMaybe<Scalars['JSON']>;
  aboutMe?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  appLogo?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  approvedAt?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  currencyCode?: InputMaybe<EnumCurrencyCodeFieldUpdateOperationsInput>;
  followedUsers?: InputMaybe<ChannelFollowerUpdateManyWithoutFolloweeInput>;
  genres?: InputMaybe<Scalars['JSON']>;
  gifts?: InputMaybe<GiftUpdateManyWithoutChannelInput>;
  giftsReceived?: InputMaybe<GiftDistributionUpdateManyWithoutReceiverInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  isGiftEnabled?: InputMaybe<BoolFieldUpdateOperationsInput>;
  isSubscriptionEnabled?: InputMaybe<BoolFieldUpdateOperationsInput>;
  lastVideoUploadedAt?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  loginBanner?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  name?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  playlists?: InputMaybe<PlaylistUpdateManyWithoutChannelInput>;
  primaryHex?: InputMaybe<StringFieldUpdateOperationsInput>;
  promotionalBanners?: InputMaybe<Scalars['JSON']>;
  receivedTips?: InputMaybe<TipsUpdateManyWithoutRecipientInput>;
  receivedTransactions?: InputMaybe<TransactionUpdateManyWithoutPaymentToInput>;
  rollLogo?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  secondaryHex?: InputMaybe<StringFieldUpdateOperationsInput>;
  setupCompletedAt?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  shortenedUrls?: InputMaybe<ShortenedUrlUpdateManyWithoutChannelInput>;
  splashScreenLogo?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  status?: InputMaybe<EnumStatusFieldUpdateOperationsInput>;
  subscribedUsers?: InputMaybe<UserSubscriptionUpdateManyWithoutSubscribedChannelInput>;
  subscriptionPlans?: InputMaybe<SubscriptionPlanUpdateManyWithoutChannelInput>;
  themeColor?: InputMaybe<EnumThemeColorFieldUpdateOperationsInput>;
  totalFollowerCount?: InputMaybe<IntFieldUpdateOperationsInput>;
  totalReleasedVideoCount?: InputMaybe<IntFieldUpdateOperationsInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  user?: InputMaybe<UserUpdateOneRequiredWithoutChannelInput>;
  videoStatistics?: InputMaybe<VideoStatisticUpdateManyWithoutChannelInput>;
};

export type ChannelUpsertWithoutFollowedUsersInput = {
  create: ChannelCreateWithoutFollowedUsersInput;
  update: ChannelUpdateWithoutFollowedUsersInput;
};

export type ChannelUpsertWithoutGiftsInput = {
  create: ChannelCreateWithoutGiftsInput;
  update: ChannelUpdateWithoutGiftsInput;
};

export type ChannelUpsertWithoutGiftsReceivedInput = {
  create: ChannelCreateWithoutGiftsReceivedInput;
  update: ChannelUpdateWithoutGiftsReceivedInput;
};

export type ChannelUpsertWithoutPlaylistsInput = {
  create: ChannelCreateWithoutPlaylistsInput;
  update: ChannelUpdateWithoutPlaylistsInput;
};

export type ChannelUpsertWithoutReceivedTipsInput = {
  create: ChannelCreateWithoutReceivedTipsInput;
  update: ChannelUpdateWithoutReceivedTipsInput;
};

export type ChannelUpsertWithoutReceivedTransactionsInput = {
  create: ChannelCreateWithoutReceivedTransactionsInput;
  update: ChannelUpdateWithoutReceivedTransactionsInput;
};

export type ChannelUpsertWithoutSubscribedUsersInput = {
  create: ChannelCreateWithoutSubscribedUsersInput;
  update: ChannelUpdateWithoutSubscribedUsersInput;
};

export type ChannelUpsertWithoutSubscriptionPlansInput = {
  create: ChannelCreateWithoutSubscriptionPlansInput;
  update: ChannelUpdateWithoutSubscriptionPlansInput;
};

export type ChannelUpsertWithoutUserInput = {
  create: ChannelCreateWithoutUserInput;
  update: ChannelUpdateWithoutUserInput;
};

export type ChannelUpsertWithoutVideoStatisticsInput = {
  create: ChannelCreateWithoutVideoStatisticsInput;
  update: ChannelUpdateWithoutVideoStatisticsInput;
};

export type ChannelUpsertWithoutVideosInput = {
  create: ChannelCreateWithoutVideosInput;
  update: ChannelUpdateWithoutVideosInput;
};

export type ChannelWhereInput = {
  AND?: InputMaybe<Array<ChannelWhereInput>>;
  NOT?: InputMaybe<Array<ChannelWhereInput>>;
  OR?: InputMaybe<Array<ChannelWhereInput>>;
  aboutLinks?: InputMaybe<JsonNullableFilter>;
  aboutMe?: InputMaybe<StringNullableFilter>;
  appLogo?: InputMaybe<StringNullableFilter>;
  approvedAt?: InputMaybe<DateTimeNullableFilter>;
  createdAt?: InputMaybe<DateTimeFilter>;
  currencyCode?: InputMaybe<EnumCurrencyCodeFilter>;
  followedUsers?: InputMaybe<ChannelFollowerListRelationFilter>;
  genres?: InputMaybe<JsonNullableFilter>;
  gifts?: InputMaybe<GiftListRelationFilter>;
  giftsReceived?: InputMaybe<GiftDistributionListRelationFilter>;
  id?: InputMaybe<StringFilter>;
  isGiftEnabled?: InputMaybe<BoolFilter>;
  isSubscriptionEnabled?: InputMaybe<BoolFilter>;
  lastVideoUploadedAt?: InputMaybe<DateTimeNullableFilter>;
  loginBanner?: InputMaybe<StringNullableFilter>;
  name?: InputMaybe<StringNullableFilter>;
  playlists?: InputMaybe<PlaylistListRelationFilter>;
  primaryHex?: InputMaybe<StringFilter>;
  promotionalBanners?: InputMaybe<JsonNullableFilter>;
  receivedTips?: InputMaybe<TipsListRelationFilter>;
  receivedTransactions?: InputMaybe<TransactionListRelationFilter>;
  rollLogo?: InputMaybe<StringNullableFilter>;
  secondaryHex?: InputMaybe<StringFilter>;
  setupCompletedAt?: InputMaybe<DateTimeNullableFilter>;
  shortenedUrls?: InputMaybe<ShortenedUrlListRelationFilter>;
  splashScreenLogo?: InputMaybe<StringNullableFilter>;
  status?: InputMaybe<EnumStatusFilter>;
  subscribedUsers?: InputMaybe<UserSubscriptionListRelationFilter>;
  subscriptionPlans?: InputMaybe<SubscriptionPlanListRelationFilter>;
  themeColor?: InputMaybe<EnumThemeColorFilter>;
  totalFollowerCount?: InputMaybe<IntFilter>;
  totalReleasedVideoCount?: InputMaybe<IntFilter>;
  updatedAt?: InputMaybe<DateTimeFilter>;
  user?: InputMaybe<UserRelationFilter>;
  userId?: InputMaybe<StringFilter>;
  videoStatistics?: InputMaybe<VideoStatisticListRelationFilter>;
  videos?: InputMaybe<VideoListRelationFilter>;
};

export type ChannelWhereUniqueInput = {
  id?: InputMaybe<Scalars['String']>;
  name?: InputMaybe<Scalars['String']>;
  userId?: InputMaybe<Scalars['String']>;
};

export type Comment = {
  __typename?: 'Comment';
  archivedAt?: Maybe<Scalars['DateTime']>;
  content: Scalars['String'];
  createdAt: Scalars['DateTime'];
  id: Scalars['String'];
  updatedAt: Scalars['DateTime'];
  user: User;
  userId: Scalars['String'];
  video: Video;
  videoId: Scalars['String'];
};

export type CommentCountAggregate = {
  __typename?: 'CommentCountAggregate';
  _all: Scalars['Int'];
  archivedAt: Scalars['Int'];
  content: Scalars['Int'];
  createdAt: Scalars['Int'];
  id: Scalars['Int'];
  updatedAt: Scalars['Int'];
  userId: Scalars['Int'];
  videoId: Scalars['Int'];
};

export type CommentCountOrderByAggregateInput = {
  archivedAt?: InputMaybe<SortOrder>;
  content?: InputMaybe<SortOrder>;
  createdAt?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  updatedAt?: InputMaybe<SortOrder>;
  userId?: InputMaybe<SortOrder>;
  videoId?: InputMaybe<SortOrder>;
};

export type CommentCreateInput = {
  archivedAt?: InputMaybe<Scalars['DateTime']>;
  content: Scalars['String'];
  createdAt?: InputMaybe<Scalars['DateTime']>;
  id?: InputMaybe<Scalars['String']>;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
  user: UserCreateNestedOneWithoutCommentsInput;
  video: VideoCreateNestedOneWithoutCommentsInput;
};

export type CommentCreateManyInput = {
  archivedAt?: InputMaybe<Scalars['DateTime']>;
  content: Scalars['String'];
  createdAt?: InputMaybe<Scalars['DateTime']>;
  id?: InputMaybe<Scalars['String']>;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
  userId: Scalars['String'];
  videoId: Scalars['String'];
};

export type CommentCreateManyUserInput = {
  archivedAt?: InputMaybe<Scalars['DateTime']>;
  content: Scalars['String'];
  createdAt?: InputMaybe<Scalars['DateTime']>;
  id?: InputMaybe<Scalars['String']>;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
  videoId: Scalars['String'];
};

export type CommentCreateManyUserInputEnvelope = {
  data: Array<CommentCreateManyUserInput>;
  skipDuplicates?: InputMaybe<Scalars['Boolean']>;
};

export type CommentCreateManyVideoInput = {
  archivedAt?: InputMaybe<Scalars['DateTime']>;
  content: Scalars['String'];
  createdAt?: InputMaybe<Scalars['DateTime']>;
  id?: InputMaybe<Scalars['String']>;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
  userId: Scalars['String'];
};

export type CommentCreateManyVideoInputEnvelope = {
  data: Array<CommentCreateManyVideoInput>;
  skipDuplicates?: InputMaybe<Scalars['Boolean']>;
};

export type CommentCreateNestedManyWithoutUserInput = {
  connect?: InputMaybe<Array<CommentWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<CommentCreateOrConnectWithoutUserInput>>;
  create?: InputMaybe<Array<CommentCreateWithoutUserInput>>;
  createMany?: InputMaybe<CommentCreateManyUserInputEnvelope>;
};

export type CommentCreateNestedManyWithoutVideoInput = {
  connect?: InputMaybe<Array<CommentWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<CommentCreateOrConnectWithoutVideoInput>>;
  create?: InputMaybe<Array<CommentCreateWithoutVideoInput>>;
  createMany?: InputMaybe<CommentCreateManyVideoInputEnvelope>;
};

export type CommentCreateOrConnectWithoutUserInput = {
  create: CommentCreateWithoutUserInput;
  where: CommentWhereUniqueInput;
};

export type CommentCreateOrConnectWithoutVideoInput = {
  create: CommentCreateWithoutVideoInput;
  where: CommentWhereUniqueInput;
};

export type CommentCreateWithoutUserInput = {
  archivedAt?: InputMaybe<Scalars['DateTime']>;
  content: Scalars['String'];
  createdAt?: InputMaybe<Scalars['DateTime']>;
  id?: InputMaybe<Scalars['String']>;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
  video: VideoCreateNestedOneWithoutCommentsInput;
};

export type CommentCreateWithoutVideoInput = {
  archivedAt?: InputMaybe<Scalars['DateTime']>;
  content: Scalars['String'];
  createdAt?: InputMaybe<Scalars['DateTime']>;
  id?: InputMaybe<Scalars['String']>;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
  user: UserCreateNestedOneWithoutCommentsInput;
};

export type CommentGroupBy = {
  __typename?: 'CommentGroupBy';
  _count?: Maybe<CommentCountAggregate>;
  _max?: Maybe<CommentMaxAggregate>;
  _min?: Maybe<CommentMinAggregate>;
  archivedAt?: Maybe<Scalars['DateTime']>;
  content: Scalars['String'];
  createdAt: Scalars['DateTime'];
  id: Scalars['String'];
  updatedAt: Scalars['DateTime'];
  userId: Scalars['String'];
  videoId: Scalars['String'];
};

export type CommentListRelationFilter = {
  every?: InputMaybe<CommentWhereInput>;
  none?: InputMaybe<CommentWhereInput>;
  some?: InputMaybe<CommentWhereInput>;
};

export type CommentMaxAggregate = {
  __typename?: 'CommentMaxAggregate';
  archivedAt?: Maybe<Scalars['DateTime']>;
  content?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  userId?: Maybe<Scalars['String']>;
  videoId?: Maybe<Scalars['String']>;
};

export type CommentMaxOrderByAggregateInput = {
  archivedAt?: InputMaybe<SortOrder>;
  content?: InputMaybe<SortOrder>;
  createdAt?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  updatedAt?: InputMaybe<SortOrder>;
  userId?: InputMaybe<SortOrder>;
  videoId?: InputMaybe<SortOrder>;
};

export type CommentMinAggregate = {
  __typename?: 'CommentMinAggregate';
  archivedAt?: Maybe<Scalars['DateTime']>;
  content?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  userId?: Maybe<Scalars['String']>;
  videoId?: Maybe<Scalars['String']>;
};

export type CommentMinOrderByAggregateInput = {
  archivedAt?: InputMaybe<SortOrder>;
  content?: InputMaybe<SortOrder>;
  createdAt?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  updatedAt?: InputMaybe<SortOrder>;
  userId?: InputMaybe<SortOrder>;
  videoId?: InputMaybe<SortOrder>;
};

export type CommentOrderByRelationAggregateInput = {
  _count?: InputMaybe<SortOrder>;
};

export type CommentOrderByWithAggregationInput = {
  _count?: InputMaybe<CommentCountOrderByAggregateInput>;
  _max?: InputMaybe<CommentMaxOrderByAggregateInput>;
  _min?: InputMaybe<CommentMinOrderByAggregateInput>;
  archivedAt?: InputMaybe<SortOrder>;
  content?: InputMaybe<SortOrder>;
  createdAt?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  updatedAt?: InputMaybe<SortOrder>;
  userId?: InputMaybe<SortOrder>;
  videoId?: InputMaybe<SortOrder>;
};

export type CommentOrderByWithRelationInput = {
  archivedAt?: InputMaybe<SortOrder>;
  content?: InputMaybe<SortOrder>;
  createdAt?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  updatedAt?: InputMaybe<SortOrder>;
  user?: InputMaybe<UserOrderByWithRelationInput>;
  userId?: InputMaybe<SortOrder>;
  video?: InputMaybe<VideoOrderByWithRelationInput>;
  videoId?: InputMaybe<SortOrder>;
};

export enum CommentScalarFieldEnum {
  ArchivedAt = 'archivedAt',
  Content = 'content',
  CreatedAt = 'createdAt',
  Id = 'id',
  UpdatedAt = 'updatedAt',
  UserId = 'userId',
  VideoId = 'videoId'
}

export type CommentScalarWhereInput = {
  AND?: InputMaybe<Array<CommentScalarWhereInput>>;
  NOT?: InputMaybe<Array<CommentScalarWhereInput>>;
  OR?: InputMaybe<Array<CommentScalarWhereInput>>;
  archivedAt?: InputMaybe<DateTimeNullableFilter>;
  content?: InputMaybe<StringFilter>;
  createdAt?: InputMaybe<DateTimeFilter>;
  id?: InputMaybe<StringFilter>;
  updatedAt?: InputMaybe<DateTimeFilter>;
  userId?: InputMaybe<StringFilter>;
  videoId?: InputMaybe<StringFilter>;
};

export type CommentScalarWhereWithAggregatesInput = {
  AND?: InputMaybe<Array<CommentScalarWhereWithAggregatesInput>>;
  NOT?: InputMaybe<Array<CommentScalarWhereWithAggregatesInput>>;
  OR?: InputMaybe<Array<CommentScalarWhereWithAggregatesInput>>;
  archivedAt?: InputMaybe<DateTimeNullableWithAggregatesFilter>;
  content?: InputMaybe<StringWithAggregatesFilter>;
  createdAt?: InputMaybe<DateTimeWithAggregatesFilter>;
  id?: InputMaybe<StringWithAggregatesFilter>;
  updatedAt?: InputMaybe<DateTimeWithAggregatesFilter>;
  userId?: InputMaybe<StringWithAggregatesFilter>;
  videoId?: InputMaybe<StringWithAggregatesFilter>;
};

export type CommentUpdateInput = {
  archivedAt?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  content?: InputMaybe<StringFieldUpdateOperationsInput>;
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  user?: InputMaybe<UserUpdateOneRequiredWithoutCommentsInput>;
  video?: InputMaybe<VideoUpdateOneRequiredWithoutCommentsInput>;
};

export type CommentUpdateManyMutationInput = {
  archivedAt?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  content?: InputMaybe<StringFieldUpdateOperationsInput>;
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
};

export type CommentUpdateManyWithWhereWithoutUserInput = {
  data: CommentUpdateManyMutationInput;
  where: CommentScalarWhereInput;
};

export type CommentUpdateManyWithWhereWithoutVideoInput = {
  data: CommentUpdateManyMutationInput;
  where: CommentScalarWhereInput;
};

export type CommentUpdateManyWithoutUserInput = {
  connect?: InputMaybe<Array<CommentWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<CommentCreateOrConnectWithoutUserInput>>;
  create?: InputMaybe<Array<CommentCreateWithoutUserInput>>;
  createMany?: InputMaybe<CommentCreateManyUserInputEnvelope>;
  delete?: InputMaybe<Array<CommentWhereUniqueInput>>;
  deleteMany?: InputMaybe<Array<CommentScalarWhereInput>>;
  disconnect?: InputMaybe<Array<CommentWhereUniqueInput>>;
  set?: InputMaybe<Array<CommentWhereUniqueInput>>;
  update?: InputMaybe<Array<CommentUpdateWithWhereUniqueWithoutUserInput>>;
  updateMany?: InputMaybe<Array<CommentUpdateManyWithWhereWithoutUserInput>>;
  upsert?: InputMaybe<Array<CommentUpsertWithWhereUniqueWithoutUserInput>>;
};

export type CommentUpdateManyWithoutVideoInput = {
  connect?: InputMaybe<Array<CommentWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<CommentCreateOrConnectWithoutVideoInput>>;
  create?: InputMaybe<Array<CommentCreateWithoutVideoInput>>;
  createMany?: InputMaybe<CommentCreateManyVideoInputEnvelope>;
  delete?: InputMaybe<Array<CommentWhereUniqueInput>>;
  deleteMany?: InputMaybe<Array<CommentScalarWhereInput>>;
  disconnect?: InputMaybe<Array<CommentWhereUniqueInput>>;
  set?: InputMaybe<Array<CommentWhereUniqueInput>>;
  update?: InputMaybe<Array<CommentUpdateWithWhereUniqueWithoutVideoInput>>;
  updateMany?: InputMaybe<Array<CommentUpdateManyWithWhereWithoutVideoInput>>;
  upsert?: InputMaybe<Array<CommentUpsertWithWhereUniqueWithoutVideoInput>>;
};

export type CommentUpdateWithWhereUniqueWithoutUserInput = {
  data: CommentUpdateWithoutUserInput;
  where: CommentWhereUniqueInput;
};

export type CommentUpdateWithWhereUniqueWithoutVideoInput = {
  data: CommentUpdateWithoutVideoInput;
  where: CommentWhereUniqueInput;
};

export type CommentUpdateWithoutUserInput = {
  archivedAt?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  content?: InputMaybe<StringFieldUpdateOperationsInput>;
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  video?: InputMaybe<VideoUpdateOneRequiredWithoutCommentsInput>;
};

export type CommentUpdateWithoutVideoInput = {
  archivedAt?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  content?: InputMaybe<StringFieldUpdateOperationsInput>;
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  user?: InputMaybe<UserUpdateOneRequiredWithoutCommentsInput>;
};

export type CommentUpsertWithWhereUniqueWithoutUserInput = {
  create: CommentCreateWithoutUserInput;
  update: CommentUpdateWithoutUserInput;
  where: CommentWhereUniqueInput;
};

export type CommentUpsertWithWhereUniqueWithoutVideoInput = {
  create: CommentCreateWithoutVideoInput;
  update: CommentUpdateWithoutVideoInput;
  where: CommentWhereUniqueInput;
};

export type CommentWhereInput = {
  AND?: InputMaybe<Array<CommentWhereInput>>;
  NOT?: InputMaybe<Array<CommentWhereInput>>;
  OR?: InputMaybe<Array<CommentWhereInput>>;
  archivedAt?: InputMaybe<DateTimeNullableFilter>;
  content?: InputMaybe<StringFilter>;
  createdAt?: InputMaybe<DateTimeFilter>;
  id?: InputMaybe<StringFilter>;
  updatedAt?: InputMaybe<DateTimeFilter>;
  user?: InputMaybe<UserRelationFilter>;
  userId?: InputMaybe<StringFilter>;
  video?: InputMaybe<VideoRelationFilter>;
  videoId?: InputMaybe<StringFilter>;
};

export type CommentWhereUniqueInput = {
  id?: InputMaybe<Scalars['String']>;
};

export enum CommunicationLanguage {
  Chinese = 'CHINESE',
  English = 'ENGLISH',
  Other = 'OTHER'
}

export type ConvertPaymentInput = {
  amount: Scalars['Float'];
  channelId: Scalars['String'];
};

export type CreateMyGiftInput = {
  id?: InputMaybe<Scalars['String']>;
  name: Scalars['String'];
  price: Scalars['Float'];
  thumbnailUrl: Scalars['String'];
};

export type CreatePaymentInput = {
  /** Amount in charged currency. Amount sent to the payment gateway. */
  amount: Scalars['Float'];
  channelId: Scalars['String'];
  currencyCode: CurrencyCode;
  description: Scalars['String'];
  message?: InputMaybe<Scalars['String']>;
  prestoUserId?: InputMaybe<Scalars['String']>;
  productDescription?: InputMaybe<Scalars['String']>;
  productId: Scalars['String'];
  productType: ProductType;
  redirectUrl: Scalars['String'];
  videoId?: InputMaybe<Scalars['String']>;
};

export type CreatePaymentResult = {
  __typename?: 'CreatePaymentResult';
  paymentGateway: Scalars['String'];
  paymentRefNumber: Scalars['String'];
  paymentUrl: Scalars['String'];
  success: Scalars['Boolean'];
  transactionId: Scalars['String'];
};

export enum CurrencyCode {
  Eth = 'ETH',
  Myr = 'MYR',
  Twd = 'TWD',
  Usd = 'USD'
}

export type CursorPaginatationMetadata = {
  __typename?: 'CursorPaginatationMetadata';
  currentCursor?: Maybe<Scalars['String']>;
  perPage: Scalars['Int'];
  totalCount: Scalars['Int'];
};

export type CursorPaginatedVideo = {
  __typename?: 'CursorPaginatedVideo';
  items?: Maybe<Array<Video>>;
  metadata: CursorPaginatationMetadata;
};

export type DateTimeFieldUpdateOperationsInput = {
  set?: InputMaybe<Scalars['DateTime']>;
};

export type DateTimeFilter = {
  equals?: InputMaybe<Scalars['DateTime']>;
  gt?: InputMaybe<Scalars['DateTime']>;
  gte?: InputMaybe<Scalars['DateTime']>;
  in?: InputMaybe<Array<Scalars['DateTime']>>;
  lt?: InputMaybe<Scalars['DateTime']>;
  lte?: InputMaybe<Scalars['DateTime']>;
  not?: InputMaybe<NestedDateTimeFilter>;
  notIn?: InputMaybe<Array<Scalars['DateTime']>>;
};

export type DateTimeNullableFilter = {
  equals?: InputMaybe<Scalars['DateTime']>;
  gt?: InputMaybe<Scalars['DateTime']>;
  gte?: InputMaybe<Scalars['DateTime']>;
  in?: InputMaybe<Array<Scalars['DateTime']>>;
  lt?: InputMaybe<Scalars['DateTime']>;
  lte?: InputMaybe<Scalars['DateTime']>;
  not?: InputMaybe<NestedDateTimeNullableFilter>;
  notIn?: InputMaybe<Array<Scalars['DateTime']>>;
};

export type DateTimeNullableWithAggregatesFilter = {
  _count?: InputMaybe<NestedIntNullableFilter>;
  _max?: InputMaybe<NestedDateTimeNullableFilter>;
  _min?: InputMaybe<NestedDateTimeNullableFilter>;
  equals?: InputMaybe<Scalars['DateTime']>;
  gt?: InputMaybe<Scalars['DateTime']>;
  gte?: InputMaybe<Scalars['DateTime']>;
  in?: InputMaybe<Array<Scalars['DateTime']>>;
  lt?: InputMaybe<Scalars['DateTime']>;
  lte?: InputMaybe<Scalars['DateTime']>;
  not?: InputMaybe<NestedDateTimeNullableWithAggregatesFilter>;
  notIn?: InputMaybe<Array<Scalars['DateTime']>>;
};

export type DateTimeWithAggregatesFilter = {
  _count?: InputMaybe<NestedIntFilter>;
  _max?: InputMaybe<NestedDateTimeFilter>;
  _min?: InputMaybe<NestedDateTimeFilter>;
  equals?: InputMaybe<Scalars['DateTime']>;
  gt?: InputMaybe<Scalars['DateTime']>;
  gte?: InputMaybe<Scalars['DateTime']>;
  in?: InputMaybe<Array<Scalars['DateTime']>>;
  lt?: InputMaybe<Scalars['DateTime']>;
  lte?: InputMaybe<Scalars['DateTime']>;
  not?: InputMaybe<NestedDateTimeWithAggregatesFilter>;
  notIn?: InputMaybe<Array<Scalars['DateTime']>>;
};

export type DeleteMyPlaylistInput = {
  id: PlaylistIdFilter;
};

export type EnumCommunicationLanguageNullableFilter = {
  equals?: InputMaybe<CommunicationLanguage>;
  in?: InputMaybe<Array<CommunicationLanguage>>;
  not?: InputMaybe<NestedEnumCommunicationLanguageNullableFilter>;
  notIn?: InputMaybe<Array<CommunicationLanguage>>;
};

export type EnumCommunicationLanguageNullableWithAggregatesFilter = {
  _count?: InputMaybe<NestedIntNullableFilter>;
  _max?: InputMaybe<NestedEnumCommunicationLanguageNullableFilter>;
  _min?: InputMaybe<NestedEnumCommunicationLanguageNullableFilter>;
  equals?: InputMaybe<CommunicationLanguage>;
  in?: InputMaybe<Array<CommunicationLanguage>>;
  not?: InputMaybe<NestedEnumCommunicationLanguageNullableWithAggregatesFilter>;
  notIn?: InputMaybe<Array<CommunicationLanguage>>;
};

export type EnumCurrencyCodeFieldUpdateOperationsInput = {
  set?: InputMaybe<CurrencyCode>;
};

export type EnumCurrencyCodeFilter = {
  equals?: InputMaybe<CurrencyCode>;
  in?: InputMaybe<Array<CurrencyCode>>;
  not?: InputMaybe<NestedEnumCurrencyCodeFilter>;
  notIn?: InputMaybe<Array<CurrencyCode>>;
};

export type EnumCurrencyCodeWithAggregatesFilter = {
  _count?: InputMaybe<NestedIntFilter>;
  _max?: InputMaybe<NestedEnumCurrencyCodeFilter>;
  _min?: InputMaybe<NestedEnumCurrencyCodeFilter>;
  equals?: InputMaybe<CurrencyCode>;
  in?: InputMaybe<Array<CurrencyCode>>;
  not?: InputMaybe<NestedEnumCurrencyCodeWithAggregatesFilter>;
  notIn?: InputMaybe<Array<CurrencyCode>>;
};

export type EnumInquiryStatusFieldUpdateOperationsInput = {
  set?: InputMaybe<InquiryStatus>;
};

export type EnumInquiryStatusFilter = {
  equals?: InputMaybe<InquiryStatus>;
  in?: InputMaybe<Array<InquiryStatus>>;
  not?: InputMaybe<NestedEnumInquiryStatusFilter>;
  notIn?: InputMaybe<Array<InquiryStatus>>;
};

export type EnumInquiryStatusWithAggregatesFilter = {
  _count?: InputMaybe<NestedIntFilter>;
  _max?: InputMaybe<NestedEnumInquiryStatusFilter>;
  _min?: InputMaybe<NestedEnumInquiryStatusFilter>;
  equals?: InputMaybe<InquiryStatus>;
  in?: InputMaybe<Array<InquiryStatus>>;
  not?: InputMaybe<NestedEnumInquiryStatusWithAggregatesFilter>;
  notIn?: InputMaybe<Array<InquiryStatus>>;
};

export type EnumMintStageFieldUpdateOperationsInput = {
  set?: InputMaybe<MintStage>;
};

export type EnumMintStageFilter = {
  equals?: InputMaybe<MintStage>;
  in?: InputMaybe<Array<MintStage>>;
  not?: InputMaybe<NestedEnumMintStageFilter>;
  notIn?: InputMaybe<Array<MintStage>>;
};

export type EnumMintStageWithAggregatesFilter = {
  _count?: InputMaybe<NestedIntFilter>;
  _max?: InputMaybe<NestedEnumMintStageFilter>;
  _min?: InputMaybe<NestedEnumMintStageFilter>;
  equals?: InputMaybe<MintStage>;
  in?: InputMaybe<Array<MintStage>>;
  not?: InputMaybe<NestedEnumMintStageWithAggregatesFilter>;
  notIn?: InputMaybe<Array<MintStage>>;
};

export type EnumPaymentGatewayFieldUpdateOperationsInput = {
  set?: InputMaybe<PaymentGateway>;
};

export type EnumPaymentGatewayFilter = {
  equals?: InputMaybe<PaymentGateway>;
  in?: InputMaybe<Array<PaymentGateway>>;
  not?: InputMaybe<NestedEnumPaymentGatewayFilter>;
  notIn?: InputMaybe<Array<PaymentGateway>>;
};

export type EnumPaymentGatewayWithAggregatesFilter = {
  _count?: InputMaybe<NestedIntFilter>;
  _max?: InputMaybe<NestedEnumPaymentGatewayFilter>;
  _min?: InputMaybe<NestedEnumPaymentGatewayFilter>;
  equals?: InputMaybe<PaymentGateway>;
  in?: InputMaybe<Array<PaymentGateway>>;
  not?: InputMaybe<NestedEnumPaymentGatewayWithAggregatesFilter>;
  notIn?: InputMaybe<Array<PaymentGateway>>;
};

export type EnumPaymentStatusFieldUpdateOperationsInput = {
  set?: InputMaybe<PaymentStatus>;
};

export type EnumPaymentStatusFilter = {
  equals?: InputMaybe<PaymentStatus>;
  in?: InputMaybe<Array<PaymentStatus>>;
  not?: InputMaybe<NestedEnumPaymentStatusFilter>;
  notIn?: InputMaybe<Array<PaymentStatus>>;
};

export type EnumPaymentStatusWithAggregatesFilter = {
  _count?: InputMaybe<NestedIntFilter>;
  _max?: InputMaybe<NestedEnumPaymentStatusFilter>;
  _min?: InputMaybe<NestedEnumPaymentStatusFilter>;
  equals?: InputMaybe<PaymentStatus>;
  in?: InputMaybe<Array<PaymentStatus>>;
  not?: InputMaybe<NestedEnumPaymentStatusWithAggregatesFilter>;
  notIn?: InputMaybe<Array<PaymentStatus>>;
};

export type EnumProductTypeNullableFilter = {
  equals?: InputMaybe<ProductType>;
  in?: InputMaybe<Array<ProductType>>;
  not?: InputMaybe<NestedEnumProductTypeNullableFilter>;
  notIn?: InputMaybe<Array<ProductType>>;
};

export type EnumProductTypeNullableWithAggregatesFilter = {
  _count?: InputMaybe<NestedIntNullableFilter>;
  _max?: InputMaybe<NestedEnumProductTypeNullableFilter>;
  _min?: InputMaybe<NestedEnumProductTypeNullableFilter>;
  equals?: InputMaybe<ProductType>;
  in?: InputMaybe<Array<ProductType>>;
  not?: InputMaybe<NestedEnumProductTypeNullableWithAggregatesFilter>;
  notIn?: InputMaybe<Array<ProductType>>;
};

export type EnumStatusFieldUpdateOperationsInput = {
  set?: InputMaybe<Status>;
};

export type EnumStatusFilter = {
  equals?: InputMaybe<Status>;
  in?: InputMaybe<Array<Status>>;
  not?: InputMaybe<NestedEnumStatusFilter>;
  notIn?: InputMaybe<Array<Status>>;
};

export type EnumStatusWithAggregatesFilter = {
  _count?: InputMaybe<NestedIntFilter>;
  _max?: InputMaybe<NestedEnumStatusFilter>;
  _min?: InputMaybe<NestedEnumStatusFilter>;
  equals?: InputMaybe<Status>;
  in?: InputMaybe<Array<Status>>;
  not?: InputMaybe<NestedEnumStatusWithAggregatesFilter>;
  notIn?: InputMaybe<Array<Status>>;
};

export type EnumThemeColorFieldUpdateOperationsInput = {
  set?: InputMaybe<ThemeColor>;
};

export type EnumThemeColorFilter = {
  equals?: InputMaybe<ThemeColor>;
  in?: InputMaybe<Array<ThemeColor>>;
  not?: InputMaybe<NestedEnumThemeColorFilter>;
  notIn?: InputMaybe<Array<ThemeColor>>;
};

export type EnumThemeColorWithAggregatesFilter = {
  _count?: InputMaybe<NestedIntFilter>;
  _max?: InputMaybe<NestedEnumThemeColorFilter>;
  _min?: InputMaybe<NestedEnumThemeColorFilter>;
  equals?: InputMaybe<ThemeColor>;
  in?: InputMaybe<Array<ThemeColor>>;
  not?: InputMaybe<NestedEnumThemeColorWithAggregatesFilter>;
  notIn?: InputMaybe<Array<ThemeColor>>;
};

export type EnumUserTypeNullableFilter = {
  equals?: InputMaybe<UserType>;
  in?: InputMaybe<Array<UserType>>;
  not?: InputMaybe<NestedEnumUserTypeNullableFilter>;
  notIn?: InputMaybe<Array<UserType>>;
};

export type EnumUserTypeNullableWithAggregatesFilter = {
  _count?: InputMaybe<NestedIntNullableFilter>;
  _max?: InputMaybe<NestedEnumUserTypeNullableFilter>;
  _min?: InputMaybe<NestedEnumUserTypeNullableFilter>;
  equals?: InputMaybe<UserType>;
  in?: InputMaybe<Array<UserType>>;
  not?: InputMaybe<NestedEnumUserTypeNullableWithAggregatesFilter>;
  notIn?: InputMaybe<Array<UserType>>;
};

export type EnumVideoRatingFieldUpdateOperationsInput = {
  set?: InputMaybe<VideoRating>;
};

export type EnumVideoRatingFilter = {
  equals?: InputMaybe<VideoRating>;
  in?: InputMaybe<Array<VideoRating>>;
  not?: InputMaybe<NestedEnumVideoRatingFilter>;
  notIn?: InputMaybe<Array<VideoRating>>;
};

export type EnumVideoRatingWithAggregatesFilter = {
  _count?: InputMaybe<NestedIntFilter>;
  _max?: InputMaybe<NestedEnumVideoRatingFilter>;
  _min?: InputMaybe<NestedEnumVideoRatingFilter>;
  equals?: InputMaybe<VideoRating>;
  in?: InputMaybe<Array<VideoRating>>;
  not?: InputMaybe<NestedEnumVideoRatingWithAggregatesFilter>;
  notIn?: InputMaybe<Array<VideoRating>>;
};

export type EnumVideoStatusFieldUpdateOperationsInput = {
  set?: InputMaybe<VideoStatus>;
};

export type EnumVideoStatusFilter = {
  equals?: InputMaybe<VideoStatus>;
  in?: InputMaybe<Array<VideoStatus>>;
  not?: InputMaybe<NestedEnumVideoStatusFilter>;
  notIn?: InputMaybe<Array<VideoStatus>>;
};

export type EnumVideoStatusWithAggregatesFilter = {
  _count?: InputMaybe<NestedIntFilter>;
  _max?: InputMaybe<NestedEnumVideoStatusFilter>;
  _min?: InputMaybe<NestedEnumVideoStatusFilter>;
  equals?: InputMaybe<VideoStatus>;
  in?: InputMaybe<Array<VideoStatus>>;
  not?: InputMaybe<NestedEnumVideoStatusWithAggregatesFilter>;
  notIn?: InputMaybe<Array<VideoStatus>>;
};

export type FloatFieldUpdateOperationsInput = {
  decrement?: InputMaybe<Scalars['Float']>;
  divide?: InputMaybe<Scalars['Float']>;
  increment?: InputMaybe<Scalars['Float']>;
  multiply?: InputMaybe<Scalars['Float']>;
  set?: InputMaybe<Scalars['Float']>;
};

export type FloatFilter = {
  equals?: InputMaybe<Scalars['Float']>;
  gt?: InputMaybe<Scalars['Float']>;
  gte?: InputMaybe<Scalars['Float']>;
  in?: InputMaybe<Array<Scalars['Float']>>;
  lt?: InputMaybe<Scalars['Float']>;
  lte?: InputMaybe<Scalars['Float']>;
  not?: InputMaybe<NestedFloatFilter>;
  notIn?: InputMaybe<Array<Scalars['Float']>>;
};

export type FloatNullableFilter = {
  equals?: InputMaybe<Scalars['Float']>;
  gt?: InputMaybe<Scalars['Float']>;
  gte?: InputMaybe<Scalars['Float']>;
  in?: InputMaybe<Array<Scalars['Float']>>;
  lt?: InputMaybe<Scalars['Float']>;
  lte?: InputMaybe<Scalars['Float']>;
  not?: InputMaybe<NestedFloatNullableFilter>;
  notIn?: InputMaybe<Array<Scalars['Float']>>;
};

export type FloatNullableWithAggregatesFilter = {
  _avg?: InputMaybe<NestedFloatNullableFilter>;
  _count?: InputMaybe<NestedIntNullableFilter>;
  _max?: InputMaybe<NestedFloatNullableFilter>;
  _min?: InputMaybe<NestedFloatNullableFilter>;
  _sum?: InputMaybe<NestedFloatNullableFilter>;
  equals?: InputMaybe<Scalars['Float']>;
  gt?: InputMaybe<Scalars['Float']>;
  gte?: InputMaybe<Scalars['Float']>;
  in?: InputMaybe<Array<Scalars['Float']>>;
  lt?: InputMaybe<Scalars['Float']>;
  lte?: InputMaybe<Scalars['Float']>;
  not?: InputMaybe<NestedFloatNullableWithAggregatesFilter>;
  notIn?: InputMaybe<Array<Scalars['Float']>>;
};

export type FloatWithAggregatesFilter = {
  _avg?: InputMaybe<NestedFloatFilter>;
  _count?: InputMaybe<NestedIntFilter>;
  _max?: InputMaybe<NestedFloatFilter>;
  _min?: InputMaybe<NestedFloatFilter>;
  _sum?: InputMaybe<NestedFloatFilter>;
  equals?: InputMaybe<Scalars['Float']>;
  gt?: InputMaybe<Scalars['Float']>;
  gte?: InputMaybe<Scalars['Float']>;
  in?: InputMaybe<Array<Scalars['Float']>>;
  lt?: InputMaybe<Scalars['Float']>;
  lte?: InputMaybe<Scalars['Float']>;
  not?: InputMaybe<NestedFloatWithAggregatesFilter>;
  notIn?: InputMaybe<Array<Scalars['Float']>>;
};

export enum Frequency {
  AllTime = 'ALL_TIME',
  Daily = 'DAILY'
}

export type GeneratePresignedFileInput = {
  fileName: Scalars['String'];
  /** The purpose of the upload, such as: profile_picture, ... */
  purpose: Scalars['String'];
  referenceId?: InputMaybe<Scalars['String']>;
};

export type Gift = {
  __typename?: 'Gift';
  _count?: Maybe<GiftCount>;
  archivedAt?: Maybe<Scalars['DateTime']>;
  channel: Channel;
  channelId: Scalars['String'];
  createdAt: Scalars['DateTime'];
  /** Display price of the gift in currency chosen by channel */
  displayPrice: Scalars['String'];
  giftDistributions: Array<GiftDistribution>;
  id: Scalars['String'];
  name: Scalars['String'];
  /** Presentment price of the gift in currency charged by payment gateway */
  presentmentMoney: PresentmentMoney;
  price: Scalars['Float'];
  thumbnailUrl: Scalars['String'];
  updatedAt: Scalars['DateTime'];
};


export type GiftGiftDistributionsArgs = {
  cursor?: InputMaybe<GiftDistributionWhereUniqueInput>;
  distinct?: InputMaybe<Array<GiftDistributionScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<GiftDistributionOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<GiftDistributionWhereInput>;
};

export type GiftAvgAggregate = {
  __typename?: 'GiftAvgAggregate';
  price?: Maybe<Scalars['Float']>;
};

export type GiftAvgOrderByAggregateInput = {
  price?: InputMaybe<SortOrder>;
};

export type GiftCount = {
  __typename?: 'GiftCount';
  giftDistributions: Scalars['Int'];
};

export type GiftCountAggregate = {
  __typename?: 'GiftCountAggregate';
  _all: Scalars['Int'];
  archivedAt: Scalars['Int'];
  channelId: Scalars['Int'];
  createdAt: Scalars['Int'];
  id: Scalars['Int'];
  name: Scalars['Int'];
  price: Scalars['Int'];
  thumbnailUrl: Scalars['Int'];
  updatedAt: Scalars['Int'];
};

export type GiftCountOrderByAggregateInput = {
  archivedAt?: InputMaybe<SortOrder>;
  channelId?: InputMaybe<SortOrder>;
  createdAt?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  name?: InputMaybe<SortOrder>;
  price?: InputMaybe<SortOrder>;
  thumbnailUrl?: InputMaybe<SortOrder>;
  updatedAt?: InputMaybe<SortOrder>;
};

export type GiftCreateInput = {
  archivedAt?: InputMaybe<Scalars['DateTime']>;
  channel: ChannelCreateNestedOneWithoutGiftsInput;
  createdAt?: InputMaybe<Scalars['DateTime']>;
  giftDistributions?: InputMaybe<GiftDistributionCreateNestedManyWithoutGiftInput>;
  id?: InputMaybe<Scalars['String']>;
  name: Scalars['String'];
  price: Scalars['Float'];
  thumbnailUrl: Scalars['String'];
  updatedAt?: InputMaybe<Scalars['DateTime']>;
};

export type GiftCreateManyChannelInput = {
  archivedAt?: InputMaybe<Scalars['DateTime']>;
  createdAt?: InputMaybe<Scalars['DateTime']>;
  id?: InputMaybe<Scalars['String']>;
  name: Scalars['String'];
  price: Scalars['Float'];
  thumbnailUrl: Scalars['String'];
  updatedAt?: InputMaybe<Scalars['DateTime']>;
};

export type GiftCreateManyChannelInputEnvelope = {
  data: Array<GiftCreateManyChannelInput>;
  skipDuplicates?: InputMaybe<Scalars['Boolean']>;
};

export type GiftCreateManyInput = {
  archivedAt?: InputMaybe<Scalars['DateTime']>;
  channelId: Scalars['String'];
  createdAt?: InputMaybe<Scalars['DateTime']>;
  id?: InputMaybe<Scalars['String']>;
  name: Scalars['String'];
  price: Scalars['Float'];
  thumbnailUrl: Scalars['String'];
  updatedAt?: InputMaybe<Scalars['DateTime']>;
};

export type GiftCreateNestedManyWithoutChannelInput = {
  connect?: InputMaybe<Array<GiftWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<GiftCreateOrConnectWithoutChannelInput>>;
  create?: InputMaybe<Array<GiftCreateWithoutChannelInput>>;
  createMany?: InputMaybe<GiftCreateManyChannelInputEnvelope>;
};

export type GiftCreateNestedOneWithoutGiftDistributionsInput = {
  connect?: InputMaybe<GiftWhereUniqueInput>;
  connectOrCreate?: InputMaybe<GiftCreateOrConnectWithoutGiftDistributionsInput>;
  create?: InputMaybe<GiftCreateWithoutGiftDistributionsInput>;
};

export type GiftCreateOrConnectWithoutChannelInput = {
  create: GiftCreateWithoutChannelInput;
  where: GiftWhereUniqueInput;
};

export type GiftCreateOrConnectWithoutGiftDistributionsInput = {
  create: GiftCreateWithoutGiftDistributionsInput;
  where: GiftWhereUniqueInput;
};

export type GiftCreateWithoutChannelInput = {
  archivedAt?: InputMaybe<Scalars['DateTime']>;
  createdAt?: InputMaybe<Scalars['DateTime']>;
  giftDistributions?: InputMaybe<GiftDistributionCreateNestedManyWithoutGiftInput>;
  id?: InputMaybe<Scalars['String']>;
  name: Scalars['String'];
  price: Scalars['Float'];
  thumbnailUrl: Scalars['String'];
  updatedAt?: InputMaybe<Scalars['DateTime']>;
};

export type GiftCreateWithoutGiftDistributionsInput = {
  archivedAt?: InputMaybe<Scalars['DateTime']>;
  channel: ChannelCreateNestedOneWithoutGiftsInput;
  createdAt?: InputMaybe<Scalars['DateTime']>;
  id?: InputMaybe<Scalars['String']>;
  name: Scalars['String'];
  price: Scalars['Float'];
  thumbnailUrl: Scalars['String'];
  updatedAt?: InputMaybe<Scalars['DateTime']>;
};

export type GiftDistribution = {
  __typename?: 'GiftDistribution';
  archivedAt?: Maybe<Scalars['DateTime']>;
  channelId: Scalars['String'];
  createdAt: Scalars['DateTime'];
  gift: Gift;
  giftId: Scalars['String'];
  id: Scalars['String'];
  receiver: Channel;
  sender: User;
  transaction: Transaction;
  transactionId: Scalars['String'];
  updatedAt: Scalars['DateTime'];
  userId: Scalars['String'];
  video: Video;
  videoId: Scalars['String'];
};

export type GiftDistributionCountAggregate = {
  __typename?: 'GiftDistributionCountAggregate';
  _all: Scalars['Int'];
  archivedAt: Scalars['Int'];
  channelId: Scalars['Int'];
  createdAt: Scalars['Int'];
  giftId: Scalars['Int'];
  id: Scalars['Int'];
  transactionId: Scalars['Int'];
  updatedAt: Scalars['Int'];
  userId: Scalars['Int'];
  videoId: Scalars['Int'];
};

export type GiftDistributionCountOrderByAggregateInput = {
  archivedAt?: InputMaybe<SortOrder>;
  channelId?: InputMaybe<SortOrder>;
  createdAt?: InputMaybe<SortOrder>;
  giftId?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  transactionId?: InputMaybe<SortOrder>;
  updatedAt?: InputMaybe<SortOrder>;
  userId?: InputMaybe<SortOrder>;
  videoId?: InputMaybe<SortOrder>;
};

export type GiftDistributionCreateInput = {
  archivedAt?: InputMaybe<Scalars['DateTime']>;
  createdAt?: InputMaybe<Scalars['DateTime']>;
  gift: GiftCreateNestedOneWithoutGiftDistributionsInput;
  id?: InputMaybe<Scalars['String']>;
  receiver: ChannelCreateNestedOneWithoutGiftsReceivedInput;
  sender: UserCreateNestedOneWithoutGiftsSentInput;
  transaction: TransactionCreateNestedOneWithoutGiftDistributionInput;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
  video: VideoCreateNestedOneWithoutGiftDistributionsInput;
};

export type GiftDistributionCreateManyGiftInput = {
  archivedAt?: InputMaybe<Scalars['DateTime']>;
  channelId: Scalars['String'];
  createdAt?: InputMaybe<Scalars['DateTime']>;
  id?: InputMaybe<Scalars['String']>;
  transactionId: Scalars['String'];
  updatedAt?: InputMaybe<Scalars['DateTime']>;
  userId: Scalars['String'];
  videoId: Scalars['String'];
};

export type GiftDistributionCreateManyGiftInputEnvelope = {
  data: Array<GiftDistributionCreateManyGiftInput>;
  skipDuplicates?: InputMaybe<Scalars['Boolean']>;
};

export type GiftDistributionCreateManyInput = {
  archivedAt?: InputMaybe<Scalars['DateTime']>;
  channelId: Scalars['String'];
  createdAt?: InputMaybe<Scalars['DateTime']>;
  giftId: Scalars['String'];
  id?: InputMaybe<Scalars['String']>;
  transactionId: Scalars['String'];
  updatedAt?: InputMaybe<Scalars['DateTime']>;
  userId: Scalars['String'];
  videoId: Scalars['String'];
};

export type GiftDistributionCreateManyReceiverInput = {
  archivedAt?: InputMaybe<Scalars['DateTime']>;
  createdAt?: InputMaybe<Scalars['DateTime']>;
  giftId: Scalars['String'];
  id?: InputMaybe<Scalars['String']>;
  transactionId: Scalars['String'];
  updatedAt?: InputMaybe<Scalars['DateTime']>;
  userId: Scalars['String'];
  videoId: Scalars['String'];
};

export type GiftDistributionCreateManyReceiverInputEnvelope = {
  data: Array<GiftDistributionCreateManyReceiverInput>;
  skipDuplicates?: InputMaybe<Scalars['Boolean']>;
};

export type GiftDistributionCreateManySenderInput = {
  archivedAt?: InputMaybe<Scalars['DateTime']>;
  channelId: Scalars['String'];
  createdAt?: InputMaybe<Scalars['DateTime']>;
  giftId: Scalars['String'];
  id?: InputMaybe<Scalars['String']>;
  transactionId: Scalars['String'];
  updatedAt?: InputMaybe<Scalars['DateTime']>;
  videoId: Scalars['String'];
};

export type GiftDistributionCreateManySenderInputEnvelope = {
  data: Array<GiftDistributionCreateManySenderInput>;
  skipDuplicates?: InputMaybe<Scalars['Boolean']>;
};

export type GiftDistributionCreateManyVideoInput = {
  archivedAt?: InputMaybe<Scalars['DateTime']>;
  channelId: Scalars['String'];
  createdAt?: InputMaybe<Scalars['DateTime']>;
  giftId: Scalars['String'];
  id?: InputMaybe<Scalars['String']>;
  transactionId: Scalars['String'];
  updatedAt?: InputMaybe<Scalars['DateTime']>;
  userId: Scalars['String'];
};

export type GiftDistributionCreateManyVideoInputEnvelope = {
  data: Array<GiftDistributionCreateManyVideoInput>;
  skipDuplicates?: InputMaybe<Scalars['Boolean']>;
};

export type GiftDistributionCreateNestedManyWithoutGiftInput = {
  connect?: InputMaybe<Array<GiftDistributionWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<GiftDistributionCreateOrConnectWithoutGiftInput>>;
  create?: InputMaybe<Array<GiftDistributionCreateWithoutGiftInput>>;
  createMany?: InputMaybe<GiftDistributionCreateManyGiftInputEnvelope>;
};

export type GiftDistributionCreateNestedManyWithoutReceiverInput = {
  connect?: InputMaybe<Array<GiftDistributionWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<GiftDistributionCreateOrConnectWithoutReceiverInput>>;
  create?: InputMaybe<Array<GiftDistributionCreateWithoutReceiverInput>>;
  createMany?: InputMaybe<GiftDistributionCreateManyReceiverInputEnvelope>;
};

export type GiftDistributionCreateNestedManyWithoutSenderInput = {
  connect?: InputMaybe<Array<GiftDistributionWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<GiftDistributionCreateOrConnectWithoutSenderInput>>;
  create?: InputMaybe<Array<GiftDistributionCreateWithoutSenderInput>>;
  createMany?: InputMaybe<GiftDistributionCreateManySenderInputEnvelope>;
};

export type GiftDistributionCreateNestedManyWithoutVideoInput = {
  connect?: InputMaybe<Array<GiftDistributionWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<GiftDistributionCreateOrConnectWithoutVideoInput>>;
  create?: InputMaybe<Array<GiftDistributionCreateWithoutVideoInput>>;
  createMany?: InputMaybe<GiftDistributionCreateManyVideoInputEnvelope>;
};

export type GiftDistributionCreateNestedOneWithoutTransactionInput = {
  connect?: InputMaybe<GiftDistributionWhereUniqueInput>;
  connectOrCreate?: InputMaybe<GiftDistributionCreateOrConnectWithoutTransactionInput>;
  create?: InputMaybe<GiftDistributionCreateWithoutTransactionInput>;
};

export type GiftDistributionCreateOrConnectWithoutGiftInput = {
  create: GiftDistributionCreateWithoutGiftInput;
  where: GiftDistributionWhereUniqueInput;
};

export type GiftDistributionCreateOrConnectWithoutReceiverInput = {
  create: GiftDistributionCreateWithoutReceiverInput;
  where: GiftDistributionWhereUniqueInput;
};

export type GiftDistributionCreateOrConnectWithoutSenderInput = {
  create: GiftDistributionCreateWithoutSenderInput;
  where: GiftDistributionWhereUniqueInput;
};

export type GiftDistributionCreateOrConnectWithoutTransactionInput = {
  create: GiftDistributionCreateWithoutTransactionInput;
  where: GiftDistributionWhereUniqueInput;
};

export type GiftDistributionCreateOrConnectWithoutVideoInput = {
  create: GiftDistributionCreateWithoutVideoInput;
  where: GiftDistributionWhereUniqueInput;
};

export type GiftDistributionCreateWithoutGiftInput = {
  archivedAt?: InputMaybe<Scalars['DateTime']>;
  createdAt?: InputMaybe<Scalars['DateTime']>;
  id?: InputMaybe<Scalars['String']>;
  receiver: ChannelCreateNestedOneWithoutGiftsReceivedInput;
  sender: UserCreateNestedOneWithoutGiftsSentInput;
  transaction: TransactionCreateNestedOneWithoutGiftDistributionInput;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
  video: VideoCreateNestedOneWithoutGiftDistributionsInput;
};

export type GiftDistributionCreateWithoutReceiverInput = {
  archivedAt?: InputMaybe<Scalars['DateTime']>;
  createdAt?: InputMaybe<Scalars['DateTime']>;
  gift: GiftCreateNestedOneWithoutGiftDistributionsInput;
  id?: InputMaybe<Scalars['String']>;
  sender: UserCreateNestedOneWithoutGiftsSentInput;
  transaction: TransactionCreateNestedOneWithoutGiftDistributionInput;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
  video: VideoCreateNestedOneWithoutGiftDistributionsInput;
};

export type GiftDistributionCreateWithoutSenderInput = {
  archivedAt?: InputMaybe<Scalars['DateTime']>;
  createdAt?: InputMaybe<Scalars['DateTime']>;
  gift: GiftCreateNestedOneWithoutGiftDistributionsInput;
  id?: InputMaybe<Scalars['String']>;
  receiver: ChannelCreateNestedOneWithoutGiftsReceivedInput;
  transaction: TransactionCreateNestedOneWithoutGiftDistributionInput;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
  video: VideoCreateNestedOneWithoutGiftDistributionsInput;
};

export type GiftDistributionCreateWithoutTransactionInput = {
  archivedAt?: InputMaybe<Scalars['DateTime']>;
  createdAt?: InputMaybe<Scalars['DateTime']>;
  gift: GiftCreateNestedOneWithoutGiftDistributionsInput;
  id?: InputMaybe<Scalars['String']>;
  receiver: ChannelCreateNestedOneWithoutGiftsReceivedInput;
  sender: UserCreateNestedOneWithoutGiftsSentInput;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
  video: VideoCreateNestedOneWithoutGiftDistributionsInput;
};

export type GiftDistributionCreateWithoutVideoInput = {
  archivedAt?: InputMaybe<Scalars['DateTime']>;
  createdAt?: InputMaybe<Scalars['DateTime']>;
  gift: GiftCreateNestedOneWithoutGiftDistributionsInput;
  id?: InputMaybe<Scalars['String']>;
  receiver: ChannelCreateNestedOneWithoutGiftsReceivedInput;
  sender: UserCreateNestedOneWithoutGiftsSentInput;
  transaction: TransactionCreateNestedOneWithoutGiftDistributionInput;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
};

export type GiftDistributionGroupBy = {
  __typename?: 'GiftDistributionGroupBy';
  _count?: Maybe<GiftDistributionCountAggregate>;
  _max?: Maybe<GiftDistributionMaxAggregate>;
  _min?: Maybe<GiftDistributionMinAggregate>;
  archivedAt?: Maybe<Scalars['DateTime']>;
  channelId: Scalars['String'];
  createdAt: Scalars['DateTime'];
  giftId: Scalars['String'];
  id: Scalars['String'];
  transactionId: Scalars['String'];
  updatedAt: Scalars['DateTime'];
  userId: Scalars['String'];
  videoId: Scalars['String'];
};

export type GiftDistributionListRelationFilter = {
  every?: InputMaybe<GiftDistributionWhereInput>;
  none?: InputMaybe<GiftDistributionWhereInput>;
  some?: InputMaybe<GiftDistributionWhereInput>;
};

export type GiftDistributionMaxAggregate = {
  __typename?: 'GiftDistributionMaxAggregate';
  archivedAt?: Maybe<Scalars['DateTime']>;
  channelId?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  giftId?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  transactionId?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  userId?: Maybe<Scalars['String']>;
  videoId?: Maybe<Scalars['String']>;
};

export type GiftDistributionMaxOrderByAggregateInput = {
  archivedAt?: InputMaybe<SortOrder>;
  channelId?: InputMaybe<SortOrder>;
  createdAt?: InputMaybe<SortOrder>;
  giftId?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  transactionId?: InputMaybe<SortOrder>;
  updatedAt?: InputMaybe<SortOrder>;
  userId?: InputMaybe<SortOrder>;
  videoId?: InputMaybe<SortOrder>;
};

export type GiftDistributionMinAggregate = {
  __typename?: 'GiftDistributionMinAggregate';
  archivedAt?: Maybe<Scalars['DateTime']>;
  channelId?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  giftId?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  transactionId?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  userId?: Maybe<Scalars['String']>;
  videoId?: Maybe<Scalars['String']>;
};

export type GiftDistributionMinOrderByAggregateInput = {
  archivedAt?: InputMaybe<SortOrder>;
  channelId?: InputMaybe<SortOrder>;
  createdAt?: InputMaybe<SortOrder>;
  giftId?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  transactionId?: InputMaybe<SortOrder>;
  updatedAt?: InputMaybe<SortOrder>;
  userId?: InputMaybe<SortOrder>;
  videoId?: InputMaybe<SortOrder>;
};

export type GiftDistributionOrderByRelationAggregateInput = {
  _count?: InputMaybe<SortOrder>;
};

export type GiftDistributionOrderByWithAggregationInput = {
  _count?: InputMaybe<GiftDistributionCountOrderByAggregateInput>;
  _max?: InputMaybe<GiftDistributionMaxOrderByAggregateInput>;
  _min?: InputMaybe<GiftDistributionMinOrderByAggregateInput>;
  archivedAt?: InputMaybe<SortOrder>;
  channelId?: InputMaybe<SortOrder>;
  createdAt?: InputMaybe<SortOrder>;
  giftId?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  transactionId?: InputMaybe<SortOrder>;
  updatedAt?: InputMaybe<SortOrder>;
  userId?: InputMaybe<SortOrder>;
  videoId?: InputMaybe<SortOrder>;
};

export type GiftDistributionOrderByWithRelationInput = {
  archivedAt?: InputMaybe<SortOrder>;
  channelId?: InputMaybe<SortOrder>;
  createdAt?: InputMaybe<SortOrder>;
  gift?: InputMaybe<GiftOrderByWithRelationInput>;
  giftId?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  receiver?: InputMaybe<ChannelOrderByWithRelationInput>;
  sender?: InputMaybe<UserOrderByWithRelationInput>;
  transaction?: InputMaybe<TransactionOrderByWithRelationInput>;
  transactionId?: InputMaybe<SortOrder>;
  updatedAt?: InputMaybe<SortOrder>;
  userId?: InputMaybe<SortOrder>;
  video?: InputMaybe<VideoOrderByWithRelationInput>;
  videoId?: InputMaybe<SortOrder>;
};

export type GiftDistributionRelationFilter = {
  is?: InputMaybe<GiftDistributionWhereInput>;
  isNot?: InputMaybe<GiftDistributionWhereInput>;
};

export enum GiftDistributionScalarFieldEnum {
  ArchivedAt = 'archivedAt',
  ChannelId = 'channelId',
  CreatedAt = 'createdAt',
  GiftId = 'giftId',
  Id = 'id',
  TransactionId = 'transactionId',
  UpdatedAt = 'updatedAt',
  UserId = 'userId',
  VideoId = 'videoId'
}

export type GiftDistributionScalarWhereInput = {
  AND?: InputMaybe<Array<GiftDistributionScalarWhereInput>>;
  NOT?: InputMaybe<Array<GiftDistributionScalarWhereInput>>;
  OR?: InputMaybe<Array<GiftDistributionScalarWhereInput>>;
  archivedAt?: InputMaybe<DateTimeNullableFilter>;
  channelId?: InputMaybe<StringFilter>;
  createdAt?: InputMaybe<DateTimeFilter>;
  giftId?: InputMaybe<StringFilter>;
  id?: InputMaybe<StringFilter>;
  transactionId?: InputMaybe<StringFilter>;
  updatedAt?: InputMaybe<DateTimeFilter>;
  userId?: InputMaybe<StringFilter>;
  videoId?: InputMaybe<StringFilter>;
};

export type GiftDistributionScalarWhereWithAggregatesInput = {
  AND?: InputMaybe<Array<GiftDistributionScalarWhereWithAggregatesInput>>;
  NOT?: InputMaybe<Array<GiftDistributionScalarWhereWithAggregatesInput>>;
  OR?: InputMaybe<Array<GiftDistributionScalarWhereWithAggregatesInput>>;
  archivedAt?: InputMaybe<DateTimeNullableWithAggregatesFilter>;
  channelId?: InputMaybe<StringWithAggregatesFilter>;
  createdAt?: InputMaybe<DateTimeWithAggregatesFilter>;
  giftId?: InputMaybe<StringWithAggregatesFilter>;
  id?: InputMaybe<StringWithAggregatesFilter>;
  transactionId?: InputMaybe<StringWithAggregatesFilter>;
  updatedAt?: InputMaybe<DateTimeWithAggregatesFilter>;
  userId?: InputMaybe<StringWithAggregatesFilter>;
  videoId?: InputMaybe<StringWithAggregatesFilter>;
};

export type GiftDistributionUpdateInput = {
  archivedAt?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  gift?: InputMaybe<GiftUpdateOneRequiredWithoutGiftDistributionsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  receiver?: InputMaybe<ChannelUpdateOneRequiredWithoutGiftsReceivedInput>;
  sender?: InputMaybe<UserUpdateOneRequiredWithoutGiftsSentInput>;
  transaction?: InputMaybe<TransactionUpdateOneRequiredWithoutGiftDistributionInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  video?: InputMaybe<VideoUpdateOneRequiredWithoutGiftDistributionsInput>;
};

export type GiftDistributionUpdateManyMutationInput = {
  archivedAt?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
};

export type GiftDistributionUpdateManyWithWhereWithoutGiftInput = {
  data: GiftDistributionUpdateManyMutationInput;
  where: GiftDistributionScalarWhereInput;
};

export type GiftDistributionUpdateManyWithWhereWithoutReceiverInput = {
  data: GiftDistributionUpdateManyMutationInput;
  where: GiftDistributionScalarWhereInput;
};

export type GiftDistributionUpdateManyWithWhereWithoutSenderInput = {
  data: GiftDistributionUpdateManyMutationInput;
  where: GiftDistributionScalarWhereInput;
};

export type GiftDistributionUpdateManyWithWhereWithoutVideoInput = {
  data: GiftDistributionUpdateManyMutationInput;
  where: GiftDistributionScalarWhereInput;
};

export type GiftDistributionUpdateManyWithoutGiftInput = {
  connect?: InputMaybe<Array<GiftDistributionWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<GiftDistributionCreateOrConnectWithoutGiftInput>>;
  create?: InputMaybe<Array<GiftDistributionCreateWithoutGiftInput>>;
  createMany?: InputMaybe<GiftDistributionCreateManyGiftInputEnvelope>;
  delete?: InputMaybe<Array<GiftDistributionWhereUniqueInput>>;
  deleteMany?: InputMaybe<Array<GiftDistributionScalarWhereInput>>;
  disconnect?: InputMaybe<Array<GiftDistributionWhereUniqueInput>>;
  set?: InputMaybe<Array<GiftDistributionWhereUniqueInput>>;
  update?: InputMaybe<Array<GiftDistributionUpdateWithWhereUniqueWithoutGiftInput>>;
  updateMany?: InputMaybe<Array<GiftDistributionUpdateManyWithWhereWithoutGiftInput>>;
  upsert?: InputMaybe<Array<GiftDistributionUpsertWithWhereUniqueWithoutGiftInput>>;
};

export type GiftDistributionUpdateManyWithoutReceiverInput = {
  connect?: InputMaybe<Array<GiftDistributionWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<GiftDistributionCreateOrConnectWithoutReceiverInput>>;
  create?: InputMaybe<Array<GiftDistributionCreateWithoutReceiverInput>>;
  createMany?: InputMaybe<GiftDistributionCreateManyReceiverInputEnvelope>;
  delete?: InputMaybe<Array<GiftDistributionWhereUniqueInput>>;
  deleteMany?: InputMaybe<Array<GiftDistributionScalarWhereInput>>;
  disconnect?: InputMaybe<Array<GiftDistributionWhereUniqueInput>>;
  set?: InputMaybe<Array<GiftDistributionWhereUniqueInput>>;
  update?: InputMaybe<Array<GiftDistributionUpdateWithWhereUniqueWithoutReceiverInput>>;
  updateMany?: InputMaybe<Array<GiftDistributionUpdateManyWithWhereWithoutReceiverInput>>;
  upsert?: InputMaybe<Array<GiftDistributionUpsertWithWhereUniqueWithoutReceiverInput>>;
};

export type GiftDistributionUpdateManyWithoutSenderInput = {
  connect?: InputMaybe<Array<GiftDistributionWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<GiftDistributionCreateOrConnectWithoutSenderInput>>;
  create?: InputMaybe<Array<GiftDistributionCreateWithoutSenderInput>>;
  createMany?: InputMaybe<GiftDistributionCreateManySenderInputEnvelope>;
  delete?: InputMaybe<Array<GiftDistributionWhereUniqueInput>>;
  deleteMany?: InputMaybe<Array<GiftDistributionScalarWhereInput>>;
  disconnect?: InputMaybe<Array<GiftDistributionWhereUniqueInput>>;
  set?: InputMaybe<Array<GiftDistributionWhereUniqueInput>>;
  update?: InputMaybe<Array<GiftDistributionUpdateWithWhereUniqueWithoutSenderInput>>;
  updateMany?: InputMaybe<Array<GiftDistributionUpdateManyWithWhereWithoutSenderInput>>;
  upsert?: InputMaybe<Array<GiftDistributionUpsertWithWhereUniqueWithoutSenderInput>>;
};

export type GiftDistributionUpdateManyWithoutVideoInput = {
  connect?: InputMaybe<Array<GiftDistributionWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<GiftDistributionCreateOrConnectWithoutVideoInput>>;
  create?: InputMaybe<Array<GiftDistributionCreateWithoutVideoInput>>;
  createMany?: InputMaybe<GiftDistributionCreateManyVideoInputEnvelope>;
  delete?: InputMaybe<Array<GiftDistributionWhereUniqueInput>>;
  deleteMany?: InputMaybe<Array<GiftDistributionScalarWhereInput>>;
  disconnect?: InputMaybe<Array<GiftDistributionWhereUniqueInput>>;
  set?: InputMaybe<Array<GiftDistributionWhereUniqueInput>>;
  update?: InputMaybe<Array<GiftDistributionUpdateWithWhereUniqueWithoutVideoInput>>;
  updateMany?: InputMaybe<Array<GiftDistributionUpdateManyWithWhereWithoutVideoInput>>;
  upsert?: InputMaybe<Array<GiftDistributionUpsertWithWhereUniqueWithoutVideoInput>>;
};

export type GiftDistributionUpdateOneWithoutTransactionInput = {
  connect?: InputMaybe<GiftDistributionWhereUniqueInput>;
  connectOrCreate?: InputMaybe<GiftDistributionCreateOrConnectWithoutTransactionInput>;
  create?: InputMaybe<GiftDistributionCreateWithoutTransactionInput>;
  delete?: InputMaybe<Scalars['Boolean']>;
  disconnect?: InputMaybe<Scalars['Boolean']>;
  update?: InputMaybe<GiftDistributionUpdateWithoutTransactionInput>;
  upsert?: InputMaybe<GiftDistributionUpsertWithoutTransactionInput>;
};

export type GiftDistributionUpdateWithWhereUniqueWithoutGiftInput = {
  data: GiftDistributionUpdateWithoutGiftInput;
  where: GiftDistributionWhereUniqueInput;
};

export type GiftDistributionUpdateWithWhereUniqueWithoutReceiverInput = {
  data: GiftDistributionUpdateWithoutReceiverInput;
  where: GiftDistributionWhereUniqueInput;
};

export type GiftDistributionUpdateWithWhereUniqueWithoutSenderInput = {
  data: GiftDistributionUpdateWithoutSenderInput;
  where: GiftDistributionWhereUniqueInput;
};

export type GiftDistributionUpdateWithWhereUniqueWithoutVideoInput = {
  data: GiftDistributionUpdateWithoutVideoInput;
  where: GiftDistributionWhereUniqueInput;
};

export type GiftDistributionUpdateWithoutGiftInput = {
  archivedAt?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  receiver?: InputMaybe<ChannelUpdateOneRequiredWithoutGiftsReceivedInput>;
  sender?: InputMaybe<UserUpdateOneRequiredWithoutGiftsSentInput>;
  transaction?: InputMaybe<TransactionUpdateOneRequiredWithoutGiftDistributionInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  video?: InputMaybe<VideoUpdateOneRequiredWithoutGiftDistributionsInput>;
};

export type GiftDistributionUpdateWithoutReceiverInput = {
  archivedAt?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  gift?: InputMaybe<GiftUpdateOneRequiredWithoutGiftDistributionsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  sender?: InputMaybe<UserUpdateOneRequiredWithoutGiftsSentInput>;
  transaction?: InputMaybe<TransactionUpdateOneRequiredWithoutGiftDistributionInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  video?: InputMaybe<VideoUpdateOneRequiredWithoutGiftDistributionsInput>;
};

export type GiftDistributionUpdateWithoutSenderInput = {
  archivedAt?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  gift?: InputMaybe<GiftUpdateOneRequiredWithoutGiftDistributionsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  receiver?: InputMaybe<ChannelUpdateOneRequiredWithoutGiftsReceivedInput>;
  transaction?: InputMaybe<TransactionUpdateOneRequiredWithoutGiftDistributionInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  video?: InputMaybe<VideoUpdateOneRequiredWithoutGiftDistributionsInput>;
};

export type GiftDistributionUpdateWithoutTransactionInput = {
  archivedAt?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  gift?: InputMaybe<GiftUpdateOneRequiredWithoutGiftDistributionsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  receiver?: InputMaybe<ChannelUpdateOneRequiredWithoutGiftsReceivedInput>;
  sender?: InputMaybe<UserUpdateOneRequiredWithoutGiftsSentInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  video?: InputMaybe<VideoUpdateOneRequiredWithoutGiftDistributionsInput>;
};

export type GiftDistributionUpdateWithoutVideoInput = {
  archivedAt?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  gift?: InputMaybe<GiftUpdateOneRequiredWithoutGiftDistributionsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  receiver?: InputMaybe<ChannelUpdateOneRequiredWithoutGiftsReceivedInput>;
  sender?: InputMaybe<UserUpdateOneRequiredWithoutGiftsSentInput>;
  transaction?: InputMaybe<TransactionUpdateOneRequiredWithoutGiftDistributionInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
};

export type GiftDistributionUpsertWithWhereUniqueWithoutGiftInput = {
  create: GiftDistributionCreateWithoutGiftInput;
  update: GiftDistributionUpdateWithoutGiftInput;
  where: GiftDistributionWhereUniqueInput;
};

export type GiftDistributionUpsertWithWhereUniqueWithoutReceiverInput = {
  create: GiftDistributionCreateWithoutReceiverInput;
  update: GiftDistributionUpdateWithoutReceiverInput;
  where: GiftDistributionWhereUniqueInput;
};

export type GiftDistributionUpsertWithWhereUniqueWithoutSenderInput = {
  create: GiftDistributionCreateWithoutSenderInput;
  update: GiftDistributionUpdateWithoutSenderInput;
  where: GiftDistributionWhereUniqueInput;
};

export type GiftDistributionUpsertWithWhereUniqueWithoutVideoInput = {
  create: GiftDistributionCreateWithoutVideoInput;
  update: GiftDistributionUpdateWithoutVideoInput;
  where: GiftDistributionWhereUniqueInput;
};

export type GiftDistributionUpsertWithoutTransactionInput = {
  create: GiftDistributionCreateWithoutTransactionInput;
  update: GiftDistributionUpdateWithoutTransactionInput;
};

export type GiftDistributionWhereInput = {
  AND?: InputMaybe<Array<GiftDistributionWhereInput>>;
  NOT?: InputMaybe<Array<GiftDistributionWhereInput>>;
  OR?: InputMaybe<Array<GiftDistributionWhereInput>>;
  archivedAt?: InputMaybe<DateTimeNullableFilter>;
  channelId?: InputMaybe<StringFilter>;
  createdAt?: InputMaybe<DateTimeFilter>;
  gift?: InputMaybe<GiftRelationFilter>;
  giftId?: InputMaybe<StringFilter>;
  id?: InputMaybe<StringFilter>;
  receiver?: InputMaybe<ChannelRelationFilter>;
  sender?: InputMaybe<UserRelationFilter>;
  transaction?: InputMaybe<TransactionRelationFilter>;
  transactionId?: InputMaybe<StringFilter>;
  updatedAt?: InputMaybe<DateTimeFilter>;
  userId?: InputMaybe<StringFilter>;
  video?: InputMaybe<VideoRelationFilter>;
  videoId?: InputMaybe<StringFilter>;
};

export type GiftDistributionWhereUniqueInput = {
  id?: InputMaybe<Scalars['String']>;
  transactionId?: InputMaybe<Scalars['String']>;
};

export type GiftGroupBy = {
  __typename?: 'GiftGroupBy';
  _avg?: Maybe<GiftAvgAggregate>;
  _count?: Maybe<GiftCountAggregate>;
  _max?: Maybe<GiftMaxAggregate>;
  _min?: Maybe<GiftMinAggregate>;
  _sum?: Maybe<GiftSumAggregate>;
  archivedAt?: Maybe<Scalars['DateTime']>;
  channelId: Scalars['String'];
  createdAt: Scalars['DateTime'];
  id: Scalars['String'];
  name: Scalars['String'];
  price: Scalars['Float'];
  thumbnailUrl: Scalars['String'];
  updatedAt: Scalars['DateTime'];
};

export type GiftListRelationFilter = {
  every?: InputMaybe<GiftWhereInput>;
  none?: InputMaybe<GiftWhereInput>;
  some?: InputMaybe<GiftWhereInput>;
};

export type GiftMaxAggregate = {
  __typename?: 'GiftMaxAggregate';
  archivedAt?: Maybe<Scalars['DateTime']>;
  channelId?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  price?: Maybe<Scalars['Float']>;
  thumbnailUrl?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type GiftMaxOrderByAggregateInput = {
  archivedAt?: InputMaybe<SortOrder>;
  channelId?: InputMaybe<SortOrder>;
  createdAt?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  name?: InputMaybe<SortOrder>;
  price?: InputMaybe<SortOrder>;
  thumbnailUrl?: InputMaybe<SortOrder>;
  updatedAt?: InputMaybe<SortOrder>;
};

export type GiftMinAggregate = {
  __typename?: 'GiftMinAggregate';
  archivedAt?: Maybe<Scalars['DateTime']>;
  channelId?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  price?: Maybe<Scalars['Float']>;
  thumbnailUrl?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type GiftMinOrderByAggregateInput = {
  archivedAt?: InputMaybe<SortOrder>;
  channelId?: InputMaybe<SortOrder>;
  createdAt?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  name?: InputMaybe<SortOrder>;
  price?: InputMaybe<SortOrder>;
  thumbnailUrl?: InputMaybe<SortOrder>;
  updatedAt?: InputMaybe<SortOrder>;
};

export type GiftOrderByRelationAggregateInput = {
  _count?: InputMaybe<SortOrder>;
};

export type GiftOrderByWithAggregationInput = {
  _avg?: InputMaybe<GiftAvgOrderByAggregateInput>;
  _count?: InputMaybe<GiftCountOrderByAggregateInput>;
  _max?: InputMaybe<GiftMaxOrderByAggregateInput>;
  _min?: InputMaybe<GiftMinOrderByAggregateInput>;
  _sum?: InputMaybe<GiftSumOrderByAggregateInput>;
  archivedAt?: InputMaybe<SortOrder>;
  channelId?: InputMaybe<SortOrder>;
  createdAt?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  name?: InputMaybe<SortOrder>;
  price?: InputMaybe<SortOrder>;
  thumbnailUrl?: InputMaybe<SortOrder>;
  updatedAt?: InputMaybe<SortOrder>;
};

export type GiftOrderByWithRelationInput = {
  archivedAt?: InputMaybe<SortOrder>;
  channel?: InputMaybe<ChannelOrderByWithRelationInput>;
  channelId?: InputMaybe<SortOrder>;
  createdAt?: InputMaybe<SortOrder>;
  giftDistributions?: InputMaybe<GiftDistributionOrderByRelationAggregateInput>;
  id?: InputMaybe<SortOrder>;
  name?: InputMaybe<SortOrder>;
  price?: InputMaybe<SortOrder>;
  thumbnailUrl?: InputMaybe<SortOrder>;
  updatedAt?: InputMaybe<SortOrder>;
};

export type GiftRelationFilter = {
  is?: InputMaybe<GiftWhereInput>;
  isNot?: InputMaybe<GiftWhereInput>;
};

export enum GiftScalarFieldEnum {
  ArchivedAt = 'archivedAt',
  ChannelId = 'channelId',
  CreatedAt = 'createdAt',
  Id = 'id',
  Name = 'name',
  Price = 'price',
  ThumbnailUrl = 'thumbnailUrl',
  UpdatedAt = 'updatedAt'
}

export type GiftScalarWhereInput = {
  AND?: InputMaybe<Array<GiftScalarWhereInput>>;
  NOT?: InputMaybe<Array<GiftScalarWhereInput>>;
  OR?: InputMaybe<Array<GiftScalarWhereInput>>;
  archivedAt?: InputMaybe<DateTimeNullableFilter>;
  channelId?: InputMaybe<StringFilter>;
  createdAt?: InputMaybe<DateTimeFilter>;
  id?: InputMaybe<StringFilter>;
  name?: InputMaybe<StringFilter>;
  price?: InputMaybe<FloatFilter>;
  thumbnailUrl?: InputMaybe<StringFilter>;
  updatedAt?: InputMaybe<DateTimeFilter>;
};

export type GiftScalarWhereWithAggregatesInput = {
  AND?: InputMaybe<Array<GiftScalarWhereWithAggregatesInput>>;
  NOT?: InputMaybe<Array<GiftScalarWhereWithAggregatesInput>>;
  OR?: InputMaybe<Array<GiftScalarWhereWithAggregatesInput>>;
  archivedAt?: InputMaybe<DateTimeNullableWithAggregatesFilter>;
  channelId?: InputMaybe<StringWithAggregatesFilter>;
  createdAt?: InputMaybe<DateTimeWithAggregatesFilter>;
  id?: InputMaybe<StringWithAggregatesFilter>;
  name?: InputMaybe<StringWithAggregatesFilter>;
  price?: InputMaybe<FloatWithAggregatesFilter>;
  thumbnailUrl?: InputMaybe<StringWithAggregatesFilter>;
  updatedAt?: InputMaybe<DateTimeWithAggregatesFilter>;
};

export type GiftSumAggregate = {
  __typename?: 'GiftSumAggregate';
  price?: Maybe<Scalars['Float']>;
};

export type GiftSumOrderByAggregateInput = {
  price?: InputMaybe<SortOrder>;
};

export type GiftUpdateInput = {
  archivedAt?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  channel?: InputMaybe<ChannelUpdateOneRequiredWithoutGiftsInput>;
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  giftDistributions?: InputMaybe<GiftDistributionUpdateManyWithoutGiftInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  name?: InputMaybe<StringFieldUpdateOperationsInput>;
  price?: InputMaybe<FloatFieldUpdateOperationsInput>;
  thumbnailUrl?: InputMaybe<StringFieldUpdateOperationsInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
};

export type GiftUpdateManyMutationInput = {
  archivedAt?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  name?: InputMaybe<StringFieldUpdateOperationsInput>;
  price?: InputMaybe<FloatFieldUpdateOperationsInput>;
  thumbnailUrl?: InputMaybe<StringFieldUpdateOperationsInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
};

export type GiftUpdateManyWithWhereWithoutChannelInput = {
  data: GiftUpdateManyMutationInput;
  where: GiftScalarWhereInput;
};

export type GiftUpdateManyWithoutChannelInput = {
  connect?: InputMaybe<Array<GiftWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<GiftCreateOrConnectWithoutChannelInput>>;
  create?: InputMaybe<Array<GiftCreateWithoutChannelInput>>;
  createMany?: InputMaybe<GiftCreateManyChannelInputEnvelope>;
  delete?: InputMaybe<Array<GiftWhereUniqueInput>>;
  deleteMany?: InputMaybe<Array<GiftScalarWhereInput>>;
  disconnect?: InputMaybe<Array<GiftWhereUniqueInput>>;
  set?: InputMaybe<Array<GiftWhereUniqueInput>>;
  update?: InputMaybe<Array<GiftUpdateWithWhereUniqueWithoutChannelInput>>;
  updateMany?: InputMaybe<Array<GiftUpdateManyWithWhereWithoutChannelInput>>;
  upsert?: InputMaybe<Array<GiftUpsertWithWhereUniqueWithoutChannelInput>>;
};

export type GiftUpdateOneRequiredWithoutGiftDistributionsInput = {
  connect?: InputMaybe<GiftWhereUniqueInput>;
  connectOrCreate?: InputMaybe<GiftCreateOrConnectWithoutGiftDistributionsInput>;
  create?: InputMaybe<GiftCreateWithoutGiftDistributionsInput>;
  update?: InputMaybe<GiftUpdateWithoutGiftDistributionsInput>;
  upsert?: InputMaybe<GiftUpsertWithoutGiftDistributionsInput>;
};

export type GiftUpdateWithWhereUniqueWithoutChannelInput = {
  data: GiftUpdateWithoutChannelInput;
  where: GiftWhereUniqueInput;
};

export type GiftUpdateWithoutChannelInput = {
  archivedAt?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  giftDistributions?: InputMaybe<GiftDistributionUpdateManyWithoutGiftInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  name?: InputMaybe<StringFieldUpdateOperationsInput>;
  price?: InputMaybe<FloatFieldUpdateOperationsInput>;
  thumbnailUrl?: InputMaybe<StringFieldUpdateOperationsInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
};

export type GiftUpdateWithoutGiftDistributionsInput = {
  archivedAt?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  channel?: InputMaybe<ChannelUpdateOneRequiredWithoutGiftsInput>;
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  name?: InputMaybe<StringFieldUpdateOperationsInput>;
  price?: InputMaybe<FloatFieldUpdateOperationsInput>;
  thumbnailUrl?: InputMaybe<StringFieldUpdateOperationsInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
};

export type GiftUpsertWithWhereUniqueWithoutChannelInput = {
  create: GiftCreateWithoutChannelInput;
  update: GiftUpdateWithoutChannelInput;
  where: GiftWhereUniqueInput;
};

export type GiftUpsertWithoutGiftDistributionsInput = {
  create: GiftCreateWithoutGiftDistributionsInput;
  update: GiftUpdateWithoutGiftDistributionsInput;
};

export type GiftWhereInput = {
  AND?: InputMaybe<Array<GiftWhereInput>>;
  NOT?: InputMaybe<Array<GiftWhereInput>>;
  OR?: InputMaybe<Array<GiftWhereInput>>;
  archivedAt?: InputMaybe<DateTimeNullableFilter>;
  channel?: InputMaybe<ChannelRelationFilter>;
  channelId?: InputMaybe<StringFilter>;
  createdAt?: InputMaybe<DateTimeFilter>;
  giftDistributions?: InputMaybe<GiftDistributionListRelationFilter>;
  id?: InputMaybe<StringFilter>;
  name?: InputMaybe<StringFilter>;
  price?: InputMaybe<FloatFilter>;
  thumbnailUrl?: InputMaybe<StringFilter>;
  updatedAt?: InputMaybe<DateTimeFilter>;
};

export type GiftWhereUniqueInput = {
  id?: InputMaybe<Scalars['String']>;
};

export type Inquiry = {
  __typename?: 'Inquiry';
  closedAt?: Maybe<Scalars['DateTime']>;
  createdAt: Scalars['DateTime'];
  email: Scalars['String'];
  id: Scalars['String'];
  internal_note?: Maybe<Scalars['String']>;
  question?: Maybe<Scalars['String']>;
  repliedAt?: Maybe<Scalars['DateTime']>;
  reply?: Maybe<Scalars['String']>;
  status: InquiryStatus;
  updatedAt: Scalars['DateTime'];
};

export type InquiryCountAggregate = {
  __typename?: 'InquiryCountAggregate';
  _all: Scalars['Int'];
  closedAt: Scalars['Int'];
  createdAt: Scalars['Int'];
  email: Scalars['Int'];
  id: Scalars['Int'];
  internal_note: Scalars['Int'];
  question: Scalars['Int'];
  repliedAt: Scalars['Int'];
  reply: Scalars['Int'];
  status: Scalars['Int'];
  updatedAt: Scalars['Int'];
};

export type InquiryCountOrderByAggregateInput = {
  closedAt?: InputMaybe<SortOrder>;
  createdAt?: InputMaybe<SortOrder>;
  email?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  internal_note?: InputMaybe<SortOrder>;
  question?: InputMaybe<SortOrder>;
  repliedAt?: InputMaybe<SortOrder>;
  reply?: InputMaybe<SortOrder>;
  status?: InputMaybe<SortOrder>;
  updatedAt?: InputMaybe<SortOrder>;
};

export type InquiryCreateInput = {
  closedAt?: InputMaybe<Scalars['DateTime']>;
  createdAt?: InputMaybe<Scalars['DateTime']>;
  email: Scalars['String'];
  id?: InputMaybe<Scalars['String']>;
  internal_note?: InputMaybe<Scalars['String']>;
  question?: InputMaybe<Scalars['String']>;
  repliedAt?: InputMaybe<Scalars['DateTime']>;
  reply?: InputMaybe<Scalars['String']>;
  status?: InputMaybe<InquiryStatus>;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
};

export type InquiryCreateManyInput = {
  closedAt?: InputMaybe<Scalars['DateTime']>;
  createdAt?: InputMaybe<Scalars['DateTime']>;
  email: Scalars['String'];
  id?: InputMaybe<Scalars['String']>;
  internal_note?: InputMaybe<Scalars['String']>;
  question?: InputMaybe<Scalars['String']>;
  repliedAt?: InputMaybe<Scalars['DateTime']>;
  reply?: InputMaybe<Scalars['String']>;
  status?: InputMaybe<InquiryStatus>;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
};

export type InquiryGroupBy = {
  __typename?: 'InquiryGroupBy';
  _count?: Maybe<InquiryCountAggregate>;
  _max?: Maybe<InquiryMaxAggregate>;
  _min?: Maybe<InquiryMinAggregate>;
  closedAt?: Maybe<Scalars['DateTime']>;
  createdAt: Scalars['DateTime'];
  email: Scalars['String'];
  id: Scalars['String'];
  internal_note?: Maybe<Scalars['String']>;
  question?: Maybe<Scalars['String']>;
  repliedAt?: Maybe<Scalars['DateTime']>;
  reply?: Maybe<Scalars['String']>;
  status: InquiryStatus;
  updatedAt: Scalars['DateTime'];
};

export type InquiryMaxAggregate = {
  __typename?: 'InquiryMaxAggregate';
  closedAt?: Maybe<Scalars['DateTime']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  email?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  internal_note?: Maybe<Scalars['String']>;
  question?: Maybe<Scalars['String']>;
  repliedAt?: Maybe<Scalars['DateTime']>;
  reply?: Maybe<Scalars['String']>;
  status?: Maybe<InquiryStatus>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type InquiryMaxOrderByAggregateInput = {
  closedAt?: InputMaybe<SortOrder>;
  createdAt?: InputMaybe<SortOrder>;
  email?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  internal_note?: InputMaybe<SortOrder>;
  question?: InputMaybe<SortOrder>;
  repliedAt?: InputMaybe<SortOrder>;
  reply?: InputMaybe<SortOrder>;
  status?: InputMaybe<SortOrder>;
  updatedAt?: InputMaybe<SortOrder>;
};

export type InquiryMinAggregate = {
  __typename?: 'InquiryMinAggregate';
  closedAt?: Maybe<Scalars['DateTime']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  email?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  internal_note?: Maybe<Scalars['String']>;
  question?: Maybe<Scalars['String']>;
  repliedAt?: Maybe<Scalars['DateTime']>;
  reply?: Maybe<Scalars['String']>;
  status?: Maybe<InquiryStatus>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type InquiryMinOrderByAggregateInput = {
  closedAt?: InputMaybe<SortOrder>;
  createdAt?: InputMaybe<SortOrder>;
  email?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  internal_note?: InputMaybe<SortOrder>;
  question?: InputMaybe<SortOrder>;
  repliedAt?: InputMaybe<SortOrder>;
  reply?: InputMaybe<SortOrder>;
  status?: InputMaybe<SortOrder>;
  updatedAt?: InputMaybe<SortOrder>;
};

export type InquiryOrderByWithAggregationInput = {
  _count?: InputMaybe<InquiryCountOrderByAggregateInput>;
  _max?: InputMaybe<InquiryMaxOrderByAggregateInput>;
  _min?: InputMaybe<InquiryMinOrderByAggregateInput>;
  closedAt?: InputMaybe<SortOrder>;
  createdAt?: InputMaybe<SortOrder>;
  email?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  internal_note?: InputMaybe<SortOrder>;
  question?: InputMaybe<SortOrder>;
  repliedAt?: InputMaybe<SortOrder>;
  reply?: InputMaybe<SortOrder>;
  status?: InputMaybe<SortOrder>;
  updatedAt?: InputMaybe<SortOrder>;
};

export type InquiryOrderByWithRelationInput = {
  closedAt?: InputMaybe<SortOrder>;
  createdAt?: InputMaybe<SortOrder>;
  email?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  internal_note?: InputMaybe<SortOrder>;
  question?: InputMaybe<SortOrder>;
  repliedAt?: InputMaybe<SortOrder>;
  reply?: InputMaybe<SortOrder>;
  status?: InputMaybe<SortOrder>;
  updatedAt?: InputMaybe<SortOrder>;
};

export enum InquiryScalarFieldEnum {
  ClosedAt = 'closedAt',
  CreatedAt = 'createdAt',
  Email = 'email',
  Id = 'id',
  InternalNote = 'internal_note',
  Question = 'question',
  RepliedAt = 'repliedAt',
  Reply = 'reply',
  Status = 'status',
  UpdatedAt = 'updatedAt'
}

export type InquiryScalarWhereWithAggregatesInput = {
  AND?: InputMaybe<Array<InquiryScalarWhereWithAggregatesInput>>;
  NOT?: InputMaybe<Array<InquiryScalarWhereWithAggregatesInput>>;
  OR?: InputMaybe<Array<InquiryScalarWhereWithAggregatesInput>>;
  closedAt?: InputMaybe<DateTimeNullableWithAggregatesFilter>;
  createdAt?: InputMaybe<DateTimeWithAggregatesFilter>;
  email?: InputMaybe<StringWithAggregatesFilter>;
  id?: InputMaybe<StringWithAggregatesFilter>;
  internal_note?: InputMaybe<StringNullableWithAggregatesFilter>;
  question?: InputMaybe<StringNullableWithAggregatesFilter>;
  repliedAt?: InputMaybe<DateTimeNullableWithAggregatesFilter>;
  reply?: InputMaybe<StringNullableWithAggregatesFilter>;
  status?: InputMaybe<EnumInquiryStatusWithAggregatesFilter>;
  updatedAt?: InputMaybe<DateTimeWithAggregatesFilter>;
};

export enum InquiryStatus {
  Closed = 'CLOSED',
  New = 'NEW',
  Replied = 'REPLIED'
}

export type InquiryUpdateInput = {
  closedAt?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  email?: InputMaybe<StringFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  internal_note?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  question?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  repliedAt?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  reply?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  status?: InputMaybe<EnumInquiryStatusFieldUpdateOperationsInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
};

export type InquiryUpdateManyMutationInput = {
  closedAt?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  email?: InputMaybe<StringFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  internal_note?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  question?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  repliedAt?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  reply?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  status?: InputMaybe<EnumInquiryStatusFieldUpdateOperationsInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
};

export type InquiryWhereInput = {
  AND?: InputMaybe<Array<InquiryWhereInput>>;
  NOT?: InputMaybe<Array<InquiryWhereInput>>;
  OR?: InputMaybe<Array<InquiryWhereInput>>;
  closedAt?: InputMaybe<DateTimeNullableFilter>;
  createdAt?: InputMaybe<DateTimeFilter>;
  email?: InputMaybe<StringFilter>;
  id?: InputMaybe<StringFilter>;
  internal_note?: InputMaybe<StringNullableFilter>;
  question?: InputMaybe<StringNullableFilter>;
  repliedAt?: InputMaybe<DateTimeNullableFilter>;
  reply?: InputMaybe<StringNullableFilter>;
  status?: InputMaybe<EnumInquiryStatusFilter>;
  updatedAt?: InputMaybe<DateTimeFilter>;
};

export type InquiryWhereUniqueInput = {
  id?: InputMaybe<Scalars['String']>;
};

export type IntFieldUpdateOperationsInput = {
  decrement?: InputMaybe<Scalars['Int']>;
  divide?: InputMaybe<Scalars['Int']>;
  increment?: InputMaybe<Scalars['Int']>;
  multiply?: InputMaybe<Scalars['Int']>;
  set?: InputMaybe<Scalars['Int']>;
};

export type IntFilter = {
  equals?: InputMaybe<Scalars['Int']>;
  gt?: InputMaybe<Scalars['Int']>;
  gte?: InputMaybe<Scalars['Int']>;
  in?: InputMaybe<Array<Scalars['Int']>>;
  lt?: InputMaybe<Scalars['Int']>;
  lte?: InputMaybe<Scalars['Int']>;
  not?: InputMaybe<NestedIntFilter>;
  notIn?: InputMaybe<Array<Scalars['Int']>>;
};

export type IntNullableFilter = {
  equals?: InputMaybe<Scalars['Int']>;
  gt?: InputMaybe<Scalars['Int']>;
  gte?: InputMaybe<Scalars['Int']>;
  in?: InputMaybe<Array<Scalars['Int']>>;
  lt?: InputMaybe<Scalars['Int']>;
  lte?: InputMaybe<Scalars['Int']>;
  not?: InputMaybe<NestedIntNullableFilter>;
  notIn?: InputMaybe<Array<Scalars['Int']>>;
};

export type IntNullableWithAggregatesFilter = {
  _avg?: InputMaybe<NestedFloatNullableFilter>;
  _count?: InputMaybe<NestedIntNullableFilter>;
  _max?: InputMaybe<NestedIntNullableFilter>;
  _min?: InputMaybe<NestedIntNullableFilter>;
  _sum?: InputMaybe<NestedIntNullableFilter>;
  equals?: InputMaybe<Scalars['Int']>;
  gt?: InputMaybe<Scalars['Int']>;
  gte?: InputMaybe<Scalars['Int']>;
  in?: InputMaybe<Array<Scalars['Int']>>;
  lt?: InputMaybe<Scalars['Int']>;
  lte?: InputMaybe<Scalars['Int']>;
  not?: InputMaybe<NestedIntNullableWithAggregatesFilter>;
  notIn?: InputMaybe<Array<Scalars['Int']>>;
};

export type IntWithAggregatesFilter = {
  _avg?: InputMaybe<NestedFloatFilter>;
  _count?: InputMaybe<NestedIntFilter>;
  _max?: InputMaybe<NestedIntFilter>;
  _min?: InputMaybe<NestedIntFilter>;
  _sum?: InputMaybe<NestedIntFilter>;
  equals?: InputMaybe<Scalars['Int']>;
  gt?: InputMaybe<Scalars['Int']>;
  gte?: InputMaybe<Scalars['Int']>;
  in?: InputMaybe<Array<Scalars['Int']>>;
  lt?: InputMaybe<Scalars['Int']>;
  lte?: InputMaybe<Scalars['Int']>;
  not?: InputMaybe<NestedIntWithAggregatesFilter>;
  notIn?: InputMaybe<Array<Scalars['Int']>>;
};

export type JsonFilter = {
  equals?: InputMaybe<Scalars['JSON']>;
  not?: InputMaybe<Scalars['JSON']>;
};

export type JsonNullableFilter = {
  equals?: InputMaybe<Scalars['JSON']>;
  not?: InputMaybe<Scalars['JSON']>;
};

export type JsonNullableWithAggregatesFilter = {
  _count?: InputMaybe<NestedIntNullableFilter>;
  _max?: InputMaybe<NestedJsonNullableFilter>;
  _min?: InputMaybe<NestedJsonNullableFilter>;
  equals?: InputMaybe<Scalars['JSON']>;
  not?: InputMaybe<Scalars['JSON']>;
};

export type JsonWithAggregatesFilter = {
  _count?: InputMaybe<NestedIntFilter>;
  _max?: InputMaybe<NestedJsonFilter>;
  _min?: InputMaybe<NestedJsonFilter>;
  equals?: InputMaybe<Scalars['JSON']>;
  not?: InputMaybe<Scalars['JSON']>;
};

export type LikedVideo = {
  __typename?: 'LikedVideo';
  active: Scalars['Boolean'];
  archivedAt?: Maybe<Scalars['DateTime']>;
  createdAt: Scalars['DateTime'];
  id: Scalars['String'];
  updatedAt: Scalars['DateTime'];
  user: User;
  userId: Scalars['String'];
  video: Video;
  videoId: Scalars['String'];
};

export type LikedVideoCountAggregate = {
  __typename?: 'LikedVideoCountAggregate';
  _all: Scalars['Int'];
  active: Scalars['Int'];
  archivedAt: Scalars['Int'];
  createdAt: Scalars['Int'];
  id: Scalars['Int'];
  updatedAt: Scalars['Int'];
  userId: Scalars['Int'];
  videoId: Scalars['Int'];
};

export type LikedVideoCountOrderByAggregateInput = {
  active?: InputMaybe<SortOrder>;
  archivedAt?: InputMaybe<SortOrder>;
  createdAt?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  updatedAt?: InputMaybe<SortOrder>;
  userId?: InputMaybe<SortOrder>;
  videoId?: InputMaybe<SortOrder>;
};

export type LikedVideoCreateInput = {
  active?: InputMaybe<Scalars['Boolean']>;
  archivedAt?: InputMaybe<Scalars['DateTime']>;
  createdAt?: InputMaybe<Scalars['DateTime']>;
  id?: InputMaybe<Scalars['String']>;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
  user: UserCreateNestedOneWithoutLikedVideosInput;
  video: VideoCreateNestedOneWithoutLikedVideosInput;
};

export type LikedVideoCreateManyInput = {
  active?: InputMaybe<Scalars['Boolean']>;
  archivedAt?: InputMaybe<Scalars['DateTime']>;
  createdAt?: InputMaybe<Scalars['DateTime']>;
  id?: InputMaybe<Scalars['String']>;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
  userId: Scalars['String'];
  videoId: Scalars['String'];
};

export type LikedVideoCreateManyUserInput = {
  active?: InputMaybe<Scalars['Boolean']>;
  archivedAt?: InputMaybe<Scalars['DateTime']>;
  createdAt?: InputMaybe<Scalars['DateTime']>;
  id?: InputMaybe<Scalars['String']>;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
  videoId: Scalars['String'];
};

export type LikedVideoCreateManyUserInputEnvelope = {
  data: Array<LikedVideoCreateManyUserInput>;
  skipDuplicates?: InputMaybe<Scalars['Boolean']>;
};

export type LikedVideoCreateManyVideoInput = {
  active?: InputMaybe<Scalars['Boolean']>;
  archivedAt?: InputMaybe<Scalars['DateTime']>;
  createdAt?: InputMaybe<Scalars['DateTime']>;
  id?: InputMaybe<Scalars['String']>;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
  userId: Scalars['String'];
};

export type LikedVideoCreateManyVideoInputEnvelope = {
  data: Array<LikedVideoCreateManyVideoInput>;
  skipDuplicates?: InputMaybe<Scalars['Boolean']>;
};

export type LikedVideoCreateNestedManyWithoutUserInput = {
  connect?: InputMaybe<Array<LikedVideoWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<LikedVideoCreateOrConnectWithoutUserInput>>;
  create?: InputMaybe<Array<LikedVideoCreateWithoutUserInput>>;
  createMany?: InputMaybe<LikedVideoCreateManyUserInputEnvelope>;
};

export type LikedVideoCreateNestedManyWithoutVideoInput = {
  connect?: InputMaybe<Array<LikedVideoWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<LikedVideoCreateOrConnectWithoutVideoInput>>;
  create?: InputMaybe<Array<LikedVideoCreateWithoutVideoInput>>;
  createMany?: InputMaybe<LikedVideoCreateManyVideoInputEnvelope>;
};

export type LikedVideoCreateOrConnectWithoutUserInput = {
  create: LikedVideoCreateWithoutUserInput;
  where: LikedVideoWhereUniqueInput;
};

export type LikedVideoCreateOrConnectWithoutVideoInput = {
  create: LikedVideoCreateWithoutVideoInput;
  where: LikedVideoWhereUniqueInput;
};

export type LikedVideoCreateWithoutUserInput = {
  active?: InputMaybe<Scalars['Boolean']>;
  archivedAt?: InputMaybe<Scalars['DateTime']>;
  createdAt?: InputMaybe<Scalars['DateTime']>;
  id?: InputMaybe<Scalars['String']>;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
  video: VideoCreateNestedOneWithoutLikedVideosInput;
};

export type LikedVideoCreateWithoutVideoInput = {
  active?: InputMaybe<Scalars['Boolean']>;
  archivedAt?: InputMaybe<Scalars['DateTime']>;
  createdAt?: InputMaybe<Scalars['DateTime']>;
  id?: InputMaybe<Scalars['String']>;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
  user: UserCreateNestedOneWithoutLikedVideosInput;
};

export type LikedVideoGroupBy = {
  __typename?: 'LikedVideoGroupBy';
  _count?: Maybe<LikedVideoCountAggregate>;
  _max?: Maybe<LikedVideoMaxAggregate>;
  _min?: Maybe<LikedVideoMinAggregate>;
  active: Scalars['Boolean'];
  archivedAt?: Maybe<Scalars['DateTime']>;
  createdAt: Scalars['DateTime'];
  id: Scalars['String'];
  updatedAt: Scalars['DateTime'];
  userId: Scalars['String'];
  videoId: Scalars['String'];
};

export type LikedVideoItem = {
  __typename?: 'LikedVideoItem';
  date: Scalars['DateTime'];
  likedRolls: Array<Video>;
  likedVideos: Array<Video>;
};

export type LikedVideoListRelationFilter = {
  every?: InputMaybe<LikedVideoWhereInput>;
  none?: InputMaybe<LikedVideoWhereInput>;
  some?: InputMaybe<LikedVideoWhereInput>;
};

export type LikedVideoMaxAggregate = {
  __typename?: 'LikedVideoMaxAggregate';
  active?: Maybe<Scalars['Boolean']>;
  archivedAt?: Maybe<Scalars['DateTime']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  userId?: Maybe<Scalars['String']>;
  videoId?: Maybe<Scalars['String']>;
};

export type LikedVideoMaxOrderByAggregateInput = {
  active?: InputMaybe<SortOrder>;
  archivedAt?: InputMaybe<SortOrder>;
  createdAt?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  updatedAt?: InputMaybe<SortOrder>;
  userId?: InputMaybe<SortOrder>;
  videoId?: InputMaybe<SortOrder>;
};

export type LikedVideoMinAggregate = {
  __typename?: 'LikedVideoMinAggregate';
  active?: Maybe<Scalars['Boolean']>;
  archivedAt?: Maybe<Scalars['DateTime']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  userId?: Maybe<Scalars['String']>;
  videoId?: Maybe<Scalars['String']>;
};

export type LikedVideoMinOrderByAggregateInput = {
  active?: InputMaybe<SortOrder>;
  archivedAt?: InputMaybe<SortOrder>;
  createdAt?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  updatedAt?: InputMaybe<SortOrder>;
  userId?: InputMaybe<SortOrder>;
  videoId?: InputMaybe<SortOrder>;
};

export type LikedVideoOrderByRelationAggregateInput = {
  _count?: InputMaybe<SortOrder>;
};

export type LikedVideoOrderByWithAggregationInput = {
  _count?: InputMaybe<LikedVideoCountOrderByAggregateInput>;
  _max?: InputMaybe<LikedVideoMaxOrderByAggregateInput>;
  _min?: InputMaybe<LikedVideoMinOrderByAggregateInput>;
  active?: InputMaybe<SortOrder>;
  archivedAt?: InputMaybe<SortOrder>;
  createdAt?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  updatedAt?: InputMaybe<SortOrder>;
  userId?: InputMaybe<SortOrder>;
  videoId?: InputMaybe<SortOrder>;
};

export type LikedVideoOrderByWithRelationInput = {
  active?: InputMaybe<SortOrder>;
  archivedAt?: InputMaybe<SortOrder>;
  createdAt?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  updatedAt?: InputMaybe<SortOrder>;
  user?: InputMaybe<UserOrderByWithRelationInput>;
  userId?: InputMaybe<SortOrder>;
  video?: InputMaybe<VideoOrderByWithRelationInput>;
  videoId?: InputMaybe<SortOrder>;
};

export enum LikedVideoScalarFieldEnum {
  Active = 'active',
  ArchivedAt = 'archivedAt',
  CreatedAt = 'createdAt',
  Id = 'id',
  UpdatedAt = 'updatedAt',
  UserId = 'userId',
  VideoId = 'videoId'
}

export type LikedVideoScalarWhereInput = {
  AND?: InputMaybe<Array<LikedVideoScalarWhereInput>>;
  NOT?: InputMaybe<Array<LikedVideoScalarWhereInput>>;
  OR?: InputMaybe<Array<LikedVideoScalarWhereInput>>;
  active?: InputMaybe<BoolFilter>;
  archivedAt?: InputMaybe<DateTimeNullableFilter>;
  createdAt?: InputMaybe<DateTimeFilter>;
  id?: InputMaybe<StringFilter>;
  updatedAt?: InputMaybe<DateTimeFilter>;
  userId?: InputMaybe<StringFilter>;
  videoId?: InputMaybe<StringFilter>;
};

export type LikedVideoScalarWhereWithAggregatesInput = {
  AND?: InputMaybe<Array<LikedVideoScalarWhereWithAggregatesInput>>;
  NOT?: InputMaybe<Array<LikedVideoScalarWhereWithAggregatesInput>>;
  OR?: InputMaybe<Array<LikedVideoScalarWhereWithAggregatesInput>>;
  active?: InputMaybe<BoolWithAggregatesFilter>;
  archivedAt?: InputMaybe<DateTimeNullableWithAggregatesFilter>;
  createdAt?: InputMaybe<DateTimeWithAggregatesFilter>;
  id?: InputMaybe<StringWithAggregatesFilter>;
  updatedAt?: InputMaybe<DateTimeWithAggregatesFilter>;
  userId?: InputMaybe<StringWithAggregatesFilter>;
  videoId?: InputMaybe<StringWithAggregatesFilter>;
};

export type LikedVideoUpdateInput = {
  active?: InputMaybe<BoolFieldUpdateOperationsInput>;
  archivedAt?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  user?: InputMaybe<UserUpdateOneRequiredWithoutLikedVideosInput>;
  video?: InputMaybe<VideoUpdateOneRequiredWithoutLikedVideosInput>;
};

export type LikedVideoUpdateManyMutationInput = {
  active?: InputMaybe<BoolFieldUpdateOperationsInput>;
  archivedAt?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
};

export type LikedVideoUpdateManyWithWhereWithoutUserInput = {
  data: LikedVideoUpdateManyMutationInput;
  where: LikedVideoScalarWhereInput;
};

export type LikedVideoUpdateManyWithWhereWithoutVideoInput = {
  data: LikedVideoUpdateManyMutationInput;
  where: LikedVideoScalarWhereInput;
};

export type LikedVideoUpdateManyWithoutUserInput = {
  connect?: InputMaybe<Array<LikedVideoWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<LikedVideoCreateOrConnectWithoutUserInput>>;
  create?: InputMaybe<Array<LikedVideoCreateWithoutUserInput>>;
  createMany?: InputMaybe<LikedVideoCreateManyUserInputEnvelope>;
  delete?: InputMaybe<Array<LikedVideoWhereUniqueInput>>;
  deleteMany?: InputMaybe<Array<LikedVideoScalarWhereInput>>;
  disconnect?: InputMaybe<Array<LikedVideoWhereUniqueInput>>;
  set?: InputMaybe<Array<LikedVideoWhereUniqueInput>>;
  update?: InputMaybe<Array<LikedVideoUpdateWithWhereUniqueWithoutUserInput>>;
  updateMany?: InputMaybe<Array<LikedVideoUpdateManyWithWhereWithoutUserInput>>;
  upsert?: InputMaybe<Array<LikedVideoUpsertWithWhereUniqueWithoutUserInput>>;
};

export type LikedVideoUpdateManyWithoutVideoInput = {
  connect?: InputMaybe<Array<LikedVideoWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<LikedVideoCreateOrConnectWithoutVideoInput>>;
  create?: InputMaybe<Array<LikedVideoCreateWithoutVideoInput>>;
  createMany?: InputMaybe<LikedVideoCreateManyVideoInputEnvelope>;
  delete?: InputMaybe<Array<LikedVideoWhereUniqueInput>>;
  deleteMany?: InputMaybe<Array<LikedVideoScalarWhereInput>>;
  disconnect?: InputMaybe<Array<LikedVideoWhereUniqueInput>>;
  set?: InputMaybe<Array<LikedVideoWhereUniqueInput>>;
  update?: InputMaybe<Array<LikedVideoUpdateWithWhereUniqueWithoutVideoInput>>;
  updateMany?: InputMaybe<Array<LikedVideoUpdateManyWithWhereWithoutVideoInput>>;
  upsert?: InputMaybe<Array<LikedVideoUpsertWithWhereUniqueWithoutVideoInput>>;
};

export type LikedVideoUpdateWithWhereUniqueWithoutUserInput = {
  data: LikedVideoUpdateWithoutUserInput;
  where: LikedVideoWhereUniqueInput;
};

export type LikedVideoUpdateWithWhereUniqueWithoutVideoInput = {
  data: LikedVideoUpdateWithoutVideoInput;
  where: LikedVideoWhereUniqueInput;
};

export type LikedVideoUpdateWithoutUserInput = {
  active?: InputMaybe<BoolFieldUpdateOperationsInput>;
  archivedAt?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  video?: InputMaybe<VideoUpdateOneRequiredWithoutLikedVideosInput>;
};

export type LikedVideoUpdateWithoutVideoInput = {
  active?: InputMaybe<BoolFieldUpdateOperationsInput>;
  archivedAt?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  user?: InputMaybe<UserUpdateOneRequiredWithoutLikedVideosInput>;
};

export type LikedVideoUpsertWithWhereUniqueWithoutUserInput = {
  create: LikedVideoCreateWithoutUserInput;
  update: LikedVideoUpdateWithoutUserInput;
  where: LikedVideoWhereUniqueInput;
};

export type LikedVideoUpsertWithWhereUniqueWithoutVideoInput = {
  create: LikedVideoCreateWithoutVideoInput;
  update: LikedVideoUpdateWithoutVideoInput;
  where: LikedVideoWhereUniqueInput;
};

export type LikedVideoVideoIdUserIdCompoundUniqueInput = {
  userId: Scalars['String'];
  videoId: Scalars['String'];
};

export type LikedVideoWhereInput = {
  AND?: InputMaybe<Array<LikedVideoWhereInput>>;
  NOT?: InputMaybe<Array<LikedVideoWhereInput>>;
  OR?: InputMaybe<Array<LikedVideoWhereInput>>;
  active?: InputMaybe<BoolFilter>;
  archivedAt?: InputMaybe<DateTimeNullableFilter>;
  createdAt?: InputMaybe<DateTimeFilter>;
  id?: InputMaybe<StringFilter>;
  updatedAt?: InputMaybe<DateTimeFilter>;
  user?: InputMaybe<UserRelationFilter>;
  userId?: InputMaybe<StringFilter>;
  video?: InputMaybe<VideoRelationFilter>;
  videoId?: InputMaybe<StringFilter>;
};

export type LikedVideoWhereUniqueInput = {
  id?: InputMaybe<Scalars['String']>;
  videoId_userId?: InputMaybe<LikedVideoVideoIdUserIdCompoundUniqueInput>;
};

export type LoginInput = {
  password: Scalars['String'];
  prestoUserId?: InputMaybe<Scalars['String']>;
  username: Scalars['String'];
};

export enum MintStage {
  EndSales = 'END_SALES',
  New = 'NEW',
  PreSales = 'PRE_SALES',
  PublicSales = 'PUBLIC_SALES'
}

export type MintedNft = {
  __typename?: 'MintedNft';
  createdAt: Scalars['DateTime'];
  creatorUser: User;
  creatorUserId: Scalars['String'];
  creatorWalletAddress: Scalars['String'];
  currencyCode: CurrencyCode;
  holderUser: User;
  holderUserId: Scalars['String'];
  holderWalletAddress: Scalars['String'];
  id: Scalars['String'];
  isPreSales: Scalars['Boolean'];
  nftCollection: NftCollection;
  nftCollectionId: Scalars['String'];
  nftCollectionSnapshot: Scalars['JSON'];
  paymentGateway: PaymentGateway;
  price: Scalars['Float'];
  updatedAt: Scalars['DateTime'];
};

export type MintedNftAvgAggregate = {
  __typename?: 'MintedNftAvgAggregate';
  price?: Maybe<Scalars['Float']>;
};

export type MintedNftAvgOrderByAggregateInput = {
  price?: InputMaybe<SortOrder>;
};

export type MintedNftCountAggregate = {
  __typename?: 'MintedNftCountAggregate';
  _all: Scalars['Int'];
  createdAt: Scalars['Int'];
  creatorUserId: Scalars['Int'];
  creatorWalletAddress: Scalars['Int'];
  currencyCode: Scalars['Int'];
  holderUserId: Scalars['Int'];
  holderWalletAddress: Scalars['Int'];
  id: Scalars['Int'];
  isPreSales: Scalars['Int'];
  nftCollectionId: Scalars['Int'];
  nftCollectionSnapshot: Scalars['Int'];
  paymentGateway: Scalars['Int'];
  price: Scalars['Int'];
  updatedAt: Scalars['Int'];
};

export type MintedNftCountOrderByAggregateInput = {
  createdAt?: InputMaybe<SortOrder>;
  creatorUserId?: InputMaybe<SortOrder>;
  creatorWalletAddress?: InputMaybe<SortOrder>;
  currencyCode?: InputMaybe<SortOrder>;
  holderUserId?: InputMaybe<SortOrder>;
  holderWalletAddress?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  isPreSales?: InputMaybe<SortOrder>;
  nftCollectionId?: InputMaybe<SortOrder>;
  nftCollectionSnapshot?: InputMaybe<SortOrder>;
  paymentGateway?: InputMaybe<SortOrder>;
  price?: InputMaybe<SortOrder>;
  updatedAt?: InputMaybe<SortOrder>;
};

export type MintedNftCreateInput = {
  createdAt?: InputMaybe<Scalars['DateTime']>;
  creatorUser: UserCreateNestedOneWithoutCreatedNftsInput;
  creatorWalletAddress: Scalars['String'];
  currencyCode: CurrencyCode;
  holderUser: UserCreateNestedOneWithoutHoldNftsInput;
  holderWalletAddress: Scalars['String'];
  id?: InputMaybe<Scalars['String']>;
  isPreSales: Scalars['Boolean'];
  nftCollection: NftCollectionCreateNestedOneWithoutMintedNftsInput;
  nftCollectionSnapshot: Scalars['JSON'];
  paymentGateway: PaymentGateway;
  price: Scalars['Float'];
  updatedAt?: InputMaybe<Scalars['DateTime']>;
};

export type MintedNftCreateManyCreatorUserInput = {
  createdAt?: InputMaybe<Scalars['DateTime']>;
  creatorWalletAddress: Scalars['String'];
  currencyCode: CurrencyCode;
  holderUserId: Scalars['String'];
  holderWalletAddress: Scalars['String'];
  id?: InputMaybe<Scalars['String']>;
  isPreSales: Scalars['Boolean'];
  nftCollectionId: Scalars['String'];
  nftCollectionSnapshot: Scalars['JSON'];
  paymentGateway: PaymentGateway;
  price: Scalars['Float'];
  updatedAt?: InputMaybe<Scalars['DateTime']>;
};

export type MintedNftCreateManyCreatorUserInputEnvelope = {
  data: Array<MintedNftCreateManyCreatorUserInput>;
  skipDuplicates?: InputMaybe<Scalars['Boolean']>;
};

export type MintedNftCreateManyHolderUserInput = {
  createdAt?: InputMaybe<Scalars['DateTime']>;
  creatorUserId: Scalars['String'];
  creatorWalletAddress: Scalars['String'];
  currencyCode: CurrencyCode;
  holderWalletAddress: Scalars['String'];
  id?: InputMaybe<Scalars['String']>;
  isPreSales: Scalars['Boolean'];
  nftCollectionId: Scalars['String'];
  nftCollectionSnapshot: Scalars['JSON'];
  paymentGateway: PaymentGateway;
  price: Scalars['Float'];
  updatedAt?: InputMaybe<Scalars['DateTime']>;
};

export type MintedNftCreateManyHolderUserInputEnvelope = {
  data: Array<MintedNftCreateManyHolderUserInput>;
  skipDuplicates?: InputMaybe<Scalars['Boolean']>;
};

export type MintedNftCreateManyInput = {
  createdAt?: InputMaybe<Scalars['DateTime']>;
  creatorUserId: Scalars['String'];
  creatorWalletAddress: Scalars['String'];
  currencyCode: CurrencyCode;
  holderUserId: Scalars['String'];
  holderWalletAddress: Scalars['String'];
  id?: InputMaybe<Scalars['String']>;
  isPreSales: Scalars['Boolean'];
  nftCollectionId: Scalars['String'];
  nftCollectionSnapshot: Scalars['JSON'];
  paymentGateway: PaymentGateway;
  price: Scalars['Float'];
  updatedAt?: InputMaybe<Scalars['DateTime']>;
};

export type MintedNftCreateManyNftCollectionInput = {
  createdAt?: InputMaybe<Scalars['DateTime']>;
  creatorUserId: Scalars['String'];
  creatorWalletAddress: Scalars['String'];
  currencyCode: CurrencyCode;
  holderUserId: Scalars['String'];
  holderWalletAddress: Scalars['String'];
  id?: InputMaybe<Scalars['String']>;
  isPreSales: Scalars['Boolean'];
  nftCollectionSnapshot: Scalars['JSON'];
  paymentGateway: PaymentGateway;
  price: Scalars['Float'];
  updatedAt?: InputMaybe<Scalars['DateTime']>;
};

export type MintedNftCreateManyNftCollectionInputEnvelope = {
  data: Array<MintedNftCreateManyNftCollectionInput>;
  skipDuplicates?: InputMaybe<Scalars['Boolean']>;
};

export type MintedNftCreateNestedManyWithoutCreatorUserInput = {
  connect?: InputMaybe<Array<MintedNftWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<MintedNftCreateOrConnectWithoutCreatorUserInput>>;
  create?: InputMaybe<Array<MintedNftCreateWithoutCreatorUserInput>>;
  createMany?: InputMaybe<MintedNftCreateManyCreatorUserInputEnvelope>;
};

export type MintedNftCreateNestedManyWithoutHolderUserInput = {
  connect?: InputMaybe<Array<MintedNftWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<MintedNftCreateOrConnectWithoutHolderUserInput>>;
  create?: InputMaybe<Array<MintedNftCreateWithoutHolderUserInput>>;
  createMany?: InputMaybe<MintedNftCreateManyHolderUserInputEnvelope>;
};

export type MintedNftCreateNestedManyWithoutNftCollectionInput = {
  connect?: InputMaybe<Array<MintedNftWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<MintedNftCreateOrConnectWithoutNftCollectionInput>>;
  create?: InputMaybe<Array<MintedNftCreateWithoutNftCollectionInput>>;
  createMany?: InputMaybe<MintedNftCreateManyNftCollectionInputEnvelope>;
};

export type MintedNftCreateOrConnectWithoutCreatorUserInput = {
  create: MintedNftCreateWithoutCreatorUserInput;
  where: MintedNftWhereUniqueInput;
};

export type MintedNftCreateOrConnectWithoutHolderUserInput = {
  create: MintedNftCreateWithoutHolderUserInput;
  where: MintedNftWhereUniqueInput;
};

export type MintedNftCreateOrConnectWithoutNftCollectionInput = {
  create: MintedNftCreateWithoutNftCollectionInput;
  where: MintedNftWhereUniqueInput;
};

export type MintedNftCreateWithoutCreatorUserInput = {
  createdAt?: InputMaybe<Scalars['DateTime']>;
  creatorWalletAddress: Scalars['String'];
  currencyCode: CurrencyCode;
  holderUser: UserCreateNestedOneWithoutHoldNftsInput;
  holderWalletAddress: Scalars['String'];
  id?: InputMaybe<Scalars['String']>;
  isPreSales: Scalars['Boolean'];
  nftCollection: NftCollectionCreateNestedOneWithoutMintedNftsInput;
  nftCollectionSnapshot: Scalars['JSON'];
  paymentGateway: PaymentGateway;
  price: Scalars['Float'];
  updatedAt?: InputMaybe<Scalars['DateTime']>;
};

export type MintedNftCreateWithoutHolderUserInput = {
  createdAt?: InputMaybe<Scalars['DateTime']>;
  creatorUser: UserCreateNestedOneWithoutCreatedNftsInput;
  creatorWalletAddress: Scalars['String'];
  currencyCode: CurrencyCode;
  holderWalletAddress: Scalars['String'];
  id?: InputMaybe<Scalars['String']>;
  isPreSales: Scalars['Boolean'];
  nftCollection: NftCollectionCreateNestedOneWithoutMintedNftsInput;
  nftCollectionSnapshot: Scalars['JSON'];
  paymentGateway: PaymentGateway;
  price: Scalars['Float'];
  updatedAt?: InputMaybe<Scalars['DateTime']>;
};

export type MintedNftCreateWithoutNftCollectionInput = {
  createdAt?: InputMaybe<Scalars['DateTime']>;
  creatorUser: UserCreateNestedOneWithoutCreatedNftsInput;
  creatorWalletAddress: Scalars['String'];
  currencyCode: CurrencyCode;
  holderUser: UserCreateNestedOneWithoutHoldNftsInput;
  holderWalletAddress: Scalars['String'];
  id?: InputMaybe<Scalars['String']>;
  isPreSales: Scalars['Boolean'];
  nftCollectionSnapshot: Scalars['JSON'];
  paymentGateway: PaymentGateway;
  price: Scalars['Float'];
  updatedAt?: InputMaybe<Scalars['DateTime']>;
};

export type MintedNftGroupBy = {
  __typename?: 'MintedNftGroupBy';
  _avg?: Maybe<MintedNftAvgAggregate>;
  _count?: Maybe<MintedNftCountAggregate>;
  _max?: Maybe<MintedNftMaxAggregate>;
  _min?: Maybe<MintedNftMinAggregate>;
  _sum?: Maybe<MintedNftSumAggregate>;
  createdAt: Scalars['DateTime'];
  creatorUserId: Scalars['String'];
  creatorWalletAddress: Scalars['String'];
  currencyCode: CurrencyCode;
  holderUserId: Scalars['String'];
  holderWalletAddress: Scalars['String'];
  id: Scalars['String'];
  isPreSales: Scalars['Boolean'];
  nftCollectionId: Scalars['String'];
  nftCollectionSnapshot: Scalars['JSON'];
  paymentGateway: PaymentGateway;
  price: Scalars['Float'];
  updatedAt: Scalars['DateTime'];
};

export type MintedNftListRelationFilter = {
  every?: InputMaybe<MintedNftWhereInput>;
  none?: InputMaybe<MintedNftWhereInput>;
  some?: InputMaybe<MintedNftWhereInput>;
};

export type MintedNftMaxAggregate = {
  __typename?: 'MintedNftMaxAggregate';
  createdAt?: Maybe<Scalars['DateTime']>;
  creatorUserId?: Maybe<Scalars['String']>;
  creatorWalletAddress?: Maybe<Scalars['String']>;
  currencyCode?: Maybe<CurrencyCode>;
  holderUserId?: Maybe<Scalars['String']>;
  holderWalletAddress?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  isPreSales?: Maybe<Scalars['Boolean']>;
  nftCollectionId?: Maybe<Scalars['String']>;
  paymentGateway?: Maybe<PaymentGateway>;
  price?: Maybe<Scalars['Float']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type MintedNftMaxOrderByAggregateInput = {
  createdAt?: InputMaybe<SortOrder>;
  creatorUserId?: InputMaybe<SortOrder>;
  creatorWalletAddress?: InputMaybe<SortOrder>;
  currencyCode?: InputMaybe<SortOrder>;
  holderUserId?: InputMaybe<SortOrder>;
  holderWalletAddress?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  isPreSales?: InputMaybe<SortOrder>;
  nftCollectionId?: InputMaybe<SortOrder>;
  paymentGateway?: InputMaybe<SortOrder>;
  price?: InputMaybe<SortOrder>;
  updatedAt?: InputMaybe<SortOrder>;
};

export type MintedNftMinAggregate = {
  __typename?: 'MintedNftMinAggregate';
  createdAt?: Maybe<Scalars['DateTime']>;
  creatorUserId?: Maybe<Scalars['String']>;
  creatorWalletAddress?: Maybe<Scalars['String']>;
  currencyCode?: Maybe<CurrencyCode>;
  holderUserId?: Maybe<Scalars['String']>;
  holderWalletAddress?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  isPreSales?: Maybe<Scalars['Boolean']>;
  nftCollectionId?: Maybe<Scalars['String']>;
  paymentGateway?: Maybe<PaymentGateway>;
  price?: Maybe<Scalars['Float']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type MintedNftMinOrderByAggregateInput = {
  createdAt?: InputMaybe<SortOrder>;
  creatorUserId?: InputMaybe<SortOrder>;
  creatorWalletAddress?: InputMaybe<SortOrder>;
  currencyCode?: InputMaybe<SortOrder>;
  holderUserId?: InputMaybe<SortOrder>;
  holderWalletAddress?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  isPreSales?: InputMaybe<SortOrder>;
  nftCollectionId?: InputMaybe<SortOrder>;
  paymentGateway?: InputMaybe<SortOrder>;
  price?: InputMaybe<SortOrder>;
  updatedAt?: InputMaybe<SortOrder>;
};

export type MintedNftOrderByRelationAggregateInput = {
  _count?: InputMaybe<SortOrder>;
};

export type MintedNftOrderByWithAggregationInput = {
  _avg?: InputMaybe<MintedNftAvgOrderByAggregateInput>;
  _count?: InputMaybe<MintedNftCountOrderByAggregateInput>;
  _max?: InputMaybe<MintedNftMaxOrderByAggregateInput>;
  _min?: InputMaybe<MintedNftMinOrderByAggregateInput>;
  _sum?: InputMaybe<MintedNftSumOrderByAggregateInput>;
  createdAt?: InputMaybe<SortOrder>;
  creatorUserId?: InputMaybe<SortOrder>;
  creatorWalletAddress?: InputMaybe<SortOrder>;
  currencyCode?: InputMaybe<SortOrder>;
  holderUserId?: InputMaybe<SortOrder>;
  holderWalletAddress?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  isPreSales?: InputMaybe<SortOrder>;
  nftCollectionId?: InputMaybe<SortOrder>;
  nftCollectionSnapshot?: InputMaybe<SortOrder>;
  paymentGateway?: InputMaybe<SortOrder>;
  price?: InputMaybe<SortOrder>;
  updatedAt?: InputMaybe<SortOrder>;
};

export type MintedNftOrderByWithRelationInput = {
  createdAt?: InputMaybe<SortOrder>;
  creatorUser?: InputMaybe<UserOrderByWithRelationInput>;
  creatorUserId?: InputMaybe<SortOrder>;
  creatorWalletAddress?: InputMaybe<SortOrder>;
  currencyCode?: InputMaybe<SortOrder>;
  holderUser?: InputMaybe<UserOrderByWithRelationInput>;
  holderUserId?: InputMaybe<SortOrder>;
  holderWalletAddress?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  isPreSales?: InputMaybe<SortOrder>;
  nftCollection?: InputMaybe<NftCollectionOrderByWithRelationInput>;
  nftCollectionId?: InputMaybe<SortOrder>;
  nftCollectionSnapshot?: InputMaybe<SortOrder>;
  paymentGateway?: InputMaybe<SortOrder>;
  price?: InputMaybe<SortOrder>;
  updatedAt?: InputMaybe<SortOrder>;
};

export enum MintedNftScalarFieldEnum {
  CreatedAt = 'createdAt',
  CreatorUserId = 'creatorUserId',
  CreatorWalletAddress = 'creatorWalletAddress',
  CurrencyCode = 'currencyCode',
  HolderUserId = 'holderUserId',
  HolderWalletAddress = 'holderWalletAddress',
  Id = 'id',
  IsPreSales = 'isPreSales',
  NftCollectionId = 'nftCollectionId',
  NftCollectionSnapshot = 'nftCollectionSnapshot',
  PaymentGateway = 'paymentGateway',
  Price = 'price',
  UpdatedAt = 'updatedAt'
}

export type MintedNftScalarWhereInput = {
  AND?: InputMaybe<Array<MintedNftScalarWhereInput>>;
  NOT?: InputMaybe<Array<MintedNftScalarWhereInput>>;
  OR?: InputMaybe<Array<MintedNftScalarWhereInput>>;
  createdAt?: InputMaybe<DateTimeFilter>;
  creatorUserId?: InputMaybe<StringFilter>;
  creatorWalletAddress?: InputMaybe<StringFilter>;
  currencyCode?: InputMaybe<EnumCurrencyCodeFilter>;
  holderUserId?: InputMaybe<StringFilter>;
  holderWalletAddress?: InputMaybe<StringFilter>;
  id?: InputMaybe<StringFilter>;
  isPreSales?: InputMaybe<BoolFilter>;
  nftCollectionId?: InputMaybe<StringFilter>;
  nftCollectionSnapshot?: InputMaybe<JsonFilter>;
  paymentGateway?: InputMaybe<EnumPaymentGatewayFilter>;
  price?: InputMaybe<FloatFilter>;
  updatedAt?: InputMaybe<DateTimeFilter>;
};

export type MintedNftScalarWhereWithAggregatesInput = {
  AND?: InputMaybe<Array<MintedNftScalarWhereWithAggregatesInput>>;
  NOT?: InputMaybe<Array<MintedNftScalarWhereWithAggregatesInput>>;
  OR?: InputMaybe<Array<MintedNftScalarWhereWithAggregatesInput>>;
  createdAt?: InputMaybe<DateTimeWithAggregatesFilter>;
  creatorUserId?: InputMaybe<StringWithAggregatesFilter>;
  creatorWalletAddress?: InputMaybe<StringWithAggregatesFilter>;
  currencyCode?: InputMaybe<EnumCurrencyCodeWithAggregatesFilter>;
  holderUserId?: InputMaybe<StringWithAggregatesFilter>;
  holderWalletAddress?: InputMaybe<StringWithAggregatesFilter>;
  id?: InputMaybe<StringWithAggregatesFilter>;
  isPreSales?: InputMaybe<BoolWithAggregatesFilter>;
  nftCollectionId?: InputMaybe<StringWithAggregatesFilter>;
  nftCollectionSnapshot?: InputMaybe<JsonWithAggregatesFilter>;
  paymentGateway?: InputMaybe<EnumPaymentGatewayWithAggregatesFilter>;
  price?: InputMaybe<FloatWithAggregatesFilter>;
  updatedAt?: InputMaybe<DateTimeWithAggregatesFilter>;
};

export type MintedNftSumAggregate = {
  __typename?: 'MintedNftSumAggregate';
  price?: Maybe<Scalars['Float']>;
};

export type MintedNftSumOrderByAggregateInput = {
  price?: InputMaybe<SortOrder>;
};

export type MintedNftUpdateInput = {
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  creatorUser?: InputMaybe<UserUpdateOneRequiredWithoutCreatedNftsInput>;
  creatorWalletAddress?: InputMaybe<StringFieldUpdateOperationsInput>;
  currencyCode?: InputMaybe<EnumCurrencyCodeFieldUpdateOperationsInput>;
  holderUser?: InputMaybe<UserUpdateOneRequiredWithoutHoldNftsInput>;
  holderWalletAddress?: InputMaybe<StringFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  isPreSales?: InputMaybe<BoolFieldUpdateOperationsInput>;
  nftCollection?: InputMaybe<NftCollectionUpdateOneRequiredWithoutMintedNftsInput>;
  nftCollectionSnapshot?: InputMaybe<Scalars['JSON']>;
  paymentGateway?: InputMaybe<EnumPaymentGatewayFieldUpdateOperationsInput>;
  price?: InputMaybe<FloatFieldUpdateOperationsInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
};

export type MintedNftUpdateManyMutationInput = {
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  creatorWalletAddress?: InputMaybe<StringFieldUpdateOperationsInput>;
  currencyCode?: InputMaybe<EnumCurrencyCodeFieldUpdateOperationsInput>;
  holderWalletAddress?: InputMaybe<StringFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  isPreSales?: InputMaybe<BoolFieldUpdateOperationsInput>;
  nftCollectionSnapshot?: InputMaybe<Scalars['JSON']>;
  paymentGateway?: InputMaybe<EnumPaymentGatewayFieldUpdateOperationsInput>;
  price?: InputMaybe<FloatFieldUpdateOperationsInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
};

export type MintedNftUpdateManyWithWhereWithoutCreatorUserInput = {
  data: MintedNftUpdateManyMutationInput;
  where: MintedNftScalarWhereInput;
};

export type MintedNftUpdateManyWithWhereWithoutHolderUserInput = {
  data: MintedNftUpdateManyMutationInput;
  where: MintedNftScalarWhereInput;
};

export type MintedNftUpdateManyWithWhereWithoutNftCollectionInput = {
  data: MintedNftUpdateManyMutationInput;
  where: MintedNftScalarWhereInput;
};

export type MintedNftUpdateManyWithoutCreatorUserInput = {
  connect?: InputMaybe<Array<MintedNftWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<MintedNftCreateOrConnectWithoutCreatorUserInput>>;
  create?: InputMaybe<Array<MintedNftCreateWithoutCreatorUserInput>>;
  createMany?: InputMaybe<MintedNftCreateManyCreatorUserInputEnvelope>;
  delete?: InputMaybe<Array<MintedNftWhereUniqueInput>>;
  deleteMany?: InputMaybe<Array<MintedNftScalarWhereInput>>;
  disconnect?: InputMaybe<Array<MintedNftWhereUniqueInput>>;
  set?: InputMaybe<Array<MintedNftWhereUniqueInput>>;
  update?: InputMaybe<Array<MintedNftUpdateWithWhereUniqueWithoutCreatorUserInput>>;
  updateMany?: InputMaybe<Array<MintedNftUpdateManyWithWhereWithoutCreatorUserInput>>;
  upsert?: InputMaybe<Array<MintedNftUpsertWithWhereUniqueWithoutCreatorUserInput>>;
};

export type MintedNftUpdateManyWithoutHolderUserInput = {
  connect?: InputMaybe<Array<MintedNftWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<MintedNftCreateOrConnectWithoutHolderUserInput>>;
  create?: InputMaybe<Array<MintedNftCreateWithoutHolderUserInput>>;
  createMany?: InputMaybe<MintedNftCreateManyHolderUserInputEnvelope>;
  delete?: InputMaybe<Array<MintedNftWhereUniqueInput>>;
  deleteMany?: InputMaybe<Array<MintedNftScalarWhereInput>>;
  disconnect?: InputMaybe<Array<MintedNftWhereUniqueInput>>;
  set?: InputMaybe<Array<MintedNftWhereUniqueInput>>;
  update?: InputMaybe<Array<MintedNftUpdateWithWhereUniqueWithoutHolderUserInput>>;
  updateMany?: InputMaybe<Array<MintedNftUpdateManyWithWhereWithoutHolderUserInput>>;
  upsert?: InputMaybe<Array<MintedNftUpsertWithWhereUniqueWithoutHolderUserInput>>;
};

export type MintedNftUpdateManyWithoutNftCollectionInput = {
  connect?: InputMaybe<Array<MintedNftWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<MintedNftCreateOrConnectWithoutNftCollectionInput>>;
  create?: InputMaybe<Array<MintedNftCreateWithoutNftCollectionInput>>;
  createMany?: InputMaybe<MintedNftCreateManyNftCollectionInputEnvelope>;
  delete?: InputMaybe<Array<MintedNftWhereUniqueInput>>;
  deleteMany?: InputMaybe<Array<MintedNftScalarWhereInput>>;
  disconnect?: InputMaybe<Array<MintedNftWhereUniqueInput>>;
  set?: InputMaybe<Array<MintedNftWhereUniqueInput>>;
  update?: InputMaybe<Array<MintedNftUpdateWithWhereUniqueWithoutNftCollectionInput>>;
  updateMany?: InputMaybe<Array<MintedNftUpdateManyWithWhereWithoutNftCollectionInput>>;
  upsert?: InputMaybe<Array<MintedNftUpsertWithWhereUniqueWithoutNftCollectionInput>>;
};

export type MintedNftUpdateWithWhereUniqueWithoutCreatorUserInput = {
  data: MintedNftUpdateWithoutCreatorUserInput;
  where: MintedNftWhereUniqueInput;
};

export type MintedNftUpdateWithWhereUniqueWithoutHolderUserInput = {
  data: MintedNftUpdateWithoutHolderUserInput;
  where: MintedNftWhereUniqueInput;
};

export type MintedNftUpdateWithWhereUniqueWithoutNftCollectionInput = {
  data: MintedNftUpdateWithoutNftCollectionInput;
  where: MintedNftWhereUniqueInput;
};

export type MintedNftUpdateWithoutCreatorUserInput = {
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  creatorWalletAddress?: InputMaybe<StringFieldUpdateOperationsInput>;
  currencyCode?: InputMaybe<EnumCurrencyCodeFieldUpdateOperationsInput>;
  holderUser?: InputMaybe<UserUpdateOneRequiredWithoutHoldNftsInput>;
  holderWalletAddress?: InputMaybe<StringFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  isPreSales?: InputMaybe<BoolFieldUpdateOperationsInput>;
  nftCollection?: InputMaybe<NftCollectionUpdateOneRequiredWithoutMintedNftsInput>;
  nftCollectionSnapshot?: InputMaybe<Scalars['JSON']>;
  paymentGateway?: InputMaybe<EnumPaymentGatewayFieldUpdateOperationsInput>;
  price?: InputMaybe<FloatFieldUpdateOperationsInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
};

export type MintedNftUpdateWithoutHolderUserInput = {
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  creatorUser?: InputMaybe<UserUpdateOneRequiredWithoutCreatedNftsInput>;
  creatorWalletAddress?: InputMaybe<StringFieldUpdateOperationsInput>;
  currencyCode?: InputMaybe<EnumCurrencyCodeFieldUpdateOperationsInput>;
  holderWalletAddress?: InputMaybe<StringFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  isPreSales?: InputMaybe<BoolFieldUpdateOperationsInput>;
  nftCollection?: InputMaybe<NftCollectionUpdateOneRequiredWithoutMintedNftsInput>;
  nftCollectionSnapshot?: InputMaybe<Scalars['JSON']>;
  paymentGateway?: InputMaybe<EnumPaymentGatewayFieldUpdateOperationsInput>;
  price?: InputMaybe<FloatFieldUpdateOperationsInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
};

export type MintedNftUpdateWithoutNftCollectionInput = {
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  creatorUser?: InputMaybe<UserUpdateOneRequiredWithoutCreatedNftsInput>;
  creatorWalletAddress?: InputMaybe<StringFieldUpdateOperationsInput>;
  currencyCode?: InputMaybe<EnumCurrencyCodeFieldUpdateOperationsInput>;
  holderUser?: InputMaybe<UserUpdateOneRequiredWithoutHoldNftsInput>;
  holderWalletAddress?: InputMaybe<StringFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  isPreSales?: InputMaybe<BoolFieldUpdateOperationsInput>;
  nftCollectionSnapshot?: InputMaybe<Scalars['JSON']>;
  paymentGateway?: InputMaybe<EnumPaymentGatewayFieldUpdateOperationsInput>;
  price?: InputMaybe<FloatFieldUpdateOperationsInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
};

export type MintedNftUpsertWithWhereUniqueWithoutCreatorUserInput = {
  create: MintedNftCreateWithoutCreatorUserInput;
  update: MintedNftUpdateWithoutCreatorUserInput;
  where: MintedNftWhereUniqueInput;
};

export type MintedNftUpsertWithWhereUniqueWithoutHolderUserInput = {
  create: MintedNftCreateWithoutHolderUserInput;
  update: MintedNftUpdateWithoutHolderUserInput;
  where: MintedNftWhereUniqueInput;
};

export type MintedNftUpsertWithWhereUniqueWithoutNftCollectionInput = {
  create: MintedNftCreateWithoutNftCollectionInput;
  update: MintedNftUpdateWithoutNftCollectionInput;
  where: MintedNftWhereUniqueInput;
};

export type MintedNftWhereInput = {
  AND?: InputMaybe<Array<MintedNftWhereInput>>;
  NOT?: InputMaybe<Array<MintedNftWhereInput>>;
  OR?: InputMaybe<Array<MintedNftWhereInput>>;
  createdAt?: InputMaybe<DateTimeFilter>;
  creatorUser?: InputMaybe<UserRelationFilter>;
  creatorUserId?: InputMaybe<StringFilter>;
  creatorWalletAddress?: InputMaybe<StringFilter>;
  currencyCode?: InputMaybe<EnumCurrencyCodeFilter>;
  holderUser?: InputMaybe<UserRelationFilter>;
  holderUserId?: InputMaybe<StringFilter>;
  holderWalletAddress?: InputMaybe<StringFilter>;
  id?: InputMaybe<StringFilter>;
  isPreSales?: InputMaybe<BoolFilter>;
  nftCollection?: InputMaybe<NftCollectionRelationFilter>;
  nftCollectionId?: InputMaybe<StringFilter>;
  nftCollectionSnapshot?: InputMaybe<JsonFilter>;
  paymentGateway?: InputMaybe<EnumPaymentGatewayFilter>;
  price?: InputMaybe<FloatFilter>;
  updatedAt?: InputMaybe<DateTimeFilter>;
};

export type MintedNftWhereUniqueInput = {
  id?: InputMaybe<Scalars['String']>;
};

export type Mutation = {
  __typename?: 'Mutation';
  adminLogin: Auth;
  adminRequestOtp: RequestOtpResponse;
  adminResetPassword: ResetPasswordResponse;
  changeMyPassword: ChangeMyPasswordResponse;
  commentOnVideo: Comment;
  createAdminUser: AdminUser;
  createChannel: Channel;
  createChannelFollower: ChannelFollower;
  createComment: Comment;
  createGift: Gift;
  createGiftDistribution: GiftDistribution;
  createInquiry: Inquiry;
  createLikedVideo: LikedVideo;
  createManyAdminUser: AffectedRowsOutput;
  createManyChannel: AffectedRowsOutput;
  createManyChannelFollower: AffectedRowsOutput;
  createManyComment: AffectedRowsOutput;
  createManyGift: AffectedRowsOutput;
  createManyGiftDistribution: AffectedRowsOutput;
  createManyInquiry: AffectedRowsOutput;
  createManyLikedVideo: AffectedRowsOutput;
  createManyMintedNft: AffectedRowsOutput;
  createManyNftCollection: AffectedRowsOutput;
  createManyPaymentWebhook: AffectedRowsOutput;
  createManyPlaylist: AffectedRowsOutput;
  createManyPlaylistVideo: AffectedRowsOutput;
  createManySubscriptionPlan: AffectedRowsOutput;
  createManyTips: AffectedRowsOutput;
  createManyTransaction: AffectedRowsOutput;
  createManyUser: AffectedRowsOutput;
  createManyUserPayPerView: AffectedRowsOutput;
  createManyUserSubscription: AffectedRowsOutput;
  createManyVideo: AffectedRowsOutput;
  createManyWatchHistory: AffectedRowsOutput;
  createMintedNft: MintedNft;
  createMyGift: Gift;
  createMyPlaylist?: Maybe<Playlist>;
  createNftCollection: NftCollection;
  createPayment: CreatePaymentResult;
  createPaymentWebhook: PaymentWebhook;
  createPlaylist: Playlist;
  createPlaylistVideo: PlaylistVideo;
  createSubscriptionPlan: SubscriptionPlan;
  createTips: Tips;
  createTransaction: Transaction;
  createUser: User;
  createUserPayPerView: UserPayPerView;
  createUserSubscription: UserSubscription;
  createVideo: Video;
  createWatchHistory: WatchHistory;
  deleteAdminUser?: Maybe<AdminUser>;
  deleteChannel?: Maybe<Channel>;
  deleteChannelFollower?: Maybe<ChannelFollower>;
  deleteComment?: Maybe<Comment>;
  deleteGift?: Maybe<Gift>;
  deleteGiftDistribution?: Maybe<GiftDistribution>;
  deleteInquiry?: Maybe<Inquiry>;
  deleteLikedVideo?: Maybe<LikedVideo>;
  deleteManyAdminUser: AffectedRowsOutput;
  deleteManyChannel: AffectedRowsOutput;
  deleteManyChannelFollower: AffectedRowsOutput;
  deleteManyComment: AffectedRowsOutput;
  deleteManyGift: AffectedRowsOutput;
  deleteManyGiftDistribution: AffectedRowsOutput;
  deleteManyInquiry: AffectedRowsOutput;
  deleteManyLikedVideo: AffectedRowsOutput;
  deleteManyMintedNft: AffectedRowsOutput;
  deleteManyNftCollection: AffectedRowsOutput;
  deleteManyPaymentWebhook: AffectedRowsOutput;
  deleteManyPlaylist: AffectedRowsOutput;
  deleteManyPlaylistVideo: AffectedRowsOutput;
  deleteManySubscriptionPlan: AffectedRowsOutput;
  deleteManyTips: AffectedRowsOutput;
  deleteManyTransaction: AffectedRowsOutput;
  deleteManyUser: AffectedRowsOutput;
  deleteManyUserPayPerView: AffectedRowsOutput;
  deleteManyUserSubscription: AffectedRowsOutput;
  deleteManyVideo: AffectedRowsOutput;
  deleteManyWatchHistory: AffectedRowsOutput;
  deleteMintedNft?: Maybe<MintedNft>;
  deleteMyPlaylists?: Maybe<Array<Playlist>>;
  deleteNftCollection?: Maybe<NftCollection>;
  deletePaymentWebhook?: Maybe<PaymentWebhook>;
  deletePlaylist?: Maybe<Playlist>;
  deletePlaylistVideo?: Maybe<PlaylistVideo>;
  deleteSubscriptionPlan?: Maybe<SubscriptionPlan>;
  deleteTips?: Maybe<Tips>;
  deleteTransaction?: Maybe<Transaction>;
  deleteUser?: Maybe<User>;
  deleteUserPayPerView?: Maybe<UserPayPerView>;
  deleteUserSubscription?: Maybe<UserSubscription>;
  deleteVideo?: Maybe<Video>;
  deleteWatchHistory?: Maybe<WatchHistory>;
  followChannel: Scalars['Boolean'];
  generatePresignedFile: SignedFile;
  likeAVideo: LikedVideo;
  login: Auth;
  prestoSSOLogin: AuthPrestoUserProfile;
  refreshToken: Auth;
  register: Auth;
  requestOtp: RequestOtpResponse;
  resetPassword: ResetPasswordResponse;
  submitInquiry: Inquiry;
  unfollowChannel: Scalars['Boolean'];
  updateAdminUser?: Maybe<AdminUser>;
  updateChannel?: Maybe<Channel>;
  updateChannelFollower?: Maybe<ChannelFollower>;
  updateComment?: Maybe<Comment>;
  updateGift?: Maybe<Gift>;
  updateGiftDistribution?: Maybe<GiftDistribution>;
  updateInquiry?: Maybe<Inquiry>;
  updateLikedVideo?: Maybe<LikedVideo>;
  updateManyAdminUser: AffectedRowsOutput;
  updateManyChannel: AffectedRowsOutput;
  updateManyChannelFollower: AffectedRowsOutput;
  updateManyComment: AffectedRowsOutput;
  updateManyGift: AffectedRowsOutput;
  updateManyGiftDistribution: AffectedRowsOutput;
  updateManyInquiry: AffectedRowsOutput;
  updateManyLikedVideo: AffectedRowsOutput;
  updateManyMintedNft: AffectedRowsOutput;
  updateManyNftCollection: AffectedRowsOutput;
  updateManyPaymentWebhook: AffectedRowsOutput;
  updateManyPlaylist: AffectedRowsOutput;
  updateManyPlaylistVideo: AffectedRowsOutput;
  updateManySubscriptionPlan: AffectedRowsOutput;
  updateManyTips: AffectedRowsOutput;
  updateManyTransaction: AffectedRowsOutput;
  updateManyUser: AffectedRowsOutput;
  updateManyUserPayPerView: AffectedRowsOutput;
  updateManyUserSubscription: AffectedRowsOutput;
  updateManyVideo: AffectedRowsOutput;
  updateManyWatchHistory: AffectedRowsOutput;
  updateMintedNft?: Maybe<MintedNft>;
  updateMyAccount: UpdateMyAccountResponse;
  updateMyChannel: Channel;
  updateMyPersonalInfo: UpdateMyPersonalInfoResponse;
  updateNftCollection?: Maybe<NftCollection>;
  updatePaymentWebhook?: Maybe<PaymentWebhook>;
  updatePlaylist?: Maybe<Playlist>;
  updatePlaylistVideo?: Maybe<PlaylistVideo>;
  updateSubscriptionPlan?: Maybe<SubscriptionPlan>;
  updateTips?: Maybe<Tips>;
  updateTransaction?: Maybe<Transaction>;
  updateUser?: Maybe<User>;
  updateUserPayPerView?: Maybe<UserPayPerView>;
  updateUserSubscription?: Maybe<UserSubscription>;
  updateVideo: Video;
  updateWatchHistory?: Maybe<WatchHistory>;
  uploadVideo: UploadVideoResult;
  upsertAdminUser: AdminUser;
  upsertChannel: Channel;
  upsertChannelFollower: ChannelFollower;
  upsertComment: Comment;
  upsertGift: Gift;
  upsertGiftDistribution: GiftDistribution;
  upsertInquiry: Inquiry;
  upsertLikedVideo: LikedVideo;
  upsertMintedNft: MintedNft;
  upsertNftCollection: NftCollection;
  upsertPaymentWebhook: PaymentWebhook;
  upsertPlaylist: Playlist;
  upsertPlaylistVideo: PlaylistVideo;
  upsertSubscriptionPlan: SubscriptionPlan;
  upsertTips: Tips;
  upsertTransaction: Transaction;
  upsertUser: User;
  upsertUserPayPerView: UserPayPerView;
  upsertUserSubscription: UserSubscription;
  upsertVideo: Video;
  upsertWatchHistory: WatchHistory;
  verifyOtp: VerifyOtpResponse;
  watchAVideo: WatchHistory;
};


export type MutationAdminLoginArgs = {
  data: AdminLoginInput;
};


export type MutationAdminRequestOtpArgs = {
  data: RequestOtpInput;
};


export type MutationAdminResetPasswordArgs = {
  data: ResetPasswordInput;
};


export type MutationChangeMyPasswordArgs = {
  data: ChangeMyPasswordInput;
};


export type MutationCommentOnVideoArgs = {
  content: Scalars['String'];
  videoId: Scalars['String'];
};


export type MutationCreateAdminUserArgs = {
  data: AdminUserCreateInput;
};


export type MutationCreateChannelArgs = {
  data: ChannelCreateInput;
};


export type MutationCreateChannelFollowerArgs = {
  data: ChannelFollowerCreateInput;
};


export type MutationCreateCommentArgs = {
  data: CommentCreateInput;
};


export type MutationCreateGiftArgs = {
  data: GiftCreateInput;
};


export type MutationCreateGiftDistributionArgs = {
  data: GiftDistributionCreateInput;
};


export type MutationCreateInquiryArgs = {
  data: InquiryCreateInput;
};


export type MutationCreateLikedVideoArgs = {
  data: LikedVideoCreateInput;
};


export type MutationCreateManyAdminUserArgs = {
  data: Array<AdminUserCreateManyInput>;
  skipDuplicates?: InputMaybe<Scalars['Boolean']>;
};


export type MutationCreateManyChannelArgs = {
  data: Array<ChannelCreateManyInput>;
  skipDuplicates?: InputMaybe<Scalars['Boolean']>;
};


export type MutationCreateManyChannelFollowerArgs = {
  data: Array<ChannelFollowerCreateManyInput>;
  skipDuplicates?: InputMaybe<Scalars['Boolean']>;
};


export type MutationCreateManyCommentArgs = {
  data: Array<CommentCreateManyInput>;
  skipDuplicates?: InputMaybe<Scalars['Boolean']>;
};


export type MutationCreateManyGiftArgs = {
  data: Array<GiftCreateManyInput>;
  skipDuplicates?: InputMaybe<Scalars['Boolean']>;
};


export type MutationCreateManyGiftDistributionArgs = {
  data: Array<GiftDistributionCreateManyInput>;
  skipDuplicates?: InputMaybe<Scalars['Boolean']>;
};


export type MutationCreateManyInquiryArgs = {
  data: Array<InquiryCreateManyInput>;
  skipDuplicates?: InputMaybe<Scalars['Boolean']>;
};


export type MutationCreateManyLikedVideoArgs = {
  data: Array<LikedVideoCreateManyInput>;
  skipDuplicates?: InputMaybe<Scalars['Boolean']>;
};


export type MutationCreateManyMintedNftArgs = {
  data: Array<MintedNftCreateManyInput>;
  skipDuplicates?: InputMaybe<Scalars['Boolean']>;
};


export type MutationCreateManyNftCollectionArgs = {
  data: Array<NftCollectionCreateManyInput>;
  skipDuplicates?: InputMaybe<Scalars['Boolean']>;
};


export type MutationCreateManyPaymentWebhookArgs = {
  data: Array<PaymentWebhookCreateManyInput>;
  skipDuplicates?: InputMaybe<Scalars['Boolean']>;
};


export type MutationCreateManyPlaylistArgs = {
  data: Array<PlaylistCreateManyInput>;
  skipDuplicates?: InputMaybe<Scalars['Boolean']>;
};


export type MutationCreateManyPlaylistVideoArgs = {
  data: Array<PlaylistVideoCreateManyInput>;
  skipDuplicates?: InputMaybe<Scalars['Boolean']>;
};


export type MutationCreateManySubscriptionPlanArgs = {
  data: Array<SubscriptionPlanCreateManyInput>;
  skipDuplicates?: InputMaybe<Scalars['Boolean']>;
};


export type MutationCreateManyTipsArgs = {
  data: Array<TipsCreateManyInput>;
  skipDuplicates?: InputMaybe<Scalars['Boolean']>;
};


export type MutationCreateManyTransactionArgs = {
  data: Array<TransactionCreateManyInput>;
  skipDuplicates?: InputMaybe<Scalars['Boolean']>;
};


export type MutationCreateManyUserArgs = {
  data: Array<UserCreateManyInput>;
  skipDuplicates?: InputMaybe<Scalars['Boolean']>;
};


export type MutationCreateManyUserPayPerViewArgs = {
  data: Array<UserPayPerViewCreateManyInput>;
  skipDuplicates?: InputMaybe<Scalars['Boolean']>;
};


export type MutationCreateManyUserSubscriptionArgs = {
  data: Array<UserSubscriptionCreateManyInput>;
  skipDuplicates?: InputMaybe<Scalars['Boolean']>;
};


export type MutationCreateManyVideoArgs = {
  data: Array<VideoCreateManyInput>;
  skipDuplicates?: InputMaybe<Scalars['Boolean']>;
};


export type MutationCreateManyWatchHistoryArgs = {
  data: Array<WatchHistoryCreateManyInput>;
  skipDuplicates?: InputMaybe<Scalars['Boolean']>;
};


export type MutationCreateMintedNftArgs = {
  data: MintedNftCreateInput;
};


export type MutationCreateMyGiftArgs = {
  data: CreateMyGiftInput;
};


export type MutationCreateMyPlaylistArgs = {
  data: MyPlaylistCreateInput;
};


export type MutationCreateNftCollectionArgs = {
  data: NftCollectionCreateInput;
};


export type MutationCreatePaymentArgs = {
  data: CreatePaymentInput;
};


export type MutationCreatePaymentWebhookArgs = {
  data: PaymentWebhookCreateInput;
};


export type MutationCreatePlaylistArgs = {
  data: PlaylistCreateInput;
};


export type MutationCreatePlaylistVideoArgs = {
  data: PlaylistVideoCreateInput;
};


export type MutationCreateSubscriptionPlanArgs = {
  data: SubscriptionPlanCreateInput;
};


export type MutationCreateTipsArgs = {
  data: TipsCreateInput;
};


export type MutationCreateTransactionArgs = {
  data: TransactionCreateInput;
};


export type MutationCreateUserArgs = {
  data: UserCreateInput;
};


export type MutationCreateUserPayPerViewArgs = {
  data: UserPayPerViewCreateInput;
};


export type MutationCreateUserSubscriptionArgs = {
  data: UserSubscriptionCreateInput;
};


export type MutationCreateVideoArgs = {
  data: VideoCreateInput;
};


export type MutationCreateWatchHistoryArgs = {
  data: WatchHistoryCreateInput;
};


export type MutationDeleteAdminUserArgs = {
  where: AdminUserWhereUniqueInput;
};


export type MutationDeleteChannelArgs = {
  where: ChannelWhereUniqueInput;
};


export type MutationDeleteChannelFollowerArgs = {
  where: ChannelFollowerWhereUniqueInput;
};


export type MutationDeleteCommentArgs = {
  where: CommentWhereUniqueInput;
};


export type MutationDeleteGiftArgs = {
  where: GiftWhereUniqueInput;
};


export type MutationDeleteGiftDistributionArgs = {
  where: GiftDistributionWhereUniqueInput;
};


export type MutationDeleteInquiryArgs = {
  where: InquiryWhereUniqueInput;
};


export type MutationDeleteLikedVideoArgs = {
  where: LikedVideoWhereUniqueInput;
};


export type MutationDeleteManyAdminUserArgs = {
  where?: InputMaybe<AdminUserWhereInput>;
};


export type MutationDeleteManyChannelArgs = {
  where?: InputMaybe<ChannelWhereInput>;
};


export type MutationDeleteManyChannelFollowerArgs = {
  where?: InputMaybe<ChannelFollowerWhereInput>;
};


export type MutationDeleteManyCommentArgs = {
  where?: InputMaybe<CommentWhereInput>;
};


export type MutationDeleteManyGiftArgs = {
  where?: InputMaybe<GiftWhereInput>;
};


export type MutationDeleteManyGiftDistributionArgs = {
  where?: InputMaybe<GiftDistributionWhereInput>;
};


export type MutationDeleteManyInquiryArgs = {
  where?: InputMaybe<InquiryWhereInput>;
};


export type MutationDeleteManyLikedVideoArgs = {
  where?: InputMaybe<LikedVideoWhereInput>;
};


export type MutationDeleteManyMintedNftArgs = {
  where?: InputMaybe<MintedNftWhereInput>;
};


export type MutationDeleteManyNftCollectionArgs = {
  where?: InputMaybe<NftCollectionWhereInput>;
};


export type MutationDeleteManyPaymentWebhookArgs = {
  where?: InputMaybe<PaymentWebhookWhereInput>;
};


export type MutationDeleteManyPlaylistArgs = {
  where?: InputMaybe<PlaylistWhereInput>;
};


export type MutationDeleteManyPlaylistVideoArgs = {
  where?: InputMaybe<PlaylistVideoWhereInput>;
};


export type MutationDeleteManySubscriptionPlanArgs = {
  where?: InputMaybe<SubscriptionPlanWhereInput>;
};


export type MutationDeleteManyTipsArgs = {
  where?: InputMaybe<TipsWhereInput>;
};


export type MutationDeleteManyTransactionArgs = {
  where?: InputMaybe<TransactionWhereInput>;
};


export type MutationDeleteManyUserArgs = {
  where?: InputMaybe<UserWhereInput>;
};


export type MutationDeleteManyUserPayPerViewArgs = {
  where?: InputMaybe<UserPayPerViewWhereInput>;
};


export type MutationDeleteManyUserSubscriptionArgs = {
  where?: InputMaybe<UserSubscriptionWhereInput>;
};


export type MutationDeleteManyVideoArgs = {
  where?: InputMaybe<VideoWhereInput>;
};


export type MutationDeleteManyWatchHistoryArgs = {
  where?: InputMaybe<WatchHistoryWhereInput>;
};


export type MutationDeleteMintedNftArgs = {
  where: MintedNftWhereUniqueInput;
};


export type MutationDeleteMyPlaylistsArgs = {
  where?: InputMaybe<DeleteMyPlaylistInput>;
};


export type MutationDeleteNftCollectionArgs = {
  where: NftCollectionWhereUniqueInput;
};


export type MutationDeletePaymentWebhookArgs = {
  where: PaymentWebhookWhereUniqueInput;
};


export type MutationDeletePlaylistArgs = {
  where: PlaylistWhereUniqueInput;
};


export type MutationDeletePlaylistVideoArgs = {
  where: PlaylistVideoWhereUniqueInput;
};


export type MutationDeleteSubscriptionPlanArgs = {
  where: SubscriptionPlanWhereUniqueInput;
};


export type MutationDeleteTipsArgs = {
  where: TipsWhereUniqueInput;
};


export type MutationDeleteTransactionArgs = {
  where: TransactionWhereUniqueInput;
};


export type MutationDeleteUserArgs = {
  where: UserWhereUniqueInput;
};


export type MutationDeleteUserPayPerViewArgs = {
  where: UserPayPerViewWhereUniqueInput;
};


export type MutationDeleteUserSubscriptionArgs = {
  where: UserSubscriptionWhereUniqueInput;
};


export type MutationDeleteVideoArgs = {
  where: VideoWhereUniqueInput;
};


export type MutationDeleteWatchHistoryArgs = {
  where: WatchHistoryWhereUniqueInput;
};


export type MutationFollowChannelArgs = {
  channelName: Scalars['String'];
};


export type MutationGeneratePresignedFileArgs = {
  data: GeneratePresignedFileInput;
};


export type MutationLikeAVideoArgs = {
  active: Scalars['Boolean'];
  videoId: Scalars['String'];
};


export type MutationLoginArgs = {
  data: LoginInput;
};


export type MutationPrestoSsoLoginArgs = {
  code: Scalars['String'];
};


export type MutationRefreshTokenArgs = {
  token: Scalars['String'];
};


export type MutationRegisterArgs = {
  data: RegisterInput;
};


export type MutationRequestOtpArgs = {
  data: RequestOtpInput;
};


export type MutationResetPasswordArgs = {
  data: ResetPasswordInput;
};


export type MutationSubmitInquiryArgs = {
  data: SubmitInquiryInput;
};


export type MutationUnfollowChannelArgs = {
  channelName: Scalars['String'];
};


export type MutationUpdateAdminUserArgs = {
  data: AdminUserUpdateInput;
  where: AdminUserWhereUniqueInput;
};


export type MutationUpdateChannelArgs = {
  data: ChannelUpdateInput;
  where: ChannelWhereUniqueInput;
};


export type MutationUpdateChannelFollowerArgs = {
  data: ChannelFollowerUpdateInput;
  where: ChannelFollowerWhereUniqueInput;
};


export type MutationUpdateCommentArgs = {
  data: CommentUpdateInput;
  where: CommentWhereUniqueInput;
};


export type MutationUpdateGiftArgs = {
  data: GiftUpdateInput;
  where: GiftWhereUniqueInput;
};


export type MutationUpdateGiftDistributionArgs = {
  data: GiftDistributionUpdateInput;
  where: GiftDistributionWhereUniqueInput;
};


export type MutationUpdateInquiryArgs = {
  data: InquiryUpdateInput;
  where: InquiryWhereUniqueInput;
};


export type MutationUpdateLikedVideoArgs = {
  data: LikedVideoUpdateInput;
  where: LikedVideoWhereUniqueInput;
};


export type MutationUpdateManyAdminUserArgs = {
  data: AdminUserUpdateManyMutationInput;
  where?: InputMaybe<AdminUserWhereInput>;
};


export type MutationUpdateManyChannelArgs = {
  data: ChannelUpdateManyMutationInput;
  where?: InputMaybe<ChannelWhereInput>;
};


export type MutationUpdateManyChannelFollowerArgs = {
  data: ChannelFollowerUpdateManyMutationInput;
  where?: InputMaybe<ChannelFollowerWhereInput>;
};


export type MutationUpdateManyCommentArgs = {
  data: CommentUpdateManyMutationInput;
  where?: InputMaybe<CommentWhereInput>;
};


export type MutationUpdateManyGiftArgs = {
  data: GiftUpdateManyMutationInput;
  where?: InputMaybe<GiftWhereInput>;
};


export type MutationUpdateManyGiftDistributionArgs = {
  data: GiftDistributionUpdateManyMutationInput;
  where?: InputMaybe<GiftDistributionWhereInput>;
};


export type MutationUpdateManyInquiryArgs = {
  data: InquiryUpdateManyMutationInput;
  where?: InputMaybe<InquiryWhereInput>;
};


export type MutationUpdateManyLikedVideoArgs = {
  data: LikedVideoUpdateManyMutationInput;
  where?: InputMaybe<LikedVideoWhereInput>;
};


export type MutationUpdateManyMintedNftArgs = {
  data: MintedNftUpdateManyMutationInput;
  where?: InputMaybe<MintedNftWhereInput>;
};


export type MutationUpdateManyNftCollectionArgs = {
  data: NftCollectionUpdateManyMutationInput;
  where?: InputMaybe<NftCollectionWhereInput>;
};


export type MutationUpdateManyPaymentWebhookArgs = {
  data: PaymentWebhookUpdateManyMutationInput;
  where?: InputMaybe<PaymentWebhookWhereInput>;
};


export type MutationUpdateManyPlaylistArgs = {
  data: PlaylistUpdateManyMutationInput;
  where?: InputMaybe<PlaylistWhereInput>;
};


export type MutationUpdateManyPlaylistVideoArgs = {
  data: PlaylistVideoUpdateManyMutationInput;
  where?: InputMaybe<PlaylistVideoWhereInput>;
};


export type MutationUpdateManySubscriptionPlanArgs = {
  data: SubscriptionPlanUpdateManyMutationInput;
  where?: InputMaybe<SubscriptionPlanWhereInput>;
};


export type MutationUpdateManyTipsArgs = {
  data: TipsUpdateManyMutationInput;
  where?: InputMaybe<TipsWhereInput>;
};


export type MutationUpdateManyTransactionArgs = {
  data: TransactionUpdateManyMutationInput;
  where?: InputMaybe<TransactionWhereInput>;
};


export type MutationUpdateManyUserArgs = {
  data: UserUpdateManyMutationInput;
  where?: InputMaybe<UserWhereInput>;
};


export type MutationUpdateManyUserPayPerViewArgs = {
  data: UserPayPerViewUpdateManyMutationInput;
  where?: InputMaybe<UserPayPerViewWhereInput>;
};


export type MutationUpdateManyUserSubscriptionArgs = {
  data: UserSubscriptionUpdateManyMutationInput;
  where?: InputMaybe<UserSubscriptionWhereInput>;
};


export type MutationUpdateManyVideoArgs = {
  data: VideoUpdateManyMutationInput;
  where?: InputMaybe<VideoWhereInput>;
};


export type MutationUpdateManyWatchHistoryArgs = {
  data: WatchHistoryUpdateManyMutationInput;
  where?: InputMaybe<WatchHistoryWhereInput>;
};


export type MutationUpdateMintedNftArgs = {
  data: MintedNftUpdateInput;
  where: MintedNftWhereUniqueInput;
};


export type MutationUpdateMyAccountArgs = {
  data: UpdateMyAccountInput;
};


export type MutationUpdateMyChannelArgs = {
  data: ChannelUpdateInput;
};


export type MutationUpdateMyPersonalInfoArgs = {
  data: UpdateMyPersonalInfoInput;
};


export type MutationUpdateNftCollectionArgs = {
  data: NftCollectionUpdateInput;
  where: NftCollectionWhereUniqueInput;
};


export type MutationUpdatePaymentWebhookArgs = {
  data: PaymentWebhookUpdateInput;
  where: PaymentWebhookWhereUniqueInput;
};


export type MutationUpdatePlaylistArgs = {
  data: PlaylistUpdateInput;
  where: PlaylistWhereUniqueInput;
};


export type MutationUpdatePlaylistVideoArgs = {
  data: PlaylistVideoUpdateInput;
  where: PlaylistVideoWhereUniqueInput;
};


export type MutationUpdateSubscriptionPlanArgs = {
  data: SubscriptionPlanUpdateInput;
  where: SubscriptionPlanWhereUniqueInput;
};


export type MutationUpdateTipsArgs = {
  data: TipsUpdateInput;
  where: TipsWhereUniqueInput;
};


export type MutationUpdateTransactionArgs = {
  data: TransactionUpdateInput;
  where: TransactionWhereUniqueInput;
};


export type MutationUpdateUserArgs = {
  data: UserUpdateInput;
  where: UserWhereUniqueInput;
};


export type MutationUpdateUserPayPerViewArgs = {
  data: UserPayPerViewUpdateInput;
  where: UserPayPerViewWhereUniqueInput;
};


export type MutationUpdateUserSubscriptionArgs = {
  data: UserSubscriptionUpdateInput;
  where: UserSubscriptionWhereUniqueInput;
};


export type MutationUpdateVideoArgs = {
  data: VideoUpdateInput;
  where: VideoWhereUniqueInput;
};


export type MutationUpdateWatchHistoryArgs = {
  data: WatchHistoryUpdateInput;
  where: WatchHistoryWhereUniqueInput;
};


export type MutationUploadVideoArgs = {
  data: UploadVideoInput;
};


export type MutationUpsertAdminUserArgs = {
  create: AdminUserCreateInput;
  update: AdminUserUpdateInput;
  where: AdminUserWhereUniqueInput;
};


export type MutationUpsertChannelArgs = {
  create: ChannelCreateInput;
  update: ChannelUpdateInput;
  where: ChannelWhereUniqueInput;
};


export type MutationUpsertChannelFollowerArgs = {
  create: ChannelFollowerCreateInput;
  update: ChannelFollowerUpdateInput;
  where: ChannelFollowerWhereUniqueInput;
};


export type MutationUpsertCommentArgs = {
  create: CommentCreateInput;
  update: CommentUpdateInput;
  where: CommentWhereUniqueInput;
};


export type MutationUpsertGiftArgs = {
  create: GiftCreateInput;
  update: GiftUpdateInput;
  where: GiftWhereUniqueInput;
};


export type MutationUpsertGiftDistributionArgs = {
  create: GiftDistributionCreateInput;
  update: GiftDistributionUpdateInput;
  where: GiftDistributionWhereUniqueInput;
};


export type MutationUpsertInquiryArgs = {
  create: InquiryCreateInput;
  update: InquiryUpdateInput;
  where: InquiryWhereUniqueInput;
};


export type MutationUpsertLikedVideoArgs = {
  create: LikedVideoCreateInput;
  update: LikedVideoUpdateInput;
  where: LikedVideoWhereUniqueInput;
};


export type MutationUpsertMintedNftArgs = {
  create: MintedNftCreateInput;
  update: MintedNftUpdateInput;
  where: MintedNftWhereUniqueInput;
};


export type MutationUpsertNftCollectionArgs = {
  create: NftCollectionCreateInput;
  update: NftCollectionUpdateInput;
  where: NftCollectionWhereUniqueInput;
};


export type MutationUpsertPaymentWebhookArgs = {
  create: PaymentWebhookCreateInput;
  update: PaymentWebhookUpdateInput;
  where: PaymentWebhookWhereUniqueInput;
};


export type MutationUpsertPlaylistArgs = {
  create: PlaylistCreateInput;
  update: PlaylistUpdateInput;
  where: PlaylistWhereUniqueInput;
};


export type MutationUpsertPlaylistVideoArgs = {
  create: PlaylistVideoCreateInput;
  update: PlaylistVideoUpdateInput;
  where: PlaylistVideoWhereUniqueInput;
};


export type MutationUpsertSubscriptionPlanArgs = {
  create: SubscriptionPlanCreateInput;
  update: SubscriptionPlanUpdateInput;
  where: SubscriptionPlanWhereUniqueInput;
};


export type MutationUpsertTipsArgs = {
  create: TipsCreateInput;
  update: TipsUpdateInput;
  where: TipsWhereUniqueInput;
};


export type MutationUpsertTransactionArgs = {
  create: TransactionCreateInput;
  update: TransactionUpdateInput;
  where: TransactionWhereUniqueInput;
};


export type MutationUpsertUserArgs = {
  create: UserCreateInput;
  update: UserUpdateInput;
  where: UserWhereUniqueInput;
};


export type MutationUpsertUserPayPerViewArgs = {
  create: UserPayPerViewCreateInput;
  update: UserPayPerViewUpdateInput;
  where: UserPayPerViewWhereUniqueInput;
};


export type MutationUpsertUserSubscriptionArgs = {
  create: UserSubscriptionCreateInput;
  update: UserSubscriptionUpdateInput;
  where: UserSubscriptionWhereUniqueInput;
};


export type MutationUpsertVideoArgs = {
  create: VideoCreateInput;
  update: VideoUpdateInput;
  where: VideoWhereUniqueInput;
};


export type MutationUpsertWatchHistoryArgs = {
  create: WatchHistoryCreateInput;
  update: WatchHistoryUpdateInput;
  where: WatchHistoryWhereUniqueInput;
};


export type MutationVerifyOtpArgs = {
  data: VerifyOtpInput;
};


export type MutationWatchAVideoArgs = {
  videoId: Scalars['String'];
};

export type MyPlaylistCreateInput = {
  archivedAt?: InputMaybe<Scalars['DateTime']>;
  createdAt?: InputMaybe<Scalars['DateTime']>;
  description?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['String']>;
  name: Scalars['String'];
  playlistVideos?: InputMaybe<PlaylistVideoCreateNestedManyWithoutPlaylistInput>;
  thumbnailUrl?: InputMaybe<Scalars['String']>;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
};

export type NestedBoolFilter = {
  equals?: InputMaybe<Scalars['Boolean']>;
  not?: InputMaybe<NestedBoolFilter>;
};

export type NestedBoolWithAggregatesFilter = {
  _count?: InputMaybe<NestedIntFilter>;
  _max?: InputMaybe<NestedBoolFilter>;
  _min?: InputMaybe<NestedBoolFilter>;
  equals?: InputMaybe<Scalars['Boolean']>;
  not?: InputMaybe<NestedBoolWithAggregatesFilter>;
};

export type NestedDateTimeFilter = {
  equals?: InputMaybe<Scalars['DateTime']>;
  gt?: InputMaybe<Scalars['DateTime']>;
  gte?: InputMaybe<Scalars['DateTime']>;
  in?: InputMaybe<Array<Scalars['DateTime']>>;
  lt?: InputMaybe<Scalars['DateTime']>;
  lte?: InputMaybe<Scalars['DateTime']>;
  not?: InputMaybe<NestedDateTimeFilter>;
  notIn?: InputMaybe<Array<Scalars['DateTime']>>;
};

export type NestedDateTimeNullableFilter = {
  equals?: InputMaybe<Scalars['DateTime']>;
  gt?: InputMaybe<Scalars['DateTime']>;
  gte?: InputMaybe<Scalars['DateTime']>;
  in?: InputMaybe<Array<Scalars['DateTime']>>;
  lt?: InputMaybe<Scalars['DateTime']>;
  lte?: InputMaybe<Scalars['DateTime']>;
  not?: InputMaybe<NestedDateTimeNullableFilter>;
  notIn?: InputMaybe<Array<Scalars['DateTime']>>;
};

export type NestedDateTimeNullableWithAggregatesFilter = {
  _count?: InputMaybe<NestedIntNullableFilter>;
  _max?: InputMaybe<NestedDateTimeNullableFilter>;
  _min?: InputMaybe<NestedDateTimeNullableFilter>;
  equals?: InputMaybe<Scalars['DateTime']>;
  gt?: InputMaybe<Scalars['DateTime']>;
  gte?: InputMaybe<Scalars['DateTime']>;
  in?: InputMaybe<Array<Scalars['DateTime']>>;
  lt?: InputMaybe<Scalars['DateTime']>;
  lte?: InputMaybe<Scalars['DateTime']>;
  not?: InputMaybe<NestedDateTimeNullableWithAggregatesFilter>;
  notIn?: InputMaybe<Array<Scalars['DateTime']>>;
};

export type NestedDateTimeWithAggregatesFilter = {
  _count?: InputMaybe<NestedIntFilter>;
  _max?: InputMaybe<NestedDateTimeFilter>;
  _min?: InputMaybe<NestedDateTimeFilter>;
  equals?: InputMaybe<Scalars['DateTime']>;
  gt?: InputMaybe<Scalars['DateTime']>;
  gte?: InputMaybe<Scalars['DateTime']>;
  in?: InputMaybe<Array<Scalars['DateTime']>>;
  lt?: InputMaybe<Scalars['DateTime']>;
  lte?: InputMaybe<Scalars['DateTime']>;
  not?: InputMaybe<NestedDateTimeWithAggregatesFilter>;
  notIn?: InputMaybe<Array<Scalars['DateTime']>>;
};

export type NestedEnumCommunicationLanguageNullableFilter = {
  equals?: InputMaybe<CommunicationLanguage>;
  in?: InputMaybe<Array<CommunicationLanguage>>;
  not?: InputMaybe<NestedEnumCommunicationLanguageNullableFilter>;
  notIn?: InputMaybe<Array<CommunicationLanguage>>;
};

export type NestedEnumCommunicationLanguageNullableWithAggregatesFilter = {
  _count?: InputMaybe<NestedIntNullableFilter>;
  _max?: InputMaybe<NestedEnumCommunicationLanguageNullableFilter>;
  _min?: InputMaybe<NestedEnumCommunicationLanguageNullableFilter>;
  equals?: InputMaybe<CommunicationLanguage>;
  in?: InputMaybe<Array<CommunicationLanguage>>;
  not?: InputMaybe<NestedEnumCommunicationLanguageNullableWithAggregatesFilter>;
  notIn?: InputMaybe<Array<CommunicationLanguage>>;
};

export type NestedEnumCurrencyCodeFilter = {
  equals?: InputMaybe<CurrencyCode>;
  in?: InputMaybe<Array<CurrencyCode>>;
  not?: InputMaybe<NestedEnumCurrencyCodeFilter>;
  notIn?: InputMaybe<Array<CurrencyCode>>;
};

export type NestedEnumCurrencyCodeWithAggregatesFilter = {
  _count?: InputMaybe<NestedIntFilter>;
  _max?: InputMaybe<NestedEnumCurrencyCodeFilter>;
  _min?: InputMaybe<NestedEnumCurrencyCodeFilter>;
  equals?: InputMaybe<CurrencyCode>;
  in?: InputMaybe<Array<CurrencyCode>>;
  not?: InputMaybe<NestedEnumCurrencyCodeWithAggregatesFilter>;
  notIn?: InputMaybe<Array<CurrencyCode>>;
};

export type NestedEnumInquiryStatusFilter = {
  equals?: InputMaybe<InquiryStatus>;
  in?: InputMaybe<Array<InquiryStatus>>;
  not?: InputMaybe<NestedEnumInquiryStatusFilter>;
  notIn?: InputMaybe<Array<InquiryStatus>>;
};

export type NestedEnumInquiryStatusWithAggregatesFilter = {
  _count?: InputMaybe<NestedIntFilter>;
  _max?: InputMaybe<NestedEnumInquiryStatusFilter>;
  _min?: InputMaybe<NestedEnumInquiryStatusFilter>;
  equals?: InputMaybe<InquiryStatus>;
  in?: InputMaybe<Array<InquiryStatus>>;
  not?: InputMaybe<NestedEnumInquiryStatusWithAggregatesFilter>;
  notIn?: InputMaybe<Array<InquiryStatus>>;
};

export type NestedEnumMintStageFilter = {
  equals?: InputMaybe<MintStage>;
  in?: InputMaybe<Array<MintStage>>;
  not?: InputMaybe<NestedEnumMintStageFilter>;
  notIn?: InputMaybe<Array<MintStage>>;
};

export type NestedEnumMintStageWithAggregatesFilter = {
  _count?: InputMaybe<NestedIntFilter>;
  _max?: InputMaybe<NestedEnumMintStageFilter>;
  _min?: InputMaybe<NestedEnumMintStageFilter>;
  equals?: InputMaybe<MintStage>;
  in?: InputMaybe<Array<MintStage>>;
  not?: InputMaybe<NestedEnumMintStageWithAggregatesFilter>;
  notIn?: InputMaybe<Array<MintStage>>;
};

export type NestedEnumPaymentGatewayFilter = {
  equals?: InputMaybe<PaymentGateway>;
  in?: InputMaybe<Array<PaymentGateway>>;
  not?: InputMaybe<NestedEnumPaymentGatewayFilter>;
  notIn?: InputMaybe<Array<PaymentGateway>>;
};

export type NestedEnumPaymentGatewayWithAggregatesFilter = {
  _count?: InputMaybe<NestedIntFilter>;
  _max?: InputMaybe<NestedEnumPaymentGatewayFilter>;
  _min?: InputMaybe<NestedEnumPaymentGatewayFilter>;
  equals?: InputMaybe<PaymentGateway>;
  in?: InputMaybe<Array<PaymentGateway>>;
  not?: InputMaybe<NestedEnumPaymentGatewayWithAggregatesFilter>;
  notIn?: InputMaybe<Array<PaymentGateway>>;
};

export type NestedEnumPaymentStatusFilter = {
  equals?: InputMaybe<PaymentStatus>;
  in?: InputMaybe<Array<PaymentStatus>>;
  not?: InputMaybe<NestedEnumPaymentStatusFilter>;
  notIn?: InputMaybe<Array<PaymentStatus>>;
};

export type NestedEnumPaymentStatusWithAggregatesFilter = {
  _count?: InputMaybe<NestedIntFilter>;
  _max?: InputMaybe<NestedEnumPaymentStatusFilter>;
  _min?: InputMaybe<NestedEnumPaymentStatusFilter>;
  equals?: InputMaybe<PaymentStatus>;
  in?: InputMaybe<Array<PaymentStatus>>;
  not?: InputMaybe<NestedEnumPaymentStatusWithAggregatesFilter>;
  notIn?: InputMaybe<Array<PaymentStatus>>;
};

export type NestedEnumProductTypeNullableFilter = {
  equals?: InputMaybe<ProductType>;
  in?: InputMaybe<Array<ProductType>>;
  not?: InputMaybe<NestedEnumProductTypeNullableFilter>;
  notIn?: InputMaybe<Array<ProductType>>;
};

export type NestedEnumProductTypeNullableWithAggregatesFilter = {
  _count?: InputMaybe<NestedIntNullableFilter>;
  _max?: InputMaybe<NestedEnumProductTypeNullableFilter>;
  _min?: InputMaybe<NestedEnumProductTypeNullableFilter>;
  equals?: InputMaybe<ProductType>;
  in?: InputMaybe<Array<ProductType>>;
  not?: InputMaybe<NestedEnumProductTypeNullableWithAggregatesFilter>;
  notIn?: InputMaybe<Array<ProductType>>;
};

export type NestedEnumStatusFilter = {
  equals?: InputMaybe<Status>;
  in?: InputMaybe<Array<Status>>;
  not?: InputMaybe<NestedEnumStatusFilter>;
  notIn?: InputMaybe<Array<Status>>;
};

export type NestedEnumStatusWithAggregatesFilter = {
  _count?: InputMaybe<NestedIntFilter>;
  _max?: InputMaybe<NestedEnumStatusFilter>;
  _min?: InputMaybe<NestedEnumStatusFilter>;
  equals?: InputMaybe<Status>;
  in?: InputMaybe<Array<Status>>;
  not?: InputMaybe<NestedEnumStatusWithAggregatesFilter>;
  notIn?: InputMaybe<Array<Status>>;
};

export type NestedEnumThemeColorFilter = {
  equals?: InputMaybe<ThemeColor>;
  in?: InputMaybe<Array<ThemeColor>>;
  not?: InputMaybe<NestedEnumThemeColorFilter>;
  notIn?: InputMaybe<Array<ThemeColor>>;
};

export type NestedEnumThemeColorWithAggregatesFilter = {
  _count?: InputMaybe<NestedIntFilter>;
  _max?: InputMaybe<NestedEnumThemeColorFilter>;
  _min?: InputMaybe<NestedEnumThemeColorFilter>;
  equals?: InputMaybe<ThemeColor>;
  in?: InputMaybe<Array<ThemeColor>>;
  not?: InputMaybe<NestedEnumThemeColorWithAggregatesFilter>;
  notIn?: InputMaybe<Array<ThemeColor>>;
};

export type NestedEnumUserTypeNullableFilter = {
  equals?: InputMaybe<UserType>;
  in?: InputMaybe<Array<UserType>>;
  not?: InputMaybe<NestedEnumUserTypeNullableFilter>;
  notIn?: InputMaybe<Array<UserType>>;
};

export type NestedEnumUserTypeNullableWithAggregatesFilter = {
  _count?: InputMaybe<NestedIntNullableFilter>;
  _max?: InputMaybe<NestedEnumUserTypeNullableFilter>;
  _min?: InputMaybe<NestedEnumUserTypeNullableFilter>;
  equals?: InputMaybe<UserType>;
  in?: InputMaybe<Array<UserType>>;
  not?: InputMaybe<NestedEnumUserTypeNullableWithAggregatesFilter>;
  notIn?: InputMaybe<Array<UserType>>;
};

export type NestedEnumVideoRatingFilter = {
  equals?: InputMaybe<VideoRating>;
  in?: InputMaybe<Array<VideoRating>>;
  not?: InputMaybe<NestedEnumVideoRatingFilter>;
  notIn?: InputMaybe<Array<VideoRating>>;
};

export type NestedEnumVideoRatingWithAggregatesFilter = {
  _count?: InputMaybe<NestedIntFilter>;
  _max?: InputMaybe<NestedEnumVideoRatingFilter>;
  _min?: InputMaybe<NestedEnumVideoRatingFilter>;
  equals?: InputMaybe<VideoRating>;
  in?: InputMaybe<Array<VideoRating>>;
  not?: InputMaybe<NestedEnumVideoRatingWithAggregatesFilter>;
  notIn?: InputMaybe<Array<VideoRating>>;
};

export type NestedEnumVideoStatusFilter = {
  equals?: InputMaybe<VideoStatus>;
  in?: InputMaybe<Array<VideoStatus>>;
  not?: InputMaybe<NestedEnumVideoStatusFilter>;
  notIn?: InputMaybe<Array<VideoStatus>>;
};

export type NestedEnumVideoStatusWithAggregatesFilter = {
  _count?: InputMaybe<NestedIntFilter>;
  _max?: InputMaybe<NestedEnumVideoStatusFilter>;
  _min?: InputMaybe<NestedEnumVideoStatusFilter>;
  equals?: InputMaybe<VideoStatus>;
  in?: InputMaybe<Array<VideoStatus>>;
  not?: InputMaybe<NestedEnumVideoStatusWithAggregatesFilter>;
  notIn?: InputMaybe<Array<VideoStatus>>;
};

export type NestedFloatFilter = {
  equals?: InputMaybe<Scalars['Float']>;
  gt?: InputMaybe<Scalars['Float']>;
  gte?: InputMaybe<Scalars['Float']>;
  in?: InputMaybe<Array<Scalars['Float']>>;
  lt?: InputMaybe<Scalars['Float']>;
  lte?: InputMaybe<Scalars['Float']>;
  not?: InputMaybe<NestedFloatFilter>;
  notIn?: InputMaybe<Array<Scalars['Float']>>;
};

export type NestedFloatNullableFilter = {
  equals?: InputMaybe<Scalars['Float']>;
  gt?: InputMaybe<Scalars['Float']>;
  gte?: InputMaybe<Scalars['Float']>;
  in?: InputMaybe<Array<Scalars['Float']>>;
  lt?: InputMaybe<Scalars['Float']>;
  lte?: InputMaybe<Scalars['Float']>;
  not?: InputMaybe<NestedFloatNullableFilter>;
  notIn?: InputMaybe<Array<Scalars['Float']>>;
};

export type NestedFloatNullableWithAggregatesFilter = {
  _avg?: InputMaybe<NestedFloatNullableFilter>;
  _count?: InputMaybe<NestedIntNullableFilter>;
  _max?: InputMaybe<NestedFloatNullableFilter>;
  _min?: InputMaybe<NestedFloatNullableFilter>;
  _sum?: InputMaybe<NestedFloatNullableFilter>;
  equals?: InputMaybe<Scalars['Float']>;
  gt?: InputMaybe<Scalars['Float']>;
  gte?: InputMaybe<Scalars['Float']>;
  in?: InputMaybe<Array<Scalars['Float']>>;
  lt?: InputMaybe<Scalars['Float']>;
  lte?: InputMaybe<Scalars['Float']>;
  not?: InputMaybe<NestedFloatNullableWithAggregatesFilter>;
  notIn?: InputMaybe<Array<Scalars['Float']>>;
};

export type NestedFloatWithAggregatesFilter = {
  _avg?: InputMaybe<NestedFloatFilter>;
  _count?: InputMaybe<NestedIntFilter>;
  _max?: InputMaybe<NestedFloatFilter>;
  _min?: InputMaybe<NestedFloatFilter>;
  _sum?: InputMaybe<NestedFloatFilter>;
  equals?: InputMaybe<Scalars['Float']>;
  gt?: InputMaybe<Scalars['Float']>;
  gte?: InputMaybe<Scalars['Float']>;
  in?: InputMaybe<Array<Scalars['Float']>>;
  lt?: InputMaybe<Scalars['Float']>;
  lte?: InputMaybe<Scalars['Float']>;
  not?: InputMaybe<NestedFloatWithAggregatesFilter>;
  notIn?: InputMaybe<Array<Scalars['Float']>>;
};

export type NestedIntFilter = {
  equals?: InputMaybe<Scalars['Int']>;
  gt?: InputMaybe<Scalars['Int']>;
  gte?: InputMaybe<Scalars['Int']>;
  in?: InputMaybe<Array<Scalars['Int']>>;
  lt?: InputMaybe<Scalars['Int']>;
  lte?: InputMaybe<Scalars['Int']>;
  not?: InputMaybe<NestedIntFilter>;
  notIn?: InputMaybe<Array<Scalars['Int']>>;
};

export type NestedIntNullableFilter = {
  equals?: InputMaybe<Scalars['Int']>;
  gt?: InputMaybe<Scalars['Int']>;
  gte?: InputMaybe<Scalars['Int']>;
  in?: InputMaybe<Array<Scalars['Int']>>;
  lt?: InputMaybe<Scalars['Int']>;
  lte?: InputMaybe<Scalars['Int']>;
  not?: InputMaybe<NestedIntNullableFilter>;
  notIn?: InputMaybe<Array<Scalars['Int']>>;
};

export type NestedIntNullableWithAggregatesFilter = {
  _avg?: InputMaybe<NestedFloatNullableFilter>;
  _count?: InputMaybe<NestedIntNullableFilter>;
  _max?: InputMaybe<NestedIntNullableFilter>;
  _min?: InputMaybe<NestedIntNullableFilter>;
  _sum?: InputMaybe<NestedIntNullableFilter>;
  equals?: InputMaybe<Scalars['Int']>;
  gt?: InputMaybe<Scalars['Int']>;
  gte?: InputMaybe<Scalars['Int']>;
  in?: InputMaybe<Array<Scalars['Int']>>;
  lt?: InputMaybe<Scalars['Int']>;
  lte?: InputMaybe<Scalars['Int']>;
  not?: InputMaybe<NestedIntNullableWithAggregatesFilter>;
  notIn?: InputMaybe<Array<Scalars['Int']>>;
};

export type NestedIntWithAggregatesFilter = {
  _avg?: InputMaybe<NestedFloatFilter>;
  _count?: InputMaybe<NestedIntFilter>;
  _max?: InputMaybe<NestedIntFilter>;
  _min?: InputMaybe<NestedIntFilter>;
  _sum?: InputMaybe<NestedIntFilter>;
  equals?: InputMaybe<Scalars['Int']>;
  gt?: InputMaybe<Scalars['Int']>;
  gte?: InputMaybe<Scalars['Int']>;
  in?: InputMaybe<Array<Scalars['Int']>>;
  lt?: InputMaybe<Scalars['Int']>;
  lte?: InputMaybe<Scalars['Int']>;
  not?: InputMaybe<NestedIntWithAggregatesFilter>;
  notIn?: InputMaybe<Array<Scalars['Int']>>;
};

export type NestedJsonFilter = {
  equals?: InputMaybe<Scalars['JSON']>;
  not?: InputMaybe<Scalars['JSON']>;
};

export type NestedJsonNullableFilter = {
  equals?: InputMaybe<Scalars['JSON']>;
  not?: InputMaybe<Scalars['JSON']>;
};

export type NestedStringFilter = {
  contains?: InputMaybe<Scalars['String']>;
  endsWith?: InputMaybe<Scalars['String']>;
  equals?: InputMaybe<Scalars['String']>;
  gt?: InputMaybe<Scalars['String']>;
  gte?: InputMaybe<Scalars['String']>;
  in?: InputMaybe<Array<Scalars['String']>>;
  lt?: InputMaybe<Scalars['String']>;
  lte?: InputMaybe<Scalars['String']>;
  not?: InputMaybe<NestedStringFilter>;
  notIn?: InputMaybe<Array<Scalars['String']>>;
  startsWith?: InputMaybe<Scalars['String']>;
};

export type NestedStringNullableFilter = {
  contains?: InputMaybe<Scalars['String']>;
  endsWith?: InputMaybe<Scalars['String']>;
  equals?: InputMaybe<Scalars['String']>;
  gt?: InputMaybe<Scalars['String']>;
  gte?: InputMaybe<Scalars['String']>;
  in?: InputMaybe<Array<Scalars['String']>>;
  lt?: InputMaybe<Scalars['String']>;
  lte?: InputMaybe<Scalars['String']>;
  not?: InputMaybe<NestedStringNullableFilter>;
  notIn?: InputMaybe<Array<Scalars['String']>>;
  startsWith?: InputMaybe<Scalars['String']>;
};

export type NestedStringNullableWithAggregatesFilter = {
  _count?: InputMaybe<NestedIntNullableFilter>;
  _max?: InputMaybe<NestedStringNullableFilter>;
  _min?: InputMaybe<NestedStringNullableFilter>;
  contains?: InputMaybe<Scalars['String']>;
  endsWith?: InputMaybe<Scalars['String']>;
  equals?: InputMaybe<Scalars['String']>;
  gt?: InputMaybe<Scalars['String']>;
  gte?: InputMaybe<Scalars['String']>;
  in?: InputMaybe<Array<Scalars['String']>>;
  lt?: InputMaybe<Scalars['String']>;
  lte?: InputMaybe<Scalars['String']>;
  not?: InputMaybe<NestedStringNullableWithAggregatesFilter>;
  notIn?: InputMaybe<Array<Scalars['String']>>;
  startsWith?: InputMaybe<Scalars['String']>;
};

export type NestedStringWithAggregatesFilter = {
  _count?: InputMaybe<NestedIntFilter>;
  _max?: InputMaybe<NestedStringFilter>;
  _min?: InputMaybe<NestedStringFilter>;
  contains?: InputMaybe<Scalars['String']>;
  endsWith?: InputMaybe<Scalars['String']>;
  equals?: InputMaybe<Scalars['String']>;
  gt?: InputMaybe<Scalars['String']>;
  gte?: InputMaybe<Scalars['String']>;
  in?: InputMaybe<Array<Scalars['String']>>;
  lt?: InputMaybe<Scalars['String']>;
  lte?: InputMaybe<Scalars['String']>;
  not?: InputMaybe<NestedStringWithAggregatesFilter>;
  notIn?: InputMaybe<Array<Scalars['String']>>;
  startsWith?: InputMaybe<Scalars['String']>;
};

export type NftCollection = {
  __typename?: 'NftCollection';
  _count?: Maybe<NftCollectionCount>;
  banner: Scalars['String'];
  contractAbi: Scalars['JSON'];
  contractAddress: Scalars['String'];
  createdAt: Scalars['DateTime'];
  creatorWalletAddress: Scalars['String'];
  creators: Array<UserNftCollections>;
  description: Scalars['String'];
  id: Scalars['String'];
  image: Scalars['String'];
  mintEndedAt?: Maybe<Scalars['DateTime']>;
  mintStage: MintStage;
  mintedNfts: Array<MintedNft>;
  preSalesEndDate: Scalars['DateTime'];
  preSalesPrice: Scalars['Float'];
  preSalesPriceCurrencyCode: CurrencyCode;
  preSalesStartDate: Scalars['DateTime'];
  publicSalesEndDate: Scalars['DateTime'];
  publicSalesPrice: Scalars['Float'];
  publicSalesPriceCurrencyCode: CurrencyCode;
  publicSalesStartDate: Scalars['DateTime'];
  roadmap: Scalars['JSON'];
  title: Scalars['String'];
  updatedAt: Scalars['DateTime'];
};


export type NftCollectionCreatorsArgs = {
  cursor?: InputMaybe<UserNftCollectionsWhereUniqueInput>;
  distinct?: InputMaybe<Array<UserNftCollectionsScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<UserNftCollectionsOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<UserNftCollectionsWhereInput>;
};


export type NftCollectionMintedNftsArgs = {
  cursor?: InputMaybe<MintedNftWhereUniqueInput>;
  distinct?: InputMaybe<Array<MintedNftScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<MintedNftOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<MintedNftWhereInput>;
};

export type NftCollectionAvgAggregate = {
  __typename?: 'NftCollectionAvgAggregate';
  preSalesPrice?: Maybe<Scalars['Float']>;
  publicSalesPrice?: Maybe<Scalars['Float']>;
};

export type NftCollectionAvgOrderByAggregateInput = {
  preSalesPrice?: InputMaybe<SortOrder>;
  publicSalesPrice?: InputMaybe<SortOrder>;
};

export type NftCollectionCount = {
  __typename?: 'NftCollectionCount';
  creators: Scalars['Int'];
  mintedNfts: Scalars['Int'];
};

export type NftCollectionCountAggregate = {
  __typename?: 'NftCollectionCountAggregate';
  _all: Scalars['Int'];
  banner: Scalars['Int'];
  contractAbi: Scalars['Int'];
  contractAddress: Scalars['Int'];
  createdAt: Scalars['Int'];
  creatorWalletAddress: Scalars['Int'];
  description: Scalars['Int'];
  id: Scalars['Int'];
  image: Scalars['Int'];
  mintEndedAt: Scalars['Int'];
  mintStage: Scalars['Int'];
  preSalesEndDate: Scalars['Int'];
  preSalesPrice: Scalars['Int'];
  preSalesPriceCurrencyCode: Scalars['Int'];
  preSalesStartDate: Scalars['Int'];
  publicSalesEndDate: Scalars['Int'];
  publicSalesPrice: Scalars['Int'];
  publicSalesPriceCurrencyCode: Scalars['Int'];
  publicSalesStartDate: Scalars['Int'];
  roadmap: Scalars['Int'];
  title: Scalars['Int'];
  updatedAt: Scalars['Int'];
};

export type NftCollectionCountOrderByAggregateInput = {
  banner?: InputMaybe<SortOrder>;
  contractAbi?: InputMaybe<SortOrder>;
  contractAddress?: InputMaybe<SortOrder>;
  createdAt?: InputMaybe<SortOrder>;
  creatorWalletAddress?: InputMaybe<SortOrder>;
  description?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  image?: InputMaybe<SortOrder>;
  mintEndedAt?: InputMaybe<SortOrder>;
  mintStage?: InputMaybe<SortOrder>;
  preSalesEndDate?: InputMaybe<SortOrder>;
  preSalesPrice?: InputMaybe<SortOrder>;
  preSalesPriceCurrencyCode?: InputMaybe<SortOrder>;
  preSalesStartDate?: InputMaybe<SortOrder>;
  publicSalesEndDate?: InputMaybe<SortOrder>;
  publicSalesPrice?: InputMaybe<SortOrder>;
  publicSalesPriceCurrencyCode?: InputMaybe<SortOrder>;
  publicSalesStartDate?: InputMaybe<SortOrder>;
  roadmap?: InputMaybe<SortOrder>;
  title?: InputMaybe<SortOrder>;
  updatedAt?: InputMaybe<SortOrder>;
};

export type NftCollectionCreateInput = {
  banner: Scalars['String'];
  contractAbi: Scalars['JSON'];
  contractAddress: Scalars['String'];
  createdAt?: InputMaybe<Scalars['DateTime']>;
  creatorWalletAddress: Scalars['String'];
  creators?: InputMaybe<UserNftCollectionsCreateNestedManyWithoutNftCollectionInput>;
  description: Scalars['String'];
  id?: InputMaybe<Scalars['String']>;
  image: Scalars['String'];
  mintEndedAt?: InputMaybe<Scalars['DateTime']>;
  mintStage?: InputMaybe<MintStage>;
  mintedNfts?: InputMaybe<MintedNftCreateNestedManyWithoutNftCollectionInput>;
  preSalesEndDate: Scalars['DateTime'];
  preSalesPrice: Scalars['Float'];
  preSalesPriceCurrencyCode: CurrencyCode;
  preSalesStartDate: Scalars['DateTime'];
  publicSalesEndDate: Scalars['DateTime'];
  publicSalesPrice: Scalars['Float'];
  publicSalesPriceCurrencyCode: CurrencyCode;
  publicSalesStartDate: Scalars['DateTime'];
  roadmap: Scalars['JSON'];
  title: Scalars['String'];
  updatedAt?: InputMaybe<Scalars['DateTime']>;
};

export type NftCollectionCreateManyInput = {
  banner: Scalars['String'];
  contractAbi: Scalars['JSON'];
  contractAddress: Scalars['String'];
  createdAt?: InputMaybe<Scalars['DateTime']>;
  creatorWalletAddress: Scalars['String'];
  description: Scalars['String'];
  id?: InputMaybe<Scalars['String']>;
  image: Scalars['String'];
  mintEndedAt?: InputMaybe<Scalars['DateTime']>;
  mintStage?: InputMaybe<MintStage>;
  preSalesEndDate: Scalars['DateTime'];
  preSalesPrice: Scalars['Float'];
  preSalesPriceCurrencyCode: CurrencyCode;
  preSalesStartDate: Scalars['DateTime'];
  publicSalesEndDate: Scalars['DateTime'];
  publicSalesPrice: Scalars['Float'];
  publicSalesPriceCurrencyCode: CurrencyCode;
  publicSalesStartDate: Scalars['DateTime'];
  roadmap: Scalars['JSON'];
  title: Scalars['String'];
  updatedAt?: InputMaybe<Scalars['DateTime']>;
};

export type NftCollectionCreateNestedOneWithoutCreatorsInput = {
  connect?: InputMaybe<NftCollectionWhereUniqueInput>;
  connectOrCreate?: InputMaybe<NftCollectionCreateOrConnectWithoutCreatorsInput>;
  create?: InputMaybe<NftCollectionCreateWithoutCreatorsInput>;
};

export type NftCollectionCreateNestedOneWithoutMintedNftsInput = {
  connect?: InputMaybe<NftCollectionWhereUniqueInput>;
  connectOrCreate?: InputMaybe<NftCollectionCreateOrConnectWithoutMintedNftsInput>;
  create?: InputMaybe<NftCollectionCreateWithoutMintedNftsInput>;
};

export type NftCollectionCreateOrConnectWithoutCreatorsInput = {
  create: NftCollectionCreateWithoutCreatorsInput;
  where: NftCollectionWhereUniqueInput;
};

export type NftCollectionCreateOrConnectWithoutMintedNftsInput = {
  create: NftCollectionCreateWithoutMintedNftsInput;
  where: NftCollectionWhereUniqueInput;
};

export type NftCollectionCreateWithoutCreatorsInput = {
  banner: Scalars['String'];
  contractAbi: Scalars['JSON'];
  contractAddress: Scalars['String'];
  createdAt?: InputMaybe<Scalars['DateTime']>;
  creatorWalletAddress: Scalars['String'];
  description: Scalars['String'];
  id?: InputMaybe<Scalars['String']>;
  image: Scalars['String'];
  mintEndedAt?: InputMaybe<Scalars['DateTime']>;
  mintStage?: InputMaybe<MintStage>;
  mintedNfts?: InputMaybe<MintedNftCreateNestedManyWithoutNftCollectionInput>;
  preSalesEndDate: Scalars['DateTime'];
  preSalesPrice: Scalars['Float'];
  preSalesPriceCurrencyCode: CurrencyCode;
  preSalesStartDate: Scalars['DateTime'];
  publicSalesEndDate: Scalars['DateTime'];
  publicSalesPrice: Scalars['Float'];
  publicSalesPriceCurrencyCode: CurrencyCode;
  publicSalesStartDate: Scalars['DateTime'];
  roadmap: Scalars['JSON'];
  title: Scalars['String'];
  updatedAt?: InputMaybe<Scalars['DateTime']>;
};

export type NftCollectionCreateWithoutMintedNftsInput = {
  banner: Scalars['String'];
  contractAbi: Scalars['JSON'];
  contractAddress: Scalars['String'];
  createdAt?: InputMaybe<Scalars['DateTime']>;
  creatorWalletAddress: Scalars['String'];
  creators?: InputMaybe<UserNftCollectionsCreateNestedManyWithoutNftCollectionInput>;
  description: Scalars['String'];
  id?: InputMaybe<Scalars['String']>;
  image: Scalars['String'];
  mintEndedAt?: InputMaybe<Scalars['DateTime']>;
  mintStage?: InputMaybe<MintStage>;
  preSalesEndDate: Scalars['DateTime'];
  preSalesPrice: Scalars['Float'];
  preSalesPriceCurrencyCode: CurrencyCode;
  preSalesStartDate: Scalars['DateTime'];
  publicSalesEndDate: Scalars['DateTime'];
  publicSalesPrice: Scalars['Float'];
  publicSalesPriceCurrencyCode: CurrencyCode;
  publicSalesStartDate: Scalars['DateTime'];
  roadmap: Scalars['JSON'];
  title: Scalars['String'];
  updatedAt?: InputMaybe<Scalars['DateTime']>;
};

export type NftCollectionGroupBy = {
  __typename?: 'NftCollectionGroupBy';
  _avg?: Maybe<NftCollectionAvgAggregate>;
  _count?: Maybe<NftCollectionCountAggregate>;
  _max?: Maybe<NftCollectionMaxAggregate>;
  _min?: Maybe<NftCollectionMinAggregate>;
  _sum?: Maybe<NftCollectionSumAggregate>;
  banner: Scalars['String'];
  contractAbi: Scalars['JSON'];
  contractAddress: Scalars['String'];
  createdAt: Scalars['DateTime'];
  creatorWalletAddress: Scalars['String'];
  description: Scalars['String'];
  id: Scalars['String'];
  image: Scalars['String'];
  mintEndedAt?: Maybe<Scalars['DateTime']>;
  mintStage: MintStage;
  preSalesEndDate: Scalars['DateTime'];
  preSalesPrice: Scalars['Float'];
  preSalesPriceCurrencyCode: CurrencyCode;
  preSalesStartDate: Scalars['DateTime'];
  publicSalesEndDate: Scalars['DateTime'];
  publicSalesPrice: Scalars['Float'];
  publicSalesPriceCurrencyCode: CurrencyCode;
  publicSalesStartDate: Scalars['DateTime'];
  roadmap: Scalars['JSON'];
  title: Scalars['String'];
  updatedAt: Scalars['DateTime'];
};

export type NftCollectionMaxAggregate = {
  __typename?: 'NftCollectionMaxAggregate';
  banner?: Maybe<Scalars['String']>;
  contractAddress?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  creatorWalletAddress?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  image?: Maybe<Scalars['String']>;
  mintEndedAt?: Maybe<Scalars['DateTime']>;
  mintStage?: Maybe<MintStage>;
  preSalesEndDate?: Maybe<Scalars['DateTime']>;
  preSalesPrice?: Maybe<Scalars['Float']>;
  preSalesPriceCurrencyCode?: Maybe<CurrencyCode>;
  preSalesStartDate?: Maybe<Scalars['DateTime']>;
  publicSalesEndDate?: Maybe<Scalars['DateTime']>;
  publicSalesPrice?: Maybe<Scalars['Float']>;
  publicSalesPriceCurrencyCode?: Maybe<CurrencyCode>;
  publicSalesStartDate?: Maybe<Scalars['DateTime']>;
  title?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type NftCollectionMaxOrderByAggregateInput = {
  banner?: InputMaybe<SortOrder>;
  contractAddress?: InputMaybe<SortOrder>;
  createdAt?: InputMaybe<SortOrder>;
  creatorWalletAddress?: InputMaybe<SortOrder>;
  description?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  image?: InputMaybe<SortOrder>;
  mintEndedAt?: InputMaybe<SortOrder>;
  mintStage?: InputMaybe<SortOrder>;
  preSalesEndDate?: InputMaybe<SortOrder>;
  preSalesPrice?: InputMaybe<SortOrder>;
  preSalesPriceCurrencyCode?: InputMaybe<SortOrder>;
  preSalesStartDate?: InputMaybe<SortOrder>;
  publicSalesEndDate?: InputMaybe<SortOrder>;
  publicSalesPrice?: InputMaybe<SortOrder>;
  publicSalesPriceCurrencyCode?: InputMaybe<SortOrder>;
  publicSalesStartDate?: InputMaybe<SortOrder>;
  title?: InputMaybe<SortOrder>;
  updatedAt?: InputMaybe<SortOrder>;
};

export type NftCollectionMinAggregate = {
  __typename?: 'NftCollectionMinAggregate';
  banner?: Maybe<Scalars['String']>;
  contractAddress?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  creatorWalletAddress?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  image?: Maybe<Scalars['String']>;
  mintEndedAt?: Maybe<Scalars['DateTime']>;
  mintStage?: Maybe<MintStage>;
  preSalesEndDate?: Maybe<Scalars['DateTime']>;
  preSalesPrice?: Maybe<Scalars['Float']>;
  preSalesPriceCurrencyCode?: Maybe<CurrencyCode>;
  preSalesStartDate?: Maybe<Scalars['DateTime']>;
  publicSalesEndDate?: Maybe<Scalars['DateTime']>;
  publicSalesPrice?: Maybe<Scalars['Float']>;
  publicSalesPriceCurrencyCode?: Maybe<CurrencyCode>;
  publicSalesStartDate?: Maybe<Scalars['DateTime']>;
  title?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type NftCollectionMinOrderByAggregateInput = {
  banner?: InputMaybe<SortOrder>;
  contractAddress?: InputMaybe<SortOrder>;
  createdAt?: InputMaybe<SortOrder>;
  creatorWalletAddress?: InputMaybe<SortOrder>;
  description?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  image?: InputMaybe<SortOrder>;
  mintEndedAt?: InputMaybe<SortOrder>;
  mintStage?: InputMaybe<SortOrder>;
  preSalesEndDate?: InputMaybe<SortOrder>;
  preSalesPrice?: InputMaybe<SortOrder>;
  preSalesPriceCurrencyCode?: InputMaybe<SortOrder>;
  preSalesStartDate?: InputMaybe<SortOrder>;
  publicSalesEndDate?: InputMaybe<SortOrder>;
  publicSalesPrice?: InputMaybe<SortOrder>;
  publicSalesPriceCurrencyCode?: InputMaybe<SortOrder>;
  publicSalesStartDate?: InputMaybe<SortOrder>;
  title?: InputMaybe<SortOrder>;
  updatedAt?: InputMaybe<SortOrder>;
};

export type NftCollectionOrderByWithAggregationInput = {
  _avg?: InputMaybe<NftCollectionAvgOrderByAggregateInput>;
  _count?: InputMaybe<NftCollectionCountOrderByAggregateInput>;
  _max?: InputMaybe<NftCollectionMaxOrderByAggregateInput>;
  _min?: InputMaybe<NftCollectionMinOrderByAggregateInput>;
  _sum?: InputMaybe<NftCollectionSumOrderByAggregateInput>;
  banner?: InputMaybe<SortOrder>;
  contractAbi?: InputMaybe<SortOrder>;
  contractAddress?: InputMaybe<SortOrder>;
  createdAt?: InputMaybe<SortOrder>;
  creatorWalletAddress?: InputMaybe<SortOrder>;
  description?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  image?: InputMaybe<SortOrder>;
  mintEndedAt?: InputMaybe<SortOrder>;
  mintStage?: InputMaybe<SortOrder>;
  preSalesEndDate?: InputMaybe<SortOrder>;
  preSalesPrice?: InputMaybe<SortOrder>;
  preSalesPriceCurrencyCode?: InputMaybe<SortOrder>;
  preSalesStartDate?: InputMaybe<SortOrder>;
  publicSalesEndDate?: InputMaybe<SortOrder>;
  publicSalesPrice?: InputMaybe<SortOrder>;
  publicSalesPriceCurrencyCode?: InputMaybe<SortOrder>;
  publicSalesStartDate?: InputMaybe<SortOrder>;
  roadmap?: InputMaybe<SortOrder>;
  title?: InputMaybe<SortOrder>;
  updatedAt?: InputMaybe<SortOrder>;
};

export type NftCollectionOrderByWithRelationInput = {
  banner?: InputMaybe<SortOrder>;
  contractAbi?: InputMaybe<SortOrder>;
  contractAddress?: InputMaybe<SortOrder>;
  createdAt?: InputMaybe<SortOrder>;
  creatorWalletAddress?: InputMaybe<SortOrder>;
  creators?: InputMaybe<UserNftCollectionsOrderByRelationAggregateInput>;
  description?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  image?: InputMaybe<SortOrder>;
  mintEndedAt?: InputMaybe<SortOrder>;
  mintStage?: InputMaybe<SortOrder>;
  mintedNfts?: InputMaybe<MintedNftOrderByRelationAggregateInput>;
  preSalesEndDate?: InputMaybe<SortOrder>;
  preSalesPrice?: InputMaybe<SortOrder>;
  preSalesPriceCurrencyCode?: InputMaybe<SortOrder>;
  preSalesStartDate?: InputMaybe<SortOrder>;
  publicSalesEndDate?: InputMaybe<SortOrder>;
  publicSalesPrice?: InputMaybe<SortOrder>;
  publicSalesPriceCurrencyCode?: InputMaybe<SortOrder>;
  publicSalesStartDate?: InputMaybe<SortOrder>;
  roadmap?: InputMaybe<SortOrder>;
  title?: InputMaybe<SortOrder>;
  updatedAt?: InputMaybe<SortOrder>;
};

export type NftCollectionRelationFilter = {
  is?: InputMaybe<NftCollectionWhereInput>;
  isNot?: InputMaybe<NftCollectionWhereInput>;
};

export enum NftCollectionScalarFieldEnum {
  Banner = 'banner',
  ContractAbi = 'contractAbi',
  ContractAddress = 'contractAddress',
  CreatedAt = 'createdAt',
  CreatorWalletAddress = 'creatorWalletAddress',
  Description = 'description',
  Id = 'id',
  Image = 'image',
  MintEndedAt = 'mintEndedAt',
  MintStage = 'mintStage',
  PreSalesEndDate = 'preSalesEndDate',
  PreSalesPrice = 'preSalesPrice',
  PreSalesPriceCurrencyCode = 'preSalesPriceCurrencyCode',
  PreSalesStartDate = 'preSalesStartDate',
  PublicSalesEndDate = 'publicSalesEndDate',
  PublicSalesPrice = 'publicSalesPrice',
  PublicSalesPriceCurrencyCode = 'publicSalesPriceCurrencyCode',
  PublicSalesStartDate = 'publicSalesStartDate',
  Roadmap = 'roadmap',
  Title = 'title',
  UpdatedAt = 'updatedAt'
}

export type NftCollectionScalarWhereWithAggregatesInput = {
  AND?: InputMaybe<Array<NftCollectionScalarWhereWithAggregatesInput>>;
  NOT?: InputMaybe<Array<NftCollectionScalarWhereWithAggregatesInput>>;
  OR?: InputMaybe<Array<NftCollectionScalarWhereWithAggregatesInput>>;
  banner?: InputMaybe<StringWithAggregatesFilter>;
  contractAbi?: InputMaybe<JsonWithAggregatesFilter>;
  contractAddress?: InputMaybe<StringWithAggregatesFilter>;
  createdAt?: InputMaybe<DateTimeWithAggregatesFilter>;
  creatorWalletAddress?: InputMaybe<StringWithAggregatesFilter>;
  description?: InputMaybe<StringWithAggregatesFilter>;
  id?: InputMaybe<StringWithAggregatesFilter>;
  image?: InputMaybe<StringWithAggregatesFilter>;
  mintEndedAt?: InputMaybe<DateTimeNullableWithAggregatesFilter>;
  mintStage?: InputMaybe<EnumMintStageWithAggregatesFilter>;
  preSalesEndDate?: InputMaybe<DateTimeWithAggregatesFilter>;
  preSalesPrice?: InputMaybe<FloatWithAggregatesFilter>;
  preSalesPriceCurrencyCode?: InputMaybe<EnumCurrencyCodeWithAggregatesFilter>;
  preSalesStartDate?: InputMaybe<DateTimeWithAggregatesFilter>;
  publicSalesEndDate?: InputMaybe<DateTimeWithAggregatesFilter>;
  publicSalesPrice?: InputMaybe<FloatWithAggregatesFilter>;
  publicSalesPriceCurrencyCode?: InputMaybe<EnumCurrencyCodeWithAggregatesFilter>;
  publicSalesStartDate?: InputMaybe<DateTimeWithAggregatesFilter>;
  roadmap?: InputMaybe<JsonWithAggregatesFilter>;
  title?: InputMaybe<StringWithAggregatesFilter>;
  updatedAt?: InputMaybe<DateTimeWithAggregatesFilter>;
};

export type NftCollectionSumAggregate = {
  __typename?: 'NftCollectionSumAggregate';
  preSalesPrice?: Maybe<Scalars['Float']>;
  publicSalesPrice?: Maybe<Scalars['Float']>;
};

export type NftCollectionSumOrderByAggregateInput = {
  preSalesPrice?: InputMaybe<SortOrder>;
  publicSalesPrice?: InputMaybe<SortOrder>;
};

export type NftCollectionUpdateInput = {
  banner?: InputMaybe<StringFieldUpdateOperationsInput>;
  contractAbi?: InputMaybe<Scalars['JSON']>;
  contractAddress?: InputMaybe<StringFieldUpdateOperationsInput>;
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  creatorWalletAddress?: InputMaybe<StringFieldUpdateOperationsInput>;
  creators?: InputMaybe<UserNftCollectionsUpdateManyWithoutNftCollectionInput>;
  description?: InputMaybe<StringFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  image?: InputMaybe<StringFieldUpdateOperationsInput>;
  mintEndedAt?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  mintStage?: InputMaybe<EnumMintStageFieldUpdateOperationsInput>;
  mintedNfts?: InputMaybe<MintedNftUpdateManyWithoutNftCollectionInput>;
  preSalesEndDate?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  preSalesPrice?: InputMaybe<FloatFieldUpdateOperationsInput>;
  preSalesPriceCurrencyCode?: InputMaybe<EnumCurrencyCodeFieldUpdateOperationsInput>;
  preSalesStartDate?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  publicSalesEndDate?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  publicSalesPrice?: InputMaybe<FloatFieldUpdateOperationsInput>;
  publicSalesPriceCurrencyCode?: InputMaybe<EnumCurrencyCodeFieldUpdateOperationsInput>;
  publicSalesStartDate?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  roadmap?: InputMaybe<Scalars['JSON']>;
  title?: InputMaybe<StringFieldUpdateOperationsInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
};

export type NftCollectionUpdateManyMutationInput = {
  banner?: InputMaybe<StringFieldUpdateOperationsInput>;
  contractAbi?: InputMaybe<Scalars['JSON']>;
  contractAddress?: InputMaybe<StringFieldUpdateOperationsInput>;
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  creatorWalletAddress?: InputMaybe<StringFieldUpdateOperationsInput>;
  description?: InputMaybe<StringFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  image?: InputMaybe<StringFieldUpdateOperationsInput>;
  mintEndedAt?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  mintStage?: InputMaybe<EnumMintStageFieldUpdateOperationsInput>;
  preSalesEndDate?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  preSalesPrice?: InputMaybe<FloatFieldUpdateOperationsInput>;
  preSalesPriceCurrencyCode?: InputMaybe<EnumCurrencyCodeFieldUpdateOperationsInput>;
  preSalesStartDate?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  publicSalesEndDate?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  publicSalesPrice?: InputMaybe<FloatFieldUpdateOperationsInput>;
  publicSalesPriceCurrencyCode?: InputMaybe<EnumCurrencyCodeFieldUpdateOperationsInput>;
  publicSalesStartDate?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  roadmap?: InputMaybe<Scalars['JSON']>;
  title?: InputMaybe<StringFieldUpdateOperationsInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
};

export type NftCollectionUpdateOneRequiredWithoutCreatorsInput = {
  connect?: InputMaybe<NftCollectionWhereUniqueInput>;
  connectOrCreate?: InputMaybe<NftCollectionCreateOrConnectWithoutCreatorsInput>;
  create?: InputMaybe<NftCollectionCreateWithoutCreatorsInput>;
  update?: InputMaybe<NftCollectionUpdateWithoutCreatorsInput>;
  upsert?: InputMaybe<NftCollectionUpsertWithoutCreatorsInput>;
};

export type NftCollectionUpdateOneRequiredWithoutMintedNftsInput = {
  connect?: InputMaybe<NftCollectionWhereUniqueInput>;
  connectOrCreate?: InputMaybe<NftCollectionCreateOrConnectWithoutMintedNftsInput>;
  create?: InputMaybe<NftCollectionCreateWithoutMintedNftsInput>;
  update?: InputMaybe<NftCollectionUpdateWithoutMintedNftsInput>;
  upsert?: InputMaybe<NftCollectionUpsertWithoutMintedNftsInput>;
};

export type NftCollectionUpdateWithoutCreatorsInput = {
  banner?: InputMaybe<StringFieldUpdateOperationsInput>;
  contractAbi?: InputMaybe<Scalars['JSON']>;
  contractAddress?: InputMaybe<StringFieldUpdateOperationsInput>;
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  creatorWalletAddress?: InputMaybe<StringFieldUpdateOperationsInput>;
  description?: InputMaybe<StringFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  image?: InputMaybe<StringFieldUpdateOperationsInput>;
  mintEndedAt?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  mintStage?: InputMaybe<EnumMintStageFieldUpdateOperationsInput>;
  mintedNfts?: InputMaybe<MintedNftUpdateManyWithoutNftCollectionInput>;
  preSalesEndDate?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  preSalesPrice?: InputMaybe<FloatFieldUpdateOperationsInput>;
  preSalesPriceCurrencyCode?: InputMaybe<EnumCurrencyCodeFieldUpdateOperationsInput>;
  preSalesStartDate?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  publicSalesEndDate?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  publicSalesPrice?: InputMaybe<FloatFieldUpdateOperationsInput>;
  publicSalesPriceCurrencyCode?: InputMaybe<EnumCurrencyCodeFieldUpdateOperationsInput>;
  publicSalesStartDate?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  roadmap?: InputMaybe<Scalars['JSON']>;
  title?: InputMaybe<StringFieldUpdateOperationsInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
};

export type NftCollectionUpdateWithoutMintedNftsInput = {
  banner?: InputMaybe<StringFieldUpdateOperationsInput>;
  contractAbi?: InputMaybe<Scalars['JSON']>;
  contractAddress?: InputMaybe<StringFieldUpdateOperationsInput>;
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  creatorWalletAddress?: InputMaybe<StringFieldUpdateOperationsInput>;
  creators?: InputMaybe<UserNftCollectionsUpdateManyWithoutNftCollectionInput>;
  description?: InputMaybe<StringFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  image?: InputMaybe<StringFieldUpdateOperationsInput>;
  mintEndedAt?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  mintStage?: InputMaybe<EnumMintStageFieldUpdateOperationsInput>;
  preSalesEndDate?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  preSalesPrice?: InputMaybe<FloatFieldUpdateOperationsInput>;
  preSalesPriceCurrencyCode?: InputMaybe<EnumCurrencyCodeFieldUpdateOperationsInput>;
  preSalesStartDate?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  publicSalesEndDate?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  publicSalesPrice?: InputMaybe<FloatFieldUpdateOperationsInput>;
  publicSalesPriceCurrencyCode?: InputMaybe<EnumCurrencyCodeFieldUpdateOperationsInput>;
  publicSalesStartDate?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  roadmap?: InputMaybe<Scalars['JSON']>;
  title?: InputMaybe<StringFieldUpdateOperationsInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
};

export type NftCollectionUpsertWithoutCreatorsInput = {
  create: NftCollectionCreateWithoutCreatorsInput;
  update: NftCollectionUpdateWithoutCreatorsInput;
};

export type NftCollectionUpsertWithoutMintedNftsInput = {
  create: NftCollectionCreateWithoutMintedNftsInput;
  update: NftCollectionUpdateWithoutMintedNftsInput;
};

export type NftCollectionWhereInput = {
  AND?: InputMaybe<Array<NftCollectionWhereInput>>;
  NOT?: InputMaybe<Array<NftCollectionWhereInput>>;
  OR?: InputMaybe<Array<NftCollectionWhereInput>>;
  banner?: InputMaybe<StringFilter>;
  contractAbi?: InputMaybe<JsonFilter>;
  contractAddress?: InputMaybe<StringFilter>;
  createdAt?: InputMaybe<DateTimeFilter>;
  creatorWalletAddress?: InputMaybe<StringFilter>;
  creators?: InputMaybe<UserNftCollectionsListRelationFilter>;
  description?: InputMaybe<StringFilter>;
  id?: InputMaybe<StringFilter>;
  image?: InputMaybe<StringFilter>;
  mintEndedAt?: InputMaybe<DateTimeNullableFilter>;
  mintStage?: InputMaybe<EnumMintStageFilter>;
  mintedNfts?: InputMaybe<MintedNftListRelationFilter>;
  preSalesEndDate?: InputMaybe<DateTimeFilter>;
  preSalesPrice?: InputMaybe<FloatFilter>;
  preSalesPriceCurrencyCode?: InputMaybe<EnumCurrencyCodeFilter>;
  preSalesStartDate?: InputMaybe<DateTimeFilter>;
  publicSalesEndDate?: InputMaybe<DateTimeFilter>;
  publicSalesPrice?: InputMaybe<FloatFilter>;
  publicSalesPriceCurrencyCode?: InputMaybe<EnumCurrencyCodeFilter>;
  publicSalesStartDate?: InputMaybe<DateTimeFilter>;
  roadmap?: InputMaybe<JsonFilter>;
  title?: InputMaybe<StringFilter>;
  updatedAt?: InputMaybe<DateTimeFilter>;
};

export type NftCollectionWhereUniqueInput = {
  id?: InputMaybe<Scalars['String']>;
};

export type NullableDateTimeFieldUpdateOperationsInput = {
  set?: InputMaybe<Scalars['DateTime']>;
};

export type NullableEnumCommunicationLanguageFieldUpdateOperationsInput = {
  set?: InputMaybe<CommunicationLanguage>;
};

export type NullableEnumProductTypeFieldUpdateOperationsInput = {
  set?: InputMaybe<ProductType>;
};

export type NullableEnumUserTypeFieldUpdateOperationsInput = {
  set?: InputMaybe<UserType>;
};

export type NullableFloatFieldUpdateOperationsInput = {
  decrement?: InputMaybe<Scalars['Float']>;
  divide?: InputMaybe<Scalars['Float']>;
  increment?: InputMaybe<Scalars['Float']>;
  multiply?: InputMaybe<Scalars['Float']>;
  set?: InputMaybe<Scalars['Float']>;
};

export type NullableIntFieldUpdateOperationsInput = {
  decrement?: InputMaybe<Scalars['Int']>;
  divide?: InputMaybe<Scalars['Int']>;
  increment?: InputMaybe<Scalars['Int']>;
  multiply?: InputMaybe<Scalars['Int']>;
  set?: InputMaybe<Scalars['Int']>;
};

export type NullableStringFieldUpdateOperationsInput = {
  set?: InputMaybe<Scalars['String']>;
};

export type PaginatationMetadata = {
  __typename?: 'PaginatationMetadata';
  currentPage: Scalars['Int'];
  perPage: Scalars['Int'];
  totalCount: Scalars['Int'];
  totalPages: Scalars['Int'];
};

export type PaginatedAdminUser = {
  __typename?: 'PaginatedAdminUser';
  items?: Maybe<Array<AdminUser>>;
  metadata: PaginatationMetadata;
};

export type PaginatedChannel = {
  __typename?: 'PaginatedChannel';
  items?: Maybe<Array<Channel>>;
  metadata: PaginatationMetadata;
};

export type PaginatedChannelFollower = {
  __typename?: 'PaginatedChannelFollower';
  items?: Maybe<Array<ChannelFollower>>;
  metadata: PaginatationMetadata;
};

export type PaginatedComment = {
  __typename?: 'PaginatedComment';
  items?: Maybe<Array<Comment>>;
  metadata: PaginatationMetadata;
};

export type PaginatedGift = {
  __typename?: 'PaginatedGift';
  items?: Maybe<Array<Gift>>;
  metadata: PaginatationMetadata;
};

export type PaginatedGiftDistribution = {
  __typename?: 'PaginatedGiftDistribution';
  items?: Maybe<Array<GiftDistribution>>;
  metadata: PaginatationMetadata;
};

export type PaginatedInquiry = {
  __typename?: 'PaginatedInquiry';
  items?: Maybe<Array<Inquiry>>;
  metadata: PaginatationMetadata;
};

export type PaginatedLikedVideo = {
  __typename?: 'PaginatedLikedVideo';
  items?: Maybe<Array<LikedVideo>>;
  metadata: PaginatationMetadata;
};

export type PaginatedMintedNft = {
  __typename?: 'PaginatedMintedNft';
  items?: Maybe<Array<MintedNft>>;
  metadata: PaginatationMetadata;
};

export type PaginatedNftCollection = {
  __typename?: 'PaginatedNftCollection';
  items?: Maybe<Array<NftCollection>>;
  metadata: PaginatationMetadata;
};

export type PaginatedPaymentWebhook = {
  __typename?: 'PaginatedPaymentWebhook';
  items?: Maybe<Array<PaymentWebhook>>;
  metadata: PaginatationMetadata;
};

export type PaginatedPlaylist = {
  __typename?: 'PaginatedPlaylist';
  items?: Maybe<Array<Playlist>>;
  metadata: PaginatationMetadata;
};

export type PaginatedPlaylistVideo = {
  __typename?: 'PaginatedPlaylistVideo';
  items?: Maybe<Array<PlaylistVideo>>;
  metadata: PaginatationMetadata;
};

export type PaginatedSubscriptionPlan = {
  __typename?: 'PaginatedSubscriptionPlan';
  items?: Maybe<Array<SubscriptionPlan>>;
  metadata: PaginatationMetadata;
};

export type PaginatedTips = {
  __typename?: 'PaginatedTips';
  items?: Maybe<Array<Tips>>;
  metadata: PaginatationMetadata;
};

export type PaginatedTransaction = {
  __typename?: 'PaginatedTransaction';
  items?: Maybe<Array<Transaction>>;
  metadata: PaginatationMetadata;
};

export type PaginatedUser = {
  __typename?: 'PaginatedUser';
  items?: Maybe<Array<User>>;
  metadata: PaginatationMetadata;
};

export type PaginatedUserPayPerView = {
  __typename?: 'PaginatedUserPayPerView';
  items?: Maybe<Array<UserPayPerView>>;
  metadata: PaginatationMetadata;
};

export type PaginatedUserSubscription = {
  __typename?: 'PaginatedUserSubscription';
  items?: Maybe<Array<UserSubscription>>;
  metadata: PaginatationMetadata;
};

export type PaginatedVideo = {
  __typename?: 'PaginatedVideo';
  items?: Maybe<Array<Video>>;
  metadata: PaginatationMetadata;
};

export type PaginatedWatchHistory = {
  __typename?: 'PaginatedWatchHistory';
  items?: Maybe<Array<WatchHistory>>;
  metadata: PaginatationMetadata;
};

export type PaginatedWatchHistorySummaryItem = {
  __typename?: 'PaginatedWatchHistorySummaryItem';
  items?: Maybe<Array<WatchHistorySummaryItem>>;
  metadata: PaginatationMetadata;
};

export enum PaymentGateway {
  PrestoConnect = 'PRESTO_CONNECT',
  Stripe = 'STRIPE'
}

export enum PaymentStatus {
  Authorised = 'AUTHORISED',
  Cancelled = 'CANCELLED',
  Created = 'CREATED',
  Expired = 'EXPIRED',
  Failed = 'FAILED',
  PendingAuthorise = 'PENDING_AUTHORISE'
}

export type PaymentWebhook = {
  __typename?: 'PaymentWebhook';
  createdAt: Scalars['DateTime'];
  id: Scalars['String'];
  paymentGateway: PaymentGateway;
  paymentRefNumber: Scalars['String'];
  status: PaymentStatus;
  transaction: Transaction;
  transactionId: Scalars['String'];
  updatedAt: Scalars['DateTime'];
  webhookResponseBody: Scalars['JSON'];
};

export type PaymentWebhookCountAggregate = {
  __typename?: 'PaymentWebhookCountAggregate';
  _all: Scalars['Int'];
  createdAt: Scalars['Int'];
  id: Scalars['Int'];
  paymentGateway: Scalars['Int'];
  paymentRefNumber: Scalars['Int'];
  status: Scalars['Int'];
  transactionId: Scalars['Int'];
  updatedAt: Scalars['Int'];
  webhookResponseBody: Scalars['Int'];
};

export type PaymentWebhookCountOrderByAggregateInput = {
  createdAt?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  paymentGateway?: InputMaybe<SortOrder>;
  paymentRefNumber?: InputMaybe<SortOrder>;
  status?: InputMaybe<SortOrder>;
  transactionId?: InputMaybe<SortOrder>;
  updatedAt?: InputMaybe<SortOrder>;
  webhookResponseBody?: InputMaybe<SortOrder>;
};

export type PaymentWebhookCreateInput = {
  createdAt?: InputMaybe<Scalars['DateTime']>;
  id?: InputMaybe<Scalars['String']>;
  paymentGateway?: InputMaybe<PaymentGateway>;
  paymentRefNumber: Scalars['String'];
  status?: InputMaybe<PaymentStatus>;
  transaction: TransactionCreateNestedOneWithoutPaymentWebhookInput;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
  webhookResponseBody: Scalars['JSON'];
};

export type PaymentWebhookCreateManyInput = {
  createdAt?: InputMaybe<Scalars['DateTime']>;
  id?: InputMaybe<Scalars['String']>;
  paymentGateway?: InputMaybe<PaymentGateway>;
  paymentRefNumber: Scalars['String'];
  status?: InputMaybe<PaymentStatus>;
  transactionId: Scalars['String'];
  updatedAt?: InputMaybe<Scalars['DateTime']>;
  webhookResponseBody: Scalars['JSON'];
};

export type PaymentWebhookCreateManyTransactionInput = {
  createdAt?: InputMaybe<Scalars['DateTime']>;
  id?: InputMaybe<Scalars['String']>;
  paymentGateway?: InputMaybe<PaymentGateway>;
  paymentRefNumber: Scalars['String'];
  status?: InputMaybe<PaymentStatus>;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
  webhookResponseBody: Scalars['JSON'];
};

export type PaymentWebhookCreateManyTransactionInputEnvelope = {
  data: Array<PaymentWebhookCreateManyTransactionInput>;
  skipDuplicates?: InputMaybe<Scalars['Boolean']>;
};

export type PaymentWebhookCreateNestedManyWithoutTransactionInput = {
  connect?: InputMaybe<Array<PaymentWebhookWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<PaymentWebhookCreateOrConnectWithoutTransactionInput>>;
  create?: InputMaybe<Array<PaymentWebhookCreateWithoutTransactionInput>>;
  createMany?: InputMaybe<PaymentWebhookCreateManyTransactionInputEnvelope>;
};

export type PaymentWebhookCreateOrConnectWithoutTransactionInput = {
  create: PaymentWebhookCreateWithoutTransactionInput;
  where: PaymentWebhookWhereUniqueInput;
};

export type PaymentWebhookCreateWithoutTransactionInput = {
  createdAt?: InputMaybe<Scalars['DateTime']>;
  id?: InputMaybe<Scalars['String']>;
  paymentGateway?: InputMaybe<PaymentGateway>;
  paymentRefNumber: Scalars['String'];
  status?: InputMaybe<PaymentStatus>;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
  webhookResponseBody: Scalars['JSON'];
};

export type PaymentWebhookGroupBy = {
  __typename?: 'PaymentWebhookGroupBy';
  _count?: Maybe<PaymentWebhookCountAggregate>;
  _max?: Maybe<PaymentWebhookMaxAggregate>;
  _min?: Maybe<PaymentWebhookMinAggregate>;
  createdAt: Scalars['DateTime'];
  id: Scalars['String'];
  paymentGateway: PaymentGateway;
  paymentRefNumber: Scalars['String'];
  status: PaymentStatus;
  transactionId: Scalars['String'];
  updatedAt: Scalars['DateTime'];
  webhookResponseBody: Scalars['JSON'];
};

export type PaymentWebhookListRelationFilter = {
  every?: InputMaybe<PaymentWebhookWhereInput>;
  none?: InputMaybe<PaymentWebhookWhereInput>;
  some?: InputMaybe<PaymentWebhookWhereInput>;
};

export type PaymentWebhookMaxAggregate = {
  __typename?: 'PaymentWebhookMaxAggregate';
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  paymentGateway?: Maybe<PaymentGateway>;
  paymentRefNumber?: Maybe<Scalars['String']>;
  status?: Maybe<PaymentStatus>;
  transactionId?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type PaymentWebhookMaxOrderByAggregateInput = {
  createdAt?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  paymentGateway?: InputMaybe<SortOrder>;
  paymentRefNumber?: InputMaybe<SortOrder>;
  status?: InputMaybe<SortOrder>;
  transactionId?: InputMaybe<SortOrder>;
  updatedAt?: InputMaybe<SortOrder>;
};

export type PaymentWebhookMinAggregate = {
  __typename?: 'PaymentWebhookMinAggregate';
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  paymentGateway?: Maybe<PaymentGateway>;
  paymentRefNumber?: Maybe<Scalars['String']>;
  status?: Maybe<PaymentStatus>;
  transactionId?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type PaymentWebhookMinOrderByAggregateInput = {
  createdAt?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  paymentGateway?: InputMaybe<SortOrder>;
  paymentRefNumber?: InputMaybe<SortOrder>;
  status?: InputMaybe<SortOrder>;
  transactionId?: InputMaybe<SortOrder>;
  updatedAt?: InputMaybe<SortOrder>;
};

export type PaymentWebhookOrderByRelationAggregateInput = {
  _count?: InputMaybe<SortOrder>;
};

export type PaymentWebhookOrderByWithAggregationInput = {
  _count?: InputMaybe<PaymentWebhookCountOrderByAggregateInput>;
  _max?: InputMaybe<PaymentWebhookMaxOrderByAggregateInput>;
  _min?: InputMaybe<PaymentWebhookMinOrderByAggregateInput>;
  createdAt?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  paymentGateway?: InputMaybe<SortOrder>;
  paymentRefNumber?: InputMaybe<SortOrder>;
  status?: InputMaybe<SortOrder>;
  transactionId?: InputMaybe<SortOrder>;
  updatedAt?: InputMaybe<SortOrder>;
  webhookResponseBody?: InputMaybe<SortOrder>;
};

export type PaymentWebhookOrderByWithRelationInput = {
  createdAt?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  paymentGateway?: InputMaybe<SortOrder>;
  paymentRefNumber?: InputMaybe<SortOrder>;
  status?: InputMaybe<SortOrder>;
  transaction?: InputMaybe<TransactionOrderByWithRelationInput>;
  transactionId?: InputMaybe<SortOrder>;
  updatedAt?: InputMaybe<SortOrder>;
  webhookResponseBody?: InputMaybe<SortOrder>;
};

export enum PaymentWebhookScalarFieldEnum {
  CreatedAt = 'createdAt',
  Id = 'id',
  PaymentGateway = 'paymentGateway',
  PaymentRefNumber = 'paymentRefNumber',
  Status = 'status',
  TransactionId = 'transactionId',
  UpdatedAt = 'updatedAt',
  WebhookResponseBody = 'webhookResponseBody'
}

export type PaymentWebhookScalarWhereInput = {
  AND?: InputMaybe<Array<PaymentWebhookScalarWhereInput>>;
  NOT?: InputMaybe<Array<PaymentWebhookScalarWhereInput>>;
  OR?: InputMaybe<Array<PaymentWebhookScalarWhereInput>>;
  createdAt?: InputMaybe<DateTimeFilter>;
  id?: InputMaybe<StringFilter>;
  paymentGateway?: InputMaybe<EnumPaymentGatewayFilter>;
  paymentRefNumber?: InputMaybe<StringFilter>;
  status?: InputMaybe<EnumPaymentStatusFilter>;
  transactionId?: InputMaybe<StringFilter>;
  updatedAt?: InputMaybe<DateTimeFilter>;
  webhookResponseBody?: InputMaybe<JsonFilter>;
};

export type PaymentWebhookScalarWhereWithAggregatesInput = {
  AND?: InputMaybe<Array<PaymentWebhookScalarWhereWithAggregatesInput>>;
  NOT?: InputMaybe<Array<PaymentWebhookScalarWhereWithAggregatesInput>>;
  OR?: InputMaybe<Array<PaymentWebhookScalarWhereWithAggregatesInput>>;
  createdAt?: InputMaybe<DateTimeWithAggregatesFilter>;
  id?: InputMaybe<StringWithAggregatesFilter>;
  paymentGateway?: InputMaybe<EnumPaymentGatewayWithAggregatesFilter>;
  paymentRefNumber?: InputMaybe<StringWithAggregatesFilter>;
  status?: InputMaybe<EnumPaymentStatusWithAggregatesFilter>;
  transactionId?: InputMaybe<StringWithAggregatesFilter>;
  updatedAt?: InputMaybe<DateTimeWithAggregatesFilter>;
  webhookResponseBody?: InputMaybe<JsonWithAggregatesFilter>;
};

export type PaymentWebhookUpdateInput = {
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  paymentGateway?: InputMaybe<EnumPaymentGatewayFieldUpdateOperationsInput>;
  paymentRefNumber?: InputMaybe<StringFieldUpdateOperationsInput>;
  status?: InputMaybe<EnumPaymentStatusFieldUpdateOperationsInput>;
  transaction?: InputMaybe<TransactionUpdateOneRequiredWithoutPaymentWebhookInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  webhookResponseBody?: InputMaybe<Scalars['JSON']>;
};

export type PaymentWebhookUpdateManyMutationInput = {
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  paymentGateway?: InputMaybe<EnumPaymentGatewayFieldUpdateOperationsInput>;
  paymentRefNumber?: InputMaybe<StringFieldUpdateOperationsInput>;
  status?: InputMaybe<EnumPaymentStatusFieldUpdateOperationsInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  webhookResponseBody?: InputMaybe<Scalars['JSON']>;
};

export type PaymentWebhookUpdateManyWithWhereWithoutTransactionInput = {
  data: PaymentWebhookUpdateManyMutationInput;
  where: PaymentWebhookScalarWhereInput;
};

export type PaymentWebhookUpdateManyWithoutTransactionInput = {
  connect?: InputMaybe<Array<PaymentWebhookWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<PaymentWebhookCreateOrConnectWithoutTransactionInput>>;
  create?: InputMaybe<Array<PaymentWebhookCreateWithoutTransactionInput>>;
  createMany?: InputMaybe<PaymentWebhookCreateManyTransactionInputEnvelope>;
  delete?: InputMaybe<Array<PaymentWebhookWhereUniqueInput>>;
  deleteMany?: InputMaybe<Array<PaymentWebhookScalarWhereInput>>;
  disconnect?: InputMaybe<Array<PaymentWebhookWhereUniqueInput>>;
  set?: InputMaybe<Array<PaymentWebhookWhereUniqueInput>>;
  update?: InputMaybe<Array<PaymentWebhookUpdateWithWhereUniqueWithoutTransactionInput>>;
  updateMany?: InputMaybe<Array<PaymentWebhookUpdateManyWithWhereWithoutTransactionInput>>;
  upsert?: InputMaybe<Array<PaymentWebhookUpsertWithWhereUniqueWithoutTransactionInput>>;
};

export type PaymentWebhookUpdateWithWhereUniqueWithoutTransactionInput = {
  data: PaymentWebhookUpdateWithoutTransactionInput;
  where: PaymentWebhookWhereUniqueInput;
};

export type PaymentWebhookUpdateWithoutTransactionInput = {
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  paymentGateway?: InputMaybe<EnumPaymentGatewayFieldUpdateOperationsInput>;
  paymentRefNumber?: InputMaybe<StringFieldUpdateOperationsInput>;
  status?: InputMaybe<EnumPaymentStatusFieldUpdateOperationsInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  webhookResponseBody?: InputMaybe<Scalars['JSON']>;
};

export type PaymentWebhookUpsertWithWhereUniqueWithoutTransactionInput = {
  create: PaymentWebhookCreateWithoutTransactionInput;
  update: PaymentWebhookUpdateWithoutTransactionInput;
  where: PaymentWebhookWhereUniqueInput;
};

export type PaymentWebhookWhereInput = {
  AND?: InputMaybe<Array<PaymentWebhookWhereInput>>;
  NOT?: InputMaybe<Array<PaymentWebhookWhereInput>>;
  OR?: InputMaybe<Array<PaymentWebhookWhereInput>>;
  createdAt?: InputMaybe<DateTimeFilter>;
  id?: InputMaybe<StringFilter>;
  paymentGateway?: InputMaybe<EnumPaymentGatewayFilter>;
  paymentRefNumber?: InputMaybe<StringFilter>;
  status?: InputMaybe<EnumPaymentStatusFilter>;
  transaction?: InputMaybe<TransactionRelationFilter>;
  transactionId?: InputMaybe<StringFilter>;
  updatedAt?: InputMaybe<DateTimeFilter>;
  webhookResponseBody?: InputMaybe<JsonFilter>;
};

export type PaymentWebhookWhereUniqueInput = {
  id?: InputMaybe<Scalars['String']>;
};

export type Playlist = {
  __typename?: 'Playlist';
  _count?: Maybe<PlaylistCount>;
  archivedAt?: Maybe<Scalars['DateTime']>;
  channel: Channel;
  channelId: Scalars['String'];
  createdAt: Scalars['DateTime'];
  description?: Maybe<Scalars['String']>;
  id: Scalars['String'];
  name: Scalars['String'];
  playlistVideos: Array<PlaylistVideo>;
  thumbnailUrl: Scalars['String'];
  updatedAt: Scalars['DateTime'];
};


export type PlaylistPlaylistVideosArgs = {
  cursor?: InputMaybe<PlaylistVideoWhereUniqueInput>;
  distinct?: InputMaybe<Array<PlaylistVideoScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<PlaylistVideoOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<PlaylistVideoWhereInput>;
};

export type PlaylistCount = {
  __typename?: 'PlaylistCount';
  playlistVideos: Scalars['Int'];
};

export type PlaylistCountAggregate = {
  __typename?: 'PlaylistCountAggregate';
  _all: Scalars['Int'];
  archivedAt: Scalars['Int'];
  channelId: Scalars['Int'];
  createdAt: Scalars['Int'];
  description: Scalars['Int'];
  id: Scalars['Int'];
  name: Scalars['Int'];
  thumbnailUrl: Scalars['Int'];
  updatedAt: Scalars['Int'];
};

export type PlaylistCountOrderByAggregateInput = {
  archivedAt?: InputMaybe<SortOrder>;
  channelId?: InputMaybe<SortOrder>;
  createdAt?: InputMaybe<SortOrder>;
  description?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  name?: InputMaybe<SortOrder>;
  thumbnailUrl?: InputMaybe<SortOrder>;
  updatedAt?: InputMaybe<SortOrder>;
};

export type PlaylistCreateInput = {
  archivedAt?: InputMaybe<Scalars['DateTime']>;
  channel: ChannelCreateNestedOneWithoutPlaylistsInput;
  createdAt?: InputMaybe<Scalars['DateTime']>;
  description?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['String']>;
  name: Scalars['String'];
  playlistVideos?: InputMaybe<PlaylistVideoCreateNestedManyWithoutPlaylistInput>;
  thumbnailUrl?: InputMaybe<Scalars['String']>;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
};

export type PlaylistCreateManyChannelInput = {
  archivedAt?: InputMaybe<Scalars['DateTime']>;
  createdAt?: InputMaybe<Scalars['DateTime']>;
  description?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['String']>;
  name: Scalars['String'];
  thumbnailUrl?: InputMaybe<Scalars['String']>;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
};

export type PlaylistCreateManyChannelInputEnvelope = {
  data: Array<PlaylistCreateManyChannelInput>;
  skipDuplicates?: InputMaybe<Scalars['Boolean']>;
};

export type PlaylistCreateManyInput = {
  archivedAt?: InputMaybe<Scalars['DateTime']>;
  channelId: Scalars['String'];
  createdAt?: InputMaybe<Scalars['DateTime']>;
  description?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['String']>;
  name: Scalars['String'];
  thumbnailUrl?: InputMaybe<Scalars['String']>;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
};

export type PlaylistCreateNestedManyWithoutChannelInput = {
  connect?: InputMaybe<Array<PlaylistWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<PlaylistCreateOrConnectWithoutChannelInput>>;
  create?: InputMaybe<Array<PlaylistCreateWithoutChannelInput>>;
  createMany?: InputMaybe<PlaylistCreateManyChannelInputEnvelope>;
};

export type PlaylistCreateNestedOneWithoutPlaylistVideosInput = {
  connect?: InputMaybe<PlaylistWhereUniqueInput>;
  connectOrCreate?: InputMaybe<PlaylistCreateOrConnectWithoutPlaylistVideosInput>;
  create?: InputMaybe<PlaylistCreateWithoutPlaylistVideosInput>;
};

export type PlaylistCreateOrConnectWithoutChannelInput = {
  create: PlaylistCreateWithoutChannelInput;
  where: PlaylistWhereUniqueInput;
};

export type PlaylistCreateOrConnectWithoutPlaylistVideosInput = {
  create: PlaylistCreateWithoutPlaylistVideosInput;
  where: PlaylistWhereUniqueInput;
};

export type PlaylistCreateWithoutChannelInput = {
  archivedAt?: InputMaybe<Scalars['DateTime']>;
  createdAt?: InputMaybe<Scalars['DateTime']>;
  description?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['String']>;
  name: Scalars['String'];
  playlistVideos?: InputMaybe<PlaylistVideoCreateNestedManyWithoutPlaylistInput>;
  thumbnailUrl?: InputMaybe<Scalars['String']>;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
};

export type PlaylistCreateWithoutPlaylistVideosInput = {
  archivedAt?: InputMaybe<Scalars['DateTime']>;
  channel: ChannelCreateNestedOneWithoutPlaylistsInput;
  createdAt?: InputMaybe<Scalars['DateTime']>;
  description?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['String']>;
  name: Scalars['String'];
  thumbnailUrl?: InputMaybe<Scalars['String']>;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
};

export type PlaylistGroupBy = {
  __typename?: 'PlaylistGroupBy';
  _count?: Maybe<PlaylistCountAggregate>;
  _max?: Maybe<PlaylistMaxAggregate>;
  _min?: Maybe<PlaylistMinAggregate>;
  archivedAt?: Maybe<Scalars['DateTime']>;
  channelId: Scalars['String'];
  createdAt: Scalars['DateTime'];
  description?: Maybe<Scalars['String']>;
  id: Scalars['String'];
  name: Scalars['String'];
  thumbnailUrl?: Maybe<Scalars['String']>;
  updatedAt: Scalars['DateTime'];
};

export type PlaylistIdFilter = {
  in?: InputMaybe<Array<Scalars['String']>>;
};

export type PlaylistListRelationFilter = {
  every?: InputMaybe<PlaylistWhereInput>;
  none?: InputMaybe<PlaylistWhereInput>;
  some?: InputMaybe<PlaylistWhereInput>;
};

export type PlaylistMaxAggregate = {
  __typename?: 'PlaylistMaxAggregate';
  archivedAt?: Maybe<Scalars['DateTime']>;
  channelId?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  description?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  thumbnailUrl?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type PlaylistMaxOrderByAggregateInput = {
  archivedAt?: InputMaybe<SortOrder>;
  channelId?: InputMaybe<SortOrder>;
  createdAt?: InputMaybe<SortOrder>;
  description?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  name?: InputMaybe<SortOrder>;
  thumbnailUrl?: InputMaybe<SortOrder>;
  updatedAt?: InputMaybe<SortOrder>;
};

export type PlaylistMinAggregate = {
  __typename?: 'PlaylistMinAggregate';
  archivedAt?: Maybe<Scalars['DateTime']>;
  channelId?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  description?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  thumbnailUrl?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type PlaylistMinOrderByAggregateInput = {
  archivedAt?: InputMaybe<SortOrder>;
  channelId?: InputMaybe<SortOrder>;
  createdAt?: InputMaybe<SortOrder>;
  description?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  name?: InputMaybe<SortOrder>;
  thumbnailUrl?: InputMaybe<SortOrder>;
  updatedAt?: InputMaybe<SortOrder>;
};

export type PlaylistOrderByRelationAggregateInput = {
  _count?: InputMaybe<SortOrder>;
};

export type PlaylistOrderByWithAggregationInput = {
  _count?: InputMaybe<PlaylistCountOrderByAggregateInput>;
  _max?: InputMaybe<PlaylistMaxOrderByAggregateInput>;
  _min?: InputMaybe<PlaylistMinOrderByAggregateInput>;
  archivedAt?: InputMaybe<SortOrder>;
  channelId?: InputMaybe<SortOrder>;
  createdAt?: InputMaybe<SortOrder>;
  description?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  name?: InputMaybe<SortOrder>;
  thumbnailUrl?: InputMaybe<SortOrder>;
  updatedAt?: InputMaybe<SortOrder>;
};

export type PlaylistOrderByWithRelationInput = {
  archivedAt?: InputMaybe<SortOrder>;
  channel?: InputMaybe<ChannelOrderByWithRelationInput>;
  channelId?: InputMaybe<SortOrder>;
  createdAt?: InputMaybe<SortOrder>;
  description?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  name?: InputMaybe<SortOrder>;
  playlistVideos?: InputMaybe<PlaylistVideoOrderByRelationAggregateInput>;
  thumbnailUrl?: InputMaybe<SortOrder>;
  updatedAt?: InputMaybe<SortOrder>;
};

export type PlaylistRelationFilter = {
  is?: InputMaybe<PlaylistWhereInput>;
  isNot?: InputMaybe<PlaylistWhereInput>;
};

export enum PlaylistScalarFieldEnum {
  ArchivedAt = 'archivedAt',
  ChannelId = 'channelId',
  CreatedAt = 'createdAt',
  Description = 'description',
  Id = 'id',
  Name = 'name',
  ThumbnailUrl = 'thumbnailUrl',
  UpdatedAt = 'updatedAt'
}

export type PlaylistScalarWhereInput = {
  AND?: InputMaybe<Array<PlaylistScalarWhereInput>>;
  NOT?: InputMaybe<Array<PlaylistScalarWhereInput>>;
  OR?: InputMaybe<Array<PlaylistScalarWhereInput>>;
  archivedAt?: InputMaybe<DateTimeNullableFilter>;
  channelId?: InputMaybe<StringFilter>;
  createdAt?: InputMaybe<DateTimeFilter>;
  description?: InputMaybe<StringNullableFilter>;
  id?: InputMaybe<StringFilter>;
  name?: InputMaybe<StringFilter>;
  thumbnailUrl?: InputMaybe<StringNullableFilter>;
  updatedAt?: InputMaybe<DateTimeFilter>;
};

export type PlaylistScalarWhereWithAggregatesInput = {
  AND?: InputMaybe<Array<PlaylistScalarWhereWithAggregatesInput>>;
  NOT?: InputMaybe<Array<PlaylistScalarWhereWithAggregatesInput>>;
  OR?: InputMaybe<Array<PlaylistScalarWhereWithAggregatesInput>>;
  archivedAt?: InputMaybe<DateTimeNullableWithAggregatesFilter>;
  channelId?: InputMaybe<StringWithAggregatesFilter>;
  createdAt?: InputMaybe<DateTimeWithAggregatesFilter>;
  description?: InputMaybe<StringNullableWithAggregatesFilter>;
  id?: InputMaybe<StringWithAggregatesFilter>;
  name?: InputMaybe<StringWithAggregatesFilter>;
  thumbnailUrl?: InputMaybe<StringNullableWithAggregatesFilter>;
  updatedAt?: InputMaybe<DateTimeWithAggregatesFilter>;
};

export type PlaylistUpdateInput = {
  archivedAt?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  channel?: InputMaybe<ChannelUpdateOneRequiredWithoutPlaylistsInput>;
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  description?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  name?: InputMaybe<StringFieldUpdateOperationsInput>;
  playlistVideos?: InputMaybe<PlaylistVideoUpdateManyWithoutPlaylistInput>;
  thumbnailUrl?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
};

export type PlaylistUpdateManyMutationInput = {
  archivedAt?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  description?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  name?: InputMaybe<StringFieldUpdateOperationsInput>;
  thumbnailUrl?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
};

export type PlaylistUpdateManyWithWhereWithoutChannelInput = {
  data: PlaylistUpdateManyMutationInput;
  where: PlaylistScalarWhereInput;
};

export type PlaylistUpdateManyWithoutChannelInput = {
  connect?: InputMaybe<Array<PlaylistWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<PlaylistCreateOrConnectWithoutChannelInput>>;
  create?: InputMaybe<Array<PlaylistCreateWithoutChannelInput>>;
  createMany?: InputMaybe<PlaylistCreateManyChannelInputEnvelope>;
  delete?: InputMaybe<Array<PlaylistWhereUniqueInput>>;
  deleteMany?: InputMaybe<Array<PlaylistScalarWhereInput>>;
  disconnect?: InputMaybe<Array<PlaylistWhereUniqueInput>>;
  set?: InputMaybe<Array<PlaylistWhereUniqueInput>>;
  update?: InputMaybe<Array<PlaylistUpdateWithWhereUniqueWithoutChannelInput>>;
  updateMany?: InputMaybe<Array<PlaylistUpdateManyWithWhereWithoutChannelInput>>;
  upsert?: InputMaybe<Array<PlaylistUpsertWithWhereUniqueWithoutChannelInput>>;
};

export type PlaylistUpdateOneRequiredWithoutPlaylistVideosInput = {
  connect?: InputMaybe<PlaylistWhereUniqueInput>;
  connectOrCreate?: InputMaybe<PlaylistCreateOrConnectWithoutPlaylistVideosInput>;
  create?: InputMaybe<PlaylistCreateWithoutPlaylistVideosInput>;
  update?: InputMaybe<PlaylistUpdateWithoutPlaylistVideosInput>;
  upsert?: InputMaybe<PlaylistUpsertWithoutPlaylistVideosInput>;
};

export type PlaylistUpdateWithWhereUniqueWithoutChannelInput = {
  data: PlaylistUpdateWithoutChannelInput;
  where: PlaylistWhereUniqueInput;
};

export type PlaylistUpdateWithoutChannelInput = {
  archivedAt?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  description?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  name?: InputMaybe<StringFieldUpdateOperationsInput>;
  playlistVideos?: InputMaybe<PlaylistVideoUpdateManyWithoutPlaylistInput>;
  thumbnailUrl?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
};

export type PlaylistUpdateWithoutPlaylistVideosInput = {
  archivedAt?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  channel?: InputMaybe<ChannelUpdateOneRequiredWithoutPlaylistsInput>;
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  description?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  name?: InputMaybe<StringFieldUpdateOperationsInput>;
  thumbnailUrl?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
};

export type PlaylistUpsertWithWhereUniqueWithoutChannelInput = {
  create: PlaylistCreateWithoutChannelInput;
  update: PlaylistUpdateWithoutChannelInput;
  where: PlaylistWhereUniqueInput;
};

export type PlaylistUpsertWithoutPlaylistVideosInput = {
  create: PlaylistCreateWithoutPlaylistVideosInput;
  update: PlaylistUpdateWithoutPlaylistVideosInput;
};

export type PlaylistVideo = {
  __typename?: 'PlaylistVideo';
  archivedAt?: Maybe<Scalars['DateTime']>;
  createdAt: Scalars['DateTime'];
  playlist: Playlist;
  playlistId: Scalars['String'];
  updatedAt: Scalars['DateTime'];
  video: Video;
  videoId: Scalars['String'];
};

export type PlaylistVideoCountAggregate = {
  __typename?: 'PlaylistVideoCountAggregate';
  _all: Scalars['Int'];
  archivedAt: Scalars['Int'];
  createdAt: Scalars['Int'];
  playlistId: Scalars['Int'];
  updatedAt: Scalars['Int'];
  videoId: Scalars['Int'];
};

export type PlaylistVideoCountOrderByAggregateInput = {
  archivedAt?: InputMaybe<SortOrder>;
  createdAt?: InputMaybe<SortOrder>;
  playlistId?: InputMaybe<SortOrder>;
  updatedAt?: InputMaybe<SortOrder>;
  videoId?: InputMaybe<SortOrder>;
};

export type PlaylistVideoCreateInput = {
  archivedAt?: InputMaybe<Scalars['DateTime']>;
  createdAt?: InputMaybe<Scalars['DateTime']>;
  playlist: PlaylistCreateNestedOneWithoutPlaylistVideosInput;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
  video: VideoCreateNestedOneWithoutPlaylistVideosInput;
};

export type PlaylistVideoCreateManyInput = {
  archivedAt?: InputMaybe<Scalars['DateTime']>;
  createdAt?: InputMaybe<Scalars['DateTime']>;
  playlistId: Scalars['String'];
  updatedAt?: InputMaybe<Scalars['DateTime']>;
  videoId: Scalars['String'];
};

export type PlaylistVideoCreateManyPlaylistInput = {
  archivedAt?: InputMaybe<Scalars['DateTime']>;
  createdAt?: InputMaybe<Scalars['DateTime']>;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
  videoId: Scalars['String'];
};

export type PlaylistVideoCreateManyPlaylistInputEnvelope = {
  data: Array<PlaylistVideoCreateManyPlaylistInput>;
  skipDuplicates?: InputMaybe<Scalars['Boolean']>;
};

export type PlaylistVideoCreateManyVideoInput = {
  archivedAt?: InputMaybe<Scalars['DateTime']>;
  createdAt?: InputMaybe<Scalars['DateTime']>;
  playlistId: Scalars['String'];
  updatedAt?: InputMaybe<Scalars['DateTime']>;
};

export type PlaylistVideoCreateManyVideoInputEnvelope = {
  data: Array<PlaylistVideoCreateManyVideoInput>;
  skipDuplicates?: InputMaybe<Scalars['Boolean']>;
};

export type PlaylistVideoCreateNestedManyWithoutPlaylistInput = {
  connect?: InputMaybe<Array<PlaylistVideoWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<PlaylistVideoCreateOrConnectWithoutPlaylistInput>>;
  create?: InputMaybe<Array<PlaylistVideoCreateWithoutPlaylistInput>>;
  createMany?: InputMaybe<PlaylistVideoCreateManyPlaylistInputEnvelope>;
};

export type PlaylistVideoCreateNestedManyWithoutVideoInput = {
  connect?: InputMaybe<Array<PlaylistVideoWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<PlaylistVideoCreateOrConnectWithoutVideoInput>>;
  create?: InputMaybe<Array<PlaylistVideoCreateWithoutVideoInput>>;
  createMany?: InputMaybe<PlaylistVideoCreateManyVideoInputEnvelope>;
};

export type PlaylistVideoCreateOrConnectWithoutPlaylistInput = {
  create: PlaylistVideoCreateWithoutPlaylistInput;
  where: PlaylistVideoWhereUniqueInput;
};

export type PlaylistVideoCreateOrConnectWithoutVideoInput = {
  create: PlaylistVideoCreateWithoutVideoInput;
  where: PlaylistVideoWhereUniqueInput;
};

export type PlaylistVideoCreateWithoutPlaylistInput = {
  archivedAt?: InputMaybe<Scalars['DateTime']>;
  createdAt?: InputMaybe<Scalars['DateTime']>;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
  video: VideoCreateNestedOneWithoutPlaylistVideosInput;
};

export type PlaylistVideoCreateWithoutVideoInput = {
  archivedAt?: InputMaybe<Scalars['DateTime']>;
  createdAt?: InputMaybe<Scalars['DateTime']>;
  playlist: PlaylistCreateNestedOneWithoutPlaylistVideosInput;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
};

export type PlaylistVideoGroupBy = {
  __typename?: 'PlaylistVideoGroupBy';
  _count?: Maybe<PlaylistVideoCountAggregate>;
  _max?: Maybe<PlaylistVideoMaxAggregate>;
  _min?: Maybe<PlaylistVideoMinAggregate>;
  archivedAt?: Maybe<Scalars['DateTime']>;
  createdAt: Scalars['DateTime'];
  playlistId: Scalars['String'];
  updatedAt: Scalars['DateTime'];
  videoId: Scalars['String'];
};

export type PlaylistVideoListRelationFilter = {
  every?: InputMaybe<PlaylistVideoWhereInput>;
  none?: InputMaybe<PlaylistVideoWhereInput>;
  some?: InputMaybe<PlaylistVideoWhereInput>;
};

export type PlaylistVideoMaxAggregate = {
  __typename?: 'PlaylistVideoMaxAggregate';
  archivedAt?: Maybe<Scalars['DateTime']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  playlistId?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  videoId?: Maybe<Scalars['String']>;
};

export type PlaylistVideoMaxOrderByAggregateInput = {
  archivedAt?: InputMaybe<SortOrder>;
  createdAt?: InputMaybe<SortOrder>;
  playlistId?: InputMaybe<SortOrder>;
  updatedAt?: InputMaybe<SortOrder>;
  videoId?: InputMaybe<SortOrder>;
};

export type PlaylistVideoMinAggregate = {
  __typename?: 'PlaylistVideoMinAggregate';
  archivedAt?: Maybe<Scalars['DateTime']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  playlistId?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  videoId?: Maybe<Scalars['String']>;
};

export type PlaylistVideoMinOrderByAggregateInput = {
  archivedAt?: InputMaybe<SortOrder>;
  createdAt?: InputMaybe<SortOrder>;
  playlistId?: InputMaybe<SortOrder>;
  updatedAt?: InputMaybe<SortOrder>;
  videoId?: InputMaybe<SortOrder>;
};

export type PlaylistVideoOrderByRelationAggregateInput = {
  _count?: InputMaybe<SortOrder>;
};

export type PlaylistVideoOrderByWithAggregationInput = {
  _count?: InputMaybe<PlaylistVideoCountOrderByAggregateInput>;
  _max?: InputMaybe<PlaylistVideoMaxOrderByAggregateInput>;
  _min?: InputMaybe<PlaylistVideoMinOrderByAggregateInput>;
  archivedAt?: InputMaybe<SortOrder>;
  createdAt?: InputMaybe<SortOrder>;
  playlistId?: InputMaybe<SortOrder>;
  updatedAt?: InputMaybe<SortOrder>;
  videoId?: InputMaybe<SortOrder>;
};

export type PlaylistVideoOrderByWithRelationInput = {
  archivedAt?: InputMaybe<SortOrder>;
  createdAt?: InputMaybe<SortOrder>;
  playlist?: InputMaybe<PlaylistOrderByWithRelationInput>;
  playlistId?: InputMaybe<SortOrder>;
  updatedAt?: InputMaybe<SortOrder>;
  video?: InputMaybe<VideoOrderByWithRelationInput>;
  videoId?: InputMaybe<SortOrder>;
};

export enum PlaylistVideoScalarFieldEnum {
  ArchivedAt = 'archivedAt',
  CreatedAt = 'createdAt',
  PlaylistId = 'playlistId',
  UpdatedAt = 'updatedAt',
  VideoId = 'videoId'
}

export type PlaylistVideoScalarWhereInput = {
  AND?: InputMaybe<Array<PlaylistVideoScalarWhereInput>>;
  NOT?: InputMaybe<Array<PlaylistVideoScalarWhereInput>>;
  OR?: InputMaybe<Array<PlaylistVideoScalarWhereInput>>;
  archivedAt?: InputMaybe<DateTimeNullableFilter>;
  createdAt?: InputMaybe<DateTimeFilter>;
  playlistId?: InputMaybe<StringFilter>;
  updatedAt?: InputMaybe<DateTimeFilter>;
  videoId?: InputMaybe<StringFilter>;
};

export type PlaylistVideoScalarWhereWithAggregatesInput = {
  AND?: InputMaybe<Array<PlaylistVideoScalarWhereWithAggregatesInput>>;
  NOT?: InputMaybe<Array<PlaylistVideoScalarWhereWithAggregatesInput>>;
  OR?: InputMaybe<Array<PlaylistVideoScalarWhereWithAggregatesInput>>;
  archivedAt?: InputMaybe<DateTimeNullableWithAggregatesFilter>;
  createdAt?: InputMaybe<DateTimeWithAggregatesFilter>;
  playlistId?: InputMaybe<StringWithAggregatesFilter>;
  updatedAt?: InputMaybe<DateTimeWithAggregatesFilter>;
  videoId?: InputMaybe<StringWithAggregatesFilter>;
};

export type PlaylistVideoUpdateInput = {
  archivedAt?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  playlist?: InputMaybe<PlaylistUpdateOneRequiredWithoutPlaylistVideosInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  video?: InputMaybe<VideoUpdateOneRequiredWithoutPlaylistVideosInput>;
};

export type PlaylistVideoUpdateManyMutationInput = {
  archivedAt?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
};

export type PlaylistVideoUpdateManyWithWhereWithoutPlaylistInput = {
  data: PlaylistVideoUpdateManyMutationInput;
  where: PlaylistVideoScalarWhereInput;
};

export type PlaylistVideoUpdateManyWithWhereWithoutVideoInput = {
  data: PlaylistVideoUpdateManyMutationInput;
  where: PlaylistVideoScalarWhereInput;
};

export type PlaylistVideoUpdateManyWithoutPlaylistInput = {
  connect?: InputMaybe<Array<PlaylistVideoWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<PlaylistVideoCreateOrConnectWithoutPlaylistInput>>;
  create?: InputMaybe<Array<PlaylistVideoCreateWithoutPlaylistInput>>;
  createMany?: InputMaybe<PlaylistVideoCreateManyPlaylistInputEnvelope>;
  delete?: InputMaybe<Array<PlaylistVideoWhereUniqueInput>>;
  deleteMany?: InputMaybe<Array<PlaylistVideoScalarWhereInput>>;
  disconnect?: InputMaybe<Array<PlaylistVideoWhereUniqueInput>>;
  set?: InputMaybe<Array<PlaylistVideoWhereUniqueInput>>;
  update?: InputMaybe<Array<PlaylistVideoUpdateWithWhereUniqueWithoutPlaylistInput>>;
  updateMany?: InputMaybe<Array<PlaylistVideoUpdateManyWithWhereWithoutPlaylistInput>>;
  upsert?: InputMaybe<Array<PlaylistVideoUpsertWithWhereUniqueWithoutPlaylistInput>>;
};

export type PlaylistVideoUpdateManyWithoutVideoInput = {
  connect?: InputMaybe<Array<PlaylistVideoWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<PlaylistVideoCreateOrConnectWithoutVideoInput>>;
  create?: InputMaybe<Array<PlaylistVideoCreateWithoutVideoInput>>;
  createMany?: InputMaybe<PlaylistVideoCreateManyVideoInputEnvelope>;
  delete?: InputMaybe<Array<PlaylistVideoWhereUniqueInput>>;
  deleteMany?: InputMaybe<Array<PlaylistVideoScalarWhereInput>>;
  disconnect?: InputMaybe<Array<PlaylistVideoWhereUniqueInput>>;
  set?: InputMaybe<Array<PlaylistVideoWhereUniqueInput>>;
  update?: InputMaybe<Array<PlaylistVideoUpdateWithWhereUniqueWithoutVideoInput>>;
  updateMany?: InputMaybe<Array<PlaylistVideoUpdateManyWithWhereWithoutVideoInput>>;
  upsert?: InputMaybe<Array<PlaylistVideoUpsertWithWhereUniqueWithoutVideoInput>>;
};

export type PlaylistVideoUpdateWithWhereUniqueWithoutPlaylistInput = {
  data: PlaylistVideoUpdateWithoutPlaylistInput;
  where: PlaylistVideoWhereUniqueInput;
};

export type PlaylistVideoUpdateWithWhereUniqueWithoutVideoInput = {
  data: PlaylistVideoUpdateWithoutVideoInput;
  where: PlaylistVideoWhereUniqueInput;
};

export type PlaylistVideoUpdateWithoutPlaylistInput = {
  archivedAt?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  video?: InputMaybe<VideoUpdateOneRequiredWithoutPlaylistVideosInput>;
};

export type PlaylistVideoUpdateWithoutVideoInput = {
  archivedAt?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  playlist?: InputMaybe<PlaylistUpdateOneRequiredWithoutPlaylistVideosInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
};

export type PlaylistVideoUpsertWithWhereUniqueWithoutPlaylistInput = {
  create: PlaylistVideoCreateWithoutPlaylistInput;
  update: PlaylistVideoUpdateWithoutPlaylistInput;
  where: PlaylistVideoWhereUniqueInput;
};

export type PlaylistVideoUpsertWithWhereUniqueWithoutVideoInput = {
  create: PlaylistVideoCreateWithoutVideoInput;
  update: PlaylistVideoUpdateWithoutVideoInput;
  where: PlaylistVideoWhereUniqueInput;
};

export type PlaylistVideoVideoIdPlaylistIdCompoundUniqueInput = {
  playlistId: Scalars['String'];
  videoId: Scalars['String'];
};

export type PlaylistVideoWhereInput = {
  AND?: InputMaybe<Array<PlaylistVideoWhereInput>>;
  NOT?: InputMaybe<Array<PlaylistVideoWhereInput>>;
  OR?: InputMaybe<Array<PlaylistVideoWhereInput>>;
  archivedAt?: InputMaybe<DateTimeNullableFilter>;
  createdAt?: InputMaybe<DateTimeFilter>;
  playlist?: InputMaybe<PlaylistRelationFilter>;
  playlistId?: InputMaybe<StringFilter>;
  updatedAt?: InputMaybe<DateTimeFilter>;
  video?: InputMaybe<VideoRelationFilter>;
  videoId?: InputMaybe<StringFilter>;
};

export type PlaylistVideoWhereUniqueInput = {
  videoId_playlistId?: InputMaybe<PlaylistVideoVideoIdPlaylistIdCompoundUniqueInput>;
};

export type PlaylistWhereInput = {
  AND?: InputMaybe<Array<PlaylistWhereInput>>;
  NOT?: InputMaybe<Array<PlaylistWhereInput>>;
  OR?: InputMaybe<Array<PlaylistWhereInput>>;
  archivedAt?: InputMaybe<DateTimeNullableFilter>;
  channel?: InputMaybe<ChannelRelationFilter>;
  channelId?: InputMaybe<StringFilter>;
  createdAt?: InputMaybe<DateTimeFilter>;
  description?: InputMaybe<StringNullableFilter>;
  id?: InputMaybe<StringFilter>;
  name?: InputMaybe<StringFilter>;
  playlistVideos?: InputMaybe<PlaylistVideoListRelationFilter>;
  thumbnailUrl?: InputMaybe<StringNullableFilter>;
  updatedAt?: InputMaybe<DateTimeFilter>;
};

export type PlaylistWhereUniqueInput = {
  id?: InputMaybe<Scalars['String']>;
};

export type PresentmentMoney = {
  __typename?: 'PresentmentMoney';
  currencyCode: CurrencyCode;
  displayPrice: Scalars['String'];
  price: Scalars['Float'];
  settlementToPresentmentExchangeRate: Scalars['Float'];
};

export type PrestoUserResponse = {
  __typename?: 'PrestoUserResponse';
  email?: Maybe<Scalars['String']>;
  mobileCountryCode?: Maybe<Scalars['String']>;
  mobileNum?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  prestoUserId?: Maybe<Scalars['String']>;
};

export enum ProductType {
  Gift = 'GIFT',
  PayPerView = 'PAY_PER_VIEW',
  Subscription = 'SUBSCRIPTION',
  Tips = 'TIPS'
}

export type Query = {
  __typename?: 'Query';
  adminUser?: Maybe<AdminUser>;
  adminUsers: Array<AdminUser>;
  aggregateAdminUser: AggregateAdminUser;
  aggregateChannel: AggregateChannel;
  aggregateChannelFollower: AggregateChannelFollower;
  aggregateComment: AggregateComment;
  aggregateGift: AggregateGift;
  aggregateGiftDistribution: AggregateGiftDistribution;
  aggregateInquiry: AggregateInquiry;
  aggregateLikedVideo: AggregateLikedVideo;
  aggregateMintedNft: AggregateMintedNft;
  aggregateNftCollection: AggregateNftCollection;
  aggregatePaymentWebhook: AggregatePaymentWebhook;
  aggregatePlaylist: AggregatePlaylist;
  aggregatePlaylistVideo: AggregatePlaylistVideo;
  aggregateSubscriptionPlan: AggregateSubscriptionPlan;
  aggregateTips: AggregateTips;
  aggregateTransaction: AggregateTransaction;
  aggregateUser: AggregateUser;
  aggregateUserPayPerView: AggregateUserPayPerView;
  aggregateUserSubscription: AggregateUserSubscription;
  aggregateVideo: AggregateVideo;
  aggregateWatchHistory: AggregateWatchHistory;
  channel?: Maybe<Channel>;
  channelFollower?: Maybe<ChannelFollower>;
  channelFollowers: Array<ChannelFollower>;
  channels: Array<Channel>;
  checkEmailRegistered: Scalars['Boolean'];
  checkUsernameAvailability: Scalars['Boolean'];
  comment?: Maybe<Comment>;
  comments: Array<Comment>;
  findFirstAdminUser?: Maybe<AdminUser>;
  findFirstChannel?: Maybe<Channel>;
  findFirstChannelFollower?: Maybe<ChannelFollower>;
  findFirstComment?: Maybe<Comment>;
  findFirstGift?: Maybe<Gift>;
  findFirstGiftDistribution?: Maybe<GiftDistribution>;
  findFirstInquiry?: Maybe<Inquiry>;
  findFirstLikedVideo?: Maybe<LikedVideo>;
  findFirstMintedNft?: Maybe<MintedNft>;
  findFirstNftCollection?: Maybe<NftCollection>;
  findFirstPaymentWebhook?: Maybe<PaymentWebhook>;
  findFirstPlaylist?: Maybe<Playlist>;
  findFirstPlaylistVideo?: Maybe<PlaylistVideo>;
  findFirstSubscriptionPlan?: Maybe<SubscriptionPlan>;
  findFirstTips?: Maybe<Tips>;
  findFirstTransaction?: Maybe<Transaction>;
  findFirstUser?: Maybe<User>;
  findFirstUserPayPerView?: Maybe<UserPayPerView>;
  findFirstUserSubscription?: Maybe<UserSubscription>;
  findFirstVideo?: Maybe<Video>;
  findFirstWatchHistory?: Maybe<WatchHistory>;
  findManyTips: Array<Tips>;
  findUniqueTips?: Maybe<Tips>;
  formatPresentmentMoney: PresentmentMoney;
  getActivePlaylist?: Maybe<Playlist>;
  getAdminUser?: Maybe<AdminUser>;
  getChannel?: Maybe<Channel>;
  getChannelFollower?: Maybe<ChannelFollower>;
  getComment?: Maybe<Comment>;
  getGift?: Maybe<Gift>;
  getGiftDistribution?: Maybe<GiftDistribution>;
  getInquiry?: Maybe<Inquiry>;
  getLikedVideo?: Maybe<LikedVideo>;
  getMintedNft?: Maybe<MintedNft>;
  getMyChannel: Channel;
  getMyPlaylists: PaginatedPlaylist;
  getMyValidSubscriptionsByChannelId?: Maybe<UserSubscription>;
  getMyVideos: PaginatedVideo;
  getNftCollection?: Maybe<NftCollection>;
  getPaymentWebhook?: Maybe<PaymentWebhook>;
  getPlaylist?: Maybe<Playlist>;
  getPlaylistVideo?: Maybe<PlaylistVideo>;
  getPublicChannelByName?: Maybe<Channel>;
  getPublicChannelByUserId?: Maybe<Channel>;
  getPublicUniqueVideo?: Maybe<Video>;
  getSubscriptionPlan?: Maybe<SubscriptionPlan>;
  getTips?: Maybe<Tips>;
  getTransaction?: Maybe<Transaction>;
  getTransactionSummary?: Maybe<TransactionSummary>;
  getUser?: Maybe<User>;
  getUserPayPerView?: Maybe<UserPayPerView>;
  getUserSubscription?: Maybe<UserSubscription>;
  getVideo?: Maybe<Video>;
  getWatchHistory?: Maybe<WatchHistory>;
  getWatchHistorySummary?: Maybe<PaginatedWatchHistorySummaryItem>;
  gift?: Maybe<Gift>;
  giftDistribution?: Maybe<GiftDistribution>;
  giftDistributions: Array<GiftDistribution>;
  gifts: Array<Gift>;
  groupByAdminUser: Array<AdminUserGroupBy>;
  groupByChannel: Array<ChannelGroupBy>;
  groupByChannelFollower: Array<ChannelFollowerGroupBy>;
  groupByComment: Array<CommentGroupBy>;
  groupByGift: Array<GiftGroupBy>;
  groupByGiftDistribution: Array<GiftDistributionGroupBy>;
  groupByInquiry: Array<InquiryGroupBy>;
  groupByLikedVideo: Array<LikedVideoGroupBy>;
  groupByMintedNft: Array<MintedNftGroupBy>;
  groupByNftCollection: Array<NftCollectionGroupBy>;
  groupByPaymentWebhook: Array<PaymentWebhookGroupBy>;
  groupByPlaylist: Array<PlaylistGroupBy>;
  groupByPlaylistVideo: Array<PlaylistVideoGroupBy>;
  groupBySubscriptionPlan: Array<SubscriptionPlanGroupBy>;
  groupByTips: Array<TipsGroupBy>;
  groupByTransaction: Array<TransactionGroupBy>;
  groupByUser: Array<UserGroupBy>;
  groupByUserPayPerView: Array<UserPayPerViewGroupBy>;
  groupByUserSubscription: Array<UserSubscriptionGroupBy>;
  groupByVideo: Array<VideoGroupBy>;
  groupByWatchHistory: Array<WatchHistoryGroupBy>;
  health: Scalars['String'];
  inquiries: Array<Inquiry>;
  inquiry?: Maybe<Inquiry>;
  likedVideo?: Maybe<LikedVideo>;
  likedVideos: Array<LikedVideo>;
  listActivePlaylists: PaginatedPlaylist;
  listAdminUsers: PaginatedAdminUser;
  listChannelFollowers: PaginatedChannelFollower;
  listChannels: PaginatedChannel;
  listComments: PaginatedComment;
  listGiftDistributions: PaginatedGiftDistribution;
  listGifts: PaginatedGift;
  listInquiries: PaginatedInquiry;
  listLikedVideos: PaginatedLikedVideo;
  listMintedNfts: PaginatedMintedNft;
  listMyFanTransactions: PaginatedTransaction;
  listMyGifts: PaginatedGift;
  listMySubscriptionPlans: PaginatedSubscriptionPlan;
  listMySubscriptions: PaginatedUserSubscription;
  listNftCollections: PaginatedNftCollection;
  listPaymentWebhooks: PaginatedPaymentWebhook;
  listPlaylistVideos: PaginatedPlaylistVideo;
  listPlaylists: PaginatedPlaylist;
  listPublicChannels: PaginatedChannel;
  listPublicRolls: PaginatedVideo;
  listPublicRollsWithCursor: CursorPaginatedVideo;
  listPublicVideos: PaginatedVideo;
  listPublicVideosUniqueGenres: Array<Scalars['String']>;
  listPublicVideosWithCursor: CursorPaginatedVideo;
  listSetupCompletedChannels: Array<SetupCompletedChannel>;
  listSubscriptionPlans: PaginatedSubscriptionPlan;
  listTips: PaginatedTips;
  listTransactions: PaginatedTransaction;
  listTransactionsByUserId: PaginatedTransaction;
  listUniqueTransactionChannelsByUserId: Array<Channel>;
  listUserFollowedChannelRolls: PaginatedVideo;
  listUserFollowedChannelUniqueGenres: Array<Scalars['String']>;
  listUserFollowedChannelVideos: PaginatedVideo;
  listUserFollowedChannels: PaginatedChannel;
  listUserLikedRolls: PaginatedVideo;
  listUserLikedVideoHistories: PaginatedLikedVideo;
  listUserLikedVideos: PaginatedVideo;
  listUserLikedVideosByDate: Array<LikedVideoItem>;
  listUserPayPerViews: PaginatedUserPayPerView;
  listUserSubscriptions: PaginatedUserSubscription;
  listUserWatchHistories: PaginatedWatchHistory;
  listUserWatchedRolls: PaginatedVideo;
  listUserWatchedVideos: PaginatedVideo;
  listUserWatchedVideosByDate: Array<WatchedVideoItem>;
  listUsers: PaginatedUser;
  listVideos: PaginatedVideo;
  listWatchHistories: PaginatedWatchHistory;
  me: User;
  mintedNft?: Maybe<MintedNft>;
  mintedNfts: Array<MintedNft>;
  nftCollection?: Maybe<NftCollection>;
  nftCollections: Array<NftCollection>;
  paymentWebhook?: Maybe<PaymentWebhook>;
  paymentWebhooks: Array<PaymentWebhook>;
  playlist?: Maybe<Playlist>;
  playlistVideo?: Maybe<PlaylistVideo>;
  playlistVideos: Array<PlaylistVideo>;
  playlists: Array<Playlist>;
  subscriptionPlan?: Maybe<SubscriptionPlan>;
  subscriptionPlans: Array<SubscriptionPlan>;
  transaction?: Maybe<Transaction>;
  transactions: Array<Transaction>;
  user?: Maybe<User>;
  userPayPerView?: Maybe<UserPayPerView>;
  userPayPerViews: Array<UserPayPerView>;
  userSubscription?: Maybe<UserSubscription>;
  userSubscriptions: Array<UserSubscription>;
  users: Array<User>;
  video?: Maybe<Video>;
  videos: Array<Video>;
  watchHistories: Array<WatchHistory>;
  watchHistory?: Maybe<WatchHistory>;
};


export type QueryAdminUserArgs = {
  where: AdminUserWhereUniqueInput;
};


export type QueryAdminUsersArgs = {
  cursor?: InputMaybe<AdminUserWhereUniqueInput>;
  distinct?: InputMaybe<Array<AdminUserScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<AdminUserOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<AdminUserWhereInput>;
};


export type QueryAggregateAdminUserArgs = {
  cursor?: InputMaybe<AdminUserWhereUniqueInput>;
  orderBy?: InputMaybe<Array<AdminUserOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<AdminUserWhereInput>;
};


export type QueryAggregateChannelArgs = {
  cursor?: InputMaybe<ChannelWhereUniqueInput>;
  orderBy?: InputMaybe<Array<ChannelOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<ChannelWhereInput>;
};


export type QueryAggregateChannelFollowerArgs = {
  cursor?: InputMaybe<ChannelFollowerWhereUniqueInput>;
  orderBy?: InputMaybe<Array<ChannelFollowerOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<ChannelFollowerWhereInput>;
};


export type QueryAggregateCommentArgs = {
  cursor?: InputMaybe<CommentWhereUniqueInput>;
  orderBy?: InputMaybe<Array<CommentOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<CommentWhereInput>;
};


export type QueryAggregateGiftArgs = {
  cursor?: InputMaybe<GiftWhereUniqueInput>;
  orderBy?: InputMaybe<Array<GiftOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<GiftWhereInput>;
};


export type QueryAggregateGiftDistributionArgs = {
  cursor?: InputMaybe<GiftDistributionWhereUniqueInput>;
  orderBy?: InputMaybe<Array<GiftDistributionOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<GiftDistributionWhereInput>;
};


export type QueryAggregateInquiryArgs = {
  cursor?: InputMaybe<InquiryWhereUniqueInput>;
  orderBy?: InputMaybe<Array<InquiryOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<InquiryWhereInput>;
};


export type QueryAggregateLikedVideoArgs = {
  cursor?: InputMaybe<LikedVideoWhereUniqueInput>;
  orderBy?: InputMaybe<Array<LikedVideoOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<LikedVideoWhereInput>;
};


export type QueryAggregateMintedNftArgs = {
  cursor?: InputMaybe<MintedNftWhereUniqueInput>;
  orderBy?: InputMaybe<Array<MintedNftOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<MintedNftWhereInput>;
};


export type QueryAggregateNftCollectionArgs = {
  cursor?: InputMaybe<NftCollectionWhereUniqueInput>;
  orderBy?: InputMaybe<Array<NftCollectionOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<NftCollectionWhereInput>;
};


export type QueryAggregatePaymentWebhookArgs = {
  cursor?: InputMaybe<PaymentWebhookWhereUniqueInput>;
  orderBy?: InputMaybe<Array<PaymentWebhookOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<PaymentWebhookWhereInput>;
};


export type QueryAggregatePlaylistArgs = {
  cursor?: InputMaybe<PlaylistWhereUniqueInput>;
  orderBy?: InputMaybe<Array<PlaylistOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<PlaylistWhereInput>;
};


export type QueryAggregatePlaylistVideoArgs = {
  cursor?: InputMaybe<PlaylistVideoWhereUniqueInput>;
  orderBy?: InputMaybe<Array<PlaylistVideoOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<PlaylistVideoWhereInput>;
};


export type QueryAggregateSubscriptionPlanArgs = {
  cursor?: InputMaybe<SubscriptionPlanWhereUniqueInput>;
  orderBy?: InputMaybe<Array<SubscriptionPlanOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<SubscriptionPlanWhereInput>;
};


export type QueryAggregateTipsArgs = {
  cursor?: InputMaybe<TipsWhereUniqueInput>;
  orderBy?: InputMaybe<Array<TipsOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<TipsWhereInput>;
};


export type QueryAggregateTransactionArgs = {
  cursor?: InputMaybe<TransactionWhereUniqueInput>;
  orderBy?: InputMaybe<Array<TransactionOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<TransactionWhereInput>;
};


export type QueryAggregateUserArgs = {
  cursor?: InputMaybe<UserWhereUniqueInput>;
  orderBy?: InputMaybe<Array<UserOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<UserWhereInput>;
};


export type QueryAggregateUserPayPerViewArgs = {
  cursor?: InputMaybe<UserPayPerViewWhereUniqueInput>;
  orderBy?: InputMaybe<Array<UserPayPerViewOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<UserPayPerViewWhereInput>;
};


export type QueryAggregateUserSubscriptionArgs = {
  cursor?: InputMaybe<UserSubscriptionWhereUniqueInput>;
  orderBy?: InputMaybe<Array<UserSubscriptionOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<UserSubscriptionWhereInput>;
};


export type QueryAggregateVideoArgs = {
  cursor?: InputMaybe<VideoWhereUniqueInput>;
  orderBy?: InputMaybe<Array<VideoOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<VideoWhereInput>;
};


export type QueryAggregateWatchHistoryArgs = {
  cursor?: InputMaybe<WatchHistoryWhereUniqueInput>;
  orderBy?: InputMaybe<Array<WatchHistoryOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<WatchHistoryWhereInput>;
};


export type QueryChannelArgs = {
  where: ChannelWhereUniqueInput;
};


export type QueryChannelFollowerArgs = {
  where: ChannelFollowerWhereUniqueInput;
};


export type QueryChannelFollowersArgs = {
  cursor?: InputMaybe<ChannelFollowerWhereUniqueInput>;
  distinct?: InputMaybe<Array<ChannelFollowerScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<ChannelFollowerOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<ChannelFollowerWhereInput>;
};


export type QueryChannelsArgs = {
  cursor?: InputMaybe<ChannelWhereUniqueInput>;
  distinct?: InputMaybe<Array<ChannelScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<ChannelOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<ChannelWhereInput>;
};


export type QueryCheckEmailRegisteredArgs = {
  email: Scalars['String'];
};


export type QueryCheckUsernameAvailabilityArgs = {
  username: Scalars['String'];
};


export type QueryCommentArgs = {
  where: CommentWhereUniqueInput;
};


export type QueryCommentsArgs = {
  cursor?: InputMaybe<CommentWhereUniqueInput>;
  distinct?: InputMaybe<Array<CommentScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<CommentOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<CommentWhereInput>;
};


export type QueryFindFirstAdminUserArgs = {
  cursor?: InputMaybe<AdminUserWhereUniqueInput>;
  distinct?: InputMaybe<Array<AdminUserScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<AdminUserOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<AdminUserWhereInput>;
};


export type QueryFindFirstChannelArgs = {
  cursor?: InputMaybe<ChannelWhereUniqueInput>;
  distinct?: InputMaybe<Array<ChannelScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<ChannelOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<ChannelWhereInput>;
};


export type QueryFindFirstChannelFollowerArgs = {
  cursor?: InputMaybe<ChannelFollowerWhereUniqueInput>;
  distinct?: InputMaybe<Array<ChannelFollowerScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<ChannelFollowerOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<ChannelFollowerWhereInput>;
};


export type QueryFindFirstCommentArgs = {
  cursor?: InputMaybe<CommentWhereUniqueInput>;
  distinct?: InputMaybe<Array<CommentScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<CommentOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<CommentWhereInput>;
};


export type QueryFindFirstGiftArgs = {
  cursor?: InputMaybe<GiftWhereUniqueInput>;
  distinct?: InputMaybe<Array<GiftScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<GiftOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<GiftWhereInput>;
};


export type QueryFindFirstGiftDistributionArgs = {
  cursor?: InputMaybe<GiftDistributionWhereUniqueInput>;
  distinct?: InputMaybe<Array<GiftDistributionScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<GiftDistributionOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<GiftDistributionWhereInput>;
};


export type QueryFindFirstInquiryArgs = {
  cursor?: InputMaybe<InquiryWhereUniqueInput>;
  distinct?: InputMaybe<Array<InquiryScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<InquiryOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<InquiryWhereInput>;
};


export type QueryFindFirstLikedVideoArgs = {
  cursor?: InputMaybe<LikedVideoWhereUniqueInput>;
  distinct?: InputMaybe<Array<LikedVideoScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<LikedVideoOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<LikedVideoWhereInput>;
};


export type QueryFindFirstMintedNftArgs = {
  cursor?: InputMaybe<MintedNftWhereUniqueInput>;
  distinct?: InputMaybe<Array<MintedNftScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<MintedNftOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<MintedNftWhereInput>;
};


export type QueryFindFirstNftCollectionArgs = {
  cursor?: InputMaybe<NftCollectionWhereUniqueInput>;
  distinct?: InputMaybe<Array<NftCollectionScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<NftCollectionOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<NftCollectionWhereInput>;
};


export type QueryFindFirstPaymentWebhookArgs = {
  cursor?: InputMaybe<PaymentWebhookWhereUniqueInput>;
  distinct?: InputMaybe<Array<PaymentWebhookScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<PaymentWebhookOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<PaymentWebhookWhereInput>;
};


export type QueryFindFirstPlaylistArgs = {
  cursor?: InputMaybe<PlaylistWhereUniqueInput>;
  distinct?: InputMaybe<Array<PlaylistScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<PlaylistOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<PlaylistWhereInput>;
};


export type QueryFindFirstPlaylistVideoArgs = {
  cursor?: InputMaybe<PlaylistVideoWhereUniqueInput>;
  distinct?: InputMaybe<Array<PlaylistVideoScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<PlaylistVideoOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<PlaylistVideoWhereInput>;
};


export type QueryFindFirstSubscriptionPlanArgs = {
  cursor?: InputMaybe<SubscriptionPlanWhereUniqueInput>;
  distinct?: InputMaybe<Array<SubscriptionPlanScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<SubscriptionPlanOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<SubscriptionPlanWhereInput>;
};


export type QueryFindFirstTipsArgs = {
  cursor?: InputMaybe<TipsWhereUniqueInput>;
  distinct?: InputMaybe<Array<TipsScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<TipsOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<TipsWhereInput>;
};


export type QueryFindFirstTransactionArgs = {
  cursor?: InputMaybe<TransactionWhereUniqueInput>;
  distinct?: InputMaybe<Array<TransactionScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<TransactionOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<TransactionWhereInput>;
};


export type QueryFindFirstUserArgs = {
  cursor?: InputMaybe<UserWhereUniqueInput>;
  distinct?: InputMaybe<Array<UserScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<UserOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<UserWhereInput>;
};


export type QueryFindFirstUserPayPerViewArgs = {
  cursor?: InputMaybe<UserPayPerViewWhereUniqueInput>;
  distinct?: InputMaybe<Array<UserPayPerViewScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<UserPayPerViewOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<UserPayPerViewWhereInput>;
};


export type QueryFindFirstUserSubscriptionArgs = {
  cursor?: InputMaybe<UserSubscriptionWhereUniqueInput>;
  distinct?: InputMaybe<Array<UserSubscriptionScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<UserSubscriptionOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<UserSubscriptionWhereInput>;
};


export type QueryFindFirstVideoArgs = {
  cursor?: InputMaybe<VideoWhereUniqueInput>;
  distinct?: InputMaybe<Array<VideoScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<VideoOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<VideoWhereInput>;
};


export type QueryFindFirstWatchHistoryArgs = {
  cursor?: InputMaybe<WatchHistoryWhereUniqueInput>;
  distinct?: InputMaybe<Array<WatchHistoryScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<WatchHistoryOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<WatchHistoryWhereInput>;
};


export type QueryFindManyTipsArgs = {
  cursor?: InputMaybe<TipsWhereUniqueInput>;
  distinct?: InputMaybe<Array<TipsScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<TipsOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<TipsWhereInput>;
};


export type QueryFindUniqueTipsArgs = {
  where: TipsWhereUniqueInput;
};


export type QueryFormatPresentmentMoneyArgs = {
  data: ConvertPaymentInput;
};


export type QueryGetActivePlaylistArgs = {
  id?: InputMaybe<Scalars['String']>;
};


export type QueryGetAdminUserArgs = {
  id?: InputMaybe<Scalars['Int']>;
};


export type QueryGetChannelArgs = {
  id?: InputMaybe<Scalars['String']>;
};


export type QueryGetChannelFollowerArgs = {
  id?: InputMaybe<Scalars['String']>;
};


export type QueryGetCommentArgs = {
  id?: InputMaybe<Scalars['String']>;
};


export type QueryGetGiftArgs = {
  id?: InputMaybe<Scalars['String']>;
};


export type QueryGetGiftDistributionArgs = {
  id?: InputMaybe<Scalars['Int']>;
};


export type QueryGetInquiryArgs = {
  id?: InputMaybe<Scalars['String']>;
};


export type QueryGetLikedVideoArgs = {
  id?: InputMaybe<Scalars['String']>;
};


export type QueryGetMintedNftArgs = {
  id?: InputMaybe<Scalars['String']>;
};


export type QueryGetMyPlaylistsArgs = {
  orderBy?: InputMaybe<Array<PlaylistOrderByWithRelationInput>>;
  page?: InputMaybe<Scalars['Int']>;
  perPage?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<PlaylistWhereInput>;
};


export type QueryGetMyValidSubscriptionsByChannelIdArgs = {
  channelId?: InputMaybe<Scalars['String']>;
};


export type QueryGetMyVideosArgs = {
  cursor?: InputMaybe<Scalars['String']>;
  firstId?: InputMaybe<Scalars['String']>;
  orderBy?: InputMaybe<Array<VideoOrderByWithRelationInput>>;
  page?: InputMaybe<Scalars['Int']>;
  perPage?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<VideoWhereInput>;
};


export type QueryGetNftCollectionArgs = {
  id?: InputMaybe<Scalars['String']>;
};


export type QueryGetPaymentWebhookArgs = {
  id?: InputMaybe<Scalars['Int']>;
};


export type QueryGetPlaylistArgs = {
  id?: InputMaybe<Scalars['String']>;
};


export type QueryGetPlaylistVideoArgs = {
  id?: InputMaybe<Scalars['String']>;
};


export type QueryGetPublicChannelByNameArgs = {
  name: Scalars['String'];
};


export type QueryGetPublicChannelByUserIdArgs = {
  userId: Scalars['String'];
};


export type QueryGetPublicUniqueVideoArgs = {
  id?: InputMaybe<Scalars['String']>;
  shortId?: InputMaybe<Scalars['String']>;
};


export type QueryGetSubscriptionPlanArgs = {
  id?: InputMaybe<Scalars['Int']>;
};


export type QueryGetTipsArgs = {
  id?: InputMaybe<Scalars['String']>;
};


export type QueryGetTransactionArgs = {
  id?: InputMaybe<Scalars['String']>;
};


export type QueryGetTransactionSummaryArgs = {
  where?: InputMaybe<TransactionSummaryInput>;
};


export type QueryGetUserArgs = {
  id?: InputMaybe<Scalars['String']>;
};


export type QueryGetUserPayPerViewArgs = {
  id?: InputMaybe<Scalars['Int']>;
};


export type QueryGetUserSubscriptionArgs = {
  id?: InputMaybe<Scalars['Int']>;
};


export type QueryGetVideoArgs = {
  id?: InputMaybe<Scalars['String']>;
};


export type QueryGetWatchHistoryArgs = {
  id?: InputMaybe<Scalars['Int']>;
};


export type QueryGetWatchHistorySummaryArgs = {
  page?: InputMaybe<Scalars['Int']>;
  perPage?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<WatchHistorySummaryWhereInput>;
};


export type QueryGiftArgs = {
  where: GiftWhereUniqueInput;
};


export type QueryGiftDistributionArgs = {
  where: GiftDistributionWhereUniqueInput;
};


export type QueryGiftDistributionsArgs = {
  cursor?: InputMaybe<GiftDistributionWhereUniqueInput>;
  distinct?: InputMaybe<Array<GiftDistributionScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<GiftDistributionOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<GiftDistributionWhereInput>;
};


export type QueryGiftsArgs = {
  cursor?: InputMaybe<GiftWhereUniqueInput>;
  distinct?: InputMaybe<Array<GiftScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<GiftOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<GiftWhereInput>;
};


export type QueryGroupByAdminUserArgs = {
  by: Array<AdminUserScalarFieldEnum>;
  having?: InputMaybe<AdminUserScalarWhereWithAggregatesInput>;
  orderBy?: InputMaybe<Array<AdminUserOrderByWithAggregationInput>>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<AdminUserWhereInput>;
};


export type QueryGroupByChannelArgs = {
  by: Array<ChannelScalarFieldEnum>;
  having?: InputMaybe<ChannelScalarWhereWithAggregatesInput>;
  orderBy?: InputMaybe<Array<ChannelOrderByWithAggregationInput>>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<ChannelWhereInput>;
};


export type QueryGroupByChannelFollowerArgs = {
  by: Array<ChannelFollowerScalarFieldEnum>;
  having?: InputMaybe<ChannelFollowerScalarWhereWithAggregatesInput>;
  orderBy?: InputMaybe<Array<ChannelFollowerOrderByWithAggregationInput>>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<ChannelFollowerWhereInput>;
};


export type QueryGroupByCommentArgs = {
  by: Array<CommentScalarFieldEnum>;
  having?: InputMaybe<CommentScalarWhereWithAggregatesInput>;
  orderBy?: InputMaybe<Array<CommentOrderByWithAggregationInput>>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<CommentWhereInput>;
};


export type QueryGroupByGiftArgs = {
  by: Array<GiftScalarFieldEnum>;
  having?: InputMaybe<GiftScalarWhereWithAggregatesInput>;
  orderBy?: InputMaybe<Array<GiftOrderByWithAggregationInput>>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<GiftWhereInput>;
};


export type QueryGroupByGiftDistributionArgs = {
  by: Array<GiftDistributionScalarFieldEnum>;
  having?: InputMaybe<GiftDistributionScalarWhereWithAggregatesInput>;
  orderBy?: InputMaybe<Array<GiftDistributionOrderByWithAggregationInput>>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<GiftDistributionWhereInput>;
};


export type QueryGroupByInquiryArgs = {
  by: Array<InquiryScalarFieldEnum>;
  having?: InputMaybe<InquiryScalarWhereWithAggregatesInput>;
  orderBy?: InputMaybe<Array<InquiryOrderByWithAggregationInput>>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<InquiryWhereInput>;
};


export type QueryGroupByLikedVideoArgs = {
  by: Array<LikedVideoScalarFieldEnum>;
  having?: InputMaybe<LikedVideoScalarWhereWithAggregatesInput>;
  orderBy?: InputMaybe<Array<LikedVideoOrderByWithAggregationInput>>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<LikedVideoWhereInput>;
};


export type QueryGroupByMintedNftArgs = {
  by: Array<MintedNftScalarFieldEnum>;
  having?: InputMaybe<MintedNftScalarWhereWithAggregatesInput>;
  orderBy?: InputMaybe<Array<MintedNftOrderByWithAggregationInput>>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<MintedNftWhereInput>;
};


export type QueryGroupByNftCollectionArgs = {
  by: Array<NftCollectionScalarFieldEnum>;
  having?: InputMaybe<NftCollectionScalarWhereWithAggregatesInput>;
  orderBy?: InputMaybe<Array<NftCollectionOrderByWithAggregationInput>>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<NftCollectionWhereInput>;
};


export type QueryGroupByPaymentWebhookArgs = {
  by: Array<PaymentWebhookScalarFieldEnum>;
  having?: InputMaybe<PaymentWebhookScalarWhereWithAggregatesInput>;
  orderBy?: InputMaybe<Array<PaymentWebhookOrderByWithAggregationInput>>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<PaymentWebhookWhereInput>;
};


export type QueryGroupByPlaylistArgs = {
  by: Array<PlaylistScalarFieldEnum>;
  having?: InputMaybe<PlaylistScalarWhereWithAggregatesInput>;
  orderBy?: InputMaybe<Array<PlaylistOrderByWithAggregationInput>>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<PlaylistWhereInput>;
};


export type QueryGroupByPlaylistVideoArgs = {
  by: Array<PlaylistVideoScalarFieldEnum>;
  having?: InputMaybe<PlaylistVideoScalarWhereWithAggregatesInput>;
  orderBy?: InputMaybe<Array<PlaylistVideoOrderByWithAggregationInput>>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<PlaylistVideoWhereInput>;
};


export type QueryGroupBySubscriptionPlanArgs = {
  by: Array<SubscriptionPlanScalarFieldEnum>;
  having?: InputMaybe<SubscriptionPlanScalarWhereWithAggregatesInput>;
  orderBy?: InputMaybe<Array<SubscriptionPlanOrderByWithAggregationInput>>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<SubscriptionPlanWhereInput>;
};


export type QueryGroupByTipsArgs = {
  by: Array<TipsScalarFieldEnum>;
  having?: InputMaybe<TipsScalarWhereWithAggregatesInput>;
  orderBy?: InputMaybe<Array<TipsOrderByWithAggregationInput>>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<TipsWhereInput>;
};


export type QueryGroupByTransactionArgs = {
  by: Array<TransactionScalarFieldEnum>;
  having?: InputMaybe<TransactionScalarWhereWithAggregatesInput>;
  orderBy?: InputMaybe<Array<TransactionOrderByWithAggregationInput>>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<TransactionWhereInput>;
};


export type QueryGroupByUserArgs = {
  by: Array<UserScalarFieldEnum>;
  having?: InputMaybe<UserScalarWhereWithAggregatesInput>;
  orderBy?: InputMaybe<Array<UserOrderByWithAggregationInput>>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<UserWhereInput>;
};


export type QueryGroupByUserPayPerViewArgs = {
  by: Array<UserPayPerViewScalarFieldEnum>;
  having?: InputMaybe<UserPayPerViewScalarWhereWithAggregatesInput>;
  orderBy?: InputMaybe<Array<UserPayPerViewOrderByWithAggregationInput>>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<UserPayPerViewWhereInput>;
};


export type QueryGroupByUserSubscriptionArgs = {
  by: Array<UserSubscriptionScalarFieldEnum>;
  having?: InputMaybe<UserSubscriptionScalarWhereWithAggregatesInput>;
  orderBy?: InputMaybe<Array<UserSubscriptionOrderByWithAggregationInput>>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<UserSubscriptionWhereInput>;
};


export type QueryGroupByVideoArgs = {
  by: Array<VideoScalarFieldEnum>;
  having?: InputMaybe<VideoScalarWhereWithAggregatesInput>;
  orderBy?: InputMaybe<Array<VideoOrderByWithAggregationInput>>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<VideoWhereInput>;
};


export type QueryGroupByWatchHistoryArgs = {
  by: Array<WatchHistoryScalarFieldEnum>;
  having?: InputMaybe<WatchHistoryScalarWhereWithAggregatesInput>;
  orderBy?: InputMaybe<Array<WatchHistoryOrderByWithAggregationInput>>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<WatchHistoryWhereInput>;
};


export type QueryInquiriesArgs = {
  cursor?: InputMaybe<InquiryWhereUniqueInput>;
  distinct?: InputMaybe<Array<InquiryScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<InquiryOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<InquiryWhereInput>;
};


export type QueryInquiryArgs = {
  where: InquiryWhereUniqueInput;
};


export type QueryLikedVideoArgs = {
  where: LikedVideoWhereUniqueInput;
};


export type QueryLikedVideosArgs = {
  cursor?: InputMaybe<LikedVideoWhereUniqueInput>;
  distinct?: InputMaybe<Array<LikedVideoScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<LikedVideoOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<LikedVideoWhereInput>;
};


export type QueryListActivePlaylistsArgs = {
  orderBy?: InputMaybe<Array<PlaylistOrderByWithRelationInput>>;
  page?: InputMaybe<Scalars['Int']>;
  perPage?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<PlaylistWhereInput>;
};


export type QueryListAdminUsersArgs = {
  orderBy?: InputMaybe<Array<AdminUserOrderByWithRelationInput>>;
  page?: InputMaybe<Scalars['Int']>;
  perPage?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<AdminUserWhereInput>;
};


export type QueryListChannelFollowersArgs = {
  orderBy?: InputMaybe<Array<ChannelFollowerOrderByWithRelationInput>>;
  page?: InputMaybe<Scalars['Int']>;
  perPage?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<ChannelFollowerWhereInput>;
};


export type QueryListChannelsArgs = {
  orderBy?: InputMaybe<Array<ChannelOrderByWithRelationInput>>;
  page?: InputMaybe<Scalars['Int']>;
  perPage?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<ChannelWhereInput>;
};


export type QueryListCommentsArgs = {
  orderBy?: InputMaybe<Array<CommentOrderByWithRelationInput>>;
  page?: InputMaybe<Scalars['Int']>;
  perPage?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<CommentWhereInput>;
};


export type QueryListGiftDistributionsArgs = {
  orderBy?: InputMaybe<Array<GiftDistributionOrderByWithRelationInput>>;
  page?: InputMaybe<Scalars['Int']>;
  perPage?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<GiftDistributionWhereInput>;
};


export type QueryListGiftsArgs = {
  orderBy?: InputMaybe<Array<GiftOrderByWithRelationInput>>;
  page?: InputMaybe<Scalars['Int']>;
  perPage?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<GiftWhereInput>;
};


export type QueryListInquiriesArgs = {
  orderBy?: InputMaybe<Array<InquiryOrderByWithRelationInput>>;
  page?: InputMaybe<Scalars['Int']>;
  perPage?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<InquiryWhereInput>;
};


export type QueryListLikedVideosArgs = {
  orderBy?: InputMaybe<Array<LikedVideoOrderByWithRelationInput>>;
  page?: InputMaybe<Scalars['Int']>;
  perPage?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<LikedVideoWhereInput>;
};


export type QueryListMintedNftsArgs = {
  orderBy?: InputMaybe<Array<MintedNftOrderByWithRelationInput>>;
  page?: InputMaybe<Scalars['Int']>;
  perPage?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<MintedNftWhereInput>;
};


export type QueryListMyFanTransactionsArgs = {
  orderBy?: InputMaybe<Array<TransactionOrderByWithRelationInput>>;
  page?: InputMaybe<Scalars['Int']>;
  perPage?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<TransactionWhereInput>;
};


export type QueryListMyGiftsArgs = {
  orderBy?: InputMaybe<Array<GiftOrderByWithRelationInput>>;
  page?: InputMaybe<Scalars['Int']>;
  perPage?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<GiftWhereInput>;
};


export type QueryListMySubscriptionPlansArgs = {
  orderBy?: InputMaybe<Array<SubscriptionPlanOrderByWithRelationInput>>;
  page?: InputMaybe<Scalars['Int']>;
  perPage?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<SubscriptionPlanWhereInput>;
};


export type QueryListMySubscriptionsArgs = {
  orderBy?: InputMaybe<Array<UserSubscriptionOrderByWithRelationInput>>;
  page?: InputMaybe<Scalars['Int']>;
  perPage?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<UserSubscriptionWhereInput>;
};


export type QueryListNftCollectionsArgs = {
  orderBy?: InputMaybe<Array<NftCollectionOrderByWithRelationInput>>;
  page?: InputMaybe<Scalars['Int']>;
  perPage?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<NftCollectionWhereInput>;
};


export type QueryListPaymentWebhooksArgs = {
  orderBy?: InputMaybe<Array<PaymentWebhookOrderByWithRelationInput>>;
  page?: InputMaybe<Scalars['Int']>;
  perPage?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<PaymentWebhookWhereInput>;
};


export type QueryListPlaylistVideosArgs = {
  orderBy?: InputMaybe<Array<PlaylistVideoOrderByWithRelationInput>>;
  page?: InputMaybe<Scalars['Int']>;
  perPage?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<PlaylistVideoWhereInput>;
};


export type QueryListPlaylistsArgs = {
  orderBy?: InputMaybe<Array<PlaylistOrderByWithRelationInput>>;
  page?: InputMaybe<Scalars['Int']>;
  perPage?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<PlaylistWhereInput>;
};


export type QueryListPublicChannelsArgs = {
  orderBy?: InputMaybe<Array<ChannelOrderByWithRelationInput>>;
  page?: InputMaybe<Scalars['Int']>;
  perPage?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<ChannelWhereInput>;
};


export type QueryListPublicRollsArgs = {
  cursor?: InputMaybe<Scalars['String']>;
  firstId?: InputMaybe<Scalars['String']>;
  orderBy?: InputMaybe<Array<VideoOrderByWithRelationInput>>;
  page?: InputMaybe<Scalars['Int']>;
  perPage?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<VideoWhereInput>;
};


export type QueryListPublicRollsWithCursorArgs = {
  cursor?: InputMaybe<Scalars['String']>;
  firstId?: InputMaybe<Scalars['String']>;
  orderBy?: InputMaybe<Array<VideoOrderByWithRelationInput>>;
  page?: InputMaybe<Scalars['Int']>;
  perPage?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<VideoWhereInput>;
};


export type QueryListPublicVideosArgs = {
  cursor?: InputMaybe<Scalars['String']>;
  firstId?: InputMaybe<Scalars['String']>;
  orderBy?: InputMaybe<Array<VideoOrderByWithRelationInput>>;
  page?: InputMaybe<Scalars['Int']>;
  perPage?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<VideoWhereInput>;
};


export type QueryListPublicVideosWithCursorArgs = {
  cursor?: InputMaybe<Scalars['String']>;
  firstId?: InputMaybe<Scalars['String']>;
  orderBy?: InputMaybe<Array<VideoOrderByWithRelationInput>>;
  page?: InputMaybe<Scalars['Int']>;
  perPage?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<VideoWhereInput>;
};


export type QueryListSubscriptionPlansArgs = {
  orderBy?: InputMaybe<Array<SubscriptionPlanOrderByWithRelationInput>>;
  page?: InputMaybe<Scalars['Int']>;
  perPage?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<SubscriptionPlanWhereInput>;
};


export type QueryListTipsArgs = {
  orderBy?: InputMaybe<Array<TipsOrderByWithRelationInput>>;
  page?: InputMaybe<Scalars['Int']>;
  perPage?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<TipsWhereInput>;
};


export type QueryListTransactionsArgs = {
  orderBy?: InputMaybe<Array<TransactionOrderByWithRelationInput>>;
  page?: InputMaybe<Scalars['Int']>;
  perPage?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<TransactionWhereInput>;
};


export type QueryListTransactionsByUserIdArgs = {
  orderBy?: InputMaybe<Array<TransactionOrderByWithRelationInput>>;
  page?: InputMaybe<Scalars['Int']>;
  perPage?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<TransactionWhereInput>;
};


export type QueryListUserFollowedChannelRollsArgs = {
  cursor?: InputMaybe<Scalars['String']>;
  firstId?: InputMaybe<Scalars['String']>;
  orderBy?: InputMaybe<Array<VideoOrderByWithRelationInput>>;
  page?: InputMaybe<Scalars['Int']>;
  perPage?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<VideoWhereInput>;
};


export type QueryListUserFollowedChannelVideosArgs = {
  cursor?: InputMaybe<Scalars['String']>;
  firstId?: InputMaybe<Scalars['String']>;
  orderBy?: InputMaybe<Array<VideoOrderByWithRelationInput>>;
  page?: InputMaybe<Scalars['Int']>;
  perPage?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<VideoWhereInput>;
};


export type QueryListUserFollowedChannelsArgs = {
  orderBy?: InputMaybe<Array<ChannelOrderByWithRelationInput>>;
  page?: InputMaybe<Scalars['Int']>;
  perPage?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<ChannelWhereInput>;
};


export type QueryListUserLikedRollsArgs = {
  cursor?: InputMaybe<Scalars['String']>;
  firstId?: InputMaybe<Scalars['String']>;
  orderBy?: InputMaybe<Array<VideoOrderByWithRelationInput>>;
  page?: InputMaybe<Scalars['Int']>;
  perPage?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<VideoWhereInput>;
};


export type QueryListUserLikedVideoHistoriesArgs = {
  orderBy?: InputMaybe<Array<LikedVideoOrderByWithRelationInput>>;
  page?: InputMaybe<Scalars['Int']>;
  perPage?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<LikedVideoWhereInput>;
};


export type QueryListUserLikedVideosArgs = {
  cursor?: InputMaybe<Scalars['String']>;
  firstId?: InputMaybe<Scalars['String']>;
  orderBy?: InputMaybe<Array<VideoOrderByWithRelationInput>>;
  page?: InputMaybe<Scalars['Int']>;
  perPage?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<VideoWhereInput>;
};


export type QueryListUserLikedVideosByDateArgs = {
  where?: InputMaybe<LikedVideoWhereInput>;
};


export type QueryListUserPayPerViewsArgs = {
  orderBy?: InputMaybe<Array<UserPayPerViewOrderByWithRelationInput>>;
  page?: InputMaybe<Scalars['Int']>;
  perPage?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<UserPayPerViewWhereInput>;
};


export type QueryListUserSubscriptionsArgs = {
  orderBy?: InputMaybe<Array<UserSubscriptionOrderByWithRelationInput>>;
  page?: InputMaybe<Scalars['Int']>;
  perPage?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<UserSubscriptionWhereInput>;
};


export type QueryListUserWatchHistoriesArgs = {
  orderBy?: InputMaybe<Array<WatchHistoryOrderByWithRelationInput>>;
  page?: InputMaybe<Scalars['Int']>;
  perPage?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<WatchHistoryWhereInput>;
};


export type QueryListUserWatchedRollsArgs = {
  cursor?: InputMaybe<Scalars['String']>;
  firstId?: InputMaybe<Scalars['String']>;
  orderBy?: InputMaybe<Array<VideoOrderByWithRelationInput>>;
  page?: InputMaybe<Scalars['Int']>;
  perPage?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<VideoWhereInput>;
};


export type QueryListUserWatchedVideosArgs = {
  cursor?: InputMaybe<Scalars['String']>;
  firstId?: InputMaybe<Scalars['String']>;
  orderBy?: InputMaybe<Array<VideoOrderByWithRelationInput>>;
  page?: InputMaybe<Scalars['Int']>;
  perPage?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<VideoWhereInput>;
};


export type QueryListUserWatchedVideosByDateArgs = {
  orderBy?: InputMaybe<Array<WatchHistoryOrderByWithRelationInput>>;
  where?: InputMaybe<WatchHistoryWhereInput>;
};


export type QueryListUsersArgs = {
  orderBy?: InputMaybe<Array<UserOrderByWithRelationInput>>;
  page?: InputMaybe<Scalars['Int']>;
  perPage?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<UserWhereInput>;
};


export type QueryListVideosArgs = {
  cursor?: InputMaybe<Scalars['String']>;
  firstId?: InputMaybe<Scalars['String']>;
  orderBy?: InputMaybe<Array<VideoOrderByWithRelationInput>>;
  page?: InputMaybe<Scalars['Int']>;
  perPage?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<VideoWhereInput>;
};


export type QueryListWatchHistoriesArgs = {
  orderBy?: InputMaybe<Array<WatchHistoryOrderByWithRelationInput>>;
  page?: InputMaybe<Scalars['Int']>;
  perPage?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<WatchHistoryWhereInput>;
};


export type QueryMintedNftArgs = {
  where: MintedNftWhereUniqueInput;
};


export type QueryMintedNftsArgs = {
  cursor?: InputMaybe<MintedNftWhereUniqueInput>;
  distinct?: InputMaybe<Array<MintedNftScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<MintedNftOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<MintedNftWhereInput>;
};


export type QueryNftCollectionArgs = {
  where: NftCollectionWhereUniqueInput;
};


export type QueryNftCollectionsArgs = {
  cursor?: InputMaybe<NftCollectionWhereUniqueInput>;
  distinct?: InputMaybe<Array<NftCollectionScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<NftCollectionOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<NftCollectionWhereInput>;
};


export type QueryPaymentWebhookArgs = {
  where: PaymentWebhookWhereUniqueInput;
};


export type QueryPaymentWebhooksArgs = {
  cursor?: InputMaybe<PaymentWebhookWhereUniqueInput>;
  distinct?: InputMaybe<Array<PaymentWebhookScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<PaymentWebhookOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<PaymentWebhookWhereInput>;
};


export type QueryPlaylistArgs = {
  where: PlaylistWhereUniqueInput;
};


export type QueryPlaylistVideoArgs = {
  where: PlaylistVideoWhereUniqueInput;
};


export type QueryPlaylistVideosArgs = {
  cursor?: InputMaybe<PlaylistVideoWhereUniqueInput>;
  distinct?: InputMaybe<Array<PlaylistVideoScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<PlaylistVideoOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<PlaylistVideoWhereInput>;
};


export type QueryPlaylistsArgs = {
  cursor?: InputMaybe<PlaylistWhereUniqueInput>;
  distinct?: InputMaybe<Array<PlaylistScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<PlaylistOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<PlaylistWhereInput>;
};


export type QuerySubscriptionPlanArgs = {
  where: SubscriptionPlanWhereUniqueInput;
};


export type QuerySubscriptionPlansArgs = {
  cursor?: InputMaybe<SubscriptionPlanWhereUniqueInput>;
  distinct?: InputMaybe<Array<SubscriptionPlanScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<SubscriptionPlanOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<SubscriptionPlanWhereInput>;
};


export type QueryTransactionArgs = {
  where: TransactionWhereUniqueInput;
};


export type QueryTransactionsArgs = {
  cursor?: InputMaybe<TransactionWhereUniqueInput>;
  distinct?: InputMaybe<Array<TransactionScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<TransactionOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<TransactionWhereInput>;
};


export type QueryUserArgs = {
  where: UserWhereUniqueInput;
};


export type QueryUserPayPerViewArgs = {
  where: UserPayPerViewWhereUniqueInput;
};


export type QueryUserPayPerViewsArgs = {
  cursor?: InputMaybe<UserPayPerViewWhereUniqueInput>;
  distinct?: InputMaybe<Array<UserPayPerViewScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<UserPayPerViewOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<UserPayPerViewWhereInput>;
};


export type QueryUserSubscriptionArgs = {
  where: UserSubscriptionWhereUniqueInput;
};


export type QueryUserSubscriptionsArgs = {
  cursor?: InputMaybe<UserSubscriptionWhereUniqueInput>;
  distinct?: InputMaybe<Array<UserSubscriptionScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<UserSubscriptionOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<UserSubscriptionWhereInput>;
};


export type QueryUsersArgs = {
  cursor?: InputMaybe<UserWhereUniqueInput>;
  distinct?: InputMaybe<Array<UserScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<UserOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<UserWhereInput>;
};


export type QueryVideoArgs = {
  where: VideoWhereUniqueInput;
};


export type QueryVideosArgs = {
  cursor?: InputMaybe<VideoWhereUniqueInput>;
  distinct?: InputMaybe<Array<VideoScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<VideoOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<VideoWhereInput>;
};


export type QueryWatchHistoriesArgs = {
  cursor?: InputMaybe<WatchHistoryWhereUniqueInput>;
  distinct?: InputMaybe<Array<WatchHistoryScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<WatchHistoryOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<WatchHistoryWhereInput>;
};


export type QueryWatchHistoryArgs = {
  where: WatchHistoryWhereUniqueInput;
};

export enum QueryMode {
  Default = 'default',
  Insensitive = 'insensitive'
}

export type RegisterInput = {
  email: Scalars['String'];
  password: Scalars['String'];
  prestoUserId?: InputMaybe<Scalars['String']>;
  subscribeInfoAgreed: Scalars['Boolean'];
  tosAgreed: Scalars['Boolean'];
  username: Scalars['String'];
};

export type RequestOtpInput = {
  email: Scalars['String'];
  isResend?: InputMaybe<Scalars['Boolean']>;
  purpose: VerificationPurpose;
};

export type RequestOtpResponse = {
  __typename?: 'RequestOtpResponse';
  nextRequestOn?: Maybe<Scalars['DateTime']>;
  success: Scalars['Boolean'];
};

export type ResetPasswordInput = {
  otpChallengeID: Scalars['String'];
  otpToken: Scalars['String'];
  password: Scalars['String'];
};

export type ResetPasswordResponse = {
  __typename?: 'ResetPasswordResponse';
  success: Scalars['Boolean'];
};

export type SetupCompletedChannel = {
  __typename?: 'SetupCompletedChannel';
  image?: Maybe<Scalars['String']>;
  name: Scalars['String'];
  value: Scalars['String'];
};

export type ShortenedUrl = {
  __typename?: 'ShortenedUrl';
  archivedAt?: Maybe<Scalars['DateTime']>;
  channel: Channel;
  channelId: Scalars['String'];
  createdAt: Scalars['DateTime'];
  id: Scalars['String'];
  originalUrl: Scalars['String'];
  shortUrlId: Scalars['String'];
  updatedAt: Scalars['DateTime'];
};

export type ShortenedUrlCreateManyChannelInput = {
  archivedAt?: InputMaybe<Scalars['DateTime']>;
  createdAt?: InputMaybe<Scalars['DateTime']>;
  id?: InputMaybe<Scalars['String']>;
  originalUrl: Scalars['String'];
  shortUrlId: Scalars['String'];
  updatedAt?: InputMaybe<Scalars['DateTime']>;
};

export type ShortenedUrlCreateManyChannelInputEnvelope = {
  data: Array<ShortenedUrlCreateManyChannelInput>;
  skipDuplicates?: InputMaybe<Scalars['Boolean']>;
};

export type ShortenedUrlCreateNestedManyWithoutChannelInput = {
  connect?: InputMaybe<Array<ShortenedUrlWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<ShortenedUrlCreateOrConnectWithoutChannelInput>>;
  create?: InputMaybe<Array<ShortenedUrlCreateWithoutChannelInput>>;
  createMany?: InputMaybe<ShortenedUrlCreateManyChannelInputEnvelope>;
};

export type ShortenedUrlCreateOrConnectWithoutChannelInput = {
  create: ShortenedUrlCreateWithoutChannelInput;
  where: ShortenedUrlWhereUniqueInput;
};

export type ShortenedUrlCreateWithoutChannelInput = {
  archivedAt?: InputMaybe<Scalars['DateTime']>;
  createdAt?: InputMaybe<Scalars['DateTime']>;
  id?: InputMaybe<Scalars['String']>;
  originalUrl: Scalars['String'];
  shortUrlId: Scalars['String'];
  updatedAt?: InputMaybe<Scalars['DateTime']>;
};

export type ShortenedUrlListRelationFilter = {
  every?: InputMaybe<ShortenedUrlWhereInput>;
  none?: InputMaybe<ShortenedUrlWhereInput>;
  some?: InputMaybe<ShortenedUrlWhereInput>;
};

export type ShortenedUrlOrderByRelationAggregateInput = {
  _count?: InputMaybe<SortOrder>;
};

export type ShortenedUrlOrderByWithRelationInput = {
  archivedAt?: InputMaybe<SortOrder>;
  channel?: InputMaybe<ChannelOrderByWithRelationInput>;
  channelId?: InputMaybe<SortOrder>;
  createdAt?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  originalUrl?: InputMaybe<SortOrder>;
  shortUrlId?: InputMaybe<SortOrder>;
  updatedAt?: InputMaybe<SortOrder>;
};

export enum ShortenedUrlScalarFieldEnum {
  ArchivedAt = 'archivedAt',
  ChannelId = 'channelId',
  CreatedAt = 'createdAt',
  Id = 'id',
  OriginalUrl = 'originalUrl',
  ShortUrlId = 'shortUrlId',
  UpdatedAt = 'updatedAt'
}

export type ShortenedUrlScalarWhereInput = {
  AND?: InputMaybe<Array<ShortenedUrlScalarWhereInput>>;
  NOT?: InputMaybe<Array<ShortenedUrlScalarWhereInput>>;
  OR?: InputMaybe<Array<ShortenedUrlScalarWhereInput>>;
  archivedAt?: InputMaybe<DateTimeNullableFilter>;
  channelId?: InputMaybe<StringFilter>;
  createdAt?: InputMaybe<DateTimeFilter>;
  id?: InputMaybe<StringFilter>;
  originalUrl?: InputMaybe<StringFilter>;
  shortUrlId?: InputMaybe<StringFilter>;
  updatedAt?: InputMaybe<DateTimeFilter>;
};

export type ShortenedUrlUpdateManyMutationInput = {
  archivedAt?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  originalUrl?: InputMaybe<StringFieldUpdateOperationsInput>;
  shortUrlId?: InputMaybe<StringFieldUpdateOperationsInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
};

export type ShortenedUrlUpdateManyWithWhereWithoutChannelInput = {
  data: ShortenedUrlUpdateManyMutationInput;
  where: ShortenedUrlScalarWhereInput;
};

export type ShortenedUrlUpdateManyWithoutChannelInput = {
  connect?: InputMaybe<Array<ShortenedUrlWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<ShortenedUrlCreateOrConnectWithoutChannelInput>>;
  create?: InputMaybe<Array<ShortenedUrlCreateWithoutChannelInput>>;
  createMany?: InputMaybe<ShortenedUrlCreateManyChannelInputEnvelope>;
  delete?: InputMaybe<Array<ShortenedUrlWhereUniqueInput>>;
  deleteMany?: InputMaybe<Array<ShortenedUrlScalarWhereInput>>;
  disconnect?: InputMaybe<Array<ShortenedUrlWhereUniqueInput>>;
  set?: InputMaybe<Array<ShortenedUrlWhereUniqueInput>>;
  update?: InputMaybe<Array<ShortenedUrlUpdateWithWhereUniqueWithoutChannelInput>>;
  updateMany?: InputMaybe<Array<ShortenedUrlUpdateManyWithWhereWithoutChannelInput>>;
  upsert?: InputMaybe<Array<ShortenedUrlUpsertWithWhereUniqueWithoutChannelInput>>;
};

export type ShortenedUrlUpdateWithWhereUniqueWithoutChannelInput = {
  data: ShortenedUrlUpdateWithoutChannelInput;
  where: ShortenedUrlWhereUniqueInput;
};

export type ShortenedUrlUpdateWithoutChannelInput = {
  archivedAt?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  originalUrl?: InputMaybe<StringFieldUpdateOperationsInput>;
  shortUrlId?: InputMaybe<StringFieldUpdateOperationsInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
};

export type ShortenedUrlUpsertWithWhereUniqueWithoutChannelInput = {
  create: ShortenedUrlCreateWithoutChannelInput;
  update: ShortenedUrlUpdateWithoutChannelInput;
  where: ShortenedUrlWhereUniqueInput;
};

export type ShortenedUrlWhereInput = {
  AND?: InputMaybe<Array<ShortenedUrlWhereInput>>;
  NOT?: InputMaybe<Array<ShortenedUrlWhereInput>>;
  OR?: InputMaybe<Array<ShortenedUrlWhereInput>>;
  archivedAt?: InputMaybe<DateTimeNullableFilter>;
  channel?: InputMaybe<ChannelRelationFilter>;
  channelId?: InputMaybe<StringFilter>;
  createdAt?: InputMaybe<DateTimeFilter>;
  id?: InputMaybe<StringFilter>;
  originalUrl?: InputMaybe<StringFilter>;
  shortUrlId?: InputMaybe<StringFilter>;
  updatedAt?: InputMaybe<DateTimeFilter>;
};

export type ShortenedUrlWhereUniqueInput = {
  id?: InputMaybe<Scalars['String']>;
  shortUrlId?: InputMaybe<Scalars['String']>;
};

export type SignedFile = {
  __typename?: 'SignedFile';
  name: Scalars['String'];
  path: Scalars['String'];
  signedUrl: Scalars['String'];
  url: Scalars['String'];
};

export enum SortOrder {
  Asc = 'asc',
  Desc = 'desc'
}

export enum Status {
  Active = 'active',
  Inactive = 'inactive'
}

export type StringFieldUpdateOperationsInput = {
  set?: InputMaybe<Scalars['String']>;
};

export type StringFilter = {
  contains?: InputMaybe<Scalars['String']>;
  endsWith?: InputMaybe<Scalars['String']>;
  equals?: InputMaybe<Scalars['String']>;
  gt?: InputMaybe<Scalars['String']>;
  gte?: InputMaybe<Scalars['String']>;
  in?: InputMaybe<Array<Scalars['String']>>;
  lt?: InputMaybe<Scalars['String']>;
  lte?: InputMaybe<Scalars['String']>;
  mode?: InputMaybe<QueryMode>;
  not?: InputMaybe<NestedStringFilter>;
  notIn?: InputMaybe<Array<Scalars['String']>>;
  startsWith?: InputMaybe<Scalars['String']>;
};

export type StringNullableFilter = {
  contains?: InputMaybe<Scalars['String']>;
  endsWith?: InputMaybe<Scalars['String']>;
  equals?: InputMaybe<Scalars['String']>;
  gt?: InputMaybe<Scalars['String']>;
  gte?: InputMaybe<Scalars['String']>;
  in?: InputMaybe<Array<Scalars['String']>>;
  lt?: InputMaybe<Scalars['String']>;
  lte?: InputMaybe<Scalars['String']>;
  mode?: InputMaybe<QueryMode>;
  not?: InputMaybe<NestedStringNullableFilter>;
  notIn?: InputMaybe<Array<Scalars['String']>>;
  startsWith?: InputMaybe<Scalars['String']>;
};

export type StringNullableWithAggregatesFilter = {
  _count?: InputMaybe<NestedIntNullableFilter>;
  _max?: InputMaybe<NestedStringNullableFilter>;
  _min?: InputMaybe<NestedStringNullableFilter>;
  contains?: InputMaybe<Scalars['String']>;
  endsWith?: InputMaybe<Scalars['String']>;
  equals?: InputMaybe<Scalars['String']>;
  gt?: InputMaybe<Scalars['String']>;
  gte?: InputMaybe<Scalars['String']>;
  in?: InputMaybe<Array<Scalars['String']>>;
  lt?: InputMaybe<Scalars['String']>;
  lte?: InputMaybe<Scalars['String']>;
  mode?: InputMaybe<QueryMode>;
  not?: InputMaybe<NestedStringNullableWithAggregatesFilter>;
  notIn?: InputMaybe<Array<Scalars['String']>>;
  startsWith?: InputMaybe<Scalars['String']>;
};

export type StringWithAggregatesFilter = {
  _count?: InputMaybe<NestedIntFilter>;
  _max?: InputMaybe<NestedStringFilter>;
  _min?: InputMaybe<NestedStringFilter>;
  contains?: InputMaybe<Scalars['String']>;
  endsWith?: InputMaybe<Scalars['String']>;
  equals?: InputMaybe<Scalars['String']>;
  gt?: InputMaybe<Scalars['String']>;
  gte?: InputMaybe<Scalars['String']>;
  in?: InputMaybe<Array<Scalars['String']>>;
  lt?: InputMaybe<Scalars['String']>;
  lte?: InputMaybe<Scalars['String']>;
  mode?: InputMaybe<QueryMode>;
  not?: InputMaybe<NestedStringWithAggregatesFilter>;
  notIn?: InputMaybe<Array<Scalars['String']>>;
  startsWith?: InputMaybe<Scalars['String']>;
};

export type SubmitInquiryInput = {
  email: Scalars['String'];
  question: Scalars['String'];
};

export type SubscriptionPlan = {
  __typename?: 'SubscriptionPlan';
  _count?: Maybe<SubscriptionPlanCount>;
  archivedAt?: Maybe<Scalars['DateTime']>;
  channel: Channel;
  channelId: Scalars['String'];
  createdAt: Scalars['DateTime'];
  description: Scalars['String'];
  /** Display price of the gift in currency chosen by channel */
  displayPrice: Scalars['String'];
  duration: Scalars['Int'];
  id: Scalars['String'];
  name: Scalars['String'];
  /** Presentment price of the gift in currency charged by payment gateway */
  presentmentMoney: PresentmentMoney;
  price: Scalars['Float'];
  updatedAt: Scalars['DateTime'];
  userSubscriptions: Array<UserSubscription>;
};


export type SubscriptionPlanUserSubscriptionsArgs = {
  cursor?: InputMaybe<UserSubscriptionWhereUniqueInput>;
  distinct?: InputMaybe<Array<UserSubscriptionScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<UserSubscriptionOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<UserSubscriptionWhereInput>;
};

export type SubscriptionPlanAvgAggregate = {
  __typename?: 'SubscriptionPlanAvgAggregate';
  duration?: Maybe<Scalars['Float']>;
  price?: Maybe<Scalars['Float']>;
};

export type SubscriptionPlanAvgOrderByAggregateInput = {
  duration?: InputMaybe<SortOrder>;
  price?: InputMaybe<SortOrder>;
};

export type SubscriptionPlanCount = {
  __typename?: 'SubscriptionPlanCount';
  userSubscriptions: Scalars['Int'];
};

export type SubscriptionPlanCountAggregate = {
  __typename?: 'SubscriptionPlanCountAggregate';
  _all: Scalars['Int'];
  archivedAt: Scalars['Int'];
  channelId: Scalars['Int'];
  createdAt: Scalars['Int'];
  description: Scalars['Int'];
  duration: Scalars['Int'];
  id: Scalars['Int'];
  name: Scalars['Int'];
  price: Scalars['Int'];
  updatedAt: Scalars['Int'];
};

export type SubscriptionPlanCountOrderByAggregateInput = {
  archivedAt?: InputMaybe<SortOrder>;
  channelId?: InputMaybe<SortOrder>;
  createdAt?: InputMaybe<SortOrder>;
  description?: InputMaybe<SortOrder>;
  duration?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  name?: InputMaybe<SortOrder>;
  price?: InputMaybe<SortOrder>;
  updatedAt?: InputMaybe<SortOrder>;
};

export type SubscriptionPlanCreateInput = {
  archivedAt?: InputMaybe<Scalars['DateTime']>;
  channel: ChannelCreateNestedOneWithoutSubscriptionPlansInput;
  createdAt?: InputMaybe<Scalars['DateTime']>;
  description: Scalars['String'];
  duration: Scalars['Int'];
  id?: InputMaybe<Scalars['String']>;
  name: Scalars['String'];
  price: Scalars['Float'];
  updatedAt?: InputMaybe<Scalars['DateTime']>;
  userSubscriptions?: InputMaybe<UserSubscriptionCreateNestedManyWithoutSubscriptionPlanInput>;
};

export type SubscriptionPlanCreateManyChannelInput = {
  archivedAt?: InputMaybe<Scalars['DateTime']>;
  createdAt?: InputMaybe<Scalars['DateTime']>;
  description: Scalars['String'];
  duration: Scalars['Int'];
  id?: InputMaybe<Scalars['String']>;
  name: Scalars['String'];
  price: Scalars['Float'];
  updatedAt?: InputMaybe<Scalars['DateTime']>;
};

export type SubscriptionPlanCreateManyChannelInputEnvelope = {
  data: Array<SubscriptionPlanCreateManyChannelInput>;
  skipDuplicates?: InputMaybe<Scalars['Boolean']>;
};

export type SubscriptionPlanCreateManyInput = {
  archivedAt?: InputMaybe<Scalars['DateTime']>;
  channelId: Scalars['String'];
  createdAt?: InputMaybe<Scalars['DateTime']>;
  description: Scalars['String'];
  duration: Scalars['Int'];
  id?: InputMaybe<Scalars['String']>;
  name: Scalars['String'];
  price: Scalars['Float'];
  updatedAt?: InputMaybe<Scalars['DateTime']>;
};

export type SubscriptionPlanCreateNestedManyWithoutChannelInput = {
  connect?: InputMaybe<Array<SubscriptionPlanWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<SubscriptionPlanCreateOrConnectWithoutChannelInput>>;
  create?: InputMaybe<Array<SubscriptionPlanCreateWithoutChannelInput>>;
  createMany?: InputMaybe<SubscriptionPlanCreateManyChannelInputEnvelope>;
};

export type SubscriptionPlanCreateNestedOneWithoutUserSubscriptionsInput = {
  connect?: InputMaybe<SubscriptionPlanWhereUniqueInput>;
  connectOrCreate?: InputMaybe<SubscriptionPlanCreateOrConnectWithoutUserSubscriptionsInput>;
  create?: InputMaybe<SubscriptionPlanCreateWithoutUserSubscriptionsInput>;
};

export type SubscriptionPlanCreateOrConnectWithoutChannelInput = {
  create: SubscriptionPlanCreateWithoutChannelInput;
  where: SubscriptionPlanWhereUniqueInput;
};

export type SubscriptionPlanCreateOrConnectWithoutUserSubscriptionsInput = {
  create: SubscriptionPlanCreateWithoutUserSubscriptionsInput;
  where: SubscriptionPlanWhereUniqueInput;
};

export type SubscriptionPlanCreateWithoutChannelInput = {
  archivedAt?: InputMaybe<Scalars['DateTime']>;
  createdAt?: InputMaybe<Scalars['DateTime']>;
  description: Scalars['String'];
  duration: Scalars['Int'];
  id?: InputMaybe<Scalars['String']>;
  name: Scalars['String'];
  price: Scalars['Float'];
  updatedAt?: InputMaybe<Scalars['DateTime']>;
  userSubscriptions?: InputMaybe<UserSubscriptionCreateNestedManyWithoutSubscriptionPlanInput>;
};

export type SubscriptionPlanCreateWithoutUserSubscriptionsInput = {
  archivedAt?: InputMaybe<Scalars['DateTime']>;
  channel: ChannelCreateNestedOneWithoutSubscriptionPlansInput;
  createdAt?: InputMaybe<Scalars['DateTime']>;
  description: Scalars['String'];
  duration: Scalars['Int'];
  id?: InputMaybe<Scalars['String']>;
  name: Scalars['String'];
  price: Scalars['Float'];
  updatedAt?: InputMaybe<Scalars['DateTime']>;
};

export type SubscriptionPlanGroupBy = {
  __typename?: 'SubscriptionPlanGroupBy';
  _avg?: Maybe<SubscriptionPlanAvgAggregate>;
  _count?: Maybe<SubscriptionPlanCountAggregate>;
  _max?: Maybe<SubscriptionPlanMaxAggregate>;
  _min?: Maybe<SubscriptionPlanMinAggregate>;
  _sum?: Maybe<SubscriptionPlanSumAggregate>;
  archivedAt?: Maybe<Scalars['DateTime']>;
  channelId: Scalars['String'];
  createdAt: Scalars['DateTime'];
  description: Scalars['String'];
  duration: Scalars['Int'];
  id: Scalars['String'];
  name: Scalars['String'];
  price: Scalars['Float'];
  updatedAt: Scalars['DateTime'];
};

export type SubscriptionPlanListRelationFilter = {
  every?: InputMaybe<SubscriptionPlanWhereInput>;
  none?: InputMaybe<SubscriptionPlanWhereInput>;
  some?: InputMaybe<SubscriptionPlanWhereInput>;
};

export type SubscriptionPlanMaxAggregate = {
  __typename?: 'SubscriptionPlanMaxAggregate';
  archivedAt?: Maybe<Scalars['DateTime']>;
  channelId?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  description?: Maybe<Scalars['String']>;
  duration?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  price?: Maybe<Scalars['Float']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type SubscriptionPlanMaxOrderByAggregateInput = {
  archivedAt?: InputMaybe<SortOrder>;
  channelId?: InputMaybe<SortOrder>;
  createdAt?: InputMaybe<SortOrder>;
  description?: InputMaybe<SortOrder>;
  duration?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  name?: InputMaybe<SortOrder>;
  price?: InputMaybe<SortOrder>;
  updatedAt?: InputMaybe<SortOrder>;
};

export type SubscriptionPlanMinAggregate = {
  __typename?: 'SubscriptionPlanMinAggregate';
  archivedAt?: Maybe<Scalars['DateTime']>;
  channelId?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  description?: Maybe<Scalars['String']>;
  duration?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  price?: Maybe<Scalars['Float']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type SubscriptionPlanMinOrderByAggregateInput = {
  archivedAt?: InputMaybe<SortOrder>;
  channelId?: InputMaybe<SortOrder>;
  createdAt?: InputMaybe<SortOrder>;
  description?: InputMaybe<SortOrder>;
  duration?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  name?: InputMaybe<SortOrder>;
  price?: InputMaybe<SortOrder>;
  updatedAt?: InputMaybe<SortOrder>;
};

export type SubscriptionPlanOrderByRelationAggregateInput = {
  _count?: InputMaybe<SortOrder>;
};

export type SubscriptionPlanOrderByWithAggregationInput = {
  _avg?: InputMaybe<SubscriptionPlanAvgOrderByAggregateInput>;
  _count?: InputMaybe<SubscriptionPlanCountOrderByAggregateInput>;
  _max?: InputMaybe<SubscriptionPlanMaxOrderByAggregateInput>;
  _min?: InputMaybe<SubscriptionPlanMinOrderByAggregateInput>;
  _sum?: InputMaybe<SubscriptionPlanSumOrderByAggregateInput>;
  archivedAt?: InputMaybe<SortOrder>;
  channelId?: InputMaybe<SortOrder>;
  createdAt?: InputMaybe<SortOrder>;
  description?: InputMaybe<SortOrder>;
  duration?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  name?: InputMaybe<SortOrder>;
  price?: InputMaybe<SortOrder>;
  updatedAt?: InputMaybe<SortOrder>;
};

export type SubscriptionPlanOrderByWithRelationInput = {
  archivedAt?: InputMaybe<SortOrder>;
  channel?: InputMaybe<ChannelOrderByWithRelationInput>;
  channelId?: InputMaybe<SortOrder>;
  createdAt?: InputMaybe<SortOrder>;
  description?: InputMaybe<SortOrder>;
  duration?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  name?: InputMaybe<SortOrder>;
  price?: InputMaybe<SortOrder>;
  updatedAt?: InputMaybe<SortOrder>;
  userSubscriptions?: InputMaybe<UserSubscriptionOrderByRelationAggregateInput>;
};

export type SubscriptionPlanRelationFilter = {
  is?: InputMaybe<SubscriptionPlanWhereInput>;
  isNot?: InputMaybe<SubscriptionPlanWhereInput>;
};

export enum SubscriptionPlanScalarFieldEnum {
  ArchivedAt = 'archivedAt',
  ChannelId = 'channelId',
  CreatedAt = 'createdAt',
  Description = 'description',
  Duration = 'duration',
  Id = 'id',
  Name = 'name',
  Price = 'price',
  UpdatedAt = 'updatedAt'
}

export type SubscriptionPlanScalarWhereInput = {
  AND?: InputMaybe<Array<SubscriptionPlanScalarWhereInput>>;
  NOT?: InputMaybe<Array<SubscriptionPlanScalarWhereInput>>;
  OR?: InputMaybe<Array<SubscriptionPlanScalarWhereInput>>;
  archivedAt?: InputMaybe<DateTimeNullableFilter>;
  channelId?: InputMaybe<StringFilter>;
  createdAt?: InputMaybe<DateTimeFilter>;
  description?: InputMaybe<StringFilter>;
  duration?: InputMaybe<IntFilter>;
  id?: InputMaybe<StringFilter>;
  name?: InputMaybe<StringFilter>;
  price?: InputMaybe<FloatFilter>;
  updatedAt?: InputMaybe<DateTimeFilter>;
};

export type SubscriptionPlanScalarWhereWithAggregatesInput = {
  AND?: InputMaybe<Array<SubscriptionPlanScalarWhereWithAggregatesInput>>;
  NOT?: InputMaybe<Array<SubscriptionPlanScalarWhereWithAggregatesInput>>;
  OR?: InputMaybe<Array<SubscriptionPlanScalarWhereWithAggregatesInput>>;
  archivedAt?: InputMaybe<DateTimeNullableWithAggregatesFilter>;
  channelId?: InputMaybe<StringWithAggregatesFilter>;
  createdAt?: InputMaybe<DateTimeWithAggregatesFilter>;
  description?: InputMaybe<StringWithAggregatesFilter>;
  duration?: InputMaybe<IntWithAggregatesFilter>;
  id?: InputMaybe<StringWithAggregatesFilter>;
  name?: InputMaybe<StringWithAggregatesFilter>;
  price?: InputMaybe<FloatWithAggregatesFilter>;
  updatedAt?: InputMaybe<DateTimeWithAggregatesFilter>;
};

export type SubscriptionPlanSumAggregate = {
  __typename?: 'SubscriptionPlanSumAggregate';
  duration?: Maybe<Scalars['Int']>;
  price?: Maybe<Scalars['Float']>;
};

export type SubscriptionPlanSumOrderByAggregateInput = {
  duration?: InputMaybe<SortOrder>;
  price?: InputMaybe<SortOrder>;
};

export type SubscriptionPlanUpdateInput = {
  archivedAt?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  channel?: InputMaybe<ChannelUpdateOneRequiredWithoutSubscriptionPlansInput>;
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  description?: InputMaybe<StringFieldUpdateOperationsInput>;
  duration?: InputMaybe<IntFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  name?: InputMaybe<StringFieldUpdateOperationsInput>;
  price?: InputMaybe<FloatFieldUpdateOperationsInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  userSubscriptions?: InputMaybe<UserSubscriptionUpdateManyWithoutSubscriptionPlanInput>;
};

export type SubscriptionPlanUpdateManyMutationInput = {
  archivedAt?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  description?: InputMaybe<StringFieldUpdateOperationsInput>;
  duration?: InputMaybe<IntFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  name?: InputMaybe<StringFieldUpdateOperationsInput>;
  price?: InputMaybe<FloatFieldUpdateOperationsInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
};

export type SubscriptionPlanUpdateManyWithWhereWithoutChannelInput = {
  data: SubscriptionPlanUpdateManyMutationInput;
  where: SubscriptionPlanScalarWhereInput;
};

export type SubscriptionPlanUpdateManyWithoutChannelInput = {
  connect?: InputMaybe<Array<SubscriptionPlanWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<SubscriptionPlanCreateOrConnectWithoutChannelInput>>;
  create?: InputMaybe<Array<SubscriptionPlanCreateWithoutChannelInput>>;
  createMany?: InputMaybe<SubscriptionPlanCreateManyChannelInputEnvelope>;
  delete?: InputMaybe<Array<SubscriptionPlanWhereUniqueInput>>;
  deleteMany?: InputMaybe<Array<SubscriptionPlanScalarWhereInput>>;
  disconnect?: InputMaybe<Array<SubscriptionPlanWhereUniqueInput>>;
  set?: InputMaybe<Array<SubscriptionPlanWhereUniqueInput>>;
  update?: InputMaybe<Array<SubscriptionPlanUpdateWithWhereUniqueWithoutChannelInput>>;
  updateMany?: InputMaybe<Array<SubscriptionPlanUpdateManyWithWhereWithoutChannelInput>>;
  upsert?: InputMaybe<Array<SubscriptionPlanUpsertWithWhereUniqueWithoutChannelInput>>;
};

export type SubscriptionPlanUpdateOneRequiredWithoutUserSubscriptionsInput = {
  connect?: InputMaybe<SubscriptionPlanWhereUniqueInput>;
  connectOrCreate?: InputMaybe<SubscriptionPlanCreateOrConnectWithoutUserSubscriptionsInput>;
  create?: InputMaybe<SubscriptionPlanCreateWithoutUserSubscriptionsInput>;
  update?: InputMaybe<SubscriptionPlanUpdateWithoutUserSubscriptionsInput>;
  upsert?: InputMaybe<SubscriptionPlanUpsertWithoutUserSubscriptionsInput>;
};

export type SubscriptionPlanUpdateWithWhereUniqueWithoutChannelInput = {
  data: SubscriptionPlanUpdateWithoutChannelInput;
  where: SubscriptionPlanWhereUniqueInput;
};

export type SubscriptionPlanUpdateWithoutChannelInput = {
  archivedAt?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  description?: InputMaybe<StringFieldUpdateOperationsInput>;
  duration?: InputMaybe<IntFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  name?: InputMaybe<StringFieldUpdateOperationsInput>;
  price?: InputMaybe<FloatFieldUpdateOperationsInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  userSubscriptions?: InputMaybe<UserSubscriptionUpdateManyWithoutSubscriptionPlanInput>;
};

export type SubscriptionPlanUpdateWithoutUserSubscriptionsInput = {
  archivedAt?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  channel?: InputMaybe<ChannelUpdateOneRequiredWithoutSubscriptionPlansInput>;
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  description?: InputMaybe<StringFieldUpdateOperationsInput>;
  duration?: InputMaybe<IntFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  name?: InputMaybe<StringFieldUpdateOperationsInput>;
  price?: InputMaybe<FloatFieldUpdateOperationsInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
};

export type SubscriptionPlanUpsertWithWhereUniqueWithoutChannelInput = {
  create: SubscriptionPlanCreateWithoutChannelInput;
  update: SubscriptionPlanUpdateWithoutChannelInput;
  where: SubscriptionPlanWhereUniqueInput;
};

export type SubscriptionPlanUpsertWithoutUserSubscriptionsInput = {
  create: SubscriptionPlanCreateWithoutUserSubscriptionsInput;
  update: SubscriptionPlanUpdateWithoutUserSubscriptionsInput;
};

export type SubscriptionPlanWhereInput = {
  AND?: InputMaybe<Array<SubscriptionPlanWhereInput>>;
  NOT?: InputMaybe<Array<SubscriptionPlanWhereInput>>;
  OR?: InputMaybe<Array<SubscriptionPlanWhereInput>>;
  archivedAt?: InputMaybe<DateTimeNullableFilter>;
  channel?: InputMaybe<ChannelRelationFilter>;
  channelId?: InputMaybe<StringFilter>;
  createdAt?: InputMaybe<DateTimeFilter>;
  description?: InputMaybe<StringFilter>;
  duration?: InputMaybe<IntFilter>;
  id?: InputMaybe<StringFilter>;
  name?: InputMaybe<StringFilter>;
  price?: InputMaybe<FloatFilter>;
  updatedAt?: InputMaybe<DateTimeFilter>;
  userSubscriptions?: InputMaybe<UserSubscriptionListRelationFilter>;
};

export type SubscriptionPlanWhereUniqueInput = {
  id?: InputMaybe<Scalars['String']>;
};

export enum ThemeColor {
  Dark = 'DARK',
  White = 'WHITE'
}

export type Tips = {
  __typename?: 'Tips';
  archivedAt?: Maybe<Scalars['DateTime']>;
  channelId: Scalars['String'];
  createdAt: Scalars['DateTime'];
  id: Scalars['String'];
  message?: Maybe<Scalars['String']>;
  payer: User;
  recipient: Channel;
  transaction: Transaction;
  transactionId: Scalars['String'];
  updatedAt: Scalars['DateTime'];
  userId: Scalars['String'];
};

export type TipsCountAggregate = {
  __typename?: 'TipsCountAggregate';
  _all: Scalars['Int'];
  archivedAt: Scalars['Int'];
  channelId: Scalars['Int'];
  createdAt: Scalars['Int'];
  id: Scalars['Int'];
  message: Scalars['Int'];
  transactionId: Scalars['Int'];
  updatedAt: Scalars['Int'];
  userId: Scalars['Int'];
};

export type TipsCountOrderByAggregateInput = {
  archivedAt?: InputMaybe<SortOrder>;
  channelId?: InputMaybe<SortOrder>;
  createdAt?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  message?: InputMaybe<SortOrder>;
  transactionId?: InputMaybe<SortOrder>;
  updatedAt?: InputMaybe<SortOrder>;
  userId?: InputMaybe<SortOrder>;
};

export type TipsCreateInput = {
  archivedAt?: InputMaybe<Scalars['DateTime']>;
  createdAt?: InputMaybe<Scalars['DateTime']>;
  id?: InputMaybe<Scalars['String']>;
  message?: InputMaybe<Scalars['String']>;
  payer: UserCreateNestedOneWithoutUserTipsInput;
  recipient: ChannelCreateNestedOneWithoutReceivedTipsInput;
  transaction: TransactionCreateNestedOneWithoutTipsInput;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
};

export type TipsCreateManyInput = {
  archivedAt?: InputMaybe<Scalars['DateTime']>;
  channelId: Scalars['String'];
  createdAt?: InputMaybe<Scalars['DateTime']>;
  id?: InputMaybe<Scalars['String']>;
  message?: InputMaybe<Scalars['String']>;
  transactionId: Scalars['String'];
  updatedAt?: InputMaybe<Scalars['DateTime']>;
  userId: Scalars['String'];
};

export type TipsCreateManyPayerInput = {
  archivedAt?: InputMaybe<Scalars['DateTime']>;
  channelId: Scalars['String'];
  createdAt?: InputMaybe<Scalars['DateTime']>;
  id?: InputMaybe<Scalars['String']>;
  message?: InputMaybe<Scalars['String']>;
  transactionId: Scalars['String'];
  updatedAt?: InputMaybe<Scalars['DateTime']>;
};

export type TipsCreateManyPayerInputEnvelope = {
  data: Array<TipsCreateManyPayerInput>;
  skipDuplicates?: InputMaybe<Scalars['Boolean']>;
};

export type TipsCreateManyRecipientInput = {
  archivedAt?: InputMaybe<Scalars['DateTime']>;
  createdAt?: InputMaybe<Scalars['DateTime']>;
  id?: InputMaybe<Scalars['String']>;
  message?: InputMaybe<Scalars['String']>;
  transactionId: Scalars['String'];
  updatedAt?: InputMaybe<Scalars['DateTime']>;
  userId: Scalars['String'];
};

export type TipsCreateManyRecipientInputEnvelope = {
  data: Array<TipsCreateManyRecipientInput>;
  skipDuplicates?: InputMaybe<Scalars['Boolean']>;
};

export type TipsCreateNestedManyWithoutPayerInput = {
  connect?: InputMaybe<Array<TipsWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<TipsCreateOrConnectWithoutPayerInput>>;
  create?: InputMaybe<Array<TipsCreateWithoutPayerInput>>;
  createMany?: InputMaybe<TipsCreateManyPayerInputEnvelope>;
};

export type TipsCreateNestedManyWithoutRecipientInput = {
  connect?: InputMaybe<Array<TipsWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<TipsCreateOrConnectWithoutRecipientInput>>;
  create?: InputMaybe<Array<TipsCreateWithoutRecipientInput>>;
  createMany?: InputMaybe<TipsCreateManyRecipientInputEnvelope>;
};

export type TipsCreateNestedOneWithoutTransactionInput = {
  connect?: InputMaybe<TipsWhereUniqueInput>;
  connectOrCreate?: InputMaybe<TipsCreateOrConnectWithoutTransactionInput>;
  create?: InputMaybe<TipsCreateWithoutTransactionInput>;
};

export type TipsCreateOrConnectWithoutPayerInput = {
  create: TipsCreateWithoutPayerInput;
  where: TipsWhereUniqueInput;
};

export type TipsCreateOrConnectWithoutRecipientInput = {
  create: TipsCreateWithoutRecipientInput;
  where: TipsWhereUniqueInput;
};

export type TipsCreateOrConnectWithoutTransactionInput = {
  create: TipsCreateWithoutTransactionInput;
  where: TipsWhereUniqueInput;
};

export type TipsCreateWithoutPayerInput = {
  archivedAt?: InputMaybe<Scalars['DateTime']>;
  createdAt?: InputMaybe<Scalars['DateTime']>;
  id?: InputMaybe<Scalars['String']>;
  message?: InputMaybe<Scalars['String']>;
  recipient: ChannelCreateNestedOneWithoutReceivedTipsInput;
  transaction: TransactionCreateNestedOneWithoutTipsInput;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
};

export type TipsCreateWithoutRecipientInput = {
  archivedAt?: InputMaybe<Scalars['DateTime']>;
  createdAt?: InputMaybe<Scalars['DateTime']>;
  id?: InputMaybe<Scalars['String']>;
  message?: InputMaybe<Scalars['String']>;
  payer: UserCreateNestedOneWithoutUserTipsInput;
  transaction: TransactionCreateNestedOneWithoutTipsInput;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
};

export type TipsCreateWithoutTransactionInput = {
  archivedAt?: InputMaybe<Scalars['DateTime']>;
  createdAt?: InputMaybe<Scalars['DateTime']>;
  id?: InputMaybe<Scalars['String']>;
  message?: InputMaybe<Scalars['String']>;
  payer: UserCreateNestedOneWithoutUserTipsInput;
  recipient: ChannelCreateNestedOneWithoutReceivedTipsInput;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
};

export type TipsGroupBy = {
  __typename?: 'TipsGroupBy';
  _count?: Maybe<TipsCountAggregate>;
  _max?: Maybe<TipsMaxAggregate>;
  _min?: Maybe<TipsMinAggregate>;
  archivedAt?: Maybe<Scalars['DateTime']>;
  channelId: Scalars['String'];
  createdAt: Scalars['DateTime'];
  id: Scalars['String'];
  message?: Maybe<Scalars['String']>;
  transactionId: Scalars['String'];
  updatedAt: Scalars['DateTime'];
  userId: Scalars['String'];
};

export type TipsListRelationFilter = {
  every?: InputMaybe<TipsWhereInput>;
  none?: InputMaybe<TipsWhereInput>;
  some?: InputMaybe<TipsWhereInput>;
};

export type TipsMaxAggregate = {
  __typename?: 'TipsMaxAggregate';
  archivedAt?: Maybe<Scalars['DateTime']>;
  channelId?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  message?: Maybe<Scalars['String']>;
  transactionId?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  userId?: Maybe<Scalars['String']>;
};

export type TipsMaxOrderByAggregateInput = {
  archivedAt?: InputMaybe<SortOrder>;
  channelId?: InputMaybe<SortOrder>;
  createdAt?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  message?: InputMaybe<SortOrder>;
  transactionId?: InputMaybe<SortOrder>;
  updatedAt?: InputMaybe<SortOrder>;
  userId?: InputMaybe<SortOrder>;
};

export type TipsMinAggregate = {
  __typename?: 'TipsMinAggregate';
  archivedAt?: Maybe<Scalars['DateTime']>;
  channelId?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  message?: Maybe<Scalars['String']>;
  transactionId?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  userId?: Maybe<Scalars['String']>;
};

export type TipsMinOrderByAggregateInput = {
  archivedAt?: InputMaybe<SortOrder>;
  channelId?: InputMaybe<SortOrder>;
  createdAt?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  message?: InputMaybe<SortOrder>;
  transactionId?: InputMaybe<SortOrder>;
  updatedAt?: InputMaybe<SortOrder>;
  userId?: InputMaybe<SortOrder>;
};

export type TipsOrderByRelationAggregateInput = {
  _count?: InputMaybe<SortOrder>;
};

export type TipsOrderByWithAggregationInput = {
  _count?: InputMaybe<TipsCountOrderByAggregateInput>;
  _max?: InputMaybe<TipsMaxOrderByAggregateInput>;
  _min?: InputMaybe<TipsMinOrderByAggregateInput>;
  archivedAt?: InputMaybe<SortOrder>;
  channelId?: InputMaybe<SortOrder>;
  createdAt?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  message?: InputMaybe<SortOrder>;
  transactionId?: InputMaybe<SortOrder>;
  updatedAt?: InputMaybe<SortOrder>;
  userId?: InputMaybe<SortOrder>;
};

export type TipsOrderByWithRelationInput = {
  archivedAt?: InputMaybe<SortOrder>;
  channelId?: InputMaybe<SortOrder>;
  createdAt?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  message?: InputMaybe<SortOrder>;
  payer?: InputMaybe<UserOrderByWithRelationInput>;
  recipient?: InputMaybe<ChannelOrderByWithRelationInput>;
  transaction?: InputMaybe<TransactionOrderByWithRelationInput>;
  transactionId?: InputMaybe<SortOrder>;
  updatedAt?: InputMaybe<SortOrder>;
  userId?: InputMaybe<SortOrder>;
};

export type TipsRelationFilter = {
  is?: InputMaybe<TipsWhereInput>;
  isNot?: InputMaybe<TipsWhereInput>;
};

export enum TipsScalarFieldEnum {
  ArchivedAt = 'archivedAt',
  ChannelId = 'channelId',
  CreatedAt = 'createdAt',
  Id = 'id',
  Message = 'message',
  TransactionId = 'transactionId',
  UpdatedAt = 'updatedAt',
  UserId = 'userId'
}

export type TipsScalarWhereInput = {
  AND?: InputMaybe<Array<TipsScalarWhereInput>>;
  NOT?: InputMaybe<Array<TipsScalarWhereInput>>;
  OR?: InputMaybe<Array<TipsScalarWhereInput>>;
  archivedAt?: InputMaybe<DateTimeNullableFilter>;
  channelId?: InputMaybe<StringFilter>;
  createdAt?: InputMaybe<DateTimeFilter>;
  id?: InputMaybe<StringFilter>;
  message?: InputMaybe<StringNullableFilter>;
  transactionId?: InputMaybe<StringFilter>;
  updatedAt?: InputMaybe<DateTimeFilter>;
  userId?: InputMaybe<StringFilter>;
};

export type TipsScalarWhereWithAggregatesInput = {
  AND?: InputMaybe<Array<TipsScalarWhereWithAggregatesInput>>;
  NOT?: InputMaybe<Array<TipsScalarWhereWithAggregatesInput>>;
  OR?: InputMaybe<Array<TipsScalarWhereWithAggregatesInput>>;
  archivedAt?: InputMaybe<DateTimeNullableWithAggregatesFilter>;
  channelId?: InputMaybe<StringWithAggregatesFilter>;
  createdAt?: InputMaybe<DateTimeWithAggregatesFilter>;
  id?: InputMaybe<StringWithAggregatesFilter>;
  message?: InputMaybe<StringNullableWithAggregatesFilter>;
  transactionId?: InputMaybe<StringWithAggregatesFilter>;
  updatedAt?: InputMaybe<DateTimeWithAggregatesFilter>;
  userId?: InputMaybe<StringWithAggregatesFilter>;
};

export type TipsUpdateInput = {
  archivedAt?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  message?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  payer?: InputMaybe<UserUpdateOneRequiredWithoutUserTipsInput>;
  recipient?: InputMaybe<ChannelUpdateOneRequiredWithoutReceivedTipsInput>;
  transaction?: InputMaybe<TransactionUpdateOneRequiredWithoutTipsInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
};

export type TipsUpdateManyMutationInput = {
  archivedAt?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  message?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
};

export type TipsUpdateManyWithWhereWithoutPayerInput = {
  data: TipsUpdateManyMutationInput;
  where: TipsScalarWhereInput;
};

export type TipsUpdateManyWithWhereWithoutRecipientInput = {
  data: TipsUpdateManyMutationInput;
  where: TipsScalarWhereInput;
};

export type TipsUpdateManyWithoutPayerInput = {
  connect?: InputMaybe<Array<TipsWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<TipsCreateOrConnectWithoutPayerInput>>;
  create?: InputMaybe<Array<TipsCreateWithoutPayerInput>>;
  createMany?: InputMaybe<TipsCreateManyPayerInputEnvelope>;
  delete?: InputMaybe<Array<TipsWhereUniqueInput>>;
  deleteMany?: InputMaybe<Array<TipsScalarWhereInput>>;
  disconnect?: InputMaybe<Array<TipsWhereUniqueInput>>;
  set?: InputMaybe<Array<TipsWhereUniqueInput>>;
  update?: InputMaybe<Array<TipsUpdateWithWhereUniqueWithoutPayerInput>>;
  updateMany?: InputMaybe<Array<TipsUpdateManyWithWhereWithoutPayerInput>>;
  upsert?: InputMaybe<Array<TipsUpsertWithWhereUniqueWithoutPayerInput>>;
};

export type TipsUpdateManyWithoutRecipientInput = {
  connect?: InputMaybe<Array<TipsWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<TipsCreateOrConnectWithoutRecipientInput>>;
  create?: InputMaybe<Array<TipsCreateWithoutRecipientInput>>;
  createMany?: InputMaybe<TipsCreateManyRecipientInputEnvelope>;
  delete?: InputMaybe<Array<TipsWhereUniqueInput>>;
  deleteMany?: InputMaybe<Array<TipsScalarWhereInput>>;
  disconnect?: InputMaybe<Array<TipsWhereUniqueInput>>;
  set?: InputMaybe<Array<TipsWhereUniqueInput>>;
  update?: InputMaybe<Array<TipsUpdateWithWhereUniqueWithoutRecipientInput>>;
  updateMany?: InputMaybe<Array<TipsUpdateManyWithWhereWithoutRecipientInput>>;
  upsert?: InputMaybe<Array<TipsUpsertWithWhereUniqueWithoutRecipientInput>>;
};

export type TipsUpdateOneWithoutTransactionInput = {
  connect?: InputMaybe<TipsWhereUniqueInput>;
  connectOrCreate?: InputMaybe<TipsCreateOrConnectWithoutTransactionInput>;
  create?: InputMaybe<TipsCreateWithoutTransactionInput>;
  delete?: InputMaybe<Scalars['Boolean']>;
  disconnect?: InputMaybe<Scalars['Boolean']>;
  update?: InputMaybe<TipsUpdateWithoutTransactionInput>;
  upsert?: InputMaybe<TipsUpsertWithoutTransactionInput>;
};

export type TipsUpdateWithWhereUniqueWithoutPayerInput = {
  data: TipsUpdateWithoutPayerInput;
  where: TipsWhereUniqueInput;
};

export type TipsUpdateWithWhereUniqueWithoutRecipientInput = {
  data: TipsUpdateWithoutRecipientInput;
  where: TipsWhereUniqueInput;
};

export type TipsUpdateWithoutPayerInput = {
  archivedAt?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  message?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  recipient?: InputMaybe<ChannelUpdateOneRequiredWithoutReceivedTipsInput>;
  transaction?: InputMaybe<TransactionUpdateOneRequiredWithoutTipsInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
};

export type TipsUpdateWithoutRecipientInput = {
  archivedAt?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  message?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  payer?: InputMaybe<UserUpdateOneRequiredWithoutUserTipsInput>;
  transaction?: InputMaybe<TransactionUpdateOneRequiredWithoutTipsInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
};

export type TipsUpdateWithoutTransactionInput = {
  archivedAt?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  message?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  payer?: InputMaybe<UserUpdateOneRequiredWithoutUserTipsInput>;
  recipient?: InputMaybe<ChannelUpdateOneRequiredWithoutReceivedTipsInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
};

export type TipsUpsertWithWhereUniqueWithoutPayerInput = {
  create: TipsCreateWithoutPayerInput;
  update: TipsUpdateWithoutPayerInput;
  where: TipsWhereUniqueInput;
};

export type TipsUpsertWithWhereUniqueWithoutRecipientInput = {
  create: TipsCreateWithoutRecipientInput;
  update: TipsUpdateWithoutRecipientInput;
  where: TipsWhereUniqueInput;
};

export type TipsUpsertWithoutTransactionInput = {
  create: TipsCreateWithoutTransactionInput;
  update: TipsUpdateWithoutTransactionInput;
};

export type TipsWhereInput = {
  AND?: InputMaybe<Array<TipsWhereInput>>;
  NOT?: InputMaybe<Array<TipsWhereInput>>;
  OR?: InputMaybe<Array<TipsWhereInput>>;
  archivedAt?: InputMaybe<DateTimeNullableFilter>;
  channelId?: InputMaybe<StringFilter>;
  createdAt?: InputMaybe<DateTimeFilter>;
  id?: InputMaybe<StringFilter>;
  message?: InputMaybe<StringNullableFilter>;
  payer?: InputMaybe<UserRelationFilter>;
  recipient?: InputMaybe<ChannelRelationFilter>;
  transaction?: InputMaybe<TransactionRelationFilter>;
  transactionId?: InputMaybe<StringFilter>;
  updatedAt?: InputMaybe<DateTimeFilter>;
  userId?: InputMaybe<StringFilter>;
};

export type TipsWhereUniqueInput = {
  id?: InputMaybe<Scalars['String']>;
  transactionId?: InputMaybe<Scalars['String']>;
};

export type Transaction = {
  __typename?: 'Transaction';
  PaymentWebhook: Array<PaymentWebhook>;
  _count?: Maybe<TransactionCount>;
  amount: Scalars['Int'];
  archivedAt?: Maybe<Scalars['DateTime']>;
  channelId: Scalars['String'];
  channelName?: Maybe<Scalars['String']>;
  createdAt: Scalars['DateTime'];
  creatorRevenue?: Maybe<Scalars['Float']>;
  currencyCode: CurrencyCode;
  description?: Maybe<Scalars['String']>;
  displayPrice: Scalars['String'];
  giftDistribution?: Maybe<GiftDistribution>;
  id: Scalars['String'];
  lineItem?: Maybe<Scalars['JSON']>;
  lineItemDescription?: Maybe<Scalars['String']>;
  lineItemName?: Maybe<Scalars['String']>;
  paymentBy: User;
  paymentGateway: PaymentGateway;
  paymentRefNumber?: Maybe<Scalars['String']>;
  paymentTo: Channel;
  settlementAmount: Scalars['Int'];
  settlementCurrencyCode: CurrencyCode;
  settlementToPresentmentExchangeRates: Scalars['Float'];
  status: PaymentStatus;
  tips?: Maybe<Tips>;
  type?: Maybe<ProductType>;
  updatedAt: Scalars['DateTime'];
  userId: Scalars['String'];
  userPayPerView?: Maybe<UserPayPerView>;
  userSubscription?: Maybe<UserSubscription>;
};


export type TransactionPaymentWebhookArgs = {
  cursor?: InputMaybe<PaymentWebhookWhereUniqueInput>;
  distinct?: InputMaybe<Array<PaymentWebhookScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<PaymentWebhookOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<PaymentWebhookWhereInput>;
};

export type TransactionAvgAggregate = {
  __typename?: 'TransactionAvgAggregate';
  amount?: Maybe<Scalars['Float']>;
  settlementAmount?: Maybe<Scalars['Float']>;
  settlementToPresentmentExchangeRates?: Maybe<Scalars['Float']>;
};

export type TransactionAvgOrderByAggregateInput = {
  amount?: InputMaybe<SortOrder>;
  settlementAmount?: InputMaybe<SortOrder>;
  settlementToPresentmentExchangeRates?: InputMaybe<SortOrder>;
};

export type TransactionCount = {
  __typename?: 'TransactionCount';
  PaymentWebhook: Scalars['Int'];
};

export type TransactionCountAggregate = {
  __typename?: 'TransactionCountAggregate';
  _all: Scalars['Int'];
  amount: Scalars['Int'];
  archivedAt: Scalars['Int'];
  channelId: Scalars['Int'];
  channelName: Scalars['Int'];
  createdAt: Scalars['Int'];
  currencyCode: Scalars['Int'];
  description: Scalars['Int'];
  id: Scalars['Int'];
  lineItem: Scalars['Int'];
  paymentGateway: Scalars['Int'];
  paymentRefNumber: Scalars['Int'];
  settlementAmount: Scalars['Int'];
  settlementCurrencyCode: Scalars['Int'];
  settlementToPresentmentExchangeRates: Scalars['Int'];
  status: Scalars['Int'];
  type: Scalars['Int'];
  updatedAt: Scalars['Int'];
  userId: Scalars['Int'];
};

export type TransactionCountOrderByAggregateInput = {
  amount?: InputMaybe<SortOrder>;
  archivedAt?: InputMaybe<SortOrder>;
  channelId?: InputMaybe<SortOrder>;
  channelName?: InputMaybe<SortOrder>;
  createdAt?: InputMaybe<SortOrder>;
  currencyCode?: InputMaybe<SortOrder>;
  description?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  lineItem?: InputMaybe<SortOrder>;
  paymentGateway?: InputMaybe<SortOrder>;
  paymentRefNumber?: InputMaybe<SortOrder>;
  settlementAmount?: InputMaybe<SortOrder>;
  settlementCurrencyCode?: InputMaybe<SortOrder>;
  settlementToPresentmentExchangeRates?: InputMaybe<SortOrder>;
  status?: InputMaybe<SortOrder>;
  type?: InputMaybe<SortOrder>;
  updatedAt?: InputMaybe<SortOrder>;
  userId?: InputMaybe<SortOrder>;
};

export type TransactionCreateInput = {
  PaymentWebhook?: InputMaybe<PaymentWebhookCreateNestedManyWithoutTransactionInput>;
  amount: Scalars['Int'];
  archivedAt?: InputMaybe<Scalars['DateTime']>;
  channelName?: InputMaybe<Scalars['String']>;
  createdAt?: InputMaybe<Scalars['DateTime']>;
  currencyCode: CurrencyCode;
  description?: InputMaybe<Scalars['String']>;
  giftDistribution?: InputMaybe<GiftDistributionCreateNestedOneWithoutTransactionInput>;
  id?: InputMaybe<Scalars['String']>;
  lineItem?: InputMaybe<Scalars['JSON']>;
  paymentBy: UserCreateNestedOneWithoutTransactionsInput;
  paymentGateway?: InputMaybe<PaymentGateway>;
  paymentRefNumber?: InputMaybe<Scalars['String']>;
  paymentTo: ChannelCreateNestedOneWithoutReceivedTransactionsInput;
  settlementAmount?: InputMaybe<Scalars['Int']>;
  settlementCurrencyCode?: InputMaybe<CurrencyCode>;
  settlementToPresentmentExchangeRates?: InputMaybe<Scalars['Float']>;
  status?: InputMaybe<PaymentStatus>;
  tips?: InputMaybe<TipsCreateNestedOneWithoutTransactionInput>;
  type?: InputMaybe<ProductType>;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
  userPayPerView?: InputMaybe<UserPayPerViewCreateNestedOneWithoutTransactionInput>;
  userSubscription?: InputMaybe<UserSubscriptionCreateNestedOneWithoutTransactionInput>;
};

export type TransactionCreateManyInput = {
  amount: Scalars['Int'];
  archivedAt?: InputMaybe<Scalars['DateTime']>;
  channelId: Scalars['String'];
  channelName?: InputMaybe<Scalars['String']>;
  createdAt?: InputMaybe<Scalars['DateTime']>;
  currencyCode: CurrencyCode;
  description?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['String']>;
  lineItem?: InputMaybe<Scalars['JSON']>;
  paymentGateway?: InputMaybe<PaymentGateway>;
  paymentRefNumber?: InputMaybe<Scalars['String']>;
  settlementAmount?: InputMaybe<Scalars['Int']>;
  settlementCurrencyCode?: InputMaybe<CurrencyCode>;
  settlementToPresentmentExchangeRates?: InputMaybe<Scalars['Float']>;
  status?: InputMaybe<PaymentStatus>;
  type?: InputMaybe<ProductType>;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
  userId: Scalars['String'];
};

export type TransactionCreateManyPaymentByInput = {
  amount: Scalars['Int'];
  archivedAt?: InputMaybe<Scalars['DateTime']>;
  channelId: Scalars['String'];
  channelName?: InputMaybe<Scalars['String']>;
  createdAt?: InputMaybe<Scalars['DateTime']>;
  currencyCode: CurrencyCode;
  description?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['String']>;
  lineItem?: InputMaybe<Scalars['JSON']>;
  paymentGateway?: InputMaybe<PaymentGateway>;
  paymentRefNumber?: InputMaybe<Scalars['String']>;
  settlementAmount?: InputMaybe<Scalars['Int']>;
  settlementCurrencyCode?: InputMaybe<CurrencyCode>;
  settlementToPresentmentExchangeRates?: InputMaybe<Scalars['Float']>;
  status?: InputMaybe<PaymentStatus>;
  type?: InputMaybe<ProductType>;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
};

export type TransactionCreateManyPaymentByInputEnvelope = {
  data: Array<TransactionCreateManyPaymentByInput>;
  skipDuplicates?: InputMaybe<Scalars['Boolean']>;
};

export type TransactionCreateManyPaymentToInput = {
  amount: Scalars['Int'];
  archivedAt?: InputMaybe<Scalars['DateTime']>;
  channelName?: InputMaybe<Scalars['String']>;
  createdAt?: InputMaybe<Scalars['DateTime']>;
  currencyCode: CurrencyCode;
  description?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['String']>;
  lineItem?: InputMaybe<Scalars['JSON']>;
  paymentGateway?: InputMaybe<PaymentGateway>;
  paymentRefNumber?: InputMaybe<Scalars['String']>;
  settlementAmount?: InputMaybe<Scalars['Int']>;
  settlementCurrencyCode?: InputMaybe<CurrencyCode>;
  settlementToPresentmentExchangeRates?: InputMaybe<Scalars['Float']>;
  status?: InputMaybe<PaymentStatus>;
  type?: InputMaybe<ProductType>;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
  userId: Scalars['String'];
};

export type TransactionCreateManyPaymentToInputEnvelope = {
  data: Array<TransactionCreateManyPaymentToInput>;
  skipDuplicates?: InputMaybe<Scalars['Boolean']>;
};

export type TransactionCreateNestedManyWithoutPaymentByInput = {
  connect?: InputMaybe<Array<TransactionWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<TransactionCreateOrConnectWithoutPaymentByInput>>;
  create?: InputMaybe<Array<TransactionCreateWithoutPaymentByInput>>;
  createMany?: InputMaybe<TransactionCreateManyPaymentByInputEnvelope>;
};

export type TransactionCreateNestedManyWithoutPaymentToInput = {
  connect?: InputMaybe<Array<TransactionWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<TransactionCreateOrConnectWithoutPaymentToInput>>;
  create?: InputMaybe<Array<TransactionCreateWithoutPaymentToInput>>;
  createMany?: InputMaybe<TransactionCreateManyPaymentToInputEnvelope>;
};

export type TransactionCreateNestedOneWithoutGiftDistributionInput = {
  connect?: InputMaybe<TransactionWhereUniqueInput>;
  connectOrCreate?: InputMaybe<TransactionCreateOrConnectWithoutGiftDistributionInput>;
  create?: InputMaybe<TransactionCreateWithoutGiftDistributionInput>;
};

export type TransactionCreateNestedOneWithoutPaymentWebhookInput = {
  connect?: InputMaybe<TransactionWhereUniqueInput>;
  connectOrCreate?: InputMaybe<TransactionCreateOrConnectWithoutPaymentWebhookInput>;
  create?: InputMaybe<TransactionCreateWithoutPaymentWebhookInput>;
};

export type TransactionCreateNestedOneWithoutTipsInput = {
  connect?: InputMaybe<TransactionWhereUniqueInput>;
  connectOrCreate?: InputMaybe<TransactionCreateOrConnectWithoutTipsInput>;
  create?: InputMaybe<TransactionCreateWithoutTipsInput>;
};

export type TransactionCreateNestedOneWithoutUserPayPerViewInput = {
  connect?: InputMaybe<TransactionWhereUniqueInput>;
  connectOrCreate?: InputMaybe<TransactionCreateOrConnectWithoutUserPayPerViewInput>;
  create?: InputMaybe<TransactionCreateWithoutUserPayPerViewInput>;
};

export type TransactionCreateNestedOneWithoutUserSubscriptionInput = {
  connect?: InputMaybe<TransactionWhereUniqueInput>;
  connectOrCreate?: InputMaybe<TransactionCreateOrConnectWithoutUserSubscriptionInput>;
  create?: InputMaybe<TransactionCreateWithoutUserSubscriptionInput>;
};

export type TransactionCreateOrConnectWithoutGiftDistributionInput = {
  create: TransactionCreateWithoutGiftDistributionInput;
  where: TransactionWhereUniqueInput;
};

export type TransactionCreateOrConnectWithoutPaymentByInput = {
  create: TransactionCreateWithoutPaymentByInput;
  where: TransactionWhereUniqueInput;
};

export type TransactionCreateOrConnectWithoutPaymentToInput = {
  create: TransactionCreateWithoutPaymentToInput;
  where: TransactionWhereUniqueInput;
};

export type TransactionCreateOrConnectWithoutPaymentWebhookInput = {
  create: TransactionCreateWithoutPaymentWebhookInput;
  where: TransactionWhereUniqueInput;
};

export type TransactionCreateOrConnectWithoutTipsInput = {
  create: TransactionCreateWithoutTipsInput;
  where: TransactionWhereUniqueInput;
};

export type TransactionCreateOrConnectWithoutUserPayPerViewInput = {
  create: TransactionCreateWithoutUserPayPerViewInput;
  where: TransactionWhereUniqueInput;
};

export type TransactionCreateOrConnectWithoutUserSubscriptionInput = {
  create: TransactionCreateWithoutUserSubscriptionInput;
  where: TransactionWhereUniqueInput;
};

export type TransactionCreateWithoutGiftDistributionInput = {
  PaymentWebhook?: InputMaybe<PaymentWebhookCreateNestedManyWithoutTransactionInput>;
  amount: Scalars['Int'];
  archivedAt?: InputMaybe<Scalars['DateTime']>;
  channelName?: InputMaybe<Scalars['String']>;
  createdAt?: InputMaybe<Scalars['DateTime']>;
  currencyCode: CurrencyCode;
  description?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['String']>;
  lineItem?: InputMaybe<Scalars['JSON']>;
  paymentBy: UserCreateNestedOneWithoutTransactionsInput;
  paymentGateway?: InputMaybe<PaymentGateway>;
  paymentRefNumber?: InputMaybe<Scalars['String']>;
  paymentTo: ChannelCreateNestedOneWithoutReceivedTransactionsInput;
  settlementAmount?: InputMaybe<Scalars['Int']>;
  settlementCurrencyCode?: InputMaybe<CurrencyCode>;
  settlementToPresentmentExchangeRates?: InputMaybe<Scalars['Float']>;
  status?: InputMaybe<PaymentStatus>;
  tips?: InputMaybe<TipsCreateNestedOneWithoutTransactionInput>;
  type?: InputMaybe<ProductType>;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
  userPayPerView?: InputMaybe<UserPayPerViewCreateNestedOneWithoutTransactionInput>;
  userSubscription?: InputMaybe<UserSubscriptionCreateNestedOneWithoutTransactionInput>;
};

export type TransactionCreateWithoutPaymentByInput = {
  PaymentWebhook?: InputMaybe<PaymentWebhookCreateNestedManyWithoutTransactionInput>;
  amount: Scalars['Int'];
  archivedAt?: InputMaybe<Scalars['DateTime']>;
  channelName?: InputMaybe<Scalars['String']>;
  createdAt?: InputMaybe<Scalars['DateTime']>;
  currencyCode: CurrencyCode;
  description?: InputMaybe<Scalars['String']>;
  giftDistribution?: InputMaybe<GiftDistributionCreateNestedOneWithoutTransactionInput>;
  id?: InputMaybe<Scalars['String']>;
  lineItem?: InputMaybe<Scalars['JSON']>;
  paymentGateway?: InputMaybe<PaymentGateway>;
  paymentRefNumber?: InputMaybe<Scalars['String']>;
  paymentTo: ChannelCreateNestedOneWithoutReceivedTransactionsInput;
  settlementAmount?: InputMaybe<Scalars['Int']>;
  settlementCurrencyCode?: InputMaybe<CurrencyCode>;
  settlementToPresentmentExchangeRates?: InputMaybe<Scalars['Float']>;
  status?: InputMaybe<PaymentStatus>;
  tips?: InputMaybe<TipsCreateNestedOneWithoutTransactionInput>;
  type?: InputMaybe<ProductType>;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
  userPayPerView?: InputMaybe<UserPayPerViewCreateNestedOneWithoutTransactionInput>;
  userSubscription?: InputMaybe<UserSubscriptionCreateNestedOneWithoutTransactionInput>;
};

export type TransactionCreateWithoutPaymentToInput = {
  PaymentWebhook?: InputMaybe<PaymentWebhookCreateNestedManyWithoutTransactionInput>;
  amount: Scalars['Int'];
  archivedAt?: InputMaybe<Scalars['DateTime']>;
  channelName?: InputMaybe<Scalars['String']>;
  createdAt?: InputMaybe<Scalars['DateTime']>;
  currencyCode: CurrencyCode;
  description?: InputMaybe<Scalars['String']>;
  giftDistribution?: InputMaybe<GiftDistributionCreateNestedOneWithoutTransactionInput>;
  id?: InputMaybe<Scalars['String']>;
  lineItem?: InputMaybe<Scalars['JSON']>;
  paymentBy: UserCreateNestedOneWithoutTransactionsInput;
  paymentGateway?: InputMaybe<PaymentGateway>;
  paymentRefNumber?: InputMaybe<Scalars['String']>;
  settlementAmount?: InputMaybe<Scalars['Int']>;
  settlementCurrencyCode?: InputMaybe<CurrencyCode>;
  settlementToPresentmentExchangeRates?: InputMaybe<Scalars['Float']>;
  status?: InputMaybe<PaymentStatus>;
  tips?: InputMaybe<TipsCreateNestedOneWithoutTransactionInput>;
  type?: InputMaybe<ProductType>;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
  userPayPerView?: InputMaybe<UserPayPerViewCreateNestedOneWithoutTransactionInput>;
  userSubscription?: InputMaybe<UserSubscriptionCreateNestedOneWithoutTransactionInput>;
};

export type TransactionCreateWithoutPaymentWebhookInput = {
  amount: Scalars['Int'];
  archivedAt?: InputMaybe<Scalars['DateTime']>;
  channelName?: InputMaybe<Scalars['String']>;
  createdAt?: InputMaybe<Scalars['DateTime']>;
  currencyCode: CurrencyCode;
  description?: InputMaybe<Scalars['String']>;
  giftDistribution?: InputMaybe<GiftDistributionCreateNestedOneWithoutTransactionInput>;
  id?: InputMaybe<Scalars['String']>;
  lineItem?: InputMaybe<Scalars['JSON']>;
  paymentBy: UserCreateNestedOneWithoutTransactionsInput;
  paymentGateway?: InputMaybe<PaymentGateway>;
  paymentRefNumber?: InputMaybe<Scalars['String']>;
  paymentTo: ChannelCreateNestedOneWithoutReceivedTransactionsInput;
  settlementAmount?: InputMaybe<Scalars['Int']>;
  settlementCurrencyCode?: InputMaybe<CurrencyCode>;
  settlementToPresentmentExchangeRates?: InputMaybe<Scalars['Float']>;
  status?: InputMaybe<PaymentStatus>;
  tips?: InputMaybe<TipsCreateNestedOneWithoutTransactionInput>;
  type?: InputMaybe<ProductType>;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
  userPayPerView?: InputMaybe<UserPayPerViewCreateNestedOneWithoutTransactionInput>;
  userSubscription?: InputMaybe<UserSubscriptionCreateNestedOneWithoutTransactionInput>;
};

export type TransactionCreateWithoutTipsInput = {
  PaymentWebhook?: InputMaybe<PaymentWebhookCreateNestedManyWithoutTransactionInput>;
  amount: Scalars['Int'];
  archivedAt?: InputMaybe<Scalars['DateTime']>;
  channelName?: InputMaybe<Scalars['String']>;
  createdAt?: InputMaybe<Scalars['DateTime']>;
  currencyCode: CurrencyCode;
  description?: InputMaybe<Scalars['String']>;
  giftDistribution?: InputMaybe<GiftDistributionCreateNestedOneWithoutTransactionInput>;
  id?: InputMaybe<Scalars['String']>;
  lineItem?: InputMaybe<Scalars['JSON']>;
  paymentBy: UserCreateNestedOneWithoutTransactionsInput;
  paymentGateway?: InputMaybe<PaymentGateway>;
  paymentRefNumber?: InputMaybe<Scalars['String']>;
  paymentTo: ChannelCreateNestedOneWithoutReceivedTransactionsInput;
  settlementAmount?: InputMaybe<Scalars['Int']>;
  settlementCurrencyCode?: InputMaybe<CurrencyCode>;
  settlementToPresentmentExchangeRates?: InputMaybe<Scalars['Float']>;
  status?: InputMaybe<PaymentStatus>;
  type?: InputMaybe<ProductType>;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
  userPayPerView?: InputMaybe<UserPayPerViewCreateNestedOneWithoutTransactionInput>;
  userSubscription?: InputMaybe<UserSubscriptionCreateNestedOneWithoutTransactionInput>;
};

export type TransactionCreateWithoutUserPayPerViewInput = {
  PaymentWebhook?: InputMaybe<PaymentWebhookCreateNestedManyWithoutTransactionInput>;
  amount: Scalars['Int'];
  archivedAt?: InputMaybe<Scalars['DateTime']>;
  channelName?: InputMaybe<Scalars['String']>;
  createdAt?: InputMaybe<Scalars['DateTime']>;
  currencyCode: CurrencyCode;
  description?: InputMaybe<Scalars['String']>;
  giftDistribution?: InputMaybe<GiftDistributionCreateNestedOneWithoutTransactionInput>;
  id?: InputMaybe<Scalars['String']>;
  lineItem?: InputMaybe<Scalars['JSON']>;
  paymentBy: UserCreateNestedOneWithoutTransactionsInput;
  paymentGateway?: InputMaybe<PaymentGateway>;
  paymentRefNumber?: InputMaybe<Scalars['String']>;
  paymentTo: ChannelCreateNestedOneWithoutReceivedTransactionsInput;
  settlementAmount?: InputMaybe<Scalars['Int']>;
  settlementCurrencyCode?: InputMaybe<CurrencyCode>;
  settlementToPresentmentExchangeRates?: InputMaybe<Scalars['Float']>;
  status?: InputMaybe<PaymentStatus>;
  tips?: InputMaybe<TipsCreateNestedOneWithoutTransactionInput>;
  type?: InputMaybe<ProductType>;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
  userSubscription?: InputMaybe<UserSubscriptionCreateNestedOneWithoutTransactionInput>;
};

export type TransactionCreateWithoutUserSubscriptionInput = {
  PaymentWebhook?: InputMaybe<PaymentWebhookCreateNestedManyWithoutTransactionInput>;
  amount: Scalars['Int'];
  archivedAt?: InputMaybe<Scalars['DateTime']>;
  channelName?: InputMaybe<Scalars['String']>;
  createdAt?: InputMaybe<Scalars['DateTime']>;
  currencyCode: CurrencyCode;
  description?: InputMaybe<Scalars['String']>;
  giftDistribution?: InputMaybe<GiftDistributionCreateNestedOneWithoutTransactionInput>;
  id?: InputMaybe<Scalars['String']>;
  lineItem?: InputMaybe<Scalars['JSON']>;
  paymentBy: UserCreateNestedOneWithoutTransactionsInput;
  paymentGateway?: InputMaybe<PaymentGateway>;
  paymentRefNumber?: InputMaybe<Scalars['String']>;
  paymentTo: ChannelCreateNestedOneWithoutReceivedTransactionsInput;
  settlementAmount?: InputMaybe<Scalars['Int']>;
  settlementCurrencyCode?: InputMaybe<CurrencyCode>;
  settlementToPresentmentExchangeRates?: InputMaybe<Scalars['Float']>;
  status?: InputMaybe<PaymentStatus>;
  tips?: InputMaybe<TipsCreateNestedOneWithoutTransactionInput>;
  type?: InputMaybe<ProductType>;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
  userPayPerView?: InputMaybe<UserPayPerViewCreateNestedOneWithoutTransactionInput>;
};

export type TransactionGroupBy = {
  __typename?: 'TransactionGroupBy';
  _avg?: Maybe<TransactionAvgAggregate>;
  _count?: Maybe<TransactionCountAggregate>;
  _max?: Maybe<TransactionMaxAggregate>;
  _min?: Maybe<TransactionMinAggregate>;
  _sum?: Maybe<TransactionSumAggregate>;
  amount: Scalars['Int'];
  archivedAt?: Maybe<Scalars['DateTime']>;
  channelId: Scalars['String'];
  channelName?: Maybe<Scalars['String']>;
  createdAt: Scalars['DateTime'];
  currencyCode: CurrencyCode;
  description?: Maybe<Scalars['String']>;
  id: Scalars['String'];
  lineItem?: Maybe<Scalars['JSON']>;
  paymentGateway: PaymentGateway;
  paymentRefNumber?: Maybe<Scalars['String']>;
  settlementAmount: Scalars['Int'];
  settlementCurrencyCode: CurrencyCode;
  settlementToPresentmentExchangeRates: Scalars['Float'];
  status: PaymentStatus;
  type?: Maybe<ProductType>;
  updatedAt: Scalars['DateTime'];
  userId: Scalars['String'];
};

export type TransactionListRelationFilter = {
  every?: InputMaybe<TransactionWhereInput>;
  none?: InputMaybe<TransactionWhereInput>;
  some?: InputMaybe<TransactionWhereInput>;
};

export type TransactionMaxAggregate = {
  __typename?: 'TransactionMaxAggregate';
  amount?: Maybe<Scalars['Int']>;
  archivedAt?: Maybe<Scalars['DateTime']>;
  channelId?: Maybe<Scalars['String']>;
  channelName?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  currencyCode?: Maybe<CurrencyCode>;
  description?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  paymentGateway?: Maybe<PaymentGateway>;
  paymentRefNumber?: Maybe<Scalars['String']>;
  settlementAmount?: Maybe<Scalars['Int']>;
  settlementCurrencyCode?: Maybe<CurrencyCode>;
  settlementToPresentmentExchangeRates?: Maybe<Scalars['Float']>;
  status?: Maybe<PaymentStatus>;
  type?: Maybe<ProductType>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  userId?: Maybe<Scalars['String']>;
};

export type TransactionMaxOrderByAggregateInput = {
  amount?: InputMaybe<SortOrder>;
  archivedAt?: InputMaybe<SortOrder>;
  channelId?: InputMaybe<SortOrder>;
  channelName?: InputMaybe<SortOrder>;
  createdAt?: InputMaybe<SortOrder>;
  currencyCode?: InputMaybe<SortOrder>;
  description?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  paymentGateway?: InputMaybe<SortOrder>;
  paymentRefNumber?: InputMaybe<SortOrder>;
  settlementAmount?: InputMaybe<SortOrder>;
  settlementCurrencyCode?: InputMaybe<SortOrder>;
  settlementToPresentmentExchangeRates?: InputMaybe<SortOrder>;
  status?: InputMaybe<SortOrder>;
  type?: InputMaybe<SortOrder>;
  updatedAt?: InputMaybe<SortOrder>;
  userId?: InputMaybe<SortOrder>;
};

export type TransactionMinAggregate = {
  __typename?: 'TransactionMinAggregate';
  amount?: Maybe<Scalars['Int']>;
  archivedAt?: Maybe<Scalars['DateTime']>;
  channelId?: Maybe<Scalars['String']>;
  channelName?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  currencyCode?: Maybe<CurrencyCode>;
  description?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  paymentGateway?: Maybe<PaymentGateway>;
  paymentRefNumber?: Maybe<Scalars['String']>;
  settlementAmount?: Maybe<Scalars['Int']>;
  settlementCurrencyCode?: Maybe<CurrencyCode>;
  settlementToPresentmentExchangeRates?: Maybe<Scalars['Float']>;
  status?: Maybe<PaymentStatus>;
  type?: Maybe<ProductType>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  userId?: Maybe<Scalars['String']>;
};

export type TransactionMinOrderByAggregateInput = {
  amount?: InputMaybe<SortOrder>;
  archivedAt?: InputMaybe<SortOrder>;
  channelId?: InputMaybe<SortOrder>;
  channelName?: InputMaybe<SortOrder>;
  createdAt?: InputMaybe<SortOrder>;
  currencyCode?: InputMaybe<SortOrder>;
  description?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  paymentGateway?: InputMaybe<SortOrder>;
  paymentRefNumber?: InputMaybe<SortOrder>;
  settlementAmount?: InputMaybe<SortOrder>;
  settlementCurrencyCode?: InputMaybe<SortOrder>;
  settlementToPresentmentExchangeRates?: InputMaybe<SortOrder>;
  status?: InputMaybe<SortOrder>;
  type?: InputMaybe<SortOrder>;
  updatedAt?: InputMaybe<SortOrder>;
  userId?: InputMaybe<SortOrder>;
};

export type TransactionOrderByRelationAggregateInput = {
  _count?: InputMaybe<SortOrder>;
};

export type TransactionOrderByWithAggregationInput = {
  _avg?: InputMaybe<TransactionAvgOrderByAggregateInput>;
  _count?: InputMaybe<TransactionCountOrderByAggregateInput>;
  _max?: InputMaybe<TransactionMaxOrderByAggregateInput>;
  _min?: InputMaybe<TransactionMinOrderByAggregateInput>;
  _sum?: InputMaybe<TransactionSumOrderByAggregateInput>;
  amount?: InputMaybe<SortOrder>;
  archivedAt?: InputMaybe<SortOrder>;
  channelId?: InputMaybe<SortOrder>;
  channelName?: InputMaybe<SortOrder>;
  createdAt?: InputMaybe<SortOrder>;
  currencyCode?: InputMaybe<SortOrder>;
  description?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  lineItem?: InputMaybe<SortOrder>;
  paymentGateway?: InputMaybe<SortOrder>;
  paymentRefNumber?: InputMaybe<SortOrder>;
  settlementAmount?: InputMaybe<SortOrder>;
  settlementCurrencyCode?: InputMaybe<SortOrder>;
  settlementToPresentmentExchangeRates?: InputMaybe<SortOrder>;
  status?: InputMaybe<SortOrder>;
  type?: InputMaybe<SortOrder>;
  updatedAt?: InputMaybe<SortOrder>;
  userId?: InputMaybe<SortOrder>;
};

export type TransactionOrderByWithRelationInput = {
  PaymentWebhook?: InputMaybe<PaymentWebhookOrderByRelationAggregateInput>;
  amount?: InputMaybe<SortOrder>;
  archivedAt?: InputMaybe<SortOrder>;
  channelId?: InputMaybe<SortOrder>;
  channelName?: InputMaybe<SortOrder>;
  createdAt?: InputMaybe<SortOrder>;
  currencyCode?: InputMaybe<SortOrder>;
  description?: InputMaybe<SortOrder>;
  giftDistribution?: InputMaybe<GiftDistributionOrderByWithRelationInput>;
  id?: InputMaybe<SortOrder>;
  lineItem?: InputMaybe<SortOrder>;
  paymentBy?: InputMaybe<UserOrderByWithRelationInput>;
  paymentGateway?: InputMaybe<SortOrder>;
  paymentRefNumber?: InputMaybe<SortOrder>;
  paymentTo?: InputMaybe<ChannelOrderByWithRelationInput>;
  settlementAmount?: InputMaybe<SortOrder>;
  settlementCurrencyCode?: InputMaybe<SortOrder>;
  settlementToPresentmentExchangeRates?: InputMaybe<SortOrder>;
  status?: InputMaybe<SortOrder>;
  tips?: InputMaybe<TipsOrderByWithRelationInput>;
  type?: InputMaybe<SortOrder>;
  updatedAt?: InputMaybe<SortOrder>;
  userId?: InputMaybe<SortOrder>;
  userPayPerView?: InputMaybe<UserPayPerViewOrderByWithRelationInput>;
  userSubscription?: InputMaybe<UserSubscriptionOrderByWithRelationInput>;
};

export type TransactionRelationFilter = {
  is?: InputMaybe<TransactionWhereInput>;
  isNot?: InputMaybe<TransactionWhereInput>;
};

export enum TransactionScalarFieldEnum {
  Amount = 'amount',
  ArchivedAt = 'archivedAt',
  ChannelId = 'channelId',
  ChannelName = 'channelName',
  CreatedAt = 'createdAt',
  CurrencyCode = 'currencyCode',
  Description = 'description',
  Id = 'id',
  LineItem = 'lineItem',
  PaymentGateway = 'paymentGateway',
  PaymentRefNumber = 'paymentRefNumber',
  SettlementAmount = 'settlementAmount',
  SettlementCurrencyCode = 'settlementCurrencyCode',
  SettlementToPresentmentExchangeRates = 'settlementToPresentmentExchangeRates',
  Status = 'status',
  Type = 'type',
  UpdatedAt = 'updatedAt',
  UserId = 'userId'
}

export type TransactionScalarWhereInput = {
  AND?: InputMaybe<Array<TransactionScalarWhereInput>>;
  NOT?: InputMaybe<Array<TransactionScalarWhereInput>>;
  OR?: InputMaybe<Array<TransactionScalarWhereInput>>;
  amount?: InputMaybe<IntFilter>;
  archivedAt?: InputMaybe<DateTimeNullableFilter>;
  channelId?: InputMaybe<StringFilter>;
  channelName?: InputMaybe<StringNullableFilter>;
  createdAt?: InputMaybe<DateTimeFilter>;
  currencyCode?: InputMaybe<EnumCurrencyCodeFilter>;
  description?: InputMaybe<StringNullableFilter>;
  id?: InputMaybe<StringFilter>;
  lineItem?: InputMaybe<JsonNullableFilter>;
  paymentGateway?: InputMaybe<EnumPaymentGatewayFilter>;
  paymentRefNumber?: InputMaybe<StringNullableFilter>;
  settlementAmount?: InputMaybe<IntFilter>;
  settlementCurrencyCode?: InputMaybe<EnumCurrencyCodeFilter>;
  settlementToPresentmentExchangeRates?: InputMaybe<FloatFilter>;
  status?: InputMaybe<EnumPaymentStatusFilter>;
  type?: InputMaybe<EnumProductTypeNullableFilter>;
  updatedAt?: InputMaybe<DateTimeFilter>;
  userId?: InputMaybe<StringFilter>;
};

export type TransactionScalarWhereWithAggregatesInput = {
  AND?: InputMaybe<Array<TransactionScalarWhereWithAggregatesInput>>;
  NOT?: InputMaybe<Array<TransactionScalarWhereWithAggregatesInput>>;
  OR?: InputMaybe<Array<TransactionScalarWhereWithAggregatesInput>>;
  amount?: InputMaybe<IntWithAggregatesFilter>;
  archivedAt?: InputMaybe<DateTimeNullableWithAggregatesFilter>;
  channelId?: InputMaybe<StringWithAggregatesFilter>;
  channelName?: InputMaybe<StringNullableWithAggregatesFilter>;
  createdAt?: InputMaybe<DateTimeWithAggregatesFilter>;
  currencyCode?: InputMaybe<EnumCurrencyCodeWithAggregatesFilter>;
  description?: InputMaybe<StringNullableWithAggregatesFilter>;
  id?: InputMaybe<StringWithAggregatesFilter>;
  lineItem?: InputMaybe<JsonNullableWithAggregatesFilter>;
  paymentGateway?: InputMaybe<EnumPaymentGatewayWithAggregatesFilter>;
  paymentRefNumber?: InputMaybe<StringNullableWithAggregatesFilter>;
  settlementAmount?: InputMaybe<IntWithAggregatesFilter>;
  settlementCurrencyCode?: InputMaybe<EnumCurrencyCodeWithAggregatesFilter>;
  settlementToPresentmentExchangeRates?: InputMaybe<FloatWithAggregatesFilter>;
  status?: InputMaybe<EnumPaymentStatusWithAggregatesFilter>;
  type?: InputMaybe<EnumProductTypeNullableWithAggregatesFilter>;
  updatedAt?: InputMaybe<DateTimeWithAggregatesFilter>;
  userId?: InputMaybe<StringWithAggregatesFilter>;
};

export type TransactionSumAggregate = {
  __typename?: 'TransactionSumAggregate';
  amount?: Maybe<Scalars['Int']>;
  settlementAmount?: Maybe<Scalars['Int']>;
  settlementToPresentmentExchangeRates?: Maybe<Scalars['Float']>;
};

export type TransactionSumOrderByAggregateInput = {
  amount?: InputMaybe<SortOrder>;
  settlementAmount?: InputMaybe<SortOrder>;
  settlementToPresentmentExchangeRates?: InputMaybe<SortOrder>;
};

export type TransactionSummary = {
  __typename?: 'TransactionSummary';
  amount: Scalars['Int'];
  currencyCode: CurrencyCode;
  gift: Scalars['Int'];
  payPerView: Scalars['Int'];
  subscription: Scalars['Int'];
  tips: Scalars['Int'];
};

export type TransactionSummaryInput = {
  channelId?: InputMaybe<Scalars['String']>;
  dateFrom?: InputMaybe<Scalars['DateTime']>;
  dateTo?: InputMaybe<Scalars['DateTime']>;
};

export type TransactionUpdateInput = {
  PaymentWebhook?: InputMaybe<PaymentWebhookUpdateManyWithoutTransactionInput>;
  amount?: InputMaybe<IntFieldUpdateOperationsInput>;
  archivedAt?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  channelName?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  currencyCode?: InputMaybe<EnumCurrencyCodeFieldUpdateOperationsInput>;
  description?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  giftDistribution?: InputMaybe<GiftDistributionUpdateOneWithoutTransactionInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  lineItem?: InputMaybe<Scalars['JSON']>;
  paymentBy?: InputMaybe<UserUpdateOneRequiredWithoutTransactionsInput>;
  paymentGateway?: InputMaybe<EnumPaymentGatewayFieldUpdateOperationsInput>;
  paymentRefNumber?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  paymentTo?: InputMaybe<ChannelUpdateOneRequiredWithoutReceivedTransactionsInput>;
  settlementAmount?: InputMaybe<IntFieldUpdateOperationsInput>;
  settlementCurrencyCode?: InputMaybe<EnumCurrencyCodeFieldUpdateOperationsInput>;
  settlementToPresentmentExchangeRates?: InputMaybe<FloatFieldUpdateOperationsInput>;
  status?: InputMaybe<EnumPaymentStatusFieldUpdateOperationsInput>;
  tips?: InputMaybe<TipsUpdateOneWithoutTransactionInput>;
  type?: InputMaybe<NullableEnumProductTypeFieldUpdateOperationsInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  userPayPerView?: InputMaybe<UserPayPerViewUpdateOneWithoutTransactionInput>;
  userSubscription?: InputMaybe<UserSubscriptionUpdateOneWithoutTransactionInput>;
};

export type TransactionUpdateManyMutationInput = {
  amount?: InputMaybe<IntFieldUpdateOperationsInput>;
  archivedAt?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  channelName?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  currencyCode?: InputMaybe<EnumCurrencyCodeFieldUpdateOperationsInput>;
  description?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  lineItem?: InputMaybe<Scalars['JSON']>;
  paymentGateway?: InputMaybe<EnumPaymentGatewayFieldUpdateOperationsInput>;
  paymentRefNumber?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  settlementAmount?: InputMaybe<IntFieldUpdateOperationsInput>;
  settlementCurrencyCode?: InputMaybe<EnumCurrencyCodeFieldUpdateOperationsInput>;
  settlementToPresentmentExchangeRates?: InputMaybe<FloatFieldUpdateOperationsInput>;
  status?: InputMaybe<EnumPaymentStatusFieldUpdateOperationsInput>;
  type?: InputMaybe<NullableEnumProductTypeFieldUpdateOperationsInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
};

export type TransactionUpdateManyWithWhereWithoutPaymentByInput = {
  data: TransactionUpdateManyMutationInput;
  where: TransactionScalarWhereInput;
};

export type TransactionUpdateManyWithWhereWithoutPaymentToInput = {
  data: TransactionUpdateManyMutationInput;
  where: TransactionScalarWhereInput;
};

export type TransactionUpdateManyWithoutPaymentByInput = {
  connect?: InputMaybe<Array<TransactionWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<TransactionCreateOrConnectWithoutPaymentByInput>>;
  create?: InputMaybe<Array<TransactionCreateWithoutPaymentByInput>>;
  createMany?: InputMaybe<TransactionCreateManyPaymentByInputEnvelope>;
  delete?: InputMaybe<Array<TransactionWhereUniqueInput>>;
  deleteMany?: InputMaybe<Array<TransactionScalarWhereInput>>;
  disconnect?: InputMaybe<Array<TransactionWhereUniqueInput>>;
  set?: InputMaybe<Array<TransactionWhereUniqueInput>>;
  update?: InputMaybe<Array<TransactionUpdateWithWhereUniqueWithoutPaymentByInput>>;
  updateMany?: InputMaybe<Array<TransactionUpdateManyWithWhereWithoutPaymentByInput>>;
  upsert?: InputMaybe<Array<TransactionUpsertWithWhereUniqueWithoutPaymentByInput>>;
};

export type TransactionUpdateManyWithoutPaymentToInput = {
  connect?: InputMaybe<Array<TransactionWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<TransactionCreateOrConnectWithoutPaymentToInput>>;
  create?: InputMaybe<Array<TransactionCreateWithoutPaymentToInput>>;
  createMany?: InputMaybe<TransactionCreateManyPaymentToInputEnvelope>;
  delete?: InputMaybe<Array<TransactionWhereUniqueInput>>;
  deleteMany?: InputMaybe<Array<TransactionScalarWhereInput>>;
  disconnect?: InputMaybe<Array<TransactionWhereUniqueInput>>;
  set?: InputMaybe<Array<TransactionWhereUniqueInput>>;
  update?: InputMaybe<Array<TransactionUpdateWithWhereUniqueWithoutPaymentToInput>>;
  updateMany?: InputMaybe<Array<TransactionUpdateManyWithWhereWithoutPaymentToInput>>;
  upsert?: InputMaybe<Array<TransactionUpsertWithWhereUniqueWithoutPaymentToInput>>;
};

export type TransactionUpdateOneRequiredWithoutGiftDistributionInput = {
  connect?: InputMaybe<TransactionWhereUniqueInput>;
  connectOrCreate?: InputMaybe<TransactionCreateOrConnectWithoutGiftDistributionInput>;
  create?: InputMaybe<TransactionCreateWithoutGiftDistributionInput>;
  update?: InputMaybe<TransactionUpdateWithoutGiftDistributionInput>;
  upsert?: InputMaybe<TransactionUpsertWithoutGiftDistributionInput>;
};

export type TransactionUpdateOneRequiredWithoutPaymentWebhookInput = {
  connect?: InputMaybe<TransactionWhereUniqueInput>;
  connectOrCreate?: InputMaybe<TransactionCreateOrConnectWithoutPaymentWebhookInput>;
  create?: InputMaybe<TransactionCreateWithoutPaymentWebhookInput>;
  update?: InputMaybe<TransactionUpdateWithoutPaymentWebhookInput>;
  upsert?: InputMaybe<TransactionUpsertWithoutPaymentWebhookInput>;
};

export type TransactionUpdateOneRequiredWithoutTipsInput = {
  connect?: InputMaybe<TransactionWhereUniqueInput>;
  connectOrCreate?: InputMaybe<TransactionCreateOrConnectWithoutTipsInput>;
  create?: InputMaybe<TransactionCreateWithoutTipsInput>;
  update?: InputMaybe<TransactionUpdateWithoutTipsInput>;
  upsert?: InputMaybe<TransactionUpsertWithoutTipsInput>;
};

export type TransactionUpdateOneRequiredWithoutUserPayPerViewInput = {
  connect?: InputMaybe<TransactionWhereUniqueInput>;
  connectOrCreate?: InputMaybe<TransactionCreateOrConnectWithoutUserPayPerViewInput>;
  create?: InputMaybe<TransactionCreateWithoutUserPayPerViewInput>;
  update?: InputMaybe<TransactionUpdateWithoutUserPayPerViewInput>;
  upsert?: InputMaybe<TransactionUpsertWithoutUserPayPerViewInput>;
};

export type TransactionUpdateOneRequiredWithoutUserSubscriptionInput = {
  connect?: InputMaybe<TransactionWhereUniqueInput>;
  connectOrCreate?: InputMaybe<TransactionCreateOrConnectWithoutUserSubscriptionInput>;
  create?: InputMaybe<TransactionCreateWithoutUserSubscriptionInput>;
  update?: InputMaybe<TransactionUpdateWithoutUserSubscriptionInput>;
  upsert?: InputMaybe<TransactionUpsertWithoutUserSubscriptionInput>;
};

export type TransactionUpdateWithWhereUniqueWithoutPaymentByInput = {
  data: TransactionUpdateWithoutPaymentByInput;
  where: TransactionWhereUniqueInput;
};

export type TransactionUpdateWithWhereUniqueWithoutPaymentToInput = {
  data: TransactionUpdateWithoutPaymentToInput;
  where: TransactionWhereUniqueInput;
};

export type TransactionUpdateWithoutGiftDistributionInput = {
  PaymentWebhook?: InputMaybe<PaymentWebhookUpdateManyWithoutTransactionInput>;
  amount?: InputMaybe<IntFieldUpdateOperationsInput>;
  archivedAt?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  channelName?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  currencyCode?: InputMaybe<EnumCurrencyCodeFieldUpdateOperationsInput>;
  description?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  lineItem?: InputMaybe<Scalars['JSON']>;
  paymentBy?: InputMaybe<UserUpdateOneRequiredWithoutTransactionsInput>;
  paymentGateway?: InputMaybe<EnumPaymentGatewayFieldUpdateOperationsInput>;
  paymentRefNumber?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  paymentTo?: InputMaybe<ChannelUpdateOneRequiredWithoutReceivedTransactionsInput>;
  settlementAmount?: InputMaybe<IntFieldUpdateOperationsInput>;
  settlementCurrencyCode?: InputMaybe<EnumCurrencyCodeFieldUpdateOperationsInput>;
  settlementToPresentmentExchangeRates?: InputMaybe<FloatFieldUpdateOperationsInput>;
  status?: InputMaybe<EnumPaymentStatusFieldUpdateOperationsInput>;
  tips?: InputMaybe<TipsUpdateOneWithoutTransactionInput>;
  type?: InputMaybe<NullableEnumProductTypeFieldUpdateOperationsInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  userPayPerView?: InputMaybe<UserPayPerViewUpdateOneWithoutTransactionInput>;
  userSubscription?: InputMaybe<UserSubscriptionUpdateOneWithoutTransactionInput>;
};

export type TransactionUpdateWithoutPaymentByInput = {
  PaymentWebhook?: InputMaybe<PaymentWebhookUpdateManyWithoutTransactionInput>;
  amount?: InputMaybe<IntFieldUpdateOperationsInput>;
  archivedAt?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  channelName?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  currencyCode?: InputMaybe<EnumCurrencyCodeFieldUpdateOperationsInput>;
  description?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  giftDistribution?: InputMaybe<GiftDistributionUpdateOneWithoutTransactionInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  lineItem?: InputMaybe<Scalars['JSON']>;
  paymentGateway?: InputMaybe<EnumPaymentGatewayFieldUpdateOperationsInput>;
  paymentRefNumber?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  paymentTo?: InputMaybe<ChannelUpdateOneRequiredWithoutReceivedTransactionsInput>;
  settlementAmount?: InputMaybe<IntFieldUpdateOperationsInput>;
  settlementCurrencyCode?: InputMaybe<EnumCurrencyCodeFieldUpdateOperationsInput>;
  settlementToPresentmentExchangeRates?: InputMaybe<FloatFieldUpdateOperationsInput>;
  status?: InputMaybe<EnumPaymentStatusFieldUpdateOperationsInput>;
  tips?: InputMaybe<TipsUpdateOneWithoutTransactionInput>;
  type?: InputMaybe<NullableEnumProductTypeFieldUpdateOperationsInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  userPayPerView?: InputMaybe<UserPayPerViewUpdateOneWithoutTransactionInput>;
  userSubscription?: InputMaybe<UserSubscriptionUpdateOneWithoutTransactionInput>;
};

export type TransactionUpdateWithoutPaymentToInput = {
  PaymentWebhook?: InputMaybe<PaymentWebhookUpdateManyWithoutTransactionInput>;
  amount?: InputMaybe<IntFieldUpdateOperationsInput>;
  archivedAt?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  channelName?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  currencyCode?: InputMaybe<EnumCurrencyCodeFieldUpdateOperationsInput>;
  description?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  giftDistribution?: InputMaybe<GiftDistributionUpdateOneWithoutTransactionInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  lineItem?: InputMaybe<Scalars['JSON']>;
  paymentBy?: InputMaybe<UserUpdateOneRequiredWithoutTransactionsInput>;
  paymentGateway?: InputMaybe<EnumPaymentGatewayFieldUpdateOperationsInput>;
  paymentRefNumber?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  settlementAmount?: InputMaybe<IntFieldUpdateOperationsInput>;
  settlementCurrencyCode?: InputMaybe<EnumCurrencyCodeFieldUpdateOperationsInput>;
  settlementToPresentmentExchangeRates?: InputMaybe<FloatFieldUpdateOperationsInput>;
  status?: InputMaybe<EnumPaymentStatusFieldUpdateOperationsInput>;
  tips?: InputMaybe<TipsUpdateOneWithoutTransactionInput>;
  type?: InputMaybe<NullableEnumProductTypeFieldUpdateOperationsInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  userPayPerView?: InputMaybe<UserPayPerViewUpdateOneWithoutTransactionInput>;
  userSubscription?: InputMaybe<UserSubscriptionUpdateOneWithoutTransactionInput>;
};

export type TransactionUpdateWithoutPaymentWebhookInput = {
  amount?: InputMaybe<IntFieldUpdateOperationsInput>;
  archivedAt?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  channelName?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  currencyCode?: InputMaybe<EnumCurrencyCodeFieldUpdateOperationsInput>;
  description?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  giftDistribution?: InputMaybe<GiftDistributionUpdateOneWithoutTransactionInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  lineItem?: InputMaybe<Scalars['JSON']>;
  paymentBy?: InputMaybe<UserUpdateOneRequiredWithoutTransactionsInput>;
  paymentGateway?: InputMaybe<EnumPaymentGatewayFieldUpdateOperationsInput>;
  paymentRefNumber?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  paymentTo?: InputMaybe<ChannelUpdateOneRequiredWithoutReceivedTransactionsInput>;
  settlementAmount?: InputMaybe<IntFieldUpdateOperationsInput>;
  settlementCurrencyCode?: InputMaybe<EnumCurrencyCodeFieldUpdateOperationsInput>;
  settlementToPresentmentExchangeRates?: InputMaybe<FloatFieldUpdateOperationsInput>;
  status?: InputMaybe<EnumPaymentStatusFieldUpdateOperationsInput>;
  tips?: InputMaybe<TipsUpdateOneWithoutTransactionInput>;
  type?: InputMaybe<NullableEnumProductTypeFieldUpdateOperationsInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  userPayPerView?: InputMaybe<UserPayPerViewUpdateOneWithoutTransactionInput>;
  userSubscription?: InputMaybe<UserSubscriptionUpdateOneWithoutTransactionInput>;
};

export type TransactionUpdateWithoutTipsInput = {
  PaymentWebhook?: InputMaybe<PaymentWebhookUpdateManyWithoutTransactionInput>;
  amount?: InputMaybe<IntFieldUpdateOperationsInput>;
  archivedAt?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  channelName?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  currencyCode?: InputMaybe<EnumCurrencyCodeFieldUpdateOperationsInput>;
  description?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  giftDistribution?: InputMaybe<GiftDistributionUpdateOneWithoutTransactionInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  lineItem?: InputMaybe<Scalars['JSON']>;
  paymentBy?: InputMaybe<UserUpdateOneRequiredWithoutTransactionsInput>;
  paymentGateway?: InputMaybe<EnumPaymentGatewayFieldUpdateOperationsInput>;
  paymentRefNumber?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  paymentTo?: InputMaybe<ChannelUpdateOneRequiredWithoutReceivedTransactionsInput>;
  settlementAmount?: InputMaybe<IntFieldUpdateOperationsInput>;
  settlementCurrencyCode?: InputMaybe<EnumCurrencyCodeFieldUpdateOperationsInput>;
  settlementToPresentmentExchangeRates?: InputMaybe<FloatFieldUpdateOperationsInput>;
  status?: InputMaybe<EnumPaymentStatusFieldUpdateOperationsInput>;
  type?: InputMaybe<NullableEnumProductTypeFieldUpdateOperationsInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  userPayPerView?: InputMaybe<UserPayPerViewUpdateOneWithoutTransactionInput>;
  userSubscription?: InputMaybe<UserSubscriptionUpdateOneWithoutTransactionInput>;
};

export type TransactionUpdateWithoutUserPayPerViewInput = {
  PaymentWebhook?: InputMaybe<PaymentWebhookUpdateManyWithoutTransactionInput>;
  amount?: InputMaybe<IntFieldUpdateOperationsInput>;
  archivedAt?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  channelName?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  currencyCode?: InputMaybe<EnumCurrencyCodeFieldUpdateOperationsInput>;
  description?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  giftDistribution?: InputMaybe<GiftDistributionUpdateOneWithoutTransactionInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  lineItem?: InputMaybe<Scalars['JSON']>;
  paymentBy?: InputMaybe<UserUpdateOneRequiredWithoutTransactionsInput>;
  paymentGateway?: InputMaybe<EnumPaymentGatewayFieldUpdateOperationsInput>;
  paymentRefNumber?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  paymentTo?: InputMaybe<ChannelUpdateOneRequiredWithoutReceivedTransactionsInput>;
  settlementAmount?: InputMaybe<IntFieldUpdateOperationsInput>;
  settlementCurrencyCode?: InputMaybe<EnumCurrencyCodeFieldUpdateOperationsInput>;
  settlementToPresentmentExchangeRates?: InputMaybe<FloatFieldUpdateOperationsInput>;
  status?: InputMaybe<EnumPaymentStatusFieldUpdateOperationsInput>;
  tips?: InputMaybe<TipsUpdateOneWithoutTransactionInput>;
  type?: InputMaybe<NullableEnumProductTypeFieldUpdateOperationsInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  userSubscription?: InputMaybe<UserSubscriptionUpdateOneWithoutTransactionInput>;
};

export type TransactionUpdateWithoutUserSubscriptionInput = {
  PaymentWebhook?: InputMaybe<PaymentWebhookUpdateManyWithoutTransactionInput>;
  amount?: InputMaybe<IntFieldUpdateOperationsInput>;
  archivedAt?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  channelName?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  currencyCode?: InputMaybe<EnumCurrencyCodeFieldUpdateOperationsInput>;
  description?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  giftDistribution?: InputMaybe<GiftDistributionUpdateOneWithoutTransactionInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  lineItem?: InputMaybe<Scalars['JSON']>;
  paymentBy?: InputMaybe<UserUpdateOneRequiredWithoutTransactionsInput>;
  paymentGateway?: InputMaybe<EnumPaymentGatewayFieldUpdateOperationsInput>;
  paymentRefNumber?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  paymentTo?: InputMaybe<ChannelUpdateOneRequiredWithoutReceivedTransactionsInput>;
  settlementAmount?: InputMaybe<IntFieldUpdateOperationsInput>;
  settlementCurrencyCode?: InputMaybe<EnumCurrencyCodeFieldUpdateOperationsInput>;
  settlementToPresentmentExchangeRates?: InputMaybe<FloatFieldUpdateOperationsInput>;
  status?: InputMaybe<EnumPaymentStatusFieldUpdateOperationsInput>;
  tips?: InputMaybe<TipsUpdateOneWithoutTransactionInput>;
  type?: InputMaybe<NullableEnumProductTypeFieldUpdateOperationsInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  userPayPerView?: InputMaybe<UserPayPerViewUpdateOneWithoutTransactionInput>;
};

export type TransactionUpsertWithWhereUniqueWithoutPaymentByInput = {
  create: TransactionCreateWithoutPaymentByInput;
  update: TransactionUpdateWithoutPaymentByInput;
  where: TransactionWhereUniqueInput;
};

export type TransactionUpsertWithWhereUniqueWithoutPaymentToInput = {
  create: TransactionCreateWithoutPaymentToInput;
  update: TransactionUpdateWithoutPaymentToInput;
  where: TransactionWhereUniqueInput;
};

export type TransactionUpsertWithoutGiftDistributionInput = {
  create: TransactionCreateWithoutGiftDistributionInput;
  update: TransactionUpdateWithoutGiftDistributionInput;
};

export type TransactionUpsertWithoutPaymentWebhookInput = {
  create: TransactionCreateWithoutPaymentWebhookInput;
  update: TransactionUpdateWithoutPaymentWebhookInput;
};

export type TransactionUpsertWithoutTipsInput = {
  create: TransactionCreateWithoutTipsInput;
  update: TransactionUpdateWithoutTipsInput;
};

export type TransactionUpsertWithoutUserPayPerViewInput = {
  create: TransactionCreateWithoutUserPayPerViewInput;
  update: TransactionUpdateWithoutUserPayPerViewInput;
};

export type TransactionUpsertWithoutUserSubscriptionInput = {
  create: TransactionCreateWithoutUserSubscriptionInput;
  update: TransactionUpdateWithoutUserSubscriptionInput;
};

export type TransactionWhereInput = {
  AND?: InputMaybe<Array<TransactionWhereInput>>;
  NOT?: InputMaybe<Array<TransactionWhereInput>>;
  OR?: InputMaybe<Array<TransactionWhereInput>>;
  PaymentWebhook?: InputMaybe<PaymentWebhookListRelationFilter>;
  amount?: InputMaybe<IntFilter>;
  archivedAt?: InputMaybe<DateTimeNullableFilter>;
  channelId?: InputMaybe<StringFilter>;
  channelName?: InputMaybe<StringNullableFilter>;
  createdAt?: InputMaybe<DateTimeFilter>;
  currencyCode?: InputMaybe<EnumCurrencyCodeFilter>;
  description?: InputMaybe<StringNullableFilter>;
  giftDistribution?: InputMaybe<GiftDistributionRelationFilter>;
  id?: InputMaybe<StringFilter>;
  lineItem?: InputMaybe<JsonNullableFilter>;
  paymentBy?: InputMaybe<UserRelationFilter>;
  paymentGateway?: InputMaybe<EnumPaymentGatewayFilter>;
  paymentRefNumber?: InputMaybe<StringNullableFilter>;
  paymentTo?: InputMaybe<ChannelRelationFilter>;
  settlementAmount?: InputMaybe<IntFilter>;
  settlementCurrencyCode?: InputMaybe<EnumCurrencyCodeFilter>;
  settlementToPresentmentExchangeRates?: InputMaybe<FloatFilter>;
  status?: InputMaybe<EnumPaymentStatusFilter>;
  tips?: InputMaybe<TipsRelationFilter>;
  type?: InputMaybe<EnumProductTypeNullableFilter>;
  updatedAt?: InputMaybe<DateTimeFilter>;
  userId?: InputMaybe<StringFilter>;
  userPayPerView?: InputMaybe<UserPayPerViewRelationFilter>;
  userSubscription?: InputMaybe<UserSubscriptionRelationFilter>;
};

export type TransactionWhereUniqueInput = {
  id?: InputMaybe<Scalars['String']>;
};

export type UpdateMyAccountInput = {
  UserTips?: InputMaybe<TipsUpdateManyWithoutPayerInput>;
  bankAccountHolderName?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  bankAccountNumber?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  bankBranchCode?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  bankName?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  bankSwiftCode?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  companyAddressCity?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  companyAddressCountry?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  companyAddressLine1?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  companyAddressLine2?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  companyAddressPostcode?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  companyAddressState?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  companyName?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  contactPersonEmail?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  contactPersonMobileNumber?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  contactPersonName?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  countryCode?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  currencyCode?: InputMaybe<EnumCurrencyCodeFieldUpdateOperationsInput>;
  email?: InputMaybe<StringFieldUpdateOperationsInput>;
  fullName?: InputMaybe<StringFieldUpdateOperationsInput>;
  mobileCountryCode?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  mobileNumber?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  preferredLanguage?: InputMaybe<NullableEnumCommunicationLanguageFieldUpdateOperationsInput>;
  username?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
};

export type UpdateMyAccountResponse = {
  __typename?: 'UpdateMyAccountResponse';
  id: Scalars['String'];
};

export type UpdateMyPersonalInfoInput = {
  avatarUrl?: InputMaybe<Scalars['String']>;
  countryCode?: InputMaybe<Scalars['String']>;
  mobileCountryCode?: InputMaybe<Scalars['String']>;
  mobileNumber?: InputMaybe<Scalars['String']>;
};

export type UpdateMyPersonalInfoResponse = {
  __typename?: 'UpdateMyPersonalInfoResponse';
  id: Scalars['String'];
};

export type UploadVideoInput = {
  coverThumbnailUrl?: InputMaybe<Scalars['String']>;
  description?: InputMaybe<Scalars['String']>;
  duration?: InputMaybe<Scalars['Float']>;
  fileName: Scalars['String'];
  genre: Scalars['String'];
  isPayPerView?: InputMaybe<Scalars['Boolean']>;
  isVertical?: InputMaybe<Scalars['Boolean']>;
  language: Scalars['String'];
  payPerViewPrice?: InputMaybe<Scalars['Float']>;
  productUrl?: InputMaybe<Scalars['String']>;
  rating: Scalars['String'];
  scheduleEnd?: InputMaybe<Scalars['DateTime']>;
  scheduleStart?: InputMaybe<Scalars['DateTime']>;
  status?: InputMaybe<Scalars['String']>;
  subtitleLanguage?: InputMaybe<Scalars['String']>;
  subtitleUrl?: InputMaybe<Scalars['String']>;
  thumbnailUrl?: InputMaybe<Scalars['String']>;
  title: Scalars['String'];
};

export type UploadVideoResult = {
  __typename?: 'UploadVideoResult';
  _count?: Maybe<VideoCount>;
  archivedAt?: Maybe<Scalars['DateTime']>;
  assetId?: Maybe<Scalars['String']>;
  channel: Channel;
  channelId: Scalars['String'];
  comments: Array<Comment>;
  coverThumbnailUrl?: Maybe<Scalars['String']>;
  createdAt: Scalars['DateTime'];
  createdTimeFromNow: Scalars['String'];
  description?: Maybe<Scalars['String']>;
  displayPrice: Scalars['String'];
  duration: Scalars['Float'];
  genre: Scalars['String'];
  giftDistributions: Array<GiftDistribution>;
  hasCommentedOnVideo: Scalars['Boolean'];
  hasGiftedVideo: Scalars['Boolean'];
  hasLikedVideo: Scalars['Boolean'];
  hasPaidForVideo: Scalars['Boolean'];
  height?: Maybe<Scalars['Int']>;
  id: Scalars['String'];
  isPayPerView: Scalars['Boolean'];
  isVertical: Scalars['Boolean'];
  language: Scalars['String'];
  likedVideos: Array<LikedVideo>;
  payPerViewPrice?: Maybe<Scalars['Float']>;
  playbackUrl?: Maybe<Scalars['String']>;
  playlistVideos: Array<PlaylistVideo>;
  /** Presentment price of the gift in currency charged by payment gateway */
  presentmentMoney: PresentmentMoney;
  productUrl?: Maybe<Scalars['String']>;
  rating: VideoRating;
  scheduleEnd?: Maybe<Scalars['DateTime']>;
  scheduleStart?: Maybe<Scalars['DateTime']>;
  shortId?: Maybe<Scalars['String']>;
  statistics: Array<VideoStatistic>;
  status: VideoStatus;
  subtitleLanguage?: Maybe<Scalars['String']>;
  subtitleUrl?: Maybe<Scalars['String']>;
  thumbnailUrl?: Maybe<Scalars['String']>;
  title: Scalars['String'];
  totalCommentCount: Scalars['Int'];
  totalGiftCount: Scalars['Int'];
  totalLikeCount: Scalars['Int'];
  totalViewCount: Scalars['Int'];
  updatedAt: Scalars['DateTime'];
  uploadId?: Maybe<Scalars['String']>;
  uploadInfo: UploadInfo;
  userPayPerView: Array<UserPayPerView>;
  watchHistories: Array<WatchHistory>;
  width?: Maybe<Scalars['Int']>;
};


export type UploadVideoResultCommentsArgs = {
  cursor?: InputMaybe<CommentWhereUniqueInput>;
  distinct?: InputMaybe<Array<CommentScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<CommentOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<CommentWhereInput>;
};


export type UploadVideoResultGiftDistributionsArgs = {
  cursor?: InputMaybe<GiftDistributionWhereUniqueInput>;
  distinct?: InputMaybe<Array<GiftDistributionScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<GiftDistributionOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<GiftDistributionWhereInput>;
};


export type UploadVideoResultLikedVideosArgs = {
  cursor?: InputMaybe<LikedVideoWhereUniqueInput>;
  distinct?: InputMaybe<Array<LikedVideoScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<LikedVideoOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<LikedVideoWhereInput>;
};


export type UploadVideoResultPlaylistVideosArgs = {
  cursor?: InputMaybe<PlaylistVideoWhereUniqueInput>;
  distinct?: InputMaybe<Array<PlaylistVideoScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<PlaylistVideoOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<PlaylistVideoWhereInput>;
};


export type UploadVideoResultStatisticsArgs = {
  cursor?: InputMaybe<VideoStatisticWhereUniqueInput>;
  distinct?: InputMaybe<Array<VideoStatisticScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<VideoStatisticOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<VideoStatisticWhereInput>;
};


export type UploadVideoResultUserPayPerViewArgs = {
  cursor?: InputMaybe<UserPayPerViewWhereUniqueInput>;
  distinct?: InputMaybe<Array<UserPayPerViewScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<UserPayPerViewOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<UserPayPerViewWhereInput>;
};


export type UploadVideoResultWatchHistoriesArgs = {
  cursor?: InputMaybe<WatchHistoryWhereUniqueInput>;
  distinct?: InputMaybe<Array<WatchHistoryScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<WatchHistoryOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<WatchHistoryWhereInput>;
};

export type User = {
  __typename?: 'User';
  UserTips: Array<Tips>;
  _count?: Maybe<UserCount>;
  accessToken?: Maybe<Scalars['String']>;
  approvedAt?: Maybe<Scalars['DateTime']>;
  archivedAt?: Maybe<Scalars['DateTime']>;
  avatarUrl?: Maybe<Scalars['String']>;
  bankAccountHolderName?: Maybe<Scalars['String']>;
  bankAccountNumber?: Maybe<Scalars['String']>;
  bankBranchCode?: Maybe<Scalars['String']>;
  bankName?: Maybe<Scalars['String']>;
  bankSwiftCode?: Maybe<Scalars['String']>;
  bannerUrl?: Maybe<Scalars['String']>;
  channel?: Maybe<Channel>;
  comments: Array<Comment>;
  companyAddressCity?: Maybe<Scalars['String']>;
  companyAddressCountry?: Maybe<Scalars['String']>;
  companyAddressLine1?: Maybe<Scalars['String']>;
  companyAddressLine2?: Maybe<Scalars['String']>;
  companyAddressPostcode?: Maybe<Scalars['String']>;
  companyAddressState?: Maybe<Scalars['String']>;
  companyName?: Maybe<Scalars['String']>;
  contactPersonEmail?: Maybe<Scalars['String']>;
  contactPersonMobileNumber?: Maybe<Scalars['String']>;
  contactPersonName?: Maybe<Scalars['String']>;
  countryCode?: Maybe<Scalars['String']>;
  createdAt: Scalars['DateTime'];
  createdNfts: Array<MintedNft>;
  currencyCode: CurrencyCode;
  email: Scalars['String'];
  followedBy: Array<ChannelFollower>;
  followedChannels: Array<ChannelFollower>;
  fullName: Scalars['String'];
  giftsSent: Array<GiftDistribution>;
  holdNfts: Array<MintedNft>;
  id: Scalars['String'];
  isEmailVerified: Scalars['Boolean'];
  likedVideos: Array<LikedVideo>;
  mobileCountryCode?: Maybe<Scalars['String']>;
  mobileNumber?: Maybe<Scalars['String']>;
  mongoUserId?: Maybe<Scalars['String']>;
  nftCollections: Array<UserNftCollections>;
  preferredLanguage?: Maybe<CommunicationLanguage>;
  prestoUserId?: Maybe<Scalars['String']>;
  referralAgentId?: Maybe<Scalars['String']>;
  refreshToken?: Maybe<Scalars['String']>;
  role?: Maybe<UserType>;
  selfRegistered: Scalars['Boolean'];
  sendbirdAccessToken?: Maybe<Scalars['String']>;
  status: Status;
  subscribeInfoAgreed: Scalars['Boolean'];
  tosAgreed: Scalars['Boolean'];
  transactions: Array<Transaction>;
  updatedAt: Scalars['DateTime'];
  userPayPerViews: Array<UserPayPerView>;
  userSubscriptions: Array<UserSubscription>;
  username?: Maybe<Scalars['String']>;
  voucherCode?: Maybe<Scalars['String']>;
  watchHistories: Array<WatchHistory>;
};


export type UserUserTipsArgs = {
  cursor?: InputMaybe<TipsWhereUniqueInput>;
  distinct?: InputMaybe<Array<TipsScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<TipsOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<TipsWhereInput>;
};


export type UserCommentsArgs = {
  cursor?: InputMaybe<CommentWhereUniqueInput>;
  distinct?: InputMaybe<Array<CommentScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<CommentOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<CommentWhereInput>;
};


export type UserCreatedNftsArgs = {
  cursor?: InputMaybe<MintedNftWhereUniqueInput>;
  distinct?: InputMaybe<Array<MintedNftScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<MintedNftOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<MintedNftWhereInput>;
};


export type UserFollowedByArgs = {
  cursor?: InputMaybe<ChannelFollowerWhereUniqueInput>;
  distinct?: InputMaybe<Array<ChannelFollowerScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<ChannelFollowerOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<ChannelFollowerWhereInput>;
};


export type UserFollowedChannelsArgs = {
  cursor?: InputMaybe<ChannelFollowerWhereUniqueInput>;
  distinct?: InputMaybe<Array<ChannelFollowerScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<ChannelFollowerOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<ChannelFollowerWhereInput>;
};


export type UserGiftsSentArgs = {
  cursor?: InputMaybe<GiftDistributionWhereUniqueInput>;
  distinct?: InputMaybe<Array<GiftDistributionScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<GiftDistributionOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<GiftDistributionWhereInput>;
};


export type UserHoldNftsArgs = {
  cursor?: InputMaybe<MintedNftWhereUniqueInput>;
  distinct?: InputMaybe<Array<MintedNftScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<MintedNftOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<MintedNftWhereInput>;
};


export type UserLikedVideosArgs = {
  cursor?: InputMaybe<LikedVideoWhereUniqueInput>;
  distinct?: InputMaybe<Array<LikedVideoScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<LikedVideoOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<LikedVideoWhereInput>;
};


export type UserNftCollectionsArgs = {
  cursor?: InputMaybe<UserNftCollectionsWhereUniqueInput>;
  distinct?: InputMaybe<Array<UserNftCollectionsScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<UserNftCollectionsOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<UserNftCollectionsWhereInput>;
};


export type UserTransactionsArgs = {
  cursor?: InputMaybe<TransactionWhereUniqueInput>;
  distinct?: InputMaybe<Array<TransactionScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<TransactionOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<TransactionWhereInput>;
};


export type UserUserPayPerViewsArgs = {
  cursor?: InputMaybe<UserPayPerViewWhereUniqueInput>;
  distinct?: InputMaybe<Array<UserPayPerViewScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<UserPayPerViewOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<UserPayPerViewWhereInput>;
};


export type UserUserSubscriptionsArgs = {
  cursor?: InputMaybe<UserSubscriptionWhereUniqueInput>;
  distinct?: InputMaybe<Array<UserSubscriptionScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<UserSubscriptionOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<UserSubscriptionWhereInput>;
};


export type UserWatchHistoriesArgs = {
  cursor?: InputMaybe<WatchHistoryWhereUniqueInput>;
  distinct?: InputMaybe<Array<WatchHistoryScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<WatchHistoryOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<WatchHistoryWhereInput>;
};

export type UserCount = {
  __typename?: 'UserCount';
  UserTips: Scalars['Int'];
  comments: Scalars['Int'];
  createdNfts: Scalars['Int'];
  followedBy: Scalars['Int'];
  followedChannels: Scalars['Int'];
  giftsSent: Scalars['Int'];
  holdNfts: Scalars['Int'];
  likedVideos: Scalars['Int'];
  nftCollections: Scalars['Int'];
  transactions: Scalars['Int'];
  userPayPerViews: Scalars['Int'];
  userSubscriptions: Scalars['Int'];
  watchHistories: Scalars['Int'];
};

export type UserCountAggregate = {
  __typename?: 'UserCountAggregate';
  _all: Scalars['Int'];
  accessToken: Scalars['Int'];
  approvedAt: Scalars['Int'];
  archivedAt: Scalars['Int'];
  avatarUrl: Scalars['Int'];
  bankAccountHolderName: Scalars['Int'];
  bankAccountNumber: Scalars['Int'];
  bankBranchCode: Scalars['Int'];
  bankName: Scalars['Int'];
  bankSwiftCode: Scalars['Int'];
  bannerUrl: Scalars['Int'];
  companyAddressCity: Scalars['Int'];
  companyAddressCountry: Scalars['Int'];
  companyAddressLine1: Scalars['Int'];
  companyAddressLine2: Scalars['Int'];
  companyAddressPostcode: Scalars['Int'];
  companyAddressState: Scalars['Int'];
  companyName: Scalars['Int'];
  contactPersonEmail: Scalars['Int'];
  contactPersonMobileNumber: Scalars['Int'];
  contactPersonName: Scalars['Int'];
  countryCode: Scalars['Int'];
  createdAt: Scalars['Int'];
  currencyCode: Scalars['Int'];
  email: Scalars['Int'];
  fullName: Scalars['Int'];
  id: Scalars['Int'];
  isEmailVerified: Scalars['Int'];
  mobileCountryCode: Scalars['Int'];
  mobileNumber: Scalars['Int'];
  mongoUserId: Scalars['Int'];
  password: Scalars['Int'];
  preferredLanguage: Scalars['Int'];
  prestoUserId: Scalars['Int'];
  referralAgentId: Scalars['Int'];
  refreshToken: Scalars['Int'];
  role: Scalars['Int'];
  selfRegistered: Scalars['Int'];
  sendbirdAccessToken: Scalars['Int'];
  status: Scalars['Int'];
  subscribeInfoAgreed: Scalars['Int'];
  tosAgreed: Scalars['Int'];
  updatedAt: Scalars['Int'];
  username: Scalars['Int'];
  voucherCode: Scalars['Int'];
};

export type UserCountOrderByAggregateInput = {
  accessToken?: InputMaybe<SortOrder>;
  approvedAt?: InputMaybe<SortOrder>;
  archivedAt?: InputMaybe<SortOrder>;
  avatarUrl?: InputMaybe<SortOrder>;
  bankAccountHolderName?: InputMaybe<SortOrder>;
  bankAccountNumber?: InputMaybe<SortOrder>;
  bankBranchCode?: InputMaybe<SortOrder>;
  bankName?: InputMaybe<SortOrder>;
  bankSwiftCode?: InputMaybe<SortOrder>;
  bannerUrl?: InputMaybe<SortOrder>;
  companyAddressCity?: InputMaybe<SortOrder>;
  companyAddressCountry?: InputMaybe<SortOrder>;
  companyAddressLine1?: InputMaybe<SortOrder>;
  companyAddressLine2?: InputMaybe<SortOrder>;
  companyAddressPostcode?: InputMaybe<SortOrder>;
  companyAddressState?: InputMaybe<SortOrder>;
  companyName?: InputMaybe<SortOrder>;
  contactPersonEmail?: InputMaybe<SortOrder>;
  contactPersonMobileNumber?: InputMaybe<SortOrder>;
  contactPersonName?: InputMaybe<SortOrder>;
  countryCode?: InputMaybe<SortOrder>;
  createdAt?: InputMaybe<SortOrder>;
  currencyCode?: InputMaybe<SortOrder>;
  email?: InputMaybe<SortOrder>;
  fullName?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  isEmailVerified?: InputMaybe<SortOrder>;
  mobileCountryCode?: InputMaybe<SortOrder>;
  mobileNumber?: InputMaybe<SortOrder>;
  mongoUserId?: InputMaybe<SortOrder>;
  password?: InputMaybe<SortOrder>;
  preferredLanguage?: InputMaybe<SortOrder>;
  prestoUserId?: InputMaybe<SortOrder>;
  referralAgentId?: InputMaybe<SortOrder>;
  refreshToken?: InputMaybe<SortOrder>;
  role?: InputMaybe<SortOrder>;
  selfRegistered?: InputMaybe<SortOrder>;
  sendbirdAccessToken?: InputMaybe<SortOrder>;
  status?: InputMaybe<SortOrder>;
  subscribeInfoAgreed?: InputMaybe<SortOrder>;
  tosAgreed?: InputMaybe<SortOrder>;
  updatedAt?: InputMaybe<SortOrder>;
  username?: InputMaybe<SortOrder>;
  voucherCode?: InputMaybe<SortOrder>;
};

export type UserCreateInput = {
  UserTips?: InputMaybe<TipsCreateNestedManyWithoutPayerInput>;
  accessToken?: InputMaybe<Scalars['String']>;
  approvedAt?: InputMaybe<Scalars['DateTime']>;
  archivedAt?: InputMaybe<Scalars['DateTime']>;
  avatarUrl?: InputMaybe<Scalars['String']>;
  bankAccountHolderName?: InputMaybe<Scalars['String']>;
  bankAccountNumber?: InputMaybe<Scalars['String']>;
  bankBranchCode?: InputMaybe<Scalars['String']>;
  bankName?: InputMaybe<Scalars['String']>;
  bankSwiftCode?: InputMaybe<Scalars['String']>;
  bannerUrl?: InputMaybe<Scalars['String']>;
  channel?: InputMaybe<ChannelCreateNestedOneWithoutUserInput>;
  comments?: InputMaybe<CommentCreateNestedManyWithoutUserInput>;
  companyAddressCity?: InputMaybe<Scalars['String']>;
  companyAddressCountry?: InputMaybe<Scalars['String']>;
  companyAddressLine1?: InputMaybe<Scalars['String']>;
  companyAddressLine2?: InputMaybe<Scalars['String']>;
  companyAddressPostcode?: InputMaybe<Scalars['String']>;
  companyAddressState?: InputMaybe<Scalars['String']>;
  companyName?: InputMaybe<Scalars['String']>;
  contactPersonEmail?: InputMaybe<Scalars['String']>;
  contactPersonMobileNumber?: InputMaybe<Scalars['String']>;
  contactPersonName?: InputMaybe<Scalars['String']>;
  countryCode?: InputMaybe<Scalars['String']>;
  createdAt?: InputMaybe<Scalars['DateTime']>;
  createdNfts?: InputMaybe<MintedNftCreateNestedManyWithoutCreatorUserInput>;
  currencyCode?: InputMaybe<CurrencyCode>;
  email: Scalars['String'];
  followedBy?: InputMaybe<ChannelFollowerCreateNestedManyWithoutFolloweeOwnerInput>;
  followedChannels?: InputMaybe<ChannelFollowerCreateNestedManyWithoutFollowerInput>;
  fullName: Scalars['String'];
  giftsSent?: InputMaybe<GiftDistributionCreateNestedManyWithoutSenderInput>;
  holdNfts?: InputMaybe<MintedNftCreateNestedManyWithoutHolderUserInput>;
  id?: InputMaybe<Scalars['String']>;
  isEmailVerified?: InputMaybe<Scalars['Boolean']>;
  likedVideos?: InputMaybe<LikedVideoCreateNestedManyWithoutUserInput>;
  mobileCountryCode?: InputMaybe<Scalars['String']>;
  mobileNumber?: InputMaybe<Scalars['String']>;
  mongoUserId?: InputMaybe<Scalars['String']>;
  nftCollections?: InputMaybe<UserNftCollectionsCreateNestedManyWithoutUserInput>;
  password?: InputMaybe<Scalars['String']>;
  preferredLanguage?: InputMaybe<CommunicationLanguage>;
  prestoUserId?: InputMaybe<Scalars['String']>;
  referralAgentId?: InputMaybe<Scalars['String']>;
  refreshToken?: InputMaybe<Scalars['String']>;
  role?: InputMaybe<UserType>;
  selfRegistered?: InputMaybe<Scalars['Boolean']>;
  sendbirdAccessToken?: InputMaybe<Scalars['String']>;
  status?: InputMaybe<Status>;
  subscribeInfoAgreed?: InputMaybe<Scalars['Boolean']>;
  tosAgreed?: InputMaybe<Scalars['Boolean']>;
  transactions?: InputMaybe<TransactionCreateNestedManyWithoutPaymentByInput>;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
  userPayPerViews?: InputMaybe<UserPayPerViewCreateNestedManyWithoutUserInput>;
  userSubscriptions?: InputMaybe<UserSubscriptionCreateNestedManyWithoutSubscriberInput>;
  username?: InputMaybe<Scalars['String']>;
  voucherCode?: InputMaybe<Scalars['String']>;
  watchHistories?: InputMaybe<WatchHistoryCreateNestedManyWithoutUserInput>;
};

export type UserCreateManyInput = {
  accessToken?: InputMaybe<Scalars['String']>;
  approvedAt?: InputMaybe<Scalars['DateTime']>;
  archivedAt?: InputMaybe<Scalars['DateTime']>;
  avatarUrl?: InputMaybe<Scalars['String']>;
  bankAccountHolderName?: InputMaybe<Scalars['String']>;
  bankAccountNumber?: InputMaybe<Scalars['String']>;
  bankBranchCode?: InputMaybe<Scalars['String']>;
  bankName?: InputMaybe<Scalars['String']>;
  bankSwiftCode?: InputMaybe<Scalars['String']>;
  bannerUrl?: InputMaybe<Scalars['String']>;
  companyAddressCity?: InputMaybe<Scalars['String']>;
  companyAddressCountry?: InputMaybe<Scalars['String']>;
  companyAddressLine1?: InputMaybe<Scalars['String']>;
  companyAddressLine2?: InputMaybe<Scalars['String']>;
  companyAddressPostcode?: InputMaybe<Scalars['String']>;
  companyAddressState?: InputMaybe<Scalars['String']>;
  companyName?: InputMaybe<Scalars['String']>;
  contactPersonEmail?: InputMaybe<Scalars['String']>;
  contactPersonMobileNumber?: InputMaybe<Scalars['String']>;
  contactPersonName?: InputMaybe<Scalars['String']>;
  countryCode?: InputMaybe<Scalars['String']>;
  createdAt?: InputMaybe<Scalars['DateTime']>;
  currencyCode?: InputMaybe<CurrencyCode>;
  email: Scalars['String'];
  fullName: Scalars['String'];
  id?: InputMaybe<Scalars['String']>;
  isEmailVerified?: InputMaybe<Scalars['Boolean']>;
  mobileCountryCode?: InputMaybe<Scalars['String']>;
  mobileNumber?: InputMaybe<Scalars['String']>;
  mongoUserId?: InputMaybe<Scalars['String']>;
  password?: InputMaybe<Scalars['String']>;
  preferredLanguage?: InputMaybe<CommunicationLanguage>;
  prestoUserId?: InputMaybe<Scalars['String']>;
  referralAgentId?: InputMaybe<Scalars['String']>;
  refreshToken?: InputMaybe<Scalars['String']>;
  role?: InputMaybe<UserType>;
  selfRegistered?: InputMaybe<Scalars['Boolean']>;
  sendbirdAccessToken?: InputMaybe<Scalars['String']>;
  status?: InputMaybe<Status>;
  subscribeInfoAgreed?: InputMaybe<Scalars['Boolean']>;
  tosAgreed?: InputMaybe<Scalars['Boolean']>;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
  username?: InputMaybe<Scalars['String']>;
  voucherCode?: InputMaybe<Scalars['String']>;
};

export type UserCreateNestedOneWithoutChannelInput = {
  connect?: InputMaybe<UserWhereUniqueInput>;
  connectOrCreate?: InputMaybe<UserCreateOrConnectWithoutChannelInput>;
  create?: InputMaybe<UserCreateWithoutChannelInput>;
};

export type UserCreateNestedOneWithoutCommentsInput = {
  connect?: InputMaybe<UserWhereUniqueInput>;
  connectOrCreate?: InputMaybe<UserCreateOrConnectWithoutCommentsInput>;
  create?: InputMaybe<UserCreateWithoutCommentsInput>;
};

export type UserCreateNestedOneWithoutCreatedNftsInput = {
  connect?: InputMaybe<UserWhereUniqueInput>;
  connectOrCreate?: InputMaybe<UserCreateOrConnectWithoutCreatedNftsInput>;
  create?: InputMaybe<UserCreateWithoutCreatedNftsInput>;
};

export type UserCreateNestedOneWithoutFollowedByInput = {
  connect?: InputMaybe<UserWhereUniqueInput>;
  connectOrCreate?: InputMaybe<UserCreateOrConnectWithoutFollowedByInput>;
  create?: InputMaybe<UserCreateWithoutFollowedByInput>;
};

export type UserCreateNestedOneWithoutFollowedChannelsInput = {
  connect?: InputMaybe<UserWhereUniqueInput>;
  connectOrCreate?: InputMaybe<UserCreateOrConnectWithoutFollowedChannelsInput>;
  create?: InputMaybe<UserCreateWithoutFollowedChannelsInput>;
};

export type UserCreateNestedOneWithoutGiftsSentInput = {
  connect?: InputMaybe<UserWhereUniqueInput>;
  connectOrCreate?: InputMaybe<UserCreateOrConnectWithoutGiftsSentInput>;
  create?: InputMaybe<UserCreateWithoutGiftsSentInput>;
};

export type UserCreateNestedOneWithoutHoldNftsInput = {
  connect?: InputMaybe<UserWhereUniqueInput>;
  connectOrCreate?: InputMaybe<UserCreateOrConnectWithoutHoldNftsInput>;
  create?: InputMaybe<UserCreateWithoutHoldNftsInput>;
};

export type UserCreateNestedOneWithoutLikedVideosInput = {
  connect?: InputMaybe<UserWhereUniqueInput>;
  connectOrCreate?: InputMaybe<UserCreateOrConnectWithoutLikedVideosInput>;
  create?: InputMaybe<UserCreateWithoutLikedVideosInput>;
};

export type UserCreateNestedOneWithoutNftCollectionsInput = {
  connect?: InputMaybe<UserWhereUniqueInput>;
  connectOrCreate?: InputMaybe<UserCreateOrConnectWithoutNftCollectionsInput>;
  create?: InputMaybe<UserCreateWithoutNftCollectionsInput>;
};

export type UserCreateNestedOneWithoutTransactionsInput = {
  connect?: InputMaybe<UserWhereUniqueInput>;
  connectOrCreate?: InputMaybe<UserCreateOrConnectWithoutTransactionsInput>;
  create?: InputMaybe<UserCreateWithoutTransactionsInput>;
};

export type UserCreateNestedOneWithoutUserPayPerViewsInput = {
  connect?: InputMaybe<UserWhereUniqueInput>;
  connectOrCreate?: InputMaybe<UserCreateOrConnectWithoutUserPayPerViewsInput>;
  create?: InputMaybe<UserCreateWithoutUserPayPerViewsInput>;
};

export type UserCreateNestedOneWithoutUserSubscriptionsInput = {
  connect?: InputMaybe<UserWhereUniqueInput>;
  connectOrCreate?: InputMaybe<UserCreateOrConnectWithoutUserSubscriptionsInput>;
  create?: InputMaybe<UserCreateWithoutUserSubscriptionsInput>;
};

export type UserCreateNestedOneWithoutUserTipsInput = {
  connect?: InputMaybe<UserWhereUniqueInput>;
  connectOrCreate?: InputMaybe<UserCreateOrConnectWithoutUserTipsInput>;
  create?: InputMaybe<UserCreateWithoutUserTipsInput>;
};

export type UserCreateNestedOneWithoutWatchHistoriesInput = {
  connect?: InputMaybe<UserWhereUniqueInput>;
  connectOrCreate?: InputMaybe<UserCreateOrConnectWithoutWatchHistoriesInput>;
  create?: InputMaybe<UserCreateWithoutWatchHistoriesInput>;
};

export type UserCreateOrConnectWithoutChannelInput = {
  create: UserCreateWithoutChannelInput;
  where: UserWhereUniqueInput;
};

export type UserCreateOrConnectWithoutCommentsInput = {
  create: UserCreateWithoutCommentsInput;
  where: UserWhereUniqueInput;
};

export type UserCreateOrConnectWithoutCreatedNftsInput = {
  create: UserCreateWithoutCreatedNftsInput;
  where: UserWhereUniqueInput;
};

export type UserCreateOrConnectWithoutFollowedByInput = {
  create: UserCreateWithoutFollowedByInput;
  where: UserWhereUniqueInput;
};

export type UserCreateOrConnectWithoutFollowedChannelsInput = {
  create: UserCreateWithoutFollowedChannelsInput;
  where: UserWhereUniqueInput;
};

export type UserCreateOrConnectWithoutGiftsSentInput = {
  create: UserCreateWithoutGiftsSentInput;
  where: UserWhereUniqueInput;
};

export type UserCreateOrConnectWithoutHoldNftsInput = {
  create: UserCreateWithoutHoldNftsInput;
  where: UserWhereUniqueInput;
};

export type UserCreateOrConnectWithoutLikedVideosInput = {
  create: UserCreateWithoutLikedVideosInput;
  where: UserWhereUniqueInput;
};

export type UserCreateOrConnectWithoutNftCollectionsInput = {
  create: UserCreateWithoutNftCollectionsInput;
  where: UserWhereUniqueInput;
};

export type UserCreateOrConnectWithoutTransactionsInput = {
  create: UserCreateWithoutTransactionsInput;
  where: UserWhereUniqueInput;
};

export type UserCreateOrConnectWithoutUserPayPerViewsInput = {
  create: UserCreateWithoutUserPayPerViewsInput;
  where: UserWhereUniqueInput;
};

export type UserCreateOrConnectWithoutUserSubscriptionsInput = {
  create: UserCreateWithoutUserSubscriptionsInput;
  where: UserWhereUniqueInput;
};

export type UserCreateOrConnectWithoutUserTipsInput = {
  create: UserCreateWithoutUserTipsInput;
  where: UserWhereUniqueInput;
};

export type UserCreateOrConnectWithoutWatchHistoriesInput = {
  create: UserCreateWithoutWatchHistoriesInput;
  where: UserWhereUniqueInput;
};

export type UserCreateWithoutChannelInput = {
  UserTips?: InputMaybe<TipsCreateNestedManyWithoutPayerInput>;
  accessToken?: InputMaybe<Scalars['String']>;
  approvedAt?: InputMaybe<Scalars['DateTime']>;
  archivedAt?: InputMaybe<Scalars['DateTime']>;
  avatarUrl?: InputMaybe<Scalars['String']>;
  bankAccountHolderName?: InputMaybe<Scalars['String']>;
  bankAccountNumber?: InputMaybe<Scalars['String']>;
  bankBranchCode?: InputMaybe<Scalars['String']>;
  bankName?: InputMaybe<Scalars['String']>;
  bankSwiftCode?: InputMaybe<Scalars['String']>;
  bannerUrl?: InputMaybe<Scalars['String']>;
  comments?: InputMaybe<CommentCreateNestedManyWithoutUserInput>;
  companyAddressCity?: InputMaybe<Scalars['String']>;
  companyAddressCountry?: InputMaybe<Scalars['String']>;
  companyAddressLine1?: InputMaybe<Scalars['String']>;
  companyAddressLine2?: InputMaybe<Scalars['String']>;
  companyAddressPostcode?: InputMaybe<Scalars['String']>;
  companyAddressState?: InputMaybe<Scalars['String']>;
  companyName?: InputMaybe<Scalars['String']>;
  contactPersonEmail?: InputMaybe<Scalars['String']>;
  contactPersonMobileNumber?: InputMaybe<Scalars['String']>;
  contactPersonName?: InputMaybe<Scalars['String']>;
  countryCode?: InputMaybe<Scalars['String']>;
  createdAt?: InputMaybe<Scalars['DateTime']>;
  createdNfts?: InputMaybe<MintedNftCreateNestedManyWithoutCreatorUserInput>;
  currencyCode?: InputMaybe<CurrencyCode>;
  email: Scalars['String'];
  followedBy?: InputMaybe<ChannelFollowerCreateNestedManyWithoutFolloweeOwnerInput>;
  followedChannels?: InputMaybe<ChannelFollowerCreateNestedManyWithoutFollowerInput>;
  fullName: Scalars['String'];
  giftsSent?: InputMaybe<GiftDistributionCreateNestedManyWithoutSenderInput>;
  holdNfts?: InputMaybe<MintedNftCreateNestedManyWithoutHolderUserInput>;
  id?: InputMaybe<Scalars['String']>;
  isEmailVerified?: InputMaybe<Scalars['Boolean']>;
  likedVideos?: InputMaybe<LikedVideoCreateNestedManyWithoutUserInput>;
  mobileCountryCode?: InputMaybe<Scalars['String']>;
  mobileNumber?: InputMaybe<Scalars['String']>;
  mongoUserId?: InputMaybe<Scalars['String']>;
  nftCollections?: InputMaybe<UserNftCollectionsCreateNestedManyWithoutUserInput>;
  password?: InputMaybe<Scalars['String']>;
  preferredLanguage?: InputMaybe<CommunicationLanguage>;
  prestoUserId?: InputMaybe<Scalars['String']>;
  referralAgentId?: InputMaybe<Scalars['String']>;
  refreshToken?: InputMaybe<Scalars['String']>;
  role?: InputMaybe<UserType>;
  selfRegistered?: InputMaybe<Scalars['Boolean']>;
  sendbirdAccessToken?: InputMaybe<Scalars['String']>;
  status?: InputMaybe<Status>;
  subscribeInfoAgreed?: InputMaybe<Scalars['Boolean']>;
  tosAgreed?: InputMaybe<Scalars['Boolean']>;
  transactions?: InputMaybe<TransactionCreateNestedManyWithoutPaymentByInput>;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
  userPayPerViews?: InputMaybe<UserPayPerViewCreateNestedManyWithoutUserInput>;
  userSubscriptions?: InputMaybe<UserSubscriptionCreateNestedManyWithoutSubscriberInput>;
  username?: InputMaybe<Scalars['String']>;
  voucherCode?: InputMaybe<Scalars['String']>;
  watchHistories?: InputMaybe<WatchHistoryCreateNestedManyWithoutUserInput>;
};

export type UserCreateWithoutCommentsInput = {
  UserTips?: InputMaybe<TipsCreateNestedManyWithoutPayerInput>;
  accessToken?: InputMaybe<Scalars['String']>;
  approvedAt?: InputMaybe<Scalars['DateTime']>;
  archivedAt?: InputMaybe<Scalars['DateTime']>;
  avatarUrl?: InputMaybe<Scalars['String']>;
  bankAccountHolderName?: InputMaybe<Scalars['String']>;
  bankAccountNumber?: InputMaybe<Scalars['String']>;
  bankBranchCode?: InputMaybe<Scalars['String']>;
  bankName?: InputMaybe<Scalars['String']>;
  bankSwiftCode?: InputMaybe<Scalars['String']>;
  bannerUrl?: InputMaybe<Scalars['String']>;
  channel?: InputMaybe<ChannelCreateNestedOneWithoutUserInput>;
  companyAddressCity?: InputMaybe<Scalars['String']>;
  companyAddressCountry?: InputMaybe<Scalars['String']>;
  companyAddressLine1?: InputMaybe<Scalars['String']>;
  companyAddressLine2?: InputMaybe<Scalars['String']>;
  companyAddressPostcode?: InputMaybe<Scalars['String']>;
  companyAddressState?: InputMaybe<Scalars['String']>;
  companyName?: InputMaybe<Scalars['String']>;
  contactPersonEmail?: InputMaybe<Scalars['String']>;
  contactPersonMobileNumber?: InputMaybe<Scalars['String']>;
  contactPersonName?: InputMaybe<Scalars['String']>;
  countryCode?: InputMaybe<Scalars['String']>;
  createdAt?: InputMaybe<Scalars['DateTime']>;
  createdNfts?: InputMaybe<MintedNftCreateNestedManyWithoutCreatorUserInput>;
  currencyCode?: InputMaybe<CurrencyCode>;
  email: Scalars['String'];
  followedBy?: InputMaybe<ChannelFollowerCreateNestedManyWithoutFolloweeOwnerInput>;
  followedChannels?: InputMaybe<ChannelFollowerCreateNestedManyWithoutFollowerInput>;
  fullName: Scalars['String'];
  giftsSent?: InputMaybe<GiftDistributionCreateNestedManyWithoutSenderInput>;
  holdNfts?: InputMaybe<MintedNftCreateNestedManyWithoutHolderUserInput>;
  id?: InputMaybe<Scalars['String']>;
  isEmailVerified?: InputMaybe<Scalars['Boolean']>;
  likedVideos?: InputMaybe<LikedVideoCreateNestedManyWithoutUserInput>;
  mobileCountryCode?: InputMaybe<Scalars['String']>;
  mobileNumber?: InputMaybe<Scalars['String']>;
  mongoUserId?: InputMaybe<Scalars['String']>;
  nftCollections?: InputMaybe<UserNftCollectionsCreateNestedManyWithoutUserInput>;
  password?: InputMaybe<Scalars['String']>;
  preferredLanguage?: InputMaybe<CommunicationLanguage>;
  prestoUserId?: InputMaybe<Scalars['String']>;
  referralAgentId?: InputMaybe<Scalars['String']>;
  refreshToken?: InputMaybe<Scalars['String']>;
  role?: InputMaybe<UserType>;
  selfRegistered?: InputMaybe<Scalars['Boolean']>;
  sendbirdAccessToken?: InputMaybe<Scalars['String']>;
  status?: InputMaybe<Status>;
  subscribeInfoAgreed?: InputMaybe<Scalars['Boolean']>;
  tosAgreed?: InputMaybe<Scalars['Boolean']>;
  transactions?: InputMaybe<TransactionCreateNestedManyWithoutPaymentByInput>;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
  userPayPerViews?: InputMaybe<UserPayPerViewCreateNestedManyWithoutUserInput>;
  userSubscriptions?: InputMaybe<UserSubscriptionCreateNestedManyWithoutSubscriberInput>;
  username?: InputMaybe<Scalars['String']>;
  voucherCode?: InputMaybe<Scalars['String']>;
  watchHistories?: InputMaybe<WatchHistoryCreateNestedManyWithoutUserInput>;
};

export type UserCreateWithoutCreatedNftsInput = {
  UserTips?: InputMaybe<TipsCreateNestedManyWithoutPayerInput>;
  accessToken?: InputMaybe<Scalars['String']>;
  approvedAt?: InputMaybe<Scalars['DateTime']>;
  archivedAt?: InputMaybe<Scalars['DateTime']>;
  avatarUrl?: InputMaybe<Scalars['String']>;
  bankAccountHolderName?: InputMaybe<Scalars['String']>;
  bankAccountNumber?: InputMaybe<Scalars['String']>;
  bankBranchCode?: InputMaybe<Scalars['String']>;
  bankName?: InputMaybe<Scalars['String']>;
  bankSwiftCode?: InputMaybe<Scalars['String']>;
  bannerUrl?: InputMaybe<Scalars['String']>;
  channel?: InputMaybe<ChannelCreateNestedOneWithoutUserInput>;
  comments?: InputMaybe<CommentCreateNestedManyWithoutUserInput>;
  companyAddressCity?: InputMaybe<Scalars['String']>;
  companyAddressCountry?: InputMaybe<Scalars['String']>;
  companyAddressLine1?: InputMaybe<Scalars['String']>;
  companyAddressLine2?: InputMaybe<Scalars['String']>;
  companyAddressPostcode?: InputMaybe<Scalars['String']>;
  companyAddressState?: InputMaybe<Scalars['String']>;
  companyName?: InputMaybe<Scalars['String']>;
  contactPersonEmail?: InputMaybe<Scalars['String']>;
  contactPersonMobileNumber?: InputMaybe<Scalars['String']>;
  contactPersonName?: InputMaybe<Scalars['String']>;
  countryCode?: InputMaybe<Scalars['String']>;
  createdAt?: InputMaybe<Scalars['DateTime']>;
  currencyCode?: InputMaybe<CurrencyCode>;
  email: Scalars['String'];
  followedBy?: InputMaybe<ChannelFollowerCreateNestedManyWithoutFolloweeOwnerInput>;
  followedChannels?: InputMaybe<ChannelFollowerCreateNestedManyWithoutFollowerInput>;
  fullName: Scalars['String'];
  giftsSent?: InputMaybe<GiftDistributionCreateNestedManyWithoutSenderInput>;
  holdNfts?: InputMaybe<MintedNftCreateNestedManyWithoutHolderUserInput>;
  id?: InputMaybe<Scalars['String']>;
  isEmailVerified?: InputMaybe<Scalars['Boolean']>;
  likedVideos?: InputMaybe<LikedVideoCreateNestedManyWithoutUserInput>;
  mobileCountryCode?: InputMaybe<Scalars['String']>;
  mobileNumber?: InputMaybe<Scalars['String']>;
  mongoUserId?: InputMaybe<Scalars['String']>;
  nftCollections?: InputMaybe<UserNftCollectionsCreateNestedManyWithoutUserInput>;
  password?: InputMaybe<Scalars['String']>;
  preferredLanguage?: InputMaybe<CommunicationLanguage>;
  prestoUserId?: InputMaybe<Scalars['String']>;
  referralAgentId?: InputMaybe<Scalars['String']>;
  refreshToken?: InputMaybe<Scalars['String']>;
  role?: InputMaybe<UserType>;
  selfRegistered?: InputMaybe<Scalars['Boolean']>;
  sendbirdAccessToken?: InputMaybe<Scalars['String']>;
  status?: InputMaybe<Status>;
  subscribeInfoAgreed?: InputMaybe<Scalars['Boolean']>;
  tosAgreed?: InputMaybe<Scalars['Boolean']>;
  transactions?: InputMaybe<TransactionCreateNestedManyWithoutPaymentByInput>;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
  userPayPerViews?: InputMaybe<UserPayPerViewCreateNestedManyWithoutUserInput>;
  userSubscriptions?: InputMaybe<UserSubscriptionCreateNestedManyWithoutSubscriberInput>;
  username?: InputMaybe<Scalars['String']>;
  voucherCode?: InputMaybe<Scalars['String']>;
  watchHistories?: InputMaybe<WatchHistoryCreateNestedManyWithoutUserInput>;
};

export type UserCreateWithoutFollowedByInput = {
  UserTips?: InputMaybe<TipsCreateNestedManyWithoutPayerInput>;
  accessToken?: InputMaybe<Scalars['String']>;
  approvedAt?: InputMaybe<Scalars['DateTime']>;
  archivedAt?: InputMaybe<Scalars['DateTime']>;
  avatarUrl?: InputMaybe<Scalars['String']>;
  bankAccountHolderName?: InputMaybe<Scalars['String']>;
  bankAccountNumber?: InputMaybe<Scalars['String']>;
  bankBranchCode?: InputMaybe<Scalars['String']>;
  bankName?: InputMaybe<Scalars['String']>;
  bankSwiftCode?: InputMaybe<Scalars['String']>;
  bannerUrl?: InputMaybe<Scalars['String']>;
  channel?: InputMaybe<ChannelCreateNestedOneWithoutUserInput>;
  comments?: InputMaybe<CommentCreateNestedManyWithoutUserInput>;
  companyAddressCity?: InputMaybe<Scalars['String']>;
  companyAddressCountry?: InputMaybe<Scalars['String']>;
  companyAddressLine1?: InputMaybe<Scalars['String']>;
  companyAddressLine2?: InputMaybe<Scalars['String']>;
  companyAddressPostcode?: InputMaybe<Scalars['String']>;
  companyAddressState?: InputMaybe<Scalars['String']>;
  companyName?: InputMaybe<Scalars['String']>;
  contactPersonEmail?: InputMaybe<Scalars['String']>;
  contactPersonMobileNumber?: InputMaybe<Scalars['String']>;
  contactPersonName?: InputMaybe<Scalars['String']>;
  countryCode?: InputMaybe<Scalars['String']>;
  createdAt?: InputMaybe<Scalars['DateTime']>;
  createdNfts?: InputMaybe<MintedNftCreateNestedManyWithoutCreatorUserInput>;
  currencyCode?: InputMaybe<CurrencyCode>;
  email: Scalars['String'];
  followedChannels?: InputMaybe<ChannelFollowerCreateNestedManyWithoutFollowerInput>;
  fullName: Scalars['String'];
  giftsSent?: InputMaybe<GiftDistributionCreateNestedManyWithoutSenderInput>;
  holdNfts?: InputMaybe<MintedNftCreateNestedManyWithoutHolderUserInput>;
  id?: InputMaybe<Scalars['String']>;
  isEmailVerified?: InputMaybe<Scalars['Boolean']>;
  likedVideos?: InputMaybe<LikedVideoCreateNestedManyWithoutUserInput>;
  mobileCountryCode?: InputMaybe<Scalars['String']>;
  mobileNumber?: InputMaybe<Scalars['String']>;
  mongoUserId?: InputMaybe<Scalars['String']>;
  nftCollections?: InputMaybe<UserNftCollectionsCreateNestedManyWithoutUserInput>;
  password?: InputMaybe<Scalars['String']>;
  preferredLanguage?: InputMaybe<CommunicationLanguage>;
  prestoUserId?: InputMaybe<Scalars['String']>;
  referralAgentId?: InputMaybe<Scalars['String']>;
  refreshToken?: InputMaybe<Scalars['String']>;
  role?: InputMaybe<UserType>;
  selfRegistered?: InputMaybe<Scalars['Boolean']>;
  sendbirdAccessToken?: InputMaybe<Scalars['String']>;
  status?: InputMaybe<Status>;
  subscribeInfoAgreed?: InputMaybe<Scalars['Boolean']>;
  tosAgreed?: InputMaybe<Scalars['Boolean']>;
  transactions?: InputMaybe<TransactionCreateNestedManyWithoutPaymentByInput>;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
  userPayPerViews?: InputMaybe<UserPayPerViewCreateNestedManyWithoutUserInput>;
  userSubscriptions?: InputMaybe<UserSubscriptionCreateNestedManyWithoutSubscriberInput>;
  username?: InputMaybe<Scalars['String']>;
  voucherCode?: InputMaybe<Scalars['String']>;
  watchHistories?: InputMaybe<WatchHistoryCreateNestedManyWithoutUserInput>;
};

export type UserCreateWithoutFollowedChannelsInput = {
  UserTips?: InputMaybe<TipsCreateNestedManyWithoutPayerInput>;
  accessToken?: InputMaybe<Scalars['String']>;
  approvedAt?: InputMaybe<Scalars['DateTime']>;
  archivedAt?: InputMaybe<Scalars['DateTime']>;
  avatarUrl?: InputMaybe<Scalars['String']>;
  bankAccountHolderName?: InputMaybe<Scalars['String']>;
  bankAccountNumber?: InputMaybe<Scalars['String']>;
  bankBranchCode?: InputMaybe<Scalars['String']>;
  bankName?: InputMaybe<Scalars['String']>;
  bankSwiftCode?: InputMaybe<Scalars['String']>;
  bannerUrl?: InputMaybe<Scalars['String']>;
  channel?: InputMaybe<ChannelCreateNestedOneWithoutUserInput>;
  comments?: InputMaybe<CommentCreateNestedManyWithoutUserInput>;
  companyAddressCity?: InputMaybe<Scalars['String']>;
  companyAddressCountry?: InputMaybe<Scalars['String']>;
  companyAddressLine1?: InputMaybe<Scalars['String']>;
  companyAddressLine2?: InputMaybe<Scalars['String']>;
  companyAddressPostcode?: InputMaybe<Scalars['String']>;
  companyAddressState?: InputMaybe<Scalars['String']>;
  companyName?: InputMaybe<Scalars['String']>;
  contactPersonEmail?: InputMaybe<Scalars['String']>;
  contactPersonMobileNumber?: InputMaybe<Scalars['String']>;
  contactPersonName?: InputMaybe<Scalars['String']>;
  countryCode?: InputMaybe<Scalars['String']>;
  createdAt?: InputMaybe<Scalars['DateTime']>;
  createdNfts?: InputMaybe<MintedNftCreateNestedManyWithoutCreatorUserInput>;
  currencyCode?: InputMaybe<CurrencyCode>;
  email: Scalars['String'];
  followedBy?: InputMaybe<ChannelFollowerCreateNestedManyWithoutFolloweeOwnerInput>;
  fullName: Scalars['String'];
  giftsSent?: InputMaybe<GiftDistributionCreateNestedManyWithoutSenderInput>;
  holdNfts?: InputMaybe<MintedNftCreateNestedManyWithoutHolderUserInput>;
  id?: InputMaybe<Scalars['String']>;
  isEmailVerified?: InputMaybe<Scalars['Boolean']>;
  likedVideos?: InputMaybe<LikedVideoCreateNestedManyWithoutUserInput>;
  mobileCountryCode?: InputMaybe<Scalars['String']>;
  mobileNumber?: InputMaybe<Scalars['String']>;
  mongoUserId?: InputMaybe<Scalars['String']>;
  nftCollections?: InputMaybe<UserNftCollectionsCreateNestedManyWithoutUserInput>;
  password?: InputMaybe<Scalars['String']>;
  preferredLanguage?: InputMaybe<CommunicationLanguage>;
  prestoUserId?: InputMaybe<Scalars['String']>;
  referralAgentId?: InputMaybe<Scalars['String']>;
  refreshToken?: InputMaybe<Scalars['String']>;
  role?: InputMaybe<UserType>;
  selfRegistered?: InputMaybe<Scalars['Boolean']>;
  sendbirdAccessToken?: InputMaybe<Scalars['String']>;
  status?: InputMaybe<Status>;
  subscribeInfoAgreed?: InputMaybe<Scalars['Boolean']>;
  tosAgreed?: InputMaybe<Scalars['Boolean']>;
  transactions?: InputMaybe<TransactionCreateNestedManyWithoutPaymentByInput>;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
  userPayPerViews?: InputMaybe<UserPayPerViewCreateNestedManyWithoutUserInput>;
  userSubscriptions?: InputMaybe<UserSubscriptionCreateNestedManyWithoutSubscriberInput>;
  username?: InputMaybe<Scalars['String']>;
  voucherCode?: InputMaybe<Scalars['String']>;
  watchHistories?: InputMaybe<WatchHistoryCreateNestedManyWithoutUserInput>;
};

export type UserCreateWithoutGiftsSentInput = {
  UserTips?: InputMaybe<TipsCreateNestedManyWithoutPayerInput>;
  accessToken?: InputMaybe<Scalars['String']>;
  approvedAt?: InputMaybe<Scalars['DateTime']>;
  archivedAt?: InputMaybe<Scalars['DateTime']>;
  avatarUrl?: InputMaybe<Scalars['String']>;
  bankAccountHolderName?: InputMaybe<Scalars['String']>;
  bankAccountNumber?: InputMaybe<Scalars['String']>;
  bankBranchCode?: InputMaybe<Scalars['String']>;
  bankName?: InputMaybe<Scalars['String']>;
  bankSwiftCode?: InputMaybe<Scalars['String']>;
  bannerUrl?: InputMaybe<Scalars['String']>;
  channel?: InputMaybe<ChannelCreateNestedOneWithoutUserInput>;
  comments?: InputMaybe<CommentCreateNestedManyWithoutUserInput>;
  companyAddressCity?: InputMaybe<Scalars['String']>;
  companyAddressCountry?: InputMaybe<Scalars['String']>;
  companyAddressLine1?: InputMaybe<Scalars['String']>;
  companyAddressLine2?: InputMaybe<Scalars['String']>;
  companyAddressPostcode?: InputMaybe<Scalars['String']>;
  companyAddressState?: InputMaybe<Scalars['String']>;
  companyName?: InputMaybe<Scalars['String']>;
  contactPersonEmail?: InputMaybe<Scalars['String']>;
  contactPersonMobileNumber?: InputMaybe<Scalars['String']>;
  contactPersonName?: InputMaybe<Scalars['String']>;
  countryCode?: InputMaybe<Scalars['String']>;
  createdAt?: InputMaybe<Scalars['DateTime']>;
  createdNfts?: InputMaybe<MintedNftCreateNestedManyWithoutCreatorUserInput>;
  currencyCode?: InputMaybe<CurrencyCode>;
  email: Scalars['String'];
  followedBy?: InputMaybe<ChannelFollowerCreateNestedManyWithoutFolloweeOwnerInput>;
  followedChannels?: InputMaybe<ChannelFollowerCreateNestedManyWithoutFollowerInput>;
  fullName: Scalars['String'];
  holdNfts?: InputMaybe<MintedNftCreateNestedManyWithoutHolderUserInput>;
  id?: InputMaybe<Scalars['String']>;
  isEmailVerified?: InputMaybe<Scalars['Boolean']>;
  likedVideos?: InputMaybe<LikedVideoCreateNestedManyWithoutUserInput>;
  mobileCountryCode?: InputMaybe<Scalars['String']>;
  mobileNumber?: InputMaybe<Scalars['String']>;
  mongoUserId?: InputMaybe<Scalars['String']>;
  nftCollections?: InputMaybe<UserNftCollectionsCreateNestedManyWithoutUserInput>;
  password?: InputMaybe<Scalars['String']>;
  preferredLanguage?: InputMaybe<CommunicationLanguage>;
  prestoUserId?: InputMaybe<Scalars['String']>;
  referralAgentId?: InputMaybe<Scalars['String']>;
  refreshToken?: InputMaybe<Scalars['String']>;
  role?: InputMaybe<UserType>;
  selfRegistered?: InputMaybe<Scalars['Boolean']>;
  sendbirdAccessToken?: InputMaybe<Scalars['String']>;
  status?: InputMaybe<Status>;
  subscribeInfoAgreed?: InputMaybe<Scalars['Boolean']>;
  tosAgreed?: InputMaybe<Scalars['Boolean']>;
  transactions?: InputMaybe<TransactionCreateNestedManyWithoutPaymentByInput>;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
  userPayPerViews?: InputMaybe<UserPayPerViewCreateNestedManyWithoutUserInput>;
  userSubscriptions?: InputMaybe<UserSubscriptionCreateNestedManyWithoutSubscriberInput>;
  username?: InputMaybe<Scalars['String']>;
  voucherCode?: InputMaybe<Scalars['String']>;
  watchHistories?: InputMaybe<WatchHistoryCreateNestedManyWithoutUserInput>;
};

export type UserCreateWithoutHoldNftsInput = {
  UserTips?: InputMaybe<TipsCreateNestedManyWithoutPayerInput>;
  accessToken?: InputMaybe<Scalars['String']>;
  approvedAt?: InputMaybe<Scalars['DateTime']>;
  archivedAt?: InputMaybe<Scalars['DateTime']>;
  avatarUrl?: InputMaybe<Scalars['String']>;
  bankAccountHolderName?: InputMaybe<Scalars['String']>;
  bankAccountNumber?: InputMaybe<Scalars['String']>;
  bankBranchCode?: InputMaybe<Scalars['String']>;
  bankName?: InputMaybe<Scalars['String']>;
  bankSwiftCode?: InputMaybe<Scalars['String']>;
  bannerUrl?: InputMaybe<Scalars['String']>;
  channel?: InputMaybe<ChannelCreateNestedOneWithoutUserInput>;
  comments?: InputMaybe<CommentCreateNestedManyWithoutUserInput>;
  companyAddressCity?: InputMaybe<Scalars['String']>;
  companyAddressCountry?: InputMaybe<Scalars['String']>;
  companyAddressLine1?: InputMaybe<Scalars['String']>;
  companyAddressLine2?: InputMaybe<Scalars['String']>;
  companyAddressPostcode?: InputMaybe<Scalars['String']>;
  companyAddressState?: InputMaybe<Scalars['String']>;
  companyName?: InputMaybe<Scalars['String']>;
  contactPersonEmail?: InputMaybe<Scalars['String']>;
  contactPersonMobileNumber?: InputMaybe<Scalars['String']>;
  contactPersonName?: InputMaybe<Scalars['String']>;
  countryCode?: InputMaybe<Scalars['String']>;
  createdAt?: InputMaybe<Scalars['DateTime']>;
  createdNfts?: InputMaybe<MintedNftCreateNestedManyWithoutCreatorUserInput>;
  currencyCode?: InputMaybe<CurrencyCode>;
  email: Scalars['String'];
  followedBy?: InputMaybe<ChannelFollowerCreateNestedManyWithoutFolloweeOwnerInput>;
  followedChannels?: InputMaybe<ChannelFollowerCreateNestedManyWithoutFollowerInput>;
  fullName: Scalars['String'];
  giftsSent?: InputMaybe<GiftDistributionCreateNestedManyWithoutSenderInput>;
  id?: InputMaybe<Scalars['String']>;
  isEmailVerified?: InputMaybe<Scalars['Boolean']>;
  likedVideos?: InputMaybe<LikedVideoCreateNestedManyWithoutUserInput>;
  mobileCountryCode?: InputMaybe<Scalars['String']>;
  mobileNumber?: InputMaybe<Scalars['String']>;
  mongoUserId?: InputMaybe<Scalars['String']>;
  nftCollections?: InputMaybe<UserNftCollectionsCreateNestedManyWithoutUserInput>;
  password?: InputMaybe<Scalars['String']>;
  preferredLanguage?: InputMaybe<CommunicationLanguage>;
  prestoUserId?: InputMaybe<Scalars['String']>;
  referralAgentId?: InputMaybe<Scalars['String']>;
  refreshToken?: InputMaybe<Scalars['String']>;
  role?: InputMaybe<UserType>;
  selfRegistered?: InputMaybe<Scalars['Boolean']>;
  sendbirdAccessToken?: InputMaybe<Scalars['String']>;
  status?: InputMaybe<Status>;
  subscribeInfoAgreed?: InputMaybe<Scalars['Boolean']>;
  tosAgreed?: InputMaybe<Scalars['Boolean']>;
  transactions?: InputMaybe<TransactionCreateNestedManyWithoutPaymentByInput>;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
  userPayPerViews?: InputMaybe<UserPayPerViewCreateNestedManyWithoutUserInput>;
  userSubscriptions?: InputMaybe<UserSubscriptionCreateNestedManyWithoutSubscriberInput>;
  username?: InputMaybe<Scalars['String']>;
  voucherCode?: InputMaybe<Scalars['String']>;
  watchHistories?: InputMaybe<WatchHistoryCreateNestedManyWithoutUserInput>;
};

export type UserCreateWithoutLikedVideosInput = {
  UserTips?: InputMaybe<TipsCreateNestedManyWithoutPayerInput>;
  accessToken?: InputMaybe<Scalars['String']>;
  approvedAt?: InputMaybe<Scalars['DateTime']>;
  archivedAt?: InputMaybe<Scalars['DateTime']>;
  avatarUrl?: InputMaybe<Scalars['String']>;
  bankAccountHolderName?: InputMaybe<Scalars['String']>;
  bankAccountNumber?: InputMaybe<Scalars['String']>;
  bankBranchCode?: InputMaybe<Scalars['String']>;
  bankName?: InputMaybe<Scalars['String']>;
  bankSwiftCode?: InputMaybe<Scalars['String']>;
  bannerUrl?: InputMaybe<Scalars['String']>;
  channel?: InputMaybe<ChannelCreateNestedOneWithoutUserInput>;
  comments?: InputMaybe<CommentCreateNestedManyWithoutUserInput>;
  companyAddressCity?: InputMaybe<Scalars['String']>;
  companyAddressCountry?: InputMaybe<Scalars['String']>;
  companyAddressLine1?: InputMaybe<Scalars['String']>;
  companyAddressLine2?: InputMaybe<Scalars['String']>;
  companyAddressPostcode?: InputMaybe<Scalars['String']>;
  companyAddressState?: InputMaybe<Scalars['String']>;
  companyName?: InputMaybe<Scalars['String']>;
  contactPersonEmail?: InputMaybe<Scalars['String']>;
  contactPersonMobileNumber?: InputMaybe<Scalars['String']>;
  contactPersonName?: InputMaybe<Scalars['String']>;
  countryCode?: InputMaybe<Scalars['String']>;
  createdAt?: InputMaybe<Scalars['DateTime']>;
  createdNfts?: InputMaybe<MintedNftCreateNestedManyWithoutCreatorUserInput>;
  currencyCode?: InputMaybe<CurrencyCode>;
  email: Scalars['String'];
  followedBy?: InputMaybe<ChannelFollowerCreateNestedManyWithoutFolloweeOwnerInput>;
  followedChannels?: InputMaybe<ChannelFollowerCreateNestedManyWithoutFollowerInput>;
  fullName: Scalars['String'];
  giftsSent?: InputMaybe<GiftDistributionCreateNestedManyWithoutSenderInput>;
  holdNfts?: InputMaybe<MintedNftCreateNestedManyWithoutHolderUserInput>;
  id?: InputMaybe<Scalars['String']>;
  isEmailVerified?: InputMaybe<Scalars['Boolean']>;
  mobileCountryCode?: InputMaybe<Scalars['String']>;
  mobileNumber?: InputMaybe<Scalars['String']>;
  mongoUserId?: InputMaybe<Scalars['String']>;
  nftCollections?: InputMaybe<UserNftCollectionsCreateNestedManyWithoutUserInput>;
  password?: InputMaybe<Scalars['String']>;
  preferredLanguage?: InputMaybe<CommunicationLanguage>;
  prestoUserId?: InputMaybe<Scalars['String']>;
  referralAgentId?: InputMaybe<Scalars['String']>;
  refreshToken?: InputMaybe<Scalars['String']>;
  role?: InputMaybe<UserType>;
  selfRegistered?: InputMaybe<Scalars['Boolean']>;
  sendbirdAccessToken?: InputMaybe<Scalars['String']>;
  status?: InputMaybe<Status>;
  subscribeInfoAgreed?: InputMaybe<Scalars['Boolean']>;
  tosAgreed?: InputMaybe<Scalars['Boolean']>;
  transactions?: InputMaybe<TransactionCreateNestedManyWithoutPaymentByInput>;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
  userPayPerViews?: InputMaybe<UserPayPerViewCreateNestedManyWithoutUserInput>;
  userSubscriptions?: InputMaybe<UserSubscriptionCreateNestedManyWithoutSubscriberInput>;
  username?: InputMaybe<Scalars['String']>;
  voucherCode?: InputMaybe<Scalars['String']>;
  watchHistories?: InputMaybe<WatchHistoryCreateNestedManyWithoutUserInput>;
};

export type UserCreateWithoutNftCollectionsInput = {
  UserTips?: InputMaybe<TipsCreateNestedManyWithoutPayerInput>;
  accessToken?: InputMaybe<Scalars['String']>;
  approvedAt?: InputMaybe<Scalars['DateTime']>;
  archivedAt?: InputMaybe<Scalars['DateTime']>;
  avatarUrl?: InputMaybe<Scalars['String']>;
  bankAccountHolderName?: InputMaybe<Scalars['String']>;
  bankAccountNumber?: InputMaybe<Scalars['String']>;
  bankBranchCode?: InputMaybe<Scalars['String']>;
  bankName?: InputMaybe<Scalars['String']>;
  bankSwiftCode?: InputMaybe<Scalars['String']>;
  bannerUrl?: InputMaybe<Scalars['String']>;
  channel?: InputMaybe<ChannelCreateNestedOneWithoutUserInput>;
  comments?: InputMaybe<CommentCreateNestedManyWithoutUserInput>;
  companyAddressCity?: InputMaybe<Scalars['String']>;
  companyAddressCountry?: InputMaybe<Scalars['String']>;
  companyAddressLine1?: InputMaybe<Scalars['String']>;
  companyAddressLine2?: InputMaybe<Scalars['String']>;
  companyAddressPostcode?: InputMaybe<Scalars['String']>;
  companyAddressState?: InputMaybe<Scalars['String']>;
  companyName?: InputMaybe<Scalars['String']>;
  contactPersonEmail?: InputMaybe<Scalars['String']>;
  contactPersonMobileNumber?: InputMaybe<Scalars['String']>;
  contactPersonName?: InputMaybe<Scalars['String']>;
  countryCode?: InputMaybe<Scalars['String']>;
  createdAt?: InputMaybe<Scalars['DateTime']>;
  createdNfts?: InputMaybe<MintedNftCreateNestedManyWithoutCreatorUserInput>;
  currencyCode?: InputMaybe<CurrencyCode>;
  email: Scalars['String'];
  followedBy?: InputMaybe<ChannelFollowerCreateNestedManyWithoutFolloweeOwnerInput>;
  followedChannels?: InputMaybe<ChannelFollowerCreateNestedManyWithoutFollowerInput>;
  fullName: Scalars['String'];
  giftsSent?: InputMaybe<GiftDistributionCreateNestedManyWithoutSenderInput>;
  holdNfts?: InputMaybe<MintedNftCreateNestedManyWithoutHolderUserInput>;
  id?: InputMaybe<Scalars['String']>;
  isEmailVerified?: InputMaybe<Scalars['Boolean']>;
  likedVideos?: InputMaybe<LikedVideoCreateNestedManyWithoutUserInput>;
  mobileCountryCode?: InputMaybe<Scalars['String']>;
  mobileNumber?: InputMaybe<Scalars['String']>;
  mongoUserId?: InputMaybe<Scalars['String']>;
  password?: InputMaybe<Scalars['String']>;
  preferredLanguage?: InputMaybe<CommunicationLanguage>;
  prestoUserId?: InputMaybe<Scalars['String']>;
  referralAgentId?: InputMaybe<Scalars['String']>;
  refreshToken?: InputMaybe<Scalars['String']>;
  role?: InputMaybe<UserType>;
  selfRegistered?: InputMaybe<Scalars['Boolean']>;
  sendbirdAccessToken?: InputMaybe<Scalars['String']>;
  status?: InputMaybe<Status>;
  subscribeInfoAgreed?: InputMaybe<Scalars['Boolean']>;
  tosAgreed?: InputMaybe<Scalars['Boolean']>;
  transactions?: InputMaybe<TransactionCreateNestedManyWithoutPaymentByInput>;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
  userPayPerViews?: InputMaybe<UserPayPerViewCreateNestedManyWithoutUserInput>;
  userSubscriptions?: InputMaybe<UserSubscriptionCreateNestedManyWithoutSubscriberInput>;
  username?: InputMaybe<Scalars['String']>;
  voucherCode?: InputMaybe<Scalars['String']>;
  watchHistories?: InputMaybe<WatchHistoryCreateNestedManyWithoutUserInput>;
};

export type UserCreateWithoutTransactionsInput = {
  UserTips?: InputMaybe<TipsCreateNestedManyWithoutPayerInput>;
  accessToken?: InputMaybe<Scalars['String']>;
  approvedAt?: InputMaybe<Scalars['DateTime']>;
  archivedAt?: InputMaybe<Scalars['DateTime']>;
  avatarUrl?: InputMaybe<Scalars['String']>;
  bankAccountHolderName?: InputMaybe<Scalars['String']>;
  bankAccountNumber?: InputMaybe<Scalars['String']>;
  bankBranchCode?: InputMaybe<Scalars['String']>;
  bankName?: InputMaybe<Scalars['String']>;
  bankSwiftCode?: InputMaybe<Scalars['String']>;
  bannerUrl?: InputMaybe<Scalars['String']>;
  channel?: InputMaybe<ChannelCreateNestedOneWithoutUserInput>;
  comments?: InputMaybe<CommentCreateNestedManyWithoutUserInput>;
  companyAddressCity?: InputMaybe<Scalars['String']>;
  companyAddressCountry?: InputMaybe<Scalars['String']>;
  companyAddressLine1?: InputMaybe<Scalars['String']>;
  companyAddressLine2?: InputMaybe<Scalars['String']>;
  companyAddressPostcode?: InputMaybe<Scalars['String']>;
  companyAddressState?: InputMaybe<Scalars['String']>;
  companyName?: InputMaybe<Scalars['String']>;
  contactPersonEmail?: InputMaybe<Scalars['String']>;
  contactPersonMobileNumber?: InputMaybe<Scalars['String']>;
  contactPersonName?: InputMaybe<Scalars['String']>;
  countryCode?: InputMaybe<Scalars['String']>;
  createdAt?: InputMaybe<Scalars['DateTime']>;
  createdNfts?: InputMaybe<MintedNftCreateNestedManyWithoutCreatorUserInput>;
  currencyCode?: InputMaybe<CurrencyCode>;
  email: Scalars['String'];
  followedBy?: InputMaybe<ChannelFollowerCreateNestedManyWithoutFolloweeOwnerInput>;
  followedChannels?: InputMaybe<ChannelFollowerCreateNestedManyWithoutFollowerInput>;
  fullName: Scalars['String'];
  giftsSent?: InputMaybe<GiftDistributionCreateNestedManyWithoutSenderInput>;
  holdNfts?: InputMaybe<MintedNftCreateNestedManyWithoutHolderUserInput>;
  id?: InputMaybe<Scalars['String']>;
  isEmailVerified?: InputMaybe<Scalars['Boolean']>;
  likedVideos?: InputMaybe<LikedVideoCreateNestedManyWithoutUserInput>;
  mobileCountryCode?: InputMaybe<Scalars['String']>;
  mobileNumber?: InputMaybe<Scalars['String']>;
  mongoUserId?: InputMaybe<Scalars['String']>;
  nftCollections?: InputMaybe<UserNftCollectionsCreateNestedManyWithoutUserInput>;
  password?: InputMaybe<Scalars['String']>;
  preferredLanguage?: InputMaybe<CommunicationLanguage>;
  prestoUserId?: InputMaybe<Scalars['String']>;
  referralAgentId?: InputMaybe<Scalars['String']>;
  refreshToken?: InputMaybe<Scalars['String']>;
  role?: InputMaybe<UserType>;
  selfRegistered?: InputMaybe<Scalars['Boolean']>;
  sendbirdAccessToken?: InputMaybe<Scalars['String']>;
  status?: InputMaybe<Status>;
  subscribeInfoAgreed?: InputMaybe<Scalars['Boolean']>;
  tosAgreed?: InputMaybe<Scalars['Boolean']>;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
  userPayPerViews?: InputMaybe<UserPayPerViewCreateNestedManyWithoutUserInput>;
  userSubscriptions?: InputMaybe<UserSubscriptionCreateNestedManyWithoutSubscriberInput>;
  username?: InputMaybe<Scalars['String']>;
  voucherCode?: InputMaybe<Scalars['String']>;
  watchHistories?: InputMaybe<WatchHistoryCreateNestedManyWithoutUserInput>;
};

export type UserCreateWithoutUserPayPerViewsInput = {
  UserTips?: InputMaybe<TipsCreateNestedManyWithoutPayerInput>;
  accessToken?: InputMaybe<Scalars['String']>;
  approvedAt?: InputMaybe<Scalars['DateTime']>;
  archivedAt?: InputMaybe<Scalars['DateTime']>;
  avatarUrl?: InputMaybe<Scalars['String']>;
  bankAccountHolderName?: InputMaybe<Scalars['String']>;
  bankAccountNumber?: InputMaybe<Scalars['String']>;
  bankBranchCode?: InputMaybe<Scalars['String']>;
  bankName?: InputMaybe<Scalars['String']>;
  bankSwiftCode?: InputMaybe<Scalars['String']>;
  bannerUrl?: InputMaybe<Scalars['String']>;
  channel?: InputMaybe<ChannelCreateNestedOneWithoutUserInput>;
  comments?: InputMaybe<CommentCreateNestedManyWithoutUserInput>;
  companyAddressCity?: InputMaybe<Scalars['String']>;
  companyAddressCountry?: InputMaybe<Scalars['String']>;
  companyAddressLine1?: InputMaybe<Scalars['String']>;
  companyAddressLine2?: InputMaybe<Scalars['String']>;
  companyAddressPostcode?: InputMaybe<Scalars['String']>;
  companyAddressState?: InputMaybe<Scalars['String']>;
  companyName?: InputMaybe<Scalars['String']>;
  contactPersonEmail?: InputMaybe<Scalars['String']>;
  contactPersonMobileNumber?: InputMaybe<Scalars['String']>;
  contactPersonName?: InputMaybe<Scalars['String']>;
  countryCode?: InputMaybe<Scalars['String']>;
  createdAt?: InputMaybe<Scalars['DateTime']>;
  createdNfts?: InputMaybe<MintedNftCreateNestedManyWithoutCreatorUserInput>;
  currencyCode?: InputMaybe<CurrencyCode>;
  email: Scalars['String'];
  followedBy?: InputMaybe<ChannelFollowerCreateNestedManyWithoutFolloweeOwnerInput>;
  followedChannels?: InputMaybe<ChannelFollowerCreateNestedManyWithoutFollowerInput>;
  fullName: Scalars['String'];
  giftsSent?: InputMaybe<GiftDistributionCreateNestedManyWithoutSenderInput>;
  holdNfts?: InputMaybe<MintedNftCreateNestedManyWithoutHolderUserInput>;
  id?: InputMaybe<Scalars['String']>;
  isEmailVerified?: InputMaybe<Scalars['Boolean']>;
  likedVideos?: InputMaybe<LikedVideoCreateNestedManyWithoutUserInput>;
  mobileCountryCode?: InputMaybe<Scalars['String']>;
  mobileNumber?: InputMaybe<Scalars['String']>;
  mongoUserId?: InputMaybe<Scalars['String']>;
  nftCollections?: InputMaybe<UserNftCollectionsCreateNestedManyWithoutUserInput>;
  password?: InputMaybe<Scalars['String']>;
  preferredLanguage?: InputMaybe<CommunicationLanguage>;
  prestoUserId?: InputMaybe<Scalars['String']>;
  referralAgentId?: InputMaybe<Scalars['String']>;
  refreshToken?: InputMaybe<Scalars['String']>;
  role?: InputMaybe<UserType>;
  selfRegistered?: InputMaybe<Scalars['Boolean']>;
  sendbirdAccessToken?: InputMaybe<Scalars['String']>;
  status?: InputMaybe<Status>;
  subscribeInfoAgreed?: InputMaybe<Scalars['Boolean']>;
  tosAgreed?: InputMaybe<Scalars['Boolean']>;
  transactions?: InputMaybe<TransactionCreateNestedManyWithoutPaymentByInput>;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
  userSubscriptions?: InputMaybe<UserSubscriptionCreateNestedManyWithoutSubscriberInput>;
  username?: InputMaybe<Scalars['String']>;
  voucherCode?: InputMaybe<Scalars['String']>;
  watchHistories?: InputMaybe<WatchHistoryCreateNestedManyWithoutUserInput>;
};

export type UserCreateWithoutUserSubscriptionsInput = {
  UserTips?: InputMaybe<TipsCreateNestedManyWithoutPayerInput>;
  accessToken?: InputMaybe<Scalars['String']>;
  approvedAt?: InputMaybe<Scalars['DateTime']>;
  archivedAt?: InputMaybe<Scalars['DateTime']>;
  avatarUrl?: InputMaybe<Scalars['String']>;
  bankAccountHolderName?: InputMaybe<Scalars['String']>;
  bankAccountNumber?: InputMaybe<Scalars['String']>;
  bankBranchCode?: InputMaybe<Scalars['String']>;
  bankName?: InputMaybe<Scalars['String']>;
  bankSwiftCode?: InputMaybe<Scalars['String']>;
  bannerUrl?: InputMaybe<Scalars['String']>;
  channel?: InputMaybe<ChannelCreateNestedOneWithoutUserInput>;
  comments?: InputMaybe<CommentCreateNestedManyWithoutUserInput>;
  companyAddressCity?: InputMaybe<Scalars['String']>;
  companyAddressCountry?: InputMaybe<Scalars['String']>;
  companyAddressLine1?: InputMaybe<Scalars['String']>;
  companyAddressLine2?: InputMaybe<Scalars['String']>;
  companyAddressPostcode?: InputMaybe<Scalars['String']>;
  companyAddressState?: InputMaybe<Scalars['String']>;
  companyName?: InputMaybe<Scalars['String']>;
  contactPersonEmail?: InputMaybe<Scalars['String']>;
  contactPersonMobileNumber?: InputMaybe<Scalars['String']>;
  contactPersonName?: InputMaybe<Scalars['String']>;
  countryCode?: InputMaybe<Scalars['String']>;
  createdAt?: InputMaybe<Scalars['DateTime']>;
  createdNfts?: InputMaybe<MintedNftCreateNestedManyWithoutCreatorUserInput>;
  currencyCode?: InputMaybe<CurrencyCode>;
  email: Scalars['String'];
  followedBy?: InputMaybe<ChannelFollowerCreateNestedManyWithoutFolloweeOwnerInput>;
  followedChannels?: InputMaybe<ChannelFollowerCreateNestedManyWithoutFollowerInput>;
  fullName: Scalars['String'];
  giftsSent?: InputMaybe<GiftDistributionCreateNestedManyWithoutSenderInput>;
  holdNfts?: InputMaybe<MintedNftCreateNestedManyWithoutHolderUserInput>;
  id?: InputMaybe<Scalars['String']>;
  isEmailVerified?: InputMaybe<Scalars['Boolean']>;
  likedVideos?: InputMaybe<LikedVideoCreateNestedManyWithoutUserInput>;
  mobileCountryCode?: InputMaybe<Scalars['String']>;
  mobileNumber?: InputMaybe<Scalars['String']>;
  mongoUserId?: InputMaybe<Scalars['String']>;
  nftCollections?: InputMaybe<UserNftCollectionsCreateNestedManyWithoutUserInput>;
  password?: InputMaybe<Scalars['String']>;
  preferredLanguage?: InputMaybe<CommunicationLanguage>;
  prestoUserId?: InputMaybe<Scalars['String']>;
  referralAgentId?: InputMaybe<Scalars['String']>;
  refreshToken?: InputMaybe<Scalars['String']>;
  role?: InputMaybe<UserType>;
  selfRegistered?: InputMaybe<Scalars['Boolean']>;
  sendbirdAccessToken?: InputMaybe<Scalars['String']>;
  status?: InputMaybe<Status>;
  subscribeInfoAgreed?: InputMaybe<Scalars['Boolean']>;
  tosAgreed?: InputMaybe<Scalars['Boolean']>;
  transactions?: InputMaybe<TransactionCreateNestedManyWithoutPaymentByInput>;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
  userPayPerViews?: InputMaybe<UserPayPerViewCreateNestedManyWithoutUserInput>;
  username?: InputMaybe<Scalars['String']>;
  voucherCode?: InputMaybe<Scalars['String']>;
  watchHistories?: InputMaybe<WatchHistoryCreateNestedManyWithoutUserInput>;
};

export type UserCreateWithoutUserTipsInput = {
  accessToken?: InputMaybe<Scalars['String']>;
  approvedAt?: InputMaybe<Scalars['DateTime']>;
  archivedAt?: InputMaybe<Scalars['DateTime']>;
  avatarUrl?: InputMaybe<Scalars['String']>;
  bankAccountHolderName?: InputMaybe<Scalars['String']>;
  bankAccountNumber?: InputMaybe<Scalars['String']>;
  bankBranchCode?: InputMaybe<Scalars['String']>;
  bankName?: InputMaybe<Scalars['String']>;
  bankSwiftCode?: InputMaybe<Scalars['String']>;
  bannerUrl?: InputMaybe<Scalars['String']>;
  channel?: InputMaybe<ChannelCreateNestedOneWithoutUserInput>;
  comments?: InputMaybe<CommentCreateNestedManyWithoutUserInput>;
  companyAddressCity?: InputMaybe<Scalars['String']>;
  companyAddressCountry?: InputMaybe<Scalars['String']>;
  companyAddressLine1?: InputMaybe<Scalars['String']>;
  companyAddressLine2?: InputMaybe<Scalars['String']>;
  companyAddressPostcode?: InputMaybe<Scalars['String']>;
  companyAddressState?: InputMaybe<Scalars['String']>;
  companyName?: InputMaybe<Scalars['String']>;
  contactPersonEmail?: InputMaybe<Scalars['String']>;
  contactPersonMobileNumber?: InputMaybe<Scalars['String']>;
  contactPersonName?: InputMaybe<Scalars['String']>;
  countryCode?: InputMaybe<Scalars['String']>;
  createdAt?: InputMaybe<Scalars['DateTime']>;
  createdNfts?: InputMaybe<MintedNftCreateNestedManyWithoutCreatorUserInput>;
  currencyCode?: InputMaybe<CurrencyCode>;
  email: Scalars['String'];
  followedBy?: InputMaybe<ChannelFollowerCreateNestedManyWithoutFolloweeOwnerInput>;
  followedChannels?: InputMaybe<ChannelFollowerCreateNestedManyWithoutFollowerInput>;
  fullName: Scalars['String'];
  giftsSent?: InputMaybe<GiftDistributionCreateNestedManyWithoutSenderInput>;
  holdNfts?: InputMaybe<MintedNftCreateNestedManyWithoutHolderUserInput>;
  id?: InputMaybe<Scalars['String']>;
  isEmailVerified?: InputMaybe<Scalars['Boolean']>;
  likedVideos?: InputMaybe<LikedVideoCreateNestedManyWithoutUserInput>;
  mobileCountryCode?: InputMaybe<Scalars['String']>;
  mobileNumber?: InputMaybe<Scalars['String']>;
  mongoUserId?: InputMaybe<Scalars['String']>;
  nftCollections?: InputMaybe<UserNftCollectionsCreateNestedManyWithoutUserInput>;
  password?: InputMaybe<Scalars['String']>;
  preferredLanguage?: InputMaybe<CommunicationLanguage>;
  prestoUserId?: InputMaybe<Scalars['String']>;
  referralAgentId?: InputMaybe<Scalars['String']>;
  refreshToken?: InputMaybe<Scalars['String']>;
  role?: InputMaybe<UserType>;
  selfRegistered?: InputMaybe<Scalars['Boolean']>;
  sendbirdAccessToken?: InputMaybe<Scalars['String']>;
  status?: InputMaybe<Status>;
  subscribeInfoAgreed?: InputMaybe<Scalars['Boolean']>;
  tosAgreed?: InputMaybe<Scalars['Boolean']>;
  transactions?: InputMaybe<TransactionCreateNestedManyWithoutPaymentByInput>;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
  userPayPerViews?: InputMaybe<UserPayPerViewCreateNestedManyWithoutUserInput>;
  userSubscriptions?: InputMaybe<UserSubscriptionCreateNestedManyWithoutSubscriberInput>;
  username?: InputMaybe<Scalars['String']>;
  voucherCode?: InputMaybe<Scalars['String']>;
  watchHistories?: InputMaybe<WatchHistoryCreateNestedManyWithoutUserInput>;
};

export type UserCreateWithoutWatchHistoriesInput = {
  UserTips?: InputMaybe<TipsCreateNestedManyWithoutPayerInput>;
  accessToken?: InputMaybe<Scalars['String']>;
  approvedAt?: InputMaybe<Scalars['DateTime']>;
  archivedAt?: InputMaybe<Scalars['DateTime']>;
  avatarUrl?: InputMaybe<Scalars['String']>;
  bankAccountHolderName?: InputMaybe<Scalars['String']>;
  bankAccountNumber?: InputMaybe<Scalars['String']>;
  bankBranchCode?: InputMaybe<Scalars['String']>;
  bankName?: InputMaybe<Scalars['String']>;
  bankSwiftCode?: InputMaybe<Scalars['String']>;
  bannerUrl?: InputMaybe<Scalars['String']>;
  channel?: InputMaybe<ChannelCreateNestedOneWithoutUserInput>;
  comments?: InputMaybe<CommentCreateNestedManyWithoutUserInput>;
  companyAddressCity?: InputMaybe<Scalars['String']>;
  companyAddressCountry?: InputMaybe<Scalars['String']>;
  companyAddressLine1?: InputMaybe<Scalars['String']>;
  companyAddressLine2?: InputMaybe<Scalars['String']>;
  companyAddressPostcode?: InputMaybe<Scalars['String']>;
  companyAddressState?: InputMaybe<Scalars['String']>;
  companyName?: InputMaybe<Scalars['String']>;
  contactPersonEmail?: InputMaybe<Scalars['String']>;
  contactPersonMobileNumber?: InputMaybe<Scalars['String']>;
  contactPersonName?: InputMaybe<Scalars['String']>;
  countryCode?: InputMaybe<Scalars['String']>;
  createdAt?: InputMaybe<Scalars['DateTime']>;
  createdNfts?: InputMaybe<MintedNftCreateNestedManyWithoutCreatorUserInput>;
  currencyCode?: InputMaybe<CurrencyCode>;
  email: Scalars['String'];
  followedBy?: InputMaybe<ChannelFollowerCreateNestedManyWithoutFolloweeOwnerInput>;
  followedChannels?: InputMaybe<ChannelFollowerCreateNestedManyWithoutFollowerInput>;
  fullName: Scalars['String'];
  giftsSent?: InputMaybe<GiftDistributionCreateNestedManyWithoutSenderInput>;
  holdNfts?: InputMaybe<MintedNftCreateNestedManyWithoutHolderUserInput>;
  id?: InputMaybe<Scalars['String']>;
  isEmailVerified?: InputMaybe<Scalars['Boolean']>;
  likedVideos?: InputMaybe<LikedVideoCreateNestedManyWithoutUserInput>;
  mobileCountryCode?: InputMaybe<Scalars['String']>;
  mobileNumber?: InputMaybe<Scalars['String']>;
  mongoUserId?: InputMaybe<Scalars['String']>;
  nftCollections?: InputMaybe<UserNftCollectionsCreateNestedManyWithoutUserInput>;
  password?: InputMaybe<Scalars['String']>;
  preferredLanguage?: InputMaybe<CommunicationLanguage>;
  prestoUserId?: InputMaybe<Scalars['String']>;
  referralAgentId?: InputMaybe<Scalars['String']>;
  refreshToken?: InputMaybe<Scalars['String']>;
  role?: InputMaybe<UserType>;
  selfRegistered?: InputMaybe<Scalars['Boolean']>;
  sendbirdAccessToken?: InputMaybe<Scalars['String']>;
  status?: InputMaybe<Status>;
  subscribeInfoAgreed?: InputMaybe<Scalars['Boolean']>;
  tosAgreed?: InputMaybe<Scalars['Boolean']>;
  transactions?: InputMaybe<TransactionCreateNestedManyWithoutPaymentByInput>;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
  userPayPerViews?: InputMaybe<UserPayPerViewCreateNestedManyWithoutUserInput>;
  userSubscriptions?: InputMaybe<UserSubscriptionCreateNestedManyWithoutSubscriberInput>;
  username?: InputMaybe<Scalars['String']>;
  voucherCode?: InputMaybe<Scalars['String']>;
};

export type UserGroupBy = {
  __typename?: 'UserGroupBy';
  _count?: Maybe<UserCountAggregate>;
  _max?: Maybe<UserMaxAggregate>;
  _min?: Maybe<UserMinAggregate>;
  accessToken?: Maybe<Scalars['String']>;
  approvedAt?: Maybe<Scalars['DateTime']>;
  archivedAt?: Maybe<Scalars['DateTime']>;
  avatarUrl?: Maybe<Scalars['String']>;
  bankAccountHolderName?: Maybe<Scalars['String']>;
  bankAccountNumber?: Maybe<Scalars['String']>;
  bankBranchCode?: Maybe<Scalars['String']>;
  bankName?: Maybe<Scalars['String']>;
  bankSwiftCode?: Maybe<Scalars['String']>;
  bannerUrl?: Maybe<Scalars['String']>;
  companyAddressCity?: Maybe<Scalars['String']>;
  companyAddressCountry?: Maybe<Scalars['String']>;
  companyAddressLine1?: Maybe<Scalars['String']>;
  companyAddressLine2?: Maybe<Scalars['String']>;
  companyAddressPostcode?: Maybe<Scalars['String']>;
  companyAddressState?: Maybe<Scalars['String']>;
  companyName?: Maybe<Scalars['String']>;
  contactPersonEmail?: Maybe<Scalars['String']>;
  contactPersonMobileNumber?: Maybe<Scalars['String']>;
  contactPersonName?: Maybe<Scalars['String']>;
  countryCode?: Maybe<Scalars['String']>;
  createdAt: Scalars['DateTime'];
  currencyCode: CurrencyCode;
  email: Scalars['String'];
  fullName: Scalars['String'];
  id: Scalars['String'];
  isEmailVerified: Scalars['Boolean'];
  mobileCountryCode?: Maybe<Scalars['String']>;
  mobileNumber?: Maybe<Scalars['String']>;
  mongoUserId?: Maybe<Scalars['String']>;
  password?: Maybe<Scalars['String']>;
  preferredLanguage?: Maybe<CommunicationLanguage>;
  prestoUserId?: Maybe<Scalars['String']>;
  referralAgentId?: Maybe<Scalars['String']>;
  refreshToken?: Maybe<Scalars['String']>;
  role?: Maybe<UserType>;
  selfRegistered: Scalars['Boolean'];
  sendbirdAccessToken?: Maybe<Scalars['String']>;
  status: Status;
  subscribeInfoAgreed: Scalars['Boolean'];
  tosAgreed: Scalars['Boolean'];
  updatedAt: Scalars['DateTime'];
  username?: Maybe<Scalars['String']>;
  voucherCode?: Maybe<Scalars['String']>;
};

export type UserMaxAggregate = {
  __typename?: 'UserMaxAggregate';
  accessToken?: Maybe<Scalars['String']>;
  approvedAt?: Maybe<Scalars['DateTime']>;
  archivedAt?: Maybe<Scalars['DateTime']>;
  avatarUrl?: Maybe<Scalars['String']>;
  bankAccountHolderName?: Maybe<Scalars['String']>;
  bankAccountNumber?: Maybe<Scalars['String']>;
  bankBranchCode?: Maybe<Scalars['String']>;
  bankName?: Maybe<Scalars['String']>;
  bankSwiftCode?: Maybe<Scalars['String']>;
  bannerUrl?: Maybe<Scalars['String']>;
  companyAddressCity?: Maybe<Scalars['String']>;
  companyAddressCountry?: Maybe<Scalars['String']>;
  companyAddressLine1?: Maybe<Scalars['String']>;
  companyAddressLine2?: Maybe<Scalars['String']>;
  companyAddressPostcode?: Maybe<Scalars['String']>;
  companyAddressState?: Maybe<Scalars['String']>;
  companyName?: Maybe<Scalars['String']>;
  contactPersonEmail?: Maybe<Scalars['String']>;
  contactPersonMobileNumber?: Maybe<Scalars['String']>;
  contactPersonName?: Maybe<Scalars['String']>;
  countryCode?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  currencyCode?: Maybe<CurrencyCode>;
  email?: Maybe<Scalars['String']>;
  fullName?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  isEmailVerified?: Maybe<Scalars['Boolean']>;
  mobileCountryCode?: Maybe<Scalars['String']>;
  mobileNumber?: Maybe<Scalars['String']>;
  mongoUserId?: Maybe<Scalars['String']>;
  password?: Maybe<Scalars['String']>;
  preferredLanguage?: Maybe<CommunicationLanguage>;
  prestoUserId?: Maybe<Scalars['String']>;
  referralAgentId?: Maybe<Scalars['String']>;
  refreshToken?: Maybe<Scalars['String']>;
  role?: Maybe<UserType>;
  selfRegistered?: Maybe<Scalars['Boolean']>;
  sendbirdAccessToken?: Maybe<Scalars['String']>;
  status?: Maybe<Status>;
  subscribeInfoAgreed?: Maybe<Scalars['Boolean']>;
  tosAgreed?: Maybe<Scalars['Boolean']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  username?: Maybe<Scalars['String']>;
  voucherCode?: Maybe<Scalars['String']>;
};

export type UserMaxOrderByAggregateInput = {
  accessToken?: InputMaybe<SortOrder>;
  approvedAt?: InputMaybe<SortOrder>;
  archivedAt?: InputMaybe<SortOrder>;
  avatarUrl?: InputMaybe<SortOrder>;
  bankAccountHolderName?: InputMaybe<SortOrder>;
  bankAccountNumber?: InputMaybe<SortOrder>;
  bankBranchCode?: InputMaybe<SortOrder>;
  bankName?: InputMaybe<SortOrder>;
  bankSwiftCode?: InputMaybe<SortOrder>;
  bannerUrl?: InputMaybe<SortOrder>;
  companyAddressCity?: InputMaybe<SortOrder>;
  companyAddressCountry?: InputMaybe<SortOrder>;
  companyAddressLine1?: InputMaybe<SortOrder>;
  companyAddressLine2?: InputMaybe<SortOrder>;
  companyAddressPostcode?: InputMaybe<SortOrder>;
  companyAddressState?: InputMaybe<SortOrder>;
  companyName?: InputMaybe<SortOrder>;
  contactPersonEmail?: InputMaybe<SortOrder>;
  contactPersonMobileNumber?: InputMaybe<SortOrder>;
  contactPersonName?: InputMaybe<SortOrder>;
  countryCode?: InputMaybe<SortOrder>;
  createdAt?: InputMaybe<SortOrder>;
  currencyCode?: InputMaybe<SortOrder>;
  email?: InputMaybe<SortOrder>;
  fullName?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  isEmailVerified?: InputMaybe<SortOrder>;
  mobileCountryCode?: InputMaybe<SortOrder>;
  mobileNumber?: InputMaybe<SortOrder>;
  mongoUserId?: InputMaybe<SortOrder>;
  password?: InputMaybe<SortOrder>;
  preferredLanguage?: InputMaybe<SortOrder>;
  prestoUserId?: InputMaybe<SortOrder>;
  referralAgentId?: InputMaybe<SortOrder>;
  refreshToken?: InputMaybe<SortOrder>;
  role?: InputMaybe<SortOrder>;
  selfRegistered?: InputMaybe<SortOrder>;
  sendbirdAccessToken?: InputMaybe<SortOrder>;
  status?: InputMaybe<SortOrder>;
  subscribeInfoAgreed?: InputMaybe<SortOrder>;
  tosAgreed?: InputMaybe<SortOrder>;
  updatedAt?: InputMaybe<SortOrder>;
  username?: InputMaybe<SortOrder>;
  voucherCode?: InputMaybe<SortOrder>;
};

export type UserMinAggregate = {
  __typename?: 'UserMinAggregate';
  accessToken?: Maybe<Scalars['String']>;
  approvedAt?: Maybe<Scalars['DateTime']>;
  archivedAt?: Maybe<Scalars['DateTime']>;
  avatarUrl?: Maybe<Scalars['String']>;
  bankAccountHolderName?: Maybe<Scalars['String']>;
  bankAccountNumber?: Maybe<Scalars['String']>;
  bankBranchCode?: Maybe<Scalars['String']>;
  bankName?: Maybe<Scalars['String']>;
  bankSwiftCode?: Maybe<Scalars['String']>;
  bannerUrl?: Maybe<Scalars['String']>;
  companyAddressCity?: Maybe<Scalars['String']>;
  companyAddressCountry?: Maybe<Scalars['String']>;
  companyAddressLine1?: Maybe<Scalars['String']>;
  companyAddressLine2?: Maybe<Scalars['String']>;
  companyAddressPostcode?: Maybe<Scalars['String']>;
  companyAddressState?: Maybe<Scalars['String']>;
  companyName?: Maybe<Scalars['String']>;
  contactPersonEmail?: Maybe<Scalars['String']>;
  contactPersonMobileNumber?: Maybe<Scalars['String']>;
  contactPersonName?: Maybe<Scalars['String']>;
  countryCode?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  currencyCode?: Maybe<CurrencyCode>;
  email?: Maybe<Scalars['String']>;
  fullName?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  isEmailVerified?: Maybe<Scalars['Boolean']>;
  mobileCountryCode?: Maybe<Scalars['String']>;
  mobileNumber?: Maybe<Scalars['String']>;
  mongoUserId?: Maybe<Scalars['String']>;
  password?: Maybe<Scalars['String']>;
  preferredLanguage?: Maybe<CommunicationLanguage>;
  prestoUserId?: Maybe<Scalars['String']>;
  referralAgentId?: Maybe<Scalars['String']>;
  refreshToken?: Maybe<Scalars['String']>;
  role?: Maybe<UserType>;
  selfRegistered?: Maybe<Scalars['Boolean']>;
  sendbirdAccessToken?: Maybe<Scalars['String']>;
  status?: Maybe<Status>;
  subscribeInfoAgreed?: Maybe<Scalars['Boolean']>;
  tosAgreed?: Maybe<Scalars['Boolean']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  username?: Maybe<Scalars['String']>;
  voucherCode?: Maybe<Scalars['String']>;
};

export type UserMinOrderByAggregateInput = {
  accessToken?: InputMaybe<SortOrder>;
  approvedAt?: InputMaybe<SortOrder>;
  archivedAt?: InputMaybe<SortOrder>;
  avatarUrl?: InputMaybe<SortOrder>;
  bankAccountHolderName?: InputMaybe<SortOrder>;
  bankAccountNumber?: InputMaybe<SortOrder>;
  bankBranchCode?: InputMaybe<SortOrder>;
  bankName?: InputMaybe<SortOrder>;
  bankSwiftCode?: InputMaybe<SortOrder>;
  bannerUrl?: InputMaybe<SortOrder>;
  companyAddressCity?: InputMaybe<SortOrder>;
  companyAddressCountry?: InputMaybe<SortOrder>;
  companyAddressLine1?: InputMaybe<SortOrder>;
  companyAddressLine2?: InputMaybe<SortOrder>;
  companyAddressPostcode?: InputMaybe<SortOrder>;
  companyAddressState?: InputMaybe<SortOrder>;
  companyName?: InputMaybe<SortOrder>;
  contactPersonEmail?: InputMaybe<SortOrder>;
  contactPersonMobileNumber?: InputMaybe<SortOrder>;
  contactPersonName?: InputMaybe<SortOrder>;
  countryCode?: InputMaybe<SortOrder>;
  createdAt?: InputMaybe<SortOrder>;
  currencyCode?: InputMaybe<SortOrder>;
  email?: InputMaybe<SortOrder>;
  fullName?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  isEmailVerified?: InputMaybe<SortOrder>;
  mobileCountryCode?: InputMaybe<SortOrder>;
  mobileNumber?: InputMaybe<SortOrder>;
  mongoUserId?: InputMaybe<SortOrder>;
  password?: InputMaybe<SortOrder>;
  preferredLanguage?: InputMaybe<SortOrder>;
  prestoUserId?: InputMaybe<SortOrder>;
  referralAgentId?: InputMaybe<SortOrder>;
  refreshToken?: InputMaybe<SortOrder>;
  role?: InputMaybe<SortOrder>;
  selfRegistered?: InputMaybe<SortOrder>;
  sendbirdAccessToken?: InputMaybe<SortOrder>;
  status?: InputMaybe<SortOrder>;
  subscribeInfoAgreed?: InputMaybe<SortOrder>;
  tosAgreed?: InputMaybe<SortOrder>;
  updatedAt?: InputMaybe<SortOrder>;
  username?: InputMaybe<SortOrder>;
  voucherCode?: InputMaybe<SortOrder>;
};

export type UserNftCollections = {
  __typename?: 'UserNftCollections';
  createdAt: Scalars['DateTime'];
  nftCollection: NftCollection;
  nftCollectionId: Scalars['String'];
  updatedAt: Scalars['DateTime'];
  user: User;
  userId: Scalars['String'];
};

export type UserNftCollectionsCreateManyNftCollectionInput = {
  createdAt?: InputMaybe<Scalars['DateTime']>;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
  userId: Scalars['String'];
};

export type UserNftCollectionsCreateManyNftCollectionInputEnvelope = {
  data: Array<UserNftCollectionsCreateManyNftCollectionInput>;
  skipDuplicates?: InputMaybe<Scalars['Boolean']>;
};

export type UserNftCollectionsCreateManyUserInput = {
  createdAt?: InputMaybe<Scalars['DateTime']>;
  nftCollectionId: Scalars['String'];
  updatedAt?: InputMaybe<Scalars['DateTime']>;
};

export type UserNftCollectionsCreateManyUserInputEnvelope = {
  data: Array<UserNftCollectionsCreateManyUserInput>;
  skipDuplicates?: InputMaybe<Scalars['Boolean']>;
};

export type UserNftCollectionsCreateNestedManyWithoutNftCollectionInput = {
  connect?: InputMaybe<Array<UserNftCollectionsWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<UserNftCollectionsCreateOrConnectWithoutNftCollectionInput>>;
  create?: InputMaybe<Array<UserNftCollectionsCreateWithoutNftCollectionInput>>;
  createMany?: InputMaybe<UserNftCollectionsCreateManyNftCollectionInputEnvelope>;
};

export type UserNftCollectionsCreateNestedManyWithoutUserInput = {
  connect?: InputMaybe<Array<UserNftCollectionsWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<UserNftCollectionsCreateOrConnectWithoutUserInput>>;
  create?: InputMaybe<Array<UserNftCollectionsCreateWithoutUserInput>>;
  createMany?: InputMaybe<UserNftCollectionsCreateManyUserInputEnvelope>;
};

export type UserNftCollectionsCreateOrConnectWithoutNftCollectionInput = {
  create: UserNftCollectionsCreateWithoutNftCollectionInput;
  where: UserNftCollectionsWhereUniqueInput;
};

export type UserNftCollectionsCreateOrConnectWithoutUserInput = {
  create: UserNftCollectionsCreateWithoutUserInput;
  where: UserNftCollectionsWhereUniqueInput;
};

export type UserNftCollectionsCreateWithoutNftCollectionInput = {
  createdAt?: InputMaybe<Scalars['DateTime']>;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
  user: UserCreateNestedOneWithoutNftCollectionsInput;
};

export type UserNftCollectionsCreateWithoutUserInput = {
  createdAt?: InputMaybe<Scalars['DateTime']>;
  nftCollection: NftCollectionCreateNestedOneWithoutCreatorsInput;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
};

export type UserNftCollectionsListRelationFilter = {
  every?: InputMaybe<UserNftCollectionsWhereInput>;
  none?: InputMaybe<UserNftCollectionsWhereInput>;
  some?: InputMaybe<UserNftCollectionsWhereInput>;
};

export type UserNftCollectionsOrderByRelationAggregateInput = {
  _count?: InputMaybe<SortOrder>;
};

export type UserNftCollectionsOrderByWithRelationInput = {
  createdAt?: InputMaybe<SortOrder>;
  nftCollection?: InputMaybe<NftCollectionOrderByWithRelationInput>;
  nftCollectionId?: InputMaybe<SortOrder>;
  updatedAt?: InputMaybe<SortOrder>;
  user?: InputMaybe<UserOrderByWithRelationInput>;
  userId?: InputMaybe<SortOrder>;
};

export enum UserNftCollectionsScalarFieldEnum {
  CreatedAt = 'createdAt',
  NftCollectionId = 'nftCollectionId',
  UpdatedAt = 'updatedAt',
  UserId = 'userId'
}

export type UserNftCollectionsScalarWhereInput = {
  AND?: InputMaybe<Array<UserNftCollectionsScalarWhereInput>>;
  NOT?: InputMaybe<Array<UserNftCollectionsScalarWhereInput>>;
  OR?: InputMaybe<Array<UserNftCollectionsScalarWhereInput>>;
  createdAt?: InputMaybe<DateTimeFilter>;
  nftCollectionId?: InputMaybe<StringFilter>;
  updatedAt?: InputMaybe<DateTimeFilter>;
  userId?: InputMaybe<StringFilter>;
};

export type UserNftCollectionsUpdateManyMutationInput = {
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
};

export type UserNftCollectionsUpdateManyWithWhereWithoutNftCollectionInput = {
  data: UserNftCollectionsUpdateManyMutationInput;
  where: UserNftCollectionsScalarWhereInput;
};

export type UserNftCollectionsUpdateManyWithWhereWithoutUserInput = {
  data: UserNftCollectionsUpdateManyMutationInput;
  where: UserNftCollectionsScalarWhereInput;
};

export type UserNftCollectionsUpdateManyWithoutNftCollectionInput = {
  connect?: InputMaybe<Array<UserNftCollectionsWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<UserNftCollectionsCreateOrConnectWithoutNftCollectionInput>>;
  create?: InputMaybe<Array<UserNftCollectionsCreateWithoutNftCollectionInput>>;
  createMany?: InputMaybe<UserNftCollectionsCreateManyNftCollectionInputEnvelope>;
  delete?: InputMaybe<Array<UserNftCollectionsWhereUniqueInput>>;
  deleteMany?: InputMaybe<Array<UserNftCollectionsScalarWhereInput>>;
  disconnect?: InputMaybe<Array<UserNftCollectionsWhereUniqueInput>>;
  set?: InputMaybe<Array<UserNftCollectionsWhereUniqueInput>>;
  update?: InputMaybe<Array<UserNftCollectionsUpdateWithWhereUniqueWithoutNftCollectionInput>>;
  updateMany?: InputMaybe<Array<UserNftCollectionsUpdateManyWithWhereWithoutNftCollectionInput>>;
  upsert?: InputMaybe<Array<UserNftCollectionsUpsertWithWhereUniqueWithoutNftCollectionInput>>;
};

export type UserNftCollectionsUpdateManyWithoutUserInput = {
  connect?: InputMaybe<Array<UserNftCollectionsWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<UserNftCollectionsCreateOrConnectWithoutUserInput>>;
  create?: InputMaybe<Array<UserNftCollectionsCreateWithoutUserInput>>;
  createMany?: InputMaybe<UserNftCollectionsCreateManyUserInputEnvelope>;
  delete?: InputMaybe<Array<UserNftCollectionsWhereUniqueInput>>;
  deleteMany?: InputMaybe<Array<UserNftCollectionsScalarWhereInput>>;
  disconnect?: InputMaybe<Array<UserNftCollectionsWhereUniqueInput>>;
  set?: InputMaybe<Array<UserNftCollectionsWhereUniqueInput>>;
  update?: InputMaybe<Array<UserNftCollectionsUpdateWithWhereUniqueWithoutUserInput>>;
  updateMany?: InputMaybe<Array<UserNftCollectionsUpdateManyWithWhereWithoutUserInput>>;
  upsert?: InputMaybe<Array<UserNftCollectionsUpsertWithWhereUniqueWithoutUserInput>>;
};

export type UserNftCollectionsUpdateWithWhereUniqueWithoutNftCollectionInput = {
  data: UserNftCollectionsUpdateWithoutNftCollectionInput;
  where: UserNftCollectionsWhereUniqueInput;
};

export type UserNftCollectionsUpdateWithWhereUniqueWithoutUserInput = {
  data: UserNftCollectionsUpdateWithoutUserInput;
  where: UserNftCollectionsWhereUniqueInput;
};

export type UserNftCollectionsUpdateWithoutNftCollectionInput = {
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  user?: InputMaybe<UserUpdateOneRequiredWithoutNftCollectionsInput>;
};

export type UserNftCollectionsUpdateWithoutUserInput = {
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  nftCollection?: InputMaybe<NftCollectionUpdateOneRequiredWithoutCreatorsInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
};

export type UserNftCollectionsUpsertWithWhereUniqueWithoutNftCollectionInput = {
  create: UserNftCollectionsCreateWithoutNftCollectionInput;
  update: UserNftCollectionsUpdateWithoutNftCollectionInput;
  where: UserNftCollectionsWhereUniqueInput;
};

export type UserNftCollectionsUpsertWithWhereUniqueWithoutUserInput = {
  create: UserNftCollectionsCreateWithoutUserInput;
  update: UserNftCollectionsUpdateWithoutUserInput;
  where: UserNftCollectionsWhereUniqueInput;
};

export type UserNftCollectionsUserIdNftCollectionIdCompoundUniqueInput = {
  nftCollectionId: Scalars['String'];
  userId: Scalars['String'];
};

export type UserNftCollectionsWhereInput = {
  AND?: InputMaybe<Array<UserNftCollectionsWhereInput>>;
  NOT?: InputMaybe<Array<UserNftCollectionsWhereInput>>;
  OR?: InputMaybe<Array<UserNftCollectionsWhereInput>>;
  createdAt?: InputMaybe<DateTimeFilter>;
  nftCollection?: InputMaybe<NftCollectionRelationFilter>;
  nftCollectionId?: InputMaybe<StringFilter>;
  updatedAt?: InputMaybe<DateTimeFilter>;
  user?: InputMaybe<UserRelationFilter>;
  userId?: InputMaybe<StringFilter>;
};

export type UserNftCollectionsWhereUniqueInput = {
  userId_nftCollectionId?: InputMaybe<UserNftCollectionsUserIdNftCollectionIdCompoundUniqueInput>;
};

export type UserOrderByWithAggregationInput = {
  _count?: InputMaybe<UserCountOrderByAggregateInput>;
  _max?: InputMaybe<UserMaxOrderByAggregateInput>;
  _min?: InputMaybe<UserMinOrderByAggregateInput>;
  accessToken?: InputMaybe<SortOrder>;
  approvedAt?: InputMaybe<SortOrder>;
  archivedAt?: InputMaybe<SortOrder>;
  avatarUrl?: InputMaybe<SortOrder>;
  bankAccountHolderName?: InputMaybe<SortOrder>;
  bankAccountNumber?: InputMaybe<SortOrder>;
  bankBranchCode?: InputMaybe<SortOrder>;
  bankName?: InputMaybe<SortOrder>;
  bankSwiftCode?: InputMaybe<SortOrder>;
  bannerUrl?: InputMaybe<SortOrder>;
  companyAddressCity?: InputMaybe<SortOrder>;
  companyAddressCountry?: InputMaybe<SortOrder>;
  companyAddressLine1?: InputMaybe<SortOrder>;
  companyAddressLine2?: InputMaybe<SortOrder>;
  companyAddressPostcode?: InputMaybe<SortOrder>;
  companyAddressState?: InputMaybe<SortOrder>;
  companyName?: InputMaybe<SortOrder>;
  contactPersonEmail?: InputMaybe<SortOrder>;
  contactPersonMobileNumber?: InputMaybe<SortOrder>;
  contactPersonName?: InputMaybe<SortOrder>;
  countryCode?: InputMaybe<SortOrder>;
  createdAt?: InputMaybe<SortOrder>;
  currencyCode?: InputMaybe<SortOrder>;
  email?: InputMaybe<SortOrder>;
  fullName?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  isEmailVerified?: InputMaybe<SortOrder>;
  mobileCountryCode?: InputMaybe<SortOrder>;
  mobileNumber?: InputMaybe<SortOrder>;
  mongoUserId?: InputMaybe<SortOrder>;
  password?: InputMaybe<SortOrder>;
  preferredLanguage?: InputMaybe<SortOrder>;
  prestoUserId?: InputMaybe<SortOrder>;
  referralAgentId?: InputMaybe<SortOrder>;
  refreshToken?: InputMaybe<SortOrder>;
  role?: InputMaybe<SortOrder>;
  selfRegistered?: InputMaybe<SortOrder>;
  sendbirdAccessToken?: InputMaybe<SortOrder>;
  status?: InputMaybe<SortOrder>;
  subscribeInfoAgreed?: InputMaybe<SortOrder>;
  tosAgreed?: InputMaybe<SortOrder>;
  updatedAt?: InputMaybe<SortOrder>;
  username?: InputMaybe<SortOrder>;
  voucherCode?: InputMaybe<SortOrder>;
};

export type UserOrderByWithRelationInput = {
  UserTips?: InputMaybe<TipsOrderByRelationAggregateInput>;
  accessToken?: InputMaybe<SortOrder>;
  approvedAt?: InputMaybe<SortOrder>;
  archivedAt?: InputMaybe<SortOrder>;
  avatarUrl?: InputMaybe<SortOrder>;
  bankAccountHolderName?: InputMaybe<SortOrder>;
  bankAccountNumber?: InputMaybe<SortOrder>;
  bankBranchCode?: InputMaybe<SortOrder>;
  bankName?: InputMaybe<SortOrder>;
  bankSwiftCode?: InputMaybe<SortOrder>;
  bannerUrl?: InputMaybe<SortOrder>;
  channel?: InputMaybe<ChannelOrderByWithRelationInput>;
  comments?: InputMaybe<CommentOrderByRelationAggregateInput>;
  companyAddressCity?: InputMaybe<SortOrder>;
  companyAddressCountry?: InputMaybe<SortOrder>;
  companyAddressLine1?: InputMaybe<SortOrder>;
  companyAddressLine2?: InputMaybe<SortOrder>;
  companyAddressPostcode?: InputMaybe<SortOrder>;
  companyAddressState?: InputMaybe<SortOrder>;
  companyName?: InputMaybe<SortOrder>;
  contactPersonEmail?: InputMaybe<SortOrder>;
  contactPersonMobileNumber?: InputMaybe<SortOrder>;
  contactPersonName?: InputMaybe<SortOrder>;
  countryCode?: InputMaybe<SortOrder>;
  createdAt?: InputMaybe<SortOrder>;
  createdNfts?: InputMaybe<MintedNftOrderByRelationAggregateInput>;
  currencyCode?: InputMaybe<SortOrder>;
  email?: InputMaybe<SortOrder>;
  followedBy?: InputMaybe<ChannelFollowerOrderByRelationAggregateInput>;
  followedChannels?: InputMaybe<ChannelFollowerOrderByRelationAggregateInput>;
  fullName?: InputMaybe<SortOrder>;
  giftsSent?: InputMaybe<GiftDistributionOrderByRelationAggregateInput>;
  holdNfts?: InputMaybe<MintedNftOrderByRelationAggregateInput>;
  id?: InputMaybe<SortOrder>;
  isEmailVerified?: InputMaybe<SortOrder>;
  likedVideos?: InputMaybe<LikedVideoOrderByRelationAggregateInput>;
  mobileCountryCode?: InputMaybe<SortOrder>;
  mobileNumber?: InputMaybe<SortOrder>;
  mongoUserId?: InputMaybe<SortOrder>;
  nftCollections?: InputMaybe<UserNftCollectionsOrderByRelationAggregateInput>;
  password?: InputMaybe<SortOrder>;
  preferredLanguage?: InputMaybe<SortOrder>;
  prestoUserId?: InputMaybe<SortOrder>;
  referralAgentId?: InputMaybe<SortOrder>;
  refreshToken?: InputMaybe<SortOrder>;
  role?: InputMaybe<SortOrder>;
  selfRegistered?: InputMaybe<SortOrder>;
  sendbirdAccessToken?: InputMaybe<SortOrder>;
  status?: InputMaybe<SortOrder>;
  subscribeInfoAgreed?: InputMaybe<SortOrder>;
  tosAgreed?: InputMaybe<SortOrder>;
  transactions?: InputMaybe<TransactionOrderByRelationAggregateInput>;
  updatedAt?: InputMaybe<SortOrder>;
  userPayPerViews?: InputMaybe<UserPayPerViewOrderByRelationAggregateInput>;
  userSubscriptions?: InputMaybe<UserSubscriptionOrderByRelationAggregateInput>;
  username?: InputMaybe<SortOrder>;
  voucherCode?: InputMaybe<SortOrder>;
  watchHistories?: InputMaybe<WatchHistoryOrderByRelationAggregateInput>;
};

export type UserPayPerView = {
  __typename?: 'UserPayPerView';
  archivedAt?: Maybe<Scalars['DateTime']>;
  createdAt: Scalars['DateTime'];
  id: Scalars['String'];
  transaction: Transaction;
  transactionId: Scalars['String'];
  updatedAt: Scalars['DateTime'];
  user: User;
  userId: Scalars['String'];
  video: Video;
  videoId: Scalars['String'];
};

export type UserPayPerViewCountAggregate = {
  __typename?: 'UserPayPerViewCountAggregate';
  _all: Scalars['Int'];
  archivedAt: Scalars['Int'];
  createdAt: Scalars['Int'];
  id: Scalars['Int'];
  transactionId: Scalars['Int'];
  updatedAt: Scalars['Int'];
  userId: Scalars['Int'];
  videoId: Scalars['Int'];
};

export type UserPayPerViewCountOrderByAggregateInput = {
  archivedAt?: InputMaybe<SortOrder>;
  createdAt?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  transactionId?: InputMaybe<SortOrder>;
  updatedAt?: InputMaybe<SortOrder>;
  userId?: InputMaybe<SortOrder>;
  videoId?: InputMaybe<SortOrder>;
};

export type UserPayPerViewCreateInput = {
  archivedAt?: InputMaybe<Scalars['DateTime']>;
  createdAt?: InputMaybe<Scalars['DateTime']>;
  id?: InputMaybe<Scalars['String']>;
  transaction: TransactionCreateNestedOneWithoutUserPayPerViewInput;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
  user: UserCreateNestedOneWithoutUserPayPerViewsInput;
  video: VideoCreateNestedOneWithoutUserPayPerViewInput;
};

export type UserPayPerViewCreateManyInput = {
  archivedAt?: InputMaybe<Scalars['DateTime']>;
  createdAt?: InputMaybe<Scalars['DateTime']>;
  id?: InputMaybe<Scalars['String']>;
  transactionId: Scalars['String'];
  updatedAt?: InputMaybe<Scalars['DateTime']>;
  userId: Scalars['String'];
  videoId: Scalars['String'];
};

export type UserPayPerViewCreateManyUserInput = {
  archivedAt?: InputMaybe<Scalars['DateTime']>;
  createdAt?: InputMaybe<Scalars['DateTime']>;
  id?: InputMaybe<Scalars['String']>;
  transactionId: Scalars['String'];
  updatedAt?: InputMaybe<Scalars['DateTime']>;
  videoId: Scalars['String'];
};

export type UserPayPerViewCreateManyUserInputEnvelope = {
  data: Array<UserPayPerViewCreateManyUserInput>;
  skipDuplicates?: InputMaybe<Scalars['Boolean']>;
};

export type UserPayPerViewCreateManyVideoInput = {
  archivedAt?: InputMaybe<Scalars['DateTime']>;
  createdAt?: InputMaybe<Scalars['DateTime']>;
  id?: InputMaybe<Scalars['String']>;
  transactionId: Scalars['String'];
  updatedAt?: InputMaybe<Scalars['DateTime']>;
  userId: Scalars['String'];
};

export type UserPayPerViewCreateManyVideoInputEnvelope = {
  data: Array<UserPayPerViewCreateManyVideoInput>;
  skipDuplicates?: InputMaybe<Scalars['Boolean']>;
};

export type UserPayPerViewCreateNestedManyWithoutUserInput = {
  connect?: InputMaybe<Array<UserPayPerViewWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<UserPayPerViewCreateOrConnectWithoutUserInput>>;
  create?: InputMaybe<Array<UserPayPerViewCreateWithoutUserInput>>;
  createMany?: InputMaybe<UserPayPerViewCreateManyUserInputEnvelope>;
};

export type UserPayPerViewCreateNestedManyWithoutVideoInput = {
  connect?: InputMaybe<Array<UserPayPerViewWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<UserPayPerViewCreateOrConnectWithoutVideoInput>>;
  create?: InputMaybe<Array<UserPayPerViewCreateWithoutVideoInput>>;
  createMany?: InputMaybe<UserPayPerViewCreateManyVideoInputEnvelope>;
};

export type UserPayPerViewCreateNestedOneWithoutTransactionInput = {
  connect?: InputMaybe<UserPayPerViewWhereUniqueInput>;
  connectOrCreate?: InputMaybe<UserPayPerViewCreateOrConnectWithoutTransactionInput>;
  create?: InputMaybe<UserPayPerViewCreateWithoutTransactionInput>;
};

export type UserPayPerViewCreateOrConnectWithoutTransactionInput = {
  create: UserPayPerViewCreateWithoutTransactionInput;
  where: UserPayPerViewWhereUniqueInput;
};

export type UserPayPerViewCreateOrConnectWithoutUserInput = {
  create: UserPayPerViewCreateWithoutUserInput;
  where: UserPayPerViewWhereUniqueInput;
};

export type UserPayPerViewCreateOrConnectWithoutVideoInput = {
  create: UserPayPerViewCreateWithoutVideoInput;
  where: UserPayPerViewWhereUniqueInput;
};

export type UserPayPerViewCreateWithoutTransactionInput = {
  archivedAt?: InputMaybe<Scalars['DateTime']>;
  createdAt?: InputMaybe<Scalars['DateTime']>;
  id?: InputMaybe<Scalars['String']>;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
  user: UserCreateNestedOneWithoutUserPayPerViewsInput;
  video: VideoCreateNestedOneWithoutUserPayPerViewInput;
};

export type UserPayPerViewCreateWithoutUserInput = {
  archivedAt?: InputMaybe<Scalars['DateTime']>;
  createdAt?: InputMaybe<Scalars['DateTime']>;
  id?: InputMaybe<Scalars['String']>;
  transaction: TransactionCreateNestedOneWithoutUserPayPerViewInput;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
  video: VideoCreateNestedOneWithoutUserPayPerViewInput;
};

export type UserPayPerViewCreateWithoutVideoInput = {
  archivedAt?: InputMaybe<Scalars['DateTime']>;
  createdAt?: InputMaybe<Scalars['DateTime']>;
  id?: InputMaybe<Scalars['String']>;
  transaction: TransactionCreateNestedOneWithoutUserPayPerViewInput;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
  user: UserCreateNestedOneWithoutUserPayPerViewsInput;
};

export type UserPayPerViewGroupBy = {
  __typename?: 'UserPayPerViewGroupBy';
  _count?: Maybe<UserPayPerViewCountAggregate>;
  _max?: Maybe<UserPayPerViewMaxAggregate>;
  _min?: Maybe<UserPayPerViewMinAggregate>;
  archivedAt?: Maybe<Scalars['DateTime']>;
  createdAt: Scalars['DateTime'];
  id: Scalars['String'];
  transactionId: Scalars['String'];
  updatedAt: Scalars['DateTime'];
  userId: Scalars['String'];
  videoId: Scalars['String'];
};

export type UserPayPerViewListRelationFilter = {
  every?: InputMaybe<UserPayPerViewWhereInput>;
  none?: InputMaybe<UserPayPerViewWhereInput>;
  some?: InputMaybe<UserPayPerViewWhereInput>;
};

export type UserPayPerViewMaxAggregate = {
  __typename?: 'UserPayPerViewMaxAggregate';
  archivedAt?: Maybe<Scalars['DateTime']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  transactionId?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  userId?: Maybe<Scalars['String']>;
  videoId?: Maybe<Scalars['String']>;
};

export type UserPayPerViewMaxOrderByAggregateInput = {
  archivedAt?: InputMaybe<SortOrder>;
  createdAt?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  transactionId?: InputMaybe<SortOrder>;
  updatedAt?: InputMaybe<SortOrder>;
  userId?: InputMaybe<SortOrder>;
  videoId?: InputMaybe<SortOrder>;
};

export type UserPayPerViewMinAggregate = {
  __typename?: 'UserPayPerViewMinAggregate';
  archivedAt?: Maybe<Scalars['DateTime']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  transactionId?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  userId?: Maybe<Scalars['String']>;
  videoId?: Maybe<Scalars['String']>;
};

export type UserPayPerViewMinOrderByAggregateInput = {
  archivedAt?: InputMaybe<SortOrder>;
  createdAt?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  transactionId?: InputMaybe<SortOrder>;
  updatedAt?: InputMaybe<SortOrder>;
  userId?: InputMaybe<SortOrder>;
  videoId?: InputMaybe<SortOrder>;
};

export type UserPayPerViewOrderByRelationAggregateInput = {
  _count?: InputMaybe<SortOrder>;
};

export type UserPayPerViewOrderByWithAggregationInput = {
  _count?: InputMaybe<UserPayPerViewCountOrderByAggregateInput>;
  _max?: InputMaybe<UserPayPerViewMaxOrderByAggregateInput>;
  _min?: InputMaybe<UserPayPerViewMinOrderByAggregateInput>;
  archivedAt?: InputMaybe<SortOrder>;
  createdAt?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  transactionId?: InputMaybe<SortOrder>;
  updatedAt?: InputMaybe<SortOrder>;
  userId?: InputMaybe<SortOrder>;
  videoId?: InputMaybe<SortOrder>;
};

export type UserPayPerViewOrderByWithRelationInput = {
  archivedAt?: InputMaybe<SortOrder>;
  createdAt?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  transaction?: InputMaybe<TransactionOrderByWithRelationInput>;
  transactionId?: InputMaybe<SortOrder>;
  updatedAt?: InputMaybe<SortOrder>;
  user?: InputMaybe<UserOrderByWithRelationInput>;
  userId?: InputMaybe<SortOrder>;
  video?: InputMaybe<VideoOrderByWithRelationInput>;
  videoId?: InputMaybe<SortOrder>;
};

export type UserPayPerViewRelationFilter = {
  is?: InputMaybe<UserPayPerViewWhereInput>;
  isNot?: InputMaybe<UserPayPerViewWhereInput>;
};

export enum UserPayPerViewScalarFieldEnum {
  ArchivedAt = 'archivedAt',
  CreatedAt = 'createdAt',
  Id = 'id',
  TransactionId = 'transactionId',
  UpdatedAt = 'updatedAt',
  UserId = 'userId',
  VideoId = 'videoId'
}

export type UserPayPerViewScalarWhereInput = {
  AND?: InputMaybe<Array<UserPayPerViewScalarWhereInput>>;
  NOT?: InputMaybe<Array<UserPayPerViewScalarWhereInput>>;
  OR?: InputMaybe<Array<UserPayPerViewScalarWhereInput>>;
  archivedAt?: InputMaybe<DateTimeNullableFilter>;
  createdAt?: InputMaybe<DateTimeFilter>;
  id?: InputMaybe<StringFilter>;
  transactionId?: InputMaybe<StringFilter>;
  updatedAt?: InputMaybe<DateTimeFilter>;
  userId?: InputMaybe<StringFilter>;
  videoId?: InputMaybe<StringFilter>;
};

export type UserPayPerViewScalarWhereWithAggregatesInput = {
  AND?: InputMaybe<Array<UserPayPerViewScalarWhereWithAggregatesInput>>;
  NOT?: InputMaybe<Array<UserPayPerViewScalarWhereWithAggregatesInput>>;
  OR?: InputMaybe<Array<UserPayPerViewScalarWhereWithAggregatesInput>>;
  archivedAt?: InputMaybe<DateTimeNullableWithAggregatesFilter>;
  createdAt?: InputMaybe<DateTimeWithAggregatesFilter>;
  id?: InputMaybe<StringWithAggregatesFilter>;
  transactionId?: InputMaybe<StringWithAggregatesFilter>;
  updatedAt?: InputMaybe<DateTimeWithAggregatesFilter>;
  userId?: InputMaybe<StringWithAggregatesFilter>;
  videoId?: InputMaybe<StringWithAggregatesFilter>;
};

export type UserPayPerViewUpdateInput = {
  archivedAt?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  transaction?: InputMaybe<TransactionUpdateOneRequiredWithoutUserPayPerViewInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  user?: InputMaybe<UserUpdateOneRequiredWithoutUserPayPerViewsInput>;
  video?: InputMaybe<VideoUpdateOneRequiredWithoutUserPayPerViewInput>;
};

export type UserPayPerViewUpdateManyMutationInput = {
  archivedAt?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
};

export type UserPayPerViewUpdateManyWithWhereWithoutUserInput = {
  data: UserPayPerViewUpdateManyMutationInput;
  where: UserPayPerViewScalarWhereInput;
};

export type UserPayPerViewUpdateManyWithWhereWithoutVideoInput = {
  data: UserPayPerViewUpdateManyMutationInput;
  where: UserPayPerViewScalarWhereInput;
};

export type UserPayPerViewUpdateManyWithoutUserInput = {
  connect?: InputMaybe<Array<UserPayPerViewWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<UserPayPerViewCreateOrConnectWithoutUserInput>>;
  create?: InputMaybe<Array<UserPayPerViewCreateWithoutUserInput>>;
  createMany?: InputMaybe<UserPayPerViewCreateManyUserInputEnvelope>;
  delete?: InputMaybe<Array<UserPayPerViewWhereUniqueInput>>;
  deleteMany?: InputMaybe<Array<UserPayPerViewScalarWhereInput>>;
  disconnect?: InputMaybe<Array<UserPayPerViewWhereUniqueInput>>;
  set?: InputMaybe<Array<UserPayPerViewWhereUniqueInput>>;
  update?: InputMaybe<Array<UserPayPerViewUpdateWithWhereUniqueWithoutUserInput>>;
  updateMany?: InputMaybe<Array<UserPayPerViewUpdateManyWithWhereWithoutUserInput>>;
  upsert?: InputMaybe<Array<UserPayPerViewUpsertWithWhereUniqueWithoutUserInput>>;
};

export type UserPayPerViewUpdateManyWithoutVideoInput = {
  connect?: InputMaybe<Array<UserPayPerViewWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<UserPayPerViewCreateOrConnectWithoutVideoInput>>;
  create?: InputMaybe<Array<UserPayPerViewCreateWithoutVideoInput>>;
  createMany?: InputMaybe<UserPayPerViewCreateManyVideoInputEnvelope>;
  delete?: InputMaybe<Array<UserPayPerViewWhereUniqueInput>>;
  deleteMany?: InputMaybe<Array<UserPayPerViewScalarWhereInput>>;
  disconnect?: InputMaybe<Array<UserPayPerViewWhereUniqueInput>>;
  set?: InputMaybe<Array<UserPayPerViewWhereUniqueInput>>;
  update?: InputMaybe<Array<UserPayPerViewUpdateWithWhereUniqueWithoutVideoInput>>;
  updateMany?: InputMaybe<Array<UserPayPerViewUpdateManyWithWhereWithoutVideoInput>>;
  upsert?: InputMaybe<Array<UserPayPerViewUpsertWithWhereUniqueWithoutVideoInput>>;
};

export type UserPayPerViewUpdateOneWithoutTransactionInput = {
  connect?: InputMaybe<UserPayPerViewWhereUniqueInput>;
  connectOrCreate?: InputMaybe<UserPayPerViewCreateOrConnectWithoutTransactionInput>;
  create?: InputMaybe<UserPayPerViewCreateWithoutTransactionInput>;
  delete?: InputMaybe<Scalars['Boolean']>;
  disconnect?: InputMaybe<Scalars['Boolean']>;
  update?: InputMaybe<UserPayPerViewUpdateWithoutTransactionInput>;
  upsert?: InputMaybe<UserPayPerViewUpsertWithoutTransactionInput>;
};

export type UserPayPerViewUpdateWithWhereUniqueWithoutUserInput = {
  data: UserPayPerViewUpdateWithoutUserInput;
  where: UserPayPerViewWhereUniqueInput;
};

export type UserPayPerViewUpdateWithWhereUniqueWithoutVideoInput = {
  data: UserPayPerViewUpdateWithoutVideoInput;
  where: UserPayPerViewWhereUniqueInput;
};

export type UserPayPerViewUpdateWithoutTransactionInput = {
  archivedAt?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  user?: InputMaybe<UserUpdateOneRequiredWithoutUserPayPerViewsInput>;
  video?: InputMaybe<VideoUpdateOneRequiredWithoutUserPayPerViewInput>;
};

export type UserPayPerViewUpdateWithoutUserInput = {
  archivedAt?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  transaction?: InputMaybe<TransactionUpdateOneRequiredWithoutUserPayPerViewInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  video?: InputMaybe<VideoUpdateOneRequiredWithoutUserPayPerViewInput>;
};

export type UserPayPerViewUpdateWithoutVideoInput = {
  archivedAt?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  transaction?: InputMaybe<TransactionUpdateOneRequiredWithoutUserPayPerViewInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  user?: InputMaybe<UserUpdateOneRequiredWithoutUserPayPerViewsInput>;
};

export type UserPayPerViewUpsertWithWhereUniqueWithoutUserInput = {
  create: UserPayPerViewCreateWithoutUserInput;
  update: UserPayPerViewUpdateWithoutUserInput;
  where: UserPayPerViewWhereUniqueInput;
};

export type UserPayPerViewUpsertWithWhereUniqueWithoutVideoInput = {
  create: UserPayPerViewCreateWithoutVideoInput;
  update: UserPayPerViewUpdateWithoutVideoInput;
  where: UserPayPerViewWhereUniqueInput;
};

export type UserPayPerViewUpsertWithoutTransactionInput = {
  create: UserPayPerViewCreateWithoutTransactionInput;
  update: UserPayPerViewUpdateWithoutTransactionInput;
};

export type UserPayPerViewWhereInput = {
  AND?: InputMaybe<Array<UserPayPerViewWhereInput>>;
  NOT?: InputMaybe<Array<UserPayPerViewWhereInput>>;
  OR?: InputMaybe<Array<UserPayPerViewWhereInput>>;
  archivedAt?: InputMaybe<DateTimeNullableFilter>;
  createdAt?: InputMaybe<DateTimeFilter>;
  id?: InputMaybe<StringFilter>;
  transaction?: InputMaybe<TransactionRelationFilter>;
  transactionId?: InputMaybe<StringFilter>;
  updatedAt?: InputMaybe<DateTimeFilter>;
  user?: InputMaybe<UserRelationFilter>;
  userId?: InputMaybe<StringFilter>;
  video?: InputMaybe<VideoRelationFilter>;
  videoId?: InputMaybe<StringFilter>;
};

export type UserPayPerViewWhereUniqueInput = {
  id?: InputMaybe<Scalars['String']>;
  transactionId?: InputMaybe<Scalars['String']>;
};

export type UserRelationFilter = {
  is?: InputMaybe<UserWhereInput>;
  isNot?: InputMaybe<UserWhereInput>;
};

export enum UserScalarFieldEnum {
  AccessToken = 'accessToken',
  ApprovedAt = 'approvedAt',
  ArchivedAt = 'archivedAt',
  AvatarUrl = 'avatarUrl',
  BankAccountHolderName = 'bankAccountHolderName',
  BankAccountNumber = 'bankAccountNumber',
  BankBranchCode = 'bankBranchCode',
  BankName = 'bankName',
  BankSwiftCode = 'bankSwiftCode',
  BannerUrl = 'bannerUrl',
  CompanyAddressCity = 'companyAddressCity',
  CompanyAddressCountry = 'companyAddressCountry',
  CompanyAddressLine1 = 'companyAddressLine1',
  CompanyAddressLine2 = 'companyAddressLine2',
  CompanyAddressPostcode = 'companyAddressPostcode',
  CompanyAddressState = 'companyAddressState',
  CompanyName = 'companyName',
  ContactPersonEmail = 'contactPersonEmail',
  ContactPersonMobileNumber = 'contactPersonMobileNumber',
  ContactPersonName = 'contactPersonName',
  CountryCode = 'countryCode',
  CreatedAt = 'createdAt',
  CurrencyCode = 'currencyCode',
  Email = 'email',
  FullName = 'fullName',
  Id = 'id',
  IsEmailVerified = 'isEmailVerified',
  MobileCountryCode = 'mobileCountryCode',
  MobileNumber = 'mobileNumber',
  MongoUserId = 'mongoUserId',
  Password = 'password',
  PreferredLanguage = 'preferredLanguage',
  PrestoUserId = 'prestoUserId',
  ReferralAgentId = 'referralAgentId',
  RefreshToken = 'refreshToken',
  Role = 'role',
  SelfRegistered = 'selfRegistered',
  SendbirdAccessToken = 'sendbirdAccessToken',
  Status = 'status',
  SubscribeInfoAgreed = 'subscribeInfoAgreed',
  TosAgreed = 'tosAgreed',
  UpdatedAt = 'updatedAt',
  Username = 'username',
  VoucherCode = 'voucherCode'
}

export type UserScalarWhereWithAggregatesInput = {
  AND?: InputMaybe<Array<UserScalarWhereWithAggregatesInput>>;
  NOT?: InputMaybe<Array<UserScalarWhereWithAggregatesInput>>;
  OR?: InputMaybe<Array<UserScalarWhereWithAggregatesInput>>;
  accessToken?: InputMaybe<StringNullableWithAggregatesFilter>;
  approvedAt?: InputMaybe<DateTimeNullableWithAggregatesFilter>;
  archivedAt?: InputMaybe<DateTimeNullableWithAggregatesFilter>;
  avatarUrl?: InputMaybe<StringNullableWithAggregatesFilter>;
  bankAccountHolderName?: InputMaybe<StringNullableWithAggregatesFilter>;
  bankAccountNumber?: InputMaybe<StringNullableWithAggregatesFilter>;
  bankBranchCode?: InputMaybe<StringNullableWithAggregatesFilter>;
  bankName?: InputMaybe<StringNullableWithAggregatesFilter>;
  bankSwiftCode?: InputMaybe<StringNullableWithAggregatesFilter>;
  bannerUrl?: InputMaybe<StringNullableWithAggregatesFilter>;
  companyAddressCity?: InputMaybe<StringNullableWithAggregatesFilter>;
  companyAddressCountry?: InputMaybe<StringNullableWithAggregatesFilter>;
  companyAddressLine1?: InputMaybe<StringNullableWithAggregatesFilter>;
  companyAddressLine2?: InputMaybe<StringNullableWithAggregatesFilter>;
  companyAddressPostcode?: InputMaybe<StringNullableWithAggregatesFilter>;
  companyAddressState?: InputMaybe<StringNullableWithAggregatesFilter>;
  companyName?: InputMaybe<StringNullableWithAggregatesFilter>;
  contactPersonEmail?: InputMaybe<StringNullableWithAggregatesFilter>;
  contactPersonMobileNumber?: InputMaybe<StringNullableWithAggregatesFilter>;
  contactPersonName?: InputMaybe<StringNullableWithAggregatesFilter>;
  countryCode?: InputMaybe<StringNullableWithAggregatesFilter>;
  createdAt?: InputMaybe<DateTimeWithAggregatesFilter>;
  currencyCode?: InputMaybe<EnumCurrencyCodeWithAggregatesFilter>;
  email?: InputMaybe<StringWithAggregatesFilter>;
  fullName?: InputMaybe<StringWithAggregatesFilter>;
  id?: InputMaybe<StringWithAggregatesFilter>;
  isEmailVerified?: InputMaybe<BoolWithAggregatesFilter>;
  mobileCountryCode?: InputMaybe<StringNullableWithAggregatesFilter>;
  mobileNumber?: InputMaybe<StringNullableWithAggregatesFilter>;
  mongoUserId?: InputMaybe<StringNullableWithAggregatesFilter>;
  password?: InputMaybe<StringNullableWithAggregatesFilter>;
  preferredLanguage?: InputMaybe<EnumCommunicationLanguageNullableWithAggregatesFilter>;
  prestoUserId?: InputMaybe<StringNullableWithAggregatesFilter>;
  referralAgentId?: InputMaybe<StringNullableWithAggregatesFilter>;
  refreshToken?: InputMaybe<StringNullableWithAggregatesFilter>;
  role?: InputMaybe<EnumUserTypeNullableWithAggregatesFilter>;
  selfRegistered?: InputMaybe<BoolWithAggregatesFilter>;
  sendbirdAccessToken?: InputMaybe<StringNullableWithAggregatesFilter>;
  status?: InputMaybe<EnumStatusWithAggregatesFilter>;
  subscribeInfoAgreed?: InputMaybe<BoolWithAggregatesFilter>;
  tosAgreed?: InputMaybe<BoolWithAggregatesFilter>;
  updatedAt?: InputMaybe<DateTimeWithAggregatesFilter>;
  username?: InputMaybe<StringNullableWithAggregatesFilter>;
  voucherCode?: InputMaybe<StringNullableWithAggregatesFilter>;
};

export type UserSubscription = {
  __typename?: 'UserSubscription';
  archivedAt?: Maybe<Scalars['DateTime']>;
  channelId: Scalars['String'];
  createdAt: Scalars['DateTime'];
  endDate: Scalars['DateTime'];
  id: Scalars['String'];
  startDate: Scalars['DateTime'];
  subscribedChannel: Channel;
  subscriber: User;
  subscriptionPlan: SubscriptionPlan;
  subscriptionPlanId: Scalars['String'];
  subscriptionPlanSnapshot: Scalars['JSON'];
  transaction: Transaction;
  transactionId: Scalars['String'];
  updatedAt: Scalars['DateTime'];
  userId: Scalars['String'];
};

export type UserSubscriptionCountAggregate = {
  __typename?: 'UserSubscriptionCountAggregate';
  _all: Scalars['Int'];
  archivedAt: Scalars['Int'];
  channelId: Scalars['Int'];
  createdAt: Scalars['Int'];
  endDate: Scalars['Int'];
  id: Scalars['Int'];
  startDate: Scalars['Int'];
  subscriptionPlanId: Scalars['Int'];
  subscriptionPlanSnapshot: Scalars['Int'];
  transactionId: Scalars['Int'];
  updatedAt: Scalars['Int'];
  userId: Scalars['Int'];
};

export type UserSubscriptionCountOrderByAggregateInput = {
  archivedAt?: InputMaybe<SortOrder>;
  channelId?: InputMaybe<SortOrder>;
  createdAt?: InputMaybe<SortOrder>;
  endDate?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  startDate?: InputMaybe<SortOrder>;
  subscriptionPlanId?: InputMaybe<SortOrder>;
  subscriptionPlanSnapshot?: InputMaybe<SortOrder>;
  transactionId?: InputMaybe<SortOrder>;
  updatedAt?: InputMaybe<SortOrder>;
  userId?: InputMaybe<SortOrder>;
};

export type UserSubscriptionCreateInput = {
  archivedAt?: InputMaybe<Scalars['DateTime']>;
  createdAt?: InputMaybe<Scalars['DateTime']>;
  endDate: Scalars['DateTime'];
  id?: InputMaybe<Scalars['String']>;
  startDate?: InputMaybe<Scalars['DateTime']>;
  subscribedChannel: ChannelCreateNestedOneWithoutSubscribedUsersInput;
  subscriber: UserCreateNestedOneWithoutUserSubscriptionsInput;
  subscriptionPlan: SubscriptionPlanCreateNestedOneWithoutUserSubscriptionsInput;
  subscriptionPlanSnapshot: Scalars['JSON'];
  transaction: TransactionCreateNestedOneWithoutUserSubscriptionInput;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
};

export type UserSubscriptionCreateManyInput = {
  archivedAt?: InputMaybe<Scalars['DateTime']>;
  channelId: Scalars['String'];
  createdAt?: InputMaybe<Scalars['DateTime']>;
  endDate: Scalars['DateTime'];
  id?: InputMaybe<Scalars['String']>;
  startDate?: InputMaybe<Scalars['DateTime']>;
  subscriptionPlanId: Scalars['String'];
  subscriptionPlanSnapshot: Scalars['JSON'];
  transactionId: Scalars['String'];
  updatedAt?: InputMaybe<Scalars['DateTime']>;
  userId: Scalars['String'];
};

export type UserSubscriptionCreateManySubscribedChannelInput = {
  archivedAt?: InputMaybe<Scalars['DateTime']>;
  createdAt?: InputMaybe<Scalars['DateTime']>;
  endDate: Scalars['DateTime'];
  id?: InputMaybe<Scalars['String']>;
  startDate?: InputMaybe<Scalars['DateTime']>;
  subscriptionPlanId: Scalars['String'];
  subscriptionPlanSnapshot: Scalars['JSON'];
  transactionId: Scalars['String'];
  updatedAt?: InputMaybe<Scalars['DateTime']>;
  userId: Scalars['String'];
};

export type UserSubscriptionCreateManySubscribedChannelInputEnvelope = {
  data: Array<UserSubscriptionCreateManySubscribedChannelInput>;
  skipDuplicates?: InputMaybe<Scalars['Boolean']>;
};

export type UserSubscriptionCreateManySubscriberInput = {
  archivedAt?: InputMaybe<Scalars['DateTime']>;
  channelId: Scalars['String'];
  createdAt?: InputMaybe<Scalars['DateTime']>;
  endDate: Scalars['DateTime'];
  id?: InputMaybe<Scalars['String']>;
  startDate?: InputMaybe<Scalars['DateTime']>;
  subscriptionPlanId: Scalars['String'];
  subscriptionPlanSnapshot: Scalars['JSON'];
  transactionId: Scalars['String'];
  updatedAt?: InputMaybe<Scalars['DateTime']>;
};

export type UserSubscriptionCreateManySubscriberInputEnvelope = {
  data: Array<UserSubscriptionCreateManySubscriberInput>;
  skipDuplicates?: InputMaybe<Scalars['Boolean']>;
};

export type UserSubscriptionCreateManySubscriptionPlanInput = {
  archivedAt?: InputMaybe<Scalars['DateTime']>;
  channelId: Scalars['String'];
  createdAt?: InputMaybe<Scalars['DateTime']>;
  endDate: Scalars['DateTime'];
  id?: InputMaybe<Scalars['String']>;
  startDate?: InputMaybe<Scalars['DateTime']>;
  subscriptionPlanSnapshot: Scalars['JSON'];
  transactionId: Scalars['String'];
  updatedAt?: InputMaybe<Scalars['DateTime']>;
  userId: Scalars['String'];
};

export type UserSubscriptionCreateManySubscriptionPlanInputEnvelope = {
  data: Array<UserSubscriptionCreateManySubscriptionPlanInput>;
  skipDuplicates?: InputMaybe<Scalars['Boolean']>;
};

export type UserSubscriptionCreateNestedManyWithoutSubscribedChannelInput = {
  connect?: InputMaybe<Array<UserSubscriptionWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<UserSubscriptionCreateOrConnectWithoutSubscribedChannelInput>>;
  create?: InputMaybe<Array<UserSubscriptionCreateWithoutSubscribedChannelInput>>;
  createMany?: InputMaybe<UserSubscriptionCreateManySubscribedChannelInputEnvelope>;
};

export type UserSubscriptionCreateNestedManyWithoutSubscriberInput = {
  connect?: InputMaybe<Array<UserSubscriptionWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<UserSubscriptionCreateOrConnectWithoutSubscriberInput>>;
  create?: InputMaybe<Array<UserSubscriptionCreateWithoutSubscriberInput>>;
  createMany?: InputMaybe<UserSubscriptionCreateManySubscriberInputEnvelope>;
};

export type UserSubscriptionCreateNestedManyWithoutSubscriptionPlanInput = {
  connect?: InputMaybe<Array<UserSubscriptionWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<UserSubscriptionCreateOrConnectWithoutSubscriptionPlanInput>>;
  create?: InputMaybe<Array<UserSubscriptionCreateWithoutSubscriptionPlanInput>>;
  createMany?: InputMaybe<UserSubscriptionCreateManySubscriptionPlanInputEnvelope>;
};

export type UserSubscriptionCreateNestedOneWithoutTransactionInput = {
  connect?: InputMaybe<UserSubscriptionWhereUniqueInput>;
  connectOrCreate?: InputMaybe<UserSubscriptionCreateOrConnectWithoutTransactionInput>;
  create?: InputMaybe<UserSubscriptionCreateWithoutTransactionInput>;
};

export type UserSubscriptionCreateOrConnectWithoutSubscribedChannelInput = {
  create: UserSubscriptionCreateWithoutSubscribedChannelInput;
  where: UserSubscriptionWhereUniqueInput;
};

export type UserSubscriptionCreateOrConnectWithoutSubscriberInput = {
  create: UserSubscriptionCreateWithoutSubscriberInput;
  where: UserSubscriptionWhereUniqueInput;
};

export type UserSubscriptionCreateOrConnectWithoutSubscriptionPlanInput = {
  create: UserSubscriptionCreateWithoutSubscriptionPlanInput;
  where: UserSubscriptionWhereUniqueInput;
};

export type UserSubscriptionCreateOrConnectWithoutTransactionInput = {
  create: UserSubscriptionCreateWithoutTransactionInput;
  where: UserSubscriptionWhereUniqueInput;
};

export type UserSubscriptionCreateWithoutSubscribedChannelInput = {
  archivedAt?: InputMaybe<Scalars['DateTime']>;
  createdAt?: InputMaybe<Scalars['DateTime']>;
  endDate: Scalars['DateTime'];
  id?: InputMaybe<Scalars['String']>;
  startDate?: InputMaybe<Scalars['DateTime']>;
  subscriber: UserCreateNestedOneWithoutUserSubscriptionsInput;
  subscriptionPlan: SubscriptionPlanCreateNestedOneWithoutUserSubscriptionsInput;
  subscriptionPlanSnapshot: Scalars['JSON'];
  transaction: TransactionCreateNestedOneWithoutUserSubscriptionInput;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
};

export type UserSubscriptionCreateWithoutSubscriberInput = {
  archivedAt?: InputMaybe<Scalars['DateTime']>;
  createdAt?: InputMaybe<Scalars['DateTime']>;
  endDate: Scalars['DateTime'];
  id?: InputMaybe<Scalars['String']>;
  startDate?: InputMaybe<Scalars['DateTime']>;
  subscribedChannel: ChannelCreateNestedOneWithoutSubscribedUsersInput;
  subscriptionPlan: SubscriptionPlanCreateNestedOneWithoutUserSubscriptionsInput;
  subscriptionPlanSnapshot: Scalars['JSON'];
  transaction: TransactionCreateNestedOneWithoutUserSubscriptionInput;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
};

export type UserSubscriptionCreateWithoutSubscriptionPlanInput = {
  archivedAt?: InputMaybe<Scalars['DateTime']>;
  createdAt?: InputMaybe<Scalars['DateTime']>;
  endDate: Scalars['DateTime'];
  id?: InputMaybe<Scalars['String']>;
  startDate?: InputMaybe<Scalars['DateTime']>;
  subscribedChannel: ChannelCreateNestedOneWithoutSubscribedUsersInput;
  subscriber: UserCreateNestedOneWithoutUserSubscriptionsInput;
  subscriptionPlanSnapshot: Scalars['JSON'];
  transaction: TransactionCreateNestedOneWithoutUserSubscriptionInput;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
};

export type UserSubscriptionCreateWithoutTransactionInput = {
  archivedAt?: InputMaybe<Scalars['DateTime']>;
  createdAt?: InputMaybe<Scalars['DateTime']>;
  endDate: Scalars['DateTime'];
  id?: InputMaybe<Scalars['String']>;
  startDate?: InputMaybe<Scalars['DateTime']>;
  subscribedChannel: ChannelCreateNestedOneWithoutSubscribedUsersInput;
  subscriber: UserCreateNestedOneWithoutUserSubscriptionsInput;
  subscriptionPlan: SubscriptionPlanCreateNestedOneWithoutUserSubscriptionsInput;
  subscriptionPlanSnapshot: Scalars['JSON'];
  updatedAt?: InputMaybe<Scalars['DateTime']>;
};

export type UserSubscriptionGroupBy = {
  __typename?: 'UserSubscriptionGroupBy';
  _count?: Maybe<UserSubscriptionCountAggregate>;
  _max?: Maybe<UserSubscriptionMaxAggregate>;
  _min?: Maybe<UserSubscriptionMinAggregate>;
  archivedAt?: Maybe<Scalars['DateTime']>;
  channelId: Scalars['String'];
  createdAt: Scalars['DateTime'];
  endDate: Scalars['DateTime'];
  id: Scalars['String'];
  startDate: Scalars['DateTime'];
  subscriptionPlanId: Scalars['String'];
  subscriptionPlanSnapshot: Scalars['JSON'];
  transactionId: Scalars['String'];
  updatedAt: Scalars['DateTime'];
  userId: Scalars['String'];
};

export type UserSubscriptionListRelationFilter = {
  every?: InputMaybe<UserSubscriptionWhereInput>;
  none?: InputMaybe<UserSubscriptionWhereInput>;
  some?: InputMaybe<UserSubscriptionWhereInput>;
};

export type UserSubscriptionMaxAggregate = {
  __typename?: 'UserSubscriptionMaxAggregate';
  archivedAt?: Maybe<Scalars['DateTime']>;
  channelId?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  endDate?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  startDate?: Maybe<Scalars['DateTime']>;
  subscriptionPlanId?: Maybe<Scalars['String']>;
  transactionId?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  userId?: Maybe<Scalars['String']>;
};

export type UserSubscriptionMaxOrderByAggregateInput = {
  archivedAt?: InputMaybe<SortOrder>;
  channelId?: InputMaybe<SortOrder>;
  createdAt?: InputMaybe<SortOrder>;
  endDate?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  startDate?: InputMaybe<SortOrder>;
  subscriptionPlanId?: InputMaybe<SortOrder>;
  transactionId?: InputMaybe<SortOrder>;
  updatedAt?: InputMaybe<SortOrder>;
  userId?: InputMaybe<SortOrder>;
};

export type UserSubscriptionMinAggregate = {
  __typename?: 'UserSubscriptionMinAggregate';
  archivedAt?: Maybe<Scalars['DateTime']>;
  channelId?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  endDate?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  startDate?: Maybe<Scalars['DateTime']>;
  subscriptionPlanId?: Maybe<Scalars['String']>;
  transactionId?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  userId?: Maybe<Scalars['String']>;
};

export type UserSubscriptionMinOrderByAggregateInput = {
  archivedAt?: InputMaybe<SortOrder>;
  channelId?: InputMaybe<SortOrder>;
  createdAt?: InputMaybe<SortOrder>;
  endDate?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  startDate?: InputMaybe<SortOrder>;
  subscriptionPlanId?: InputMaybe<SortOrder>;
  transactionId?: InputMaybe<SortOrder>;
  updatedAt?: InputMaybe<SortOrder>;
  userId?: InputMaybe<SortOrder>;
};

export type UserSubscriptionOrderByRelationAggregateInput = {
  _count?: InputMaybe<SortOrder>;
};

export type UserSubscriptionOrderByWithAggregationInput = {
  _count?: InputMaybe<UserSubscriptionCountOrderByAggregateInput>;
  _max?: InputMaybe<UserSubscriptionMaxOrderByAggregateInput>;
  _min?: InputMaybe<UserSubscriptionMinOrderByAggregateInput>;
  archivedAt?: InputMaybe<SortOrder>;
  channelId?: InputMaybe<SortOrder>;
  createdAt?: InputMaybe<SortOrder>;
  endDate?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  startDate?: InputMaybe<SortOrder>;
  subscriptionPlanId?: InputMaybe<SortOrder>;
  subscriptionPlanSnapshot?: InputMaybe<SortOrder>;
  transactionId?: InputMaybe<SortOrder>;
  updatedAt?: InputMaybe<SortOrder>;
  userId?: InputMaybe<SortOrder>;
};

export type UserSubscriptionOrderByWithRelationInput = {
  archivedAt?: InputMaybe<SortOrder>;
  channelId?: InputMaybe<SortOrder>;
  createdAt?: InputMaybe<SortOrder>;
  endDate?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  startDate?: InputMaybe<SortOrder>;
  subscribedChannel?: InputMaybe<ChannelOrderByWithRelationInput>;
  subscriber?: InputMaybe<UserOrderByWithRelationInput>;
  subscriptionPlan?: InputMaybe<SubscriptionPlanOrderByWithRelationInput>;
  subscriptionPlanId?: InputMaybe<SortOrder>;
  subscriptionPlanSnapshot?: InputMaybe<SortOrder>;
  transaction?: InputMaybe<TransactionOrderByWithRelationInput>;
  transactionId?: InputMaybe<SortOrder>;
  updatedAt?: InputMaybe<SortOrder>;
  userId?: InputMaybe<SortOrder>;
};

export type UserSubscriptionRelationFilter = {
  is?: InputMaybe<UserSubscriptionWhereInput>;
  isNot?: InputMaybe<UserSubscriptionWhereInput>;
};

export enum UserSubscriptionScalarFieldEnum {
  ArchivedAt = 'archivedAt',
  ChannelId = 'channelId',
  CreatedAt = 'createdAt',
  EndDate = 'endDate',
  Id = 'id',
  StartDate = 'startDate',
  SubscriptionPlanId = 'subscriptionPlanId',
  SubscriptionPlanSnapshot = 'subscriptionPlanSnapshot',
  TransactionId = 'transactionId',
  UpdatedAt = 'updatedAt',
  UserId = 'userId'
}

export type UserSubscriptionScalarWhereInput = {
  AND?: InputMaybe<Array<UserSubscriptionScalarWhereInput>>;
  NOT?: InputMaybe<Array<UserSubscriptionScalarWhereInput>>;
  OR?: InputMaybe<Array<UserSubscriptionScalarWhereInput>>;
  archivedAt?: InputMaybe<DateTimeNullableFilter>;
  channelId?: InputMaybe<StringFilter>;
  createdAt?: InputMaybe<DateTimeFilter>;
  endDate?: InputMaybe<DateTimeFilter>;
  id?: InputMaybe<StringFilter>;
  startDate?: InputMaybe<DateTimeFilter>;
  subscriptionPlanId?: InputMaybe<StringFilter>;
  subscriptionPlanSnapshot?: InputMaybe<JsonFilter>;
  transactionId?: InputMaybe<StringFilter>;
  updatedAt?: InputMaybe<DateTimeFilter>;
  userId?: InputMaybe<StringFilter>;
};

export type UserSubscriptionScalarWhereWithAggregatesInput = {
  AND?: InputMaybe<Array<UserSubscriptionScalarWhereWithAggregatesInput>>;
  NOT?: InputMaybe<Array<UserSubscriptionScalarWhereWithAggregatesInput>>;
  OR?: InputMaybe<Array<UserSubscriptionScalarWhereWithAggregatesInput>>;
  archivedAt?: InputMaybe<DateTimeNullableWithAggregatesFilter>;
  channelId?: InputMaybe<StringWithAggregatesFilter>;
  createdAt?: InputMaybe<DateTimeWithAggregatesFilter>;
  endDate?: InputMaybe<DateTimeWithAggregatesFilter>;
  id?: InputMaybe<StringWithAggregatesFilter>;
  startDate?: InputMaybe<DateTimeWithAggregatesFilter>;
  subscriptionPlanId?: InputMaybe<StringWithAggregatesFilter>;
  subscriptionPlanSnapshot?: InputMaybe<JsonWithAggregatesFilter>;
  transactionId?: InputMaybe<StringWithAggregatesFilter>;
  updatedAt?: InputMaybe<DateTimeWithAggregatesFilter>;
  userId?: InputMaybe<StringWithAggregatesFilter>;
};

export type UserSubscriptionUpdateInput = {
  archivedAt?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  endDate?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  startDate?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  subscribedChannel?: InputMaybe<ChannelUpdateOneRequiredWithoutSubscribedUsersInput>;
  subscriber?: InputMaybe<UserUpdateOneRequiredWithoutUserSubscriptionsInput>;
  subscriptionPlan?: InputMaybe<SubscriptionPlanUpdateOneRequiredWithoutUserSubscriptionsInput>;
  subscriptionPlanSnapshot?: InputMaybe<Scalars['JSON']>;
  transaction?: InputMaybe<TransactionUpdateOneRequiredWithoutUserSubscriptionInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
};

export type UserSubscriptionUpdateManyMutationInput = {
  archivedAt?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  endDate?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  startDate?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  subscriptionPlanSnapshot?: InputMaybe<Scalars['JSON']>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
};

export type UserSubscriptionUpdateManyWithWhereWithoutSubscribedChannelInput = {
  data: UserSubscriptionUpdateManyMutationInput;
  where: UserSubscriptionScalarWhereInput;
};

export type UserSubscriptionUpdateManyWithWhereWithoutSubscriberInput = {
  data: UserSubscriptionUpdateManyMutationInput;
  where: UserSubscriptionScalarWhereInput;
};

export type UserSubscriptionUpdateManyWithWhereWithoutSubscriptionPlanInput = {
  data: UserSubscriptionUpdateManyMutationInput;
  where: UserSubscriptionScalarWhereInput;
};

export type UserSubscriptionUpdateManyWithoutSubscribedChannelInput = {
  connect?: InputMaybe<Array<UserSubscriptionWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<UserSubscriptionCreateOrConnectWithoutSubscribedChannelInput>>;
  create?: InputMaybe<Array<UserSubscriptionCreateWithoutSubscribedChannelInput>>;
  createMany?: InputMaybe<UserSubscriptionCreateManySubscribedChannelInputEnvelope>;
  delete?: InputMaybe<Array<UserSubscriptionWhereUniqueInput>>;
  deleteMany?: InputMaybe<Array<UserSubscriptionScalarWhereInput>>;
  disconnect?: InputMaybe<Array<UserSubscriptionWhereUniqueInput>>;
  set?: InputMaybe<Array<UserSubscriptionWhereUniqueInput>>;
  update?: InputMaybe<Array<UserSubscriptionUpdateWithWhereUniqueWithoutSubscribedChannelInput>>;
  updateMany?: InputMaybe<Array<UserSubscriptionUpdateManyWithWhereWithoutSubscribedChannelInput>>;
  upsert?: InputMaybe<Array<UserSubscriptionUpsertWithWhereUniqueWithoutSubscribedChannelInput>>;
};

export type UserSubscriptionUpdateManyWithoutSubscriberInput = {
  connect?: InputMaybe<Array<UserSubscriptionWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<UserSubscriptionCreateOrConnectWithoutSubscriberInput>>;
  create?: InputMaybe<Array<UserSubscriptionCreateWithoutSubscriberInput>>;
  createMany?: InputMaybe<UserSubscriptionCreateManySubscriberInputEnvelope>;
  delete?: InputMaybe<Array<UserSubscriptionWhereUniqueInput>>;
  deleteMany?: InputMaybe<Array<UserSubscriptionScalarWhereInput>>;
  disconnect?: InputMaybe<Array<UserSubscriptionWhereUniqueInput>>;
  set?: InputMaybe<Array<UserSubscriptionWhereUniqueInput>>;
  update?: InputMaybe<Array<UserSubscriptionUpdateWithWhereUniqueWithoutSubscriberInput>>;
  updateMany?: InputMaybe<Array<UserSubscriptionUpdateManyWithWhereWithoutSubscriberInput>>;
  upsert?: InputMaybe<Array<UserSubscriptionUpsertWithWhereUniqueWithoutSubscriberInput>>;
};

export type UserSubscriptionUpdateManyWithoutSubscriptionPlanInput = {
  connect?: InputMaybe<Array<UserSubscriptionWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<UserSubscriptionCreateOrConnectWithoutSubscriptionPlanInput>>;
  create?: InputMaybe<Array<UserSubscriptionCreateWithoutSubscriptionPlanInput>>;
  createMany?: InputMaybe<UserSubscriptionCreateManySubscriptionPlanInputEnvelope>;
  delete?: InputMaybe<Array<UserSubscriptionWhereUniqueInput>>;
  deleteMany?: InputMaybe<Array<UserSubscriptionScalarWhereInput>>;
  disconnect?: InputMaybe<Array<UserSubscriptionWhereUniqueInput>>;
  set?: InputMaybe<Array<UserSubscriptionWhereUniqueInput>>;
  update?: InputMaybe<Array<UserSubscriptionUpdateWithWhereUniqueWithoutSubscriptionPlanInput>>;
  updateMany?: InputMaybe<Array<UserSubscriptionUpdateManyWithWhereWithoutSubscriptionPlanInput>>;
  upsert?: InputMaybe<Array<UserSubscriptionUpsertWithWhereUniqueWithoutSubscriptionPlanInput>>;
};

export type UserSubscriptionUpdateOneWithoutTransactionInput = {
  connect?: InputMaybe<UserSubscriptionWhereUniqueInput>;
  connectOrCreate?: InputMaybe<UserSubscriptionCreateOrConnectWithoutTransactionInput>;
  create?: InputMaybe<UserSubscriptionCreateWithoutTransactionInput>;
  delete?: InputMaybe<Scalars['Boolean']>;
  disconnect?: InputMaybe<Scalars['Boolean']>;
  update?: InputMaybe<UserSubscriptionUpdateWithoutTransactionInput>;
  upsert?: InputMaybe<UserSubscriptionUpsertWithoutTransactionInput>;
};

export type UserSubscriptionUpdateWithWhereUniqueWithoutSubscribedChannelInput = {
  data: UserSubscriptionUpdateWithoutSubscribedChannelInput;
  where: UserSubscriptionWhereUniqueInput;
};

export type UserSubscriptionUpdateWithWhereUniqueWithoutSubscriberInput = {
  data: UserSubscriptionUpdateWithoutSubscriberInput;
  where: UserSubscriptionWhereUniqueInput;
};

export type UserSubscriptionUpdateWithWhereUniqueWithoutSubscriptionPlanInput = {
  data: UserSubscriptionUpdateWithoutSubscriptionPlanInput;
  where: UserSubscriptionWhereUniqueInput;
};

export type UserSubscriptionUpdateWithoutSubscribedChannelInput = {
  archivedAt?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  endDate?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  startDate?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  subscriber?: InputMaybe<UserUpdateOneRequiredWithoutUserSubscriptionsInput>;
  subscriptionPlan?: InputMaybe<SubscriptionPlanUpdateOneRequiredWithoutUserSubscriptionsInput>;
  subscriptionPlanSnapshot?: InputMaybe<Scalars['JSON']>;
  transaction?: InputMaybe<TransactionUpdateOneRequiredWithoutUserSubscriptionInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
};

export type UserSubscriptionUpdateWithoutSubscriberInput = {
  archivedAt?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  endDate?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  startDate?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  subscribedChannel?: InputMaybe<ChannelUpdateOneRequiredWithoutSubscribedUsersInput>;
  subscriptionPlan?: InputMaybe<SubscriptionPlanUpdateOneRequiredWithoutUserSubscriptionsInput>;
  subscriptionPlanSnapshot?: InputMaybe<Scalars['JSON']>;
  transaction?: InputMaybe<TransactionUpdateOneRequiredWithoutUserSubscriptionInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
};

export type UserSubscriptionUpdateWithoutSubscriptionPlanInput = {
  archivedAt?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  endDate?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  startDate?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  subscribedChannel?: InputMaybe<ChannelUpdateOneRequiredWithoutSubscribedUsersInput>;
  subscriber?: InputMaybe<UserUpdateOneRequiredWithoutUserSubscriptionsInput>;
  subscriptionPlanSnapshot?: InputMaybe<Scalars['JSON']>;
  transaction?: InputMaybe<TransactionUpdateOneRequiredWithoutUserSubscriptionInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
};

export type UserSubscriptionUpdateWithoutTransactionInput = {
  archivedAt?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  endDate?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  startDate?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  subscribedChannel?: InputMaybe<ChannelUpdateOneRequiredWithoutSubscribedUsersInput>;
  subscriber?: InputMaybe<UserUpdateOneRequiredWithoutUserSubscriptionsInput>;
  subscriptionPlan?: InputMaybe<SubscriptionPlanUpdateOneRequiredWithoutUserSubscriptionsInput>;
  subscriptionPlanSnapshot?: InputMaybe<Scalars['JSON']>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
};

export type UserSubscriptionUpsertWithWhereUniqueWithoutSubscribedChannelInput = {
  create: UserSubscriptionCreateWithoutSubscribedChannelInput;
  update: UserSubscriptionUpdateWithoutSubscribedChannelInput;
  where: UserSubscriptionWhereUniqueInput;
};

export type UserSubscriptionUpsertWithWhereUniqueWithoutSubscriberInput = {
  create: UserSubscriptionCreateWithoutSubscriberInput;
  update: UserSubscriptionUpdateWithoutSubscriberInput;
  where: UserSubscriptionWhereUniqueInput;
};

export type UserSubscriptionUpsertWithWhereUniqueWithoutSubscriptionPlanInput = {
  create: UserSubscriptionCreateWithoutSubscriptionPlanInput;
  update: UserSubscriptionUpdateWithoutSubscriptionPlanInput;
  where: UserSubscriptionWhereUniqueInput;
};

export type UserSubscriptionUpsertWithoutTransactionInput = {
  create: UserSubscriptionCreateWithoutTransactionInput;
  update: UserSubscriptionUpdateWithoutTransactionInput;
};

export type UserSubscriptionWhereInput = {
  AND?: InputMaybe<Array<UserSubscriptionWhereInput>>;
  NOT?: InputMaybe<Array<UserSubscriptionWhereInput>>;
  OR?: InputMaybe<Array<UserSubscriptionWhereInput>>;
  archivedAt?: InputMaybe<DateTimeNullableFilter>;
  channelId?: InputMaybe<StringFilter>;
  createdAt?: InputMaybe<DateTimeFilter>;
  endDate?: InputMaybe<DateTimeFilter>;
  id?: InputMaybe<StringFilter>;
  startDate?: InputMaybe<DateTimeFilter>;
  subscribedChannel?: InputMaybe<ChannelRelationFilter>;
  subscriber?: InputMaybe<UserRelationFilter>;
  subscriptionPlan?: InputMaybe<SubscriptionPlanRelationFilter>;
  subscriptionPlanId?: InputMaybe<StringFilter>;
  subscriptionPlanSnapshot?: InputMaybe<JsonFilter>;
  transaction?: InputMaybe<TransactionRelationFilter>;
  transactionId?: InputMaybe<StringFilter>;
  updatedAt?: InputMaybe<DateTimeFilter>;
  userId?: InputMaybe<StringFilter>;
};

export type UserSubscriptionWhereUniqueInput = {
  id?: InputMaybe<Scalars['String']>;
  transactionId?: InputMaybe<Scalars['String']>;
};

export enum UserType {
  Audience = 'AUDIENCE',
  Channel = 'CHANNEL',
  Creator = 'CREATOR'
}

export type UserUpdateInput = {
  UserTips?: InputMaybe<TipsUpdateManyWithoutPayerInput>;
  accessToken?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  approvedAt?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  archivedAt?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  avatarUrl?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  bankAccountHolderName?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  bankAccountNumber?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  bankBranchCode?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  bankName?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  bankSwiftCode?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  bannerUrl?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  channel?: InputMaybe<ChannelUpdateOneWithoutUserInput>;
  comments?: InputMaybe<CommentUpdateManyWithoutUserInput>;
  companyAddressCity?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  companyAddressCountry?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  companyAddressLine1?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  companyAddressLine2?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  companyAddressPostcode?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  companyAddressState?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  companyName?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  contactPersonEmail?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  contactPersonMobileNumber?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  contactPersonName?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  countryCode?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  createdNfts?: InputMaybe<MintedNftUpdateManyWithoutCreatorUserInput>;
  currencyCode?: InputMaybe<EnumCurrencyCodeFieldUpdateOperationsInput>;
  email?: InputMaybe<StringFieldUpdateOperationsInput>;
  followedBy?: InputMaybe<ChannelFollowerUpdateManyWithoutFolloweeOwnerInput>;
  followedChannels?: InputMaybe<ChannelFollowerUpdateManyWithoutFollowerInput>;
  fullName?: InputMaybe<StringFieldUpdateOperationsInput>;
  giftsSent?: InputMaybe<GiftDistributionUpdateManyWithoutSenderInput>;
  holdNfts?: InputMaybe<MintedNftUpdateManyWithoutHolderUserInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  isEmailVerified?: InputMaybe<BoolFieldUpdateOperationsInput>;
  likedVideos?: InputMaybe<LikedVideoUpdateManyWithoutUserInput>;
  mobileCountryCode?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  mobileNumber?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  mongoUserId?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  nftCollections?: InputMaybe<UserNftCollectionsUpdateManyWithoutUserInput>;
  password?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  preferredLanguage?: InputMaybe<NullableEnumCommunicationLanguageFieldUpdateOperationsInput>;
  prestoUserId?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  referralAgentId?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  refreshToken?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  role?: InputMaybe<NullableEnumUserTypeFieldUpdateOperationsInput>;
  selfRegistered?: InputMaybe<BoolFieldUpdateOperationsInput>;
  sendbirdAccessToken?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  status?: InputMaybe<EnumStatusFieldUpdateOperationsInput>;
  subscribeInfoAgreed?: InputMaybe<BoolFieldUpdateOperationsInput>;
  tosAgreed?: InputMaybe<BoolFieldUpdateOperationsInput>;
  transactions?: InputMaybe<TransactionUpdateManyWithoutPaymentByInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  userPayPerViews?: InputMaybe<UserPayPerViewUpdateManyWithoutUserInput>;
  userSubscriptions?: InputMaybe<UserSubscriptionUpdateManyWithoutSubscriberInput>;
  username?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  voucherCode?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  watchHistories?: InputMaybe<WatchHistoryUpdateManyWithoutUserInput>;
};

export type UserUpdateManyMutationInput = {
  accessToken?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  approvedAt?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  archivedAt?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  avatarUrl?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  bankAccountHolderName?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  bankAccountNumber?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  bankBranchCode?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  bankName?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  bankSwiftCode?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  bannerUrl?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  companyAddressCity?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  companyAddressCountry?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  companyAddressLine1?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  companyAddressLine2?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  companyAddressPostcode?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  companyAddressState?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  companyName?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  contactPersonEmail?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  contactPersonMobileNumber?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  contactPersonName?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  countryCode?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  currencyCode?: InputMaybe<EnumCurrencyCodeFieldUpdateOperationsInput>;
  email?: InputMaybe<StringFieldUpdateOperationsInput>;
  fullName?: InputMaybe<StringFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  isEmailVerified?: InputMaybe<BoolFieldUpdateOperationsInput>;
  mobileCountryCode?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  mobileNumber?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  mongoUserId?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  password?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  preferredLanguage?: InputMaybe<NullableEnumCommunicationLanguageFieldUpdateOperationsInput>;
  prestoUserId?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  referralAgentId?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  refreshToken?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  role?: InputMaybe<NullableEnumUserTypeFieldUpdateOperationsInput>;
  selfRegistered?: InputMaybe<BoolFieldUpdateOperationsInput>;
  sendbirdAccessToken?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  status?: InputMaybe<EnumStatusFieldUpdateOperationsInput>;
  subscribeInfoAgreed?: InputMaybe<BoolFieldUpdateOperationsInput>;
  tosAgreed?: InputMaybe<BoolFieldUpdateOperationsInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  username?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  voucherCode?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
};

export type UserUpdateOneRequiredWithoutChannelInput = {
  connect?: InputMaybe<UserWhereUniqueInput>;
  connectOrCreate?: InputMaybe<UserCreateOrConnectWithoutChannelInput>;
  create?: InputMaybe<UserCreateWithoutChannelInput>;
  update?: InputMaybe<UserUpdateWithoutChannelInput>;
  upsert?: InputMaybe<UserUpsertWithoutChannelInput>;
};

export type UserUpdateOneRequiredWithoutCommentsInput = {
  connect?: InputMaybe<UserWhereUniqueInput>;
  connectOrCreate?: InputMaybe<UserCreateOrConnectWithoutCommentsInput>;
  create?: InputMaybe<UserCreateWithoutCommentsInput>;
  update?: InputMaybe<UserUpdateWithoutCommentsInput>;
  upsert?: InputMaybe<UserUpsertWithoutCommentsInput>;
};

export type UserUpdateOneRequiredWithoutCreatedNftsInput = {
  connect?: InputMaybe<UserWhereUniqueInput>;
  connectOrCreate?: InputMaybe<UserCreateOrConnectWithoutCreatedNftsInput>;
  create?: InputMaybe<UserCreateWithoutCreatedNftsInput>;
  update?: InputMaybe<UserUpdateWithoutCreatedNftsInput>;
  upsert?: InputMaybe<UserUpsertWithoutCreatedNftsInput>;
};

export type UserUpdateOneRequiredWithoutFollowedByInput = {
  connect?: InputMaybe<UserWhereUniqueInput>;
  connectOrCreate?: InputMaybe<UserCreateOrConnectWithoutFollowedByInput>;
  create?: InputMaybe<UserCreateWithoutFollowedByInput>;
  update?: InputMaybe<UserUpdateWithoutFollowedByInput>;
  upsert?: InputMaybe<UserUpsertWithoutFollowedByInput>;
};

export type UserUpdateOneRequiredWithoutFollowedChannelsInput = {
  connect?: InputMaybe<UserWhereUniqueInput>;
  connectOrCreate?: InputMaybe<UserCreateOrConnectWithoutFollowedChannelsInput>;
  create?: InputMaybe<UserCreateWithoutFollowedChannelsInput>;
  update?: InputMaybe<UserUpdateWithoutFollowedChannelsInput>;
  upsert?: InputMaybe<UserUpsertWithoutFollowedChannelsInput>;
};

export type UserUpdateOneRequiredWithoutGiftsSentInput = {
  connect?: InputMaybe<UserWhereUniqueInput>;
  connectOrCreate?: InputMaybe<UserCreateOrConnectWithoutGiftsSentInput>;
  create?: InputMaybe<UserCreateWithoutGiftsSentInput>;
  update?: InputMaybe<UserUpdateWithoutGiftsSentInput>;
  upsert?: InputMaybe<UserUpsertWithoutGiftsSentInput>;
};

export type UserUpdateOneRequiredWithoutHoldNftsInput = {
  connect?: InputMaybe<UserWhereUniqueInput>;
  connectOrCreate?: InputMaybe<UserCreateOrConnectWithoutHoldNftsInput>;
  create?: InputMaybe<UserCreateWithoutHoldNftsInput>;
  update?: InputMaybe<UserUpdateWithoutHoldNftsInput>;
  upsert?: InputMaybe<UserUpsertWithoutHoldNftsInput>;
};

export type UserUpdateOneRequiredWithoutLikedVideosInput = {
  connect?: InputMaybe<UserWhereUniqueInput>;
  connectOrCreate?: InputMaybe<UserCreateOrConnectWithoutLikedVideosInput>;
  create?: InputMaybe<UserCreateWithoutLikedVideosInput>;
  update?: InputMaybe<UserUpdateWithoutLikedVideosInput>;
  upsert?: InputMaybe<UserUpsertWithoutLikedVideosInput>;
};

export type UserUpdateOneRequiredWithoutNftCollectionsInput = {
  connect?: InputMaybe<UserWhereUniqueInput>;
  connectOrCreate?: InputMaybe<UserCreateOrConnectWithoutNftCollectionsInput>;
  create?: InputMaybe<UserCreateWithoutNftCollectionsInput>;
  update?: InputMaybe<UserUpdateWithoutNftCollectionsInput>;
  upsert?: InputMaybe<UserUpsertWithoutNftCollectionsInput>;
};

export type UserUpdateOneRequiredWithoutTransactionsInput = {
  connect?: InputMaybe<UserWhereUniqueInput>;
  connectOrCreate?: InputMaybe<UserCreateOrConnectWithoutTransactionsInput>;
  create?: InputMaybe<UserCreateWithoutTransactionsInput>;
  update?: InputMaybe<UserUpdateWithoutTransactionsInput>;
  upsert?: InputMaybe<UserUpsertWithoutTransactionsInput>;
};

export type UserUpdateOneRequiredWithoutUserPayPerViewsInput = {
  connect?: InputMaybe<UserWhereUniqueInput>;
  connectOrCreate?: InputMaybe<UserCreateOrConnectWithoutUserPayPerViewsInput>;
  create?: InputMaybe<UserCreateWithoutUserPayPerViewsInput>;
  update?: InputMaybe<UserUpdateWithoutUserPayPerViewsInput>;
  upsert?: InputMaybe<UserUpsertWithoutUserPayPerViewsInput>;
};

export type UserUpdateOneRequiredWithoutUserSubscriptionsInput = {
  connect?: InputMaybe<UserWhereUniqueInput>;
  connectOrCreate?: InputMaybe<UserCreateOrConnectWithoutUserSubscriptionsInput>;
  create?: InputMaybe<UserCreateWithoutUserSubscriptionsInput>;
  update?: InputMaybe<UserUpdateWithoutUserSubscriptionsInput>;
  upsert?: InputMaybe<UserUpsertWithoutUserSubscriptionsInput>;
};

export type UserUpdateOneRequiredWithoutUserTipsInput = {
  connect?: InputMaybe<UserWhereUniqueInput>;
  connectOrCreate?: InputMaybe<UserCreateOrConnectWithoutUserTipsInput>;
  create?: InputMaybe<UserCreateWithoutUserTipsInput>;
  update?: InputMaybe<UserUpdateWithoutUserTipsInput>;
  upsert?: InputMaybe<UserUpsertWithoutUserTipsInput>;
};

export type UserUpdateOneRequiredWithoutWatchHistoriesInput = {
  connect?: InputMaybe<UserWhereUniqueInput>;
  connectOrCreate?: InputMaybe<UserCreateOrConnectWithoutWatchHistoriesInput>;
  create?: InputMaybe<UserCreateWithoutWatchHistoriesInput>;
  update?: InputMaybe<UserUpdateWithoutWatchHistoriesInput>;
  upsert?: InputMaybe<UserUpsertWithoutWatchHistoriesInput>;
};

export type UserUpdateWithoutChannelInput = {
  UserTips?: InputMaybe<TipsUpdateManyWithoutPayerInput>;
  accessToken?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  approvedAt?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  archivedAt?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  avatarUrl?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  bankAccountHolderName?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  bankAccountNumber?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  bankBranchCode?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  bankName?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  bankSwiftCode?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  bannerUrl?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  comments?: InputMaybe<CommentUpdateManyWithoutUserInput>;
  companyAddressCity?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  companyAddressCountry?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  companyAddressLine1?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  companyAddressLine2?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  companyAddressPostcode?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  companyAddressState?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  companyName?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  contactPersonEmail?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  contactPersonMobileNumber?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  contactPersonName?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  countryCode?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  createdNfts?: InputMaybe<MintedNftUpdateManyWithoutCreatorUserInput>;
  currencyCode?: InputMaybe<EnumCurrencyCodeFieldUpdateOperationsInput>;
  email?: InputMaybe<StringFieldUpdateOperationsInput>;
  followedBy?: InputMaybe<ChannelFollowerUpdateManyWithoutFolloweeOwnerInput>;
  followedChannels?: InputMaybe<ChannelFollowerUpdateManyWithoutFollowerInput>;
  fullName?: InputMaybe<StringFieldUpdateOperationsInput>;
  giftsSent?: InputMaybe<GiftDistributionUpdateManyWithoutSenderInput>;
  holdNfts?: InputMaybe<MintedNftUpdateManyWithoutHolderUserInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  isEmailVerified?: InputMaybe<BoolFieldUpdateOperationsInput>;
  likedVideos?: InputMaybe<LikedVideoUpdateManyWithoutUserInput>;
  mobileCountryCode?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  mobileNumber?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  mongoUserId?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  nftCollections?: InputMaybe<UserNftCollectionsUpdateManyWithoutUserInput>;
  password?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  preferredLanguage?: InputMaybe<NullableEnumCommunicationLanguageFieldUpdateOperationsInput>;
  prestoUserId?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  referralAgentId?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  refreshToken?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  role?: InputMaybe<NullableEnumUserTypeFieldUpdateOperationsInput>;
  selfRegistered?: InputMaybe<BoolFieldUpdateOperationsInput>;
  sendbirdAccessToken?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  status?: InputMaybe<EnumStatusFieldUpdateOperationsInput>;
  subscribeInfoAgreed?: InputMaybe<BoolFieldUpdateOperationsInput>;
  tosAgreed?: InputMaybe<BoolFieldUpdateOperationsInput>;
  transactions?: InputMaybe<TransactionUpdateManyWithoutPaymentByInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  userPayPerViews?: InputMaybe<UserPayPerViewUpdateManyWithoutUserInput>;
  userSubscriptions?: InputMaybe<UserSubscriptionUpdateManyWithoutSubscriberInput>;
  username?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  voucherCode?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  watchHistories?: InputMaybe<WatchHistoryUpdateManyWithoutUserInput>;
};

export type UserUpdateWithoutCommentsInput = {
  UserTips?: InputMaybe<TipsUpdateManyWithoutPayerInput>;
  accessToken?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  approvedAt?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  archivedAt?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  avatarUrl?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  bankAccountHolderName?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  bankAccountNumber?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  bankBranchCode?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  bankName?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  bankSwiftCode?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  bannerUrl?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  channel?: InputMaybe<ChannelUpdateOneWithoutUserInput>;
  companyAddressCity?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  companyAddressCountry?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  companyAddressLine1?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  companyAddressLine2?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  companyAddressPostcode?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  companyAddressState?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  companyName?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  contactPersonEmail?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  contactPersonMobileNumber?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  contactPersonName?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  countryCode?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  createdNfts?: InputMaybe<MintedNftUpdateManyWithoutCreatorUserInput>;
  currencyCode?: InputMaybe<EnumCurrencyCodeFieldUpdateOperationsInput>;
  email?: InputMaybe<StringFieldUpdateOperationsInput>;
  followedBy?: InputMaybe<ChannelFollowerUpdateManyWithoutFolloweeOwnerInput>;
  followedChannels?: InputMaybe<ChannelFollowerUpdateManyWithoutFollowerInput>;
  fullName?: InputMaybe<StringFieldUpdateOperationsInput>;
  giftsSent?: InputMaybe<GiftDistributionUpdateManyWithoutSenderInput>;
  holdNfts?: InputMaybe<MintedNftUpdateManyWithoutHolderUserInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  isEmailVerified?: InputMaybe<BoolFieldUpdateOperationsInput>;
  likedVideos?: InputMaybe<LikedVideoUpdateManyWithoutUserInput>;
  mobileCountryCode?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  mobileNumber?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  mongoUserId?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  nftCollections?: InputMaybe<UserNftCollectionsUpdateManyWithoutUserInput>;
  password?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  preferredLanguage?: InputMaybe<NullableEnumCommunicationLanguageFieldUpdateOperationsInput>;
  prestoUserId?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  referralAgentId?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  refreshToken?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  role?: InputMaybe<NullableEnumUserTypeFieldUpdateOperationsInput>;
  selfRegistered?: InputMaybe<BoolFieldUpdateOperationsInput>;
  sendbirdAccessToken?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  status?: InputMaybe<EnumStatusFieldUpdateOperationsInput>;
  subscribeInfoAgreed?: InputMaybe<BoolFieldUpdateOperationsInput>;
  tosAgreed?: InputMaybe<BoolFieldUpdateOperationsInput>;
  transactions?: InputMaybe<TransactionUpdateManyWithoutPaymentByInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  userPayPerViews?: InputMaybe<UserPayPerViewUpdateManyWithoutUserInput>;
  userSubscriptions?: InputMaybe<UserSubscriptionUpdateManyWithoutSubscriberInput>;
  username?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  voucherCode?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  watchHistories?: InputMaybe<WatchHistoryUpdateManyWithoutUserInput>;
};

export type UserUpdateWithoutCreatedNftsInput = {
  UserTips?: InputMaybe<TipsUpdateManyWithoutPayerInput>;
  accessToken?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  approvedAt?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  archivedAt?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  avatarUrl?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  bankAccountHolderName?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  bankAccountNumber?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  bankBranchCode?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  bankName?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  bankSwiftCode?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  bannerUrl?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  channel?: InputMaybe<ChannelUpdateOneWithoutUserInput>;
  comments?: InputMaybe<CommentUpdateManyWithoutUserInput>;
  companyAddressCity?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  companyAddressCountry?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  companyAddressLine1?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  companyAddressLine2?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  companyAddressPostcode?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  companyAddressState?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  companyName?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  contactPersonEmail?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  contactPersonMobileNumber?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  contactPersonName?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  countryCode?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  currencyCode?: InputMaybe<EnumCurrencyCodeFieldUpdateOperationsInput>;
  email?: InputMaybe<StringFieldUpdateOperationsInput>;
  followedBy?: InputMaybe<ChannelFollowerUpdateManyWithoutFolloweeOwnerInput>;
  followedChannels?: InputMaybe<ChannelFollowerUpdateManyWithoutFollowerInput>;
  fullName?: InputMaybe<StringFieldUpdateOperationsInput>;
  giftsSent?: InputMaybe<GiftDistributionUpdateManyWithoutSenderInput>;
  holdNfts?: InputMaybe<MintedNftUpdateManyWithoutHolderUserInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  isEmailVerified?: InputMaybe<BoolFieldUpdateOperationsInput>;
  likedVideos?: InputMaybe<LikedVideoUpdateManyWithoutUserInput>;
  mobileCountryCode?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  mobileNumber?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  mongoUserId?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  nftCollections?: InputMaybe<UserNftCollectionsUpdateManyWithoutUserInput>;
  password?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  preferredLanguage?: InputMaybe<NullableEnumCommunicationLanguageFieldUpdateOperationsInput>;
  prestoUserId?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  referralAgentId?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  refreshToken?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  role?: InputMaybe<NullableEnumUserTypeFieldUpdateOperationsInput>;
  selfRegistered?: InputMaybe<BoolFieldUpdateOperationsInput>;
  sendbirdAccessToken?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  status?: InputMaybe<EnumStatusFieldUpdateOperationsInput>;
  subscribeInfoAgreed?: InputMaybe<BoolFieldUpdateOperationsInput>;
  tosAgreed?: InputMaybe<BoolFieldUpdateOperationsInput>;
  transactions?: InputMaybe<TransactionUpdateManyWithoutPaymentByInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  userPayPerViews?: InputMaybe<UserPayPerViewUpdateManyWithoutUserInput>;
  userSubscriptions?: InputMaybe<UserSubscriptionUpdateManyWithoutSubscriberInput>;
  username?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  voucherCode?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  watchHistories?: InputMaybe<WatchHistoryUpdateManyWithoutUserInput>;
};

export type UserUpdateWithoutFollowedByInput = {
  UserTips?: InputMaybe<TipsUpdateManyWithoutPayerInput>;
  accessToken?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  approvedAt?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  archivedAt?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  avatarUrl?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  bankAccountHolderName?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  bankAccountNumber?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  bankBranchCode?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  bankName?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  bankSwiftCode?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  bannerUrl?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  channel?: InputMaybe<ChannelUpdateOneWithoutUserInput>;
  comments?: InputMaybe<CommentUpdateManyWithoutUserInput>;
  companyAddressCity?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  companyAddressCountry?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  companyAddressLine1?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  companyAddressLine2?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  companyAddressPostcode?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  companyAddressState?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  companyName?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  contactPersonEmail?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  contactPersonMobileNumber?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  contactPersonName?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  countryCode?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  createdNfts?: InputMaybe<MintedNftUpdateManyWithoutCreatorUserInput>;
  currencyCode?: InputMaybe<EnumCurrencyCodeFieldUpdateOperationsInput>;
  email?: InputMaybe<StringFieldUpdateOperationsInput>;
  followedChannels?: InputMaybe<ChannelFollowerUpdateManyWithoutFollowerInput>;
  fullName?: InputMaybe<StringFieldUpdateOperationsInput>;
  giftsSent?: InputMaybe<GiftDistributionUpdateManyWithoutSenderInput>;
  holdNfts?: InputMaybe<MintedNftUpdateManyWithoutHolderUserInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  isEmailVerified?: InputMaybe<BoolFieldUpdateOperationsInput>;
  likedVideos?: InputMaybe<LikedVideoUpdateManyWithoutUserInput>;
  mobileCountryCode?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  mobileNumber?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  mongoUserId?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  nftCollections?: InputMaybe<UserNftCollectionsUpdateManyWithoutUserInput>;
  password?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  preferredLanguage?: InputMaybe<NullableEnumCommunicationLanguageFieldUpdateOperationsInput>;
  prestoUserId?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  referralAgentId?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  refreshToken?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  role?: InputMaybe<NullableEnumUserTypeFieldUpdateOperationsInput>;
  selfRegistered?: InputMaybe<BoolFieldUpdateOperationsInput>;
  sendbirdAccessToken?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  status?: InputMaybe<EnumStatusFieldUpdateOperationsInput>;
  subscribeInfoAgreed?: InputMaybe<BoolFieldUpdateOperationsInput>;
  tosAgreed?: InputMaybe<BoolFieldUpdateOperationsInput>;
  transactions?: InputMaybe<TransactionUpdateManyWithoutPaymentByInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  userPayPerViews?: InputMaybe<UserPayPerViewUpdateManyWithoutUserInput>;
  userSubscriptions?: InputMaybe<UserSubscriptionUpdateManyWithoutSubscriberInput>;
  username?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  voucherCode?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  watchHistories?: InputMaybe<WatchHistoryUpdateManyWithoutUserInput>;
};

export type UserUpdateWithoutFollowedChannelsInput = {
  UserTips?: InputMaybe<TipsUpdateManyWithoutPayerInput>;
  accessToken?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  approvedAt?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  archivedAt?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  avatarUrl?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  bankAccountHolderName?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  bankAccountNumber?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  bankBranchCode?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  bankName?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  bankSwiftCode?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  bannerUrl?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  channel?: InputMaybe<ChannelUpdateOneWithoutUserInput>;
  comments?: InputMaybe<CommentUpdateManyWithoutUserInput>;
  companyAddressCity?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  companyAddressCountry?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  companyAddressLine1?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  companyAddressLine2?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  companyAddressPostcode?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  companyAddressState?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  companyName?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  contactPersonEmail?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  contactPersonMobileNumber?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  contactPersonName?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  countryCode?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  createdNfts?: InputMaybe<MintedNftUpdateManyWithoutCreatorUserInput>;
  currencyCode?: InputMaybe<EnumCurrencyCodeFieldUpdateOperationsInput>;
  email?: InputMaybe<StringFieldUpdateOperationsInput>;
  followedBy?: InputMaybe<ChannelFollowerUpdateManyWithoutFolloweeOwnerInput>;
  fullName?: InputMaybe<StringFieldUpdateOperationsInput>;
  giftsSent?: InputMaybe<GiftDistributionUpdateManyWithoutSenderInput>;
  holdNfts?: InputMaybe<MintedNftUpdateManyWithoutHolderUserInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  isEmailVerified?: InputMaybe<BoolFieldUpdateOperationsInput>;
  likedVideos?: InputMaybe<LikedVideoUpdateManyWithoutUserInput>;
  mobileCountryCode?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  mobileNumber?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  mongoUserId?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  nftCollections?: InputMaybe<UserNftCollectionsUpdateManyWithoutUserInput>;
  password?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  preferredLanguage?: InputMaybe<NullableEnumCommunicationLanguageFieldUpdateOperationsInput>;
  prestoUserId?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  referralAgentId?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  refreshToken?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  role?: InputMaybe<NullableEnumUserTypeFieldUpdateOperationsInput>;
  selfRegistered?: InputMaybe<BoolFieldUpdateOperationsInput>;
  sendbirdAccessToken?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  status?: InputMaybe<EnumStatusFieldUpdateOperationsInput>;
  subscribeInfoAgreed?: InputMaybe<BoolFieldUpdateOperationsInput>;
  tosAgreed?: InputMaybe<BoolFieldUpdateOperationsInput>;
  transactions?: InputMaybe<TransactionUpdateManyWithoutPaymentByInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  userPayPerViews?: InputMaybe<UserPayPerViewUpdateManyWithoutUserInput>;
  userSubscriptions?: InputMaybe<UserSubscriptionUpdateManyWithoutSubscriberInput>;
  username?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  voucherCode?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  watchHistories?: InputMaybe<WatchHistoryUpdateManyWithoutUserInput>;
};

export type UserUpdateWithoutGiftsSentInput = {
  UserTips?: InputMaybe<TipsUpdateManyWithoutPayerInput>;
  accessToken?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  approvedAt?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  archivedAt?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  avatarUrl?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  bankAccountHolderName?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  bankAccountNumber?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  bankBranchCode?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  bankName?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  bankSwiftCode?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  bannerUrl?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  channel?: InputMaybe<ChannelUpdateOneWithoutUserInput>;
  comments?: InputMaybe<CommentUpdateManyWithoutUserInput>;
  companyAddressCity?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  companyAddressCountry?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  companyAddressLine1?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  companyAddressLine2?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  companyAddressPostcode?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  companyAddressState?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  companyName?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  contactPersonEmail?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  contactPersonMobileNumber?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  contactPersonName?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  countryCode?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  createdNfts?: InputMaybe<MintedNftUpdateManyWithoutCreatorUserInput>;
  currencyCode?: InputMaybe<EnumCurrencyCodeFieldUpdateOperationsInput>;
  email?: InputMaybe<StringFieldUpdateOperationsInput>;
  followedBy?: InputMaybe<ChannelFollowerUpdateManyWithoutFolloweeOwnerInput>;
  followedChannels?: InputMaybe<ChannelFollowerUpdateManyWithoutFollowerInput>;
  fullName?: InputMaybe<StringFieldUpdateOperationsInput>;
  holdNfts?: InputMaybe<MintedNftUpdateManyWithoutHolderUserInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  isEmailVerified?: InputMaybe<BoolFieldUpdateOperationsInput>;
  likedVideos?: InputMaybe<LikedVideoUpdateManyWithoutUserInput>;
  mobileCountryCode?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  mobileNumber?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  mongoUserId?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  nftCollections?: InputMaybe<UserNftCollectionsUpdateManyWithoutUserInput>;
  password?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  preferredLanguage?: InputMaybe<NullableEnumCommunicationLanguageFieldUpdateOperationsInput>;
  prestoUserId?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  referralAgentId?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  refreshToken?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  role?: InputMaybe<NullableEnumUserTypeFieldUpdateOperationsInput>;
  selfRegistered?: InputMaybe<BoolFieldUpdateOperationsInput>;
  sendbirdAccessToken?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  status?: InputMaybe<EnumStatusFieldUpdateOperationsInput>;
  subscribeInfoAgreed?: InputMaybe<BoolFieldUpdateOperationsInput>;
  tosAgreed?: InputMaybe<BoolFieldUpdateOperationsInput>;
  transactions?: InputMaybe<TransactionUpdateManyWithoutPaymentByInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  userPayPerViews?: InputMaybe<UserPayPerViewUpdateManyWithoutUserInput>;
  userSubscriptions?: InputMaybe<UserSubscriptionUpdateManyWithoutSubscriberInput>;
  username?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  voucherCode?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  watchHistories?: InputMaybe<WatchHistoryUpdateManyWithoutUserInput>;
};

export type UserUpdateWithoutHoldNftsInput = {
  UserTips?: InputMaybe<TipsUpdateManyWithoutPayerInput>;
  accessToken?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  approvedAt?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  archivedAt?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  avatarUrl?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  bankAccountHolderName?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  bankAccountNumber?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  bankBranchCode?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  bankName?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  bankSwiftCode?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  bannerUrl?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  channel?: InputMaybe<ChannelUpdateOneWithoutUserInput>;
  comments?: InputMaybe<CommentUpdateManyWithoutUserInput>;
  companyAddressCity?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  companyAddressCountry?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  companyAddressLine1?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  companyAddressLine2?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  companyAddressPostcode?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  companyAddressState?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  companyName?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  contactPersonEmail?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  contactPersonMobileNumber?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  contactPersonName?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  countryCode?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  createdNfts?: InputMaybe<MintedNftUpdateManyWithoutCreatorUserInput>;
  currencyCode?: InputMaybe<EnumCurrencyCodeFieldUpdateOperationsInput>;
  email?: InputMaybe<StringFieldUpdateOperationsInput>;
  followedBy?: InputMaybe<ChannelFollowerUpdateManyWithoutFolloweeOwnerInput>;
  followedChannels?: InputMaybe<ChannelFollowerUpdateManyWithoutFollowerInput>;
  fullName?: InputMaybe<StringFieldUpdateOperationsInput>;
  giftsSent?: InputMaybe<GiftDistributionUpdateManyWithoutSenderInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  isEmailVerified?: InputMaybe<BoolFieldUpdateOperationsInput>;
  likedVideos?: InputMaybe<LikedVideoUpdateManyWithoutUserInput>;
  mobileCountryCode?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  mobileNumber?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  mongoUserId?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  nftCollections?: InputMaybe<UserNftCollectionsUpdateManyWithoutUserInput>;
  password?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  preferredLanguage?: InputMaybe<NullableEnumCommunicationLanguageFieldUpdateOperationsInput>;
  prestoUserId?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  referralAgentId?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  refreshToken?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  role?: InputMaybe<NullableEnumUserTypeFieldUpdateOperationsInput>;
  selfRegistered?: InputMaybe<BoolFieldUpdateOperationsInput>;
  sendbirdAccessToken?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  status?: InputMaybe<EnumStatusFieldUpdateOperationsInput>;
  subscribeInfoAgreed?: InputMaybe<BoolFieldUpdateOperationsInput>;
  tosAgreed?: InputMaybe<BoolFieldUpdateOperationsInput>;
  transactions?: InputMaybe<TransactionUpdateManyWithoutPaymentByInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  userPayPerViews?: InputMaybe<UserPayPerViewUpdateManyWithoutUserInput>;
  userSubscriptions?: InputMaybe<UserSubscriptionUpdateManyWithoutSubscriberInput>;
  username?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  voucherCode?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  watchHistories?: InputMaybe<WatchHistoryUpdateManyWithoutUserInput>;
};

export type UserUpdateWithoutLikedVideosInput = {
  UserTips?: InputMaybe<TipsUpdateManyWithoutPayerInput>;
  accessToken?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  approvedAt?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  archivedAt?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  avatarUrl?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  bankAccountHolderName?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  bankAccountNumber?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  bankBranchCode?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  bankName?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  bankSwiftCode?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  bannerUrl?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  channel?: InputMaybe<ChannelUpdateOneWithoutUserInput>;
  comments?: InputMaybe<CommentUpdateManyWithoutUserInput>;
  companyAddressCity?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  companyAddressCountry?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  companyAddressLine1?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  companyAddressLine2?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  companyAddressPostcode?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  companyAddressState?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  companyName?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  contactPersonEmail?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  contactPersonMobileNumber?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  contactPersonName?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  countryCode?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  createdNfts?: InputMaybe<MintedNftUpdateManyWithoutCreatorUserInput>;
  currencyCode?: InputMaybe<EnumCurrencyCodeFieldUpdateOperationsInput>;
  email?: InputMaybe<StringFieldUpdateOperationsInput>;
  followedBy?: InputMaybe<ChannelFollowerUpdateManyWithoutFolloweeOwnerInput>;
  followedChannels?: InputMaybe<ChannelFollowerUpdateManyWithoutFollowerInput>;
  fullName?: InputMaybe<StringFieldUpdateOperationsInput>;
  giftsSent?: InputMaybe<GiftDistributionUpdateManyWithoutSenderInput>;
  holdNfts?: InputMaybe<MintedNftUpdateManyWithoutHolderUserInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  isEmailVerified?: InputMaybe<BoolFieldUpdateOperationsInput>;
  mobileCountryCode?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  mobileNumber?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  mongoUserId?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  nftCollections?: InputMaybe<UserNftCollectionsUpdateManyWithoutUserInput>;
  password?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  preferredLanguage?: InputMaybe<NullableEnumCommunicationLanguageFieldUpdateOperationsInput>;
  prestoUserId?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  referralAgentId?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  refreshToken?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  role?: InputMaybe<NullableEnumUserTypeFieldUpdateOperationsInput>;
  selfRegistered?: InputMaybe<BoolFieldUpdateOperationsInput>;
  sendbirdAccessToken?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  status?: InputMaybe<EnumStatusFieldUpdateOperationsInput>;
  subscribeInfoAgreed?: InputMaybe<BoolFieldUpdateOperationsInput>;
  tosAgreed?: InputMaybe<BoolFieldUpdateOperationsInput>;
  transactions?: InputMaybe<TransactionUpdateManyWithoutPaymentByInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  userPayPerViews?: InputMaybe<UserPayPerViewUpdateManyWithoutUserInput>;
  userSubscriptions?: InputMaybe<UserSubscriptionUpdateManyWithoutSubscriberInput>;
  username?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  voucherCode?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  watchHistories?: InputMaybe<WatchHistoryUpdateManyWithoutUserInput>;
};

export type UserUpdateWithoutNftCollectionsInput = {
  UserTips?: InputMaybe<TipsUpdateManyWithoutPayerInput>;
  accessToken?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  approvedAt?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  archivedAt?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  avatarUrl?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  bankAccountHolderName?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  bankAccountNumber?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  bankBranchCode?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  bankName?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  bankSwiftCode?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  bannerUrl?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  channel?: InputMaybe<ChannelUpdateOneWithoutUserInput>;
  comments?: InputMaybe<CommentUpdateManyWithoutUserInput>;
  companyAddressCity?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  companyAddressCountry?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  companyAddressLine1?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  companyAddressLine2?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  companyAddressPostcode?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  companyAddressState?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  companyName?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  contactPersonEmail?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  contactPersonMobileNumber?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  contactPersonName?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  countryCode?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  createdNfts?: InputMaybe<MintedNftUpdateManyWithoutCreatorUserInput>;
  currencyCode?: InputMaybe<EnumCurrencyCodeFieldUpdateOperationsInput>;
  email?: InputMaybe<StringFieldUpdateOperationsInput>;
  followedBy?: InputMaybe<ChannelFollowerUpdateManyWithoutFolloweeOwnerInput>;
  followedChannels?: InputMaybe<ChannelFollowerUpdateManyWithoutFollowerInput>;
  fullName?: InputMaybe<StringFieldUpdateOperationsInput>;
  giftsSent?: InputMaybe<GiftDistributionUpdateManyWithoutSenderInput>;
  holdNfts?: InputMaybe<MintedNftUpdateManyWithoutHolderUserInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  isEmailVerified?: InputMaybe<BoolFieldUpdateOperationsInput>;
  likedVideos?: InputMaybe<LikedVideoUpdateManyWithoutUserInput>;
  mobileCountryCode?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  mobileNumber?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  mongoUserId?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  password?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  preferredLanguage?: InputMaybe<NullableEnumCommunicationLanguageFieldUpdateOperationsInput>;
  prestoUserId?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  referralAgentId?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  refreshToken?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  role?: InputMaybe<NullableEnumUserTypeFieldUpdateOperationsInput>;
  selfRegistered?: InputMaybe<BoolFieldUpdateOperationsInput>;
  sendbirdAccessToken?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  status?: InputMaybe<EnumStatusFieldUpdateOperationsInput>;
  subscribeInfoAgreed?: InputMaybe<BoolFieldUpdateOperationsInput>;
  tosAgreed?: InputMaybe<BoolFieldUpdateOperationsInput>;
  transactions?: InputMaybe<TransactionUpdateManyWithoutPaymentByInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  userPayPerViews?: InputMaybe<UserPayPerViewUpdateManyWithoutUserInput>;
  userSubscriptions?: InputMaybe<UserSubscriptionUpdateManyWithoutSubscriberInput>;
  username?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  voucherCode?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  watchHistories?: InputMaybe<WatchHistoryUpdateManyWithoutUserInput>;
};

export type UserUpdateWithoutTransactionsInput = {
  UserTips?: InputMaybe<TipsUpdateManyWithoutPayerInput>;
  accessToken?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  approvedAt?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  archivedAt?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  avatarUrl?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  bankAccountHolderName?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  bankAccountNumber?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  bankBranchCode?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  bankName?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  bankSwiftCode?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  bannerUrl?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  channel?: InputMaybe<ChannelUpdateOneWithoutUserInput>;
  comments?: InputMaybe<CommentUpdateManyWithoutUserInput>;
  companyAddressCity?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  companyAddressCountry?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  companyAddressLine1?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  companyAddressLine2?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  companyAddressPostcode?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  companyAddressState?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  companyName?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  contactPersonEmail?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  contactPersonMobileNumber?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  contactPersonName?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  countryCode?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  createdNfts?: InputMaybe<MintedNftUpdateManyWithoutCreatorUserInput>;
  currencyCode?: InputMaybe<EnumCurrencyCodeFieldUpdateOperationsInput>;
  email?: InputMaybe<StringFieldUpdateOperationsInput>;
  followedBy?: InputMaybe<ChannelFollowerUpdateManyWithoutFolloweeOwnerInput>;
  followedChannels?: InputMaybe<ChannelFollowerUpdateManyWithoutFollowerInput>;
  fullName?: InputMaybe<StringFieldUpdateOperationsInput>;
  giftsSent?: InputMaybe<GiftDistributionUpdateManyWithoutSenderInput>;
  holdNfts?: InputMaybe<MintedNftUpdateManyWithoutHolderUserInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  isEmailVerified?: InputMaybe<BoolFieldUpdateOperationsInput>;
  likedVideos?: InputMaybe<LikedVideoUpdateManyWithoutUserInput>;
  mobileCountryCode?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  mobileNumber?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  mongoUserId?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  nftCollections?: InputMaybe<UserNftCollectionsUpdateManyWithoutUserInput>;
  password?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  preferredLanguage?: InputMaybe<NullableEnumCommunicationLanguageFieldUpdateOperationsInput>;
  prestoUserId?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  referralAgentId?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  refreshToken?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  role?: InputMaybe<NullableEnumUserTypeFieldUpdateOperationsInput>;
  selfRegistered?: InputMaybe<BoolFieldUpdateOperationsInput>;
  sendbirdAccessToken?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  status?: InputMaybe<EnumStatusFieldUpdateOperationsInput>;
  subscribeInfoAgreed?: InputMaybe<BoolFieldUpdateOperationsInput>;
  tosAgreed?: InputMaybe<BoolFieldUpdateOperationsInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  userPayPerViews?: InputMaybe<UserPayPerViewUpdateManyWithoutUserInput>;
  userSubscriptions?: InputMaybe<UserSubscriptionUpdateManyWithoutSubscriberInput>;
  username?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  voucherCode?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  watchHistories?: InputMaybe<WatchHistoryUpdateManyWithoutUserInput>;
};

export type UserUpdateWithoutUserPayPerViewsInput = {
  UserTips?: InputMaybe<TipsUpdateManyWithoutPayerInput>;
  accessToken?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  approvedAt?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  archivedAt?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  avatarUrl?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  bankAccountHolderName?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  bankAccountNumber?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  bankBranchCode?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  bankName?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  bankSwiftCode?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  bannerUrl?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  channel?: InputMaybe<ChannelUpdateOneWithoutUserInput>;
  comments?: InputMaybe<CommentUpdateManyWithoutUserInput>;
  companyAddressCity?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  companyAddressCountry?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  companyAddressLine1?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  companyAddressLine2?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  companyAddressPostcode?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  companyAddressState?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  companyName?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  contactPersonEmail?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  contactPersonMobileNumber?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  contactPersonName?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  countryCode?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  createdNfts?: InputMaybe<MintedNftUpdateManyWithoutCreatorUserInput>;
  currencyCode?: InputMaybe<EnumCurrencyCodeFieldUpdateOperationsInput>;
  email?: InputMaybe<StringFieldUpdateOperationsInput>;
  followedBy?: InputMaybe<ChannelFollowerUpdateManyWithoutFolloweeOwnerInput>;
  followedChannels?: InputMaybe<ChannelFollowerUpdateManyWithoutFollowerInput>;
  fullName?: InputMaybe<StringFieldUpdateOperationsInput>;
  giftsSent?: InputMaybe<GiftDistributionUpdateManyWithoutSenderInput>;
  holdNfts?: InputMaybe<MintedNftUpdateManyWithoutHolderUserInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  isEmailVerified?: InputMaybe<BoolFieldUpdateOperationsInput>;
  likedVideos?: InputMaybe<LikedVideoUpdateManyWithoutUserInput>;
  mobileCountryCode?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  mobileNumber?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  mongoUserId?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  nftCollections?: InputMaybe<UserNftCollectionsUpdateManyWithoutUserInput>;
  password?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  preferredLanguage?: InputMaybe<NullableEnumCommunicationLanguageFieldUpdateOperationsInput>;
  prestoUserId?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  referralAgentId?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  refreshToken?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  role?: InputMaybe<NullableEnumUserTypeFieldUpdateOperationsInput>;
  selfRegistered?: InputMaybe<BoolFieldUpdateOperationsInput>;
  sendbirdAccessToken?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  status?: InputMaybe<EnumStatusFieldUpdateOperationsInput>;
  subscribeInfoAgreed?: InputMaybe<BoolFieldUpdateOperationsInput>;
  tosAgreed?: InputMaybe<BoolFieldUpdateOperationsInput>;
  transactions?: InputMaybe<TransactionUpdateManyWithoutPaymentByInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  userSubscriptions?: InputMaybe<UserSubscriptionUpdateManyWithoutSubscriberInput>;
  username?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  voucherCode?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  watchHistories?: InputMaybe<WatchHistoryUpdateManyWithoutUserInput>;
};

export type UserUpdateWithoutUserSubscriptionsInput = {
  UserTips?: InputMaybe<TipsUpdateManyWithoutPayerInput>;
  accessToken?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  approvedAt?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  archivedAt?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  avatarUrl?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  bankAccountHolderName?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  bankAccountNumber?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  bankBranchCode?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  bankName?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  bankSwiftCode?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  bannerUrl?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  channel?: InputMaybe<ChannelUpdateOneWithoutUserInput>;
  comments?: InputMaybe<CommentUpdateManyWithoutUserInput>;
  companyAddressCity?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  companyAddressCountry?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  companyAddressLine1?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  companyAddressLine2?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  companyAddressPostcode?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  companyAddressState?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  companyName?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  contactPersonEmail?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  contactPersonMobileNumber?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  contactPersonName?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  countryCode?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  createdNfts?: InputMaybe<MintedNftUpdateManyWithoutCreatorUserInput>;
  currencyCode?: InputMaybe<EnumCurrencyCodeFieldUpdateOperationsInput>;
  email?: InputMaybe<StringFieldUpdateOperationsInput>;
  followedBy?: InputMaybe<ChannelFollowerUpdateManyWithoutFolloweeOwnerInput>;
  followedChannels?: InputMaybe<ChannelFollowerUpdateManyWithoutFollowerInput>;
  fullName?: InputMaybe<StringFieldUpdateOperationsInput>;
  giftsSent?: InputMaybe<GiftDistributionUpdateManyWithoutSenderInput>;
  holdNfts?: InputMaybe<MintedNftUpdateManyWithoutHolderUserInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  isEmailVerified?: InputMaybe<BoolFieldUpdateOperationsInput>;
  likedVideos?: InputMaybe<LikedVideoUpdateManyWithoutUserInput>;
  mobileCountryCode?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  mobileNumber?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  mongoUserId?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  nftCollections?: InputMaybe<UserNftCollectionsUpdateManyWithoutUserInput>;
  password?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  preferredLanguage?: InputMaybe<NullableEnumCommunicationLanguageFieldUpdateOperationsInput>;
  prestoUserId?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  referralAgentId?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  refreshToken?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  role?: InputMaybe<NullableEnumUserTypeFieldUpdateOperationsInput>;
  selfRegistered?: InputMaybe<BoolFieldUpdateOperationsInput>;
  sendbirdAccessToken?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  status?: InputMaybe<EnumStatusFieldUpdateOperationsInput>;
  subscribeInfoAgreed?: InputMaybe<BoolFieldUpdateOperationsInput>;
  tosAgreed?: InputMaybe<BoolFieldUpdateOperationsInput>;
  transactions?: InputMaybe<TransactionUpdateManyWithoutPaymentByInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  userPayPerViews?: InputMaybe<UserPayPerViewUpdateManyWithoutUserInput>;
  username?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  voucherCode?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  watchHistories?: InputMaybe<WatchHistoryUpdateManyWithoutUserInput>;
};

export type UserUpdateWithoutUserTipsInput = {
  accessToken?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  approvedAt?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  archivedAt?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  avatarUrl?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  bankAccountHolderName?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  bankAccountNumber?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  bankBranchCode?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  bankName?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  bankSwiftCode?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  bannerUrl?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  channel?: InputMaybe<ChannelUpdateOneWithoutUserInput>;
  comments?: InputMaybe<CommentUpdateManyWithoutUserInput>;
  companyAddressCity?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  companyAddressCountry?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  companyAddressLine1?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  companyAddressLine2?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  companyAddressPostcode?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  companyAddressState?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  companyName?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  contactPersonEmail?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  contactPersonMobileNumber?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  contactPersonName?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  countryCode?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  createdNfts?: InputMaybe<MintedNftUpdateManyWithoutCreatorUserInput>;
  currencyCode?: InputMaybe<EnumCurrencyCodeFieldUpdateOperationsInput>;
  email?: InputMaybe<StringFieldUpdateOperationsInput>;
  followedBy?: InputMaybe<ChannelFollowerUpdateManyWithoutFolloweeOwnerInput>;
  followedChannels?: InputMaybe<ChannelFollowerUpdateManyWithoutFollowerInput>;
  fullName?: InputMaybe<StringFieldUpdateOperationsInput>;
  giftsSent?: InputMaybe<GiftDistributionUpdateManyWithoutSenderInput>;
  holdNfts?: InputMaybe<MintedNftUpdateManyWithoutHolderUserInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  isEmailVerified?: InputMaybe<BoolFieldUpdateOperationsInput>;
  likedVideos?: InputMaybe<LikedVideoUpdateManyWithoutUserInput>;
  mobileCountryCode?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  mobileNumber?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  mongoUserId?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  nftCollections?: InputMaybe<UserNftCollectionsUpdateManyWithoutUserInput>;
  password?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  preferredLanguage?: InputMaybe<NullableEnumCommunicationLanguageFieldUpdateOperationsInput>;
  prestoUserId?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  referralAgentId?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  refreshToken?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  role?: InputMaybe<NullableEnumUserTypeFieldUpdateOperationsInput>;
  selfRegistered?: InputMaybe<BoolFieldUpdateOperationsInput>;
  sendbirdAccessToken?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  status?: InputMaybe<EnumStatusFieldUpdateOperationsInput>;
  subscribeInfoAgreed?: InputMaybe<BoolFieldUpdateOperationsInput>;
  tosAgreed?: InputMaybe<BoolFieldUpdateOperationsInput>;
  transactions?: InputMaybe<TransactionUpdateManyWithoutPaymentByInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  userPayPerViews?: InputMaybe<UserPayPerViewUpdateManyWithoutUserInput>;
  userSubscriptions?: InputMaybe<UserSubscriptionUpdateManyWithoutSubscriberInput>;
  username?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  voucherCode?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  watchHistories?: InputMaybe<WatchHistoryUpdateManyWithoutUserInput>;
};

export type UserUpdateWithoutWatchHistoriesInput = {
  UserTips?: InputMaybe<TipsUpdateManyWithoutPayerInput>;
  accessToken?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  approvedAt?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  archivedAt?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  avatarUrl?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  bankAccountHolderName?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  bankAccountNumber?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  bankBranchCode?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  bankName?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  bankSwiftCode?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  bannerUrl?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  channel?: InputMaybe<ChannelUpdateOneWithoutUserInput>;
  comments?: InputMaybe<CommentUpdateManyWithoutUserInput>;
  companyAddressCity?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  companyAddressCountry?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  companyAddressLine1?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  companyAddressLine2?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  companyAddressPostcode?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  companyAddressState?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  companyName?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  contactPersonEmail?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  contactPersonMobileNumber?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  contactPersonName?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  countryCode?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  createdNfts?: InputMaybe<MintedNftUpdateManyWithoutCreatorUserInput>;
  currencyCode?: InputMaybe<EnumCurrencyCodeFieldUpdateOperationsInput>;
  email?: InputMaybe<StringFieldUpdateOperationsInput>;
  followedBy?: InputMaybe<ChannelFollowerUpdateManyWithoutFolloweeOwnerInput>;
  followedChannels?: InputMaybe<ChannelFollowerUpdateManyWithoutFollowerInput>;
  fullName?: InputMaybe<StringFieldUpdateOperationsInput>;
  giftsSent?: InputMaybe<GiftDistributionUpdateManyWithoutSenderInput>;
  holdNfts?: InputMaybe<MintedNftUpdateManyWithoutHolderUserInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  isEmailVerified?: InputMaybe<BoolFieldUpdateOperationsInput>;
  likedVideos?: InputMaybe<LikedVideoUpdateManyWithoutUserInput>;
  mobileCountryCode?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  mobileNumber?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  mongoUserId?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  nftCollections?: InputMaybe<UserNftCollectionsUpdateManyWithoutUserInput>;
  password?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  preferredLanguage?: InputMaybe<NullableEnumCommunicationLanguageFieldUpdateOperationsInput>;
  prestoUserId?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  referralAgentId?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  refreshToken?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  role?: InputMaybe<NullableEnumUserTypeFieldUpdateOperationsInput>;
  selfRegistered?: InputMaybe<BoolFieldUpdateOperationsInput>;
  sendbirdAccessToken?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  status?: InputMaybe<EnumStatusFieldUpdateOperationsInput>;
  subscribeInfoAgreed?: InputMaybe<BoolFieldUpdateOperationsInput>;
  tosAgreed?: InputMaybe<BoolFieldUpdateOperationsInput>;
  transactions?: InputMaybe<TransactionUpdateManyWithoutPaymentByInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  userPayPerViews?: InputMaybe<UserPayPerViewUpdateManyWithoutUserInput>;
  userSubscriptions?: InputMaybe<UserSubscriptionUpdateManyWithoutSubscriberInput>;
  username?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  voucherCode?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
};

export type UserUpsertWithoutChannelInput = {
  create: UserCreateWithoutChannelInput;
  update: UserUpdateWithoutChannelInput;
};

export type UserUpsertWithoutCommentsInput = {
  create: UserCreateWithoutCommentsInput;
  update: UserUpdateWithoutCommentsInput;
};

export type UserUpsertWithoutCreatedNftsInput = {
  create: UserCreateWithoutCreatedNftsInput;
  update: UserUpdateWithoutCreatedNftsInput;
};

export type UserUpsertWithoutFollowedByInput = {
  create: UserCreateWithoutFollowedByInput;
  update: UserUpdateWithoutFollowedByInput;
};

export type UserUpsertWithoutFollowedChannelsInput = {
  create: UserCreateWithoutFollowedChannelsInput;
  update: UserUpdateWithoutFollowedChannelsInput;
};

export type UserUpsertWithoutGiftsSentInput = {
  create: UserCreateWithoutGiftsSentInput;
  update: UserUpdateWithoutGiftsSentInput;
};

export type UserUpsertWithoutHoldNftsInput = {
  create: UserCreateWithoutHoldNftsInput;
  update: UserUpdateWithoutHoldNftsInput;
};

export type UserUpsertWithoutLikedVideosInput = {
  create: UserCreateWithoutLikedVideosInput;
  update: UserUpdateWithoutLikedVideosInput;
};

export type UserUpsertWithoutNftCollectionsInput = {
  create: UserCreateWithoutNftCollectionsInput;
  update: UserUpdateWithoutNftCollectionsInput;
};

export type UserUpsertWithoutTransactionsInput = {
  create: UserCreateWithoutTransactionsInput;
  update: UserUpdateWithoutTransactionsInput;
};

export type UserUpsertWithoutUserPayPerViewsInput = {
  create: UserCreateWithoutUserPayPerViewsInput;
  update: UserUpdateWithoutUserPayPerViewsInput;
};

export type UserUpsertWithoutUserSubscriptionsInput = {
  create: UserCreateWithoutUserSubscriptionsInput;
  update: UserUpdateWithoutUserSubscriptionsInput;
};

export type UserUpsertWithoutUserTipsInput = {
  create: UserCreateWithoutUserTipsInput;
  update: UserUpdateWithoutUserTipsInput;
};

export type UserUpsertWithoutWatchHistoriesInput = {
  create: UserCreateWithoutWatchHistoriesInput;
  update: UserUpdateWithoutWatchHistoriesInput;
};

export type UserWhereInput = {
  AND?: InputMaybe<Array<UserWhereInput>>;
  NOT?: InputMaybe<Array<UserWhereInput>>;
  OR?: InputMaybe<Array<UserWhereInput>>;
  UserTips?: InputMaybe<TipsListRelationFilter>;
  accessToken?: InputMaybe<StringNullableFilter>;
  approvedAt?: InputMaybe<DateTimeNullableFilter>;
  archivedAt?: InputMaybe<DateTimeNullableFilter>;
  avatarUrl?: InputMaybe<StringNullableFilter>;
  bankAccountHolderName?: InputMaybe<StringNullableFilter>;
  bankAccountNumber?: InputMaybe<StringNullableFilter>;
  bankBranchCode?: InputMaybe<StringNullableFilter>;
  bankName?: InputMaybe<StringNullableFilter>;
  bankSwiftCode?: InputMaybe<StringNullableFilter>;
  bannerUrl?: InputMaybe<StringNullableFilter>;
  channel?: InputMaybe<ChannelRelationFilter>;
  comments?: InputMaybe<CommentListRelationFilter>;
  companyAddressCity?: InputMaybe<StringNullableFilter>;
  companyAddressCountry?: InputMaybe<StringNullableFilter>;
  companyAddressLine1?: InputMaybe<StringNullableFilter>;
  companyAddressLine2?: InputMaybe<StringNullableFilter>;
  companyAddressPostcode?: InputMaybe<StringNullableFilter>;
  companyAddressState?: InputMaybe<StringNullableFilter>;
  companyName?: InputMaybe<StringNullableFilter>;
  contactPersonEmail?: InputMaybe<StringNullableFilter>;
  contactPersonMobileNumber?: InputMaybe<StringNullableFilter>;
  contactPersonName?: InputMaybe<StringNullableFilter>;
  countryCode?: InputMaybe<StringNullableFilter>;
  createdAt?: InputMaybe<DateTimeFilter>;
  createdNfts?: InputMaybe<MintedNftListRelationFilter>;
  currencyCode?: InputMaybe<EnumCurrencyCodeFilter>;
  email?: InputMaybe<StringFilter>;
  followedBy?: InputMaybe<ChannelFollowerListRelationFilter>;
  followedChannels?: InputMaybe<ChannelFollowerListRelationFilter>;
  fullName?: InputMaybe<StringFilter>;
  giftsSent?: InputMaybe<GiftDistributionListRelationFilter>;
  holdNfts?: InputMaybe<MintedNftListRelationFilter>;
  id?: InputMaybe<StringFilter>;
  isEmailVerified?: InputMaybe<BoolFilter>;
  likedVideos?: InputMaybe<LikedVideoListRelationFilter>;
  mobileCountryCode?: InputMaybe<StringNullableFilter>;
  mobileNumber?: InputMaybe<StringNullableFilter>;
  mongoUserId?: InputMaybe<StringNullableFilter>;
  nftCollections?: InputMaybe<UserNftCollectionsListRelationFilter>;
  password?: InputMaybe<StringNullableFilter>;
  preferredLanguage?: InputMaybe<EnumCommunicationLanguageNullableFilter>;
  prestoUserId?: InputMaybe<StringNullableFilter>;
  referralAgentId?: InputMaybe<StringNullableFilter>;
  refreshToken?: InputMaybe<StringNullableFilter>;
  role?: InputMaybe<EnumUserTypeNullableFilter>;
  selfRegistered?: InputMaybe<BoolFilter>;
  sendbirdAccessToken?: InputMaybe<StringNullableFilter>;
  status?: InputMaybe<EnumStatusFilter>;
  subscribeInfoAgreed?: InputMaybe<BoolFilter>;
  tosAgreed?: InputMaybe<BoolFilter>;
  transactions?: InputMaybe<TransactionListRelationFilter>;
  updatedAt?: InputMaybe<DateTimeFilter>;
  userPayPerViews?: InputMaybe<UserPayPerViewListRelationFilter>;
  userSubscriptions?: InputMaybe<UserSubscriptionListRelationFilter>;
  username?: InputMaybe<StringNullableFilter>;
  voucherCode?: InputMaybe<StringNullableFilter>;
  watchHistories?: InputMaybe<WatchHistoryListRelationFilter>;
};

export type UserWhereUniqueInput = {
  email?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['String']>;
  mongoUserId?: InputMaybe<Scalars['String']>;
  prestoUserId?: InputMaybe<Scalars['String']>;
  username?: InputMaybe<Scalars['String']>;
};

export enum VerificationPurpose {
  EmailVerification = 'EMAIL_VERIFICATION',
  PasswordReset = 'PASSWORD_RESET'
}

export type VerifyOtpInput = {
  email: Scalars['String'];
  isResend?: InputMaybe<Scalars['Boolean']>;
  otpCode: Scalars['String'];
  purpose: VerificationPurpose;
};

export type VerifyOtpResponse = {
  __typename?: 'VerifyOtpResponse';
  success: Scalars['Boolean'];
};

export type Video = {
  __typename?: 'Video';
  _count?: Maybe<VideoCount>;
  archivedAt?: Maybe<Scalars['DateTime']>;
  assetId?: Maybe<Scalars['String']>;
  channel: Channel;
  channelId: Scalars['String'];
  comments: Array<Comment>;
  coverThumbnailUrl?: Maybe<Scalars['String']>;
  createdAt: Scalars['DateTime'];
  createdTimeFromNow: Scalars['String'];
  description?: Maybe<Scalars['String']>;
  displayPrice: Scalars['String'];
  duration: Scalars['Float'];
  genre: Scalars['String'];
  giftDistributions: Array<GiftDistribution>;
  hasCommentedOnVideo: Scalars['Boolean'];
  hasGiftedVideo: Scalars['Boolean'];
  hasLikedVideo: Scalars['Boolean'];
  hasPaidForVideo: Scalars['Boolean'];
  height?: Maybe<Scalars['Int']>;
  id: Scalars['String'];
  isPayPerView: Scalars['Boolean'];
  isVertical: Scalars['Boolean'];
  language: Scalars['String'];
  likedVideos: Array<LikedVideo>;
  payPerViewPrice?: Maybe<Scalars['Float']>;
  playbackUrl?: Maybe<Scalars['String']>;
  playlistVideos: Array<PlaylistVideo>;
  /** Presentment price of the gift in currency charged by payment gateway */
  presentmentMoney: PresentmentMoney;
  productUrl?: Maybe<Scalars['String']>;
  rating: VideoRating;
  scheduleEnd?: Maybe<Scalars['DateTime']>;
  scheduleStart?: Maybe<Scalars['DateTime']>;
  shortId?: Maybe<Scalars['String']>;
  statistics: Array<VideoStatistic>;
  status: VideoStatus;
  subtitleLanguage?: Maybe<Scalars['String']>;
  subtitleUrl?: Maybe<Scalars['String']>;
  thumbnailUrl?: Maybe<Scalars['String']>;
  title: Scalars['String'];
  totalCommentCount: Scalars['Int'];
  totalGiftCount: Scalars['Int'];
  totalLikeCount: Scalars['Int'];
  totalViewCount: Scalars['Int'];
  updatedAt: Scalars['DateTime'];
  uploadId?: Maybe<Scalars['String']>;
  userPayPerView: Array<UserPayPerView>;
  watchHistories: Array<WatchHistory>;
  width?: Maybe<Scalars['Int']>;
};


export type VideoCommentsArgs = {
  cursor?: InputMaybe<CommentWhereUniqueInput>;
  distinct?: InputMaybe<Array<CommentScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<CommentOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<CommentWhereInput>;
};


export type VideoGiftDistributionsArgs = {
  cursor?: InputMaybe<GiftDistributionWhereUniqueInput>;
  distinct?: InputMaybe<Array<GiftDistributionScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<GiftDistributionOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<GiftDistributionWhereInput>;
};


export type VideoLikedVideosArgs = {
  cursor?: InputMaybe<LikedVideoWhereUniqueInput>;
  distinct?: InputMaybe<Array<LikedVideoScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<LikedVideoOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<LikedVideoWhereInput>;
};


export type VideoPlaylistVideosArgs = {
  cursor?: InputMaybe<PlaylistVideoWhereUniqueInput>;
  distinct?: InputMaybe<Array<PlaylistVideoScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<PlaylistVideoOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<PlaylistVideoWhereInput>;
};


export type VideoStatisticsArgs = {
  cursor?: InputMaybe<VideoStatisticWhereUniqueInput>;
  distinct?: InputMaybe<Array<VideoStatisticScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<VideoStatisticOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<VideoStatisticWhereInput>;
};


export type VideoUserPayPerViewArgs = {
  cursor?: InputMaybe<UserPayPerViewWhereUniqueInput>;
  distinct?: InputMaybe<Array<UserPayPerViewScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<UserPayPerViewOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<UserPayPerViewWhereInput>;
};


export type VideoWatchHistoriesArgs = {
  cursor?: InputMaybe<WatchHistoryWhereUniqueInput>;
  distinct?: InputMaybe<Array<WatchHistoryScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<WatchHistoryOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<WatchHistoryWhereInput>;
};

export type VideoAvgAggregate = {
  __typename?: 'VideoAvgAggregate';
  duration?: Maybe<Scalars['Float']>;
  height?: Maybe<Scalars['Float']>;
  payPerViewPrice?: Maybe<Scalars['Float']>;
  totalCommentCount?: Maybe<Scalars['Float']>;
  totalGiftCount?: Maybe<Scalars['Float']>;
  totalLikeCount?: Maybe<Scalars['Float']>;
  totalViewCount?: Maybe<Scalars['Float']>;
  width?: Maybe<Scalars['Float']>;
};

export type VideoAvgOrderByAggregateInput = {
  duration?: InputMaybe<SortOrder>;
  height?: InputMaybe<SortOrder>;
  payPerViewPrice?: InputMaybe<SortOrder>;
  totalCommentCount?: InputMaybe<SortOrder>;
  totalGiftCount?: InputMaybe<SortOrder>;
  totalLikeCount?: InputMaybe<SortOrder>;
  totalViewCount?: InputMaybe<SortOrder>;
  width?: InputMaybe<SortOrder>;
};

export type VideoCount = {
  __typename?: 'VideoCount';
  comments: Scalars['Int'];
  giftDistributions: Scalars['Int'];
  likedVideos: Scalars['Int'];
  playlistVideos: Scalars['Int'];
  statistics: Scalars['Int'];
  userPayPerView: Scalars['Int'];
  watchHistories: Scalars['Int'];
};

export type VideoCountAggregate = {
  __typename?: 'VideoCountAggregate';
  _all: Scalars['Int'];
  archivedAt: Scalars['Int'];
  assetId: Scalars['Int'];
  channelId: Scalars['Int'];
  coverThumbnailUrl: Scalars['Int'];
  createdAt: Scalars['Int'];
  description: Scalars['Int'];
  duration: Scalars['Int'];
  genre: Scalars['Int'];
  height: Scalars['Int'];
  id: Scalars['Int'];
  isPayPerView: Scalars['Int'];
  isVertical: Scalars['Int'];
  language: Scalars['Int'];
  payPerViewPrice: Scalars['Int'];
  playbackUrl: Scalars['Int'];
  productUrl: Scalars['Int'];
  rating: Scalars['Int'];
  scheduleEnd: Scalars['Int'];
  scheduleStart: Scalars['Int'];
  shortId: Scalars['Int'];
  status: Scalars['Int'];
  subtitleLanguage: Scalars['Int'];
  subtitleUrl: Scalars['Int'];
  thumbnailUrl: Scalars['Int'];
  title: Scalars['Int'];
  totalCommentCount: Scalars['Int'];
  totalGiftCount: Scalars['Int'];
  totalLikeCount: Scalars['Int'];
  totalViewCount: Scalars['Int'];
  updatedAt: Scalars['Int'];
  uploadId: Scalars['Int'];
  width: Scalars['Int'];
};

export type VideoCountOrderByAggregateInput = {
  archivedAt?: InputMaybe<SortOrder>;
  assetId?: InputMaybe<SortOrder>;
  channelId?: InputMaybe<SortOrder>;
  coverThumbnailUrl?: InputMaybe<SortOrder>;
  createdAt?: InputMaybe<SortOrder>;
  description?: InputMaybe<SortOrder>;
  duration?: InputMaybe<SortOrder>;
  genre?: InputMaybe<SortOrder>;
  height?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  isPayPerView?: InputMaybe<SortOrder>;
  isVertical?: InputMaybe<SortOrder>;
  language?: InputMaybe<SortOrder>;
  payPerViewPrice?: InputMaybe<SortOrder>;
  playbackUrl?: InputMaybe<SortOrder>;
  productUrl?: InputMaybe<SortOrder>;
  rating?: InputMaybe<SortOrder>;
  scheduleEnd?: InputMaybe<SortOrder>;
  scheduleStart?: InputMaybe<SortOrder>;
  shortId?: InputMaybe<SortOrder>;
  status?: InputMaybe<SortOrder>;
  subtitleLanguage?: InputMaybe<SortOrder>;
  subtitleUrl?: InputMaybe<SortOrder>;
  thumbnailUrl?: InputMaybe<SortOrder>;
  title?: InputMaybe<SortOrder>;
  totalCommentCount?: InputMaybe<SortOrder>;
  totalGiftCount?: InputMaybe<SortOrder>;
  totalLikeCount?: InputMaybe<SortOrder>;
  totalViewCount?: InputMaybe<SortOrder>;
  updatedAt?: InputMaybe<SortOrder>;
  uploadId?: InputMaybe<SortOrder>;
  width?: InputMaybe<SortOrder>;
};

export type VideoCreateInput = {
  archivedAt?: InputMaybe<Scalars['DateTime']>;
  assetId?: InputMaybe<Scalars['String']>;
  channel: ChannelCreateNestedOneWithoutVideosInput;
  comments?: InputMaybe<CommentCreateNestedManyWithoutVideoInput>;
  coverThumbnailUrl?: InputMaybe<Scalars['String']>;
  createdAt?: InputMaybe<Scalars['DateTime']>;
  description?: InputMaybe<Scalars['String']>;
  duration?: InputMaybe<Scalars['Float']>;
  genre: Scalars['String'];
  giftDistributions?: InputMaybe<GiftDistributionCreateNestedManyWithoutVideoInput>;
  height?: InputMaybe<Scalars['Int']>;
  id?: InputMaybe<Scalars['String']>;
  isPayPerView?: InputMaybe<Scalars['Boolean']>;
  isVertical?: InputMaybe<Scalars['Boolean']>;
  language: Scalars['String'];
  likedVideos?: InputMaybe<LikedVideoCreateNestedManyWithoutVideoInput>;
  payPerViewPrice?: InputMaybe<Scalars['Float']>;
  playbackUrl?: InputMaybe<Scalars['String']>;
  playlistVideos?: InputMaybe<PlaylistVideoCreateNestedManyWithoutVideoInput>;
  productUrl?: InputMaybe<Scalars['String']>;
  rating: VideoRating;
  scheduleEnd?: InputMaybe<Scalars['DateTime']>;
  scheduleStart?: InputMaybe<Scalars['DateTime']>;
  shortId?: InputMaybe<Scalars['String']>;
  statistics?: InputMaybe<VideoStatisticCreateNestedManyWithoutVideoInput>;
  status?: InputMaybe<VideoStatus>;
  subtitleLanguage?: InputMaybe<Scalars['String']>;
  subtitleUrl?: InputMaybe<Scalars['String']>;
  thumbnailUrl?: InputMaybe<Scalars['String']>;
  title: Scalars['String'];
  totalCommentCount?: InputMaybe<Scalars['Int']>;
  totalGiftCount?: InputMaybe<Scalars['Int']>;
  totalLikeCount?: InputMaybe<Scalars['Int']>;
  totalViewCount?: InputMaybe<Scalars['Int']>;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
  uploadId?: InputMaybe<Scalars['String']>;
  userPayPerView?: InputMaybe<UserPayPerViewCreateNestedManyWithoutVideoInput>;
  watchHistories?: InputMaybe<WatchHistoryCreateNestedManyWithoutVideoInput>;
  width?: InputMaybe<Scalars['Int']>;
};

export type VideoCreateManyChannelInput = {
  archivedAt?: InputMaybe<Scalars['DateTime']>;
  assetId?: InputMaybe<Scalars['String']>;
  coverThumbnailUrl?: InputMaybe<Scalars['String']>;
  createdAt?: InputMaybe<Scalars['DateTime']>;
  description?: InputMaybe<Scalars['String']>;
  duration?: InputMaybe<Scalars['Float']>;
  genre: Scalars['String'];
  height?: InputMaybe<Scalars['Int']>;
  id?: InputMaybe<Scalars['String']>;
  isPayPerView?: InputMaybe<Scalars['Boolean']>;
  isVertical?: InputMaybe<Scalars['Boolean']>;
  language: Scalars['String'];
  payPerViewPrice?: InputMaybe<Scalars['Float']>;
  playbackUrl?: InputMaybe<Scalars['String']>;
  productUrl?: InputMaybe<Scalars['String']>;
  rating: VideoRating;
  scheduleEnd?: InputMaybe<Scalars['DateTime']>;
  scheduleStart?: InputMaybe<Scalars['DateTime']>;
  shortId?: InputMaybe<Scalars['String']>;
  status?: InputMaybe<VideoStatus>;
  subtitleLanguage?: InputMaybe<Scalars['String']>;
  subtitleUrl?: InputMaybe<Scalars['String']>;
  thumbnailUrl?: InputMaybe<Scalars['String']>;
  title: Scalars['String'];
  totalCommentCount?: InputMaybe<Scalars['Int']>;
  totalGiftCount?: InputMaybe<Scalars['Int']>;
  totalLikeCount?: InputMaybe<Scalars['Int']>;
  totalViewCount?: InputMaybe<Scalars['Int']>;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
  uploadId?: InputMaybe<Scalars['String']>;
  width?: InputMaybe<Scalars['Int']>;
};

export type VideoCreateManyChannelInputEnvelope = {
  data: Array<VideoCreateManyChannelInput>;
  skipDuplicates?: InputMaybe<Scalars['Boolean']>;
};

export type VideoCreateManyInput = {
  archivedAt?: InputMaybe<Scalars['DateTime']>;
  assetId?: InputMaybe<Scalars['String']>;
  channelId: Scalars['String'];
  coverThumbnailUrl?: InputMaybe<Scalars['String']>;
  createdAt?: InputMaybe<Scalars['DateTime']>;
  description?: InputMaybe<Scalars['String']>;
  duration?: InputMaybe<Scalars['Float']>;
  genre: Scalars['String'];
  height?: InputMaybe<Scalars['Int']>;
  id?: InputMaybe<Scalars['String']>;
  isPayPerView?: InputMaybe<Scalars['Boolean']>;
  isVertical?: InputMaybe<Scalars['Boolean']>;
  language: Scalars['String'];
  payPerViewPrice?: InputMaybe<Scalars['Float']>;
  playbackUrl?: InputMaybe<Scalars['String']>;
  productUrl?: InputMaybe<Scalars['String']>;
  rating: VideoRating;
  scheduleEnd?: InputMaybe<Scalars['DateTime']>;
  scheduleStart?: InputMaybe<Scalars['DateTime']>;
  shortId?: InputMaybe<Scalars['String']>;
  status?: InputMaybe<VideoStatus>;
  subtitleLanguage?: InputMaybe<Scalars['String']>;
  subtitleUrl?: InputMaybe<Scalars['String']>;
  thumbnailUrl?: InputMaybe<Scalars['String']>;
  title: Scalars['String'];
  totalCommentCount?: InputMaybe<Scalars['Int']>;
  totalGiftCount?: InputMaybe<Scalars['Int']>;
  totalLikeCount?: InputMaybe<Scalars['Int']>;
  totalViewCount?: InputMaybe<Scalars['Int']>;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
  uploadId?: InputMaybe<Scalars['String']>;
  width?: InputMaybe<Scalars['Int']>;
};

export type VideoCreateNestedManyWithoutChannelInput = {
  connect?: InputMaybe<Array<VideoWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<VideoCreateOrConnectWithoutChannelInput>>;
  create?: InputMaybe<Array<VideoCreateWithoutChannelInput>>;
  createMany?: InputMaybe<VideoCreateManyChannelInputEnvelope>;
};

export type VideoCreateNestedOneWithoutCommentsInput = {
  connect?: InputMaybe<VideoWhereUniqueInput>;
  connectOrCreate?: InputMaybe<VideoCreateOrConnectWithoutCommentsInput>;
  create?: InputMaybe<VideoCreateWithoutCommentsInput>;
};

export type VideoCreateNestedOneWithoutGiftDistributionsInput = {
  connect?: InputMaybe<VideoWhereUniqueInput>;
  connectOrCreate?: InputMaybe<VideoCreateOrConnectWithoutGiftDistributionsInput>;
  create?: InputMaybe<VideoCreateWithoutGiftDistributionsInput>;
};

export type VideoCreateNestedOneWithoutLikedVideosInput = {
  connect?: InputMaybe<VideoWhereUniqueInput>;
  connectOrCreate?: InputMaybe<VideoCreateOrConnectWithoutLikedVideosInput>;
  create?: InputMaybe<VideoCreateWithoutLikedVideosInput>;
};

export type VideoCreateNestedOneWithoutPlaylistVideosInput = {
  connect?: InputMaybe<VideoWhereUniqueInput>;
  connectOrCreate?: InputMaybe<VideoCreateOrConnectWithoutPlaylistVideosInput>;
  create?: InputMaybe<VideoCreateWithoutPlaylistVideosInput>;
};

export type VideoCreateNestedOneWithoutStatisticsInput = {
  connect?: InputMaybe<VideoWhereUniqueInput>;
  connectOrCreate?: InputMaybe<VideoCreateOrConnectWithoutStatisticsInput>;
  create?: InputMaybe<VideoCreateWithoutStatisticsInput>;
};

export type VideoCreateNestedOneWithoutUserPayPerViewInput = {
  connect?: InputMaybe<VideoWhereUniqueInput>;
  connectOrCreate?: InputMaybe<VideoCreateOrConnectWithoutUserPayPerViewInput>;
  create?: InputMaybe<VideoCreateWithoutUserPayPerViewInput>;
};

export type VideoCreateNestedOneWithoutWatchHistoriesInput = {
  connect?: InputMaybe<VideoWhereUniqueInput>;
  connectOrCreate?: InputMaybe<VideoCreateOrConnectWithoutWatchHistoriesInput>;
  create?: InputMaybe<VideoCreateWithoutWatchHistoriesInput>;
};

export type VideoCreateOrConnectWithoutChannelInput = {
  create: VideoCreateWithoutChannelInput;
  where: VideoWhereUniqueInput;
};

export type VideoCreateOrConnectWithoutCommentsInput = {
  create: VideoCreateWithoutCommentsInput;
  where: VideoWhereUniqueInput;
};

export type VideoCreateOrConnectWithoutGiftDistributionsInput = {
  create: VideoCreateWithoutGiftDistributionsInput;
  where: VideoWhereUniqueInput;
};

export type VideoCreateOrConnectWithoutLikedVideosInput = {
  create: VideoCreateWithoutLikedVideosInput;
  where: VideoWhereUniqueInput;
};

export type VideoCreateOrConnectWithoutPlaylistVideosInput = {
  create: VideoCreateWithoutPlaylistVideosInput;
  where: VideoWhereUniqueInput;
};

export type VideoCreateOrConnectWithoutStatisticsInput = {
  create: VideoCreateWithoutStatisticsInput;
  where: VideoWhereUniqueInput;
};

export type VideoCreateOrConnectWithoutUserPayPerViewInput = {
  create: VideoCreateWithoutUserPayPerViewInput;
  where: VideoWhereUniqueInput;
};

export type VideoCreateOrConnectWithoutWatchHistoriesInput = {
  create: VideoCreateWithoutWatchHistoriesInput;
  where: VideoWhereUniqueInput;
};

export type VideoCreateWithoutChannelInput = {
  archivedAt?: InputMaybe<Scalars['DateTime']>;
  assetId?: InputMaybe<Scalars['String']>;
  comments?: InputMaybe<CommentCreateNestedManyWithoutVideoInput>;
  coverThumbnailUrl?: InputMaybe<Scalars['String']>;
  createdAt?: InputMaybe<Scalars['DateTime']>;
  description?: InputMaybe<Scalars['String']>;
  duration?: InputMaybe<Scalars['Float']>;
  genre: Scalars['String'];
  giftDistributions?: InputMaybe<GiftDistributionCreateNestedManyWithoutVideoInput>;
  height?: InputMaybe<Scalars['Int']>;
  id?: InputMaybe<Scalars['String']>;
  isPayPerView?: InputMaybe<Scalars['Boolean']>;
  isVertical?: InputMaybe<Scalars['Boolean']>;
  language: Scalars['String'];
  likedVideos?: InputMaybe<LikedVideoCreateNestedManyWithoutVideoInput>;
  payPerViewPrice?: InputMaybe<Scalars['Float']>;
  playbackUrl?: InputMaybe<Scalars['String']>;
  playlistVideos?: InputMaybe<PlaylistVideoCreateNestedManyWithoutVideoInput>;
  productUrl?: InputMaybe<Scalars['String']>;
  rating: VideoRating;
  scheduleEnd?: InputMaybe<Scalars['DateTime']>;
  scheduleStart?: InputMaybe<Scalars['DateTime']>;
  shortId?: InputMaybe<Scalars['String']>;
  statistics?: InputMaybe<VideoStatisticCreateNestedManyWithoutVideoInput>;
  status?: InputMaybe<VideoStatus>;
  subtitleLanguage?: InputMaybe<Scalars['String']>;
  subtitleUrl?: InputMaybe<Scalars['String']>;
  thumbnailUrl?: InputMaybe<Scalars['String']>;
  title: Scalars['String'];
  totalCommentCount?: InputMaybe<Scalars['Int']>;
  totalGiftCount?: InputMaybe<Scalars['Int']>;
  totalLikeCount?: InputMaybe<Scalars['Int']>;
  totalViewCount?: InputMaybe<Scalars['Int']>;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
  uploadId?: InputMaybe<Scalars['String']>;
  userPayPerView?: InputMaybe<UserPayPerViewCreateNestedManyWithoutVideoInput>;
  watchHistories?: InputMaybe<WatchHistoryCreateNestedManyWithoutVideoInput>;
  width?: InputMaybe<Scalars['Int']>;
};

export type VideoCreateWithoutCommentsInput = {
  archivedAt?: InputMaybe<Scalars['DateTime']>;
  assetId?: InputMaybe<Scalars['String']>;
  channel: ChannelCreateNestedOneWithoutVideosInput;
  coverThumbnailUrl?: InputMaybe<Scalars['String']>;
  createdAt?: InputMaybe<Scalars['DateTime']>;
  description?: InputMaybe<Scalars['String']>;
  duration?: InputMaybe<Scalars['Float']>;
  genre: Scalars['String'];
  giftDistributions?: InputMaybe<GiftDistributionCreateNestedManyWithoutVideoInput>;
  height?: InputMaybe<Scalars['Int']>;
  id?: InputMaybe<Scalars['String']>;
  isPayPerView?: InputMaybe<Scalars['Boolean']>;
  isVertical?: InputMaybe<Scalars['Boolean']>;
  language: Scalars['String'];
  likedVideos?: InputMaybe<LikedVideoCreateNestedManyWithoutVideoInput>;
  payPerViewPrice?: InputMaybe<Scalars['Float']>;
  playbackUrl?: InputMaybe<Scalars['String']>;
  playlistVideos?: InputMaybe<PlaylistVideoCreateNestedManyWithoutVideoInput>;
  productUrl?: InputMaybe<Scalars['String']>;
  rating: VideoRating;
  scheduleEnd?: InputMaybe<Scalars['DateTime']>;
  scheduleStart?: InputMaybe<Scalars['DateTime']>;
  shortId?: InputMaybe<Scalars['String']>;
  statistics?: InputMaybe<VideoStatisticCreateNestedManyWithoutVideoInput>;
  status?: InputMaybe<VideoStatus>;
  subtitleLanguage?: InputMaybe<Scalars['String']>;
  subtitleUrl?: InputMaybe<Scalars['String']>;
  thumbnailUrl?: InputMaybe<Scalars['String']>;
  title: Scalars['String'];
  totalCommentCount?: InputMaybe<Scalars['Int']>;
  totalGiftCount?: InputMaybe<Scalars['Int']>;
  totalLikeCount?: InputMaybe<Scalars['Int']>;
  totalViewCount?: InputMaybe<Scalars['Int']>;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
  uploadId?: InputMaybe<Scalars['String']>;
  userPayPerView?: InputMaybe<UserPayPerViewCreateNestedManyWithoutVideoInput>;
  watchHistories?: InputMaybe<WatchHistoryCreateNestedManyWithoutVideoInput>;
  width?: InputMaybe<Scalars['Int']>;
};

export type VideoCreateWithoutGiftDistributionsInput = {
  archivedAt?: InputMaybe<Scalars['DateTime']>;
  assetId?: InputMaybe<Scalars['String']>;
  channel: ChannelCreateNestedOneWithoutVideosInput;
  comments?: InputMaybe<CommentCreateNestedManyWithoutVideoInput>;
  coverThumbnailUrl?: InputMaybe<Scalars['String']>;
  createdAt?: InputMaybe<Scalars['DateTime']>;
  description?: InputMaybe<Scalars['String']>;
  duration?: InputMaybe<Scalars['Float']>;
  genre: Scalars['String'];
  height?: InputMaybe<Scalars['Int']>;
  id?: InputMaybe<Scalars['String']>;
  isPayPerView?: InputMaybe<Scalars['Boolean']>;
  isVertical?: InputMaybe<Scalars['Boolean']>;
  language: Scalars['String'];
  likedVideos?: InputMaybe<LikedVideoCreateNestedManyWithoutVideoInput>;
  payPerViewPrice?: InputMaybe<Scalars['Float']>;
  playbackUrl?: InputMaybe<Scalars['String']>;
  playlistVideos?: InputMaybe<PlaylistVideoCreateNestedManyWithoutVideoInput>;
  productUrl?: InputMaybe<Scalars['String']>;
  rating: VideoRating;
  scheduleEnd?: InputMaybe<Scalars['DateTime']>;
  scheduleStart?: InputMaybe<Scalars['DateTime']>;
  shortId?: InputMaybe<Scalars['String']>;
  statistics?: InputMaybe<VideoStatisticCreateNestedManyWithoutVideoInput>;
  status?: InputMaybe<VideoStatus>;
  subtitleLanguage?: InputMaybe<Scalars['String']>;
  subtitleUrl?: InputMaybe<Scalars['String']>;
  thumbnailUrl?: InputMaybe<Scalars['String']>;
  title: Scalars['String'];
  totalCommentCount?: InputMaybe<Scalars['Int']>;
  totalGiftCount?: InputMaybe<Scalars['Int']>;
  totalLikeCount?: InputMaybe<Scalars['Int']>;
  totalViewCount?: InputMaybe<Scalars['Int']>;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
  uploadId?: InputMaybe<Scalars['String']>;
  userPayPerView?: InputMaybe<UserPayPerViewCreateNestedManyWithoutVideoInput>;
  watchHistories?: InputMaybe<WatchHistoryCreateNestedManyWithoutVideoInput>;
  width?: InputMaybe<Scalars['Int']>;
};

export type VideoCreateWithoutLikedVideosInput = {
  archivedAt?: InputMaybe<Scalars['DateTime']>;
  assetId?: InputMaybe<Scalars['String']>;
  channel: ChannelCreateNestedOneWithoutVideosInput;
  comments?: InputMaybe<CommentCreateNestedManyWithoutVideoInput>;
  coverThumbnailUrl?: InputMaybe<Scalars['String']>;
  createdAt?: InputMaybe<Scalars['DateTime']>;
  description?: InputMaybe<Scalars['String']>;
  duration?: InputMaybe<Scalars['Float']>;
  genre: Scalars['String'];
  giftDistributions?: InputMaybe<GiftDistributionCreateNestedManyWithoutVideoInput>;
  height?: InputMaybe<Scalars['Int']>;
  id?: InputMaybe<Scalars['String']>;
  isPayPerView?: InputMaybe<Scalars['Boolean']>;
  isVertical?: InputMaybe<Scalars['Boolean']>;
  language: Scalars['String'];
  payPerViewPrice?: InputMaybe<Scalars['Float']>;
  playbackUrl?: InputMaybe<Scalars['String']>;
  playlistVideos?: InputMaybe<PlaylistVideoCreateNestedManyWithoutVideoInput>;
  productUrl?: InputMaybe<Scalars['String']>;
  rating: VideoRating;
  scheduleEnd?: InputMaybe<Scalars['DateTime']>;
  scheduleStart?: InputMaybe<Scalars['DateTime']>;
  shortId?: InputMaybe<Scalars['String']>;
  statistics?: InputMaybe<VideoStatisticCreateNestedManyWithoutVideoInput>;
  status?: InputMaybe<VideoStatus>;
  subtitleLanguage?: InputMaybe<Scalars['String']>;
  subtitleUrl?: InputMaybe<Scalars['String']>;
  thumbnailUrl?: InputMaybe<Scalars['String']>;
  title: Scalars['String'];
  totalCommentCount?: InputMaybe<Scalars['Int']>;
  totalGiftCount?: InputMaybe<Scalars['Int']>;
  totalLikeCount?: InputMaybe<Scalars['Int']>;
  totalViewCount?: InputMaybe<Scalars['Int']>;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
  uploadId?: InputMaybe<Scalars['String']>;
  userPayPerView?: InputMaybe<UserPayPerViewCreateNestedManyWithoutVideoInput>;
  watchHistories?: InputMaybe<WatchHistoryCreateNestedManyWithoutVideoInput>;
  width?: InputMaybe<Scalars['Int']>;
};

export type VideoCreateWithoutPlaylistVideosInput = {
  archivedAt?: InputMaybe<Scalars['DateTime']>;
  assetId?: InputMaybe<Scalars['String']>;
  channel: ChannelCreateNestedOneWithoutVideosInput;
  comments?: InputMaybe<CommentCreateNestedManyWithoutVideoInput>;
  coverThumbnailUrl?: InputMaybe<Scalars['String']>;
  createdAt?: InputMaybe<Scalars['DateTime']>;
  description?: InputMaybe<Scalars['String']>;
  duration?: InputMaybe<Scalars['Float']>;
  genre: Scalars['String'];
  giftDistributions?: InputMaybe<GiftDistributionCreateNestedManyWithoutVideoInput>;
  height?: InputMaybe<Scalars['Int']>;
  id?: InputMaybe<Scalars['String']>;
  isPayPerView?: InputMaybe<Scalars['Boolean']>;
  isVertical?: InputMaybe<Scalars['Boolean']>;
  language: Scalars['String'];
  likedVideos?: InputMaybe<LikedVideoCreateNestedManyWithoutVideoInput>;
  payPerViewPrice?: InputMaybe<Scalars['Float']>;
  playbackUrl?: InputMaybe<Scalars['String']>;
  productUrl?: InputMaybe<Scalars['String']>;
  rating: VideoRating;
  scheduleEnd?: InputMaybe<Scalars['DateTime']>;
  scheduleStart?: InputMaybe<Scalars['DateTime']>;
  shortId?: InputMaybe<Scalars['String']>;
  statistics?: InputMaybe<VideoStatisticCreateNestedManyWithoutVideoInput>;
  status?: InputMaybe<VideoStatus>;
  subtitleLanguage?: InputMaybe<Scalars['String']>;
  subtitleUrl?: InputMaybe<Scalars['String']>;
  thumbnailUrl?: InputMaybe<Scalars['String']>;
  title: Scalars['String'];
  totalCommentCount?: InputMaybe<Scalars['Int']>;
  totalGiftCount?: InputMaybe<Scalars['Int']>;
  totalLikeCount?: InputMaybe<Scalars['Int']>;
  totalViewCount?: InputMaybe<Scalars['Int']>;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
  uploadId?: InputMaybe<Scalars['String']>;
  userPayPerView?: InputMaybe<UserPayPerViewCreateNestedManyWithoutVideoInput>;
  watchHistories?: InputMaybe<WatchHistoryCreateNestedManyWithoutVideoInput>;
  width?: InputMaybe<Scalars['Int']>;
};

export type VideoCreateWithoutStatisticsInput = {
  archivedAt?: InputMaybe<Scalars['DateTime']>;
  assetId?: InputMaybe<Scalars['String']>;
  channel: ChannelCreateNestedOneWithoutVideosInput;
  comments?: InputMaybe<CommentCreateNestedManyWithoutVideoInput>;
  coverThumbnailUrl?: InputMaybe<Scalars['String']>;
  createdAt?: InputMaybe<Scalars['DateTime']>;
  description?: InputMaybe<Scalars['String']>;
  duration?: InputMaybe<Scalars['Float']>;
  genre: Scalars['String'];
  giftDistributions?: InputMaybe<GiftDistributionCreateNestedManyWithoutVideoInput>;
  height?: InputMaybe<Scalars['Int']>;
  id?: InputMaybe<Scalars['String']>;
  isPayPerView?: InputMaybe<Scalars['Boolean']>;
  isVertical?: InputMaybe<Scalars['Boolean']>;
  language: Scalars['String'];
  likedVideos?: InputMaybe<LikedVideoCreateNestedManyWithoutVideoInput>;
  payPerViewPrice?: InputMaybe<Scalars['Float']>;
  playbackUrl?: InputMaybe<Scalars['String']>;
  playlistVideos?: InputMaybe<PlaylistVideoCreateNestedManyWithoutVideoInput>;
  productUrl?: InputMaybe<Scalars['String']>;
  rating: VideoRating;
  scheduleEnd?: InputMaybe<Scalars['DateTime']>;
  scheduleStart?: InputMaybe<Scalars['DateTime']>;
  shortId?: InputMaybe<Scalars['String']>;
  status?: InputMaybe<VideoStatus>;
  subtitleLanguage?: InputMaybe<Scalars['String']>;
  subtitleUrl?: InputMaybe<Scalars['String']>;
  thumbnailUrl?: InputMaybe<Scalars['String']>;
  title: Scalars['String'];
  totalCommentCount?: InputMaybe<Scalars['Int']>;
  totalGiftCount?: InputMaybe<Scalars['Int']>;
  totalLikeCount?: InputMaybe<Scalars['Int']>;
  totalViewCount?: InputMaybe<Scalars['Int']>;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
  uploadId?: InputMaybe<Scalars['String']>;
  userPayPerView?: InputMaybe<UserPayPerViewCreateNestedManyWithoutVideoInput>;
  watchHistories?: InputMaybe<WatchHistoryCreateNestedManyWithoutVideoInput>;
  width?: InputMaybe<Scalars['Int']>;
};

export type VideoCreateWithoutUserPayPerViewInput = {
  archivedAt?: InputMaybe<Scalars['DateTime']>;
  assetId?: InputMaybe<Scalars['String']>;
  channel: ChannelCreateNestedOneWithoutVideosInput;
  comments?: InputMaybe<CommentCreateNestedManyWithoutVideoInput>;
  coverThumbnailUrl?: InputMaybe<Scalars['String']>;
  createdAt?: InputMaybe<Scalars['DateTime']>;
  description?: InputMaybe<Scalars['String']>;
  duration?: InputMaybe<Scalars['Float']>;
  genre: Scalars['String'];
  giftDistributions?: InputMaybe<GiftDistributionCreateNestedManyWithoutVideoInput>;
  height?: InputMaybe<Scalars['Int']>;
  id?: InputMaybe<Scalars['String']>;
  isPayPerView?: InputMaybe<Scalars['Boolean']>;
  isVertical?: InputMaybe<Scalars['Boolean']>;
  language: Scalars['String'];
  likedVideos?: InputMaybe<LikedVideoCreateNestedManyWithoutVideoInput>;
  payPerViewPrice?: InputMaybe<Scalars['Float']>;
  playbackUrl?: InputMaybe<Scalars['String']>;
  playlistVideos?: InputMaybe<PlaylistVideoCreateNestedManyWithoutVideoInput>;
  productUrl?: InputMaybe<Scalars['String']>;
  rating: VideoRating;
  scheduleEnd?: InputMaybe<Scalars['DateTime']>;
  scheduleStart?: InputMaybe<Scalars['DateTime']>;
  shortId?: InputMaybe<Scalars['String']>;
  statistics?: InputMaybe<VideoStatisticCreateNestedManyWithoutVideoInput>;
  status?: InputMaybe<VideoStatus>;
  subtitleLanguage?: InputMaybe<Scalars['String']>;
  subtitleUrl?: InputMaybe<Scalars['String']>;
  thumbnailUrl?: InputMaybe<Scalars['String']>;
  title: Scalars['String'];
  totalCommentCount?: InputMaybe<Scalars['Int']>;
  totalGiftCount?: InputMaybe<Scalars['Int']>;
  totalLikeCount?: InputMaybe<Scalars['Int']>;
  totalViewCount?: InputMaybe<Scalars['Int']>;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
  uploadId?: InputMaybe<Scalars['String']>;
  watchHistories?: InputMaybe<WatchHistoryCreateNestedManyWithoutVideoInput>;
  width?: InputMaybe<Scalars['Int']>;
};

export type VideoCreateWithoutWatchHistoriesInput = {
  archivedAt?: InputMaybe<Scalars['DateTime']>;
  assetId?: InputMaybe<Scalars['String']>;
  channel: ChannelCreateNestedOneWithoutVideosInput;
  comments?: InputMaybe<CommentCreateNestedManyWithoutVideoInput>;
  coverThumbnailUrl?: InputMaybe<Scalars['String']>;
  createdAt?: InputMaybe<Scalars['DateTime']>;
  description?: InputMaybe<Scalars['String']>;
  duration?: InputMaybe<Scalars['Float']>;
  genre: Scalars['String'];
  giftDistributions?: InputMaybe<GiftDistributionCreateNestedManyWithoutVideoInput>;
  height?: InputMaybe<Scalars['Int']>;
  id?: InputMaybe<Scalars['String']>;
  isPayPerView?: InputMaybe<Scalars['Boolean']>;
  isVertical?: InputMaybe<Scalars['Boolean']>;
  language: Scalars['String'];
  likedVideos?: InputMaybe<LikedVideoCreateNestedManyWithoutVideoInput>;
  payPerViewPrice?: InputMaybe<Scalars['Float']>;
  playbackUrl?: InputMaybe<Scalars['String']>;
  playlistVideos?: InputMaybe<PlaylistVideoCreateNestedManyWithoutVideoInput>;
  productUrl?: InputMaybe<Scalars['String']>;
  rating: VideoRating;
  scheduleEnd?: InputMaybe<Scalars['DateTime']>;
  scheduleStart?: InputMaybe<Scalars['DateTime']>;
  shortId?: InputMaybe<Scalars['String']>;
  statistics?: InputMaybe<VideoStatisticCreateNestedManyWithoutVideoInput>;
  status?: InputMaybe<VideoStatus>;
  subtitleLanguage?: InputMaybe<Scalars['String']>;
  subtitleUrl?: InputMaybe<Scalars['String']>;
  thumbnailUrl?: InputMaybe<Scalars['String']>;
  title: Scalars['String'];
  totalCommentCount?: InputMaybe<Scalars['Int']>;
  totalGiftCount?: InputMaybe<Scalars['Int']>;
  totalLikeCount?: InputMaybe<Scalars['Int']>;
  totalViewCount?: InputMaybe<Scalars['Int']>;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
  uploadId?: InputMaybe<Scalars['String']>;
  userPayPerView?: InputMaybe<UserPayPerViewCreateNestedManyWithoutVideoInput>;
  width?: InputMaybe<Scalars['Int']>;
};

export type VideoGroupBy = {
  __typename?: 'VideoGroupBy';
  _avg?: Maybe<VideoAvgAggregate>;
  _count?: Maybe<VideoCountAggregate>;
  _max?: Maybe<VideoMaxAggregate>;
  _min?: Maybe<VideoMinAggregate>;
  _sum?: Maybe<VideoSumAggregate>;
  archivedAt?: Maybe<Scalars['DateTime']>;
  assetId?: Maybe<Scalars['String']>;
  channelId: Scalars['String'];
  coverThumbnailUrl?: Maybe<Scalars['String']>;
  createdAt: Scalars['DateTime'];
  description?: Maybe<Scalars['String']>;
  duration: Scalars['Float'];
  genre: Scalars['String'];
  height?: Maybe<Scalars['Int']>;
  id: Scalars['String'];
  isPayPerView: Scalars['Boolean'];
  isVertical: Scalars['Boolean'];
  language: Scalars['String'];
  payPerViewPrice?: Maybe<Scalars['Float']>;
  playbackUrl?: Maybe<Scalars['String']>;
  productUrl?: Maybe<Scalars['String']>;
  rating: VideoRating;
  scheduleEnd?: Maybe<Scalars['DateTime']>;
  scheduleStart?: Maybe<Scalars['DateTime']>;
  shortId?: Maybe<Scalars['String']>;
  status: VideoStatus;
  subtitleLanguage?: Maybe<Scalars['String']>;
  subtitleUrl?: Maybe<Scalars['String']>;
  thumbnailUrl?: Maybe<Scalars['String']>;
  title: Scalars['String'];
  totalCommentCount: Scalars['Int'];
  totalGiftCount: Scalars['Int'];
  totalLikeCount: Scalars['Int'];
  totalViewCount: Scalars['Int'];
  updatedAt: Scalars['DateTime'];
  uploadId?: Maybe<Scalars['String']>;
  width?: Maybe<Scalars['Int']>;
};

export type VideoListRelationFilter = {
  every?: InputMaybe<VideoWhereInput>;
  none?: InputMaybe<VideoWhereInput>;
  some?: InputMaybe<VideoWhereInput>;
};

export type VideoMaxAggregate = {
  __typename?: 'VideoMaxAggregate';
  archivedAt?: Maybe<Scalars['DateTime']>;
  assetId?: Maybe<Scalars['String']>;
  channelId?: Maybe<Scalars['String']>;
  coverThumbnailUrl?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  description?: Maybe<Scalars['String']>;
  duration?: Maybe<Scalars['Float']>;
  genre?: Maybe<Scalars['String']>;
  height?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['String']>;
  isPayPerView?: Maybe<Scalars['Boolean']>;
  isVertical?: Maybe<Scalars['Boolean']>;
  language?: Maybe<Scalars['String']>;
  payPerViewPrice?: Maybe<Scalars['Float']>;
  playbackUrl?: Maybe<Scalars['String']>;
  productUrl?: Maybe<Scalars['String']>;
  rating?: Maybe<VideoRating>;
  scheduleEnd?: Maybe<Scalars['DateTime']>;
  scheduleStart?: Maybe<Scalars['DateTime']>;
  shortId?: Maybe<Scalars['String']>;
  status?: Maybe<VideoStatus>;
  subtitleLanguage?: Maybe<Scalars['String']>;
  subtitleUrl?: Maybe<Scalars['String']>;
  thumbnailUrl?: Maybe<Scalars['String']>;
  title?: Maybe<Scalars['String']>;
  totalCommentCount?: Maybe<Scalars['Int']>;
  totalGiftCount?: Maybe<Scalars['Int']>;
  totalLikeCount?: Maybe<Scalars['Int']>;
  totalViewCount?: Maybe<Scalars['Int']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  uploadId?: Maybe<Scalars['String']>;
  width?: Maybe<Scalars['Int']>;
};

export type VideoMaxOrderByAggregateInput = {
  archivedAt?: InputMaybe<SortOrder>;
  assetId?: InputMaybe<SortOrder>;
  channelId?: InputMaybe<SortOrder>;
  coverThumbnailUrl?: InputMaybe<SortOrder>;
  createdAt?: InputMaybe<SortOrder>;
  description?: InputMaybe<SortOrder>;
  duration?: InputMaybe<SortOrder>;
  genre?: InputMaybe<SortOrder>;
  height?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  isPayPerView?: InputMaybe<SortOrder>;
  isVertical?: InputMaybe<SortOrder>;
  language?: InputMaybe<SortOrder>;
  payPerViewPrice?: InputMaybe<SortOrder>;
  playbackUrl?: InputMaybe<SortOrder>;
  productUrl?: InputMaybe<SortOrder>;
  rating?: InputMaybe<SortOrder>;
  scheduleEnd?: InputMaybe<SortOrder>;
  scheduleStart?: InputMaybe<SortOrder>;
  shortId?: InputMaybe<SortOrder>;
  status?: InputMaybe<SortOrder>;
  subtitleLanguage?: InputMaybe<SortOrder>;
  subtitleUrl?: InputMaybe<SortOrder>;
  thumbnailUrl?: InputMaybe<SortOrder>;
  title?: InputMaybe<SortOrder>;
  totalCommentCount?: InputMaybe<SortOrder>;
  totalGiftCount?: InputMaybe<SortOrder>;
  totalLikeCount?: InputMaybe<SortOrder>;
  totalViewCount?: InputMaybe<SortOrder>;
  updatedAt?: InputMaybe<SortOrder>;
  uploadId?: InputMaybe<SortOrder>;
  width?: InputMaybe<SortOrder>;
};

export type VideoMinAggregate = {
  __typename?: 'VideoMinAggregate';
  archivedAt?: Maybe<Scalars['DateTime']>;
  assetId?: Maybe<Scalars['String']>;
  channelId?: Maybe<Scalars['String']>;
  coverThumbnailUrl?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  description?: Maybe<Scalars['String']>;
  duration?: Maybe<Scalars['Float']>;
  genre?: Maybe<Scalars['String']>;
  height?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['String']>;
  isPayPerView?: Maybe<Scalars['Boolean']>;
  isVertical?: Maybe<Scalars['Boolean']>;
  language?: Maybe<Scalars['String']>;
  payPerViewPrice?: Maybe<Scalars['Float']>;
  playbackUrl?: Maybe<Scalars['String']>;
  productUrl?: Maybe<Scalars['String']>;
  rating?: Maybe<VideoRating>;
  scheduleEnd?: Maybe<Scalars['DateTime']>;
  scheduleStart?: Maybe<Scalars['DateTime']>;
  shortId?: Maybe<Scalars['String']>;
  status?: Maybe<VideoStatus>;
  subtitleLanguage?: Maybe<Scalars['String']>;
  subtitleUrl?: Maybe<Scalars['String']>;
  thumbnailUrl?: Maybe<Scalars['String']>;
  title?: Maybe<Scalars['String']>;
  totalCommentCount?: Maybe<Scalars['Int']>;
  totalGiftCount?: Maybe<Scalars['Int']>;
  totalLikeCount?: Maybe<Scalars['Int']>;
  totalViewCount?: Maybe<Scalars['Int']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  uploadId?: Maybe<Scalars['String']>;
  width?: Maybe<Scalars['Int']>;
};

export type VideoMinOrderByAggregateInput = {
  archivedAt?: InputMaybe<SortOrder>;
  assetId?: InputMaybe<SortOrder>;
  channelId?: InputMaybe<SortOrder>;
  coverThumbnailUrl?: InputMaybe<SortOrder>;
  createdAt?: InputMaybe<SortOrder>;
  description?: InputMaybe<SortOrder>;
  duration?: InputMaybe<SortOrder>;
  genre?: InputMaybe<SortOrder>;
  height?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  isPayPerView?: InputMaybe<SortOrder>;
  isVertical?: InputMaybe<SortOrder>;
  language?: InputMaybe<SortOrder>;
  payPerViewPrice?: InputMaybe<SortOrder>;
  playbackUrl?: InputMaybe<SortOrder>;
  productUrl?: InputMaybe<SortOrder>;
  rating?: InputMaybe<SortOrder>;
  scheduleEnd?: InputMaybe<SortOrder>;
  scheduleStart?: InputMaybe<SortOrder>;
  shortId?: InputMaybe<SortOrder>;
  status?: InputMaybe<SortOrder>;
  subtitleLanguage?: InputMaybe<SortOrder>;
  subtitleUrl?: InputMaybe<SortOrder>;
  thumbnailUrl?: InputMaybe<SortOrder>;
  title?: InputMaybe<SortOrder>;
  totalCommentCount?: InputMaybe<SortOrder>;
  totalGiftCount?: InputMaybe<SortOrder>;
  totalLikeCount?: InputMaybe<SortOrder>;
  totalViewCount?: InputMaybe<SortOrder>;
  updatedAt?: InputMaybe<SortOrder>;
  uploadId?: InputMaybe<SortOrder>;
  width?: InputMaybe<SortOrder>;
};

export type VideoOrderByRelationAggregateInput = {
  _count?: InputMaybe<SortOrder>;
};

export type VideoOrderByWithAggregationInput = {
  _avg?: InputMaybe<VideoAvgOrderByAggregateInput>;
  _count?: InputMaybe<VideoCountOrderByAggregateInput>;
  _max?: InputMaybe<VideoMaxOrderByAggregateInput>;
  _min?: InputMaybe<VideoMinOrderByAggregateInput>;
  _sum?: InputMaybe<VideoSumOrderByAggregateInput>;
  archivedAt?: InputMaybe<SortOrder>;
  assetId?: InputMaybe<SortOrder>;
  channelId?: InputMaybe<SortOrder>;
  coverThumbnailUrl?: InputMaybe<SortOrder>;
  createdAt?: InputMaybe<SortOrder>;
  description?: InputMaybe<SortOrder>;
  duration?: InputMaybe<SortOrder>;
  genre?: InputMaybe<SortOrder>;
  height?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  isPayPerView?: InputMaybe<SortOrder>;
  isVertical?: InputMaybe<SortOrder>;
  language?: InputMaybe<SortOrder>;
  payPerViewPrice?: InputMaybe<SortOrder>;
  playbackUrl?: InputMaybe<SortOrder>;
  productUrl?: InputMaybe<SortOrder>;
  rating?: InputMaybe<SortOrder>;
  scheduleEnd?: InputMaybe<SortOrder>;
  scheduleStart?: InputMaybe<SortOrder>;
  shortId?: InputMaybe<SortOrder>;
  status?: InputMaybe<SortOrder>;
  subtitleLanguage?: InputMaybe<SortOrder>;
  subtitleUrl?: InputMaybe<SortOrder>;
  thumbnailUrl?: InputMaybe<SortOrder>;
  title?: InputMaybe<SortOrder>;
  totalCommentCount?: InputMaybe<SortOrder>;
  totalGiftCount?: InputMaybe<SortOrder>;
  totalLikeCount?: InputMaybe<SortOrder>;
  totalViewCount?: InputMaybe<SortOrder>;
  updatedAt?: InputMaybe<SortOrder>;
  uploadId?: InputMaybe<SortOrder>;
  width?: InputMaybe<SortOrder>;
};

export type VideoOrderByWithRelationInput = {
  archivedAt?: InputMaybe<SortOrder>;
  assetId?: InputMaybe<SortOrder>;
  channel?: InputMaybe<ChannelOrderByWithRelationInput>;
  channelId?: InputMaybe<SortOrder>;
  comments?: InputMaybe<CommentOrderByRelationAggregateInput>;
  coverThumbnailUrl?: InputMaybe<SortOrder>;
  createdAt?: InputMaybe<SortOrder>;
  description?: InputMaybe<SortOrder>;
  duration?: InputMaybe<SortOrder>;
  genre?: InputMaybe<SortOrder>;
  giftDistributions?: InputMaybe<GiftDistributionOrderByRelationAggregateInput>;
  height?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  isPayPerView?: InputMaybe<SortOrder>;
  isVertical?: InputMaybe<SortOrder>;
  language?: InputMaybe<SortOrder>;
  likedVideos?: InputMaybe<LikedVideoOrderByRelationAggregateInput>;
  payPerViewPrice?: InputMaybe<SortOrder>;
  playbackUrl?: InputMaybe<SortOrder>;
  playlistVideos?: InputMaybe<PlaylistVideoOrderByRelationAggregateInput>;
  productUrl?: InputMaybe<SortOrder>;
  rating?: InputMaybe<SortOrder>;
  scheduleEnd?: InputMaybe<SortOrder>;
  scheduleStart?: InputMaybe<SortOrder>;
  shortId?: InputMaybe<SortOrder>;
  statistics?: InputMaybe<VideoStatisticOrderByRelationAggregateInput>;
  status?: InputMaybe<SortOrder>;
  subtitleLanguage?: InputMaybe<SortOrder>;
  subtitleUrl?: InputMaybe<SortOrder>;
  thumbnailUrl?: InputMaybe<SortOrder>;
  title?: InputMaybe<SortOrder>;
  totalCommentCount?: InputMaybe<SortOrder>;
  totalGiftCount?: InputMaybe<SortOrder>;
  totalLikeCount?: InputMaybe<SortOrder>;
  totalViewCount?: InputMaybe<SortOrder>;
  updatedAt?: InputMaybe<SortOrder>;
  uploadId?: InputMaybe<SortOrder>;
  userPayPerView?: InputMaybe<UserPayPerViewOrderByRelationAggregateInput>;
  watchHistories?: InputMaybe<WatchHistoryOrderByRelationAggregateInput>;
  width?: InputMaybe<SortOrder>;
};

export enum VideoRating {
  General = 'GENERAL',
  Pg13 = 'PG13',
  R18 = 'R18'
}

export type VideoRelationFilter = {
  is?: InputMaybe<VideoWhereInput>;
  isNot?: InputMaybe<VideoWhereInput>;
};

export enum VideoScalarFieldEnum {
  ArchivedAt = 'archivedAt',
  AssetId = 'assetId',
  ChannelId = 'channelId',
  CoverThumbnailUrl = 'coverThumbnailUrl',
  CreatedAt = 'createdAt',
  Description = 'description',
  Duration = 'duration',
  Genre = 'genre',
  Height = 'height',
  Id = 'id',
  IsPayPerView = 'isPayPerView',
  IsVertical = 'isVertical',
  Language = 'language',
  PayPerViewPrice = 'payPerViewPrice',
  PlaybackUrl = 'playbackUrl',
  ProductUrl = 'productUrl',
  Rating = 'rating',
  ScheduleEnd = 'scheduleEnd',
  ScheduleStart = 'scheduleStart',
  ShortId = 'shortId',
  Status = 'status',
  SubtitleLanguage = 'subtitleLanguage',
  SubtitleUrl = 'subtitleUrl',
  ThumbnailUrl = 'thumbnailUrl',
  Title = 'title',
  TotalCommentCount = 'totalCommentCount',
  TotalGiftCount = 'totalGiftCount',
  TotalLikeCount = 'totalLikeCount',
  TotalViewCount = 'totalViewCount',
  UpdatedAt = 'updatedAt',
  UploadId = 'uploadId',
  Width = 'width'
}

export type VideoScalarWhereInput = {
  AND?: InputMaybe<Array<VideoScalarWhereInput>>;
  NOT?: InputMaybe<Array<VideoScalarWhereInput>>;
  OR?: InputMaybe<Array<VideoScalarWhereInput>>;
  archivedAt?: InputMaybe<DateTimeNullableFilter>;
  assetId?: InputMaybe<StringNullableFilter>;
  channelId?: InputMaybe<StringFilter>;
  coverThumbnailUrl?: InputMaybe<StringNullableFilter>;
  createdAt?: InputMaybe<DateTimeFilter>;
  description?: InputMaybe<StringNullableFilter>;
  duration?: InputMaybe<FloatFilter>;
  genre?: InputMaybe<StringFilter>;
  height?: InputMaybe<IntNullableFilter>;
  id?: InputMaybe<StringFilter>;
  isPayPerView?: InputMaybe<BoolFilter>;
  isVertical?: InputMaybe<BoolFilter>;
  language?: InputMaybe<StringFilter>;
  payPerViewPrice?: InputMaybe<FloatNullableFilter>;
  playbackUrl?: InputMaybe<StringNullableFilter>;
  productUrl?: InputMaybe<StringNullableFilter>;
  rating?: InputMaybe<EnumVideoRatingFilter>;
  scheduleEnd?: InputMaybe<DateTimeNullableFilter>;
  scheduleStart?: InputMaybe<DateTimeNullableFilter>;
  shortId?: InputMaybe<StringNullableFilter>;
  status?: InputMaybe<EnumVideoStatusFilter>;
  subtitleLanguage?: InputMaybe<StringNullableFilter>;
  subtitleUrl?: InputMaybe<StringNullableFilter>;
  thumbnailUrl?: InputMaybe<StringNullableFilter>;
  title?: InputMaybe<StringFilter>;
  totalCommentCount?: InputMaybe<IntFilter>;
  totalGiftCount?: InputMaybe<IntFilter>;
  totalLikeCount?: InputMaybe<IntFilter>;
  totalViewCount?: InputMaybe<IntFilter>;
  updatedAt?: InputMaybe<DateTimeFilter>;
  uploadId?: InputMaybe<StringNullableFilter>;
  width?: InputMaybe<IntNullableFilter>;
};

export type VideoScalarWhereWithAggregatesInput = {
  AND?: InputMaybe<Array<VideoScalarWhereWithAggregatesInput>>;
  NOT?: InputMaybe<Array<VideoScalarWhereWithAggregatesInput>>;
  OR?: InputMaybe<Array<VideoScalarWhereWithAggregatesInput>>;
  archivedAt?: InputMaybe<DateTimeNullableWithAggregatesFilter>;
  assetId?: InputMaybe<StringNullableWithAggregatesFilter>;
  channelId?: InputMaybe<StringWithAggregatesFilter>;
  coverThumbnailUrl?: InputMaybe<StringNullableWithAggregatesFilter>;
  createdAt?: InputMaybe<DateTimeWithAggregatesFilter>;
  description?: InputMaybe<StringNullableWithAggregatesFilter>;
  duration?: InputMaybe<FloatWithAggregatesFilter>;
  genre?: InputMaybe<StringWithAggregatesFilter>;
  height?: InputMaybe<IntNullableWithAggregatesFilter>;
  id?: InputMaybe<StringWithAggregatesFilter>;
  isPayPerView?: InputMaybe<BoolWithAggregatesFilter>;
  isVertical?: InputMaybe<BoolWithAggregatesFilter>;
  language?: InputMaybe<StringWithAggregatesFilter>;
  payPerViewPrice?: InputMaybe<FloatNullableWithAggregatesFilter>;
  playbackUrl?: InputMaybe<StringNullableWithAggregatesFilter>;
  productUrl?: InputMaybe<StringNullableWithAggregatesFilter>;
  rating?: InputMaybe<EnumVideoRatingWithAggregatesFilter>;
  scheduleEnd?: InputMaybe<DateTimeNullableWithAggregatesFilter>;
  scheduleStart?: InputMaybe<DateTimeNullableWithAggregatesFilter>;
  shortId?: InputMaybe<StringNullableWithAggregatesFilter>;
  status?: InputMaybe<EnumVideoStatusWithAggregatesFilter>;
  subtitleLanguage?: InputMaybe<StringNullableWithAggregatesFilter>;
  subtitleUrl?: InputMaybe<StringNullableWithAggregatesFilter>;
  thumbnailUrl?: InputMaybe<StringNullableWithAggregatesFilter>;
  title?: InputMaybe<StringWithAggregatesFilter>;
  totalCommentCount?: InputMaybe<IntWithAggregatesFilter>;
  totalGiftCount?: InputMaybe<IntWithAggregatesFilter>;
  totalLikeCount?: InputMaybe<IntWithAggregatesFilter>;
  totalViewCount?: InputMaybe<IntWithAggregatesFilter>;
  updatedAt?: InputMaybe<DateTimeWithAggregatesFilter>;
  uploadId?: InputMaybe<StringNullableWithAggregatesFilter>;
  width?: InputMaybe<IntNullableWithAggregatesFilter>;
};

export type VideoStatistic = {
  __typename?: 'VideoStatistic';
  archivedAt?: Maybe<Scalars['DateTime']>;
  channel: Channel;
  channelId: Scalars['String'];
  createdAt: Scalars['DateTime'];
  id: Scalars['String'];
  totalPlayingTime: Scalars['Int'];
  updatedAt: Scalars['DateTime'];
  video: Video;
  videoId: Scalars['String'];
  viewCount: Scalars['Int'];
};

export type VideoStatisticCreateManyChannelInput = {
  archivedAt?: InputMaybe<Scalars['DateTime']>;
  createdAt?: InputMaybe<Scalars['DateTime']>;
  id?: InputMaybe<Scalars['String']>;
  totalPlayingTime?: InputMaybe<Scalars['Int']>;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
  videoId: Scalars['String'];
  viewCount?: InputMaybe<Scalars['Int']>;
};

export type VideoStatisticCreateManyChannelInputEnvelope = {
  data: Array<VideoStatisticCreateManyChannelInput>;
  skipDuplicates?: InputMaybe<Scalars['Boolean']>;
};

export type VideoStatisticCreateManyVideoInput = {
  archivedAt?: InputMaybe<Scalars['DateTime']>;
  channelId: Scalars['String'];
  createdAt?: InputMaybe<Scalars['DateTime']>;
  id?: InputMaybe<Scalars['String']>;
  totalPlayingTime?: InputMaybe<Scalars['Int']>;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
  viewCount?: InputMaybe<Scalars['Int']>;
};

export type VideoStatisticCreateManyVideoInputEnvelope = {
  data: Array<VideoStatisticCreateManyVideoInput>;
  skipDuplicates?: InputMaybe<Scalars['Boolean']>;
};

export type VideoStatisticCreateNestedManyWithoutChannelInput = {
  connect?: InputMaybe<Array<VideoStatisticWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<VideoStatisticCreateOrConnectWithoutChannelInput>>;
  create?: InputMaybe<Array<VideoStatisticCreateWithoutChannelInput>>;
  createMany?: InputMaybe<VideoStatisticCreateManyChannelInputEnvelope>;
};

export type VideoStatisticCreateNestedManyWithoutVideoInput = {
  connect?: InputMaybe<Array<VideoStatisticWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<VideoStatisticCreateOrConnectWithoutVideoInput>>;
  create?: InputMaybe<Array<VideoStatisticCreateWithoutVideoInput>>;
  createMany?: InputMaybe<VideoStatisticCreateManyVideoInputEnvelope>;
};

export type VideoStatisticCreateOrConnectWithoutChannelInput = {
  create: VideoStatisticCreateWithoutChannelInput;
  where: VideoStatisticWhereUniqueInput;
};

export type VideoStatisticCreateOrConnectWithoutVideoInput = {
  create: VideoStatisticCreateWithoutVideoInput;
  where: VideoStatisticWhereUniqueInput;
};

export type VideoStatisticCreateWithoutChannelInput = {
  archivedAt?: InputMaybe<Scalars['DateTime']>;
  createdAt?: InputMaybe<Scalars['DateTime']>;
  id?: InputMaybe<Scalars['String']>;
  totalPlayingTime?: InputMaybe<Scalars['Int']>;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
  video: VideoCreateNestedOneWithoutStatisticsInput;
  viewCount?: InputMaybe<Scalars['Int']>;
};

export type VideoStatisticCreateWithoutVideoInput = {
  archivedAt?: InputMaybe<Scalars['DateTime']>;
  channel: ChannelCreateNestedOneWithoutVideoStatisticsInput;
  createdAt?: InputMaybe<Scalars['DateTime']>;
  id?: InputMaybe<Scalars['String']>;
  totalPlayingTime?: InputMaybe<Scalars['Int']>;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
  viewCount?: InputMaybe<Scalars['Int']>;
};

export type VideoStatisticListRelationFilter = {
  every?: InputMaybe<VideoStatisticWhereInput>;
  none?: InputMaybe<VideoStatisticWhereInput>;
  some?: InputMaybe<VideoStatisticWhereInput>;
};

export type VideoStatisticOrderByRelationAggregateInput = {
  _count?: InputMaybe<SortOrder>;
};

export type VideoStatisticOrderByWithRelationInput = {
  archivedAt?: InputMaybe<SortOrder>;
  channel?: InputMaybe<ChannelOrderByWithRelationInput>;
  channelId?: InputMaybe<SortOrder>;
  createdAt?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  totalPlayingTime?: InputMaybe<SortOrder>;
  updatedAt?: InputMaybe<SortOrder>;
  video?: InputMaybe<VideoOrderByWithRelationInput>;
  videoId?: InputMaybe<SortOrder>;
  viewCount?: InputMaybe<SortOrder>;
};

export enum VideoStatisticScalarFieldEnum {
  ArchivedAt = 'archivedAt',
  ChannelId = 'channelId',
  CreatedAt = 'createdAt',
  Id = 'id',
  TotalPlayingTime = 'totalPlayingTime',
  UpdatedAt = 'updatedAt',
  VideoId = 'videoId',
  ViewCount = 'viewCount'
}

export type VideoStatisticScalarWhereInput = {
  AND?: InputMaybe<Array<VideoStatisticScalarWhereInput>>;
  NOT?: InputMaybe<Array<VideoStatisticScalarWhereInput>>;
  OR?: InputMaybe<Array<VideoStatisticScalarWhereInput>>;
  archivedAt?: InputMaybe<DateTimeNullableFilter>;
  channelId?: InputMaybe<StringFilter>;
  createdAt?: InputMaybe<DateTimeFilter>;
  id?: InputMaybe<StringFilter>;
  totalPlayingTime?: InputMaybe<IntFilter>;
  updatedAt?: InputMaybe<DateTimeFilter>;
  videoId?: InputMaybe<StringFilter>;
  viewCount?: InputMaybe<IntFilter>;
};

export type VideoStatisticUpdateManyMutationInput = {
  archivedAt?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  totalPlayingTime?: InputMaybe<IntFieldUpdateOperationsInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  viewCount?: InputMaybe<IntFieldUpdateOperationsInput>;
};

export type VideoStatisticUpdateManyWithWhereWithoutChannelInput = {
  data: VideoStatisticUpdateManyMutationInput;
  where: VideoStatisticScalarWhereInput;
};

export type VideoStatisticUpdateManyWithWhereWithoutVideoInput = {
  data: VideoStatisticUpdateManyMutationInput;
  where: VideoStatisticScalarWhereInput;
};

export type VideoStatisticUpdateManyWithoutChannelInput = {
  connect?: InputMaybe<Array<VideoStatisticWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<VideoStatisticCreateOrConnectWithoutChannelInput>>;
  create?: InputMaybe<Array<VideoStatisticCreateWithoutChannelInput>>;
  createMany?: InputMaybe<VideoStatisticCreateManyChannelInputEnvelope>;
  delete?: InputMaybe<Array<VideoStatisticWhereUniqueInput>>;
  deleteMany?: InputMaybe<Array<VideoStatisticScalarWhereInput>>;
  disconnect?: InputMaybe<Array<VideoStatisticWhereUniqueInput>>;
  set?: InputMaybe<Array<VideoStatisticWhereUniqueInput>>;
  update?: InputMaybe<Array<VideoStatisticUpdateWithWhereUniqueWithoutChannelInput>>;
  updateMany?: InputMaybe<Array<VideoStatisticUpdateManyWithWhereWithoutChannelInput>>;
  upsert?: InputMaybe<Array<VideoStatisticUpsertWithWhereUniqueWithoutChannelInput>>;
};

export type VideoStatisticUpdateManyWithoutVideoInput = {
  connect?: InputMaybe<Array<VideoStatisticWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<VideoStatisticCreateOrConnectWithoutVideoInput>>;
  create?: InputMaybe<Array<VideoStatisticCreateWithoutVideoInput>>;
  createMany?: InputMaybe<VideoStatisticCreateManyVideoInputEnvelope>;
  delete?: InputMaybe<Array<VideoStatisticWhereUniqueInput>>;
  deleteMany?: InputMaybe<Array<VideoStatisticScalarWhereInput>>;
  disconnect?: InputMaybe<Array<VideoStatisticWhereUniqueInput>>;
  set?: InputMaybe<Array<VideoStatisticWhereUniqueInput>>;
  update?: InputMaybe<Array<VideoStatisticUpdateWithWhereUniqueWithoutVideoInput>>;
  updateMany?: InputMaybe<Array<VideoStatisticUpdateManyWithWhereWithoutVideoInput>>;
  upsert?: InputMaybe<Array<VideoStatisticUpsertWithWhereUniqueWithoutVideoInput>>;
};

export type VideoStatisticUpdateWithWhereUniqueWithoutChannelInput = {
  data: VideoStatisticUpdateWithoutChannelInput;
  where: VideoStatisticWhereUniqueInput;
};

export type VideoStatisticUpdateWithWhereUniqueWithoutVideoInput = {
  data: VideoStatisticUpdateWithoutVideoInput;
  where: VideoStatisticWhereUniqueInput;
};

export type VideoStatisticUpdateWithoutChannelInput = {
  archivedAt?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  totalPlayingTime?: InputMaybe<IntFieldUpdateOperationsInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  video?: InputMaybe<VideoUpdateOneRequiredWithoutStatisticsInput>;
  viewCount?: InputMaybe<IntFieldUpdateOperationsInput>;
};

export type VideoStatisticUpdateWithoutVideoInput = {
  archivedAt?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  channel?: InputMaybe<ChannelUpdateOneRequiredWithoutVideoStatisticsInput>;
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  totalPlayingTime?: InputMaybe<IntFieldUpdateOperationsInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  viewCount?: InputMaybe<IntFieldUpdateOperationsInput>;
};

export type VideoStatisticUpsertWithWhereUniqueWithoutChannelInput = {
  create: VideoStatisticCreateWithoutChannelInput;
  update: VideoStatisticUpdateWithoutChannelInput;
  where: VideoStatisticWhereUniqueInput;
};

export type VideoStatisticUpsertWithWhereUniqueWithoutVideoInput = {
  create: VideoStatisticCreateWithoutVideoInput;
  update: VideoStatisticUpdateWithoutVideoInput;
  where: VideoStatisticWhereUniqueInput;
};

export type VideoStatisticVideoIdChannelIdCreatedAtCompoundUniqueInput = {
  channelId: Scalars['String'];
  createdAt: Scalars['DateTime'];
  videoId: Scalars['String'];
};

export type VideoStatisticWhereInput = {
  AND?: InputMaybe<Array<VideoStatisticWhereInput>>;
  NOT?: InputMaybe<Array<VideoStatisticWhereInput>>;
  OR?: InputMaybe<Array<VideoStatisticWhereInput>>;
  archivedAt?: InputMaybe<DateTimeNullableFilter>;
  channel?: InputMaybe<ChannelRelationFilter>;
  channelId?: InputMaybe<StringFilter>;
  createdAt?: InputMaybe<DateTimeFilter>;
  id?: InputMaybe<StringFilter>;
  totalPlayingTime?: InputMaybe<IntFilter>;
  updatedAt?: InputMaybe<DateTimeFilter>;
  video?: InputMaybe<VideoRelationFilter>;
  videoId?: InputMaybe<StringFilter>;
  viewCount?: InputMaybe<IntFilter>;
};

export type VideoStatisticWhereUniqueInput = {
  id?: InputMaybe<Scalars['String']>;
  videoId_channelId_createdAt?: InputMaybe<VideoStatisticVideoIdChannelIdCreatedAtCompoundUniqueInput>;
};

export enum VideoStatus {
  DeactivatedByAdmin = 'DEACTIVATED_BY_ADMIN',
  Failed = 'FAILED',
  Preparing = 'PREPARING',
  Released = 'RELEASED',
  WaitingForUpload = 'WAITING_FOR_UPLOAD'
}

export type VideoSumAggregate = {
  __typename?: 'VideoSumAggregate';
  duration?: Maybe<Scalars['Float']>;
  height?: Maybe<Scalars['Int']>;
  payPerViewPrice?: Maybe<Scalars['Float']>;
  totalCommentCount?: Maybe<Scalars['Int']>;
  totalGiftCount?: Maybe<Scalars['Int']>;
  totalLikeCount?: Maybe<Scalars['Int']>;
  totalViewCount?: Maybe<Scalars['Int']>;
  width?: Maybe<Scalars['Int']>;
};

export type VideoSumOrderByAggregateInput = {
  duration?: InputMaybe<SortOrder>;
  height?: InputMaybe<SortOrder>;
  payPerViewPrice?: InputMaybe<SortOrder>;
  totalCommentCount?: InputMaybe<SortOrder>;
  totalGiftCount?: InputMaybe<SortOrder>;
  totalLikeCount?: InputMaybe<SortOrder>;
  totalViewCount?: InputMaybe<SortOrder>;
  width?: InputMaybe<SortOrder>;
};

export type VideoUpdateInput = {
  archivedAt?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  assetId?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  channel?: InputMaybe<ChannelUpdateOneRequiredWithoutVideosInput>;
  comments?: InputMaybe<CommentUpdateManyWithoutVideoInput>;
  coverThumbnailUrl?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  description?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  duration?: InputMaybe<FloatFieldUpdateOperationsInput>;
  genre?: InputMaybe<StringFieldUpdateOperationsInput>;
  giftDistributions?: InputMaybe<GiftDistributionUpdateManyWithoutVideoInput>;
  height?: InputMaybe<NullableIntFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  isPayPerView?: InputMaybe<BoolFieldUpdateOperationsInput>;
  isVertical?: InputMaybe<BoolFieldUpdateOperationsInput>;
  language?: InputMaybe<StringFieldUpdateOperationsInput>;
  likedVideos?: InputMaybe<LikedVideoUpdateManyWithoutVideoInput>;
  payPerViewPrice?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  playbackUrl?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  playlistVideos?: InputMaybe<PlaylistVideoUpdateManyWithoutVideoInput>;
  productUrl?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  rating?: InputMaybe<EnumVideoRatingFieldUpdateOperationsInput>;
  scheduleEnd?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  scheduleStart?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  shortId?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  statistics?: InputMaybe<VideoStatisticUpdateManyWithoutVideoInput>;
  status?: InputMaybe<EnumVideoStatusFieldUpdateOperationsInput>;
  subtitleLanguage?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  subtitleUrl?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  thumbnailUrl?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  title?: InputMaybe<StringFieldUpdateOperationsInput>;
  totalCommentCount?: InputMaybe<IntFieldUpdateOperationsInput>;
  totalGiftCount?: InputMaybe<IntFieldUpdateOperationsInput>;
  totalLikeCount?: InputMaybe<IntFieldUpdateOperationsInput>;
  totalViewCount?: InputMaybe<IntFieldUpdateOperationsInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  uploadId?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  userPayPerView?: InputMaybe<UserPayPerViewUpdateManyWithoutVideoInput>;
  watchHistories?: InputMaybe<WatchHistoryUpdateManyWithoutVideoInput>;
  width?: InputMaybe<NullableIntFieldUpdateOperationsInput>;
};

export type VideoUpdateManyMutationInput = {
  archivedAt?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  assetId?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  coverThumbnailUrl?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  description?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  duration?: InputMaybe<FloatFieldUpdateOperationsInput>;
  genre?: InputMaybe<StringFieldUpdateOperationsInput>;
  height?: InputMaybe<NullableIntFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  isPayPerView?: InputMaybe<BoolFieldUpdateOperationsInput>;
  isVertical?: InputMaybe<BoolFieldUpdateOperationsInput>;
  language?: InputMaybe<StringFieldUpdateOperationsInput>;
  payPerViewPrice?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  playbackUrl?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  productUrl?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  rating?: InputMaybe<EnumVideoRatingFieldUpdateOperationsInput>;
  scheduleEnd?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  scheduleStart?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  shortId?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  status?: InputMaybe<EnumVideoStatusFieldUpdateOperationsInput>;
  subtitleLanguage?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  subtitleUrl?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  thumbnailUrl?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  title?: InputMaybe<StringFieldUpdateOperationsInput>;
  totalCommentCount?: InputMaybe<IntFieldUpdateOperationsInput>;
  totalGiftCount?: InputMaybe<IntFieldUpdateOperationsInput>;
  totalLikeCount?: InputMaybe<IntFieldUpdateOperationsInput>;
  totalViewCount?: InputMaybe<IntFieldUpdateOperationsInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  uploadId?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  width?: InputMaybe<NullableIntFieldUpdateOperationsInput>;
};

export type VideoUpdateManyWithWhereWithoutChannelInput = {
  data: VideoUpdateManyMutationInput;
  where: VideoScalarWhereInput;
};

export type VideoUpdateManyWithoutChannelInput = {
  connect?: InputMaybe<Array<VideoWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<VideoCreateOrConnectWithoutChannelInput>>;
  create?: InputMaybe<Array<VideoCreateWithoutChannelInput>>;
  createMany?: InputMaybe<VideoCreateManyChannelInputEnvelope>;
  delete?: InputMaybe<Array<VideoWhereUniqueInput>>;
  deleteMany?: InputMaybe<Array<VideoScalarWhereInput>>;
  disconnect?: InputMaybe<Array<VideoWhereUniqueInput>>;
  set?: InputMaybe<Array<VideoWhereUniqueInput>>;
  update?: InputMaybe<Array<VideoUpdateWithWhereUniqueWithoutChannelInput>>;
  updateMany?: InputMaybe<Array<VideoUpdateManyWithWhereWithoutChannelInput>>;
  upsert?: InputMaybe<Array<VideoUpsertWithWhereUniqueWithoutChannelInput>>;
};

export type VideoUpdateOneRequiredWithoutCommentsInput = {
  connect?: InputMaybe<VideoWhereUniqueInput>;
  connectOrCreate?: InputMaybe<VideoCreateOrConnectWithoutCommentsInput>;
  create?: InputMaybe<VideoCreateWithoutCommentsInput>;
  update?: InputMaybe<VideoUpdateWithoutCommentsInput>;
  upsert?: InputMaybe<VideoUpsertWithoutCommentsInput>;
};

export type VideoUpdateOneRequiredWithoutGiftDistributionsInput = {
  connect?: InputMaybe<VideoWhereUniqueInput>;
  connectOrCreate?: InputMaybe<VideoCreateOrConnectWithoutGiftDistributionsInput>;
  create?: InputMaybe<VideoCreateWithoutGiftDistributionsInput>;
  update?: InputMaybe<VideoUpdateWithoutGiftDistributionsInput>;
  upsert?: InputMaybe<VideoUpsertWithoutGiftDistributionsInput>;
};

export type VideoUpdateOneRequiredWithoutLikedVideosInput = {
  connect?: InputMaybe<VideoWhereUniqueInput>;
  connectOrCreate?: InputMaybe<VideoCreateOrConnectWithoutLikedVideosInput>;
  create?: InputMaybe<VideoCreateWithoutLikedVideosInput>;
  update?: InputMaybe<VideoUpdateWithoutLikedVideosInput>;
  upsert?: InputMaybe<VideoUpsertWithoutLikedVideosInput>;
};

export type VideoUpdateOneRequiredWithoutPlaylistVideosInput = {
  connect?: InputMaybe<VideoWhereUniqueInput>;
  connectOrCreate?: InputMaybe<VideoCreateOrConnectWithoutPlaylistVideosInput>;
  create?: InputMaybe<VideoCreateWithoutPlaylistVideosInput>;
  update?: InputMaybe<VideoUpdateWithoutPlaylistVideosInput>;
  upsert?: InputMaybe<VideoUpsertWithoutPlaylistVideosInput>;
};

export type VideoUpdateOneRequiredWithoutStatisticsInput = {
  connect?: InputMaybe<VideoWhereUniqueInput>;
  connectOrCreate?: InputMaybe<VideoCreateOrConnectWithoutStatisticsInput>;
  create?: InputMaybe<VideoCreateWithoutStatisticsInput>;
  update?: InputMaybe<VideoUpdateWithoutStatisticsInput>;
  upsert?: InputMaybe<VideoUpsertWithoutStatisticsInput>;
};

export type VideoUpdateOneRequiredWithoutUserPayPerViewInput = {
  connect?: InputMaybe<VideoWhereUniqueInput>;
  connectOrCreate?: InputMaybe<VideoCreateOrConnectWithoutUserPayPerViewInput>;
  create?: InputMaybe<VideoCreateWithoutUserPayPerViewInput>;
  update?: InputMaybe<VideoUpdateWithoutUserPayPerViewInput>;
  upsert?: InputMaybe<VideoUpsertWithoutUserPayPerViewInput>;
};

export type VideoUpdateOneRequiredWithoutWatchHistoriesInput = {
  connect?: InputMaybe<VideoWhereUniqueInput>;
  connectOrCreate?: InputMaybe<VideoCreateOrConnectWithoutWatchHistoriesInput>;
  create?: InputMaybe<VideoCreateWithoutWatchHistoriesInput>;
  update?: InputMaybe<VideoUpdateWithoutWatchHistoriesInput>;
  upsert?: InputMaybe<VideoUpsertWithoutWatchHistoriesInput>;
};

export type VideoUpdateWithWhereUniqueWithoutChannelInput = {
  data: VideoUpdateWithoutChannelInput;
  where: VideoWhereUniqueInput;
};

export type VideoUpdateWithoutChannelInput = {
  archivedAt?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  assetId?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  comments?: InputMaybe<CommentUpdateManyWithoutVideoInput>;
  coverThumbnailUrl?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  description?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  duration?: InputMaybe<FloatFieldUpdateOperationsInput>;
  genre?: InputMaybe<StringFieldUpdateOperationsInput>;
  giftDistributions?: InputMaybe<GiftDistributionUpdateManyWithoutVideoInput>;
  height?: InputMaybe<NullableIntFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  isPayPerView?: InputMaybe<BoolFieldUpdateOperationsInput>;
  isVertical?: InputMaybe<BoolFieldUpdateOperationsInput>;
  language?: InputMaybe<StringFieldUpdateOperationsInput>;
  likedVideos?: InputMaybe<LikedVideoUpdateManyWithoutVideoInput>;
  payPerViewPrice?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  playbackUrl?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  playlistVideos?: InputMaybe<PlaylistVideoUpdateManyWithoutVideoInput>;
  productUrl?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  rating?: InputMaybe<EnumVideoRatingFieldUpdateOperationsInput>;
  scheduleEnd?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  scheduleStart?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  shortId?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  statistics?: InputMaybe<VideoStatisticUpdateManyWithoutVideoInput>;
  status?: InputMaybe<EnumVideoStatusFieldUpdateOperationsInput>;
  subtitleLanguage?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  subtitleUrl?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  thumbnailUrl?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  title?: InputMaybe<StringFieldUpdateOperationsInput>;
  totalCommentCount?: InputMaybe<IntFieldUpdateOperationsInput>;
  totalGiftCount?: InputMaybe<IntFieldUpdateOperationsInput>;
  totalLikeCount?: InputMaybe<IntFieldUpdateOperationsInput>;
  totalViewCount?: InputMaybe<IntFieldUpdateOperationsInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  uploadId?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  userPayPerView?: InputMaybe<UserPayPerViewUpdateManyWithoutVideoInput>;
  watchHistories?: InputMaybe<WatchHistoryUpdateManyWithoutVideoInput>;
  width?: InputMaybe<NullableIntFieldUpdateOperationsInput>;
};

export type VideoUpdateWithoutCommentsInput = {
  archivedAt?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  assetId?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  channel?: InputMaybe<ChannelUpdateOneRequiredWithoutVideosInput>;
  coverThumbnailUrl?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  description?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  duration?: InputMaybe<FloatFieldUpdateOperationsInput>;
  genre?: InputMaybe<StringFieldUpdateOperationsInput>;
  giftDistributions?: InputMaybe<GiftDistributionUpdateManyWithoutVideoInput>;
  height?: InputMaybe<NullableIntFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  isPayPerView?: InputMaybe<BoolFieldUpdateOperationsInput>;
  isVertical?: InputMaybe<BoolFieldUpdateOperationsInput>;
  language?: InputMaybe<StringFieldUpdateOperationsInput>;
  likedVideos?: InputMaybe<LikedVideoUpdateManyWithoutVideoInput>;
  payPerViewPrice?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  playbackUrl?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  playlistVideos?: InputMaybe<PlaylistVideoUpdateManyWithoutVideoInput>;
  productUrl?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  rating?: InputMaybe<EnumVideoRatingFieldUpdateOperationsInput>;
  scheduleEnd?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  scheduleStart?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  shortId?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  statistics?: InputMaybe<VideoStatisticUpdateManyWithoutVideoInput>;
  status?: InputMaybe<EnumVideoStatusFieldUpdateOperationsInput>;
  subtitleLanguage?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  subtitleUrl?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  thumbnailUrl?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  title?: InputMaybe<StringFieldUpdateOperationsInput>;
  totalCommentCount?: InputMaybe<IntFieldUpdateOperationsInput>;
  totalGiftCount?: InputMaybe<IntFieldUpdateOperationsInput>;
  totalLikeCount?: InputMaybe<IntFieldUpdateOperationsInput>;
  totalViewCount?: InputMaybe<IntFieldUpdateOperationsInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  uploadId?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  userPayPerView?: InputMaybe<UserPayPerViewUpdateManyWithoutVideoInput>;
  watchHistories?: InputMaybe<WatchHistoryUpdateManyWithoutVideoInput>;
  width?: InputMaybe<NullableIntFieldUpdateOperationsInput>;
};

export type VideoUpdateWithoutGiftDistributionsInput = {
  archivedAt?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  assetId?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  channel?: InputMaybe<ChannelUpdateOneRequiredWithoutVideosInput>;
  comments?: InputMaybe<CommentUpdateManyWithoutVideoInput>;
  coverThumbnailUrl?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  description?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  duration?: InputMaybe<FloatFieldUpdateOperationsInput>;
  genre?: InputMaybe<StringFieldUpdateOperationsInput>;
  height?: InputMaybe<NullableIntFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  isPayPerView?: InputMaybe<BoolFieldUpdateOperationsInput>;
  isVertical?: InputMaybe<BoolFieldUpdateOperationsInput>;
  language?: InputMaybe<StringFieldUpdateOperationsInput>;
  likedVideos?: InputMaybe<LikedVideoUpdateManyWithoutVideoInput>;
  payPerViewPrice?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  playbackUrl?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  playlistVideos?: InputMaybe<PlaylistVideoUpdateManyWithoutVideoInput>;
  productUrl?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  rating?: InputMaybe<EnumVideoRatingFieldUpdateOperationsInput>;
  scheduleEnd?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  scheduleStart?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  shortId?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  statistics?: InputMaybe<VideoStatisticUpdateManyWithoutVideoInput>;
  status?: InputMaybe<EnumVideoStatusFieldUpdateOperationsInput>;
  subtitleLanguage?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  subtitleUrl?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  thumbnailUrl?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  title?: InputMaybe<StringFieldUpdateOperationsInput>;
  totalCommentCount?: InputMaybe<IntFieldUpdateOperationsInput>;
  totalGiftCount?: InputMaybe<IntFieldUpdateOperationsInput>;
  totalLikeCount?: InputMaybe<IntFieldUpdateOperationsInput>;
  totalViewCount?: InputMaybe<IntFieldUpdateOperationsInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  uploadId?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  userPayPerView?: InputMaybe<UserPayPerViewUpdateManyWithoutVideoInput>;
  watchHistories?: InputMaybe<WatchHistoryUpdateManyWithoutVideoInput>;
  width?: InputMaybe<NullableIntFieldUpdateOperationsInput>;
};

export type VideoUpdateWithoutLikedVideosInput = {
  archivedAt?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  assetId?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  channel?: InputMaybe<ChannelUpdateOneRequiredWithoutVideosInput>;
  comments?: InputMaybe<CommentUpdateManyWithoutVideoInput>;
  coverThumbnailUrl?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  description?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  duration?: InputMaybe<FloatFieldUpdateOperationsInput>;
  genre?: InputMaybe<StringFieldUpdateOperationsInput>;
  giftDistributions?: InputMaybe<GiftDistributionUpdateManyWithoutVideoInput>;
  height?: InputMaybe<NullableIntFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  isPayPerView?: InputMaybe<BoolFieldUpdateOperationsInput>;
  isVertical?: InputMaybe<BoolFieldUpdateOperationsInput>;
  language?: InputMaybe<StringFieldUpdateOperationsInput>;
  payPerViewPrice?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  playbackUrl?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  playlistVideos?: InputMaybe<PlaylistVideoUpdateManyWithoutVideoInput>;
  productUrl?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  rating?: InputMaybe<EnumVideoRatingFieldUpdateOperationsInput>;
  scheduleEnd?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  scheduleStart?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  shortId?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  statistics?: InputMaybe<VideoStatisticUpdateManyWithoutVideoInput>;
  status?: InputMaybe<EnumVideoStatusFieldUpdateOperationsInput>;
  subtitleLanguage?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  subtitleUrl?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  thumbnailUrl?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  title?: InputMaybe<StringFieldUpdateOperationsInput>;
  totalCommentCount?: InputMaybe<IntFieldUpdateOperationsInput>;
  totalGiftCount?: InputMaybe<IntFieldUpdateOperationsInput>;
  totalLikeCount?: InputMaybe<IntFieldUpdateOperationsInput>;
  totalViewCount?: InputMaybe<IntFieldUpdateOperationsInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  uploadId?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  userPayPerView?: InputMaybe<UserPayPerViewUpdateManyWithoutVideoInput>;
  watchHistories?: InputMaybe<WatchHistoryUpdateManyWithoutVideoInput>;
  width?: InputMaybe<NullableIntFieldUpdateOperationsInput>;
};

export type VideoUpdateWithoutPlaylistVideosInput = {
  archivedAt?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  assetId?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  channel?: InputMaybe<ChannelUpdateOneRequiredWithoutVideosInput>;
  comments?: InputMaybe<CommentUpdateManyWithoutVideoInput>;
  coverThumbnailUrl?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  description?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  duration?: InputMaybe<FloatFieldUpdateOperationsInput>;
  genre?: InputMaybe<StringFieldUpdateOperationsInput>;
  giftDistributions?: InputMaybe<GiftDistributionUpdateManyWithoutVideoInput>;
  height?: InputMaybe<NullableIntFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  isPayPerView?: InputMaybe<BoolFieldUpdateOperationsInput>;
  isVertical?: InputMaybe<BoolFieldUpdateOperationsInput>;
  language?: InputMaybe<StringFieldUpdateOperationsInput>;
  likedVideos?: InputMaybe<LikedVideoUpdateManyWithoutVideoInput>;
  payPerViewPrice?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  playbackUrl?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  productUrl?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  rating?: InputMaybe<EnumVideoRatingFieldUpdateOperationsInput>;
  scheduleEnd?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  scheduleStart?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  shortId?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  statistics?: InputMaybe<VideoStatisticUpdateManyWithoutVideoInput>;
  status?: InputMaybe<EnumVideoStatusFieldUpdateOperationsInput>;
  subtitleLanguage?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  subtitleUrl?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  thumbnailUrl?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  title?: InputMaybe<StringFieldUpdateOperationsInput>;
  totalCommentCount?: InputMaybe<IntFieldUpdateOperationsInput>;
  totalGiftCount?: InputMaybe<IntFieldUpdateOperationsInput>;
  totalLikeCount?: InputMaybe<IntFieldUpdateOperationsInput>;
  totalViewCount?: InputMaybe<IntFieldUpdateOperationsInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  uploadId?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  userPayPerView?: InputMaybe<UserPayPerViewUpdateManyWithoutVideoInput>;
  watchHistories?: InputMaybe<WatchHistoryUpdateManyWithoutVideoInput>;
  width?: InputMaybe<NullableIntFieldUpdateOperationsInput>;
};

export type VideoUpdateWithoutStatisticsInput = {
  archivedAt?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  assetId?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  channel?: InputMaybe<ChannelUpdateOneRequiredWithoutVideosInput>;
  comments?: InputMaybe<CommentUpdateManyWithoutVideoInput>;
  coverThumbnailUrl?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  description?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  duration?: InputMaybe<FloatFieldUpdateOperationsInput>;
  genre?: InputMaybe<StringFieldUpdateOperationsInput>;
  giftDistributions?: InputMaybe<GiftDistributionUpdateManyWithoutVideoInput>;
  height?: InputMaybe<NullableIntFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  isPayPerView?: InputMaybe<BoolFieldUpdateOperationsInput>;
  isVertical?: InputMaybe<BoolFieldUpdateOperationsInput>;
  language?: InputMaybe<StringFieldUpdateOperationsInput>;
  likedVideos?: InputMaybe<LikedVideoUpdateManyWithoutVideoInput>;
  payPerViewPrice?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  playbackUrl?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  playlistVideos?: InputMaybe<PlaylistVideoUpdateManyWithoutVideoInput>;
  productUrl?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  rating?: InputMaybe<EnumVideoRatingFieldUpdateOperationsInput>;
  scheduleEnd?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  scheduleStart?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  shortId?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  status?: InputMaybe<EnumVideoStatusFieldUpdateOperationsInput>;
  subtitleLanguage?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  subtitleUrl?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  thumbnailUrl?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  title?: InputMaybe<StringFieldUpdateOperationsInput>;
  totalCommentCount?: InputMaybe<IntFieldUpdateOperationsInput>;
  totalGiftCount?: InputMaybe<IntFieldUpdateOperationsInput>;
  totalLikeCount?: InputMaybe<IntFieldUpdateOperationsInput>;
  totalViewCount?: InputMaybe<IntFieldUpdateOperationsInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  uploadId?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  userPayPerView?: InputMaybe<UserPayPerViewUpdateManyWithoutVideoInput>;
  watchHistories?: InputMaybe<WatchHistoryUpdateManyWithoutVideoInput>;
  width?: InputMaybe<NullableIntFieldUpdateOperationsInput>;
};

export type VideoUpdateWithoutUserPayPerViewInput = {
  archivedAt?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  assetId?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  channel?: InputMaybe<ChannelUpdateOneRequiredWithoutVideosInput>;
  comments?: InputMaybe<CommentUpdateManyWithoutVideoInput>;
  coverThumbnailUrl?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  description?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  duration?: InputMaybe<FloatFieldUpdateOperationsInput>;
  genre?: InputMaybe<StringFieldUpdateOperationsInput>;
  giftDistributions?: InputMaybe<GiftDistributionUpdateManyWithoutVideoInput>;
  height?: InputMaybe<NullableIntFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  isPayPerView?: InputMaybe<BoolFieldUpdateOperationsInput>;
  isVertical?: InputMaybe<BoolFieldUpdateOperationsInput>;
  language?: InputMaybe<StringFieldUpdateOperationsInput>;
  likedVideos?: InputMaybe<LikedVideoUpdateManyWithoutVideoInput>;
  payPerViewPrice?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  playbackUrl?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  playlistVideos?: InputMaybe<PlaylistVideoUpdateManyWithoutVideoInput>;
  productUrl?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  rating?: InputMaybe<EnumVideoRatingFieldUpdateOperationsInput>;
  scheduleEnd?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  scheduleStart?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  shortId?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  statistics?: InputMaybe<VideoStatisticUpdateManyWithoutVideoInput>;
  status?: InputMaybe<EnumVideoStatusFieldUpdateOperationsInput>;
  subtitleLanguage?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  subtitleUrl?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  thumbnailUrl?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  title?: InputMaybe<StringFieldUpdateOperationsInput>;
  totalCommentCount?: InputMaybe<IntFieldUpdateOperationsInput>;
  totalGiftCount?: InputMaybe<IntFieldUpdateOperationsInput>;
  totalLikeCount?: InputMaybe<IntFieldUpdateOperationsInput>;
  totalViewCount?: InputMaybe<IntFieldUpdateOperationsInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  uploadId?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  watchHistories?: InputMaybe<WatchHistoryUpdateManyWithoutVideoInput>;
  width?: InputMaybe<NullableIntFieldUpdateOperationsInput>;
};

export type VideoUpdateWithoutWatchHistoriesInput = {
  archivedAt?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  assetId?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  channel?: InputMaybe<ChannelUpdateOneRequiredWithoutVideosInput>;
  comments?: InputMaybe<CommentUpdateManyWithoutVideoInput>;
  coverThumbnailUrl?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  description?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  duration?: InputMaybe<FloatFieldUpdateOperationsInput>;
  genre?: InputMaybe<StringFieldUpdateOperationsInput>;
  giftDistributions?: InputMaybe<GiftDistributionUpdateManyWithoutVideoInput>;
  height?: InputMaybe<NullableIntFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  isPayPerView?: InputMaybe<BoolFieldUpdateOperationsInput>;
  isVertical?: InputMaybe<BoolFieldUpdateOperationsInput>;
  language?: InputMaybe<StringFieldUpdateOperationsInput>;
  likedVideos?: InputMaybe<LikedVideoUpdateManyWithoutVideoInput>;
  payPerViewPrice?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  playbackUrl?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  playlistVideos?: InputMaybe<PlaylistVideoUpdateManyWithoutVideoInput>;
  productUrl?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  rating?: InputMaybe<EnumVideoRatingFieldUpdateOperationsInput>;
  scheduleEnd?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  scheduleStart?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  shortId?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  statistics?: InputMaybe<VideoStatisticUpdateManyWithoutVideoInput>;
  status?: InputMaybe<EnumVideoStatusFieldUpdateOperationsInput>;
  subtitleLanguage?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  subtitleUrl?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  thumbnailUrl?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  title?: InputMaybe<StringFieldUpdateOperationsInput>;
  totalCommentCount?: InputMaybe<IntFieldUpdateOperationsInput>;
  totalGiftCount?: InputMaybe<IntFieldUpdateOperationsInput>;
  totalLikeCount?: InputMaybe<IntFieldUpdateOperationsInput>;
  totalViewCount?: InputMaybe<IntFieldUpdateOperationsInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  uploadId?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  userPayPerView?: InputMaybe<UserPayPerViewUpdateManyWithoutVideoInput>;
  width?: InputMaybe<NullableIntFieldUpdateOperationsInput>;
};

export type VideoUpsertWithWhereUniqueWithoutChannelInput = {
  create: VideoCreateWithoutChannelInput;
  update: VideoUpdateWithoutChannelInput;
  where: VideoWhereUniqueInput;
};

export type VideoUpsertWithoutCommentsInput = {
  create: VideoCreateWithoutCommentsInput;
  update: VideoUpdateWithoutCommentsInput;
};

export type VideoUpsertWithoutGiftDistributionsInput = {
  create: VideoCreateWithoutGiftDistributionsInput;
  update: VideoUpdateWithoutGiftDistributionsInput;
};

export type VideoUpsertWithoutLikedVideosInput = {
  create: VideoCreateWithoutLikedVideosInput;
  update: VideoUpdateWithoutLikedVideosInput;
};

export type VideoUpsertWithoutPlaylistVideosInput = {
  create: VideoCreateWithoutPlaylistVideosInput;
  update: VideoUpdateWithoutPlaylistVideosInput;
};

export type VideoUpsertWithoutStatisticsInput = {
  create: VideoCreateWithoutStatisticsInput;
  update: VideoUpdateWithoutStatisticsInput;
};

export type VideoUpsertWithoutUserPayPerViewInput = {
  create: VideoCreateWithoutUserPayPerViewInput;
  update: VideoUpdateWithoutUserPayPerViewInput;
};

export type VideoUpsertWithoutWatchHistoriesInput = {
  create: VideoCreateWithoutWatchHistoriesInput;
  update: VideoUpdateWithoutWatchHistoriesInput;
};

export type VideoWhereInput = {
  AND?: InputMaybe<Array<VideoWhereInput>>;
  NOT?: InputMaybe<Array<VideoWhereInput>>;
  OR?: InputMaybe<Array<VideoWhereInput>>;
  archivedAt?: InputMaybe<DateTimeNullableFilter>;
  assetId?: InputMaybe<StringNullableFilter>;
  channel?: InputMaybe<ChannelRelationFilter>;
  channelId?: InputMaybe<StringFilter>;
  comments?: InputMaybe<CommentListRelationFilter>;
  coverThumbnailUrl?: InputMaybe<StringNullableFilter>;
  createdAt?: InputMaybe<DateTimeFilter>;
  description?: InputMaybe<StringNullableFilter>;
  duration?: InputMaybe<FloatFilter>;
  genre?: InputMaybe<StringFilter>;
  giftDistributions?: InputMaybe<GiftDistributionListRelationFilter>;
  height?: InputMaybe<IntNullableFilter>;
  id?: InputMaybe<StringFilter>;
  isPayPerView?: InputMaybe<BoolFilter>;
  isVertical?: InputMaybe<BoolFilter>;
  language?: InputMaybe<StringFilter>;
  likedVideos?: InputMaybe<LikedVideoListRelationFilter>;
  payPerViewPrice?: InputMaybe<FloatNullableFilter>;
  playbackUrl?: InputMaybe<StringNullableFilter>;
  playlistVideos?: InputMaybe<PlaylistVideoListRelationFilter>;
  productUrl?: InputMaybe<StringNullableFilter>;
  rating?: InputMaybe<EnumVideoRatingFilter>;
  scheduleEnd?: InputMaybe<DateTimeNullableFilter>;
  scheduleStart?: InputMaybe<DateTimeNullableFilter>;
  shortId?: InputMaybe<StringNullableFilter>;
  statistics?: InputMaybe<VideoStatisticListRelationFilter>;
  status?: InputMaybe<EnumVideoStatusFilter>;
  subtitleLanguage?: InputMaybe<StringNullableFilter>;
  subtitleUrl?: InputMaybe<StringNullableFilter>;
  thumbnailUrl?: InputMaybe<StringNullableFilter>;
  title?: InputMaybe<StringFilter>;
  totalCommentCount?: InputMaybe<IntFilter>;
  totalGiftCount?: InputMaybe<IntFilter>;
  totalLikeCount?: InputMaybe<IntFilter>;
  totalViewCount?: InputMaybe<IntFilter>;
  updatedAt?: InputMaybe<DateTimeFilter>;
  uploadId?: InputMaybe<StringNullableFilter>;
  userPayPerView?: InputMaybe<UserPayPerViewListRelationFilter>;
  watchHistories?: InputMaybe<WatchHistoryListRelationFilter>;
  width?: InputMaybe<IntNullableFilter>;
};

export type VideoWhereUniqueInput = {
  id?: InputMaybe<Scalars['String']>;
  shortId?: InputMaybe<Scalars['String']>;
};

export type WatchHistory = {
  __typename?: 'WatchHistory';
  archivedAt?: Maybe<Scalars['DateTime']>;
  createdAt: Scalars['DateTime'];
  id: Scalars['String'];
  updatedAt: Scalars['DateTime'];
  user: User;
  userId: Scalars['String'];
  video: Video;
  videoId: Scalars['String'];
};

export type WatchHistoryCountAggregate = {
  __typename?: 'WatchHistoryCountAggregate';
  _all: Scalars['Int'];
  archivedAt: Scalars['Int'];
  createdAt: Scalars['Int'];
  id: Scalars['Int'];
  updatedAt: Scalars['Int'];
  userId: Scalars['Int'];
  videoId: Scalars['Int'];
};

export type WatchHistoryCountOrderByAggregateInput = {
  archivedAt?: InputMaybe<SortOrder>;
  createdAt?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  updatedAt?: InputMaybe<SortOrder>;
  userId?: InputMaybe<SortOrder>;
  videoId?: InputMaybe<SortOrder>;
};

export type WatchHistoryCreateInput = {
  archivedAt?: InputMaybe<Scalars['DateTime']>;
  createdAt?: InputMaybe<Scalars['DateTime']>;
  id?: InputMaybe<Scalars['String']>;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
  user: UserCreateNestedOneWithoutWatchHistoriesInput;
  video: VideoCreateNestedOneWithoutWatchHistoriesInput;
};

export type WatchHistoryCreateManyInput = {
  archivedAt?: InputMaybe<Scalars['DateTime']>;
  createdAt?: InputMaybe<Scalars['DateTime']>;
  id?: InputMaybe<Scalars['String']>;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
  userId: Scalars['String'];
  videoId: Scalars['String'];
};

export type WatchHistoryCreateManyUserInput = {
  archivedAt?: InputMaybe<Scalars['DateTime']>;
  createdAt?: InputMaybe<Scalars['DateTime']>;
  id?: InputMaybe<Scalars['String']>;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
  videoId: Scalars['String'];
};

export type WatchHistoryCreateManyUserInputEnvelope = {
  data: Array<WatchHistoryCreateManyUserInput>;
  skipDuplicates?: InputMaybe<Scalars['Boolean']>;
};

export type WatchHistoryCreateManyVideoInput = {
  archivedAt?: InputMaybe<Scalars['DateTime']>;
  createdAt?: InputMaybe<Scalars['DateTime']>;
  id?: InputMaybe<Scalars['String']>;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
  userId: Scalars['String'];
};

export type WatchHistoryCreateManyVideoInputEnvelope = {
  data: Array<WatchHistoryCreateManyVideoInput>;
  skipDuplicates?: InputMaybe<Scalars['Boolean']>;
};

export type WatchHistoryCreateNestedManyWithoutUserInput = {
  connect?: InputMaybe<Array<WatchHistoryWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<WatchHistoryCreateOrConnectWithoutUserInput>>;
  create?: InputMaybe<Array<WatchHistoryCreateWithoutUserInput>>;
  createMany?: InputMaybe<WatchHistoryCreateManyUserInputEnvelope>;
};

export type WatchHistoryCreateNestedManyWithoutVideoInput = {
  connect?: InputMaybe<Array<WatchHistoryWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<WatchHistoryCreateOrConnectWithoutVideoInput>>;
  create?: InputMaybe<Array<WatchHistoryCreateWithoutVideoInput>>;
  createMany?: InputMaybe<WatchHistoryCreateManyVideoInputEnvelope>;
};

export type WatchHistoryCreateOrConnectWithoutUserInput = {
  create: WatchHistoryCreateWithoutUserInput;
  where: WatchHistoryWhereUniqueInput;
};

export type WatchHistoryCreateOrConnectWithoutVideoInput = {
  create: WatchHistoryCreateWithoutVideoInput;
  where: WatchHistoryWhereUniqueInput;
};

export type WatchHistoryCreateWithoutUserInput = {
  archivedAt?: InputMaybe<Scalars['DateTime']>;
  createdAt?: InputMaybe<Scalars['DateTime']>;
  id?: InputMaybe<Scalars['String']>;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
  video: VideoCreateNestedOneWithoutWatchHistoriesInput;
};

export type WatchHistoryCreateWithoutVideoInput = {
  archivedAt?: InputMaybe<Scalars['DateTime']>;
  createdAt?: InputMaybe<Scalars['DateTime']>;
  id?: InputMaybe<Scalars['String']>;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
  user: UserCreateNestedOneWithoutWatchHistoriesInput;
};

export type WatchHistoryGroupBy = {
  __typename?: 'WatchHistoryGroupBy';
  _count?: Maybe<WatchHistoryCountAggregate>;
  _max?: Maybe<WatchHistoryMaxAggregate>;
  _min?: Maybe<WatchHistoryMinAggregate>;
  archivedAt?: Maybe<Scalars['DateTime']>;
  createdAt: Scalars['DateTime'];
  id: Scalars['String'];
  updatedAt: Scalars['DateTime'];
  userId: Scalars['String'];
  videoId: Scalars['String'];
};

export type WatchHistoryListRelationFilter = {
  every?: InputMaybe<WatchHistoryWhereInput>;
  none?: InputMaybe<WatchHistoryWhereInput>;
  some?: InputMaybe<WatchHistoryWhereInput>;
};

export type WatchHistoryMaxAggregate = {
  __typename?: 'WatchHistoryMaxAggregate';
  archivedAt?: Maybe<Scalars['DateTime']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  userId?: Maybe<Scalars['String']>;
  videoId?: Maybe<Scalars['String']>;
};

export type WatchHistoryMaxOrderByAggregateInput = {
  archivedAt?: InputMaybe<SortOrder>;
  createdAt?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  updatedAt?: InputMaybe<SortOrder>;
  userId?: InputMaybe<SortOrder>;
  videoId?: InputMaybe<SortOrder>;
};

export type WatchHistoryMinAggregate = {
  __typename?: 'WatchHistoryMinAggregate';
  archivedAt?: Maybe<Scalars['DateTime']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  userId?: Maybe<Scalars['String']>;
  videoId?: Maybe<Scalars['String']>;
};

export type WatchHistoryMinOrderByAggregateInput = {
  archivedAt?: InputMaybe<SortOrder>;
  createdAt?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  updatedAt?: InputMaybe<SortOrder>;
  userId?: InputMaybe<SortOrder>;
  videoId?: InputMaybe<SortOrder>;
};

export type WatchHistoryOrderByRelationAggregateInput = {
  _count?: InputMaybe<SortOrder>;
};

export type WatchHistoryOrderByWithAggregationInput = {
  _count?: InputMaybe<WatchHistoryCountOrderByAggregateInput>;
  _max?: InputMaybe<WatchHistoryMaxOrderByAggregateInput>;
  _min?: InputMaybe<WatchHistoryMinOrderByAggregateInput>;
  archivedAt?: InputMaybe<SortOrder>;
  createdAt?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  updatedAt?: InputMaybe<SortOrder>;
  userId?: InputMaybe<SortOrder>;
  videoId?: InputMaybe<SortOrder>;
};

export type WatchHistoryOrderByWithRelationInput = {
  archivedAt?: InputMaybe<SortOrder>;
  createdAt?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  updatedAt?: InputMaybe<SortOrder>;
  user?: InputMaybe<UserOrderByWithRelationInput>;
  userId?: InputMaybe<SortOrder>;
  video?: InputMaybe<VideoOrderByWithRelationInput>;
  videoId?: InputMaybe<SortOrder>;
};

export enum WatchHistoryScalarFieldEnum {
  ArchivedAt = 'archivedAt',
  CreatedAt = 'createdAt',
  Id = 'id',
  UpdatedAt = 'updatedAt',
  UserId = 'userId',
  VideoId = 'videoId'
}

export type WatchHistoryScalarWhereInput = {
  AND?: InputMaybe<Array<WatchHistoryScalarWhereInput>>;
  NOT?: InputMaybe<Array<WatchHistoryScalarWhereInput>>;
  OR?: InputMaybe<Array<WatchHistoryScalarWhereInput>>;
  archivedAt?: InputMaybe<DateTimeNullableFilter>;
  createdAt?: InputMaybe<DateTimeFilter>;
  id?: InputMaybe<StringFilter>;
  updatedAt?: InputMaybe<DateTimeFilter>;
  userId?: InputMaybe<StringFilter>;
  videoId?: InputMaybe<StringFilter>;
};

export type WatchHistoryScalarWhereWithAggregatesInput = {
  AND?: InputMaybe<Array<WatchHistoryScalarWhereWithAggregatesInput>>;
  NOT?: InputMaybe<Array<WatchHistoryScalarWhereWithAggregatesInput>>;
  OR?: InputMaybe<Array<WatchHistoryScalarWhereWithAggregatesInput>>;
  archivedAt?: InputMaybe<DateTimeNullableWithAggregatesFilter>;
  createdAt?: InputMaybe<DateTimeWithAggregatesFilter>;
  id?: InputMaybe<StringWithAggregatesFilter>;
  updatedAt?: InputMaybe<DateTimeWithAggregatesFilter>;
  userId?: InputMaybe<StringWithAggregatesFilter>;
  videoId?: InputMaybe<StringWithAggregatesFilter>;
};

export type WatchHistorySummaryItem = {
  __typename?: 'WatchHistorySummaryItem';
  date?: Maybe<Scalars['DateTime']>;
  frequency: Frequency;
  totalVideoCount: Scalars['Int'];
  totalViewCount: Scalars['Int'];
};

export type WatchHistorySummaryWhereInput = {
  channelId?: InputMaybe<Scalars['String']>;
  dateFrom?: InputMaybe<Scalars['DateTime']>;
  dateTo?: InputMaybe<Scalars['DateTime']>;
  frequency: Frequency;
};

export type WatchHistoryUpdateInput = {
  archivedAt?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  user?: InputMaybe<UserUpdateOneRequiredWithoutWatchHistoriesInput>;
  video?: InputMaybe<VideoUpdateOneRequiredWithoutWatchHistoriesInput>;
};

export type WatchHistoryUpdateManyMutationInput = {
  archivedAt?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
};

export type WatchHistoryUpdateManyWithWhereWithoutUserInput = {
  data: WatchHistoryUpdateManyMutationInput;
  where: WatchHistoryScalarWhereInput;
};

export type WatchHistoryUpdateManyWithWhereWithoutVideoInput = {
  data: WatchHistoryUpdateManyMutationInput;
  where: WatchHistoryScalarWhereInput;
};

export type WatchHistoryUpdateManyWithoutUserInput = {
  connect?: InputMaybe<Array<WatchHistoryWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<WatchHistoryCreateOrConnectWithoutUserInput>>;
  create?: InputMaybe<Array<WatchHistoryCreateWithoutUserInput>>;
  createMany?: InputMaybe<WatchHistoryCreateManyUserInputEnvelope>;
  delete?: InputMaybe<Array<WatchHistoryWhereUniqueInput>>;
  deleteMany?: InputMaybe<Array<WatchHistoryScalarWhereInput>>;
  disconnect?: InputMaybe<Array<WatchHistoryWhereUniqueInput>>;
  set?: InputMaybe<Array<WatchHistoryWhereUniqueInput>>;
  update?: InputMaybe<Array<WatchHistoryUpdateWithWhereUniqueWithoutUserInput>>;
  updateMany?: InputMaybe<Array<WatchHistoryUpdateManyWithWhereWithoutUserInput>>;
  upsert?: InputMaybe<Array<WatchHistoryUpsertWithWhereUniqueWithoutUserInput>>;
};

export type WatchHistoryUpdateManyWithoutVideoInput = {
  connect?: InputMaybe<Array<WatchHistoryWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<WatchHistoryCreateOrConnectWithoutVideoInput>>;
  create?: InputMaybe<Array<WatchHistoryCreateWithoutVideoInput>>;
  createMany?: InputMaybe<WatchHistoryCreateManyVideoInputEnvelope>;
  delete?: InputMaybe<Array<WatchHistoryWhereUniqueInput>>;
  deleteMany?: InputMaybe<Array<WatchHistoryScalarWhereInput>>;
  disconnect?: InputMaybe<Array<WatchHistoryWhereUniqueInput>>;
  set?: InputMaybe<Array<WatchHistoryWhereUniqueInput>>;
  update?: InputMaybe<Array<WatchHistoryUpdateWithWhereUniqueWithoutVideoInput>>;
  updateMany?: InputMaybe<Array<WatchHistoryUpdateManyWithWhereWithoutVideoInput>>;
  upsert?: InputMaybe<Array<WatchHistoryUpsertWithWhereUniqueWithoutVideoInput>>;
};

export type WatchHistoryUpdateWithWhereUniqueWithoutUserInput = {
  data: WatchHistoryUpdateWithoutUserInput;
  where: WatchHistoryWhereUniqueInput;
};

export type WatchHistoryUpdateWithWhereUniqueWithoutVideoInput = {
  data: WatchHistoryUpdateWithoutVideoInput;
  where: WatchHistoryWhereUniqueInput;
};

export type WatchHistoryUpdateWithoutUserInput = {
  archivedAt?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  video?: InputMaybe<VideoUpdateOneRequiredWithoutWatchHistoriesInput>;
};

export type WatchHistoryUpdateWithoutVideoInput = {
  archivedAt?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  user?: InputMaybe<UserUpdateOneRequiredWithoutWatchHistoriesInput>;
};

export type WatchHistoryUpsertWithWhereUniqueWithoutUserInput = {
  create: WatchHistoryCreateWithoutUserInput;
  update: WatchHistoryUpdateWithoutUserInput;
  where: WatchHistoryWhereUniqueInput;
};

export type WatchHistoryUpsertWithWhereUniqueWithoutVideoInput = {
  create: WatchHistoryCreateWithoutVideoInput;
  update: WatchHistoryUpdateWithoutVideoInput;
  where: WatchHistoryWhereUniqueInput;
};

export type WatchHistoryWhereInput = {
  AND?: InputMaybe<Array<WatchHistoryWhereInput>>;
  NOT?: InputMaybe<Array<WatchHistoryWhereInput>>;
  OR?: InputMaybe<Array<WatchHistoryWhereInput>>;
  archivedAt?: InputMaybe<DateTimeNullableFilter>;
  createdAt?: InputMaybe<DateTimeFilter>;
  id?: InputMaybe<StringFilter>;
  updatedAt?: InputMaybe<DateTimeFilter>;
  user?: InputMaybe<UserRelationFilter>;
  userId?: InputMaybe<StringFilter>;
  video?: InputMaybe<VideoRelationFilter>;
  videoId?: InputMaybe<StringFilter>;
};

export type WatchHistoryWhereUniqueInput = {
  id?: InputMaybe<Scalars['String']>;
};

export type WatchedVideoItem = {
  __typename?: 'WatchedVideoItem';
  date: Scalars['DateTime'];
  watchedRolls: Array<Video>;
  watchedVideos: Array<Video>;
};

export type UploadInfo = {
  __typename?: 'uploadInfo';
  requestId: Scalars['String'];
  uploadAddress: Scalars['String'];
  uploadAuth: Scalars['String'];
  videoId: Scalars['String'];
};
