import React from 'react';
import {
  Breadcrumb,
  Card,
  Col,
  Create,
  Form,
  Icons,
  Input,
  InputNumber,
  Row,
  Select,
  Switch,
  Upload,
  message,
  useForm,
} from '@pankod/refine-antd';
import {
  HttpError,
  IResourceComponentsProps,
  useRedirectionAfterSubmission,
  useResourceWithRoute,
  useTranslate,
} from '@pankod/refine-core';
import { useFindMyChannel } from 'pages/personal-brand/hooks/use-find-my-channel';
import { AliyunOSSUpload } from 'components/upload';
import { useUploadVideoDialog } from 'components/video';
import { RESOURCE_NAME, genreOptions, ratingOptions, voLanguageOptions } from './constant';
import { DatePicker } from 'antd';
import { Channel, CurrencyCode } from 'api';
import { IUploadVideoForm, IVideo } from 'interfaces';
import { useAliUpload } from 'libs/ali-upload';
import moment from 'moment';

const responsive = {
  xs: 24,
  sm: 24,
  md: 12,
  lg: 12,
  xl: 12,
};

const metaData = {
  operation: 'uploadVideo',
  operationType: 'UploadVideoInput',
  fields: [
    'id',
    'title',
    'description',
    {
      uploadInfo: ['uploadAddress', 'videoId', 'requestId', 'uploadAuth'],
    },
  ],
};

export const VideoCreate: React.FC<IResourceComponentsProps> = () => {
  const t = useTranslate();

  const resourceWithRoute = useResourceWithRoute();
  const resource = resourceWithRoute(RESOURCE_NAME);
  const handleSubmitWithRedirect = useRedirectionAfterSubmission();
  const [isPayPerView, setIsPayPerView] = React.useState(false);
  const [channelCurrencyCode, setChannelCurrencyCode] = React.useState(CurrencyCode.Myr);

  const { formProps, saveButtonProps, mutationResult } = useForm<IVideo, HttpError, IUploadVideoForm>({
    action: 'create',
    metaData,
    redirect: false,
    successNotification: false,
  });

  const queryResult = useFindMyChannel<Channel>();

  React.useEffect(() => {
    if (queryResult?.isSuccess && queryResult?.data?.data) {
      const { currencyCode } = queryResult?.data?.data;
      setChannelCurrencyCode(currencyCode);
    }
  }, [queryResult?.data?.data, queryResult?.isSuccess]);

  React.useEffect(() => {
    formProps.form.setFieldsValue({
      scheduleStart: moment(),
      payPerViewPrice: 1,
    });
  }, [formProps.form]);

  // useAliUpload need script below to work
  React.useEffect(() => {
    const aliyunSDKPaths = [
      '/aliyun-upload-sdk-1.5.3/aliyun-upload-sdk-1.5.3.min.js',
      '/aliyun-upload-sdk-1.5.3/lib/aliyun-oss-sdk-6.17.1.min.js',
      '/aliyun-upload-sdk-1.5.3/lib/es6-promise.min.js',
    ];
    const appendedScripts = aliyunSDKPaths.map((path) => {
      const script = document.createElement('script');
      script.src = path;
      script.type = 'text/javascript';
      document.body.appendChild(script);
      return script;
    });
    appendedScripts.forEach((script) => document.body.removeChild(script));
  }, []);

  const { showUploading, uploadProgress, uploadDismiss } = useUploadVideoDialog();

  React.useEffect(() => {
    const ossUploadInfo = mutationResult?.data?.data?.uploadInfo;
    const videoFile = formProps.form.getFieldValue('video');

    const uploadVideo = async () => {
      const uploader = useAliUpload.create(videoFile.file, {
        userId: process.env.REACT_APP_ALI_CLOUD_USER_ID as string,
        region: process.env.REACT_APP_ALI_CLOUD_REGION as string,
        getAuthByuploadInfo: async () => ({
          uploadAuth: ossUploadInfo!.uploadAuth,
          uploadAddress: ossUploadInfo!.uploadAddress,
          videoId: ossUploadInfo!.videoId,
        }),
        getNewTokenWhenExpire: async (uploadInfo) => {
          return '';
        },
        onSuccess: (uploadInfo) => {
          uploadDismiss();

          handleSubmitWithRedirect({
            redirect: 'list',
            resource: resource,
          });

          message.success('Successfully upload video');
        },
        onUpload: () => {
          showUploading();
        },
        onProgress: (uploadInfo, totalSize, progress) => {
          console.log(`${progress}%`);
          uploadProgress(progress);
        },
        onCancel: () => {
          uploadDismiss();
          message.error('Upload video had been cancelled');
        },
        onError: () => {
          uploadDismiss();
          message.error('Error on upload video');
        },
      });

      await uploader.start();
    };

    if (ossUploadInfo && videoFile) {
      uploadVideo();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [mutationResult?.data?.data]);

  return (
    <Create
      isLoading={mutationResult?.isLoading}
      saveButtonProps={{
        ...saveButtonProps,
      }}
      pageHeaderProps={{
        title: 'Upload New Content',
        breadcrumb: <Breadcrumb hideIcons />,
      }}
    >
      <Form
        {...formProps}
        layout="vertical"
        onFinish={({ video, ...values }) => {
          formProps.onFinish({
            ...values,
            fileName: video.file.name,
            payPerViewPrice: Math.round(Math.max(values.payPerViewPrice * 100, 0)),
          });
        }}
      >
        <Row gutter={[24, 24]} justify="start" align="top">
          <Col {...responsive}>
            <Card title="General Settings">
              <Form.Item label={t('videos.fields.title')} name="title" rules={[{ required: true }]}>
                <Input />
              </Form.Item>

              <Form.Item label={t('videos.fields.description')} name="description" rules={[{ required: false }]}>
                <Input.TextArea />
              </Form.Item>

              <Form.Item label={t('videos.fields.language')} name="language" rules={[{ required: true }]}>
                <Select options={voLanguageOptions} />
              </Form.Item>

              <Form.Item label={t('videos.fields.genre')} name="genre" rules={[{ required: true }]}>
                <Select options={genreOptions} />
              </Form.Item>

              <Form.Item label={t('videos.fields.rating')} name="rating" rules={[{ required: true }]}>
                <Select options={ratingOptions} />
              </Form.Item>

              <Form.Item label={'Publish Date'} name="scheduleStart" rules={[{ required: true }]}>
                <DatePicker
                  style={{ width: '100%' }}
                  disabledDate={(current) => {
                    return current && current < moment().subtract(1, 'day').endOf('day');
                  }}
                />
              </Form.Item>
            </Card>
          </Col>

          <Col {...responsive}>
            <Card title="Monetize Video">
              <Form.Item
                label={'Is this Pay-Per-View Content?'}
                name="isPayPerView"
                valuePropName="checked"
                initialValue={false}
              >
                <Switch
                  onChange={(value) => {
                    setIsPayPerView(value);
                  }}
                />
              </Form.Item>

              {isPayPerView && (
                <Form.Item
                  label={`Price Pay-Per-View (${channelCurrencyCode})`}
                  name="payPerViewPrice"
                  rules={[{ required: true }]}
                >
                  <InputNumber style={{ width: '100%' }} min={1} />
                </Form.Item>
              )}
            </Card>
          </Col>

          <Col {...responsive}>
            <Card>
              <Form.Item label={'Video Upload'} name="video" rules={[{ required: true }]}>
                <Upload.Dragger
                  accept="video/*"
                  maxCount={1}
                  beforeUpload={async (file: File) => {
                    return false;
                  }}
                >
                  <p className="ant-upload-drag-icon">
                    <Icons.InboxOutlined />
                  </p>
                  <p className="ant-upload-text">Click or drag file to this area to upload</p>
                  <p className="ant-upload-hint">Extension Allowed: .mov/.mp4/.mv4/.mpg</p>
                </Upload.Dragger>
              </Form.Item>

              <Form.Item label={'Subtitles Upload'} name="subtitleUrl">
                <AliyunOSSUpload
                  purpose="VideoSubtitle"
                  accept=".vtt,.srt"
                  description="Extension Allowed: .srt/.vtt"
                  onChange={(response) => {
                    formProps.form?.setFieldsValue({
                      subtitleUrl: response.url,
                    });
                  }}
                />
              </Form.Item>
            </Card>
          </Col>

          <Col {...responsive}>
            <Card>
              <Form.Item label={'Thumbnail Upload'} name="thumbnailUrl" rules={[{ required: false }]}>
                <AliyunOSSUpload
                  purpose="VideoThumbnail"
                  accept="image/*"
                  description="Extension Allowed: .jpg/.jpeg/.png<br>Recommended Dimension: 1280 x 720 pixels"
                  onChange={(response) => {
                    formProps.form?.setFieldsValue({
                      thumbnailUrl: response.url,
                    });
                  }}
                />
              </Form.Item>
            </Card>
          </Col>
        </Row>
      </Form>
    </Create>
  );
};
