const MaticIcon = (props) => (
  <svg width={14} height={12} fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      d="M10.326 3.668a.907.907 0 0 0-.854 0L7.478 4.843l-1.353.747-1.959 1.176a.907.907 0 0 1-.854 0L1.78 5.84a.868.868 0 0 1-.427-.748v-1.78c0-.285.143-.57.427-.748l1.532-.89a.907.907 0 0 1 .854 0l1.531.925c.25.143.428.428.428.748v1.175l1.353-.783v-1.21a.878.878 0 0 0-.428-.749L4.202.107a.907.907 0 0 0-.855 0L.427 1.816A.783.783 0 0 0 0 2.528v3.347c0 .285.142.57.427.748l2.885 1.674c.249.142.57.142.854 0l1.959-1.14 1.353-.783 1.958-1.14a.907.907 0 0 1 .855 0l1.531.89c.25.143.427.428.427.748v1.78c0 .286-.142.57-.427.749l-1.496.89a.907.907 0 0 1-.854 0L7.94 9.4a.868.868 0 0 1-.428-.748v-1.14l-1.353.784v1.175c0 .285.143.57.428.748l2.884 1.673c.25.143.57.143.854 0l2.885-1.673a.868.868 0 0 0 .427-.748V6.089a.878.878 0 0 0-.427-.748l-2.885-1.673Z"
      fill="#8247E5"
    />
  </svg>
);

export default MaticIcon;
