import {
  Row,
  Col,
  AntdLayout,
  Card,
  Typography,
  Button,
} from "@pankod/refine-antd";
import "./style.less";
import { Trans } from "react-i18next";
import { ColorverseIcon } from "components/icon";
import { useState } from "react";
import {
  ForgotPasswordForm,
  IForgotPasswordForm,
} from "components/auth/ForgotPasswordForm";
import { ResetPasswordForm } from "components/auth/ResetPasswordForm";
import { useNavigation } from "@pankod/refine-core";

const { Title } = Typography;

export enum ForgotPasswordStep {
  RequestEmail = "Request Email",
  ResetPassword = "Reset Password",
}

export const ForgotPasswordPage: React.FC = () => {
  const { push } = useNavigation();

  const [step, setStep] = useState(ForgotPasswordStep.RequestEmail);
  const [email, setEmail] = useState("");

  const onForgotPasswordSubmit = async (values: IForgotPasswordForm) => {
    setStep(ForgotPasswordStep.ResetPassword);
    setEmail(values.email);
  };

  const ForgotPasswordCardTitle = (
    <>
      <Title level={4} className="text-center">
        <Trans i18nKey="pages.login.resetPassword" defaults="Reset Password" />
      </Title>
    </>
  );

  const ResetPasswordCardTitle = (
    <>
      <Title level={4} className="text-center">
        <Trans
          i18nKey="pages.forgotPassword.resetPassword"
          defaults="Reset Password"
        />
      </Title>
    </>
  );

  return (
    <AntdLayout
      style={{
        background:
          "radial-gradient(50% 50% at 50% 50%,rgba(255, 255, 255, 0) 0%,rgba(0, 0, 0, 0.5) 100%)",
        backgroundSize: "cover",
      }}
    >
      <Row className="min-h-[100vh]" justify="center" align="middle">
        <Col xs={22} md={10}>
          <div className="w-72 md:w-96 mx-auto my-4">
            <ColorverseIcon />
          </div>
          {step === ForgotPasswordStep.RequestEmail && (
            <Card
              title={ForgotPasswordCardTitle}
              headStyle={{ borderBottom: 0, marginBottom: "0.75rem" }}
              className="md:max-w-[80%] mx-auto"
              extra={
                <Button type="link" onClick={() => push("/login")}>
                  Back
                </Button>
              }
            >
              <ForgotPasswordForm onSubmit={onForgotPasswordSubmit} />
            </Card>
          )}
          {step === ForgotPasswordStep.ResetPassword && (
            <Card
              title={ResetPasswordCardTitle}
              headStyle={{ borderBottom: 0, marginBottom: "0.75rem" }}
              className="md:max-w-[80%] mx-auto"
              extra={
                <a className="text-brand" href="/forgot-password">
                  Back
                </a>
              }
            >
              <ResetPasswordForm
                email={email}
                isSuccessCallback={() => push("/login")}
              />
            </Card>
          )}
        </Col>
      </Row>
    </AntdLayout>
  );
};
