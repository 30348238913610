import { Button, Col, Collapse, Form, FormProps, Icons, Input, Row, Select } from '@pankod/refine-antd';
import { useTranslate } from '@pankod/refine-core';
import { genreOptions, videoStatusOptions } from 'pages/videos/constant';
import { DatePicker } from 'antd';

export const VideoFilter: React.FC<{ formProps?: FormProps }> = ({ formProps }) => {
  const t = useTranslate();

  return (
    <Collapse>
      <Collapse.Panel header="Filter" key="1">
        <Form layout="vertical" {...formProps}>
          <Row gutter={[10, 0]} align="bottom">
            <Col xs={24} sm={24} md={6} lg={6} xl={6}>
              <Form.Item label={t('videos.filter.search.label')} name="title">
                <Input placeholder={'Video title'} prefix={<Icons.SearchOutlined />} />
              </Form.Item>
            </Col>

            <Col xs={24} sm={24} md={6} lg={6} xl={6}>
              <Form.Item label={'Uploaded On'} name="createdDates">
                <DatePicker.RangePicker style={{ width: '100%' }} />
              </Form.Item>
            </Col>

            <Col xs={24} sm={24} md={6} lg={6} xl={6}>
              <Form.Item label={'Genre'} name="genre">
                <Select options={[...genreOptions]} />
              </Form.Item>
            </Col>

            <Col xs={24} sm={24} md={6} lg={6} xl={6}>
              <Form.Item label={'Status'} name="status">
                <Select options={[...videoStatusOptions]} />
              </Form.Item>
            </Col>
          </Row>

          <Row gutter={[10, 0]} align="bottom">
            <Col xs={24} sm={24} md={4} lg={4} xl={4}>
              <Form.Item>
                <Button style={{ width: '100%' }} htmlType="submit" type="primary">
                  {t('videos.filter.submit')}
                </Button>
              </Form.Item>
            </Col>
            <Col xs={24} sm={24} md={4} lg={4} xl={4}>
              <Form.Item>
                <Button
                  style={{ width: '100%' }}
                  onClick={() => {
                    formProps.form.resetFields();
                    formProps.form.submit();
                  }}
                  type="primary"
                >
                  Reset
                </Button>
              </Form.Item>
            </Col>
          </Row>
        </Form>
      </Collapse.Panel>
    </Collapse>
  );
};
