import { Button, Col, Collapse, Form, FormProps, Icons, Input, Row } from '@pankod/refine-antd';
import { CrudFilters, useTranslate } from '@pankod/refine-core';
import { DatePicker } from 'antd';
import { IChannelFilterVariables } from 'interfaces';

export const ChannelFilter: React.FC<{
  formProps?: FormProps<IChannelFilterVariables>;
  filters?: CrudFilters;
  tableQueryResult: any;
}> = ({ formProps }) => {
  const t = useTranslate();

  return (
    <Collapse>
      <Collapse.Panel header="Filter" key="1">
        <Form layout="vertical" {...formProps}>
          <Row gutter={[10, 0]} align="bottom">
            <Col xs={24} sm={24} md={8} lg={8} xl={8}>
              <Form.Item label={'Search'} name="name">
                <Input placeholder={'Channel Name'} prefix={<Icons.SearchOutlined />} />
              </Form.Item>
            </Col>

            <Col xs={24} sm={24} md={8} lg={8} xl={8}>
              <Form.Item label={'Joined On'} name="joinedDates">
                <DatePicker.RangePicker style={{ width: '100%' }} />
              </Form.Item>
            </Col>
          </Row>

          <Row gutter={[10, 0]} align="bottom">
            <Col xs={24} sm={24} md={4} lg={4} xl={4}>
              <Form.Item>
                <Button style={{ width: '100%' }} htmlType="submit" type="primary">
                  {t('videos.filter.submit')}
                </Button>
              </Form.Item>
            </Col>

            <Col xs={24} sm={24} md={4} lg={4} xl={4}>
              <Form.Item>
                <Button
                  style={{ width: '100%' }}
                  onClick={() => {
                    formProps.form.resetFields();
                    formProps.form.submit();
                  }}
                  type="primary"
                >
                  Reset
                </Button>
              </Form.Item>
            </Col>
          </Row>
        </Form>
      </Collapse.Panel>
    </Collapse>
  );
};
