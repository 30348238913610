import React from 'react';
import { BsArrowUpRightSquare } from 'react-icons/bs';
import { Button, Col, Edit, Icons, Image, Input, Row, Space, Tooltip, message } from '@pankod/refine-antd';
import { BannerTable } from 'pages/personal-brand/BannerTable';
import { UploadImageDialog } from 'components/personal-brand';
import { useFindMyChannel } from './hooks/use-find-my-channel';
import { useSearchChannel } from './hooks/use-search-channel';
import { useUpdateMyChannel } from './hooks/use-update-my-channel';
import { MediaTable } from './MediaTable';
import { Form } from 'antd';

interface Link {
  key: string;
  title: string;
  url: string;
}

interface Banner {
  key: string;
  url: string;
  bannersUrl: string;
  scheduleEnd: string;
  scheduleStart: string;
}

interface Channel {
  id: string;
  name: string;
  appLogo: string;
  promotionalBanners: Banner[];
  aboutLinks: Link[];
}

export const PersonalBrandPage: React.FC = () => {
  const baseChannelUrl = process.env.REACT_APP_H5_API_BASE_URL + '/c/';
  const [form] = Form.useForm();
  const [defaultChannelName, setDefaultChannelName] = React.useState('');
  const [mediaLinks, setMediaLinks] = React.useState<Channel['aboutLinks']>([]);
  const [banners, setBanners] = React.useState<Channel['promotionalBanners']>([]);
  const [appLogo, setAppLogo] = React.useState('');

  const {
    search: debouncedSearch,
    isSearching,
    isValidName,
  } = useSearchChannel({
    form,
    exclude: defaultChannelName,
  });

  const { updateSite, isUpdating } = useUpdateMyChannel();

  const queryResult = useFindMyChannel<Channel>();

  React.useEffect(() => {
    if (queryResult.isSuccess && queryResult?.data?.data) {
      const result = queryResult?.data?.data;
      form.setFieldsValue({
        ...result,
      });
      setAppLogo(result?.appLogo);
      setDefaultChannelName(result?.name);
      setMediaLinks(
        (result?.aboutLinks || []).map((link, index) => ({
          key: `link ${index}`,
          title: link.title,
          url: link.url,
        })),
      );
      setBanners(
        (result?.promotionalBanners || []).map((banner, index) => ({
          key: `banners ${index}`,
          url: banner['promotional_url'] || '',
          bannersUrl: banner['banner_url'] || '',
          scheduleStart: banner['schedule_start'] || '',
          scheduleEnd: banner['schedule_end'] || '',
        })),
      );
    }

    return () => {
      form.resetFields();
    };
  }, [form, queryResult?.data?.data, queryResult.isSuccess]);

  const channelName = () => {
    return baseChannelUrl + form.getFieldValue('name');
  };

  const renderChannelLinkButton = () => {
    const channelNameHasChanged = form.getFieldValue('name') && defaultChannelName !== form.getFieldValue('name');

    return (
      <>
        <Tooltip placement="top" title={'Copy link to clipboard'}>
          <Button
            type="link"
            style={{ marginLeft: '5px' }}
            icon={<Icons.CopyOutlined />}
            onClick={() => {
              navigator.clipboard.writeText(channelName());
              message.success('Copied to clipboard');
            }}
          />
        </Tooltip>
        <Tooltip placement="top" title={'Visit your Colorverse channel in new tab'}>
          <Button
            disabled={channelNameHasChanged}
            type="link"
            icon={<BsArrowUpRightSquare />}
            onClick={() => {
              window.open(baseChannelUrl + defaultChannelName);
            }}
          />
        </Tooltip>
      </>
    );
  };

  const isFetching = queryResult.isFetching || isUpdating;

  return (
    <Edit
      pageHeaderProps={{ extra: <></> }}
      isLoading={isFetching}
      saveButtonProps={{
        disabled: isUpdating || !isValidName,
        onClick: () => {
          form.submit();
        },
      }}
    >
      <Form
        form={form}
        layout="vertical"
        onFinish={(values) => {
          updateSite({
            name: { set: values.name },
            aboutMe: { set: values.aboutMe },
            appLogo: { set: appLogo },
            promotionalBanners: banners.map((banner) => {
              return {
                archived: null,
                promotional_url: banner?.url || null,
                banner_url: banner?.bannersUrl || null,
                schedule_start: banner?.scheduleStart || null,
                schedule_end: banner?.scheduleEnd || null,
              };
            }),
            aboutLinks: mediaLinks.map((item) => ({
              title: item.title,
              url: item.url,
            })),
          });
        }}
      >
        <Row gutter={[10, 0]} justify="start" align="bottom">
          <Col span={24}>
            <Form.Item label={'Channel Logo'}>
              <Space>
                <Image width={80} height={80} className="object-contain" src={appLogo} />
                <UploadImageDialog
                  title="Upload Logo"
                  onSubmit={(url) => {
                    setAppLogo(url);
                  }}
                />
              </Space>
            </Form.Item>
          </Col>

          <Col span={12}>
            <Form.Item
              label={'Channel URL'}
              name="name"
              rules={[
                { min: 3 },
                { type: 'string', required: true },
                {
                  pattern: /^\S+$/,
                  message: 'empty space is not allowed',
                },
              ]}
            >
              <Input
                placeholder={'Url'}
                onChange={(e) => {
                  debouncedSearch(e.target.value);
                }}
                suffix={
                  isSearching ? (
                    <Icons.LoadingOutlined />
                  ) : isValidName ? (
                    <Icons.CheckCircleOutlined style={{ color: 'green' }} />
                  ) : (
                    <Icons.CloseCircleOutlined style={{ color: 'red' }} />
                  )
                }
              />
            </Form.Item>

            <Form.Item>
              {channelName()}

              {renderChannelLinkButton()}
            </Form.Item>
          </Col>
        </Row>

        <Row>
          <Col span={12}>
            <Form.Item label={'About me'} name="aboutMe">
              <Input.TextArea placeholder={'About me'} />
            </Form.Item>
          </Col>
        </Row>

        <Row gutter={[10, 0]} justify="start" align="bottom">
          <Col span={24}>
            <BannerTable
              banners={banners}
              onChange={(values) => {
                setBanners(values);
              }}
            />
          </Col>
        </Row>

        <Row gutter={[10, 0]} justify="start" align="bottom">
          <Col span={24}>
            <MediaTable
              data={mediaLinks || []}
              onChange={(values) => {
                setMediaLinks(values);
              }}
            />
          </Col>
        </Row>
      </Form>
    </Edit>
  );
};
