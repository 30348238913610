import { useCustom } from '@pankod/refine-core';
import { RESOURCE_FIELDS } from '../constant';

export const useFindMyChannel = <T>() => {
  return useCustom<T>({
    url: '',
    method: 'get',
    metaData: {
      operation: 'getMyChannel',
      fields: [...RESOURCE_FIELDS],
    },
  });
};
