// use this hook for update cause the useForm provide by refine and refine-antd un-able to convert the url params id ot other data types
import React from "react";
import {
  useOne,
  useUpdate,
  useRedirectionAfterSubmission,
  useResourceWithRoute,
  HttpError,
} from "@pankod/refine-core";
import { Form } from "@pankod/refine-antd";
import { useParams } from "react-router-dom";

interface FormUpdateProps {
  id?: string | number;
  resourceName: string;
  fields: any[];
  parse?: (data: any | undefined) => any;
  submitRedirect?: boolean;
}

export const useFormUpdate = <TData, TError extends HttpError = HttpError>({
  id: idFromProps,
  resourceName,
  fields,
  parse,
  submitRedirect = true,
}: FormUpdateProps) => {
  const params = useParams();
  const id = idFromProps || params?.id || "";
  const resourceWithRoute = useResourceWithRoute();
  const resource = resourceWithRoute(resourceName);

  const queryResult = useOne<TData, TError>({
    id,
    resource: resource.name,
    metaData: {
      fields,
    },
  });
  const { data, isFetching } = queryResult;

  const [form] = Form.useForm();

  React.useEffect(() => {
    form.setFieldsValue({
      ...(data?.data as any),
      ...(parse && parse(data?.data as any)),
    });
    return () => {
      form.resetFields();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data, id, isFetching]);

  const { mutate, ...mutateResult } = useUpdate();

  // refer to /refine/packages/core/src/hooks/redirection/index.ts
  const handleSubmitWithRedirect = useRedirectionAfterSubmission();

  return {
    form,
    formProps: {
      onFinish: (values: any) => {
        mutate({
          id,
          resource: resource.name,
          values: {
            ...values,
          },
        });

        if (submitRedirect) {
          handleSubmitWithRedirect({
            redirect: "list",
            resource,
            id,
          });
        }
      },
    },
    queryResult,
    mutateResult,
  };
};
