import { useState } from 'react';
import { useTranslate } from '@pankod/refine-core';
import { VerifyEmailDialog } from './VerifyEmailDialog';
import { WarningOutlined } from '@ant-design/icons';
import { Button } from 'antd';

type Props = {
  onSuccess?: () => void;
};

const VerifyEmailLabel = ({ onSuccess }: Props) => {
  const [openPopup, setOpenPopup] = useState(false);
  const t = useTranslate();

  return (
    <>
      <VerifyEmailDialog open={openPopup} setOpen={setOpenPopup} onSuccess={() => onSuccess && onSuccess()} />
      <div className="flex justify-between items-center py-2 px-4 mb-2 bg-[#fdaead] rounded-lg">
        <div className="flex items-center px-2">
          <WarningOutlined className="text-red-500 mr-3" />
          <div className="text-red-500">{t('pages.editProfile.verifyEmail.verifyEmailLabel')}</div>
        </div>
        <Button
          type="primary"
          className="text-white bg-red rounded-md px-2 py-1"
          onClick={() => setOpenPopup(!openPopup)}
        >
          {t('buttons.verify')}
        </Button>
      </div>
    </>
  );
};

export default VerifyEmailLabel;
