import { message } from '@pankod/refine-antd';
import { AuthProvider } from '@pankod/refine-core';
import { Auth } from 'api';
import { GraphQLClient, gql } from 'graphql-request';
import { simplifyErrorMessage } from 'libs';

const TOKEN_KEY = 'color-auth';
const IDENTITY_KEY = 'color-identity';
const WALLET_ADDRESS_KEY = 'color-wallet-address';

export interface Identity {
  username: string;
  role: 'user' | 'admin';
}

interface AuthProviderProps extends AuthProvider {
  setAuth: (auth: Auth, values: Identity) => void;
  removeAuth: () => void;
}

export const customAuthProvider = (gqlClient: GraphQLClient): AuthProviderProps => {
  const defaultProvider = {
    login: async ({ username, password, role }) => {
      try {
        const mutations = {
          user: gql`
            mutation Login($username: String!, $password: String!) {
              login(data: { username: $username, password: $password }) {
                accessToken
                refreshToken
              }
            }
          `,
          admin: gql`
            mutation adminLogin($username: String!, $password: String!) {
              adminLogin(data: { username: $username, password: $password }) {
                accessToken
                refreshToken
              }
            }
          `,
        };

        const response = await gqlClient.request(mutations[role], {
          username,
          password,
        });
        const tokenPayload = role === 'user' ? response.login : response.adminLogin;

        setAuth(tokenPayload, { username, role });
        message.success('Login success!');
        return Promise.resolve();
      } catch (error) {
        return Promise.reject({
          name: simplifyErrorMessage(error),
          message: 'Login Failed!',
        });
      }
    },
    logout: () => {
      removeAuth();
      return Promise.resolve();
    },
    checkError: () => Promise.resolve(),
    checkAuth: () => {
      const token = localStorage.getItem(TOKEN_KEY);
      const identity = localStorage.getItem(IDENTITY_KEY);

      if (token && identity) {
        const parsedToken = JSON.parse(token);
        const parsedIdentity = JSON.parse(identity);

        gqlClient.setHeaders({
          Authorization: `Bearer ${parsedToken.accessToken}`,
        });

        return Promise.resolve();
      }

      removeAuth();

      return Promise.reject();
    },
    getPermissions: () => {
      const identity = localStorage.getItem(IDENTITY_KEY);

      if (identity) {
        const parsedIdentity = JSON.parse(identity);
        return Promise.resolve(parsedIdentity.role);
      }

      return Promise.reject();
    },
    getUserIdentity: async () => {
      const token = localStorage.getItem(TOKEN_KEY);
      const identity = localStorage.getItem(IDENTITY_KEY);

      if (!token || !identity) {
        removeAuth();
        return Promise.reject();
      }

      const parsedIdentity = JSON.parse(identity);

      return Promise.resolve({
        ...parsedIdentity,
      });
    },
  };

  const removeAuth = () => {
    localStorage.removeItem(TOKEN_KEY);
    localStorage.removeItem(IDENTITY_KEY);
    gqlClient.setHeaders({ Authorization: '' });
  };

  const setAuth = (auth: Auth, payload: Identity) => {
    localStorage.setItem(TOKEN_KEY, JSON.stringify(auth));
    localStorage.setItem(IDENTITY_KEY, JSON.stringify(payload));
    gqlClient.setHeaders({ Authorization: `Bearer ${auth.accessToken}` });
  };

  return {
    ...defaultProvider,
    setAuth,
    removeAuth,
  };
};
