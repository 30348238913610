export const RESOURCE_NAME = 'playlists';
export const RESOURCE_FIELDS = [
  'id',
  'name',
  'thumbnailUrl',
  'description',
  'archivedAt',
  'createdAt',
  'updatedAt',
] as const;
export const PAGINATION_FIELDS = ['currentPage', 'perPage', 'totalCount', 'totalPages'] as const;
